import classNames from 'classnames';
import { PropsWithChildren } from 'react';

import styles from './AnonymousLayout.module.scss';

function AnonymousLayout({ children }: PropsWithChildren) {
  return <main className={classNames(styles.Main)}>{children}</main>;
}

export default AnonymousLayout;
