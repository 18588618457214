import { useState, useCallback, useMemo } from 'react';
import { ErrorOption, UseFormWatch } from 'react-hook-form';

import ServerErrorAdapter from 'src/utils/ServerErrorAdapter';
import checkFormHasChanges from 'src/utils/checkFormHasChanges';

import { ServerError, ServerFormErrors } from '../types';

export function useFormErrors<FD extends object>(
  setError: (name: keyof FD, error: ErrorOption) => void,
  clearErrors: (name?: keyof FD) => void,
) {
  const [serverErrorMessages, setServerErrorMessages] = useState<ServerFormErrors>([]);

  const handleErrors = useCallback(
    (error?: ServerError | null) => {
      if (!error) {
        clearErrors();
        setServerErrorMessages([]);
        return;
      }
      const { fieldErrors, formErrors } = new ServerErrorAdapter(error);
      Object.entries(fieldErrors).forEach(([key, value]) => {
        const message = value.join(';\r\n');
        setError(key as keyof FD, { message, type: 'manual' });
      });
      setServerErrorMessages(formErrors);
    },
    [setError, clearErrors],
  );

  return {
    serverErrorMessages,
    setServerErrorMessages,
    handleErrors,
  };
}

export function useFormHasChanges<FD extends object>(watch: UseFormWatch<FD>, comparedData?: Partial<FD> | null) {
  const values = watch();
  const hasChanges = useMemo(() => checkFormHasChanges(values, comparedData), [values, comparedData]);
  return hasChanges;
}
