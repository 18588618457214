const MAIN_APP_PREFIX = 'app';
const ADMIN_APP_PREFIX = 'admin';

const { protocol, hostname, host } = window.location;

export const envDomain = hostname.slice(hostname.indexOf('.') + 1);
export const envHost = host.slice(host.indexOf('.') + 1);
export const envTenant = hostname.slice(0, hostname.indexOf('.'));

export function getSubDomainHostUrl(subDomainPrefix: string) {
  return `${protocol}//${subDomainPrefix}.${envHost}`;
}

export const mainAppUrl = getSubDomainHostUrl(MAIN_APP_PREFIX);
export const adminAppUrl = getSubDomainHostUrl(ADMIN_APP_PREFIX);
