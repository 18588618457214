import classNames from 'classnames';
import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams, Params } from 'react-router-dom';

import PreviewDocument from 'src/components/PreviewDocument';
import ServerErrorMessages from 'src/components/ServerErrorMessages';

import useUrlQuery from 'src/hooks/useUrlQuery';

import parsePublicToken from 'src/utils/parsePublicToken';
import ServerErrorAdapter from 'src/utils/ServerErrorAdapter';

import { downloadAgreementDocument } from 'src/api/base/agreement';
import { downloadAgreementDocumentPublic } from 'src/api/base/contactSign';

import { ServerError, ServerFormErrors } from 'src/types';

import styles from './DocumentViewer.module.scss';

function DocumentViewer() {
  const urlQuery = useUrlQuery();
  const token = urlQuery.get('token');
  const accessToken = useMemo(() => parsePublicToken(token), [token]);
  const { agreementDocumentId = '' } = useParams<Params<'agreementDocumentId'>>();

  const { data: document, error } = useQuery({
    enabled: Boolean(agreementDocumentId),
    queryKey: ['downloadAgreementDocument', agreementDocumentId, accessToken],
    async queryFn({ signal }) {
      const config = { signal };
      const res = await (accessToken
        ? downloadAgreementDocumentPublic(accessToken, agreementDocumentId, config)
        : downloadAgreementDocument(agreementDocumentId, config));
      return res.data;
    },
    retry: false,
    refetchOnWindowFocus: false,
  });
  const errorMessages = useMemo<ServerFormErrors>(
    () => new ServerErrorAdapter(error as ServerError).combine(),
    [error],
  );

  return (
    <main className={classNames(styles.Main)}>
      {document && <PreviewDocument document={document} hasSignatureFilename={false} />}
      <ServerErrorMessages messages={errorMessages} />
    </main>
  );
}

export default DocumentViewer;
