import { AxiosRequestConfig } from 'axios';

import { baseApi } from 'src/api';

import RequestSearchParamsAdapter from 'src/utils/RequestSearchParamsAdapter';

import {
  DealRotorDto,
  CreateDealRotorModel,
  DealRotorUpdateModel,
  DealRotorActivateModel,
  DealRotorUpdateChatModel,
  DealRotorSummaryDtoDataPagedDto,
  DealRotorParticipantDtoDataPagedDto,
  DealRotorType,
  DealRotorStatus,
  DealRotorRelationshipType,
  AgreementStage,
  DealRotorPartyDto,
  DealRotorAuditGrouped,
  DealRotorAuditDtoDataPagedDto,
  DealRotorAuditCategory,
  DealRotorAuditSubCategory,
  UserSummaryDto,
  TagDto,
  RoleName,
  DealRotorTemplateDto,
  ExpectedSignatoryDto,
  UUIDString,
  DateTimeISOString,
  DealRotorAttachmentPagedDto,
  AgreementTransactionType,
  DealRotorForUserSummaryDtoDataPagedDto,
  DealRotorTerminationModel,
} from 'src/types';

// PermissionNames.DealRotorRead
export const getDealRotorById = (dealRotorId: UUIDString, config: AxiosRequestConfig = {}) =>
  baseApi.get<DealRotorDto>(`/deal-rotors/${dealRotorId}`, config);

// PermissionNames.DealRotorDetailsWrite
export const updateDealRotor = (
  dealRotorId: UUIDString,
  data: DealRotorUpdateModel,
  config: AxiosRequestConfig = {},
) => {
  return baseApi.patch<UUIDString>(`/deal-rotors/${dealRotorId}`, data, config);
};

// PermissionNames.DealRotorCreate
export const deleteDealRotorById = (dealRotorId: UUIDString, config: AxiosRequestConfig = {}) =>
  baseApi.delete<void>(`/deal-rotors/${dealRotorId}`, config);

// PermissionNames.DealRotorRead
export const getDealRotorPartyList = (dealRotorId: UUIDString, config: AxiosRequestConfig = {}) =>
  baseApi.get<DealRotorPartyDto[]>(`/deal-rotors/${dealRotorId}/parties`, config);

export type GetDealRotorAuditListAllParams = {
  subCategories: DealRotorAuditSubCategory[] | undefined;
  dateFrom: DateTimeISOString | undefined;
  dateTo: DateTimeISOString | undefined;
};
// PermissionNames.DealRotorRead
export const getDealRotorAuditListAll = (
  dealRotorId: UUIDString,
  params: GetDealRotorAuditListAllParams,
  config: AxiosRequestConfig = {},
) =>
  baseApi.get<DealRotorAuditGrouped>(`/deal-rotors/${dealRotorId}/audits/all-grouped`, {
    ...config,
    params,
  });

export type GetDealRotorAuditListSearchParams = {
  category: DealRotorAuditCategory | undefined;
  subCategory: DealRotorAuditSubCategory | undefined;
  dateFrom: DateTimeISOString | undefined;
  dateTo: DateTimeISOString | undefined;
  userIds: UUIDString[] | undefined;
};
// PermissionNames.DealRotorAuditRead
export const getDealRotorAuditListSearch = (
  dealRotorId: UUIDString,
  params: RequestSearchParamsAdapter & GetDealRotorAuditListSearchParams,
  config: AxiosRequestConfig = {},
) => baseApi.get<DealRotorAuditDtoDataPagedDto>(`/deal-rotors/${dealRotorId}/audits`, { ...config, params });

// PermissionNames.DealRotorAuditRead
export const getDealRotorAuditParticipantList = (dealRotorId: UUIDString, config: AxiosRequestConfig = {}) =>
  baseApi.get<UserSummaryDto[]>(`/deal-rotors/${dealRotorId}/audits/participants`, config);

// PermissionNames.DealRotorRead
export const getDealRotorTagList = (config: AxiosRequestConfig = {}) =>
  baseApi.get<TagDto[]>('/deal-rotors/tags', config);

// PermissionNames.DealRotorRead
export const getDealRotorTemplateList = (config: AxiosRequestConfig = {}) =>
  baseApi.get<DealRotorTemplateDto[]>('/deal-rotors/templates', config);

// PermissionNames.DealRotorRead
export const getExpectedSignatoryList = (dealRotorId: UUIDString, config: AxiosRequestConfig = {}) =>
  baseApi.get<ExpectedSignatoryDto[]>(`/deal-rotors/${dealRotorId}/expected-signatories`, config);

// PermissionNames.DealRotorRead
export const downloadDealRotorFile = (dealRotorFileId: UUIDString, config: AxiosRequestConfig = {}) =>
  baseApi.get<Blob>(`/deal-rotors/deal-rotor-files/${dealRotorFileId}`, {
    ...config,
    responseType: 'blob',
  });

// PermissionNames.DealRotorRead
export const downloadAttachment = (dealRotorFileId: UUIDString, config: AxiosRequestConfig = {}) =>
  baseApi.get<Blob>(`/deal-rotors/deal-rotor-files/${dealRotorFileId}`, {
    ...config,
    responseType: 'blob',
  });

export type GetDealRotorListParams = {
  type: DealRotorType | undefined;
  status: DealRotorStatus | undefined;
  relationshipType: DealRotorRelationshipType | undefined;
  agreementStage: AgreementStage | undefined;
  startDateFrom: DateTimeISOString | undefined;
  startDateTo: DateTimeISOString | undefined;
  partyB: UUIDString[] | undefined;
  tags: string[] | undefined;
  roles: RoleName[] | undefined;
  agreementTransactionType: AgreementTransactionType | undefined;
  daysRange: string | undefined;
};
// PermissionNames.DealRotorRead
export const getDealRotorListSearch = (
  params: RequestSearchParamsAdapter & GetDealRotorListParams,
  config: AxiosRequestConfig = {},
) => baseApi.get<DealRotorSummaryDtoDataPagedDto>('/deal-rotors/search', { ...config, params });

// PermissionNames.DealRotorRead
// RoleName.GlobalDealRotorManager
export const getDealRotorListSearchByUserId = (
  userId: UUIDString,
  params: RequestSearchParamsAdapter & { agreementStage: AgreementStage | undefined },
  config: AxiosRequestConfig = {},
) => baseApi.get<DealRotorForUserSummaryDtoDataPagedDto>(`/deal-rotors/users/${userId}/search`, { ...config, params });

// PermissionNames.DealRotorRead
export const getParticipantDealRotorListSearch = (
  userId: UUIDString,
  params: RequestSearchParamsAdapter,
  config: AxiosRequestConfig = {},
) =>
  baseApi.get<DealRotorParticipantDtoDataPagedDto>(`/deal-rotors/participants/${userId}/search`, { ...config, params });

// PermissionNames.DealRotorRead
export const getAttachments = (
  dealRotorId: UUIDString,
  params: Omit<RequestSearchParamsAdapter, 'orderBy' | 'orderField'>,
  config: AxiosRequestConfig = {},
) => baseApi.get<DealRotorAttachmentPagedDto>(`/deal-rotors/${dealRotorId}/attachments`, { params, ...config });

// PermissionNames.DealRotorAttachmentWrite
export const uploadAttachment = (dealRotorId: UUIDString, data: FormData, config: AxiosRequestConfig = {}) =>
  baseApi.post<UUIDString>(`/deal-rotors/${dealRotorId}/attachments`, data, config);

// PermissionNames.DealRotorCreate
export const createDealRotor = (data: CreateDealRotorModel, config: AxiosRequestConfig = {}) => {
  return baseApi.post<UUIDString>('/deal-rotors', data, config);
};

// PermissionNames.DealRotorCreate
export const activateDealRotor = (
  dealRotorId: UUIDString,
  data: DealRotorActivateModel,
  config: AxiosRequestConfig = {},
) => {
  return baseApi.post<void>(`/deal-rotors/${dealRotorId}/activate`, data, config);
};

// PermissionNames.DealRotorDetailsWrite
export const saveDealRotorAsDraft = (
  dealRotorId: UUIDString,
  data: DealRotorActivateModel,
  config: AxiosRequestConfig = {},
) => {
  return baseApi.patch<void>(`/deal-rotors/${dealRotorId}/draft`, data, config);
};

// PermissionNames.DealRotorDetailsWrite
export const updateDealRotorChatEnabled = (
  dealRotorId: UUIDString,
  data: DealRotorUpdateChatModel,
  config: AxiosRequestConfig = {},
) => {
  return baseApi.patch<void>(`/deal-rotors/${dealRotorId}/chat`, data, config);
};

// PermissionNames.AgreementAttributesWrite
export const scheduleAgreementTermination = (
  dealRotorId: UUIDString,
  data: DealRotorTerminationModel,
  config: AxiosRequestConfig = {},
) => {
  return baseApi.post<void>(`/deal-rotors/${dealRotorId}/termination/schedule`, data, config);
};

// PermissionNames.AgreementAttributesWrite
export const editAgreementTermination = (
  dealRotorId: UUIDString,
  data: DealRotorTerminationModel,
  config: AxiosRequestConfig = {},
) => {
  return baseApi.patch<void>(`/deal-rotors/${dealRotorId}/termination/edit`, data, config);
};

// PermissionNames.AgreementAttributesWrite
export const cancelAgreementTermination = (dealRotorId: UUIDString, config: AxiosRequestConfig = {}) => {
  return baseApi.post<void>(`/deal-rotors/${dealRotorId}/termination/cancel`, null, config);
};

// PermissionNames.DealRotorDeactivate
export const deactivateDealRotor = (dealRotorId: UUIDString, config: AxiosRequestConfig = {}) => {
  return baseApi.post<void>(`/deal-rotors/${dealRotorId}/deactivate`, null, config);
};
