import { useState, useMemo, useEffect } from 'react';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { Link, generatePath } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { useForm, UseFormWatch, UseFormHandleSubmit } from 'react-hook-form';
import { boolean, object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Field from 'src/components/forms/Field';
import Table, { useTableChangeParams, createColumnHelper } from 'src/components/Table';
import Avatar from 'src/components/Avatar';
import ServerErrorMessages from 'src/components/ServerErrorMessages';

import useIsMount from 'src/hooks/useIsMount';
import useUserPermission from 'src/hooks/useUserPermission';
import useUrlQueryFilters from 'src/hooks/useUrlQueryFilters';

import ServerErrorAdapter from 'src/utils/ServerErrorAdapter';
import RequestSearchParamsAdapter from 'src/utils/RequestSearchParamsAdapter';

import {
  getParticipantListSearch,
  getCurrentUserParticipantListSearch,
  GetParticipantListParams,
} from 'src/api/base/user';

import {
  GetUserParticipantDto,
  OrganisationType,
  YupFormShape,
  ServerError,
  ServerFormErrors,
  PermissionNames,
} from 'src/types';

type FormData = GetParticipantListParams & {
  search: string | undefined;
};
type FormShape = YupFormShape<FormData>;
const formSchema = object().shape<FormShape>({
  search: string().trim().label('Search').emptyToUndefined(),
  type: string().trim().label('Users').emptyToUndefined(),
  includeAll: boolean().label('Include all user deals'),
});

const columnHelper = createColumnHelper<GetUserParticipantDto>();

const typeOptions = [
  { value: OrganisationType.PartyA, label: 'Our users' },
  { value: OrganisationType.PartyB, label: 'Party B users' },
];

function useFormattedWatch(
  isDataLoaded: boolean,
  watch: UseFormWatch<FormData>,
  handleSubmit: UseFormHandleSubmit<FormData, undefined>,
  resetPage: () => void,
): FormData {
  const isMount = useIsMount();
  const [data, setData] = useState<FormData>({} as FormData);
  const { search, type, includeAll } = watch();

  useEffect(() => {
    handleSubmit((formData) => {
      if (isMount) return;
      setData({ ...formData });
      if (isDataLoaded) {
        resetPage();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, type, includeAll]);

  return data;
}

function DealParticipantList() {
  const { hasUserPermission } = useUserPermission();
  const isAllowedUserRead = hasUserPermission(PermissionNames.UserRead);
  const isAllowedUserReadContacts = hasUserPermission(PermissionNames.UserReadContacts);
  const isAllowedAnalyticAllSystemDealsRotorsRead = hasUserPermission(PermissionNames.AnalyticAllSystemDealsRotorsRead);

  const [isFiltersInitialized, setIsFiltersInitialized] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false); // Used to enable reset paging

  const { register, control, formState, setValue, watch, handleSubmit } = useForm<FormData>({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    defaultValues: { includeAll: false },
  });
  const { pagination, setPagination, sorting, setSorting, resetPage } = useTableChangeParams({});
  const { search, type, includeAll = false } = useFormattedWatch(isDataLoaded, watch, handleSubmit, resetPage);
  const initialFilters = useUrlQueryFilters({ search, type, includeAll }, { pagination, sorting });

  useEffect(() => {
    if (initialFilters.search) {
      setValue('search', initialFilters.search);
    }
    if (initialFilters.type) {
      setValue('type', initialFilters.type);
    }
    if (initialFilters.includeAll) {
      setValue('includeAll', String(initialFilters.includeAll) === 'true');
    }

    if (initialFilters.pagination) {
      setPagination(initialFilters.pagination);
    }

    if (initialFilters.sorting) {
      setSorting(initialFilters.sorting);
    }

    // 1ms timeout to get rid of 2 requests
    setTimeout(() => {
      setIsFiltersInitialized(true);
    }, 1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFilters]);

  const {
    isFetching,
    data: paginatedData,
    error: paginatedDataError,
  } = useQuery({
    enabled: isFiltersInitialized,
    queryKey: [
      'getParticipantListSearch',
      isAllowedAnalyticAllSystemDealsRotorsRead,
      search,
      pagination,
      sorting,
      type,
      includeAll,
    ],
    async queryFn({ signal }) {
      const config = { signal };
      const res = isAllowedAnalyticAllSystemDealsRotorsRead
        ? await getParticipantListSearch(
            { ...new RequestSearchParamsAdapter({ search, pagination, sorting }), type, includeAll },
            config,
          )
        : await getCurrentUserParticipantListSearch(
            { ...new RequestSearchParamsAdapter({ search, pagination, sorting }), type },
            config,
          );
      return res.data;
    },
    placeholderData: keepPreviousData,
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 3 * 60 * 1000, // 3 min
  });

  const { data, pageCount, total } = useMemo(
    () => ({
      data: paginatedData?.items ?? [],
      pageCount: paginatedData?.total ? Math.ceil(paginatedData.total / pagination.pageSize) : 1,
      total: paginatedData?.total ?? 0,
    }),
    [paginatedData, pagination.pageSize],
  );

  const errorMessages = useMemo<ServerFormErrors>(() => {
    const paginatedDataErrorMessages = new ServerErrorAdapter(paginatedDataError as ServerError).combine();
    return paginatedDataErrorMessages;
  }, [paginatedDataError]);

  useEffect(() => {
    if (paginatedData) {
      setIsDataLoaded(true);
    }
  }, [paginatedData]);

  useEffect(() => {
    // Keep in mind that data can be loaded from the cache
    if (isFiltersInitialized && isDataLoaded) {
      resetPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('fullName', {
        id: 'firstName',
        header: 'Name',
        cell: ({ getValue, row }) => {
          const { id: userId } = row.original;
          return (
            <div className="d-flex align-items-center gap-2">
              <Avatar src={row.original.logoUrl} alt={getValue() || '--'} />
              {isAllowedUserRead ? (
                <Link className="fw-medium" to={generatePath(RoutePath.dealParticipantDetailsById, { userId })}>
                  {getValue()}
                </Link>
              ) : isAllowedUserReadContacts ? (
                <Link className="fw-medium" to={generatePath(RoutePath.userContactById, { userId })}>
                  {getValue()}
                </Link>
              ) : (
                <span className="fw-medium">{getValue()}</span>
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor('email', {
        id: 'email',
        header: 'Email',
      }),
      columnHelper.accessor('statistic', {
        id: 'statistic',
        header: 'Statistic',
        enableSorting: false,
        cell: ({ getValue, row }) => {
          return (
            <>
              <div>
                <b>{getValue().dealRotorsCount}</b> deal rotors
              </div>
              {row.original.type === OrganisationType.PartyB && (
                <div>
                  <b>{getValue().partyBCount}</b> party B
                </div>
              )}
            </>
          );
        },
      }),
    ],
    [isAllowedUserRead, isAllowedUserReadContacts],
  );

  return (
    <div className="h-100">
      <h1 className="h5 mb-4">Deal participants</h1>
      <div className="bg-white border rounded-top border-bottom-0">
        <form className="p-3" name="dealParticipantFilters" noValidate>
          <div className="row g-3">
            <Field
              className="col-sm-6 col-md-4 col-lg-4 col-xl-3"
              field="input"
              type="search"
              placeholder="Search"
              autoComplete="off"
              register={register('search')}
              control={control}
              formSchema={formSchema}
              errors={formState.errors}
            />
            <Field
              className="col-sm-6 col-md-4 col-lg-4 col-xl-3"
              field="dropdown"
              placeholder="All users"
              autoComplete="off"
              register={register('type')}
              control={control}
              formSchema={formSchema}
              errors={formState.errors}
              options={typeOptions}
              dropdownProps={{
                isSearchable: true,
                isClearable: true,
              }}
            />
            {isAllowedAnalyticAllSystemDealsRotorsRead && (
              <Field
                className="col-sm-6 col-md-4 col-lg-4 col-xl-3 d-flex align-items-center"
                field="input"
                type="checkbox"
                label="Include all user deals"
                autoComplete="off"
                register={register('includeAll')}
                control={control}
                formSchema={formSchema}
                errors={formState.errors}
              />
            )}
          </div>
          <ServerErrorMessages className="mt-3 mb-0" messages={errorMessages} />
        </form>
      </div>
      <Table
        className="table-border-bottom-none mb-0"
        wrapperClassName="border rounded-bottom border-top-0 mb-3"
        data={data}
        total={total}
        columns={columns}
        isLoading={isFetching}
        pageCount={pageCount}
        pagination={pagination}
        setPagination={setPagination}
        sorting={sorting}
        setSorting={setSorting}
      />
    </div>
  );
}

export default DealParticipantList;
