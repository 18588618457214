import classNames from 'classnames';
import { useState, useEffect, useCallback, useMemo, useId, PropsWithChildren } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useOutletContext, useParams, Params, Link, generatePath } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { useSelector } from 'src/store';
import { tenantTimezoneInBracketsSelector } from 'src/store/selectors/tenantSelector';

import { useForm } from 'react-hook-form';
import { object, string, date, array } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Field from 'src/components/forms/Field';
import FormatDate from 'src/components/FormatDate';
import LoadingButton from 'src/components/buttons/LoadingButton';
import ServerErrorMessages from 'src/components/ServerErrorMessages';
import { InfoIcon } from 'src/components/icons';

import useUserPermission from 'src/hooks/useUserPermission';
import useTenantTimezone from 'src/hooks/useTenantTimezone';
import { useFormErrors, useFormHasChanges } from 'src/hooks/FormHelpers';

import { DealRotorEditDetailedTabsOutletContext } from './DealRotorEdit.detailed.tabs';

import Converter from 'src/utils/Converter';
import LocalDate from 'src/utils/LocalDate';
import checkFormHasChanges from 'src/utils/checkFormHasChanges';
import { dealRotorTypeOptions, dealRotorRelationshipTypeOptions } from 'src/utils/selectOptions';

import { updateDealRotor, deactivateDealRotor } from 'src/api/base/dealRotor';

import {
  DealRotorUpdateModel,
  DealRotorType,
  DealRotorStatus,
  YupFormShape,
  ServerError,
  UUIDString,
  PermissionNames,
  AgreementStage,
  AgreementState,
  AgreementStatus,
  DealRotorChildDto,
} from 'src/types';

type FormData = DealRotorUpdateModel & {
  partyB: UUIDString[];
  type: DealRotorType;
};
type ChildDetailsProps = {
  childDeals: DealRotorChildDto[];
};
type ParentDetailsProps = {
  parentDeal: {
    id: string | null;
    name: string | null;
    number: string | null;
  };
};
type FormShape = YupFormShape<FormData>;

const ChildDetails = ({ childDeals }: ChildDetailsProps) => (
  <div className="card mb-3">
    <header className="card-header bg-white h6">Child details</header>
    <div className="card-body">
      <div className={classNames('d-flex flex-wrap gap-2')}>
        {childDeals.map((deal, dealIndex) => (
          <div className="hstack gap-2" key={deal.id}>
            <span className="text-nowrap text-secondary">{deal.name}</span>
            <Link className="text-center" to={generatePath(RoutePath.dealRotorEditRoot, { dealRotorId: deal.id })}>
              {deal.number || deal.id}
            </Link>
            {childDeals.length > 1 && dealIndex !== childDeals.length - 1 && <div className="vr"></div>}
          </div>
        ))}
      </div>
    </div>
  </div>
);

const ParentDetails = ({ parentDeal }: ParentDetailsProps) => (
  <div className="col-sm-6 col-xxl-3 mb-3">
    <div className="text-secondary mb-1">Parent deal rotor</div>
    <div className="form-control bg-cultured">
      <span>{parentDeal.name} </span>
      <Link to={generatePath(RoutePath.dealRotorEditRoot, { dealRotorId: parentDeal.id })}>
        {parentDeal.number || parentDeal.id}
      </Link>
    </div>
  </div>
);

const DealRotorCeasedDate = ({ children }: PropsWithChildren) => (
  <div className="col-sm-6 col-xxl-3 mb-3">
    <div className="text-secondary mb-1">Deactivated from</div>
    <div className="form-control bg-cultured">{children}</div>
  </div>
);

function DealRotorDetailsTab() {
  const dealRotorDetailsFormId = useId();
  const queryClient = useQueryClient();
  const { dealRotorId = '' } = useParams<Params<'dealRotorId'>>();
  const tenantTimezoneText = useSelector(tenantTimezoneInBracketsSelector);
  const { tenantTimezoneDateNow } = useTenantTimezone();
  const { isDealRotorLoading, dealRotor, isPartyBOptionsLoading, partyBOptions } =
    useOutletContext<DealRotorEditDetailedTabsOutletContext>();

  const [isDeactivateModalOpen, setIsDeactivateModalOpen] = useState(false);
  const closeDeactivateModal = () => setIsDeactivateModalOpen(false);

  const [isSaveDealRotorDetailsModalOpen, setIsSaveDealRotorDetailsModalOpen] = useState(false);
  const closeSaveDealRotorDetailsModal = () => setIsSaveDealRotorDetailsModalOpen(false);

  const [isDealRotorDetailsEditModeOn, setIsDealRotorDetailsEditModeOn] = useState(false);

  const activatedFromMaxDate = useMemo(
    () =>
      dealRotor?.agreementStartDate
        ? new Date(Math.min(new Date(dealRotor.agreementStartDate).getTime(), tenantTimezoneDateNow.getTime()))
        : tenantTimezoneDateNow,
    [dealRotor, tenantTimezoneDateNow],
  );

  const activatedFromMaxDateStartDay = useMemo(
    () => new LocalDate(activatedFromMaxDate).getStartDay().value,
    [activatedFromMaxDate],
  );

  const formSchema = useMemo(
    () =>
      object().shape<FormShape>({
        partyB: array().of(string()).label('Party B'),
        name: string().label('Deal name').trim().required(),
        startDate: date()
          .label('Date from')
          .nullable()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .max(activatedFromMaxDate, ({ label, max }) => {
            const dateFromFormatted = Converter.getFormattedDate(new LocalDate(max).toJSON());
            return `${label} field must be ${dateFromFormatted} or later`;
          })
          .required(),
        relationshipType: string().label('Relationship type').trim().required(),

        type: string().label('Deal type').trim(),

        description: string().label('Description').nullable().trim().max(210),
        tags: array().of(string()).label('Tags'),
      }),
    [activatedFromMaxDate],
  );

  const { register, control, handleSubmit, formState, setValue, setError, clearErrors, watch } = useForm<FormData>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      partyB: [],
      tags: [],
    },
  });
  const { serverErrorMessages, handleErrors } = useFormErrors<FormData>(setError, clearErrors);
  const type = watch('type');

  const dealRotorPrefill = useMemo(() => {
    if (!dealRotor) return;
    return {
      partyB: dealRotor.partyB ? dealRotor.partyB.map(({ organisationId }) => organisationId) : [],
      name: dealRotor.name || '',
      startDate: new LocalDate(dealRotor.startDate).getStartDay().toJSON(),
      relationshipType: dealRotor.relationshipType,
      type: dealRotor.type,
      description: dealRotor.description,
      tags: dealRotor.tags ? dealRotor.tags.map(({ name }) => name) : [],
    };
  }, [dealRotor]);

  const makeDealRotorPrefill = useCallback(() => {
    if (!dealRotorPrefill) return;
    setValue('partyB', dealRotorPrefill.partyB);
    setValue('name', dealRotorPrefill.name);
    setValue('startDate', dealRotorPrefill.startDate);
    setValue('relationshipType', dealRotorPrefill.relationshipType);
    setValue('type', dealRotorPrefill.type);
    setValue('description', dealRotorPrefill.description);
    setValue('tags', dealRotorPrefill.tags);
  }, [dealRotorPrefill, setValue]);

  const dealRotorDetailsCancelEditMode = useCallback(() => {
    setIsDealRotorDetailsEditModeOn(false);
    makeDealRotorPrefill();
  }, [makeDealRotorPrefill]);

  const hasChanges = useFormHasChanges(watch, dealRotorPrefill);

  useEffect(() => {
    makeDealRotorPrefill();
  }, [makeDealRotorPrefill]);

  const onSubmit = handleSubmit(async ({ name, description, relationshipType, startDate, tags }) => {
    if (!dealRotorId || !dealRotor) return;

    if (
      !checkFormHasChanges<FormData>({ name, description, relationshipType, startDate, tags }, {
        ...dealRotor,
        tags: dealRotor.tags ? dealRotor.tags.map(({ name }) => name) : [],
      } as any)
    ) {
      const error = new Error('You have to change some field to update.');
      handleErrors(error as ServerError);
      return;
    }

    if (!isSaveDealRotorDetailsModalOpen) {
      setIsSaveDealRotorDetailsModalOpen(true);
      return;
    }

    try {
      handleErrors();
      const payload: DealRotorUpdateModel = {
        name,
        description,
        relationshipType,
        startDate: new LocalDate(startDate).getStartDay().toJSON(),
        tags,
      };
      await updateDealRotor(dealRotorId, payload);
      await Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: ['getDealRotorById', dealRotorId] }),
        queryClient.invalidateQueries({ queryKey: ['getDealRotorAuditListSearch', dealRotorId] }),
      ]);
      queryClient.removeQueries({ queryKey: ['getDealRotorListSearch'] });
      queryClient.removeQueries({ queryKey: ['getDealRotorAuditListAll'] });
      setIsDealRotorDetailsEditModeOn(false);
    } catch (e) {
      handleErrors(e as ServerError);
    } finally {
      closeSaveDealRotorDetailsModal();
    }
  });

  const deactivateDealRotorRequest = async () => {
    try {
      handleErrors();
      await deactivateDealRotor(dealRotorId);

      await Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: ['getDealRotorById', dealRotorId] }),
        queryClient.invalidateQueries({ queryKey: ['getAgreementList', dealRotorId] }),
        queryClient.invalidateQueries({ queryKey: ['getDealRotorAuditListSearch', dealRotorId] }),
      ]);
      queryClient.removeQueries({ queryKey: ['getDealRotorListSearch'] });
      queryClient.removeQueries({ queryKey: ['getDealRotorAuditListAll'] });

      closeDeactivateModal();
    } catch (e) {
      handleErrors(e as ServerError);
    }
  };

  const { hasUserPermission } = useUserPermission();
  const isAllowedDealRotorDeactivate = hasUserPermission(PermissionNames.DealRotorDeactivate);
  const isAllowedDealRotorDetailsWrite = hasUserPermission(PermissionNames.DealRotorDetailsWrite);

  const isDeactivateVisible = useMemo(() => {
    const isPreAgreementFormalisationFinalised =
      !!dealRotor &&
      dealRotor.agreementStage === AgreementStage.Pre &&
      dealRotor.agreementState === AgreementState.Formalisation &&
      dealRotor.agreementStatus === AgreementStatus.Finalised;
    const isAgreementActive = !!dealRotor && dealRotor.agreementStage === AgreementStage.Active;

    if (!dealRotor) {
      return false;
    } else if (isAgreementActive) {
      return false;
    } else if (isPreAgreementFormalisationFinalised) {
      return false;
    } else {
      return true;
    }
  }, [dealRotor]);

  const isDealRotorDetailsDeactivateButtonDisabled =
    !isAllowedDealRotorDetailsWrite ||
    (dealRotor ? dealRotor.status === DealRotorStatus.Ceased || dealRotor.isAgreementTerminated === true : true);
  const isDealRotorDetailsEditDisabled =
    !isAllowedDealRotorDetailsWrite ||
    (dealRotor ? dealRotor.status === DealRotorStatus.Active && isDealRotorDetailsEditModeOn === false : true) ||
    (dealRotor ? dealRotor.status === DealRotorStatus.Ceased || dealRotor.isAgreementTerminated === true : true);
  const isDealRotorDetailsEditModeButtonVisible =
    dealRotor && dealRotor.status === DealRotorStatus.Active && isDealRotorDetailsEditModeOn === false;
  const isDealRotorDetailsEditModeButtonDisabled =
    !isAllowedDealRotorDetailsWrite ||
    (dealRotor ? dealRotor.status === DealRotorStatus.Ceased || dealRotor.isAgreementTerminated === true : true);

  return (
    <>
      <header className="placeholder-glow row g-3 mb-4">
        <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3">
          <div className="text-secondary">Deal number:</div>
          <div className={classNames('text-nowrap align-baseline', { placeholder: isDealRotorLoading })}>
            {isDealRotorLoading ? 'DL-000000' : dealRotor?.number ?? '---'}
          </div>
        </div>
        <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3">
          <div className="text-secondary">Created by:</div>
          <div className={classNames('text-nowrap align-baseline', { placeholder: isDealRotorLoading })}>
            {isDealRotorLoading ? 'Created by' : dealRotor?.createdBy ?? '---'}
          </div>
        </div>
        <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3">
          <div className="text-secondary">Party A:</div>
          <div className={classNames('text-nowrap align-baseline', { placeholder: isDealRotorLoading })}>
            {isDealRotorLoading ? 'Party A' : dealRotor?.partyAName ?? '---'}
          </div>
        </div>
        <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3">
          <div className="text-secondary">Deal Template:</div>
          <div className={classNames('text-nowrap align-baseline', { placeholder: isDealRotorLoading })}>
            {isDealRotorLoading ? 'Deal Template' : dealRotor?.templateName ?? '---'}
          </div>
        </div>
      </header>
      <form name="dealRotorDetailsForm" id={dealRotorDetailsFormId} onSubmit={onSubmit} noValidate>
        <div className="row g-3 mb-3">
          <Field
            className="col-sm-6 col-xxl-3"
            field="dropdown"
            label="Party B"
            placeholder="Choose party B"
            autoComplete="off"
            register={register('partyB')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
            options={partyBOptions}
            dropdownProps={{
              isLoading: isPartyBOptionsLoading,
              isSearchable: true,
              isClearable: false,
            }}
            disabled
            multiple
          />
          <Field
            className="col-sm-6 col-xxl-3"
            field="input"
            type="text"
            label="Deal name"
            placeholder="Enter deal name"
            autoComplete="off"
            register={register('name')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
            disabled={isDealRotorLoading || isDealRotorDetailsEditDisabled}
          />
          <Field
            className="col-sm-6 col-xxl-3"
            field="datepicker"
            label={`Activated from${tenantTimezoneText}`}
            autoComplete="off"
            register={register('startDate')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
            disabled={isDealRotorLoading || isDealRotorDetailsEditDisabled}
            datepickerProps={{ maxDate: activatedFromMaxDateStartDay }}
          />
          <Field
            className="col-sm-6 col-xxl-3"
            field="dropdown"
            label="Relationship type"
            placeholder="Choose relationship type"
            autoComplete="off"
            register={register('relationshipType')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
            options={dealRotorRelationshipTypeOptions}
            dropdownProps={{
              isSearchable: true,
              isClearable: false,
            }}
            disabled={isDealRotorLoading || isDealRotorDetailsEditDisabled}
          />
          <Field
            className="col-sm-6 col-xxl-3"
            field="dropdown"
            label="Deal type"
            placeholder="Choose deal type"
            autoComplete="off"
            register={register('type')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
            options={dealRotorTypeOptions}
            dropdownProps={{
              isSearchable: true,
              isClearable: false,
            }}
            disabled
          />
          {type === DealRotorType.Child && dealRotor && dealRotor.parentDealRotorId && (
            <ParentDetails
              parentDeal={{
                id: dealRotor.parentDealRotorId,
                name: dealRotor.parentDealRotorName,
                number: dealRotor.parentDealRotorNumber,
              }}
            />
          )}
          {dealRotor && dealRotor.endDate && dealRotor.status === DealRotorStatus.Ceased && (
            <DealRotorCeasedDate>
              <FormatDate date={dealRotor.endDate} />
            </DealRotorCeasedDate>
          )}
        </div>

        {type === DealRotorType.Parent && dealRotor?.childs && dealRotor.childs.length > 0 && (
          <ChildDetails childDeals={dealRotor.childs} />
        )}

        <div className="row g-3 mb-3">
          <Field
            className="col-sm-6 col-xxl-3"
            field="textarea"
            label="Description"
            placeholder="Enter description"
            autoComplete="off"
            max={210}
            register={register('description')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
            options={[]}
            dropdownProps={{
              isSearchable: true,
              isClearable: false,
            }}
            disabled={isDealRotorLoading || isDealRotorDetailsEditDisabled}
            After={<div className="text-end text-secondary fs-14 pt-1">Maximum 210 characters allowed</div>}
          />

          <Field
            className="col-sm-6 col-xxl-3"
            field="tags"
            label="Tags"
            placeholder="Enter to create a tag"
            autoComplete="off"
            register={register('tags')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
            disabled={isDealRotorLoading || isDealRotorDetailsEditDisabled}
            After={<div className="text-end text-secondary fs-14 pt-1">Separate by comma (,) to create a tag</div>}
          />
        </div>

        <footer className="d-flex flex-wrap justify-content-end gap-3">
          {isDeactivateVisible && (
            <button
              type="button"
              className="btn btn-outline-primary me-auto"
              disabled={
                isDealRotorLoading || isDealRotorDetailsDeactivateButtonDisabled || !isAllowedDealRotorDeactivate
              }
              onClick={() => setIsDeactivateModalOpen(true)}
            >
              Deactivate
            </button>
          )}
          {isDealRotorDetailsEditModeButtonVisible && (
            <button
              type="button"
              className="btn btn-outline-primary"
              disabled={isDealRotorLoading || isDealRotorDetailsEditModeButtonDisabled}
              onClick={() => setIsDealRotorDetailsEditModeOn(true)}
            >
              Edit
            </button>
          )}

          {isDealRotorDetailsEditModeButtonVisible && !isAllowedDealRotorDetailsWrite && (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>You don't have permission to edit details of active deal rotors.</Tooltip>}
            >
              <InfoIcon className="text-primary align-self-center" />
            </OverlayTrigger>
          )}

          {isDealRotorDetailsEditModeOn && (
            <button type="button" className="btn btn-outline-primary" onClick={dealRotorDetailsCancelEditMode}>
              Cancel
            </button>
          )}

          {isDealRotorDetailsEditModeOn && (
            <LoadingButton
              type="submit"
              className="btn btn-primary text-nowrap"
              disabled={isDealRotorLoading || isDealRotorDetailsEditDisabled || !hasChanges || formState.isSubmitting}
            >
              Save changes
            </LoadingButton>
          )}

          <ServerErrorMessages className="w-100" messages={serverErrorMessages} />
        </footer>
      </form>

      <Modal show={isDeactivateModalOpen} onHide={closeDeactivateModal} size="lg" centered>
        <Modal.Header onHide={closeDeactivateModal} closeButton>
          <Modal.Title className="h5">Are you sure you want to deactivate the deal rotor?</Modal.Title>
        </Modal.Header>
        <Modal.Body>After deactivating the deal rotor it will be deactivated.</Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-link" onClick={closeDeactivateModal}>
            Cancel
          </button>

          <LoadingButton type="button" className="btn btn-primary" onClick={deactivateDealRotorRequest}>
            Deactivate
          </LoadingButton>

          <ServerErrorMessages className="w-100" messages={serverErrorMessages} />
        </Modal.Footer>
      </Modal>

      <Modal show={isSaveDealRotorDetailsModalOpen} onHide={closeSaveDealRotorDetailsModal} size="lg" centered>
        <Modal.Header onHide={closeSaveDealRotorDetailsModal} closeButton>
          <Modal.Title className="h5">Are you sure you want to save the deal rotor details?</Modal.Title>
        </Modal.Header>
        <Modal.Body>After saving the deal rotor changes it will be updated.</Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-link" onClick={closeSaveDealRotorDetailsModal}>
            Cancel
          </button>

          <LoadingButton
            type="submit"
            className="btn btn-primary"
            form={dealRotorDetailsFormId}
            isLoading={formState.isSubmitting}
          >
            Save changes
          </LoadingButton>

          <ServerErrorMessages className="w-100" messages={serverErrorMessages} />
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DealRotorDetailsTab;
