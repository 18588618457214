import { Link } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { useForm } from 'react-hook-form';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Field from 'src/components/forms/Field';
import LoadingButton from 'src/components/buttons/LoadingButton';
import ServerErrorMessages from 'src/components/ServerErrorMessages';

import { useFormErrors } from 'src/hooks/FormHelpers';

import { forgotUserPassword } from 'src/api/auth/user';
import useAlertStatus from 'src/hooks/useAlertStatus';

import { UserResetTokenModel, YupFormShape, ServerError } from 'src/types';

type FormData = UserResetTokenModel;
type FormShape = YupFormShape<FormData>;

const formSchema = object().shape<FormShape>({
  email: string().label('Email').nullable().trim().lowercase().required().email(),
});

function PasswordResetForm() {
  const { setAlertState, AlertStatus: ResetPasswordAlert } = useAlertStatus({ variant: 'success' });
  const { register, control, handleSubmit, formState, setError, clearErrors } = useForm<FormData>({
    resolver: yupResolver(formSchema),
  });
  const { serverErrorMessages, handleErrors } = useFormErrors<FormData>(setError, clearErrors);

  const onSubmit = handleSubmit(async ({ email }) => {
    try {
      handleErrors();
      await forgotUserPassword({ email });
      setAlertState(true);
    } catch (e) {
      handleErrors(e as ServerError);
    }
  });

  return (
    <form name="passwordForgotForm" onSubmit={onSubmit} noValidate>
      <div className="mb-4">
        <Field
          label="Email address"
          field="input"
          type="email"
          placeholder="Enter your email"
          autoComplete="email"
          register={register('email')}
          control={control}
          formSchema={formSchema}
          errors={formState.errors}
        />
      </div>

      <div className="text-center mb-4">
        <LoadingButton type="submit" className="btn btn-primary" isLoading={formState.isSubmitting}>
          Request reset link
        </LoadingButton>
      </div>

      <ResetPasswordAlert>A password reset notification has been sent to your email address.</ResetPasswordAlert>

      <div className="text-center fs-14 fw-medium mb-4">
        <Link to={RoutePath.login}>Back to Sign In</Link>
      </div>

      <ServerErrorMessages messages={serverErrorMessages} />
    </form>
  );
}

export default PasswordResetForm;
