/**
 * Function checks if there are changes in the form data compared to the pre-populated data.
 */
export default function checkFormHasChanges<D extends object>(
  formData: Partial<D>,
  comparedData?: Partial<D> | null,
): boolean {
  if (!comparedData) return true;

  const formDataKeys = Object.keys(formData) as unknown as (keyof typeof formData)[];
  const comparedObject = Array.isArray(comparedData)
    ? (Object(comparedData).slice() as any[])
    : (Object.assign({}, comparedData) as Partial<D>);

  const hasNoChanges = formDataKeys.reduce((acc, key) => {
    if (!acc) return acc;
    let a = formData[key];
    if (typeof a === 'string') {
      (a as any) = a.trim();
    } else if ((a as any) instanceof Date) {
      (a as any) = (a as Date).toISOString();
    }
    let b: unknown;
    const aObj = Object(a);

    // Get 'b' (comparedValue)
    if (Array.isArray(formData)) {
      if (!Array.isArray(comparedObject)) return false;

      if (aObj === a) {
        const aKeys = Object.keys(aObj);
        const hasChildObject = aKeys.some((k) => Object(aObj[k]) === aObj[k]);
        const bIndex = comparedObject.findIndex((comparedValue) =>
          aKeys.every((k) => comparedValue[k] === aObj[k] || Object(aObj[k]) === aObj[k]),
        );

        if (bIndex >= 0) {
          b = comparedObject.splice(bIndex, 1)[0];
          if (!hasChildObject) return true; // a === b, 'a' don't have children
        } else {
          return false; // a !== b, has difference in primitive property
        }
      } else {
        // a is primitive
        const bIndex = comparedObject.findIndex((v) => v === a);
        b = bIndex !== -1 ? comparedObject.splice(bIndex, 1)[0] : undefined;
      }
    } else {
      // Array.isArray(formData) === false
      b = comparedData[key];
    }

    const bObj = Object(b);

    // Final check
    let isEqual: boolean;
    if (aObj === a && bObj === b) {
      isEqual =
        Array.isArray(aObj) && Array.isArray(bObj)
          ? aObj.length === bObj.length && !checkFormHasChanges<Record<number, unknown>>(aObj, bObj)
          : !checkFormHasChanges(aObj, bObj);
    } else {
      // primitive - use == to compare e.g. number with string
      isEqual = a == b; // eslint-disable-line eqeqeq
    }

    return acc && isEqual;
  }, true);

  return !hasNoChanges;
}
