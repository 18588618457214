import { useForm } from 'react-hook-form';
import { object, string, ref } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Field from 'src/components/forms/Field';
import LoadingButton from 'src/components/buttons/LoadingButton';
import ServerErrorMessages from 'src/components/ServerErrorMessages';

import { useFormErrors } from 'src/hooks/FormHelpers';

import { changeUserPassword } from 'src/api/base/user';

import { strongPasswordReg } from 'src/utils/constants';
import useAlertStatus from 'src/hooks/useAlertStatus';

import { UserChangePasswordModel, YupFormShape, ServerError } from 'src/types';

type FormData = UserChangePasswordModel & {
  confirmPassword: string;
};
type FormShape = YupFormShape<FormData>;
const formSchema = object().shape<FormShape>({
  oldPassword: string().label('Current password').trim().required(),
  newPassword: string()
    .label('New password')
    .trim()
    .required()
    .min(8)
    .matches(
      strongPasswordReg,
      ({ label }) =>
        `${label} should contain at least an uppercase letter, lowercase letter, number and special character`,
    ),
  confirmPassword: string()
    .label('Confirm password')
    .trim()
    .required()
    .oneOf([ref('newPassword'), ''], 'Passwords should be the same'),
});

const ChangePasswordForm = () => {
  const { setAlertState, AlertStatus: ChangePasswordAlert } = useAlertStatus({
    variant: 'success',
    innerProps: { alertWrapper: { className: 'mt-3 mb-0' } },
  });
  const { register, control, handleSubmit, formState, setError, clearErrors, reset } = useForm<FormData>({
    resolver: yupResolver(formSchema),
  });
  const { serverErrorMessages, handleErrors } = useFormErrors<FormData>(setError, clearErrors);

  const onSubmit = handleSubmit(async ({ oldPassword, newPassword }) => {
    handleErrors();
    setAlertState(false);
    try {
      await changeUserPassword({ oldPassword, newPassword });
      setAlertState(true);
      reset();
    } catch (e) {
      handleErrors(e as ServerError);
    }
  });

  return (
    <form name="changePasswordForm" onSubmit={onSubmit} noValidate>
      <Field
        className="col-6 mb-3"
        label="Current password"
        field="input"
        type="password"
        placeholder="Enter your current password"
        autoComplete="current-password"
        register={register('oldPassword')}
        control={control}
        formSchema={formSchema}
        errors={formState.errors}
        autoFocus
      />
      <Field
        className="col-6 mb-3"
        label="New password"
        field="input"
        type="password"
        placeholder="Enter your new password"
        autoComplete="new-password"
        register={register('newPassword')}
        control={control}
        formSchema={formSchema}
        errors={formState.errors}
      />
      <Field
        className="col-6 mb-3"
        label="Confirm new password"
        field="input"
        type="password"
        placeholder="Confirm your new password"
        autoComplete="off"
        register={register('confirmPassword')}
        control={control}
        formSchema={formSchema}
        errors={formState.errors}
      />
      <LoadingButton className="btn btn-primary" type="submit" isLoading={formState.isSubmitting}>
        Change
      </LoadingButton>

      <ChangePasswordAlert>Password had been changed</ChangePasswordAlert>
      <ServerErrorMessages className="mt-3 mb-0" messages={serverErrorMessages} />
    </form>
  );
};

export default ChangePasswordForm;
