import { useEffect, useMemo } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, generatePath, Link } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { useForm } from 'react-hook-form';
import { object, string, array } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Field from 'src/components/forms/Field';
import LoadingButton from 'src/components/buttons/LoadingButton';
import ServerErrorMessages from 'src/components/ServerErrorMessages';

import usePartyAOptionsByRoles from 'src/hooks/usePartyAOptionsByRoles';
import usePersistPageParams, { DEAL_ROTOR_LIST_PAGE_KEY } from 'src/hooks/usePersistPageParams';
import { useFormErrors } from 'src/hooks/FormHelpers';

import ServerErrorAdapter from 'src/utils/ServerErrorAdapter';

import { createDealRotor, getDealRotorTemplateList } from 'src/api/base/dealRotor';

import { CreateDealRotorModel, SelectOption, YupFormShape, ServerError } from 'src/types';

type FormData = CreateDealRotorModel;
type FormShape = YupFormShape<FormData>;
const formSchema = object().shape<FormShape>({
  templateId: string().label('Deal template').trim().required(),
  dealOwnerUserId: string().label('Deal owner').trim().required(),
  dealOpsOwnerUserId: string().label('Ops owner').trim().required(),
  dealManagers: array().of(string()).label('Deal manager').nullable(),
  dealOpsManagers: array().of(string()).label('Ops manager').nullable(),
  signatories: array().of(string()).label('Signatories').nullable(),
});

function DealRotorCreateTemplateAndRoles() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { pageUrlSearch: dealRotorListPageUrlSearch } = usePersistPageParams(DEAL_ROTOR_LIST_PAGE_KEY);

  const { register, control, handleSubmit, formState, setError, clearErrors } = useForm<FormData>({
    resolver: yupResolver(formSchema),
  });
  const { serverErrorMessages, setServerErrorMessages, handleErrors } = useFormErrors<FormData>(setError, clearErrors);

  const {
    isPartyAUsersLoading,
    partyAUsersError,
    dealRotorOwnerOptions,
    operationsOwnerOptions,
    dealRotorManagerOptions,
    operationsManagerOptions,
    signatoryPartyAOptions,
  } = usePartyAOptionsByRoles();

  const {
    isLoading: isDealRotorTemplateListLoading,
    data: dealRotorTemplateList,
    error: dealRotorTemplateListError,
  } = useQuery({
    queryKey: ['getDealRotorTemplateList'],
    async queryFn({ signal }) {
      const config = { signal };
      const res = await getDealRotorTemplateList(config);
      return res.data;
    },
    initialData: [],
    retry: false,
    refetchOnWindowFocus: false,
  });

  const dealRotorTemplateOptions = useMemo<SelectOption[]>(() => {
    return dealRotorTemplateList.map((dealRotorTemplate) => ({
      label: dealRotorTemplate.description,
      value: dealRotorTemplate.id,
    }));
  }, [dealRotorTemplateList]);

  useEffect(() => {
    const partyAUsersErrorMessages = new ServerErrorAdapter(partyAUsersError as ServerError).combine();
    const dealRotorTemplateOptionsErrorMessages = new ServerErrorAdapter(
      dealRotorTemplateListError as ServerError,
    ).combine();
    const errorMessages = [...partyAUsersErrorMessages, ...dealRotorTemplateOptionsErrorMessages];
    setServerErrorMessages(errorMessages);
  }, [partyAUsersError, dealRotorTemplateListError, setServerErrorMessages]);

  const onSubmit = handleSubmit(async (formData) => {
    try {
      handleErrors();
      const res = await createDealRotor(formData);
      const dealRotorId = res.data;
      const nextRoute = generatePath(RoutePath.dealRotorCreateDetails, { dealRotorId });
      queryClient.removeQueries({ queryKey: ['getDealRotorListSearch'] });
      navigate(nextRoute);
    } catch (e) {
      handleErrors(e as ServerError);
    }
  });

  return (
    <section>
      <header className="d-flex justify-content-between flex-wrap bg-white border-start border-end gap-3 p-4">
        <h2 className="h5 mb-0">Select Templates and Roles</h2>
      </header>
      <form name="createDealRotorTemplateAndRoles" onSubmit={onSubmit} noValidate>
        <div className="bg-white border border-top-0 rounded-bottom p-4 pt-0 mb-4">
          <div className="row g-3 mb-3">
            <Field
              className="col-sm-6 col-md-4 col-lg-4 col-xl-3"
              field="dropdown"
              label="Deal template"
              placeholder="Choose deal template"
              autoComplete="off"
              register={register('templateId')}
              control={control}
              formSchema={formSchema}
              errors={formState.errors}
              options={dealRotorTemplateOptions}
              dropdownProps={{
                isLoading: isDealRotorTemplateListLoading,
                isSearchable: false,
                isClearable: false,
              }}
            />
          </div>

          <p>Please assign yourself as one of the roles (Owner/Manager)</p>

          <div className="row g-3 mb-3">
            <Field
              className="col-sm-6 col-md-4 col-lg-4 col-xl-3"
              field="dropdown"
              label="Deal owner"
              placeholder="Choose deal owner"
              autoComplete="off"
              register={register('dealOwnerUserId')}
              control={control}
              formSchema={formSchema}
              errors={formState.errors}
              options={dealRotorOwnerOptions}
              dropdownProps={{
                isLoading: isPartyAUsersLoading,
                isSearchable: true,
                isClearable: false,
              }}
            />

            <Field
              className="col-sm-6 col-md-4 col-lg-4 col-xl-3"
              field="dropdown"
              label="Ops owner"
              placeholder="Choose ops owner"
              autoComplete="off"
              register={register('dealOpsOwnerUserId')}
              control={control}
              formSchema={formSchema}
              errors={formState.errors}
              options={operationsOwnerOptions}
              dropdownProps={{
                isLoading: isPartyAUsersLoading,
                isSearchable: true,
                isClearable: false,
              }}
            />
          </div>

          <div className="row g-3">
            <Field
              className="col-sm-6 col-md-4 col-lg-4 col-xl-3"
              field="dropdown"
              label="Deal manager"
              placeholder="Choose deal manager"
              autoComplete="off"
              register={register('dealManagers')}
              control={control}
              formSchema={formSchema}
              errors={formState.errors}
              options={dealRotorManagerOptions}
              dropdownProps={{
                isLoading: isPartyAUsersLoading,
                isSearchable: true,
                isClearable: false,
              }}
              multiple
            />

            <Field
              className="col-sm-6 col-md-4 col-lg-4 col-xl-3"
              field="dropdown"
              label="Ops manager"
              placeholder="Choose ops manager"
              autoComplete="off"
              register={register('dealOpsManagers')}
              control={control}
              formSchema={formSchema}
              errors={formState.errors}
              options={operationsManagerOptions}
              dropdownProps={{
                isLoading: isPartyAUsersLoading,
                isSearchable: true,
                isClearable: false,
              }}
              multiple
            />

            <Field
              className="col-sm-6 col-md-4 col-lg-4 col-xl-3"
              field="dropdown"
              label="Signatories"
              placeholder="Choose signatories"
              autoComplete="off"
              register={register('signatories')}
              control={control}
              formSchema={formSchema}
              errors={formState.errors}
              options={signatoryPartyAOptions}
              dropdownProps={{
                isLoading: isPartyAUsersLoading,
                isSearchable: true,
                isClearable: false,
              }}
              multiple
            />
          </div>
        </div>
        <footer className="d-flex flex-wrap justify-content-end gap-3">
          <Link
            className="btn btn-outline-primary"
            to={{ pathname: RoutePath.dealRotorList, search: dealRotorListPageUrlSearch }}
          >
            Cancel
          </Link>
          <LoadingButton type="submit" className="btn btn-primary text-nowrap" isLoading={formState.isSubmitting}>
            Create and continue
          </LoadingButton>
          <ServerErrorMessages className="w-100" messages={serverErrorMessages} />
        </footer>
      </form>
    </section>
  );
}

export default DealRotorCreateTemplateAndRoles;
