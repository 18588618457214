import { useMemo } from 'react';
import { useQuery, keepPreviousData } from '@tanstack/react-query';

import { getUserListSummary } from 'src/api/base/user';

import { RoleName, SelectOption } from 'src/types';

function usePartyAOptionsByRoles() {
  const {
    isLoading: isPartyAUsersLoading,
    data: partyAUsers,
    error: partyAUsersError,
  } = useQuery({
    queryKey: ['getUserListSummary'],
    async queryFn({ signal }) {
      const config = { signal };
      const res = await getUserListSummary(config);
      return res.data;
    },
    initialData: [],
    placeholderData: keepPreviousData,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const {
    dealRotorOwnerOptions,
    operationsOwnerOptions,
    dealRotorManagerOptions,
    operationsManagerOptions,
    signatoryPartyAOptions,
  } = useMemo(() => {
    const dealRotorOwnerOptions: SelectOption[] = [];
    const operationsOwnerOptions: SelectOption[] = [];
    const dealRotorManagerOptions: SelectOption[] = [];
    const operationsManagerOptions: SelectOption[] = [];
    const signatoryPartyAOptions: SelectOption[] = [];

    partyAUsers.forEach((user) => {
      const option = { label: `${user.firstName} ${user.lastName}`, value: user.id };
      user.roles?.forEach((userRole) => {
        switch (userRole) {
          case RoleName.DealRotorOwner:
            dealRotorOwnerOptions.push(option);
            break;
          case RoleName.OperationsOwner:
            operationsOwnerOptions.push(option);
            break;
          case RoleName.DealRotorManager:
            dealRotorManagerOptions.push(option);
            break;
          case RoleName.OperationsManager:
            operationsManagerOptions.push(option);
            break;
          case RoleName.Signatory:
            signatoryPartyAOptions.push(option);
            break;
          default:
        }
      });
    });

    return {
      dealRotorOwnerOptions,
      operationsOwnerOptions,
      dealRotorManagerOptions,
      operationsManagerOptions,
      signatoryPartyAOptions,
    };
  }, [partyAUsers]);

  return {
    isPartyAUsersLoading,
    partyAUsers,
    partyAUsersError,
    dealRotorOwnerOptions,
    operationsOwnerOptions,
    dealRotorManagerOptions,
    operationsManagerOptions,
    signatoryPartyAOptions,
  };
}

export default usePartyAOptionsByRoles;
