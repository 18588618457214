import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { useForm } from 'react-hook-form';
import { object, string, ref } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Field from 'src/components/forms/Field';
import LoadingButton from 'src/components/buttons/LoadingButton';
import ServerErrorMessages from 'src/components/ServerErrorMessages';

import { loginUserRequest } from 'src/hocs/Auth';

import { useFormErrors } from 'src/hooks/FormHelpers';
import useMFA from '../hooks/useMFA';

import { strongPasswordReg } from 'src/utils/constants';

import { userConfirmEmail } from 'src/api/auth/user';

import { ConfirmEmailModel, YupFormShape, ServerError } from 'src/types';

type Props = {
  email: string;
  token: string;
};

type FormData = Omit<ConfirmEmailModel, 'token'> & {
  passwordConfirmation: string;
};
type FormShape = YupFormShape<FormData>;

const formSchema = object().shape<FormShape>({
  email: string().label('Email').nullable().trim().lowercase().required().email(),
  password: string()
    .label('Password')
    .trim()
    .required()
    .min(8)
    .matches(
      strongPasswordReg,
      ({ label }) =>
        `${label} should contain at least an uppercase letter, lowercase letter, number and special character`,
    ),
  passwordConfirmation: string()
    .label('Password')
    .trim()
    .required()
    .oneOf([ref('password'), ''], 'Passwords should be the same'),
});

function SignUpForm({ email, token }: Props) {
  const { handleLoginStatus } = useMFA();

  const { register, control, handleSubmit, formState, setValue, setError, clearErrors } = useForm<FormData>({
    resolver: yupResolver(formSchema),
  });
  const { serverErrorMessages, handleErrors } = useFormErrors<FormData>(setError, clearErrors);

  const onSubmit = handleSubmit(async ({ email, password }) => {
    try {
      handleErrors();
      await userConfirmEmail({ email, password, token });
      const { status, secret } = await loginUserRequest(email, password, null);
      handleLoginStatus({ status, secret, email, password });
    } catch (e) {
      handleErrors(e as ServerError);
    }
  });

  useEffect(() => {
    setValue('email', email);
  }, [email, setValue]);

  return (
    <form name="signUpForm" onSubmit={onSubmit} noValidate>
      <div className="mb-3">
        <Field
          label="Email address"
          field="input"
          type="email"
          placeholder="Enter your email"
          autoComplete="email"
          register={register('email')}
          control={control}
          formSchema={formSchema}
          errors={formState.errors}
          tabIndex={-1}
          readOnly
          disabled
        />
      </div>
      <div className="mb-3">
        <Field
          label="New password"
          field="input"
          type="password"
          placeholder="Enter your new password"
          autoComplete="new-password"
          register={register('password')}
          control={control}
          formSchema={formSchema}
          errors={formState.errors}
          autoFocus
        />
      </div>

      <div className="mb-3">
        <Field
          label="Confirm new password"
          field="input"
          type="password"
          placeholder="Confirm your new password"
          autoComplete="off"
          register={register('passwordConfirmation')}
          control={control}
          formSchema={formSchema}
          errors={formState.errors}
        />
      </div>

      <div className="text-center mb-4">
        <LoadingButton type="submit" className="btn btn-primary" isLoading={formState.isSubmitting}>
          Confirm and set MFA
        </LoadingButton>
      </div>

      <div className="text-center fs-14 fw-medium mb-4">
        <Link to={RoutePath.login}>Back to Sign In</Link>
      </div>

      <ServerErrorMessages messages={serverErrorMessages} />
    </form>
  );
}

export default SignUpForm;
