import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/store';

import { DEFAULT_LOGO_URL } from 'src/utils/constants';

export const tenantSliceSelector = (state: RootState) => state.tenant;

export const tenantPublicSettingsSelector = createSelector(tenantSliceSelector, (state) => state.tenantPublicSettings);
export const tenantSettingSelector = createSelector(tenantSliceSelector, (state) => state.tenantSetting);
export const tenantLogoUrlSelector = createSelector(
  tenantSliceSelector,
  (state) => state.tenantPublicSettings.logoUrl || DEFAULT_LOGO_URL,
);
export const tenantTimezoneSelector = createSelector(tenantSettingSelector, (state) => state?.timezone);
export const tenantTimezoneInBracketsSelector = createSelector(tenantTimezoneSelector, (tz) => (tz ? ` (${tz})` : ''));

export const tenantTimezoneOffsetSecondsSelector = createSelector(
  tenantSettingSelector,
  (state) => state?.timezoneOffsetSeconds ?? 0,
);
