import { useMemo, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useParams, Params } from 'react-router-dom';

import { useSelector } from 'src/store';
import { tenantTimezoneInBracketsSelector } from 'src/store/selectors/tenantSelector';

import { useForm } from 'react-hook-form';
import { object, string, date, DateSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Field from 'src/components/forms/Field';
import LoadingButton from 'src/components/buttons/LoadingButton';
import ServerErrorMessages from 'src/components/ServerErrorMessages';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import useTenantTimezone from 'src/hooks/useTenantTimezone';
import { useFormErrors, useFormHasChanges } from 'src/hooks/FormHelpers';

import Converter from 'src/utils/Converter';
import LocalDate from 'src/utils/LocalDate';

import { updateAgreement } from 'src/api/base/agreement';

import {
  DealRotorDto,
  AgreementDto,
  AgreementType,
  UpdateAgreementDetailsModel,
  AgreementEndDateModification,
  YupFormShape,
  ServerError,
} from 'src/types';
import { InfoIcon } from 'src/components/icons';

type Props = {
  dealRotor: DealRotorDto;
  dealRotorAgreement: AgreementDto;
  isAgreementEditDisabled: boolean;
};

type FormData = UpdateAgreementDetailsModel;
type FormShape = YupFormShape<FormData>;

function AgreementDetailsForm({ dealRotor, dealRotorAgreement, isAgreementEditDisabled }: Props) {
  const queryClient = useQueryClient();
  const { dealRotorId = '' } = useParams<Params<'dealRotorId'>>();
  const tenantTimezoneText = useSelector(tenantTimezoneInBracketsSelector);
  const { tenantTimezoneDateNow } = useTenantTimezone();

  const startDateMinDateStartDay = useMemo(
    () => (dealRotor.startDate ? new LocalDate(dealRotor.startDate).getStartDay().value : null),
    [dealRotor.startDate],
  );

  const formSchema = useMemo(
    () =>
      object().shape<FormShape>({
        startDate: date()
          .label('Date from')
          .nullable()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .min(startDateMinDateStartDay, ({ label, min }) => {
            const startDateFormatted = Converter.getFormattedDate(new LocalDate(min).toJSON());
            return `${label} field must be ${startDateFormatted} or later`;
          })
          .required(),
        endDate: date()
          .label('Date to')
          .nullable()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .when('startDate', ([value]: Array<string | Date | undefined | null>, scheme: DateSchema<any>) => {
            const todayStartDay = new LocalDate(tenantTimezoneDateNow).getStartDay().value;
            let startDate = todayStartDay;
            if (value) {
              const dateFromStartDay = new LocalDate(value).getStartDay().value;
              startDate = new Date(Math.max(startDate.getTime(), dateFromStartDay.getTime()));
            }
            return scheme.min(startDate, ({ label, min }) => {
              const startDateFormatted = Converter.getFormattedDate(new LocalDate(min).toJSON());
              return `${label} field must be ${startDateFormatted} or later`;
            });
          })
          .required(),
        description: string().label('Description').trim().max(210),
      }),
    [startDateMinDateStartDay, tenantTimezoneDateNow],
  );

  const { register, control, handleSubmit, formState, setValue, setError, clearErrors, watch } = useForm<FormData>({
    resolver: yupResolver(formSchema),
  });
  const { serverErrorMessages, handleErrors } = useFormErrors<FormData>(setError, clearErrors);
  const startDate = watch('startDate');
  const endDate = watch('endDate');
  const startDateMaxDate = endDate ? new LocalDate(endDate).getEndDay().value : null;

  const onSubmit = handleSubmit(async (formData) => {
    try {
      handleErrors();
      const startDate = new LocalDate(formData.startDate).getStartDay().toJSON();
      const endDate = new LocalDate(formData.endDate).getEndDay().toJSON();
      await updateAgreement(dealRotorAgreement.id, { ...formData, startDate, endDate });

      await Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: ['getDealRotorById', dealRotorId] }),
        queryClient.invalidateQueries({ queryKey: ['getAgreementList', dealRotorId] }),
        queryClient.invalidateQueries({ queryKey: ['getDealRotorAuditListSearch', dealRotorId] }),
      ]);
      queryClient.removeQueries({ queryKey: ['getDealRotorListSearch'] });
      queryClient.removeQueries({ queryKey: ['getDealRotorAuditListAll'] });
    } catch (e) {
      handleErrors(e as ServerError);
    }
  });

  const dealRotorAgreementPrefill = useMemo(
    () => ({
      startDate: dealRotorAgreement.startDate ?? '',
      endDate: dealRotorAgreement.endDate ?? '',
      description: dealRotorAgreement.description || '',
    }),
    [dealRotorAgreement],
  );

  const hasChanges = useFormHasChanges(watch, dealRotorAgreementPrefill);

  useEffect(() => {
    setValue('startDate', dealRotorAgreementPrefill.startDate);
    setValue('endDate', dealRotorAgreementPrefill.endDate);
    setValue('description', dealRotorAgreementPrefill.description);
  }, [dealRotorAgreementPrefill, setValue]);

  const dateFromLabel = () => {
    return (
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip>
            Agreements Start date relates Activated from date of Deal rotor. If Agreements Start date should be moved to
            the past, please change Activated from date on Deal rotor details tab
          </Tooltip>
        }
      >
        <InfoIcon className="text-primary" />
      </OverlayTrigger>
    );
  };

  return (
    <form name="agreementDetailsForm" onSubmit={onSubmit} noValidate>
      <div className="row g-3 mb-3">
        <div className="col-md-6 col-xxl-8">
          <div className="row g-3">
            <Field
              className="col-sm-12 col-xxl-6"
              field="datepicker"
              label={`Date from${tenantTimezoneText}`}
              autoComplete="off"
              register={register('startDate')}
              control={control}
              formSchema={formSchema}
              errors={formState.errors}
              datepickerProps={{ minDate: startDateMinDateStartDay, maxDate: startDateMaxDate }}
              disabled={
                isAgreementEditDisabled ||
                (dealRotorAgreement.type === AgreementType.Addendum &&
                  dealRotorAgreement.endDateModification === AgreementEndDateModification.WithModification)
              }
              AfterLabel={dateFromLabel()}
            />

            <Field
              className="col-sm-12 col-xxl-6"
              field="datepicker"
              label={`Date to${tenantTimezoneText}`}
              autoComplete="off"
              register={register('endDate')}
              control={control}
              formSchema={formSchema}
              errors={formState.errors}
              datepickerProps={{
                minDate: startDate
                  ? new Date(Math.max(new Date(startDate).getTime(), tenantTimezoneDateNow.getTime()))
                  : tenantTimezoneDateNow,
              }}
              disabled={
                isAgreementEditDisabled ||
                (dealRotorAgreement.type === AgreementType.Addendum &&
                  dealRotorAgreement.endDateModification === AgreementEndDateModification.WithoutModification)
              }
            />
          </div>
        </div>
        <Field
          className="col-md-6 col-xxl-4"
          field="textarea"
          label="Description"
          placeholder="Enter description"
          autoComplete="off"
          max={210}
          register={register('description')}
          control={control}
          formSchema={formSchema}
          errors={formState.errors}
          After={<div className="text-end text-secondary fs-14 pt-1">Maximum 210 characters allowed</div>}
          disabled={isAgreementEditDisabled}
        />
      </div>

      <div className="d-flex flex-wrap justify-content-end gap-3">
        <LoadingButton
          type="submit"
          className="btn btn-primary text-nowrap"
          disabled={isAgreementEditDisabled || !hasChanges}
          isLoading={formState.isSubmitting}
        >
          Save
        </LoadingButton>
        <ServerErrorMessages className="w-100" messages={serverErrorMessages} />
      </div>
    </form>
  );
}

export default AgreementDetailsForm;
