import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { Control, FieldErrors, UseFormRegister, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, array, string, boolean } from 'yup';

import Field from 'src/components/forms/Field';
import LoadingButton from 'src/components/buttons/LoadingButton';
import ServerErrorMessages from 'src/components/ServerErrorMessages';

import { useFormErrors } from 'src/hooks/FormHelpers';

import { createOrganisationWithContacts } from 'src/api/base/organisation';

import {
  CreateOrganisationWithContactsModel,
  GetSFOrganisationResponse,
  GetSFContactResponse,
  CreateContactModel,
  YupFormShape,
  ServerError,
} from 'src/types';

import Converter from 'src/utils/Converter';

type Props = {
  organisationContacts: GetSFOrganisationResponse[];
};

type FormDataContacts = CreateContactModel & {
  orgIndex: string;
  isInclude: boolean;
};
type FormDataOrgs = Omit<CreateOrganisationWithContactsModel, 'contacts'> & {
  isInclude: boolean;
  displayInitials: string;
  email: string;
  contacts: FormDataContacts[] | null;
};
type FormData = { orgs: FormDataOrgs[] };

type FormShapeContacts = YupFormShape<FormDataContacts>;
type FormShapeOrgs = YupFormShape<FormDataOrgs>;
type FormShape = YupFormShape<FormData>;

let prevOrgFromValidator: FormDataOrgs[] | undefined;
function isContactFieldRequired(
  orgs: FormDataOrgs[],
  orgIndex: string | undefined,
  isInclude: boolean | string | undefined,
) {
  if (orgs) {
    prevOrgFromValidator = orgs;
  }

  if (typeof orgs === 'undefined' && typeof orgIndex === 'undefined' && typeof isInclude === 'undefined') {
    return true;
  } else {
    const orgIndexNum = typeof orgIndex === 'string' ? parseInt(orgIndex) : NaN;
    if (isNaN(orgIndexNum)) {
      return false;
    } else {
      const isIncludeOrg = (orgs || prevOrgFromValidator)?.[orgIndexNum]?.isInclude;
      return isIncludeOrg && isInclude;
    }
  }
}

const formSchema = object().shape<FormShape>({
  orgs: array().of(
    object().shape<FormShapeOrgs>({
      isInclude: boolean(),
      externalId: string().label('Company external id').trim().nullable(),
      name: string()
        .label('Company name')
        .trim()
        .nullable()
        .when('isInclude', {
          is: (isInclude: boolean | string | undefined) => isInclude === true || isInclude === 'true',
          then: (schema) => schema.required(),
        }),
      displayInitials: string().label('Company display initials').trim().uppercase().max(2).nullable(),
      theme: string().label('Company identity color').trim().nullable(),
      country: string().label('Company country').trim().nullable(),
      town: string().label('Company town/city').trim().nullable(),
      addressLine: string().label('Company street address').trim().nullable(),
      state: string().label('Company county/state').trim().nullable(),
      postCode: string().label('Company post code').trim().nullable(),
      email: string().label('Company email').trim().email().nullable(),
      phone: string().label('Company phone number').trim().nullable(),
      contacts: array()
        .of(
          object().shape<FormShapeContacts>({
            orgIndex: string(),
            isInclude: boolean(),
            externalId: string().label('External id').trim().nullable(),
            email: string()
              .label('Email')
              .trim()
              .nullable()
              .when(['$orgs', 'orgIndex', 'isInclude'], {
                is: isContactFieldRequired,
                then: (schema) => schema.email().required(),
              }),
            firstName: string()
              .label('First name')
              .trim()
              .nullable()
              .when(['$orgs', 'orgIndex', 'isInclude'], {
                is: isContactFieldRequired,
                then: (schema) => schema.required(),
              }),
            lastName: string()
              .label('Last name')
              .trim()
              .nullable()
              .when(['$orgs', 'orgIndex', 'isInclude'], {
                is: isContactFieldRequired,
                then: (schema) => schema.required(),
              }),
            title: string()
              .label('Job title')
              .trim()
              .nullable()
              .when(['$orgs', 'orgIndex', 'isInclude'], {
                is: isContactFieldRequired,
                then: (schema) => schema.required(),
              }),
            phone: string().label('Phone number').trim().nullable(),
            mobile: string().label('Mobile number').trim().nullable(),
          }),
        )
        .nullable(),
    }),
  ),
});

type OrganisationContactProps = {
  register: UseFormRegister<FormData>;
  control: Control<FormData, any>;
  formStateErrors: FieldErrors<FormData>;
  contact: GetSFContactResponse;
  orgIndex: number;
  contactIndex: number;
};

function OrganisationContact({
  register,
  control,
  formStateErrors,
  contact,
  orgIndex,
  contactIndex,
}: OrganisationContactProps) {
  const isIncludeContact = useWatch({ control, name: `orgs.${orgIndex}.contacts.${contactIndex}.isInclude` }) ?? true;
  return (
    <div className="mt-3">
      <div className="hstack">
        <Field
          labelClassName="text-secondary fw-medium"
          formCheckClassName="form-check-reverse"
          field="switch"
          label={`Contact ${contactIndex + 1}`}
          defaultChecked
          autoComplete="off"
          register={register(`orgs.${orgIndex}.contacts.${contactIndex}.isInclude`)}
          control={control}
          formSchema={formSchema}
          errors={formStateErrors}
        />
      </div>

      <div hidden={!isIncludeContact} className="mt-3">
        <input type="hidden" value={orgIndex} {...register(`orgs.${orgIndex}.contacts.${contactIndex}.orgIndex`)} />
        <input
          type="hidden"
          defaultValue={contact.externalId || ''}
          {...register(`orgs.${orgIndex}.contacts.${contactIndex}.externalId`)}
        />
        <div className="row g-3 mb-3">
          <Field
            className="col-md-4 col-lg-3"
            label="Email"
            field="input"
            type="email"
            placeholder="Enter email"
            autoComplete="off"
            register={register(`orgs.${orgIndex}.contacts.${contactIndex}.email`)}
            defaultValue={contact.email || ''}
            control={control}
            formSchema={formSchema}
            errors={formStateErrors}
          />
          <Field
            className="col-md-4 col-lg-3"
            label="First name"
            field="input"
            type="text"
            placeholder="Enter first name"
            autoComplete="off"
            register={register(`orgs.${orgIndex}.contacts.${contactIndex}.firstName`)}
            defaultValue={contact.firstName || ''}
            control={control}
            formSchema={formSchema}
            errors={formStateErrors}
          />
          <Field
            className="col-md-4 col-lg-3"
            label="Last name"
            field="input"
            type="text"
            placeholder="Enter last name"
            autoComplete="off"
            register={register(`orgs.${orgIndex}.contacts.${contactIndex}.lastName`)}
            defaultValue={contact.lastName || ''}
            control={control}
            formSchema={formSchema}
            errors={formStateErrors}
          />
        </div>
        <div className="row g-3">
          <Field
            className="col-md-4 col-lg-3"
            label="Job title"
            field="input"
            type="text"
            placeholder="Enter job title"
            autoComplete="off"
            register={register(`orgs.${orgIndex}.contacts.${contactIndex}.title`)}
            defaultValue={contact.title || ''}
            control={control}
            formSchema={formSchema}
            errors={formStateErrors}
          />
          <Field
            className="col-md-4  col-lg-3"
            label="Phone number"
            field="input"
            type="text"
            placeholder="Enter phone number"
            autoComplete="off"
            register={register(`orgs.${orgIndex}.contacts.${contactIndex}.phone`)}
            defaultValue={contact.phone || ''}
            control={control}
            formSchema={formSchema}
            errors={formStateErrors}
          />
          <Field
            className="col-md-4 mb-2 col-lg-3"
            label="Mobile number"
            field="input"
            type="text"
            placeholder="Enter mobile number"
            autoComplete="off"
            register={register(`orgs.${orgIndex}.contacts.${contactIndex}.mobile`)}
            defaultValue={contact.mobile || ''}
            control={control}
            formSchema={formSchema}
            errors={formStateErrors}
          />
        </div>
      </div>
    </div>
  );
}

type OrganisationProps = {
  register: UseFormRegister<FormData>;
  control: Control<FormData, any>;
  formStateErrors: FieldErrors<FormData>;
  organisation: GetSFOrganisationResponse;
  orgIndex: number;
};

function Organisation({ register, control, formStateErrors, organisation, orgIndex }: OrganisationProps) {
  const displayInitials = organisation.name ? Converter.getInitials(organisation.name) : '';
  const isIncludeOrg = useWatch({ control, name: `orgs.${orgIndex}.isInclude` }) ?? true;
  return (
    <div className="card">
      <div className="d-flex px-4 py-3">
        <Field
          labelClassName="fw-medium"
          formCheckClassName="form-check-reverse"
          field="switch"
          label={organisation.name || 'N/A'}
          autoComplete="off"
          register={register(`orgs.${orgIndex}.isInclude`)}
          defaultChecked
          control={control}
          formSchema={formSchema}
          errors={formStateErrors}
        />
      </div>

      <div hidden={!isIncludeOrg} className="border-top px-4 py-3">
        <input
          type="hidden"
          defaultValue={organisation.externalId || ''}
          {...register(`orgs.${orgIndex}.externalId`)}
        />
        <div className="row g-3 mb-3">
          <Field
            className="col-sm-12 col-md-12 col-lg-3"
            label="Company name"
            field="input"
            type="text"
            placeholder="Enter company name"
            autoComplete="off"
            register={register(`orgs.${orgIndex}.name`)}
            defaultValue={organisation.name || ''}
            control={control}
            formSchema={formSchema}
            errors={formStateErrors}
          />
          <Field
            className="col-sm-12 col-md-6 col-lg-3"
            label="Company display initials"
            field="input"
            type="text"
            placeholder="Enter company display initials"
            autoComplete="off"
            register={register(`orgs.${orgIndex}.displayInitials`)}
            defaultValue={displayInitials}
            control={control}
            formSchema={formSchema}
            errors={formStateErrors}
          />
          <Field
            className="col-sm-12 col-md-6 col-lg-3"
            label="Company country"
            field="input"
            type="text"
            placeholder="Enter company country"
            autoComplete="off"
            register={register(`orgs.${orgIndex}.country`)}
            defaultValue={organisation.country || ''}
            control={control}
            formSchema={formSchema}
            errors={formStateErrors}
          />
        </div>
        <div className="row g-3 mb-3">
          <Field
            className="col-sm-12 col-md-6 col-lg-3"
            label="Company county/state"
            field="input"
            type="text"
            placeholder="Enter company county/state"
            autoComplete="off"
            register={register(`orgs.${orgIndex}.state`)}
            defaultValue={organisation.state || ''}
            control={control}
            formSchema={formSchema}
            errors={formStateErrors}
          />
          <Field
            className="col-sm-12 col-md-6 col-lg-3"
            label="Company town/city"
            field="input"
            type="text"
            placeholder="Enter company town/city"
            autoComplete="off"
            register={register(`orgs.${orgIndex}.town`)}
            defaultValue={organisation.town || ''}
            control={control}
            formSchema={formSchema}
            errors={formStateErrors}
          />
          <Field
            className="col-sm-12 col-md-12 col-lg-3"
            label="Company street address"
            field="input"
            type="text"
            placeholder="Enter company street address"
            autoComplete="off"
            register={register(`orgs.${orgIndex}.addressLine`)}
            defaultValue={organisation.addressLine || ''}
            control={control}
            formSchema={formSchema}
            errors={formStateErrors}
          />
        </div>
        <div className="row g-3 mb-4">
          <Field
            className="col-sm-12 col-md-6 col-lg-3"
            label="Company post code"
            field="input"
            type="text"
            placeholder="Enter company post code"
            autoComplete="off"
            register={register(`orgs.${orgIndex}.postCode`)}
            defaultValue={organisation.postCode || ''}
            control={control}
            formSchema={formSchema}
            errors={formStateErrors}
          />
          <Field
            className="col-sm-12 col-md-6 col-lg-3"
            label="Company email"
            field="input"
            type="email"
            placeholder="Enter company email"
            autoComplete="off"
            register={register(`orgs.${orgIndex}.email`)}
            defaultValue=""
            control={control}
            formSchema={formSchema}
            errors={formStateErrors}
          />
          <Field
            className="col-sm-12 col-md-6 col-lg-3"
            label="Company phone number"
            field="input"
            type="text"
            placeholder="Enter company phone number"
            autoComplete="off"
            register={register(`orgs.${orgIndex}.phone`)}
            defaultValue={organisation.phone || ''}
            control={control}
            formSchema={formSchema}
            errors={formStateErrors}
          />
        </div>

        {organisation.contacts && (
          <>
            <hr />
            {organisation.contacts.length ? (
              organisation.contacts.map((contact, contactIndex) => {
                return (
                  <OrganisationContact
                    register={register}
                    control={control}
                    formStateErrors={formStateErrors}
                    contact={contact}
                    orgIndex={orgIndex}
                    contactIndex={contactIndex}
                    key={contact.externalId || `${orgIndex}_${contactIndex}`}
                  />
                );
              })
            ) : (
              <div className="text-secondary">No contacts</div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

function IntegrationSalesForcePartyBForm({ organisationContacts }: Props) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { register, control, handleSubmit, formState, setError, clearErrors } = useForm<FormData>({
    resolver: yupResolver(formSchema),
  });
  const { serverErrorMessages, handleErrors } = useFormErrors<FormData>(setError, clearErrors);

  const onSubmit = handleSubmit(async ({ orgs }) => {
    handleErrors();
    try {
      const payload = orgs.reduce<CreateOrganisationWithContactsModel[]>(
        (acc, { isInclude: isIncludeOrg, contacts, ...org }) => {
          if (isIncludeOrg) {
            const filteredContacts = contacts
              ? contacts.reduce<CreateContactModel[]>(
                  (acc, { isInclude: isIncludeContact, ...contact }) =>
                    isIncludeContact
                      ? acc.concat({
                          externalId: contact.externalId || null,
                          firstName: contact.firstName || null,
                          lastName: contact.lastName || null,
                          email: contact.email || null,
                          title: contact.title || null,
                          phone: contact.phone || null,
                          mobile: contact.mobile || null,
                        })
                      : acc,
                  [],
                )
              : [];
            return acc.concat({
              ...org,
              externalId: org.externalId || null,
              displayInitials: org.displayInitials || null,
              addressLine: org.addressLine || null,
              state: org.state || null,
              town: org.town || null,
              country: org.country || null,
              postCode: org.postCode || null,
              email: org.email || null,
              phone: org.phone || null,
              theme: org.theme || null,
              contacts: filteredContacts.length ? filteredContacts : null,
            });
          }
          return acc;
        },
        [],
      );

      await createOrganisationWithContacts(payload);

      queryClient.removeQueries({ queryKey: ['getOrganisationListSearch'] });

      navigate(RoutePath.partyBManagementList);
    } catch (e) {
      handleErrors(e as ServerError);
    }
  });

  return (
    <form className="vstack gap-3" name="salesforceOrganisationContactsForm" onSubmit={onSubmit} noValidate>
      {organisationContacts.map((organisation, orgIndex) => {
        return (
          <Organisation
            register={register}
            control={control}
            organisation={organisation}
            orgIndex={orgIndex}
            formStateErrors={formState.errors}
            key={organisation.externalId || orgIndex}
          />
        );
      })}
      <div className="d-flex justify-content-end">
        <LoadingButton className="btn btn-primary" type="submit" isLoading={formState.isSubmitting}>
          Import selected
        </LoadingButton>
      </div>
      <ServerErrorMessages className="mb-0" messages={serverErrorMessages} />
    </form>
  );
}

export default IntegrationSalesForcePartyBForm;
