import classNames from 'classnames';

import styles from './Footer.module.scss';

const year = new Date().getFullYear();

function Footer() {
  return (
    <footer
      className={classNames(
        styles.Footer,
        'd-flex align-items-center justify-content-between flex-wrap border-top fs-12 py-4 py-sm-0',
      )}
    >
      <div className="flex-grow-1 flex-md-grow-0 text-center mb-3 mb-sm-0">
        <span>&copy; {year}</span>
        <span className="mx-2">All rights reserved to BridgingX</span>
        <a href="/files/End_User_Licence_Agreement.pdf" target="_blank" className="me-2">
          End user licence agreement
        </a>
        <a href="/files/Privacy_Notice.pdf" target="_blank">
          Privacy notice
        </a>
      </div>
      <div className="flex-grow-1 flex-md-grow-0 text-center">
        <a href="https://bridgingx.com/" target="_blank" rel="noopener noreferrer">
          <img className={styles.Logo} src="/images/logo/app-logo.png" alt="bridgingx logo" height="12" width="149" />
        </a>
      </div>
    </footer>
  );
}

export default Footer;
