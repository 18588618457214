import { BusinessIcon } from 'src/components/icons';

type TenantLogoProps = { logo: string | null; name: string };

const TenantLogo = ({ name, logo }: TenantLogoProps) =>
  !logo ? (
    <BusinessIcon className="text-primary" width={24} height={24} />
  ) : (
    <img className="w-100" src={logo} alt={name} />
  );

export default TenantLogo;
