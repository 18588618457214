import { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';

import { object, string, array } from 'yup';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { useForm, useFieldArray, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useOutletContext } from 'react-router-dom';

import Field from 'src/components/forms/Field';
import ServerErrorMessages from 'src/components/ServerErrorMessages';

import { useFormErrors } from 'src/hooks/FormHelpers';

import { envTenant } from 'src/utils/domain';
import ServerErrorAdapter from 'src/utils/ServerErrorAdapter';

import { DealRotorEditDetailedTabsOutletContext } from 'src/pages/deal-rotor/DealRotorEdit.detailed.tabs';
import PreviewTemplate from 'src/pages/deal-rotor/components/dealRotorEdit/AgreementDetails.upload-template-preview';

import { getAllTemplates } from 'src/api/base/template';

import { SelectOption, YupFormShape, ServerError, TemplateRenderedParamModel, UUIDString } from 'src/types';

type UploadTemplateProps = {
  dealRotorAgreementId: UUIDString;
  isShowTemplateUpload: boolean;
  toggleShowTemplateUpload: () => void;
};

type FormParameters = {
  id: string;
  name: string;
  value: string | null;
};

type FormData = {
  name: string;
  templateId: string;
  parameters: FormParameters[];
};

type FormShape = YupFormShape<FormData>;
type ParametersShape = YupFormShape<FormParameters>;

const formSchema = object().shape<FormShape>({
  name: string().label('Document name').required(),
  templateId: string().label('Template').required(),
  parameters: array()
    .of(
      object().shape<ParametersShape>({
        id: string(),
        name: string(),
        value: string().label('Parameter').required(),
      }),
    )
    .label('Parameters'),
});

const UploadTemplate = ({
  isShowTemplateUpload,
  toggleShowTemplateUpload,
  dealRotorAgreementId,
}: UploadTemplateProps) => {
  const { dealRotor } = useOutletContext<DealRotorEditDetailedTabsOutletContext>();
  const [isShowPreview, setIsShowPreview] = useState(false);
  const [payloadParams, setPayloadParams] = useState<TemplateRenderedParamModel[]>([]);
  const [documentName, setDocumentName] = useState('');
  const { register, control, formState, handleSubmit, setError, clearErrors, setValue } = useForm<FormData>({
    resolver: yupResolver(formSchema),
  });

  const { fields } = useFieldArray({
    control,
    name: 'parameters',
  });

  const templateIdField = useWatch({
    control,
    name: 'templateId',
  });

  const { serverErrorMessages, setServerErrorMessages, handleErrors } = useFormErrors<FormData>(setError, clearErrors);

  const onSubmit = handleSubmit((formData) => {
    if (!currentFormTemplate) return;
    const preparePayload = () => {
      const payloadParameters = currentFormTemplate.templateParameters
        .filter((parameter) => !parameter.templateParam.isBridgingXParameter)
        .map((parameter) => {
          const editedParam = formData.parameters.find((param) => param.id === parameter.templateParamId);
          return editedParam
            ? {
                ...parameter.templateParam,
                value: editedParam.value,
              }
            : parameter.templateParam;
        });
      return payloadParameters;
    };

    handleErrors();
    try {
      const payload = preparePayload().filter((parameter) => parameter.value !== null) as TemplateRenderedParamModel[];
      setPayloadParams(payload);
      setIsShowPreview(true);
      setDocumentName(formData.name);
    } catch (error) {
      const serverErrors = new ServerErrorAdapter(error as ServerError);
      setServerErrorMessages(serverErrors.combine());
    }
  });

  const {
    data: templatesList,
    error: templatesListError,
    isLoading: templatesListIsLoading,
  } = useQuery({
    queryKey: ['getAllTemplates', isShowTemplateUpload],
    async queryFn({ signal }) {
      const config = { signal };
      const res = await getAllTemplates(config);
      return res.data;
    },
    initialData: [],
    placeholderData: keepPreviousData,
    retry: false,
    refetchOnWindowFocus: false,
    enabled: isShowTemplateUpload,
  });

  const templateOptions: SelectOption[] = useMemo(() => {
    return templatesList.map((template) => {
      return {
        label: template.name,
        value: template.id,
      };
    });
  }, [templatesList]);

  const currentFormTemplate = useMemo(() => {
    const currentTemplate = templatesList.find((template) => template.id === templateIdField);
    return currentTemplate;
  }, [templateIdField, templatesList]);

  const currentFormParameters = useMemo(() => {
    const currentParameters = currentFormTemplate
      ? currentFormTemplate.templateParameters
          .filter((parameter) => !parameter.templateParam.isBridgingXParameter)
          .map((parameter) => {
            return {
              id: parameter.templateParam.id,
              name: parameter.templateParam.name,
              value: parameter.templateParam.value,
            };
          })
      : [];
    return currentParameters;
  }, [currentFormTemplate]);

  useEffect(() => {
    const serverErrors = new ServerErrorAdapter(templatesListError as ServerError);
    setServerErrorMessages(serverErrors.combine());
  }, [setServerErrorMessages, templatesListError]);

  useEffect(() => {
    if (isShowTemplateUpload && templateIdField) {
      const templateName = currentFormTemplate ? currentFormTemplate.name : '';
      const tenantName = envTenant;
      const dealRotorName = dealRotor?.name || '';
      const documentName = `${templateName}-${tenantName}-${dealRotorName}`;
      setValue('parameters', currentFormParameters);
      setValue('name', documentName);
    }
  }, [currentFormParameters, currentFormTemplate, dealRotor?.name, isShowTemplateUpload, setValue, templateIdField]);

  useEffect(() => {
    if (templateOptions.length) {
      setValue('templateId', templateOptions[0].value);
    }
  }, [setValue, templateOptions]);

  return (
    <Modal show={isShowTemplateUpload} onHide={toggleShowTemplateUpload} centered keyboard>
      <Modal.Header onHide={toggleShowTemplateUpload} closeButton>
        <Modal.Title className="h5">Add from template</Modal.Title>
      </Modal.Header>
      <form name="agreementTemplateDocumentForm" onSubmit={onSubmit} noValidate>
        <Modal.Body>
          <div className="row">
            <Field
              className="col mb-2"
              label="Document name"
              field="input"
              type="text"
              placeholder="Enter document name"
              autoComplete="off"
              register={register('name')}
              control={control}
              formSchema={formSchema}
              errors={formState.errors}
            />
          </div>
          <div className="row mb-3">
            <Field
              className="col mb-2"
              field="dropdown"
              label="Template"
              placeholder="Select"
              autoComplete="off"
              register={register('templateId')}
              control={control}
              formSchema={formSchema}
              errors={formState.errors}
              options={templateOptions}
              dropdownProps={{
                isLoading: templatesListIsLoading,
                isSearchable: true,
                isClearable: false,
              }}
            />
          </div>
          <div>
            <p className="row px-3 mb-2">Parameters</p>
            {fields.map((group, index) => {
              return (
                <div className="row mb-2" key={group.id}>
                  <input type="hidden" {...register(`parameters.${index}.id`)} />
                  <Field
                    className="col"
                    label={group.name}
                    field="input"
                    type="text"
                    placeholder="Enter parameter value"
                    autoComplete="off"
                    register={register(`parameters.${index}.value`)}
                    control={control}
                    formSchema={formSchema}
                    errors={formState.errors}
                    autoFocus={index === 0}
                  />
                </div>
              );
            })}
          </div>
          <PreviewTemplate
            isOpenNested={isShowPreview}
            setIsOpenNested={setIsShowPreview}
            setIsOpenParent={toggleShowTemplateUpload}
            parameters={payloadParams}
            templateId={currentFormTemplate ? currentFormTemplate.id : ''}
            dealRotorAgreementId={dealRotorAgreementId}
            documentName={documentName}
          />
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-outline-primary" type="button" onClick={toggleShowTemplateUpload}>
            Cancel
          </button>
          <button className="btn btn-primary" type="submit">
            Preview
          </button>
        </Modal.Footer>
      </form>
      <ServerErrorMessages className="mx-3" messages={serverErrorMessages} />
    </Modal>
  );
};

export default UploadTemplate;
