import { useMemo } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { RoutePath } from 'src/router';

import Breadcrumbs from 'src/components/Breadcrumbs';
import TabNavigation from 'src/components/TabNavigation';

import PasswordPage from './UserProfileSettings.password';
import MFAPage from './UserProfileSettings.mfa';

const UserProfileSettings = () => {
  const location = useLocation();
  const breadcrumbs = useMemo(
    () => [
      { name: 'Home', to: RoutePath.root },
      { name: 'Settings', to: location },
    ],
    [location],
  );
  const tabs = useMemo(
    () => [
      { name: 'Change Password', to: 'password' },
      { name: 'Multi-Factor Authentication (MFA)', to: 'mfa' },
    ],
    [],
  );
  return (
    <div className="h-100">
      <Breadcrumbs className="mb-4" breadcrumbs={breadcrumbs} />
      <div className="bg-white border rounded">
        <header className="p-3 pt-4 py-3">
          <h1 className="h5 mb-0">Settings</h1>
        </header>
        <TabNavigation className="border-bottom px-3 pt-1" tabs={tabs} />
        <div className="p-3">
          <Routes>
            <Route index element={<Navigate to={tabs[0].to} replace={true} />} />

            <Route path="password" element={<PasswordPage />} />
            <Route path="mfa" element={<MFAPage />} />

            <Route path="*" element={<Navigate to="./" />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default UserProfileSettings;
