import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { RoutePath } from 'src/router';
import { useQueryClient } from '@tanstack/react-query';

import {
  useForm,
  useFieldArray,
  FieldArrayWithId,
  UseFormRegister,
  Control,
  UseFieldArrayRemove,
  FormState,
} from 'react-hook-form';
import { object, string, mixed, array, boolean } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Field from 'src/components/forms/Field';
import LoadingButton from 'src/components/buttons/LoadingButton';
import ServerErrorMessages from 'src/components/ServerErrorMessages';
import Avatar from 'src/components/Avatar';
import { InfoIcon } from 'src/components/icons';

import useUserPermission from 'src/hooks/useUserPermission';
import { useFormErrors } from 'src/hooks/FormHelpers';

import Converter from 'src/utils/Converter';
import ServerErrorAdapter from 'src/utils/ServerErrorAdapter';

import {
  uploadOrganisationLogo,
  createOrganisation,
  updateOrganisation,
  updateOrganisationStatus,
  deleteOrganisation,
} from 'src/api/base/organisation';
import {
  getExistPartyBUsers,
  createOrganisationUser,
  addExistUserToOrganisation,
  updateOrganisationUser,
  deleteOrganisationUser,
} from 'src/api/base/user';

import {
  YupFormShape,
  ServerError,
  SelectOption,
  GetOrganisationDto,
  OrganisationUserDto,
  UserPartyBSummaryDto,
  PermissionNames,
  ServerFormErrors,
  OrganisationAction,
  OrganisationStatus,
} from 'src/types';

import styles from './PartyBManagement.form.module.scss';
import './PartyBManagement.form.styles.scss';

type OrganisationUserFormData = {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  title: string;
  externalNumber: string;
  phone: string;
  mobile: string;
  isExistUser: boolean;
};
type DeleteContactModel = Pick<OrganisationUserFormData, 'userId' | 'firstName' | 'lastName'> & {
  organisationId: string;
};
type FormData = {
  logoFile: FileList;
  name: string;
  displayInitials: string;
  theme: string;
  country: string;
  town: string;
  addressLine: string;
  state: string;
  postCode: string;
  email: string;
  phone: string;
  users: OrganisationUserFormData[] | [];
};
type FormShape = YupFormShape<FormData>;
type UserShape = YupFormShape<OrganisationUserFormData>;
type PartyBManagementFormProps = {
  organisation: GetOrganisationDto | null | undefined;
  organisationError: unknown;
  organisationRefetch: () => Promise<any>;
  organisationId?: string | undefined;
  organisationExistUsers: UserPartyBSummaryDto[];
};
enum OrganisationColorTheme {
  CAPER = '#bccc85',
  BERMUDA = '#81D4BD',
  MALIBU = '#72B4E5',
  COLD_PURPLE = '#9D89C7',
  SWEET_CORN = '#EFE16C',
  ATOMIC_TANGERINE = '#EF9A6A',
  ALUMINIUM = '#798289',
}

const organisationActionColorMap: Record<OrganisationAction, 'primary' | 'warning' | 'danger'> = {
  [OrganisationAction.Activate]: 'primary',
  [OrganisationAction.Archive]: 'warning',
  [OrganisationAction.Delete]: 'danger',
};

const organisationColorOptions = Object.values(OrganisationColorTheme).map<SelectOption>((value) => ({
  value,
  label: '',
}));

const userDefaultValues: OrganisationUserFormData = {
  userId: '',
  firstName: '',
  lastName: '',
  email: '',
  title: '',
  externalNumber: '',
  phone: '',
  mobile: '',
  isExistUser: false,
};

const formSchema = object().shape<FormShape>({
  logoFile: mixed<FileList>().label('Company logo').fileExt(['.png']).fileSizeMax(30000000),
  name: string().label('Company name').trim().required(),
  displayInitials: string().label('Company display initials').trim().uppercase().max(2).nullable(),
  theme: string().label('Company identity color').trim().nullable(),
  country: string().label('Company country').trim().nullable(),
  town: string().label('Company town/city').trim().nullable(),
  addressLine: string().label('Company street address').trim().nullable(),
  state: string().label('Company county/state').trim().nullable(),
  postCode: string().label('Company post code').trim().nullable(),
  email: string().label('Company email').trim().email().nullable(),
  phone: string().label('Company phone number').trim().nullable(),
  users: array().of(
    object().shape<UserShape>({
      userId: string().trim(),
      firstName: string().label('First name').trim().required(),
      lastName: string().label('Last name').trim().required(),
      email: string().label('Email').trim().email().required(),
      title: string().label('Job title').trim().required(),
      externalNumber: string().label('External number').trim().nullable(),
      phone: string().label('Phone number').trim().nullable(),
      mobile: string().label('Mobile number').trim().nullable(),
      isExistUser: boolean(),
    }),
  ),
});

const getFieldArray = (users: OrganisationUserDto[]): OrganisationUserFormData[] => {
  if (users.length === 0) return [userDefaultValues];
  return users.map((user) => {
    const { id, firstName, lastName, email, title, externalNumber, phone, mobile } = user;
    return {
      userId: id || '',
      firstName: firstName || '',
      lastName: lastName || '',
      email: email || '',
      title: title || '',
      externalNumber: externalNumber || '',
      phone: phone || '',
      mobile: mobile || '',
      isExistUser: false,
    };
  });
};

const getLabel = (firstName: string, lastName: string, logoUrl: string | null, email: string) => {
  const fullName = `${firstName} ${lastName}`;
  return (
    <>
      <div className="d-flex py-1">
        <Avatar className="me-3 align-self-center" src={logoUrl} size={32} alt={fullName} />
        <div>
          <div>{fullName}</div>
          <div className="text-secondary fs-14">{email}</div>
        </div>
      </div>
    </>
  );
};

type DeleteContactModalProps = {
  user: DeleteContactModel | null;
  setUser: React.Dispatch<React.SetStateAction<DeleteContactModel | null>>;
  organisationRefetch: () => Promise<any>;
};

function DeleteContactModal({ user, setUser, organisationRefetch }: DeleteContactModalProps) {
  const [serverErrorMessages, setServerErrorMessages] = useState<ServerFormErrors>([]);

  useEffect(() => {
    setServerErrorMessages([]);
  }, [user]);

  if (!user) return;

  const userNameInTitle = user.firstName || user.lastName ? `"${user.firstName} ${user.lastName}"` : '';
  const title = `Are you sure you want to delete contact ${userNameInTitle}?`;
  const closeModal = () => setUser(null);

  const deleteContactModalRequest = async () => {
    try {
      const { userId, organisationId } = user;
      await deleteOrganisationUser(userId, organisationId);

      await organisationRefetch();
      closeModal();
    } catch (e) {
      const serverErrors = new ServerErrorAdapter(e as ServerError);
      setServerErrorMessages(serverErrors.combine());
    }
  };

  return (
    <Modal show={true} onHide={closeModal} size="lg" centered>
      <Modal.Header onHide={closeModal} closeButton>
        <Modal.Title className="h5">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <button type="button" className="btn btn-link" onClick={closeModal}>
          Cancel
        </button>
        <LoadingButton type="button" className="btn btn-danger" onClick={deleteContactModalRequest}>
          Delete contact
        </LoadingButton>
        <ServerErrorMessages className="w-100" messages={serverErrorMessages} />
      </Modal.Footer>
    </Modal>
  );
}

type ChangeOrganisationStatusModalProps = {
  organisationId: string | undefined;
  organisation: GetOrganisationDto | null | undefined;
  organisationAction: OrganisationAction | null;
  setOrganisationAction: React.Dispatch<React.SetStateAction<OrganisationAction | null>>;
};

function ChangeOrganisationStatusModal({
  organisationId,
  organisation,
  organisationAction,
  setOrganisationAction,
}: ChangeOrganisationStatusModalProps) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [serverErrorMessages, setServerErrorMessages] = useState<ServerFormErrors>([]);

  useEffect(() => {
    setServerErrorMessages([]);
  }, [organisation]);

  const closeModal = useCallback(() => setOrganisationAction(null), [setOrganisationAction]);

  if (!organisationId || !organisationAction || !organisation) return <></>;

  const updateOrganisationStatusRequest = async () => {
    try {
      switch (organisationAction) {
        case OrganisationAction.Activate:
          await updateOrganisationStatus(organisationId, OrganisationStatus.Active);
          break;
        case OrganisationAction.Archive:
          await updateOrganisationStatus(organisationId, OrganisationStatus.Archived);
          break;
        case OrganisationAction.Delete:
          await deleteOrganisation(organisationId);
          break;
      }

      queryClient.removeQueries({ queryKey: ['getOrganisationById', organisationId] });
      queryClient.removeQueries({ queryKey: ['getOrganisationListSearch'] });
      navigate(RoutePath.partyBManagementList, { replace: true });
    } catch (e) {
      const serverErrors = new ServerErrorAdapter(e as ServerError);
      setServerErrorMessages(serverErrors.combine());
    }
  };

  return (
    <Modal show={organisationAction !== null} onHide={closeModal} size="lg" centered>
      <Modal.Header onHide={closeModal} closeButton>
        <Modal.Title className="h5">
          Do you want to {organisationAction.toLocaleLowerCase()} "{organisation.name}"?
        </Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <button type="button" className="btn btn-link" onClick={closeModal}>
          Cancel
        </button>
        <LoadingButton
          type="button"
          className={classNames('btn', {
            'btn-primary':
              organisationAction === OrganisationAction.Activate || organisationAction === OrganisationAction.Archive,
            'btn-danger': organisationAction === OrganisationAction.Delete,
          })}
          onClick={updateOrganisationStatusRequest}
        >
          {organisationAction}
        </LoadingButton>
        <ServerErrorMessages className="w-100" messages={serverErrorMessages} />
      </Modal.Footer>
    </Modal>
  );
}

type PartyBContactFieldsProps = {
  group: FieldArrayWithId<FormData, 'users', 'id'>;
  index: number;
  contactsCounter: number;
  register: UseFormRegister<FormData>;
  control: Control<FormData, any>;
  remove: UseFieldArrayRemove;
  formState: FormState<FormData>;
  onClearSearch: (email: string, index: number) => void;
  handleCloseDropdown: (index: number) => void;
  setUserToChangeStatus: React.Dispatch<React.SetStateAction<DeleteContactModel | null>>;
  promiseOptions: (inputValue: string) => Promise<SelectOption[]>;
  organisationId: string | undefined;
  organisation: GetOrganisationDto | null | undefined;
  isAllowedOrganisationWrite: boolean;
};
function PartyBContactFields({
  group,
  index,
  contactsCounter,
  register,
  control,
  remove,
  formState,
  onClearSearch,
  handleCloseDropdown,
  setUserToChangeStatus,
  promiseOptions,
  organisationId,
  organisation,
  isAllowedOrganisationWrite,
}: PartyBContactFieldsProps) {
  const isUserPresent = Boolean(group.email);
  const hasUserExist = group.isExistUser;
  return (
    <div className="pt-3">
      <div className="d-flex justify-content-between">
        <p className="text-secondary fw-medium">{`Contact ${index + 1}`}</p>
        {contactsCounter > 1 && !isUserPresent && (
          <button className="btn btn-close" type="button" onClick={() => remove(index)}></button>
        )}
      </div>
      <input type="hidden" {...register(`users.${index}.userId`)} />
      <div className="row g-3 mb-3">
        <Field
          className="col-md-4 col-lg-3"
          label="Email"
          field="dropdown-async-creatable"
          type="email"
          placeholder="Select or enter email"
          autoComplete="off"
          register={register(`users.${index}.email`)}
          control={control}
          formSchema={formSchema}
          errors={formState.errors}
          onClearOptions={(removedValues) => {
            if (!removedValues.length) return;
            onClearSearch(removedValues[0].value, index);
          }}
          dropdownAsyncCreatableProps={{
            allowCreateWhileLoading: true,
            loadOptions: promiseOptions,
            isDisabled:
              isUserPresent || !isAllowedOrganisationWrite || organisation?.status === OrganisationStatus.Archived,
            isClearable: true,
            defaultOptions: !isUserPresent,
            createOptionPosition: 'first',
            loadingMessage: () => 'finding exist users...',
            onMenuClose: () => handleCloseDropdown(index),
            blurInputOnSelect: true,
          }}
        />
        <Field
          className="col-md-4 col-lg-3"
          label="First name"
          field="input"
          type="text"
          placeholder="Enter first name"
          autoComplete="off"
          register={register(`users.${index}.firstName`)}
          control={control}
          formSchema={formSchema}
          errors={formState.errors}
          disabled={hasUserExist || !isAllowedOrganisationWrite || organisation?.status === OrganisationStatus.Archived}
        />
        <Field
          className="col-md-4 col-lg-3"
          label="Last name"
          field="input"
          type="text"
          placeholder="Enter last name"
          autoComplete="off"
          register={register(`users.${index}.lastName`)}
          control={control}
          formSchema={formSchema}
          errors={formState.errors}
          disabled={hasUserExist || !isAllowedOrganisationWrite || organisation?.status === OrganisationStatus.Archived}
        />
      </div>
      <div className="row g-3 mb-3">
        <Field
          className="col-md-4 col-lg-3"
          label="Job title"
          field="input"
          type="text"
          placeholder="Enter job title"
          autoComplete="off"
          register={register(`users.${index}.title`)}
          control={control}
          formSchema={formSchema}
          errors={formState.errors}
          disabled={!isAllowedOrganisationWrite || organisation?.status === OrganisationStatus.Archived}
        />
        <Field
          className="col-md-4  col-lg-3"
          label="Phone number"
          field="input"
          type="text"
          placeholder="Enter phone number"
          autoComplete="off"
          register={register(`users.${index}.phone`)}
          control={control}
          formSchema={formSchema}
          errors={formState.errors}
          disabled={!isAllowedOrganisationWrite || organisation?.status === OrganisationStatus.Archived}
        />
        <Field
          className="col-md-4  col-lg-3"
          label="External number"
          field="input"
          type="text"
          placeholder="Enter external number"
          autoComplete="off"
          register={register(`users.${index}.externalNumber`)}
          control={control}
          formSchema={formSchema}
          errors={formState.errors}
          disabled={!isAllowedOrganisationWrite || organisation?.status === OrganisationStatus.Archived}
        />
      </div>
      <div className="row g-3 mb-3">
        <Field
          className="col-md-4 mb-2 col-lg-3"
          label="Mobile number"
          field="input"
          type="text"
          placeholder="Enter mobile number"
          autoComplete="off"
          register={register(`users.${index}.mobile`)}
          control={control}
          formSchema={formSchema}
          errors={formState.errors}
          disabled={!isAllowedOrganisationWrite || organisation?.status === OrganisationStatus.Archived}
        />
        <input type="hidden" {...register(`users.${index}.isExistUser`)} />
        {isAllowedOrganisationWrite && organisationId && isUserPresent && (
          <div className="col-md-4 mb-2 col-lg-3 d-flex align-items-end">
            <button
              type="button"
              className="btn btn-danger text-nowrap"
              disabled={!isAllowedOrganisationWrite || organisation?.status === OrganisationStatus.Archived}
              onClick={() =>
                setUserToChangeStatus({
                  organisationId,
                  userId: group.userId,
                  firstName: group.firstName,
                  lastName: group.lastName,
                })
              }
            >
              Delete contact
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

function PartyBManagementForm({
  organisation,
  organisationError,
  organisationRefetch,
  organisationId,
  organisationExistUsers,
}: PartyBManagementFormProps) {
  const { hasUserPermission } = useUserPermission();
  const isAllowedOrganisationWrite = hasUserPermission(PermissionNames.OrganisationWrite);

  const [inputSearchIds, setInputSearchIds] = useState<string[] | null>(null);
  const [userToChangeStatus, setUserToChangeStatus] = useState<DeleteContactModel | null>(null);
  const [organisationAction, setOrganisationAction] = useState<OrganisationAction | null>(null);

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { register, control, formState, handleSubmit, setError, clearErrors, setValue, watch, getValues } =
    useForm<FormData>({
      resolver: yupResolver(formSchema),
    });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'users',
  });

  const { serverErrorMessages, setServerErrorMessages, handleErrors } = useFormErrors<FormData>(setError, clearErrors);

  const nameRaw = watch('name');

  useEffect(() => {
    if (organisationId) return;
    const initials = nameRaw ? Converter.getInitials(nameRaw.trim()) : '';
    setValue('displayInitials', initials);
  }, [nameRaw, organisationId, setValue]);

  const promiseOptions = async (inputValue: string) => {
    const data = await queryClient.fetchQuery({
      queryKey: ['getExistPartyBUsersFiltered', organisationId, inputValue, inputSearchIds],
      queryFn: async ({ signal }) => {
        if (!inputSearchIds) return [];
        const config = { signal };
        const excludeOrganisationId = organisationId || undefined;
        const search = inputValue || undefined;
        const excludeUserIds = inputSearchIds.length ? inputSearchIds : undefined;
        const params = {
          search,
          excludeOrganisationId,
          excludeUserIds,
        };
        const res = await getExistPartyBUsers(params, config);
        const resOptions = res.data.items.map((user) => {
          const { firstName, lastName, logoUrl, email } = user;
          return {
            label: getLabel(firstName, lastName, logoUrl, email),
            shortLabel: email,
            value: email,
          };
        });
        return resOptions;
      },
    });
    return data;
  };

  const handleCloseDropdown = (index: number) => {
    handleErrors();
    const allExistUsers = getValues('users').filter((user) => user.isExistUser);
    const { email } = getValues(`users.${index}`);

    const existUser = organisationExistUsers?.find((user) => user.email === email);

    if (existUser) {
      setInputSearchIds((prevState) => [...(prevState || []), existUser.id]);
    }

    if (!email) return;
    const attachedUserIndex = allExistUsers.findIndex((user) => user.email === email);
    if (attachedUserIndex !== -1) {
      const error = new Error(`User ${email} has been added`);
      handleErrors(error as ServerError);
    }

    if (existUser) {
      setValue(`users.${index}.firstName`, existUser.firstName);
      setValue(`users.${index}.lastName`, existUser.lastName);
      setValue(`users.${index}.userId`, existUser.id);
      setValue(`users.${index}.isExistUser`, true);
    } else {
      setValue(`users.${index}.firstName`, '');
      setValue(`users.${index}.lastName`, '');
      setValue(`users.${index}.userId`, '');
      setValue(`users.${index}.isExistUser`, false);
    }
  };

  const updateOrganisationLogo = async (organisationId: string, file: FileList) => {
    if (file.length === 0) return;
    try {
      const formData = new FormData();
      formData.append('file', file[0]);
      await uploadOrganisationLogo(organisationId, formData);
    } catch (e) {
      const serverErrors = new ServerErrorAdapter(e as ServerError);
      setServerErrorMessages(serverErrors.combine());
    }
  };

  const addUsersByOrganisationId = (organisationId: string, users: OrganisationUserFormData[]) => {
    if (users.length === 0) return;
    const createUserList = users.map((user) => {
      const { userId, isExistUser, ...userPayload } = user;
      return !userId && createOrganisationUser(organisationId, userPayload);
    });
    return Promise.all(createUserList);
  };

  const updateUsersByOrganisationId = (organisationId: string, users: OrganisationUserFormData[]) => {
    if (users.length === 0) return;
    const updateUserList = users.map((user) => {
      const { userId, isExistUser, email, ...userPayload } = user;
      return userId && updateOrganisationUser(userId, organisationId, userPayload);
    });
    return Promise.all(updateUserList);
  };

  const assignExistUsersToOrganisation = (organisationId: string, users: OrganisationUserFormData[]) => {
    if (users.length === 0) return;
    const updateUserList = users.map((user) => {
      const { userId, isExistUser, email, ...userPayload } = user;
      return userId && addExistUserToOrganisation(userId, organisationId, userPayload);
    });
    return Promise.all(updateUserList);
  };

  const onClearSearch = useCallback(
    (email: string, index: number) => {
      const existUser = organisationExistUsers?.find((user) => user.email === email);
      if (existUser) {
        const updateIds = (inputSearchIds || []).filter((id) => id !== existUser.id);
        setInputSearchIds(updateIds);
      }
      setValue(`users.${index}.firstName`, '');
      setValue(`users.${index}.lastName`, '');
      setValue(`users.${index}.email`, '');
      setValue(`users.${index}.userId`, '');
      setValue(`users.${index}.isExistUser`, false);
    },
    [inputSearchIds, organisationExistUsers, setValue],
  );

  const onSubmit = handleSubmit(async (formData) => {
    const { logoFile, users, ...organisationPayload } = formData;
    const originUsers: OrganisationUserFormData[] = [];
    const existUsers: OrganisationUserFormData[] = [];

    users.forEach((user) => {
      if (!user.isExistUser) {
        originUsers.push(user);
      } else {
        existUsers.push(user);
      }
    });

    try {
      handleErrors();

      if (!organisationId) {
        //Create organisation & add logo & add contacts
        const res = await createOrganisation(organisationPayload);
        await updateOrganisationLogo(res.data, logoFile);
        await addUsersByOrganisationId(res.data, originUsers);
        await assignExistUsersToOrganisation(res.data, existUsers);
      } else {
        //Update organisation & update contacts & add new contacts
        const newUsers: OrganisationUserFormData[] = [];
        const usersToUpdate: OrganisationUserFormData[] = [];

        originUsers.forEach((user) => {
          if (!user.userId) {
            newUsers.push(user);
          } else {
            usersToUpdate.push(user);
          }
        });

        await updateOrganisation(organisationId, organisationPayload);
        await updateOrganisationLogo(organisationId, logoFile);
        await updateUsersByOrganisationId(organisationId, usersToUpdate);
        await addUsersByOrganisationId(organisationId, newUsers);
        await assignExistUsersToOrganisation(organisationId, existUsers);
      }

      queryClient.removeQueries({ queryKey: ['getOrganisationListSearch'] });

      navigate(RoutePath.partyBManagementList, { replace: true });
    } catch (error) {
      const serverErrors = new ServerErrorAdapter(error as ServerError);
      setServerErrorMessages(serverErrors.combine());
    }
  });

  useEffect(() => {
    if (!organisationError) return;
    const serverErrors = new ServerErrorAdapter(organisationError as ServerError);
    setServerErrorMessages(serverErrors.combine());
  }, [organisationError, setServerErrorMessages]);

  const organisationPrefill = useMemo(
    () => ({
      name: organisation?.name || '',
      displayInitials: organisation?.displayInitials || '',
      addressLine: organisation?.addressLine || '',
      state: organisation?.state || '',
      town: organisation?.town || '',
      country: organisation?.country || '',
      postCode: organisation?.postCode || '',
      email: organisation?.email || '',
      phone: organisation?.phone || '',
      theme: organisation?.theme || OrganisationColorTheme.CAPER,
      users: organisation ? getFieldArray(organisation?.users) : getFieldArray([]),
    }),
    [organisation],
  );

  useEffect(() => {
    setValue('name', organisationPrefill.name);
    setValue('displayInitials', organisationPrefill.displayInitials);
    setValue('addressLine', organisationPrefill.addressLine);
    setValue('state', organisationPrefill.state);
    setValue('town', organisationPrefill.town);
    setValue('country', organisationPrefill.country);
    setValue('postCode', organisationPrefill.postCode);
    setValue('email', organisationPrefill.email);
    setValue('phone', organisationPrefill.phone);
    setValue('theme', organisationPrefill.theme);
    setValue('users', organisationPrefill.users);
  }, [organisationPrefill, setValue]);

  useEffect(() => {
    if (!organisation) return;
    const addedUsers = organisation.users.map((user) => user.id);
    setInputSearchIds(addedUsers);
  }, [organisation]);

  return (
    <div className="bg-white border rounded">
      <h2 className="h6 p-3 mb-0">{organisationId ? 'Update' : 'Create'} party B organisation</h2>
      <form className="border-top p-3" name="createOrganisationForm" onSubmit={onSubmit} noValidate>
        <div className="row g-3 mb-3">
          <Field
            className="col-sm-6 col-md-6 col-lg-3"
            inputClassName={classNames(styles.Image)}
            field="image"
            label="Company logo"
            placeholder={'Upload PNG\nnot more than 3 MB'}
            autoComplete="off"
            register={register('logoFile')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
            url={organisation?.logoUrl}
            disabled={!isAllowedOrganisationWrite || organisation?.status === OrganisationStatus.Archived}
          />
          <Field
            className="col-sm-6 col-md-6 col-lg-9"
            inputClassName={classNames(styles.Input)}
            formCheckClassName={classNames(styles.FormInput)}
            label="Choose company identity color"
            field="input"
            type="radio"
            options={organisationColorOptions}
            autoComplete="off"
            register={register('theme')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
            disabled={!isAllowedOrganisationWrite || organisation?.status === OrganisationStatus.Archived}
          />
        </div>
        <div className="row g-3 mb-3">
          <Field
            className="col-sm-12 col-md-12 col-lg-3"
            label="Company name"
            field="input"
            type="text"
            placeholder="Enter company name"
            autoComplete="off"
            register={register('name')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
            disabled={!isAllowedOrganisationWrite || organisation?.status === OrganisationStatus.Archived}
            autoFocus
          />
          <Field
            className="col-sm-12 col-md-6 col-lg-3"
            label="Company display initials"
            field="input"
            type="text"
            placeholder="Enter company display initials"
            autoComplete="off"
            register={register('displayInitials')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
            disabled={!isAllowedOrganisationWrite || organisation?.status === OrganisationStatus.Archived}
          />
          <Field
            className="col-sm-12 col-md-6 col-lg-3"
            label="Company country"
            field="input"
            type="text"
            placeholder="Enter company country"
            autoComplete="off"
            register={register('country')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
            disabled={!isAllowedOrganisationWrite || organisation?.status === OrganisationStatus.Archived}
          />
        </div>
        <div className="row g-3 mb-3">
          <Field
            className="col-sm-12 col-md-6 col-lg-3"
            label="Company county/state"
            field="input"
            type="text"
            placeholder="Enter company county/state"
            autoComplete="off"
            register={register('state')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
            disabled={!isAllowedOrganisationWrite || organisation?.status === OrganisationStatus.Archived}
          />
          <Field
            className="col-sm-12 col-md-6 col-lg-3"
            label="Company town/city"
            field="input"
            type="text"
            placeholder="Enter company town/city"
            autoComplete="off"
            register={register('town')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
            disabled={!isAllowedOrganisationWrite || organisation?.status === OrganisationStatus.Archived}
          />
          <Field
            className="col-sm-12 col-md-12 col-lg-3"
            label="Company street address"
            field="input"
            type="text"
            placeholder="Enter company street address"
            autoComplete="off"
            register={register('addressLine')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
            disabled={!isAllowedOrganisationWrite || organisation?.status === OrganisationStatus.Archived}
          />
        </div>
        <div className="row g-3 mb-4">
          <Field
            className="col-sm-12 col-md-6 col-lg-3"
            label="Company post code"
            field="input"
            type="text"
            placeholder="Enter company post code"
            autoComplete="off"
            register={register('postCode')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
            disabled={!isAllowedOrganisationWrite || organisation?.status === OrganisationStatus.Archived}
          />
          <Field
            className="col-sm-12 col-md-6 col-lg-3"
            label="Company email"
            field="input"
            type="email"
            placeholder="Enter company email"
            autoComplete="off"
            register={register('email')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
            disabled={!isAllowedOrganisationWrite || organisation?.status === OrganisationStatus.Archived}
          />
          <Field
            className="col-sm-12 col-md-6 col-lg-3"
            label="Company phone number"
            field="input"
            type="text"
            placeholder="Enter company phone number"
            autoComplete="off"
            register={register('phone')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
            disabled={!isAllowedOrganisationWrite || organisation?.status === OrganisationStatus.Archived}
          />
        </div>
        {organisation && (
          <div className="d-flex gap-4 mb-4">
            {organisation.actions.toSorted().map((action) => (
              <button
                key={action}
                className={classNames('btn', `btn-outline-${organisationActionColorMap[action]}`)}
                type="button"
                onClick={() => setOrganisationAction(action)}
              >
                {action}
              </button>
            ))}
            {organisation.status === OrganisationStatus.Active &&
              !organisation.actions.includes(OrganisationAction.Archive) && (
                <div className="hstack gap-2">
                  <button
                    className={classNames(
                      'btn',
                      `btn-outline-${organisationActionColorMap[OrganisationAction.Archive]}`,
                    )}
                    type="button"
                    disabled
                  >
                    {OrganisationAction.Archive}
                  </button>
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip className={styles.Tooltip}>
                        This company has deals in motion, so it can't be archived.
                      </Tooltip>
                    }
                  >
                    <InfoIcon className="text-primary" />
                  </OverlayTrigger>
                </div>
              )}
          </div>
        )}
        <div>
          <h2 className="h6 border-bottom pb-4">Company Contacts</h2>
          {fields.map((group, index) => (
            <PartyBContactFields
              key={group.id}
              group={group}
              index={index}
              contactsCounter={fields.length}
              register={register}
              control={control}
              remove={remove}
              formState={formState}
              onClearSearch={onClearSearch}
              handleCloseDropdown={handleCloseDropdown}
              setUserToChangeStatus={setUserToChangeStatus}
              promiseOptions={promiseOptions}
              organisationId={organisationId}
              organisation={organisation}
              isAllowedOrganisationWrite={isAllowedOrganisationWrite}
            />
          ))}
        </div>
        {isAllowedOrganisationWrite && (
          <button
            className="btn btn-link"
            type="button"
            disabled={!isAllowedOrganisationWrite || organisation?.status === OrganisationStatus.Archived}
            onClick={() => append(userDefaultValues)}
          >
            Add contact
          </button>
        )}

        <div className="d-flex justify-content-end gap-2">
          <button className="btn btn-outline-primary" type="button" onClick={() => navigate(-1)}>
            Back
          </button>
          <LoadingButton
            className="btn btn-primary"
            type="submit"
            isLoading={formState.isSubmitting}
            disabled={
              !isAllowedOrganisationWrite || !formState.isDirty || organisation?.status === OrganisationStatus.Archived
            }
          >
            {organisation?.id ? 'Update' : 'Create'}
          </LoadingButton>
        </div>
      </form>
      <ServerErrorMessages className="mx-3" messages={serverErrorMessages} />
      <DeleteContactModal
        user={userToChangeStatus}
        setUser={setUserToChangeStatus}
        organisationRefetch={organisationRefetch}
      />
      <ChangeOrganisationStatusModal
        organisationId={organisationId}
        organisation={organisation}
        organisationAction={organisationAction}
        setOrganisationAction={setOrganisationAction}
      />
    </div>
  );
}

export default PartyBManagementForm;
