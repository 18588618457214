import { AxiosRequestConfig } from 'axios';

import { baseApi } from 'src/api';

import { UserNotificationDto, Integer, UUIDString } from 'src/types';

export type GetUserNotificationListParams = {
  take: Integer;
  skip: Integer;
};
export const getUserNotificationList = (params: GetUserNotificationListParams, config: AxiosRequestConfig = {}) =>
  baseApi.get<UserNotificationDto[]>('/notifications/in-app/current-user', { ...config, params });

export const getUserNotificationUnreadCount = (config: AxiosRequestConfig = {}) =>
  baseApi.get<Integer>('/notifications/in-app/current-user/unread-count', config);

export const markNotificationAsRead = (userNotificationId: UUIDString, config: AxiosRequestConfig = {}) =>
  baseApi.post<void>(`/notifications/in-app/current-user/${userNotificationId}/mark-read`, config);

export const markAllNotificationAsRead = (config: AxiosRequestConfig = {}) =>
  baseApi.post<void>('/notifications/in-app/current-user/mark-read-all', config);

export const deleteNotification = (userNotificationId: UUIDString, config: AxiosRequestConfig = {}) =>
  baseApi.delete<void>(`/notifications/in-app/current-user/${userNotificationId}`, config);
