import { parseISO, format, isValid } from 'date-fns';
import { SelectOption } from 'src/types';

type RoundToNearestMinutesOptions = {
  nearestTo?: number;
  roundingMethod?: 'ceil' | 'floor' | 'round' | 'trunc';
};

type ParsedNodaTime = {
  dateISOString: string;
  timeZone: string;
  offset: string;
};

class Converter {
  static pascalToCamel(s: string): string {
    return `${s.charAt(0).toLocaleLowerCase()}${s.slice(1)}`;
  }

  private static splitUpperCaseStringToArray(s: string): string[] {
    return s.split(/([A-Z][a-z]+)/g).filter((e) => e);
  }

  /**
   * Returns a string with inserted spaces between uppercased words
   * @deprecated should use "stripUpperToCapitalizedAll" instead
   */
  static splitUpperCase(s: string): string {
    return this.stripUpperToCapitalizedAll(s);
  }

  static stripToAbbreviation(s: string): string {
    return s.replace(/[^A-Z]/g, '');
  }

  /**
   * Returns a string with inserted spaces between uppercased words
   */
  static stripUpperToCapitalizedAll(s: string): string {
    return this.splitUpperCaseStringToArray(s).join(' ');
  }

  /**
   * Returns a string with inserted spaces between uppercased words.
   * Additionally makes all words lowercased except first one and abbreviations.
   */
  static stripUpperToCapitalizedFirst(s: string): string {
    return this.splitUpperCaseStringToArray(s)
      .map((word, index) => {
        // First word must be capitalized
        if (index === 0) return word;
        // Abbreviation must be as is - uppercased
        if (word.length > 1 && word === word.toUpperCase()) return word;
        return word.toLowerCase();
      })
      .join(' ');
  }

  static splitCommaColonSeparatedStringToKeyValue(s: string): Record<string, string> {
    return s
      .split(',')
      .filter(this.emptyValuePredicate)
      .reduce((acc, string) => {
        const [key, value] = string.split(': ').filter(this.emptyValuePredicate);
        return { ...acc, [key]: value };
      }, {});
  }

  static enumToSelectOptions(enumValue: Record<string, string>): SelectOption[] {
    return Object.values(enumValue).map((value) => ({ value, label: this.stripUpperToCapitalizedFirst(value) }));
  }

  private static emptyValuePredicate(v: unknown): boolean {
    return !!v;
  }

  static hexToRGBA(hex: string, alpha: number) {
    const chunkSize = Math.floor((hex.length - 1) / 3);
    const hexArr = hex.slice(1).match(new RegExp(`.{${chunkSize}}`, 'g')) || [];
    const [r = 0, g = 0, b = 0] = hexArr.map((h) => parseInt(h.repeat(2 / h.length), 16));
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  static formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}${sizes[i]}`;
  }

  /**
   * Returns the initials of the words in the string.
   * @param max limits the number of returned initials, default value is 2.
   */
  static getInitials(s: string, max = 2): string {
    return s
      .split(/\b_?/)
      .filter((token) => /\w+/.test(token))
      .slice(0, max)
      .reduce((acc, token) => acc + token.charAt(0).toUpperCase(), '');
  }

  /**
   * Escape characters with special meaning either inside or outside character sets.
   * Use a simple backslash escape when it’s always valid, and a `\xnn` escape when the simpler form would be disallowed by Unicode patterns’ stricter grammar.
   */
  static escapeStringRegexp(s: string): string {
    return s.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&').replace(/-/g, '\\x2d');
  }

  static parseNodaTime(nodaTimeString: string): ParsedNodaTime | null {
    const regex = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z?(\.\d{1,3}Z?)?)\s?([\w\d+-/]+)\s\(([-+]\d{2}(:\d{2})?)\)$/;
    const match = nodaTimeString.match(regex);
    if (!match) return null;
    const [, dateISOString, , timeZone, offset] = match;
    return { dateISOString, timeZone, offset };
  }

  static getFormattedDate(dateString: string, formatValue = 'dd/MM/yyyy') {
    const parsedDate = parseISO(dateString);
    if (!isValid(parsedDate)) return dateString;
    return format(parsedDate, formatValue);
  }

  /**
   * Rounds the given date to the nearest minute (or number of minutes).
   * Made close to date-fns while date-fns has a bugs
   * https://date-fns.org/v2.30.0/docs/roundToNearestMinutes
   */
  static roundToNearestMinutes(date: Date, { nearestTo = 30, roundingMethod = 'ceil' }: RoundToNearestMinutesOptions) {
    const ms = 1000 * 60 * nearestTo;
    return new Date(Math[roundingMethod](date.getTime() / ms) * ms);
  }
}

export default Converter;
