import { useEffect } from 'react';

import { object, string } from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Field from 'src/components/forms/Field';
import ServerErrorMessages from 'src/components/ServerErrorMessages';

import { useFormErrors } from 'src/hooks/FormHelpers';

import { GetUserContactDto, YupFormShape } from 'src/types';

type Props = {
  userContact: GetUserContactDto | undefined;
};

type FormData = {
  firstName: string;
  lastName: string;
  email: string;
};
type FormShape = YupFormShape<FormData>;
const formSchema = object().shape<FormShape>({
  firstName: string().label('First name').trim(),
  lastName: string().label('Last name').trim(),
  email: string().label('Email').trim().email(),
});

function ContactDetailsFrom({ userContact }: Props) {
  const { register, control, formState, setValue, setError, clearErrors } = useForm<FormData>({
    resolver: yupResolver(formSchema),
  });
  const { serverErrorMessages } = useFormErrors<FormData>(setError, clearErrors);

  useEffect(() => {
    if (!userContact) return;
    setValue('firstName', userContact.firstName || '');
    setValue('lastName', userContact.lastName || '');
    setValue('email', userContact.email || '');
  }, [userContact, setValue]);

  return (
    <form className="bg-white border rounded p-3 mb-5" name="contactDetailsForm" noValidate>
      <div className="row g-3">
        <div className="col-sm-12 col-md">
          <div className="row g-3">
            <Field
              className="col-md-6 col-lg-4 col-xl-3 col-xxl-2"
              field="input"
              type="text"
              label="First name"
              placeholder="Enter first name"
              autoComplete="off"
              register={register('firstName')}
              control={control}
              formSchema={formSchema}
              errors={formState.errors}
              disabled
            />
            <Field
              className="col-md-6 col-lg-4 col-xl-3 col-xxl-2"
              field="input"
              type="text"
              label="Last name"
              placeholder="Enter last name"
              autoComplete="off"
              register={register('lastName')}
              control={control}
              formSchema={formSchema}
              errors={formState.errors}
              disabled
            />
            <Field
              className="col-md-6 col-lg-4 col-xl-3 col-xxl-2"
              field="input"
              type="email"
              label="Email"
              placeholder="Enter email"
              autoComplete="off"
              register={register('email')}
              control={control}
              formSchema={formSchema}
              errors={formState.errors}
              disabled
            />
          </div>
        </div>
      </div>
      <ServerErrorMessages className="mt-3" messages={serverErrorMessages} />
    </form>
  );
}

export default ContactDetailsFrom;
