import { AxiosError } from 'axios';
import { AnySchema } from 'yup';

// Global
export enum SearchOrder {
  asc,
  desc,
}

export type PagedResponse<T> = {
  total: number;
  items: T[];
};
export type DateTimeISOString = string;
export type UUIDString = string;
export type Integer = number;
export type Double = number;
export type Base64String = string;
export type BinaryString = string;

export type SelectOption = {
  label: string | JSX.Element;
  shortLabel?: string | JSX.Element;
  value: string;
  isDisabled?: boolean;
};

export type TakeSkip = {
  take: number;
  skip: number;
};

export type OverrideType<T, R> = Omit<T, keyof R> & R;

// Forms
export type YupFormShape<T> = Record<keyof T, AnySchema>;

export type ServerValidationError = Record<string, string[]>;

export type ServerValidationResponse = {
  type: string;
  title: string;
  status: number;
  traceId: string;
  errors?: ServerValidationError;
};
export type ServerError = AxiosError<ServerValidationResponse | LoginResponse | Blob | string | null | undefined>;

export type FieldErrors = Record<string, string[]>;

export type ServerFormErrors = string[];

// Auth API
export enum ClientType {
  App = 'App',
  Admin = 'Admin',
}

export type LoginUserModel = {
  email: string;
  password: string;
  clientType: ClientType;
  code: string | null;
};

export type LoginRefreshTokenModel = {
  refreshToken: string;
  clientType: ClientType;
};

export enum LoginStatus {
  Failed = 'Failed',
  RequireSetupMFA = 'RequireSetupMFA',
  RequireMFA = 'RequireMFA',
  Success = 'Success',
}

export type LoginResponse = {
  accessToken: string;
  expiresIn: Integer;
  refreshToken: string;
  refreshTokenExpiresIn: Integer;
  tokenType: string;
  scope: string;
  secret: string | null;
  status: LoginStatus;
};

export enum Role {
  Admin = 'Admin',
  Client = 'Client',
}

export type AccessTokenData = {
  iss: string;
  nbf: number;
  iat: number;
  exp: number;
  aud: string;
  scope: string[];
  amr: string[];
  client_id: string;
  sub: string;
  auth_time: number;
  idp: string;
  email: string;
  tenant: string | string[];
  role: Role;
  jti: string;
};

export type ConfirmEmailModel = {
  email: string;
  token: string;
  password: string;
};

export type UserResetTokenModel = {
  email: string;
};

// Admin API
export type TenantPublicSettingsDto = {
  logoUrl: string | null;
  name: string;
};

// Base API
// Agreement
export enum AgreementType {
  Agreement = 'Agreement',
  Addendum = 'Addendum',
}

export enum AgreementDocumentType {
  Agreement = 'Agreement',
  Source = 'Source',
}

export enum AgreementStatus {
  Inactive = 'Inactive',
  Finalised = 'Finalised',
  AwaitingSignature = 'AwaitingSignature',
  Signed = 'Signed',
  Rejected = 'Rejected',
  InEffect = 'InEffect',
  Ended = 'Ended',
  Cancelled = 'Cancelled',
}

export enum DealRotorFileType {
  Agreement = 'Agreement',
  Source = 'Source',
  Attachment = 'Attachment',
}

export type AgreementDocumentDto = {
  agreementDocumentId: UUIDString;
  name: string | null;
  mimeType: string | null;
  type: DealRotorFileType;
  createdDate: DateTimeISOString;
};

export enum PartySigningStatus {
  Waiting = 'Waiting',
  Signing = 'Signing',
  Signed = 'Signed',
  Rejected = 'Rejected',
}

export type AgreementDto = {
  id: UUIDString;
  name: string | null;
  description: string | null;
  type: AgreementType;
  status: AgreementStatus;
  endDateModification: AgreementEndDateModification;
  isAddedToBlockchain: boolean;
  isSigned: boolean;
  createdDate: DateTimeISOString;
  updatedDate: DateTimeISOString;
  startDate: DateTimeISOString | null;
  endDate: DateTimeISOString | null;
  isCurrentUserSignaturePending: boolean | null;
  signingPartyFirst: OrganisationType | null;
  partyASigningStatus: PartySigningStatus | null;
  partyBSigningStatus: PartySigningStatus | null;
  agreementDocument: AgreementDocumentDto | null;
  sourceDocument: AgreementDocumentDto | null;
  participants: UserSummaryDto[];
};

export type AgreementSummaryDto = {
  agreementId: UUIDString;
  name: string | null;
  agreementDocument: AgreementDocumentDto;
};

export enum SignatureStatus {
  AbleToSign = 'AbleToSign',
  NotAbleToSign = 'NotAbleToSign',
  Signed = 'Signed',
  Rejected = 'Rejected',
}

export type GetAgreementSignatoryDto = {
  userId: UUIDString;
  signingType: SigningType;
  status: SignatureStatus;
  partyType: OrganisationType;
  partyName: string | null;
  comment: string | null;
  fullName: string | null;
  title: string | null;
  signatureFilename: string | null;
  actionDate: DateTimeISOString;
};

export type ExpectedSignatoryDto = {
  userId: UUIDString;
  partyType: OrganisationType;
};

export type SignAgreementAggregatedDetailsDto = {
  dealRotorNumber: UUIDString | null;
  documents: AgreementSummaryDto[];
  signatories: GetAgreementSignatoryDto[];
};

export type ContactSignAgreementAggregatedDetailsDto = SignAgreementAggregatedDetailsDto & {
  userId: UUIDString;
  signatureFilename: string | null;
};

export type UpdateAgreementDetailsModel = {
  description: string | null | undefined;
  startDate: DateTimeISOString;
  endDate: DateTimeISOString;
};

export enum AgreementEndDateModification {
  WithModification = 'WithModification',
  WithoutModification = 'WithoutModification',
}

export type CreateAddendumModel = {
  agreementEndDate: DateTimeISOString | undefined;
  endDateModification: AgreementEndDateModification;
};

export enum SigningParty {
  All = 'All',
  Single = 'Single',
}

export enum OrganisationType {
  PartyA = 'PartyA',
  PartyB = 'PartyB',
}

export enum SigningType {
  Signature = 'Signature',
  PrintedName = 'PrintedName',
}

export enum SignatureType {
  Signature = 'Signature',
  FreeText = 'FreeText',
}

export type SignatoryModel = {
  userId: UUIDString;
  organisationId: UUIDString;
  order: Integer;
};

export type SendToSigningAgreementModel = {
  signingParty: SigningParty;
  signingPartyFirst: OrganisationType | null;
  isOrderRequired: boolean;
  signingType: SigningType;
  signatories: SignatoryModel[];
};

export type SignAgreementModel = {
  signatureFilename: string;
};

export type ResendSigningInvitationModel = {
  userIds: UUIDString[];
};

export type RejectAgreementModel = {
  comment: string;
};

// Agreement Attribute
export enum BOVE {
  NotSpecified = 'NotSpecified',
  TimeAndMaterial = 'TimeAndMaterial',
  FixedPrice = 'FixedPrice',
  CostPlus = 'CostPlus',
}

export enum CurrencyCodes {
  GBP = 'GBP',
  EUR = 'EUR',
  USD = 'USD',
  AUD = 'AUD',
  ZAR = 'ZAR',
}

export type AgreementAttributeDto = {
  bove: BOVE;
  currencyCode: CurrencyCodes | null;
  value: Double | null;
};

export type UpdateAgreementAttributeModel = {
  bove: BOVE;
  currencyCode: CurrencyCodes | null;
  value: Double | null;
};

// Agreement template
export type TemplateRenderedParamModel = {
  key: string;
  name: string;
  value: string;
  isBridgingXParameter: boolean;
};

export type PreviewAgreementTemplateModel = {
  parameters: TemplateRenderedParamModel[];
};

export type RenderAgreementTemplateModel = {
  name: string;
  templateRenderedParams: TemplateRenderedParamModel[];
};

// Analytics
export type GetPartyDto = {
  id: UUIDString;
  name: string;
  dealsCount: number;
  status: OrganisationStatus;
};

export type BOVEDealsResponse = {
  total: number;
  none: number;
  deals: BOVEDealsDto[];
};

export type RelationshipTypeDealsResponse = {
  total: number;
  deals: RelationshipTypeDealsDto[];
};

export type RelationshipTypeDealsDto = {
  relationshipType: DealRotorRelationshipType;
  count: number;
};

export enum AgreementTypeDuration {
  TBD = 'TBD',
  LThreeMonths = 'LThreeMonths',
  GEThreeToSixMonths = 'GEThreeToSixMonths',
  GESixToTwelveMonths = 'GESixToTwelveMonths',
  GEOneToFiveYears = 'GEOneToFiveYears',
  GEFiveToNNYears = 'GEFiveToNNYears',
  GENNYears = 'GENNYears',
}

export type DurationDealsResponse = {
  count: number;
  duration: AgreementTypeDuration;
};

export enum DealType {
  LOE = 'LOE',
  SOW = 'SOW',
  NDA = 'NDA',
  MSA = 'MSA',
  None = 'None',
}

export enum CurrencySymbol {
  GBP = '£',
  EUR = '€',
  USD = '$',
  AUD = '$',
  ZAR = 'R',
}

export type FinancialDealsDto = {
  count: number;
  name: DealType.LOE | DealType.SOW | string;
};

export type NonFinancialDealsDto = {
  count: number;
  name: DealType.NDA | DealType.MSA | string;
};

export type FinancialDealsValueDto = {
  count: number;
  name: DealType.LOE | DealType.SOW | string;
};

export type FinancialDealsBOVEValueResponse = {
  currencyCode: CurrencyCodes;
  values: FinancialDealsBOVEValueDto[];
};

export type FinancialDealsBOVEValueDto = {
  bove: BOVE.CostPlus | BOVE.FixedPrice | BOVE.TimeAndMaterial;
  boveValues: FinancialDealsValueDto[];
};

export type AnalyticsDealsDto = {
  count: number;
  name: DealType;
};

export type PartyDealsDto = {
  name: string;
  total: number;
  deals: AnalyticsDealsDto[];
};

export type FinancialDealsResponse = {
  total: number;
  financialDeals: FinancialDealsDto[];
  nonFinancialDeals: NonFinancialDealsDto[];
};

export type BOVEDealsDto = {
  bove: BOVE;
  count: number;
};

export type AgreementStageIntervalDto = {
  agreementStage: AgreementStage;
  from: DateTimeISOString;
  to: DateTimeISOString | null;
};

export type DealByAgreementStageTimelineDto = {
  dealRotorId: UUIDString;
  dealRotorNumber: string | null;
  dealRotorName: string | null;
  dealRotorDescription: string | null;
  dealRotorStatus: DealRotorStatus;
  dealRotorType: DealRotorType;
  dealRotorTags: TagDto[] | null;
  dealRotorStartDate: DateTimeISOString;
  dealRotorEndDate: DateTimeISOString | null;
  dealRotorTemplate: DealTemplateDto | null;
  partyBs: OrganisationSummaryDto[] | null;
  agreementStageTimeLine: AgreementStageIntervalDto[] | null;
};

export type DealsResponse = {
  totalActiveDeals: number;
  totalSelectedDeals: number;
  preAgreementStageDeals: number;
  activeAgreementStageDeals: number;
  postAgreementStageDeals: number;
  boveDeals: BOVEDealsResponse;
  relationshipTypeDeals: RelationshipTypeDealsResponse;
  durationDeals: DurationDealsResponse[];
  financialDealsValues: FinancialDealsResponse;
  financialDealsBOVEValues: FinancialDealsBOVEValueResponse[];
  dealsByParties: PartyDealsDto[];
  dealsByAgreementStageTimeline: DealByAgreementStageTimelineDto[] | null;
};

export type GetAnalyticsParams = {
  partyBIds: UUIDString[];
  stages: AgreementStage[];
  templateIds: string[];
};

export type GetDashboardAnalyticsParams = {
  partyBIds: UUIDString[];
  relationshipType: DealRotorRelationshipType;
  daysRange: number;
};

export type AgreementStateResponse = {
  state: AgreementState;
  count: number;
};

export type AgreementStageResponse = {
  stage: AgreementStage;
  count: number;
  agreementStates: AgreementStateResponse[];
};

export type DashboardAgreementsDto = {
  awaitingSignature: number;
  dueToBecomeActive: number;
  dueToExtend: number;
  dueToEnd: number;
};

export type UserActionDto = {
  dealRotorId: UUIDString;
  agreementId: UUIDString;
  dealRotorName: string | null;
  partyBs: string[] | null;
};

export type DashboardDealsDto = {
  totalActiveDeals: number;
  stages: AgreementStageResponse[];
};

export type ReminderDto = {
  id: string;
  dealRotorId: string;
  dealRotorName: string | null;
  message: string | null;
  createDate: DateTimeISOString;
};

export type DashboardDto = {
  deals: DashboardDealsDto;
  agreements: DashboardAgreementsDto;
  actions: UserActionDto[];
  reminders: ReminderDto[];
};

// Chat
export type ChatSettingDto = {
  isChatEnabled: boolean;
  isChatEnabledByDefault: boolean;
};

export type ChatQuestionMetadataDto = {
  deal_rotor_id: UUIDString;
  type: string | null;
  file_id: string | null;
  page: string | null;
  source: string | null;
};

export type ChatQuestionSourceDocumentDto = {
  page_content: string | null;
  metadata: ChatQuestionMetadataDto;
};

export type ChatQuestionResultDto = {
  answer: string | null;
  source_documents: ChatQuestionSourceDocumentDto[];
};

export type ChatQuestionResponseDto = {
  result: ChatQuestionResultDto;
  chat_history: string | null;
};

export type ChatDealRotorQuestionAnswerDto = {
  question: string | null;
  answer: ChatQuestionResponseDto;
};

export enum ChatQuestionType {
  Free = 'Free',
  SystemDefault = 'SystemDefault',
}

export type ChatAskModel = {
  question: string;
  questionType: ChatQuestionType;
  chatHistory: string | null;
};

//Global chat
export type GlobalChatAskModel = {
  question: string;
  chatHistory: string | null;
};

export type GlobalChatQuestionResponseDto = {
  result: string | null;
  chat_history: string | null;
};

// DealRotor
export enum DealRotorType {
  Parent = 'Parent',
  Child = 'Child',
}

export enum DealRotorRelationshipType {
  Customer = 'Customer',
  Supplier = 'Supplier',
  Partner = 'Partner',
  Internal = 'Internal',
  Other = 'Other',
}

export enum DealRotorStage {
  Deal = 'Deal',
  Contract = 'Contract',
  Addendum = 'Addendum',
}

export enum DealRotorStatus {
  Inactive = 'Inactive',
  Active = 'Active',
  Ceased = 'Ceased',
}

export enum AgreementStage {
  Pre = 'Pre',
  Active = 'Active',
  Post = 'Post',
}

export enum AgreementState {
  Shaping = 'Shaping',
  Formalisation = 'Formalisation',
  DeliveryWithoutEvents = 'DeliveryWithoutEvents',
  DeliveryWithEvents = 'DeliveryWithEvents',
  PostCompletion = 'PostCompletion',
}

export enum AgreementTransactionType {
  AwaitingSignature = 'AwaitingSignature',
  DueToBecomeActive = 'DueToBecomeActive',
  DueToExtend = 'DueToExtend',
  DueToEnd = 'DueToEnd',
}

export type TagDto = {
  id: UUIDString;
  name: string;
};

export type DealTemplateDto = {
  id: UUIDString;
  name: DealType | null;
};

export type DealRotorTemplateDto = {
  id: UUIDString;
  name: string;
  description: string;
};

export enum RoleName {
  CustomerAdmin = 'CustomerAdmin',
  Creator = 'Creator',
  DealRotorOwner = 'DealRotorOwner',
  OperationsOwner = 'OperationsOwner',
  DealRotorManager = 'DealRotorManager',
  OperationsManager = 'OperationsManager',
  Signatory = 'Signatory',
  DataAnalyst = 'DataAnalyst',
  GlobalDealRotorManager = 'GlobalDealRotorManager',
}

export type DealRotorActorDto = {
  userId: UUIDString;
  name: string | null;
  email: string | null;
  logoUrl: string | null;
  organisationId: UUIDString;
  party: OrganisationType;
  roleName: RoleName;
};

export type DealRotorPartyDto = {
  organisationId: UUIDString;
  name: string | null;
  type: OrganisationType;
};

export type DealRotorChildDto = {
  id: string;
  name: string | null;
  number: string | null;
};

export type DealRotorDto = {
  id: UUIDString;
  createdBy: string | null;
  partyAName: string | null;
  name: string | null;
  number: string | null;
  templateName: string | null;
  parentDealRotorId: string | null;
  parentDealRotorName: string | null;
  parentDealRotorNumber: string | null;
  description: string | null;
  type: DealRotorType;
  relationshipType: DealRotorRelationshipType;
  stage: DealRotorStage;
  status: DealRotorStatus;
  agreementStage: AgreementStage | null;
  agreementState: AgreementState | null;
  agreementStatus: AgreementStatus | null;
  agreementStartDate: DateTimeISOString | null;
  agreementEndDate: DateTimeISOString | null;
  createdDate: DateTimeISOString;
  startDate: DateTimeISOString;
  endDate: DateTimeISOString | null;
  attachmentsCount: Integer;
  isAgreementAttribute: boolean;
  agreementTerminationDate: DateTimeISOString | null;
  isAgreementTerminated: boolean | null;
  isChatEnabled: boolean;
  tags: TagDto[] | null;
  partyB: DealRotorPartyDto[] | null;
  childs: DealRotorChildDto[] | null;
};

export type CreateDealRotorModel = {
  templateId: UUIDString | null;
  dealOwnerUserId: UUIDString;
  dealOpsOwnerUserId: UUIDString;
  dealManagers: UUIDString[] | null;
  dealOpsManagers?: UUIDString[] | null;
  signatories: UUIDString[] | null;
};

export type DealRotorUpdateModel = {
  name: string;
  description: string | null | undefined;
  relationshipType: DealRotorRelationshipType;
  startDate: DateTimeISOString;
  tags: string[];
};

export type DealRotorActivateModel = {
  name: string;
  parentDealRotorId: UUIDString | null | undefined;
  description: string | null | undefined;
  type: DealRotorType;
  relationshipType: DealRotorRelationshipType;
  partyB: UUIDString[];
  tags: string[];
};

export type DealRotorUpdateChatModel = {
  isChatEnabled: boolean;
};

export type DealRotorSummaryDto = {
  id: UUIDString;
  name: string | null;
  number: string | null;
  status: DealRotorStatus;
  relationshipType: DealRotorRelationshipType | null;
  agreementStage: AgreementStage | null;
  agreementState: AgreementState | null;
  agreementStatus: AgreementStatus | null;
  updatedDate: DateTimeISOString;
  agreementAttributeBOVE: BOVE | null;
  agreementAttributeCurrencyCode: CurrencyCodes | null;
  agreementAttributeValue: Double | null;
  type: DealRotorType;
  startDate: DateTimeISOString | null;
  agreementStartDate: DateTimeISOString | null;
  agreementEndDate: DateTimeISOString | null;
  partyB: OrganisationSummaryDto[] | null;
  userRoles: RoleName[] | null;
  tags: string[] | null;
};

export type DealRotorSummaryDtoDataPagedDto = PagedResponse<DealRotorSummaryDto>;

export type DealRotorParticipantDto = {
  id: UUIDString;
  name: string | null;
  number: string | null;
  title: string | null;
  updatedDate: DateTimeISOString;
  partyB: DealRotorPartyDto | null;
  userRoles: RoleName[] | null;
};

export type DealRotorParticipantDtoDataPagedDto = PagedResponse<DealRotorParticipantDto>;

export enum DealRotorAuditCategory {
  DealRotor = 'DealRotor',
  Agreement = 'Agreement',
  Addendum = 'Addendum',
  Attachments = 'Attachments',
  AgreementAttributes = 'AgreementAttributes',
  Participants = 'Participants',
}

export enum DealRotorAuditSubCategory {
  Action = 'Action',
  AgreementAttributes = 'AgreementAttributes',
  Attachments = 'Attachments',
  SigningEvent = 'SigningEvent',
  StatusChange = 'StatusChange',
  Participants = 'Participants ',
}

export type DealRotorAuditDto = {
  id: string;
  userId: UUIDString | null;
  message: string | null;
  category: DealRotorAuditCategory;
  subCategory: DealRotorAuditSubCategory;
  stage: DealRotorStage;
  status: DealRotorStatus;
  agreementStage: AgreementStage;
  agreementState: AgreementState;
  agreementStatus: AgreementStatus;
  createdDate: DateTimeISOString;
  entityBeforeJson: string | null;
  entityAfterJson: string | null;
};

export type DealRotorAuditDtoDataPagedDto = PagedResponse<DealRotorAuditDto>;

export type DealRotorAuditSummaryDto = {
  userId: UUIDString | null;
  message: string | null;
  category: DealRotorAuditCategory;
  subCategory: DealRotorAuditSubCategory;
  stage: DealRotorStage;
  status: DealRotorStatus;
  agreementStage: AgreementStage;
  agreementState: AgreementState;
  agreementStatus: AgreementStatus;
  createdDate: DateTimeISOString;
};

export type DealRotorAuditGrouped = {
  engagementDateFrom: DateTimeISOString;
  engagementDateTo: DateTimeISOString;
  audits: Record<DateTimeISOString, DealRotorAuditSummaryDto[]>;
};

export type DealRotorForUserSummaryDto = {
  id: UUIDString;
  name: string;
  number: string;
  status: DealRotorStatus;
  relationshipType: DealRotorRelationshipType;
  agreementStage: AgreementStage;
  agreementState: AgreementState;
  agreementStatus: AgreementStatus;
  updatedDate: DateTimeISOString;
  partyB: OrganisationSummaryDto[];
};

export type DealRotorForUserSummaryDtoDataPagedDto = PagedResponse<DealRotorForUserSummaryDto>;

export type DealRotorTerminationModel = {
  terminationDate: DateTimeISOString;
};

// DealRotorParticipants
export type GetPartyBUserDto = {
  organisationId: UUIDString;
  organisationName: string;
  userId: UUIDString;
  name: string;
  email: string;
};

export type TransferDealOwnerModel = {
  userId: UUIDString;
};

export type UpsertDealManagersModel = {
  userIds: UUIDString[];
};

export type UpsertDealSignatoriesModel = {
  userIds: UUIDString[];
  party: OrganisationType;
  partyBId: UUIDString | null;
};

export enum DealRotorAttachmentType {
  Note = 'Note',
  Attachment = 'Attachment',
}

export type DealRotorAttachmentDto = {
  id: UUIDString;
  dealRotorFileId: UUIDString | null;
  dealRotorFileName: string | null;
  type: DealRotorAttachmentType;
  description: string;
  createdDate: DateTimeISOString;
  createdByUserId: UUIDString;
  createdByUserFullName: string;
};

export type DealRotorAttachmentPagedDto = PagedResponse<DealRotorAttachmentDto>;

// Integration
export type GetDataModel = {
  clientId: string;
  clientSecret: string;
  userName: string;
  password: string;
};

export type GetSFContactResponse = {
  externalId: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  title: string | null;
  phone: string | null;
  mobile: string | null;
};

export type GetSFOrganisationResponse = {
  externalId: string | null;
  name: string | null;
  phone: string | null;
  country: string | null;
  state: string | null;
  town: string | null;
  addressLine: string | null;
  postCode: string | null;
  contacts: GetSFContactResponse[] | null;
};

export type GetSFOrganisationResponseDataPagedDto = PagedResponse<GetSFOrganisationResponse>;

// Notification
export enum NotificationMethod {
  InApp = 'InApp',
  Email = 'Email',
}

export enum NotificationSource {
  System = 'System',
  Custom = 'Custom',
}

export enum NotificationType {
  AgreementEndDateReminder = 'AgreementEndDateReminder',
  AgreementActivation = 'AgreementActivation',
  AgreementMovedToEventsAndChanges = 'AgreementMovedToEventsAndChanges',
  AgreementSigningEvents = 'AgreementSigningEvents',
  DealRotorParticipantsChange = 'DealRotorParticipantsChange',
  DealRotorTermination = 'DealRotorTermination',
}

export type NotificationDto = {
  id: UUIDString;
  name: string;
  description: string;
  methods: NotificationMethod[];
  type: NotificationType;
  source: NotificationSource;
  triggerNoOfDaysBefore: number | null;
  createdDate: DateTimeISOString;
  updatedDate: DateTimeISOString;
  roles: RoleDto[];
};

export type CreateAgreementEndDateNotificationModel = {
  triggerNoOfDaysBefore: number;
  methods: NotificationMethod[];
  roles: UUIDString[];
};

export type UpdateNotificationModel = {
  triggerNoOfDaysBefore: number | null;
  methods: NotificationMethod[];
  roles: UUIDString[];
};

// Custom notification
export enum CustomNotificationType {
  AgreementEndDateReminder = 'AgreementEndDateReminder',
  OnDate = 'OnDate',
}

export enum CustomNotificationStatus {
  Active = 'Active',
  Past = 'Past',
}

export type CustomNotificationDto = {
  id: UUIDString;
  description: string;
  triggerDate: DateTimeISOString | null;
  triggerNoOfDaysBefore: number | null;
  methods: NotificationMethod[];
  type: CustomNotificationType;
  status: CustomNotificationStatus;
};

export type CreateCustomNotificationModel = {
  description: string;
  triggerDate: DateTimeISOString | null;
  triggerNoOfDaysBefore: number | null;
  methods: NotificationMethod[];
  type: CustomNotificationType;
};

export type UpdateCustomNotificationModel = {
  description: string;
  triggerDate: DateTimeISOString | null;
  triggerNoOfDaysBefore: number | null;
  methods: NotificationMethod[];
};

// NotificationInApp
export type UserNotificationDto = {
  id: UUIDString;
  message: string | null;
  isRead: boolean;
  readDate: DateTimeISOString;
  createdDate: DateTimeISOString;
};

export enum MessageVariableLinkType {
  UserProfile = 'UserProfile',
  DealRotor = 'DealRotor',
  DealRotorFile = 'DealRotorFile',
  PartyB = 'PartyB',
}

export type MessageVariableLink = {
  Id: UUIDString;
  Type: MessageVariableLinkType;
};

export enum MessageVariableKey {
  UserName = '_UserName_',
  OrganisationName = '_OrganisationName_',
  DealRotorNumber = '_DealRotorNumber_',
  DealRotorFile = '_DealRotorFile_',
}

export type MessageVariable = {
  Key: MessageVariableKey;
  Text: string;
  Link: MessageVariableLink;
};

export type MessageDto = {
  Text: string;
  Variables: MessageVariable[];
};

// Organisation Party B
export enum OrganisationStatus {
  Active = 'Active',
  Archived = 'Archived',
}

export enum OrganisationAction {
  Activate = 'Activate',
  Archive = 'Archive',
  Delete = 'Delete',
}

export type GetOrganisationDto = {
  id: UUIDString;
  name: string;
  displayInitials: string | null;
  addressLine: string | null;
  state: string | null;
  town: string | null;
  country: string | null;
  postCode: string | null;
  email: string | null;
  phone: string | null;
  logoUrl: string | null;
  theme: string | null;
  status: OrganisationStatus;
  users: OrganisationUserDto[];
  actions: OrganisationAction[];
};

export type OrganisationDto = Omit<GetOrganisationDto, 'actions'>;

export type OrganisationModel = {
  name: string;
  displayInitials: string | null;
  addressLine: string | null;
  state: string | null;
  town: string | null;
  country: string | null;
  postCode: string | null;
  email: string | null;
  phone: string | null;
  theme: string | null;
};

export type CreateContactModel = {
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  title: string | null;
  phone: string | null;
  mobile: string | null;
  externalId: string | null;
};

export type CreateOrganisationWithContactsModel = OrganisationModel & {
  externalId: string | null;
  contacts: CreateContactModel[] | null;
};

export type OrganisationUserDto = {
  id: UUIDString;
  firstName: string;
  lastName: string;
  accessStatus: UserAccessStatus;
  email: string;
  title: string | null;
  externalNumber: string | null;
  phone: string | null;
  mobile: string | null;
  logoUrl: string | null;
};

export type OrganisationUserModel = {
  firstName: string;
  lastName: string;
  email: string;
  title: string;
  externalNumber: string | null;
  phone: string | null;
  mobile: string | null;
};

export type UpdateOrganisationUserModel = Omit<OrganisationUserModel, 'email'>;

export type OrganisationDtoDataPagedDto = PagedResponse<OrganisationDto>;

export type OrganisationSummaryDto = {
  id: UUIDString;
  name: string;
  status: OrganisationStatus;
};

export type ExistedOrganisationUserModel = {
  title: string;
  externalNumber: string | null;
  phone: string | null;
  mobile: string | null;
};

export type UserPartyBSummaryDto = {
  id: UUIDString;
  email: string;
  firstName: string;
  lastName: string;
  logoUrl: string | null;
};

export type UserPartyBSummaryDtoDataPagedDto = PagedResponse<UserPartyBSummaryDto>;

// Permissions
export enum PermissionType {
  None = 'None',
  User = 'User',
  Organisation = 'Organisation',
  DealRotor = 'DealRotor',
  Role = 'Role',
  Template = 'Template',
  Notification = 'Notification',
  Analytic = 'Analytic',
  Integrations = 'Integrations',
  Chat = 'Chat',
  Timezone = 'Timezone',
}

export enum RoleType {
  System = 'System',
  Custom = 'Custom',
}

export type PermissionDto = {
  id: UUIDString;
  name: string;
  description: string;
  type: PermissionType;
};

export type GetRoleDto = {
  id: UUIDString;
  name: string;
  type: RoleType;
  permissionIds: UUIDString[];
};

export type RoleDto = {
  id: UUIDString;
  name: string;
  displayName: string;
  type: RoleType;
};

export type PermissionRoleDto = {
  id: UUIDString;
  permission: PermissionDto;
  role: RoleDto;
};

export type CreateRoleDto = {
  id: string | null;
  name: string;
  permissionIds: string[];
};

export enum PermissionNames {
  UserRead = 'user_read',
  UserWrite = 'user_write',
  UserResetMFA = 'user_reset_mfa',
  UserReadSummary = 'user_read_summary',
  UserReadContacts = 'user_read_contacts',
  UserReadSignature = 'user_read_signature',
  DealRotorCreate = 'deal_rotor_create',
  DealRotorRead = 'deal_rotor_read',
  DealRotorAuditRead = 'deal_rotor_audit_read',
  DealRotorNotificationsRead = 'deal_rotor_notifications_read',
  DealRotorDetailsWrite = 'deal_rotor_details_write',
  DealRotorTypeUpdate = 'deal_rotor_type_update',
  DealRotorDeactivate = 'deal_rotor_deactivate',
  AgreementAttributesRead = 'agreement_attributes_read',
  AgreementAttributesWrite = 'agreement_attributes_write',
  DealRotorAgreementWrite = 'deal_rotor_agreement_write',
  DealRotorAgreementSign = 'deal_rotor_agreement_sign',
  DealRotorAttachmentWrite = 'deal_rotor_attachment_write',
  DealRotorTransferOwner = 'deal_rotor_transfer_deal_rotor_owner',
  DealRotorTransferOperationOwner = 'deal_rotor_transfer_operation_owner',
  DealRotorUpdateManagers = 'deal_rotor_update_deal_rotor_managers',
  DealRotorUpdateOperationManagers = 'deal_rotor_update_operation_managers',
  DealRotorUpdateSignatories = 'deal_rotor_update_signatories',
  AnalyticAllSystemDealsRotorsRead = 'analytic_all_system_deals_rotors_read',
  NotificationRead = 'notification_read',
  NotificationWrite = 'notification_write',
  OrganisationRead = 'organisation_read',
  OrganisationWrite = 'organisation_write',
  OrganisationPartyBSummaryRead = 'organisation_party_b_summary_read',
  RoleRead = 'role_read',
  RoleWrite = 'role_write',
  TemplateRead = 'template_read',
  TemplateWrite = 'template_write',
  IntegrationsSalesforce = 'integrations_salesforce',
  ChatRead = 'chat_read',
  ChatWrite = 'chat_write',
  TimezoneRead = 'timezone_read',
  TimezoneWrite = 'timezone_write',
}

// Templates
export type TemplateDto = {
  id: UUIDString;
  name: string;
  description: string;
  createdByUserId: UUIDString;
  createdDate: DateTimeISOString;
  templateParameters: TemplateParameterDto[] | [];
};

export type GetTemplateDto = {
  id: UUIDString;
  name: string;
  description: string;
  text: Base64String;
  createdByUserId: UUIDString;
  createdDate: DateTimeISOString;
};

export type TemplateParameterDto = {
  id: UUIDString;
  templateId: UUIDString;
  templateParamId: UUIDString;
  assignmentDate: DateTimeISOString;
  templateParam: TemplateParamDto;
};

export type TemplateModel = {
  name: string;
  description: string;
  text: Base64String;
};

export type UpdateTemplateModel = TemplateModel;

export type TemplateWithUserDto = {
  id: UUIDString;
  name: string;
  createdByUserId: string;
  createdByUserFullname: string;
  createdByUserLogo: string | null;
  createdDate: string;
};

export type TemplateWithUserDtoDataPagedDto = PagedResponse<TemplateWithUserDto>;

// Template Parameter
export type TemplateParamDto = {
  id: UUIDString;
  key: string;
  name: string;
  value: string | null;
  isBridgingXParameter: boolean;
};

export type TemplateParamModel = {
  key: string;
  name: string;
  value: string | null;
};

export type UpdateTemplateParamModel = TemplateParamModel & { id: UUIDString };

// TenantSetting
export type TenantSettingDto = {
  tenantId: UUIDString;
  isRoleManagement: boolean;
  isChatAccessible: boolean;
  timezone: string | null;
  timezoneOffsetSeconds: Integer;
};

// Timezone
export type GetTenantTimezoneResponse = {
  timezoneId: string | null;
  timezoneUpdatedDate: DateTimeISOString | null;
};

// Users
export type GetUserDto = {
  id: UUIDString;
  securityId: UUIDString | null;
  firstName: string;
  lastName: string;
  fullName: string;
  accessStatus: UserAccessStatus;
  mfaEnabled: boolean;
  email: string;
  title: string | null;
  phone: string | null;
  mobile: string | null;
  logoUrl: string | null;
  signatureFilename: string | null;
  signatureFreeTextFilename: string | null;
  roles: RoleDto[] | null;
};

export type GetCurrentUserDto = Omit<GetUserDto, 'roles'> & {
  roles: string[] | null;
  permissions: PermissionNames[];
};

export type UserOrganisationDto = {
  id: UUIDString;
  name: string | null;
  title: string | null;
  phone: string | null;
  mobile: string | null;
  externalNumber: string | null;
};

export type GetUserContactDto = Pick<GetUserDto, 'id' | 'securityId' | 'firstName' | 'lastName' | 'email'> & {
  organisations: UserOrganisationDto[];
};

export type CreateUserModel = {
  firstName: string;
  lastName: string;
  email: string;
  title: string;
  phone: string;
  mobile: string;
  timezone: string | null;
  shouldBeInvited: boolean;
};

export type UserChangePasswordModel = {
  oldPassword: string;
  newPassword: string;
};

export enum UserAccessStatus {
  NotInvited = 'NotInvited',
  Invited = 'Invited',
  Active = 'Active',
  Deactivated = 'Deactivated',
}

export type UserSummaryDto = Pick<GetUserDto, 'id' | 'firstName' | 'lastName' | 'logoUrl'> & {
  roles?: RoleName[];
};

export type UserSignatoryDto = Pick<GetUserDto, 'id' | 'firstName' | 'lastName' | 'title' | 'logoUrl'>;

export type GetUserDtoDataPagedDto = PagedResponse<GetUserDto[]>;

export type UserStatisticDto = {
  dealRotorsCount: Integer;
  partyBCount: Integer;
};

export type GetUserParticipantDto = Pick<
  GetUserDto,
  'id' | 'securityId' | 'firstName' | 'lastName' | 'fullName' | 'email' | 'logoUrl'
> & {
  type: OrganisationType;
  statistic: UserStatisticDto;
};

export type GetUserParticipantDtoDataPagedDto = PagedResponse<GetUserParticipantDto[]>;

export type UpsertRolesModel = {
  roleIds: string[];
};

export type UpdateUserModel = {
  firstName: string;
  lastName: string;
  title: string;
  phone: string;
  mobile: string;
  shouldBeInvited: boolean;
};

export type UpdateCurrentUserModel = Omit<UpdateUserModel, 'shouldBeInvited'>;

export type GetUserSignedAgreementDto = {
  agreementId: UUIDString;
  agreementDocumentId: UUIDString;
  dealRotorId: UUIDString;
  dealName: string;
  number: string;
  documentName: string | null;
  signedOn: DateTimeISOString;
  organisations: OrganisationSummaryDto[];
};

export type GetUserSignedAgreementDtoDataPagedDto = PagedResponse<GetUserSignedAgreementDto>;
