import qs from 'qs';
import { useMemo } from 'react';
import { useLocalStorage } from 'usehooks-ts';

export * from './usePersistPageParams.constants';
export type * from './usePersistPageParams.types';

function usePersistPageParams<T>(key: string) {
  const [persistPageParams, setPersistPageParams] = useLocalStorage<T | null>(key, null, { initializeWithValue: true });
  const pageUrlSearch = useMemo(
    () => (persistPageParams ? qs.stringify(persistPageParams, { indices: false }) : undefined),
    [persistPageParams],
  );
  return { pageUrlSearch, persistPageParams, setPersistPageParams };
}

export default usePersistPageParams;
