import { useState } from 'react';

import IntegrationSalesForceAuthForm from './IntegrationConfig.sales-force.auth-form';
import IntegrationSalesForcePartyBForm from './IntegrationConfig.sales-force.part-b-form';

import { GetSFOrganisationResponse } from 'src/types';

function IntegrationSalesForce() {
  const [organisationContacts, setOrganisationContacts] = useState<GetSFOrganisationResponse[]>();

  return (
    <>
      <IntegrationSalesForceAuthForm setOrganisationContacts={setOrganisationContacts} />

      {organisationContacts && (
        <section className="mt-4">
          <h2 className="h5 mb-3">Companies ({organisationContacts.length} total)</h2>
          <IntegrationSalesForcePartyBForm organisationContacts={organisationContacts} />
        </section>
      )}
    </>
  );
}

export default IntegrationSalesForce;
