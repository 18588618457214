import { useMemo } from 'react';
import { Link, Navigate, useLocation } from 'react-router-dom';
import { RoutePath } from 'src/router';

import AuthCard from './components/AuthCard';
import SetupMFAForm from './components/SetupMFAForm';

function SetupMFA() {
  const { state } = useLocation();

  const { email, password, secret } = useMemo(() => {
    const locationState: unknown = state;
    if (
      typeof locationState === 'object' &&
      locationState !== null &&
      'email' in locationState &&
      'password' in locationState &&
      'secret' in locationState &&
      typeof locationState.email === 'string' &&
      typeof locationState.password === 'string' &&
      typeof locationState.secret === 'string'
    ) {
      const { email, password, secret } = locationState;
      return { email, password, secret };
    }
    return {};
  }, [state]);

  if (!email || !password || !secret) {
    return <Navigate to={RoutePath.root} replace />;
  }

  return (
    <AuthCard title="MFA Setup">
      <SetupMFAForm email={email} password={password} secret={secret} />
      <div className="text-center mb-4">
        <Link className="btn btn-link fs-14 lh-base p-0 border-0" to={RoutePath.login}>
          Back to Sign In
        </Link>
      </div>
    </AuthCard>
  );
}

export default SetupMFA;
