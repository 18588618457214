import classNames from 'classnames';

import Dot from 'src/components/Dot';
import Message from 'src/components/Message';
import FormatDate from 'src/components/FormatDate';
import {
  AddendumAuditIcon,
  AgreementAttributesAuditIcon,
  AgreementAuditIcon,
  AttachmentsAuditIcon,
  DealRotorAuditIcon,
  ParticipantsAuditIcon,
} from 'src/components/icons/audit';

import Converter from 'src/utils/Converter';
import LocalDate from 'src/utils/LocalDate';
import { getAgreementFullStatus } from 'src/utils/statusFormat';
import {
  boveOptions,
  dealRotorRelationshipTypeOptions,
  currencyCodesOptions,
  dealRotorStatusColorMap,
  agreementStageColorMap,
} from 'src/utils/selectOptions';

import {
  DealRotorAuditDto,
  DealRotorAuditCategory,
  DealRotorAuditSubCategory,
  DealRotorStatus,
  AgreementStage,
  DateTimeISOString,
} from 'src/types';

import styles from './AuditList.module.scss';

const boveNamesOrdered = boveOptions.map(({ label }) => label);
const currencyCodeNamesOrdered = currencyCodesOptions.map(({ label }) => label);
const relationshipTypeNamesOrdered = dealRotorRelationshipTypeOptions.map(({ label }) => label as string);
relationshipTypeNamesOrdered.unshift(relationshipTypeNamesOrdered.pop() as string); // Fix Other to be first

function getFormattedDateStartDay(inputDate: DateTimeISOString | null | undefined): string {
  if (!inputDate) return '';
  const localDate = new LocalDate(inputDate);
  if (!localDate.isValid) return '';
  return Converter.getFormattedDate(localDate.getStartDay().toJSON());
}

type ChangeFromToParams = {
  name: string | JSX.Element;
  from: string | JSX.Element;
  to: string | JSX.Element;
};
function ChangeFromTo({ name, from, to }: ChangeFromToParams) {
  if (from === to) return null;
  return (
    <div className={classNames(styles.ChangeFromTo, 'fs-14 text-secondary')}>
      <strong>{name}</strong>
      {from ? (
        <>
          <span> changed from </span>
          <del>"{from}"</del>
          <span> to </span>
        </>
      ) : (
        <span> added </span>
      )}
      <ins>"{to}"</ins>
    </div>
  );
}

type AuditProps = {
  audit: DealRotorAuditDto;
};
function Audit({ audit }: AuditProps) {
  const {
    message,
    category,
    subCategory,
    status,
    agreementStage,
    agreementState,
    agreementStatus,
    createdDate,
    entityBeforeJson,
    entityAfterJson,
  } = audit;

  const fullStatus = getAgreementFullStatus(agreementStage, agreementState, agreementStatus);
  const entityBefore = entityBeforeJson ? JSON.parse(entityBeforeJson) : null;
  const entityAfter = entityAfterJson ? JSON.parse(entityAfterJson) : null;

  return (
    <div className={classNames(styles.Audit, 'd-flex px-4 py-2 gap-3')}>
      <div>
        {category === DealRotorAuditCategory.DealRotor && (
          <DealRotorAuditIcon
            className={classNames({
              'text-info': status === DealRotorStatus.Active, // old-blue
              'text-danger': status === DealRotorStatus.Ceased, // old-red
              'text-metallic-silver': status === DealRotorStatus.Inactive, // old-brown
            })}
          />
        )}
        {category === DealRotorAuditCategory.Agreement && (
          <AgreementAuditIcon
            className={classNames({
              'text-success': agreementStage === AgreementStage.Active,
              'text-yellow': agreementStage === AgreementStage.Pre,
              'text-warning': agreementStage === AgreementStage.Post,
            })}
          />
        )}
        {category === DealRotorAuditCategory.Addendum && (
          <AddendumAuditIcon
            className={classNames({
              'text-success': agreementStage === AgreementStage.Active,
              'text-yellow': agreementStage === AgreementStage.Pre,
              'text-warning': agreementStage === AgreementStage.Post,
            })}
          />
        )}
        {category === DealRotorAuditCategory.Attachments && <AttachmentsAuditIcon />}
        {category === DealRotorAuditCategory.AgreementAttributes && <AgreementAttributesAuditIcon />}
        {category === DealRotorAuditCategory.Participants && <ParticipantsAuditIcon />}
      </div>
      <div className="vstack gap-1">
        <Message message={message} />
        {category === DealRotorAuditCategory.DealRotor && subCategory === DealRotorAuditSubCategory.StatusChange && (
          <div className="hstack gap-2">
            <Dot color={dealRotorStatusColorMap[status]} />
            <span>{status}</span>
          </div>
        )}
        {category === DealRotorAuditCategory.DealRotor &&
          subCategory !== DealRotorAuditSubCategory.StatusChange &&
          entityBefore &&
          entityAfter && (
            <>
              <ChangeFromTo name="Deal name" from={entityBefore.Name} to={entityAfter.Name} />
              <ChangeFromTo
                name="Activated from"
                from={getFormattedDateStartDay(entityBefore.StartDate)}
                to={getFormattedDateStartDay(entityAfter.StartDate)}
              />
              <ChangeFromTo
                name="Relationship type"
                from={relationshipTypeNamesOrdered[entityBefore.RelationshipType]}
                to={relationshipTypeNamesOrdered[entityAfter.RelationshipType]}
              />
              <ChangeFromTo name="Description" from={entityBefore.Description} to={entityAfter.Description} />
            </>
          )}
        {(category === DealRotorAuditCategory.Agreement || category === DealRotorAuditCategory.Addendum) &&
          subCategory === DealRotorAuditSubCategory.StatusChange &&
          (agreementStage || agreementState || agreementStatus) && (
            <div className="hstack gap-2">
              <Dot color={agreementStage ? agreementStageColorMap[agreementStage] : 'metallic-silver'} />
              <span>{fullStatus}</span>
            </div>
          )}
        {(category === DealRotorAuditCategory.Agreement || category === DealRotorAuditCategory.Addendum) &&
          subCategory !== DealRotorAuditSubCategory.StatusChange &&
          entityBefore &&
          entityAfter && (
            <>
              <ChangeFromTo
                name="Date from"
                from={getFormattedDateStartDay(entityBefore.StartDate)}
                to={getFormattedDateStartDay(entityAfter.StartDate)}
              />
              <ChangeFromTo
                name="Date to"
                from={getFormattedDateStartDay(entityBefore.EndDate)}
                to={getFormattedDateStartDay(entityAfter.EndDate)}
              />
              <ChangeFromTo name="Description" from={entityBefore.Description} to={entityAfter.Description} />
            </>
          )}
        {category === DealRotorAuditCategory.AgreementAttributes && entityBefore && entityAfter && (
          <>
            <ChangeFromTo
              name="BOVE"
              from={boveNamesOrdered[entityBefore.BOVE]}
              to={boveNamesOrdered[entityAfter.BOVE]}
            />
            <ChangeFromTo
              name="Total agreement value"
              from={`${entityBefore.Value ?? ''} ${currencyCodeNamesOrdered[entityBefore.CurrencyCode] ?? ''}`.trim()}
              to={`${entityAfter.Value ?? ''} ${currencyCodeNamesOrdered[entityAfter.CurrencyCode] ?? ''}`.trim()}
            />
          </>
        )}
      </div>
      <div>
        <FormatDate className="fs-14 text-secondary" date={createdDate} formatValue="HH:mm:ss" />
      </div>
    </div>
  );
}

type Props = {
  auditTupleList: [string, DealRotorAuditDto[]][];
};
function AuditList({ auditTupleList }: Props) {
  return auditTupleList.map(([dateLocalStartDay, auditList]) => (
    <div className="mb-4" key={dateLocalStartDay}>
      <div className={classNames(styles.DateDivider, 'text-secondary text-center fs-14 mx-4 mb-1')}>
        <FormatDate className="bg-white px-3" date={dateLocalStartDay} />
      </div>
      {auditList.map((audit) => (
        <Audit audit={audit} key={audit.id} />
      ))}
    </div>
  ));
}

export default AuditList;
