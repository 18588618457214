import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Link, generatePath } from 'react-router-dom';
import { RoutePath } from 'src/router';

import Avatar from 'src/components/Avatar';
import { ViewParticipantsIcon } from 'src/components/icons';

import { UserSummaryDto } from 'src/types';

type Props = {
  isAllowedUserReadContacts: boolean;
  participants: UserSummaryDto[];
};

function AgreementDetailsParticipants({ isAllowedUserReadContacts, participants }: Props) {
  return participants.length > 0 ? (
    <OverlayTrigger
      placement="bottom"
      rootClose={true}
      trigger={['click']}
      overlay={
        <Popover className=" mb-3">
          <ul className="list-unstyled mb-0 py-2">
            {participants.map(({ id, firstName, lastName, logoUrl }) => {
              const fullName = `${firstName} ${lastName}`;
              return (
                <li key={id}>
                  <div className="hstack gap-2 px-3 py-1">
                    <Avatar src={logoUrl} alt={fullName} />
                    {isAllowedUserReadContacts ? (
                      <Link className="fw-medium" to={generatePath(RoutePath.userContactById, { userId: id })}>
                        {fullName}
                      </Link>
                    ) : (
                      <span className="fw-medium">{fullName}</span>
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
        </Popover>
      }
    >
      <button type="button" className="btn btn-link hstack gap-2 fs-14 lh-1 fw-normal p-0">
        <ViewParticipantsIcon />
        <span>View participants</span>
      </button>
    </OverlayTrigger>
  ) : null;
}

export default AgreementDetailsParticipants;
