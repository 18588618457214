import classNames from 'classnames';
import { PropsWithChildren } from 'react';

import styles from './AuthCard.module.scss';

type Props = {
  title: string;
};

function AuthCard({ title, children }: PropsWithChildren<Props>) {
  return (
    <main className={classNames(styles.AuthCard, 'card mx-3')}>
      <header className="text-center">
        <img className="mb-4 w-100" src="/images/logo/app-logo.png" alt="bridgingx logo" width="320" height="25" />
        <h2 className={classNames(styles.Title, 'fs-22 pt-2 mb-4')}>{title}</h2>
      </header>
      {children}
      <footer className={classNames(styles.Footer, 'text-center fs-14')}>
        By proceeding, you agree to the BridgingX
        <br />
        <a href="/files/End_User_Licence_Agreement.pdf" target="_blank">
          End user licence agreement
        </a>{' '}
        and{' '}
        <a href="/files/Privacy_Notice.pdf" target="_blank">
          Privacy notice
        </a>
        .
      </footer>
    </main>
  );
}

export default AuthCard;
