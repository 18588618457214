import { useEffect, useMemo, useRef } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Spinner } from 'react-bootstrap';
import { useParams, Params } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { object, mixed } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Field from 'src/components/forms/Field';
import ServerErrorMessages from 'src/components/ServerErrorMessages';
import { PlusIcon } from 'src/components/icons';

import { useFormErrors } from 'src/hooks/FormHelpers';

import { uploadAgreementDocument } from 'src/api/base/agreement';

import { AgreementDocumentType, YupFormShape, ServerError } from 'src/types';

type Props = {
  agreementId: string;
  type: AgreementDocumentType;
  isAgreementEditDisabled: boolean;
};

type FormData = {
  file: FileList;
};
type FormShape = YupFormShape<FormData>;

function AgreementDetailsDocumentForm({ agreementId, type, isAgreementEditDisabled }: Props) {
  const queryClient = useQueryClient();
  const { dealRotorId = '' } = useParams<Params<'dealRotorId'>>();
  const formRef = useRef<HTMLFormElement>(null);

  const formSchema = useMemo(
    () =>
      object().shape<FormShape>({
        file:
          type === AgreementDocumentType.Agreement
            ? mixed<FileList>().label('Agreement document').fileRequired().fileExt(['.pdf']).fileSizeMax(10000000)
            : mixed<FileList>()
                .label('Source document')
                .fileExt(['.pdf', '.docx', '.doc', '.rtf'])
                .fileSizeMax(10000000),
      }),
    [type],
  );

  const { register, control, handleSubmit, formState, setError, clearErrors, watch } = useForm<FormData>({
    resolver: yupResolver(formSchema),
  });
  const { serverErrorMessages, handleErrors } = useFormErrors<FormData>(setError, clearErrors);
  const file = watch('file');

  useEffect(() => {
    if (formRef.current && file && file.length) {
      formRef.current.requestSubmit();
    }
  }, [file]);

  const onSubmit = handleSubmit(async ({ file }) => {
    if (file.length === 0) return;
    try {
      handleErrors();

      const formData = new FormData();
      formData.append('file', file[0]);
      await uploadAgreementDocument(agreementId, type, formData);

      await Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: ['getAgreementList', dealRotorId] }),
        queryClient.invalidateQueries({ queryKey: ['getDealRotorAuditListSearch', dealRotorId] }),
      ]);
      queryClient.removeQueries({ queryKey: ['getDealRotorListSearch'] });
      queryClient.removeQueries({ queryKey: ['getDealRotorAuditListAll'] });
    } catch (e) {
      handleErrors(e as ServerError);
    }
  });

  return (
    <form name="agreementDocumentForm" onSubmit={onSubmit} ref={formRef} noValidate>
      <Field
        field="document"
        label="Upload"
        placeholder={
          type === AgreementDocumentType.Agreement
            ? '(File format PDF and should be more than 10MB)'
            : '(File format PDF DOC DOCX RTF and should be more than 10MB)'
        }
        accept={type === AgreementDocumentType.Agreement ? '.pdf' : '.pdf, .docx, .doc, .rtf'}
        autoComplete="off"
        register={register('file')}
        control={control}
        formSchema={formSchema}
        errors={formState.errors}
        disabled={isAgreementEditDisabled}
        IconBefore={() => (formState.isSubmitting ? <Spinner variant="primary" size="sm" /> : <></>)}
        IconAfter={() => <PlusIcon />}
      />
      <ServerErrorMessages messages={serverErrorMessages} />
    </form>
  );
}

export default AgreementDetailsDocumentForm;
