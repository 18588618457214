import { Outlet } from 'react-router-dom';
import { useMemo } from 'react';

import HeaderConfig from 'src/pages/configuration/components/HeaderConfig';
import { RoutePath } from 'src/router';
import TabNavigation from 'src/components/TabNavigation';

export const TemplateManagementList = () => {
  const tabs = useMemo(
    () => [
      { name: 'Templates', to: RoutePath.configurationTemplatesManagementTemplates },
      { name: 'Parameters', to: RoutePath.configurationTemplatesManagementParameters },
    ],
    [],
  );

  return (
    <div>
      <TabNavigation className="mb-4 border-bottom" tabs={tabs} />
      <Outlet />
    </div>
  );
};

const TemplateConfig = () => {
  const breadcrumbPaths = [
    { name: 'Home', to: RoutePath.root },
    { name: 'Configuration', to: RoutePath.configurationRoot },
  ];

  return (
    <div className="h-100">
      <HeaderConfig className="mb-4" title="Templates management" breadcrumbPaths={breadcrumbPaths} />
      <Outlet />
    </div>
  );
};

export default TemplateConfig;
