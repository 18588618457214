import { AxiosRequestConfig } from 'axios';

import { baseApi } from 'src/api';
import {
  ChatSettingDto,
  ChatDealRotorQuestionAnswerDto,
  ChatAskModel,
  ChatQuestionResponseDto,
  UUIDString,
  GlobalChatAskModel,
  GlobalChatQuestionResponseDto,
} from 'src/types';

// PermissionNames.ChatRead
export const getChatGlobalSettings = (config: AxiosRequestConfig = {}) =>
  baseApi.get<ChatSettingDto>('/chat/settings', config);

// PermissionNames.ChatWrite
export const updateChatSettings = (data: ChatSettingDto) => baseApi.patch<void>('/chat/settings', data);

// PermissionNames.DealRotorRead
export const initChatForDealRotor = (dealRotorId: UUIDString, config: AxiosRequestConfig = {}) =>
  baseApi.post<void>(`/chat/deal-rotors/${dealRotorId}/init`, null, config);

// PermissionNames.DealRotorRead
export const askChatQuestion = (dealRotorId: UUIDString, data: ChatAskModel, config: AxiosRequestConfig = {}) =>
  baseApi.post<ChatQuestionResponseDto>(`/chat/deal-rotors/${dealRotorId}/ask`, data, config);

// PermissionNames.DealRotorRead
export const getChatDefaultQuestions = (dealRotorId: UUIDString, config: AxiosRequestConfig = {}) =>
  baseApi.get<ChatDealRotorQuestionAnswerDto[]>(`/chat/deal-rotors/${dealRotorId}/ask-default-questions`, config);

// RoleName.GlobalDealRotorManager
export const askGlobalChatQuestion = (data: GlobalChatAskModel, config: AxiosRequestConfig = {}) =>
  baseApi.post<GlobalChatQuestionResponseDto>('/chat/ask', data, config);
