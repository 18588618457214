import classNames from 'classnames';
import { useEffect, useRef } from 'react';

import { useForm } from 'react-hook-form';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useSelector, useDispatch } from 'src/store';
import { userIdSelector } from 'src/store/selectors/profileSelector';
import { dealRotorChatSliceSelector, dealRotorGlobalChatSliceSelector } from 'src/store/selectors/chatSelector';
import {
  addChatMessage,
  updateChatMessage,
  setChatHistory,
  removeLoadingChatMessage,
} from 'src/store/slices/dealRotorChatSlice';

import {
  addGlobalChatMessage,
  updateGlobalChatMessage,
  setGlobalChatHistory,
  removeLoadingGlobalChatMessage,
} from 'src/store/slices/globalChatSlice';

import Field from 'src/components/forms/Field';
import ServerErrorAdapter from 'src/utils/ServerErrorAdapter';

import AIConsoleChatMessage from './AIConsoleChat.message';

import { askChatQuestion, askGlobalChatQuestion } from 'src/api/base/chat';

import uuidv4 from 'src/utils/uuidv4';

import { ChatAskModel, GlobalChatAskModel, ChatQuestionType, ServerError, UUIDString, YupFormShape } from 'src/types';
import { ChatMessageModel, GlobalChatMessageModel, SenderType } from 'src/types/chat';

import styles from './AIConsoleChat.module.scss';

type Props = {
  dealRotorId: UUIDString;
};

type FormData = {
  question: string;
};
type FormShape = YupFormShape<FormData>;

const formSchema = object().shape<FormShape>({
  question: string().label('Message').nullable().trim().required(),
});

function AIConsoleChat({ dealRotorId }: Props) {
  const chatEndRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const userId = useSelector(userIdSelector);
  const { chatHistory = null, chatConversation = [] } = useSelector(dealRotorChatSliceSelector)[dealRotorId] ?? {};

  const { register, control, handleSubmit, formState, setValue } = useForm<FormData>({
    resolver: yupResolver(formSchema),
  });

  const scrollBottom = (behavior: ScrollBehavior = 'smooth') => {
    if (!chatEndRef.current) return;
    const chatLastEl = chatEndRef.current;
    setTimeout(() => {
      chatLastEl.scrollIntoView({ behavior });
    });
  };

  useEffect(() => {
    scrollBottom('instant');
  }, []);

  useEffect(() => {
    if (!dealRotorId) return;
    dispatch(removeLoadingChatMessage(dealRotorId));
  }, [dealRotorId, dispatch]);

  const onSubmit = handleSubmit(async ({ question }) => {
    const userMessage: ChatMessageModel = {
      id: uuidv4(),
      senderId: userId,
      senderType: SenderType.User,
      textMessage: question,
      isLoading: true,
      sourceDocuments: null,
      errors: null,
    };
    dispatch(addChatMessage({ dealRotorId, message: userMessage }));
    setValue('question', '');
    scrollBottom();

    try {
      const payload: ChatAskModel = { question, questionType: ChatQuestionType.Free, chatHistory };

      const res = await askChatQuestion(dealRotorId, payload);
      const answer = res.data.result.answer ?? 'No answer';
      const sourceDocuments = res.data.result.source_documents;
      const delRotorMessage: ChatMessageModel = {
        id: uuidv4(),
        senderId: dealRotorId,
        senderType: SenderType.DealRotor,
        textMessage: answer,
        isLoading: false,
        sourceDocuments,
        errors: null,
      };

      dispatch(updateChatMessage({ dealRotorId, messageId: userMessage.id, messagePatch: { isLoading: false } }));
      dispatch(addChatMessage({ dealRotorId, message: delRotorMessage }));
      dispatch(setChatHistory({ dealRotorId, chatHistory: res.data.chat_history }));
      scrollBottom();
    } catch (e) {
      const errors = new ServerErrorAdapter(e as ServerError).combine();
      setValue('question', question);
      dispatch(
        updateChatMessage({ dealRotorId, messageId: userMessage.id, messagePatch: { isLoading: false, errors } }),
      );
      scrollBottom();
    }
  });

  return (
    <div className="d-flex flex-column gap-3 h-100">
      <div className={classNames(styles.ScrollableChatBody, 'd-flex flex-column gap-3 flex-grow-1 custom-scroll')}>
        {chatConversation.map((msg) => (
          <AIConsoleChatMessage msg={msg} key={msg.id} />
        ))}
        <div className={styles.ChatEnd} ref={chatEndRef} />
      </div>
      <form className="position-relative" name="chatNewMessageForm" onSubmit={onSubmit} noValidate>
        <Field
          field="input"
          type="text"
          placeholder="Send a message"
          autoComplete="off"
          register={register('question')}
          control={control}
          formSchema={formSchema}
          errors={formState.errors}
          disabled={formState.isSubmitting}
          autoFocus
        />
        <button
          type="submit"
          className={classNames(styles.SendBtn, 'btn btn-primary btn-sm position-absolute')}
          disabled={formState.isSubmitting}
        >
          Send
        </button>
      </form>
    </div>
  );
}

export default AIConsoleChat;

export function AIConsoleGlobalChat() {
  const globalChatEndRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const userId = useSelector(userIdSelector);
  const { chatHistory = null, chatConversation = [] } = useSelector(dealRotorGlobalChatSliceSelector) ?? {};

  const { register, control, handleSubmit, formState, setValue } = useForm<FormData>({
    resolver: yupResolver(formSchema),
  });

  const scrollBottom = (behavior: ScrollBehavior = 'smooth') => {
    if (!globalChatEndRef.current) return;
    const chatLastEl = globalChatEndRef.current;
    setTimeout(() => {
      chatLastEl.scrollIntoView({ behavior });
    });
  };

  useEffect(() => {
    scrollBottom('instant');
  }, []);

  useEffect(() => {
    dispatch(removeLoadingGlobalChatMessage());
  }, [dispatch]);

  const onSubmit = handleSubmit(async ({ question }) => {
    const userMessage: GlobalChatMessageModel = {
      id: uuidv4(),
      senderId: userId,
      senderType: SenderType.User,
      textMessage: question,
      isLoading: true,
      sourceDocuments: null,
      errors: null,
    };
    dispatch(addGlobalChatMessage({ message: userMessage }));
    setValue('question', '');
    scrollBottom();

    try {
      const payload: GlobalChatAskModel = { question, chatHistory };

      const res = await askGlobalChatQuestion(payload);
      const answer = res.data.result ?? 'No answer';
      const systemId = '00000000-0000-0000-0000-000000000000';
      const systemMessage: GlobalChatMessageModel = {
        id: uuidv4(),
        senderId: systemId,
        senderType: SenderType.System,
        textMessage: answer,
        isLoading: false,
        sourceDocuments: null,
        errors: null,
      };

      dispatch(updateGlobalChatMessage({ messageId: userMessage.id, messagePatch: { isLoading: false } }));
      dispatch(addGlobalChatMessage({ message: systemMessage }));
      dispatch(setGlobalChatHistory({ chatHistory: res.data.chat_history }));
      scrollBottom();
    } catch (e) {
      const errors = new ServerErrorAdapter(e as ServerError).combine();
      setValue('question', question);
      dispatch(updateGlobalChatMessage({ messageId: userMessage.id, messagePatch: { isLoading: false, errors } }));
      scrollBottom();
    }
  });

  return (
    <div className="d-flex flex-column gap-3">
      <div
        className={classNames(styles.ScrollableGlobalChatBody, 'd-flex flex-column gap-3 flex-grow-1 custom-scroll')}
      >
        {chatConversation.map((msg) => (
          <AIConsoleChatMessage msg={msg} key={msg.id} />
        ))}
        <div className={styles.ChatEnd} ref={globalChatEndRef} />
      </div>
      <form className="position-relative" name="chatGlobalNewMessageForm" onSubmit={onSubmit} noValidate>
        <Field
          field="input"
          type="text"
          placeholder="Send a message"
          autoComplete="off"
          register={register('question')}
          control={control}
          formSchema={formSchema}
          errors={formState.errors}
          disabled={formState.isSubmitting}
          autoFocus
        />
        <button
          type="submit"
          className={classNames(styles.SendBtn, 'btn btn-primary btn-sm position-absolute')}
          disabled={formState.isSubmitting}
        >
          Send
        </button>
      </form>
    </div>
  );
}
