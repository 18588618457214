import Converter from 'src/utils/Converter';

export type Props = {
  date: string;
  formatValue?: string;
} & React.DetailedHTMLProps<React.TimeHTMLAttributes<HTMLTimeElement>, HTMLTimeElement>;

function FormatDate({ date, formatValue, ...props }: Props) {
  const formattedDate = Converter.getFormattedDate(date, formatValue);
  return (
    <time dateTime={date} {...props}>
      {formattedDate}
    </time>
  );
}

export default FormatDate;
