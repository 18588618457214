import { Fragment, useCallback, useMemo, useState } from 'react';
import { Offcanvas } from 'react-bootstrap';

import { ChatQuestionSourceDocumentDto } from 'src/types';

import styles from './AnswerSource.module.scss';

type AnswerSourceProps = {
  sourceDocuments: ChatQuestionSourceDocumentDto[] | null;
};

function AnswerSource({ sourceDocuments }: AnswerSourceProps) {
  const sourceDocumentsFiltered = useMemo(() => {
    if (!sourceDocuments) return [];
    return sourceDocuments.filter((d) => d.metadata.type === 'Document');
  }, [sourceDocuments]);

  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);
  const toggleOffcanvas = () => {
    setIsOffcanvasOpen((state) => !state);
  };
  const closeOffcanvas = useCallback(() => {
    setIsOffcanvasOpen(false);
  }, []);

  if (!sourceDocumentsFiltered.length) {
    return null;
  }

  return (
    <>
      <div>
        <button className="btn btn-link btn-sm p-0" type="button" onClick={toggleOffcanvas}>
          source
        </button>
      </div>

      <Offcanvas className={styles.Offcanvas} show={isOffcanvasOpen} onHide={closeOffcanvas} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Source info</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="custom-scroll">
          <ul className="list-unstyled mb-0">
            {sourceDocumentsFiltered.map((sourceDocument, index, arr) => {
              const { page } = sourceDocument.metadata;
              const fullDocName = sourceDocument.metadata.source ?? '';
              const docName =
                fullDocName.slice(0, fullDocName.lastIndexOf('_')) + fullDocName.slice(fullDocName.lastIndexOf('.'));
              const isLast = index + 1 === arr.length;

              return (
                <Fragment key={index}>
                  <li>
                    <div className="h6">{docName}</div>
                    {page !== null && <div className="h6">Page: {page + 1}</div>}
                    <blockquote className="mb-0">{sourceDocument.page_content}</blockquote>
                  </li>
                  {!isLast && <hr />}
                </Fragment>
              );
            })}
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default AnswerSource;
