import { NoteFileTypeIcon } from 'src/components/icons';
import {
  BmpFileTypeIcon,
  DocFileTypeIcon,
  DocxFileTypeIcon,
  GifFileTypeIcon,
  JpegFileTypeIcon,
  JpgFileTypeIcon,
  MsgFileTypeIcon,
  PdfFileTypeIcon,
  PngFileTypeIcon,
  PptFileTypeIcon,
  PptxFileTypeIcon,
  PstFileTypeIcon,
  RtfFileTypeIcon,
  TxtFileTypeIcon,
  XlsFileTypeIcon,
  XlsxFileTypeIcon,
} from 'src/components/icons/file-type';

type FileTypeIconProps = React.SVGProps<SVGSVGElement> & {
  ext?: string | null;
};
const FileTypeIcon = ({ ext, ...svgProps }: FileTypeIconProps) => {
  switch (ext) {
    case 'bmp':
      return <BmpFileTypeIcon {...svgProps} />;
    case 'doc':
      return <DocFileTypeIcon {...svgProps} />;
    case 'docx':
      return <DocxFileTypeIcon {...svgProps} />;
    case 'gif':
      return <GifFileTypeIcon {...svgProps} />;
    case 'jpeg':
      return <JpegFileTypeIcon {...svgProps} />;
    case 'jpg':
      return <JpgFileTypeIcon {...svgProps} />;
    case 'msg':
      return <MsgFileTypeIcon {...svgProps} />;
    case 'pdf':
      return <PdfFileTypeIcon {...svgProps} />;
    case 'png':
      return <PngFileTypeIcon {...svgProps} />;
    case 'ppt':
      return <PptFileTypeIcon {...svgProps} />;
    case 'pptx':
      return <PptxFileTypeIcon {...svgProps} />;
    case 'pst':
      return <PstFileTypeIcon {...svgProps} />;
    case 'rtf':
      return <RtfFileTypeIcon {...svgProps} />;
    case 'txt':
      return <TxtFileTypeIcon {...svgProps} />;
    case 'xls':
      return <XlsFileTypeIcon {...svgProps} />;
    case 'xlsx':
      return <XlsxFileTypeIcon {...svgProps} />;

    default:
      return <NoteFileTypeIcon {...svgProps} />;
  }
};

export default FileTypeIcon;
