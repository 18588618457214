import classNames from 'classnames';
import { useMemo } from 'react';
import { NavLink, To } from 'react-router-dom';
import { RoutePath } from 'src/router';

import useUserPermission from 'src/hooks/useUserPermission';

import {
  AnalyticsMenuIcon,
  ConfigurationMenuIcon,
  DashboardMenuIcon,
  DealParticipantMenuIcon,
  DealRotorMenuIcon,
  GlobalCognitiveMenuIcon,
  PartyBManagementMenuIcon,
} from 'src/components/icons/menu';

import { PermissionNames, RoleName } from 'src/types';

import styles from './Sidebar.module.scss';
import useUserRole from 'src/hooks/useUserRole';

type Nav = {
  name: string | null;
  to: To;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  allowedPermissions?: PermissionNames[];
  allowedRoles?: RoleName[];
};

function Sidebar() {
  const { hasUserAllowedRoles } = useUserRole();
  const { hasUserAllowedPermissions } = useUserPermission();
  const navigationConfig = useMemo<Nav[]>(
    () =>
      [
        {
          name: 'Deals in motion',
          to: RoutePath.dashboard,
          Icon: DashboardMenuIcon,
        },
        {
          name: 'Deal rotors',
          to: RoutePath.dealRotorRoot,
          Icon: DealRotorMenuIcon,
          allowedPermissions: [
            PermissionNames.DealRotorCreate,
            PermissionNames.DealRotorRead,
            PermissionNames.DealRotorAuditRead,
            PermissionNames.DealRotorNotificationsRead,
            PermissionNames.DealRotorDetailsWrite,
            PermissionNames.DealRotorTypeUpdate,
            PermissionNames.DealRotorDeactivate,
            PermissionNames.AgreementAttributesRead,
            PermissionNames.AgreementAttributesWrite,
            PermissionNames.DealRotorAgreementWrite,
            PermissionNames.DealRotorAgreementSign,
            PermissionNames.DealRotorAttachmentWrite,
            PermissionNames.DealRotorTransferOwner,
            PermissionNames.DealRotorTransferOperationOwner,
            PermissionNames.DealRotorUpdateManagers,
            PermissionNames.DealRotorUpdateOperationManagers,
            PermissionNames.DealRotorUpdateSignatories,
          ],
        },
        {
          name: 'Global AI console',
          to: RoutePath.globalAIConsole,
          Icon: GlobalCognitiveMenuIcon,
          allowedRoles: [RoleName.GlobalDealRotorManager],
        },
        {
          name: 'Deal participants',
          to: RoutePath.dealParticipantRoot,
          Icon: DealParticipantMenuIcon,
          allowedPermissions: [PermissionNames.DealRotorRead],
        },
        {
          name: 'Party B management',
          to: RoutePath.partyBManagementRoot,
          Icon: PartyBManagementMenuIcon,
          allowedPermissions: [PermissionNames.OrganisationRead, PermissionNames.OrganisationWrite],
        },
        {
          name: 'Analytics & Insights',
          to: RoutePath.analyticsRoot,
          Icon: AnalyticsMenuIcon,
        },
        {
          name: 'Configuration',
          to: RoutePath.configurationRoot,
          Icon: ConfigurationMenuIcon,
          allowedPermissions: [
            PermissionNames.UserRead,
            PermissionNames.UserWrite,
            PermissionNames.RoleRead,
            PermissionNames.RoleWrite,
            PermissionNames.NotificationWrite,
            PermissionNames.TemplateWrite,
          ],
        },
      ].filter(({ allowedPermissions, allowedRoles }) => {
        return hasUserAllowedPermissions(allowedPermissions) && hasUserAllowedRoles(allowedRoles);
      }),
    [hasUserAllowedPermissions, hasUserAllowedRoles],
  );

  return (
    <aside className={classNames(styles.Sidebar, 'd-flex flex-column custom-scroll pt-3')}>
      <nav className={styles.Navigation} role="navigation" aria-label="sidebar navigation">
        <ul className="nav flex-column">
          {navigationConfig.map(({ name, to, Icon }) => (
            <li className="nav-item" key={name}>
              <NavLink className="nav-link d-flex flex-column align-items-center text-center fw-semibold" to={to}>
                <Icon />
                <span>{name}</span>
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </aside>
  );
}

export default Sidebar;
