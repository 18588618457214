import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { useParams, Params } from 'react-router-dom';

import PartyBManagementForm from 'src/pages/party-b-management/components/PartyBManagement.form';

import { getOrganisationById } from 'src/api/base/organisation';
import { getExistPartyBUsers } from 'src/api/base/user';

const PartyBManagementProfile = () => {
  const { organisationId } = useParams<Params<'organisationId'>>();

  const {
    data: organisation,
    error: organisationError,
    refetch: organisationRefetch,
  } = useQuery({
    queryKey: ['getOrganisationById', organisationId],
    async queryFn({ signal }) {
      if (!organisationId) return null;
      const config = { signal };
      const res = await getOrganisationById(organisationId, config);
      return res.data;
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  const existUsersSummery = useQuery({
    queryKey: ['getExistPartyBUsersSummaryAll', organisationId],
    async queryFn({ signal }) {
      const config = { signal };
      const excludeOrganisationId = organisationId || undefined;
      const params = {
        excludeOrganisationId,
      };
      const res = await getExistPartyBUsers(params, config);
      return res.data.items;
    },
    initialData: [],
    placeholderData: keepPreviousData,
    retry: false,
    refetchOnWindowFocus: false,
  });

  return (
    <PartyBManagementForm
      organisation={organisation}
      organisationError={organisationError}
      organisationRefetch={organisationRefetch}
      organisationId={organisationId}
      organisationExistUsers={existUsersSummery.data}
    />
  );
};

export default PartyBManagementProfile;
