import classNames from 'classnames';
import { useMemo } from 'react';
import { OverlayTrigger, Tooltip, ProgressBar } from 'react-bootstrap';
import { Link, generatePath, createSearchParams } from 'react-router-dom';

import {
  AgreementStage,
  DashboardDealsDto,
  AgreementState,
  DealRotorRelationshipType,
  DealRotorStatus,
} from 'src/types';
import { RoutePath } from 'src/router';

import styles from 'src/pages/dashboard/components/Summary.module.scss';

type SummaryBarProps = {
  data: DashboardDealsDto | undefined;
  filters: {
    relationshipType?: DealRotorRelationshipType;
    partyBs?: string[];
  };
};

const SummaryBar = ({ data, filters }: SummaryBarProps) => {
  const dataByStage = useMemo(() => {
    const preStage = data?.stages?.find((el) => el.stage === AgreementStage.Pre);
    const activeStage = data?.stages?.find((el) => el.stage === AgreementStage.Active);
    const postStage = data?.stages?.find((el) => el.stage === AgreementStage.Post);

    const getAgreementStage = (agreementStage: AgreementStage, stageName: AgreementState) => {
      switch (agreementStage) {
        case AgreementStage.Pre:
          return preStage?.agreementStates.find((el) => el.state === stageName)?.count || 0;
        case AgreementStage.Active:
          return activeStage?.agreementStates.find((el) => el.state === stageName)?.count || 0;
        case AgreementStage.Post:
          return postStage?.agreementStates.find((el) => el.state === stageName)?.count || 0;

        default:
          return 0;
      }
    };

    const getStageProgress = (agreementStage: AgreementStage) => {
      const totalDeals = data?.totalActiveDeals;
      switch (agreementStage) {
        case AgreementStage.Pre:
          return preStage?.count && totalDeals ? Math.round((preStage.count / totalDeals) * 100) : 0;
        case AgreementStage.Active:
          return activeStage?.count && totalDeals ? Math.round((activeStage.count / totalDeals) * 100) : 0;
        case AgreementStage.Post:
          return postStage?.count && totalDeals ? Math.round((postStage.count / totalDeals) * 100) : 0;

        default:
          return 0;
      }
    };

    return {
      [AgreementStage.Pre]: {
        total: preStage?.count || 0,
        progress: getStageProgress(AgreementStage.Pre),
        [AgreementState.Shaping]: getAgreementStage(AgreementStage.Pre, AgreementState.Shaping),
        [AgreementState.Formalisation]: getAgreementStage(AgreementStage.Pre, AgreementState.Formalisation),
      },
      [AgreementStage.Active]: {
        total: activeStage?.count || 0,
        progress: getStageProgress(AgreementStage.Active),
        [AgreementState.DeliveryWithoutEvents]: getAgreementStage(
          AgreementStage.Active,
          AgreementState.DeliveryWithoutEvents,
        ),
        [AgreementState.DeliveryWithEvents]: getAgreementStage(
          AgreementStage.Active,
          AgreementState.DeliveryWithEvents,
        ),
      },
      [AgreementStage.Post]: {
        total: postStage?.count || 0,
        progress: getStageProgress(AgreementStage.Post),
        [AgreementState.PostCompletion]: getAgreementStage(AgreementStage.Post, AgreementState.PostCompletion),
      },
    };
  }, [data?.stages, data?.totalActiveDeals]);

  const filterSearchParams = useMemo(() => {
    const params = { status: DealRotorStatus.Active };
    if (filters.relationshipType) {
      Object.assign(params, { relationshipType: filters.relationshipType });
    }
    if (filters.partyBs) {
      Object.assign(params, { partyB: filters.partyBs });
    }
    return params;
  }, [filters.partyBs, filters.relationshipType]);

  return (
    <div className="row g-0 mb-3">
      <div className={classNames(styles.CardDisabled, styles.CardWithArrow, 'card rounded-0 vstack col-sm-6 col-lg-3')}>
        <div className="card-body d-flex flex-column justify-content-between mb-2">
          <h2 className="card-title h5">Total active deals</h2>
          <div className="d-flex flex-row mb-4 align-items-center">
            <div className="card-text fs-2 fw-semibold me-2">{data?.totalActiveDeals || 0}</div>
            <p className="card-text fs-14">Sum of pre, active and post agreements</p>
          </div>
        </div>
      </div>

      <div className={classNames(styles.CardWithArrow, 'card rounded-0 vstack col-sm-6 col-lg-3 z-2')}>
        <div className="card-body d-flex flex-column justify-content-between">
          <h2 className="card-title h5">
            <Link
              to={generatePath(
                `${RoutePath.dealRotorList}?${createSearchParams({
                  agreementStage: AgreementStage.Pre,
                  ...filterSearchParams,
                })}`,
              )}
            >
              {`${AgreementStage.Pre} agreement stage`}
            </Link>
          </h2>
          <div className="d-flex flex-row mb-3">
            <div className="card-text fs-2 fw-semibold me-2">{dataByStage[AgreementStage.Pre].total}</div>
            <div className="card-text fs-14 text-secondary align-self-center">
              <p className="m-0">{`Shaping - ${dataByStage[AgreementStage.Pre][AgreementState.Shaping]}`}</p>
              <p className="m-0">{`Formalisation - ${
                dataByStage[AgreementStage.Pre][AgreementState.Formalisation]
              }`}</p>
            </div>
          </div>

          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip>{`${dataByStage[AgreementStage.Pre].progress}%`}</Tooltip>}
          >
            <ProgressBar variant="yellow" now={dataByStage[AgreementStage.Pre].progress} />
          </OverlayTrigger>
        </div>
      </div>

      <div className={classNames(styles.CardWithArrow, 'card rounded-0 vstack col-sm-6 col-lg-3 z-1')}>
        <div className="card-body d-flex flex-column justify-content-between">
          <h2 className="card-title h5">
            <Link
              to={generatePath(
                `${RoutePath.dealRotorList}?${createSearchParams({
                  agreementStage: AgreementStage.Active,
                  ...filterSearchParams,
                })}`,
              )}
            >
              {`${AgreementStage.Active} agreement stage`}
            </Link>
          </h2>
          <div className="d-flex flex-row mb-3">
            <div className="card-text fs-2 fw-semibold me-2">{dataByStage[AgreementStage.Active].total}</div>
            <div className="card-text fs-14 text-secondary align-self-center">
              <p className="m-0">{`Delivery - ${
                dataByStage[AgreementStage.Active][AgreementState.DeliveryWithoutEvents]
              }`}</p>
              <p className="m-0">{`Events and changes - ${
                dataByStage[AgreementStage.Active][AgreementState.DeliveryWithEvents]
              }`}</p>
            </div>
          </div>

          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip>{`${dataByStage[AgreementStage.Active].progress}%`}</Tooltip>}
          >
            <ProgressBar variant="success" now={dataByStage[AgreementStage.Active].progress} />
          </OverlayTrigger>
        </div>
        <div className={classNames(styles.ArrowRight)}></div>
      </div>

      <div className="card rounded-0 vstack col-sm-6 col-lg-3">
        <div className="card-body d-flex flex-column justify-content-between">
          <h2 className="card-title h5">
            <Link
              to={generatePath(
                `${RoutePath.dealRotorList}?${createSearchParams({
                  agreementStage: AgreementStage.Post,
                  ...filterSearchParams,
                })}`,
              )}
            >
              {`${AgreementStage.Post} agreement stage`}
            </Link>
          </h2>
          <div className="d-flex flex-row mb-3">
            <div className="card-text fs-2 fw-semibold me-2">{dataByStage[AgreementStage.Post].total}</div>
            <div className="card-text fs-14 text-secondary align-self-center">
              <p className="m-0">{`Completion - ${dataByStage[AgreementStage.Post][AgreementState.PostCompletion]}`}</p>
            </div>
          </div>

          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip>{`${dataByStage[AgreementStage.Post].progress}%`}</Tooltip>}
          >
            <ProgressBar variant="warning" now={dataByStage[AgreementStage.Post].progress} />
          </OverlayTrigger>
        </div>
        <div className={classNames(styles.ArrowRight)}></div>
      </div>
    </div>
  );
};

export default SummaryBar;
