import { AxiosRequestConfig } from 'axios';

import { baseApi } from 'src/api';
import {
  UUIDString,
  CustomNotificationDto,
  CreateCustomNotificationModel,
  UpdateCustomNotificationModel,
} from 'src/types';

// IgnorePermissions
export const getAllDealNotification = (dealRotorId: UUIDString, config: AxiosRequestConfig = {}) =>
  baseApi.get<CustomNotificationDto[]>(`/custom-notifications/deal-rotors/${dealRotorId}`, config);

// IgnorePermissions
export const createDealNotification = (
  dealRotorId: UUIDString,
  data: CreateCustomNotificationModel,
  config: AxiosRequestConfig = {},
) => baseApi.post<UUIDString>(`/custom-notifications/${dealRotorId}`, data, config);

// IgnorePermissions
export const updateDealNotification = (
  notificationId: UUIDString,
  data: UpdateCustomNotificationModel,
  config: AxiosRequestConfig = {},
) => baseApi.patch<void>(`/custom-notifications/${notificationId}`, data, config);

// IgnorePermissions
export const removeNotification = (notificationId: UUIDString, config: AxiosRequestConfig = {}) =>
  baseApi.delete<void>(`/custom-notifications/${notificationId}`, config);
