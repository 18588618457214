import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import SpinnerLoader from 'src/components/SpinnerLoader';

import { RoutePath } from 'src/router';
import { logout } from 'src/hocs/Auth';

function Logout() {
  const navigate = useNavigate();
  useEffect(() => {
    logout();
    navigate(RoutePath.login);
  }, [navigate]);

  return <SpinnerLoader />;
}

export default Logout;
