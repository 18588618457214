import classNames from 'classnames';
import { useMemo } from 'react';
import { UseFormSetValue, UseFormGetValues } from 'react-hook-form';

import Converter from 'src/utils/Converter';
import jsxToText from 'src/utils/jsxToText';

import { SelectOption } from 'src/types';

import styles from './ActiveFilterList.module.scss';

export type ActiveFilter = {
  name: string;
  title: string;
  value: string | string[] | Date | undefined;
  options?: SelectOption[];
};

type Tag = {
  title: string;
  fieldName: string;
  fieldLabel: string | JSX.Element;
  fieldValue: string;
};

type Props = {
  filters: ActiveFilter[];
  total: number;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
};

function ActiveFilterList({ filters, total, setValue, getValues }: Props) {
  const tagList = useMemo(
    () =>
      filters.reduce<Tag[]>((acc, filter) => {
        if (!filter.value) return acc;

        if (Array.isArray(filter.value)) {
          const tags = filter.value.map<Tag>((filterValue) => ({
            title: filter.title,
            fieldName: filter.name,
            fieldLabel: filter.options
              ? filter.options.find(({ value }) => filterValue === value)?.label ?? filterValue
              : filterValue,
            fieldValue: filterValue,
          }));
          return [...acc, ...tags];
        }

        if (typeof filter.value === 'string') {
          const tag: Tag = {
            title: filter.title,
            fieldName: filter.name,
            fieldLabel: filter.options
              ? filter.options.find(({ value }) => filter.value === value)?.label ?? filter.value
              : filter.value,
            fieldValue: filter.value,
          };
          return [...acc, tag];
        }
        if (filter.value instanceof Date) {
          const dateISO = filter.value.toISOString();
          const tag: Tag = {
            title: filter.title,
            fieldName: filter.name,
            fieldLabel: Converter.getFormattedDate(dateISO),
            fieldValue: dateISO,
          };
          return [...acc, tag];
        }
        return acc;
      }, []),
    [filters],
  );

  const removeTag = ({ fieldName, fieldValue }: Pick<Tag, 'fieldName' | 'fieldValue'>) => {
    const currentFieldValue: string | string[] | undefined = getValues(fieldName);
    if (Array.isArray(currentFieldValue)) {
      const index = currentFieldValue.indexOf(fieldValue);
      if (index !== -1) {
        const newValue = currentFieldValue.slice();
        newValue.splice(index, 1);
        setValue(fieldName, newValue);
      }
    } else {
      setValue(fieldName, '');
    }
    if (fieldName === 'agreementTransactionType') {
      setValue('daysRange', '');
    }
  };

  const removeAllTags = () => {
    tagList
      .map(({ fieldName }) => fieldName)
      .filter((v, i, a) => a.indexOf(v) === i)
      .forEach((fieldName) => {
        const currentFieldValue: string | string[] | undefined = getValues(fieldName);
        if (Array.isArray(currentFieldValue)) {
          setValue(fieldName, []);
        } else {
          setValue(fieldName, '');
        }
        if (fieldName === 'agreementTransactionType') {
          setValue('daysRange', '');
        }
      });
  };

  return tagList.length > 0 ? (
    <div className="p-3 border-top">
      <div className="hstack flex-wrap gap-3">
        <strong className="fw-normal">{total} search results:</strong>
        {tagList.map(({ title, fieldName, fieldLabel, fieldValue }) => (
          <div
            className={classNames(styles.Tag, 'hstack text-nowrap gap-2 h-44')}
            title={`${title}: ${jsxToText(fieldLabel)}`}
            key={`${fieldName}_${fieldValue}`}
          >
            <span>{fieldLabel}</span>
            <button
              className="tag-delete"
              type="button"
              aria-label={`Remove filter - ${title}: ${fieldLabel}`}
              onClick={() => removeTag({ fieldName, fieldValue })}
            ></button>
          </div>
        ))}
        {tagList.length > 0 && (
          <button className="btn btn-link px-0" type="button" onClick={removeAllTags}>
            Clear all
          </button>
        )}
      </div>
    </div>
  ) : null;
}

export default ActiveFilterList;
