import { AxiosRequestConfig } from 'axios';

import { baseApi } from 'src/api';

import {
  TemplateDto,
  UUIDString,
  TemplateModel,
  UpdateTemplateModel,
  TemplateWithUserDtoDataPagedDto,
  GetTemplateDto,
} from 'src/types';

import RequestSearchParamsAdapter from 'src/utils/RequestSearchParamsAdapter';

export const getAllTemplates = (config: AxiosRequestConfig = {}) => {
  return baseApi.get<TemplateDto[]>('/templates', config);
};

export const createTemplate = (data: TemplateModel, config: AxiosRequestConfig = {}) => {
  return baseApi.post<UUIDString>('/templates', data, config);
};

export const getTemplateById = (templateId: UUIDString, config: AxiosRequestConfig = {}) => {
  return baseApi.get<GetTemplateDto>(`/templates/${templateId}`, config);
};

export const updateTemplate = (templateId: UUIDString, data: UpdateTemplateModel, config: AxiosRequestConfig = {}) => {
  return baseApi.patch<void>(`/templates/${templateId}`, data, config);
};

export const removeTemplate = (templateId: UUIDString, config: AxiosRequestConfig = {}) => {
  return baseApi.delete<void>(`/templates/${templateId}`, config);
};

export const getTemplateListSearch = (params: RequestSearchParamsAdapter, config: AxiosRequestConfig = {}) => {
  return baseApi.get<TemplateWithUserDtoDataPagedDto>('/templates/search', { ...config, params });
};
