import { useMemo } from 'react';
import { Link, generatePath } from 'react-router-dom';
import { RoutePath } from 'src/router';

import Table, { createColumnHelper } from 'src/components/Table';

import useUserPermission from 'src/hooks/useUserPermission';

import { UserOrganisationDto, PermissionNames } from 'src/types';

type Props = {
  isLoading: boolean;
  organisations: UserOrganisationDto[];
};

const columnHelper = createColumnHelper<UserOrganisationDto>();

function ContactDetailsList({ isLoading, organisations }: Props) {
  const { hasUserPermission } = useUserPermission();
  const isAllowedOrganisationRead = hasUserPermission(PermissionNames.OrganisationRead);

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        id: 'name',
        header: 'Name',
        cell: ({ getValue, row }) => (
          <div className="d-flex align-items-center gap-2">
            {isAllowedOrganisationRead ? (
              <Link
                className="fw-medium"
                to={generatePath(RoutePath.partyBManagementById, { organisationId: row.original.id })}
              >
                {getValue()}
              </Link>
            ) : (
              <span className="fw-medium">{getValue()}</span>
            )}
          </div>
        ),
      }),
      columnHelper.accessor('title', {
        id: 'title',
        header: 'Title',
        cell: ({ getValue }) => getValue() || 'N/A',
      }),
      columnHelper.accessor('phone', {
        id: 'phone',
        header: 'Phone',
        cell: ({ getValue }) => {
          const phone = getValue();
          return phone ? <Link to={`tel:${phone.replace(/[\s()]/g, '')}`}>{phone}</Link> : 'N/A';
        },
      }),
      columnHelper.accessor('mobile', {
        id: 'mobile',
        header: 'Mobile',
        cell: ({ getValue }) => {
          const mobile = getValue();
          return mobile ? <Link to={`tel:${mobile.replace(/[\s()]/g, '')}`}>{mobile}</Link> : 'N/A';
        },
      }),
      columnHelper.accessor('externalNumber', {
        id: 'externalNumber',
        header: 'External number',
        cell: ({ getValue }) => getValue() || 'N/A',
      }),
    ],
    [isAllowedOrganisationRead],
  );

  return (
    <section>
      <h2 className="h5 mb-4">User's organisations</h2>
      <Table
        className="table-border-bottom-none mb-0"
        wrapperClassName="border rounded mb-3"
        data={organisations}
        total={organisations.length}
        columns={columns}
        isLoading={isLoading}
      />
    </section>
  );
}

export default ContactDetailsList;
