import { string, array, mixed, addMethod, StringSchema, ArraySchema, MixedSchema, AnyObject } from 'yup';
import Converter from 'src/utils/Converter';

addMethod<StringSchema>(string, 'nDigitCode', function (n: number = 6) {
  return this.matches(/^\d+$/, ({ label }) => `${label} should be only digits`)
    .min(n, ({ label, min }) => `${label} should be exactly ${min} digits`)
    .max(n, ({ label, max }) => `${label} should be exactly ${max} digits`);
});

addMethod<StringSchema>(string, 'emptyToUndefined', function () {
  return this.transform((value) => (value && value.length ? value : undefined));
});

addMethod<ArraySchema<any[] | undefined, AnyObject, undefined, ''>>(array, 'emptyToUndefined', function () {
  return this.transform((value) => (value && value.length ? value : undefined));
});

addMethod<StringSchema>(string, 'isLatinLowercase', function () {
  return this.test(
    'isLatinLowercase',
    ({ label }) => `${label} must contain latin characters in lower case`,
    (value) => (value ? /^[a-z]+$/.test(value) : true),
  );
});

addMethod<MixedSchema<FileList>>(mixed as any, 'fileRequired', function () {
  return this.test(
    'required',
    ({ label, path }) => `${label || path} is a required field`,
    (value) => (value ? value.length > 0 : true),
  ).required();
});

addMethod<MixedSchema<FileList>>(mixed as any, 'fileExt', function (fileSupportedExtensions: string[]) {
  return this.test(
    'fileExt',
    ({ label, path }) =>
      `${label || path} has an unsupported file type. The supported types: ${fileSupportedExtensions
        .map((ext) => `"${ext}"`)
        .join(', ')}.`,
    (value: FileList) => {
      return Array.prototype.every.call(
        value,
        (file: File) => !!fileSupportedExtensions.find((ext) => file.name.endsWith(ext)),
      );
    },
  );
});

addMethod<MixedSchema<FileList>>(mixed as any, 'fileFormat', function (fileSupportedFormats: string[]) {
  return this.test(
    'fileFormat',
    ({ label, path }) =>
      `${label || path} has an unsupported file format. The supported formats: ${fileSupportedFormats
        .map((mimeType) => `"${mimeType}"`)
        .join(', ')}.`,
    (value: FileList) => {
      return Array.prototype.every.call(value, (file: File) => fileSupportedFormats.includes(file.type));
    },
  );
});

addMethod<MixedSchema<FileList>>(mixed as any, 'fileSizeMax', function (maxSizeInBytes: number) {
  return this.test(
    'fileSizeMax',
    ({ label, path }) =>
      `${label || path} file size is to big. Max file size must be less then ${Converter.formatBytes(maxSizeInBytes)}.`,
    (value) => {
      return Array.prototype.every.call(value, (file) => file.size < maxSizeInBytes);
    },
  );
});
