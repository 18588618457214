import { Routes, Route, Navigate } from 'react-router-dom';

import UserProfile from 'src/pages/profile/UserProfile';
import UserProfileSettings from 'src/pages/profile/UserProfileSettings';

function UserProfileRoutes() {
  // '/profile/*'
  return (
    <Routes>
      <Route path=":userId" element={<UserProfile />} />
      <Route path="settings/*" element={<UserProfileSettings />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default UserProfileRoutes;
