import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { PermissionNames } from 'src/types';

export const profileSliceSelector = (state: RootState) => state.profile;

export const userSelector = createSelector(profileSliceSelector, (state) => state.user);
export const userIdSelector = createSelector(profileSliceSelector, (state): string => state.user?.id ?? '');
export const userFullNameSelector = createSelector(profileSliceSelector, (state): string => state.user?.fullName ?? '');
export const userSignatureFilenameSelector = createSelector(
  profileSliceSelector,
  (state): string | null => state.user?.signatureFilename ?? null,
);
export const profileUserSelector = createSelector(profileSliceSelector, (state) => {
  const { fullName, id, logoUrl } = state.user || {};
  return { fullName, id, logoUrl };
});
export const userPermissionsSelector = createSelector(
  profileSliceSelector,
  (state): PermissionNames[] => state.user?.permissions ?? [],
);
export const userRolesSelector = createSelector(profileSliceSelector, (state): string[] => state.user?.roles ?? []);
export const userMFAEnabledSelector = createSelector(
  profileSliceSelector,
  (state): boolean => state.mfaEnabled ?? false,
);
