import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { QRCodeSVG } from 'qrcode.react';

import { useForm } from 'react-hook-form';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Field from 'src/components/forms/Field';
import LoadingButton from 'src/components/buttons/LoadingButton';
import ServerErrorMessages from 'src/components/ServerErrorMessages';
import { CopyIcon, InfoIcon } from 'src/components/icons';

import { loginUserRequest } from 'src/hocs/Auth';

import { useFormErrors } from 'src/hooks/FormHelpers';
import useMFA from '../hooks/useMFA';

import { envDomain } from 'src/utils/domain';

import { YupFormShape, ServerError } from 'src/types';

import styles from './SetupMFAForm.module.scss';

type Props = {
  email: string;
  password: string;
  secret: string;
};

type FormData = {
  code: string;
};
type FormShape = YupFormShape<FormData>;

const formSchema = object().shape<FormShape>({
  code: string().label('Code').trim().required().nDigitCode(),
});

function SetupMFAForm({ email, password, secret }: Props) {
  const { handleLoginStatus } = useMFA();
  const [isShowCopyTooltip, setIsShowCopyTooltip] = useState(false);

  const qrCodeString = useMemo<string>(() => {
    const issuer = `BridgingX (app.${envDomain})`;
    return `otpauth://totp/${email}?secret=${secret}&issuer=${issuer}`;
  }, [email, secret]);

  const { register, control, handleSubmit, formState, setError, clearErrors } = useForm<FormData>({
    resolver: yupResolver(formSchema),
  });
  const { serverErrorMessages, handleErrors } = useFormErrors<FormData>(setError, clearErrors);

  const onSubmit = handleSubmit(async ({ code }) => {
    try {
      handleErrors();
      const { status, secret } = await loginUserRequest(email, password, code);
      handleLoginStatus({ status, secret, email, password });
    } catch (e) {
      handleErrors(e as ServerError);
    }
  });

  const onCLickCopySecret = async () => {
    try {
      await navigator.clipboard.writeText(secret);
      setIsShowCopyTooltip(true);
      setTimeout(() => {
        setIsShowCopyTooltip(false);
      }, 4000);
    } catch (e) {}
  };

  return (
    <>
      <div className="text-center">
        <p>
          Please scan this QR code <br />
          with your verification App{' '}
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip className={styles.Tooltip}>
                We recommend installing a trusted Multi-Factor Authentication (MFA) application on your device before
                proceeding. Here are some popular options available for iOS and Android:
                <ul className="mb-0">
                  <li>
                    <strong>Google Authenticator</strong>: Available on both iOS and Android, Google Authenticator is a
                    widely used MFA application that generates time-based one-time passcodes (TOTP).
                  </li>
                  <li>
                    <strong>Microsoft Authenticator</strong>: Another reliable option, Microsoft Authenticator offers
                    both TOTP and push notification-based MFA capabilities, compatible with iOS and Android devices.
                  </li>
                </ul>
              </Tooltip>
            }
          >
            <InfoIcon className="text-primary" />
          </OverlayTrigger>
        </p>

        <div className="mb-3">
          <QRCodeSVG value={qrCodeString} size={150} />
        </div>
        <p className="mb-1">or you can enter this code manually:</p>
        <div className="hstack justify-content-end gap-2 mb-4">
          <code className={classNames(styles.Secret, 'd-inline-block bg-cultured rounded px-2 lh-1')}>{secret}</code>
          <OverlayTrigger show={isShowCopyTooltip} trigger={[]} placement="left" overlay={<Tooltip>Copied!</Tooltip>}>
            <button
              className={classNames(styles.CopyBtn, 'btn btn-link px-2 py-0')}
              type="button"
              aria-label="copy secret code"
              onClick={onCLickCopySecret}
            >
              <CopyIcon width={16} height={16} />
            </button>
          </OverlayTrigger>
        </div>
        <p>enter 6-digit code below</p>
      </div>

      <form name="setupMFAForm" onSubmit={onSubmit} noValidate>
        <div className="mb-4">
          <Field
            label=""
            field="input"
            type="text"
            inputMode="numeric"
            placeholder="Enter code"
            autoComplete="off"
            register={register('code')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
            autoFocus
          />
        </div>

        <div className="text-center mb-3">
          <LoadingButton
            type="submit"
            className="btn btn-primary w-100"
            tabIndex={3}
            isLoading={formState.isSubmitting}
          >
            Set MFA
          </LoadingButton>
        </div>
      </form>
      <ServerErrorMessages messages={serverErrorMessages} />
    </>
  );
}

export default SetupMFAForm;
