import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import Table, { createColumnHelper } from 'src/components/Table';
import ServerErrorMessages from 'src/components/ServerErrorMessages';
import ServerErrorAdapter from 'src/utils/ServerErrorAdapter';
import { InfoIcon } from 'src/components/icons';
import { groupedNotifications } from 'src/pages/configuration/components/NotificationConfig';
import { getMethodCell } from 'src/pages/configuration/components/NotificationConfig';
import Converter from 'src/utils/Converter';

import { getAllNotifications } from 'src/api/base/notification';

import { NotificationDto, NotificationType, ServerFormErrors, ServerError } from 'src/types';

const agreementColumnHelper = createColumnHelper<NotificationDto>();

const DealRotorEditNotificationsDealTab = () => {
  const notifications = useQuery({
    queryKey: ['getAllNotifications'],
    async queryFn({ signal }) {
      const config = { signal };
      const res = await getAllNotifications(config);

      const groupedNotifications = res.data
        .sort((a, b) => (a.source < b.source ? 1 : -1))
        .reduce((acc, notice) => {
          const { type } = notice;
          acc[type] = acc[type] ?? { description: '', collection: [] };
          acc[type].collection.push(notice);
          acc[type].description = notice.description;
          acc[type].name = notice.name;
          return acc;
        }, {} as groupedNotifications);
      return groupedNotifications;
    },
    initialData: {
      [NotificationType.AgreementActivation]: { name: '', description: '', collection: [] },
      [NotificationType.AgreementEndDateReminder]: { name: '', description: '', collection: [] },
      [NotificationType.AgreementMovedToEventsAndChanges]: { name: '', description: '', collection: [] },
      [NotificationType.AgreementSigningEvents]: { name: '', description: '', collection: [] },
      [NotificationType.DealRotorParticipantsChange]: { name: '', description: '', collection: [] },
      [NotificationType.DealRotorTermination]: { name: '', description: '', collection: [] },
    } as groupedNotifications,
    retry: false,
    refetchOnWindowFocus: false,
  });
  const daysColumn = useMemo(
    () => [
      agreementColumnHelper.accessor('triggerNoOfDaysBefore', {
        id: 'triggerNoOfDaysBefore',
        header: 'Number of days',
        enableSorting: false,
      }),
    ],
    [],
  );
  const agreementColumns = useMemo(
    () => [
      agreementColumnHelper.accessor('methods', {
        id: 'methods',
        header: 'Notification method',
        enableSorting: false,
        cell: ({ getValue, row }) => {
          const methodsValue = getValue();
          return getMethodCell(methodsValue);
        },
      }),
      agreementColumnHelper.accessor('roles', {
        id: 'roles',
        header: 'Roles',
        enableSorting: false,
        cell: ({ getValue }) => (
          <>
            <span className="me-3 align-middle">
              {getValue()
                .map((role) => Converter.stripToAbbreviation(role.displayName))
                .join(', ')}
            </span>
            <OverlayTrigger
              overlay={
                <Tooltip>
                  {getValue().map((role, index) => (
                    <div key={index}>{`${role.displayName} (${Converter.stripToAbbreviation(role.displayName)})`}</div>
                  ))}
                </Tooltip>
              }
            >
              <InfoIcon className="text-primary" />
            </OverlayTrigger>
          </>
        ),
      }),
    ],
    [],
  );

  const errorMessages = useMemo<ServerFormErrors>(() => {
    const paginatedDataErrorMessages = new ServerErrorAdapter(notifications.error as ServerError).combine();
    return paginatedDataErrorMessages;
  }, [notifications.error]);

  return (
    <>
      <div className="mb-4 bg-white border rounded">
        <div className="pt-3 px-3">
          <h2 className="h5">{notifications.data[NotificationType.AgreementEndDateReminder].name}</h2>
          <p className="text-secondary">{notifications.data[NotificationType.AgreementEndDateReminder].description}</p>
        </div>
        <hr />
        <Table
          className="table-bordered mb-0"
          wrapperClassName="border rounded-bottom mx-3 mb-3"
          data={notifications.data[NotificationType.AgreementEndDateReminder].collection}
          total={notifications.data[NotificationType.AgreementEndDateReminder].collection.length}
          columns={[...daysColumn, ...agreementColumns]}
          isShowPagination={false}
          pagination={{
            pageIndex: 0,
            pageSize: notifications.data[NotificationType.AgreementEndDateReminder].collection.length,
          }}
        />
      </div>
      <div className="mb-4 bg-white border rounded">
        <h2 className="h5 pt-3 px-3">Agreement status</h2>
        <hr />
        <div className="px-3">
          <h3 className="h6">{notifications.data[NotificationType.AgreementActivation].name}</h3>
          <p className="text-secondary">{notifications.data[NotificationType.AgreementActivation].description}</p>
          <Table
            className="table-bordered mb-0"
            wrapperClassName="border rounded-bottom"
            data={notifications.data[NotificationType.AgreementActivation].collection}
            total={notifications.data[NotificationType.AgreementActivation].collection.length}
            columns={agreementColumns}
            isShowPagination={false}
            pagination={{
              pageIndex: 0,
              pageSize: notifications.data[NotificationType.AgreementActivation].collection.length,
            }}
          />
        </div>
        <hr />
        <div className="px-3 mb-3">
          <h3 className="h6">{notifications.data[NotificationType.AgreementMovedToEventsAndChanges].name}</h3>
          <p className="text-secondary">
            {notifications.data[NotificationType.AgreementMovedToEventsAndChanges].description}
          </p>
          <Table
            className="table-bordered mb-0"
            wrapperClassName="border rounded-bottom"
            data={notifications.data[NotificationType.AgreementMovedToEventsAndChanges].collection}
            total={notifications.data[NotificationType.AgreementMovedToEventsAndChanges].collection.length}
            columns={agreementColumns}
            isShowPagination={false}
            pagination={{
              pageIndex: 0,
              pageSize: notifications.data[NotificationType.AgreementMovedToEventsAndChanges].collection.length,
            }}
          />
        </div>
      </div>
      <div className="mb-4 pb-3 bg-white border rounded">
        <div className="pt-3 px-3">
          <h2 className="h5">{notifications.data[NotificationType.AgreementSigningEvents].name}</h2>
          <p>{notifications.data[NotificationType.AgreementSigningEvents].description}</p>
        </div>
        <hr />
        <Table
          className="table-bordered mb-0"
          wrapperClassName="border rounded-bottom mx-3"
          data={notifications.data[NotificationType.AgreementSigningEvents].collection}
          total={notifications.data[NotificationType.AgreementSigningEvents].collection.length}
          columns={agreementColumns}
          isShowPagination={false}
          pagination={{
            pageIndex: 0,
            pageSize: notifications.data[NotificationType.AgreementSigningEvents].collection.length,
          }}
        />
      </div>
      <div className="mb-4 bg-white border rounded">
        <div className="pt-3 px-3">
          <h2 className="h5">{notifications.data[NotificationType.DealRotorParticipantsChange].name}</h2>
          <p>{notifications.data[NotificationType.DealRotorParticipantsChange].description}</p>
        </div>
        <hr />
        <Table
          className="table-bordered mb-0"
          wrapperClassName="border rounded-bottom mx-3 mb-3"
          data={notifications.data[NotificationType.DealRotorParticipantsChange].collection}
          total={notifications.data[NotificationType.DealRotorParticipantsChange].collection.length}
          columns={agreementColumns}
          isShowPagination={false}
          pagination={{
            pageIndex: 0,
            pageSize: notifications.data[NotificationType.DealRotorParticipantsChange].collection.length,
          }}
        />
      </div>
      <div className="bg-white border rounded">
        <div className="pt-3 px-3">
          <h2 className="h5">{notifications.data[NotificationType.DealRotorTermination].name}</h2>
          <p>{notifications.data[NotificationType.DealRotorTermination].description}</p>
        </div>
        <hr />
        <Table
          className="table-bordered mb-0"
          wrapperClassName="border rounded-bottom mx-3 mb-3"
          data={notifications.data[NotificationType.DealRotorTermination].collection}
          total={notifications.data[NotificationType.DealRotorTermination].collection.length}
          columns={agreementColumns}
          isShowPagination={false}
          pagination={{
            pageIndex: 0,
            pageSize: notifications.data[NotificationType.DealRotorTermination].collection.length,
          }}
        />
      </div>
      <ServerErrorMessages className="mx-3" messages={errorMessages} />
    </>
  );
};

export default DealRotorEditNotificationsDealTab;
