import { AxiosRequestConfig } from 'axios';
import { useState, useCallback, useMemo } from 'react';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { useParams, Params } from 'react-router-dom';

import { useDispatch, useSelector } from 'src/store';
import { fetchCurrentUser } from 'src/store/slices/profileSlice';
import { userIdSelector, userSignatureFilenameSelector } from 'src/store/selectors/profileSelector';

import useUserPermission from 'src/hooks/useUserPermission';

import ServerErrorAdapter from 'src/utils/ServerErrorAdapter';

import {
  getAgreementDetailsForSign,
  downloadLatestAgreementDocument,
  downloadSignPreviewAgreementDocument,
  signAgreement,
  rejectAgreement,
} from 'src/api/base/agreement';
import { downloadUserSignature, uploadUserSignature } from 'src/api/base/user';

import {
  SignAgreementModel,
  RejectAgreementModel,
  SignatureType,
  ServerError,
  ServerFormErrors,
  PermissionNames,
} from 'src/types';

import Notarise from './Notarise';

function NotarisePrivate() {
  const { hasUserPermission } = useUserPermission();
  const isAllowedDealRotorAgreementSign = hasUserPermission(PermissionNames.DealRotorAgreementSign);
  const isAllowedUserReadSignature = hasUserPermission(PermissionNames.UserReadSignature);

  const { agreementId = '' } = useParams<Params<'agreementId'>>();

  const dispatch = useDispatch();

  const userId = useSelector(userIdSelector);
  const userSignatureFilename = useSelector(userSignatureFilenameSelector);

  const refetchUserSignatureFilename = useCallback(() => {
    return dispatch(fetchCurrentUser());
  }, [dispatch]);

  const [signatureFilename, setSignatureFilename] = useState('');

  const {
    isLoading: isAgreementDetailsForSignLoading,
    data: agreementDetailsForSign,
    refetch: refetchAgreementDetailsForSign,
    error: agreementDetailsForSignError,
  } = useQuery({
    enabled: Boolean(agreementId),
    queryKey: ['getAgreementDetailsForSign', agreementId],
    async queryFn({ signal }) {
      const config = { signal };
      const res = await getAgreementDetailsForSign(agreementId, config);
      return res.data;
    },
    placeholderData: keepPreviousData,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const { dealRotorNumber, agreementSignatoryList, agreementDocumentList } = useMemo(
    () => ({
      dealRotorNumber: agreementDetailsForSign ? agreementDetailsForSign.dealRotorNumber : null,
      agreementSignatoryList: agreementDetailsForSign ? agreementDetailsForSign.signatories : null,
      agreementDocumentList: agreementDetailsForSign ? agreementDetailsForSign.documents : null,
    }),
    [agreementDetailsForSign],
  );

  const {
    isLoading: isLatestAgreementDocumentLoading,
    isFetching: isLatestAgreementDocumentFetching,
    data: latestAgreementDocument,
    refetch: refetchDownloadLatestAgreementDocument,
    error: latestAgreementDocumentError,
  } = useQuery({
    enabled: Boolean(agreementId),
    queryKey: ['downloadLatestAgreementDocument', agreementId],
    async queryFn({ signal }) {
      const config = { signal };
      const res = await downloadLatestAgreementDocument(agreementId, config);
      return res.data;
    },
    placeholderData: keepPreviousData,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const {
    isLoading: isSignPreviewAgreementDocumentLoading,
    isFetching: isSignPreviewAgreementDocumentFetching,
    data: signPreviewAgreementDocument,
    error: signPreviewAgreementDocumentError,
  } = useQuery({
    enabled: Boolean(agreementId),
    queryKey: ['downloadSignPreviewAgreementDocument', agreementId, signatureFilename],
    async queryFn({ signal }) {
      if (!signatureFilename) return null;
      const config = { signal };
      const payload = { signatureFilename };
      const res = await downloadSignPreviewAgreementDocument(agreementId, payload, config);
      return res.data;
    },
    initialData: null,
    placeholderData: keepPreviousData,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const errorMessages = useMemo<ServerFormErrors>(() => {
    const agreementDetailsForSignErrorMessages = new ServerErrorAdapter(
      agreementDetailsForSignError as ServerError,
    ).combine();
    const latestAgreementDocumentErrorMessages = new ServerErrorAdapter(
      latestAgreementDocumentError as ServerError,
    ).combine();
    const signPreviewAgreementDocumentErrorMessages = new ServerErrorAdapter(
      signPreviewAgreementDocumentError as ServerError,
    ).combine();

    return [
      ...agreementDetailsForSignErrorMessages,
      ...latestAgreementDocumentErrorMessages,
      ...signPreviewAgreementDocumentErrorMessages,
    ];
  }, [agreementDetailsForSignError, latestAgreementDocumentError, signPreviewAgreementDocumentError]);

  const isLatestDocumentLoading =
    isLatestAgreementDocumentLoading ||
    isLatestAgreementDocumentFetching ||
    isSignPreviewAgreementDocumentLoading ||
    isSignPreviewAgreementDocumentFetching;
  const latestDocument = useMemo(
    () => signPreviewAgreementDocument || latestAgreementDocument,
    [latestAgreementDocument, signPreviewAgreementDocument],
  );

  const downloadUserSignatureRequest = useCallback(async (signatureFilename: string, config?: AxiosRequestConfig) => {
    const res = await downloadUserSignature(signatureFilename, config);
    return res.data;
  }, []);

  const uploadUserSignatureRequest = useCallback(
    async (type: SignatureType, data: FormData) => {
      const res = await uploadUserSignature(userId, type, data);
      return res.data;
    },
    [userId],
  );

  const signAgreementRequest = useCallback(
    (data: SignAgreementModel) => signAgreement(agreementId, data),
    [agreementId],
  );

  const rejectAgreementRequest = useCallback(
    (data: RejectAgreementModel) => rejectAgreement(agreementId, data),
    [agreementId],
  );

  return (
    <Notarise
      // Permissions
      isAllowedDealRotorAgreementSign={isAllowedDealRotorAgreementSign}
      isAllowedUserReadSignature={isAllowedUserReadSignature}
      // User
      userId={userId}
      userSignatureFilename={userSignatureFilename}
      // Agreement
      isAgreementDetailsForSignLoading={isAgreementDetailsForSignLoading}
      dealRotorNumber={dealRotorNumber}
      agreementSignatoryList={agreementSignatoryList}
      agreementDocumentList={agreementDocumentList}
      // PDF
      isLatestDocumentLoading={isLatestDocumentLoading}
      latestDocument={latestDocument}
      // signatureFilename
      signatureFilename={signatureFilename}
      setSignatureFilename={setSignatureFilename}
      // Requests
      downloadUserSignatureRequest={downloadUserSignatureRequest}
      uploadUserSignatureRequest={uploadUserSignatureRequest}
      signAgreementRequest={signAgreementRequest}
      rejectAgreementRequest={rejectAgreementRequest}
      // Refetch
      refetchAgreementDetailsForSign={refetchAgreementDetailsForSign}
      refetchDownloadLatestAgreementDocument={refetchDownloadLatestAgreementDocument}
      refetchUserSignatureFilename={refetchUserSignatureFilename}
      // Errors
      errorMessages={errorMessages}
    />
  );
}

export default NotarisePrivate;
