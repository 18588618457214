import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { useForm } from 'react-hook-form';
import { object, string, array, mixed, bool } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import Field from 'src/components/forms/Field';
import LoadingButton from 'src/components/buttons/LoadingButton';
import ServerErrorMessages from 'src/components/ServerErrorMessages';

import { useFormErrors } from 'src/hooks/FormHelpers';

import ServerErrorAdapter from 'src/utils/ServerErrorAdapter';

import { createUser, uploadUserLogo, updateUserRoles } from 'src/api/base/user';
import { getAllRoles } from 'src/api/base/permissions';

import { YupFormShape, CreateUserModel, SelectOption, ServerError } from 'src/types';

import styles from 'src/pages/configuration/components/UserConfig.module.scss';

type FormData = {
  firstName: string;
  lastName: string;
  email: string;
  title: string;
  phone: string;
  mobile: string;
  roles: string[];
  logoFile: FileList;
  shouldBeInvited: boolean;
};
type FormShape = YupFormShape<FormData>;

const formSchema = object().shape<FormShape>({
  firstName: string().label('First name').trim().required(),
  lastName: string().label('Last name').trim().required(),
  email: string().label('Email').trim().email().required(),
  title: string().label('Job title').trim().required(),
  phone: string().label('Phone number').trim(),
  mobile: string().label('Phone mobile').trim(),
  roles: array().of(string()).label('Roles'),
  logoFile: mixed<FileList>().label('User logo').fileExt(['.png']).fileSizeMax(30000000),
  shouldBeInvited: bool().label('Invite user').default(false),
});

const CreateUserForm = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { register, control, formState, handleSubmit, setError, clearErrors } = useForm<FormData>({
    resolver: yupResolver(formSchema),
  });

  const { serverErrorMessages, setServerErrorMessages, handleErrors } = useFormErrors<FormData>(setError, clearErrors);

  const roles = useQuery({
    queryKey: ['getAllRoles'],
    async queryFn({ signal }): Promise<SelectOption[]> {
      const config = { signal };
      const res = await getAllRoles(config);
      return res.data.map(({ id, name }) => ({ label: name, value: id }));
    },
    initialData: [],
    retry: false,
    refetchOnWindowFocus: false,
  });

  const updateUserLogo = async (userId: string, file: FileList) => {
    if (file.length === 0) return;
    try {
      const formData = new FormData();
      formData.append('file', file[0]);
      await uploadUserLogo(userId, formData);
    } catch (e) {
      const serverErrors = new ServerErrorAdapter(e as ServerError);
      setServerErrorMessages(serverErrors.combine());
    }
  };

  const onSubmit = handleSubmit(async (formData) => {
    const { logoFile, roles, ...userData } = formData;
    const userPayload: CreateUserModel = { ...userData, timezone: null };
    const userRoles = {
      roleIds: roles,
    };
    try {
      handleErrors();

      const res = await createUser(userPayload);
      await updateUserLogo(res.data, logoFile);
      await updateUserRoles(res.data, userRoles);

      queryClient.removeQueries({ queryKey: ['getUserListSearch'] });

      navigate(RoutePath.configurationUserList, { replace: true });
    } catch (e) {
      handleErrors(e as ServerError);
    }
  });

  useEffect(() => {
    const error = new ServerErrorAdapter(roles.error as ServerError).combine();
    setServerErrorMessages(error);
  }, [roles.error, setServerErrorMessages]);

  return (
    <div className="bg-white border rounded">
      <h2 className="h6 p-3">Create party A user</h2>
      <form className="border-top p-3" name="userCreateForm" onSubmit={onSubmit} noValidate>
        <div className="row">
          <Field
            className="col-3 mb-2"
            inputClassName={classNames(styles.Image)}
            field="image"
            label="User logo"
            placeholder={'Upload PNG\nnot more than 3 MB'}
            accept=".png"
            autoComplete="off"
            register={register('logoFile')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
          />
        </div>
        <div className="row">
          <Field
            className="col-md-6 col-lg-3 mb-2"
            label="First name"
            field="input"
            type="text"
            placeholder="Enter first name"
            autoComplete="off"
            register={register('firstName')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
            autoFocus
          />
          <Field
            className="col-md-6 col-lg-3 mb-2"
            label="Last name"
            field="input"
            type="text"
            placeholder="Enter last name"
            autoComplete="off"
            register={register('lastName')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
          />
          <Field
            className="col-md-6 col-lg-3 mb-2"
            field="input"
            label="Email"
            type="email"
            placeholder="Enter email"
            autoComplete="off"
            register={register('email')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
          />
        </div>
        <div className="row">
          <Field
            className="col-md-6 col-lg-3 mb-2"
            field="input"
            label="Job title"
            type="text"
            placeholder="Enter job title"
            autoComplete="off"
            register={register('title')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
          />
          <Field
            className="col-md-6 col-lg-3 mb-2"
            label="Phone number"
            field="input"
            type="tel"
            placeholder="Enter phone number"
            autoComplete="off"
            register={register('phone')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
            autoFocus
          />
          <Field
            className="col-md-6 col-lg-3 mb-2"
            label="Phone mobile"
            field="input"
            type="tel"
            placeholder="Enter phone mobile"
            autoComplete="off"
            register={register('mobile')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
          />
        </div>
        <div className="row">
          <Field
            className="col-md-12 col-lg-6 mb-3"
            field="dropdown"
            label="Roles"
            placeholder="Choose roles"
            autoComplete="off"
            register={register('roles')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
            options={roles.data}
            multiple
            dropdownProps={{
              isLoading: roles.isLoading,
              isSearchable: true,
              isClearable: false,
            }}
          />
        </div>
        <div className="row">
          <Field
            className="col-12 mb-3"
            field="input"
            type="checkbox"
            label="Invite user"
            register={register('shouldBeInvited')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
          />
        </div>
        <div className="d-flex justify-content-end gap-2">
          <button className="btn btn-outline-primary" type="button" onClick={() => navigate(-1)}>
            Back
          </button>
          <LoadingButton
            className="btn btn-primary"
            type="submit"
            isLoading={formState.isSubmitting}
            disabled={formState.isSubmitting}
          >
            Create
          </LoadingButton>
        </div>
      </form>
      <ServerErrorMessages className="mx-3" messages={serverErrorMessages} />
    </div>
  );
};

export default CreateUserForm;
