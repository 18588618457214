import classNames from 'classnames';
import { NavLink, To } from 'react-router-dom';

import styles from './Breadcrumbs.module.scss';

export type Breadcrumb = {
  name: string;
  to: To;
  isLoading?: boolean;
};

export type Props = {
  breadcrumbs: Breadcrumb[];
  className?: string;
};

function Breadcrumbs({ className, breadcrumbs }: Props) {
  return (
    <nav className={classNames(styles.Breadcrumbs, className)} aria-label="breadcrumb">
      <ol className="breadcrumb placeholder-glow mb-0">
        {breadcrumbs.map(({ name, to, isLoading }, index) => (
          <li className="breadcrumb-item" key={`${name}${index}`}>
            <NavLink className={({ isActive }) => classNames({ active: isActive, placeholder: isLoading })} to={to} end>
              {name}
            </NavLink>
          </li>
        ))}
      </ol>
    </nav>
  );
}

export default Breadcrumbs;
