import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Field from 'src/components/forms/Field';
import LoadingButton from 'src/components/buttons/LoadingButton';
import ServerErrorMessages from 'src/components/ServerErrorMessages';

import { useFormErrors } from 'src/hooks/FormHelpers';

import { TransferDealOwnerModel, YupFormShape, SelectOption, ServerError, UUIDString } from 'src/types';
import { useEffect, useMemo } from 'react';

type OwnerFormProps = {
  formName: string;
  fieldLabel: string;
  dealRotorId: UUIDString;
  userId: UUIDString;
  isLoading: boolean;
  isUserOptionsLoading: boolean;
  userOptions: SelectOption[];
  isAllowEditParticipants: boolean;
  transferOwner: (
    dealRotorId: UUIDString,
    data: TransferDealOwnerModel,
    config?: AxiosRequestConfig,
  ) => Promise<AxiosResponse<void, any>>;
};

type FormData = { userId: UUIDString };
type FormShape = YupFormShape<FormData>;

function OwnerForm({
  formName,
  fieldLabel,
  userId,
  dealRotorId,
  isLoading,
  isUserOptionsLoading,
  userOptions,
  isAllowEditParticipants,
  transferOwner,
}: OwnerFormProps) {
  const queryClient = useQueryClient();

  const formSchema = useMemo(
    () =>
      object().shape<FormShape>({
        userId: string().label(fieldLabel).trim().required(),
      }),
    [fieldLabel],
  );

  const { register, control, handleSubmit, formState, setValue, setError, clearErrors, watch } = useForm<FormData>({
    resolver: yupResolver(formSchema),
  });
  const { serverErrorMessages, handleErrors } = useFormErrors<FormData>(setError, clearErrors);

  const selectedUserId = watch('userId');
  const isSameValue = selectedUserId === userId;

  useEffect(() => {
    setValue('userId', userId);
  }, [userId, setValue]);

  const onSubmit = handleSubmit(async (formData) => {
    try {
      handleErrors();
      await transferOwner(dealRotorId, formData);

      await Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: ['getDealRotorParticipantList', dealRotorId] }),
        queryClient.invalidateQueries({ queryKey: ['getDealRotorAuditListSearch', dealRotorId] }),
        queryClient.invalidateQueries({ queryKey: ['getDealRotorAuditParticipantList', dealRotorId] }),
      ]);
      queryClient.removeQueries({ queryKey: ['getDealRotorListSearch'] });
      queryClient.removeQueries({ queryKey: ['getDealRotorAuditListAll'] });
    } catch (e) {
      handleErrors(e as ServerError);
    }
  });

  return (
    <form name={formName} onSubmit={onSubmit} noValidate>
      <Field
        field="dropdown"
        label={fieldLabel}
        placeholder={`${isAllowEditParticipants ? 'Choose' : 'No'} ${fieldLabel.toLocaleLowerCase()}`}
        autoComplete="off"
        register={register('userId')}
        control={control}
        formSchema={formSchema}
        errors={formState.errors}
        options={userOptions}
        disabled={isLoading || !isAllowEditParticipants}
        dropdownProps={{
          isLoading: isUserOptionsLoading,
          isSearchable: true,
          isClearable: false,
        }}
      />

      {isAllowEditParticipants && (
        <div className="d-flex flex-wrap justify-content-end gap-3 mt-3">
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={() => {
              setValue('userId', userId);
              handleErrors();
            }}
            disabled={isLoading || isSameValue}
          >
            Cancel
          </button>
          <LoadingButton
            type="submit"
            className="btn btn-primary text-nowrap"
            disabled={isLoading || isSameValue}
            isLoading={formState.isSubmitting}
          >
            Transfer
          </LoadingButton>
          <ServerErrorMessages className="w-100" messages={serverErrorMessages} />
        </div>
      )}
    </form>
  );
}

export default OwnerForm;
