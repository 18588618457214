import AuthCard from './components/AuthCard';
import PasswordForgotForm from './components/PasswordForgotForm';

function PasswordForgot() {
  return (
    <AuthCard title="Forgot your password?">
      <p>Enter your email. You will be sent an email containing password reset instructions.</p>
      <PasswordForgotForm />
    </AuthCard>
  );
}
export default PasswordForgot;
