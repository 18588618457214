import { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { RoutePath } from 'src/router';

import TabNavigation from 'src/components/TabNavigation';

import HeaderConfig from 'src/pages/configuration/components/HeaderConfig';

import useUserPermission from 'src/hooks/useUserPermission';

import { PermissionNames } from 'src/types';

const IntegrationConfig = () => {
  const { hasUserAllowedPermissions } = useUserPermission();

  const breadcrumbPaths = [
    { name: 'Home', to: RoutePath.root },
    { name: 'Configuration', to: RoutePath.configurationRoot },
  ];

  const tabs = useMemo(
    () =>
      [
        {
          name: 'Salesforce',
          to: RoutePath.configurationIntegrationSalesforce,
          allowedPermissions: [PermissionNames.IntegrationsSalesforce],
        },
        { name: 'Xero', to: RoutePath.configurationIntegrationXero, allowedPermissions: [] },
      ].filter(({ allowedPermissions }) => hasUserAllowedPermissions(allowedPermissions)),
    [hasUserAllowedPermissions],
  );

  return (
    <div className="h-100">
      <HeaderConfig className="mb-4" title="Integrations" breadcrumbPaths={breadcrumbPaths} />
      <TabNavigation className="border-bottom mb-4" tabs={tabs} />
      <Outlet />
    </div>
  );
};

export default IntegrationConfig;
