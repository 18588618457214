import classNames from 'classnames';
import { useState } from 'react';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link, generatePath } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { useSelector } from 'src/store';
import { tenantLogoUrlSelector } from 'src/store/selectors/tenantSelector';
import { profileUserSelector } from 'src/store/selectors/profileSelector';
import { authUserTenantSubDomainListSelector } from 'src/store/selectors/authSelector';

import Avatar from 'src/components/Avatar';
import Notifications from 'src/components/notifications/Notifications';
import { QuestionMarkIcon } from 'src/components/icons';
import ChevronIcon from 'src/components/icons/ChevronIcon';
import TenantMenu from 'src/components/TenantMenu';

import { logout } from 'src/hocs/Auth';

import styles from './Header.module.scss';

function Header() {
  const tenantLogoUrl = useSelector(tenantLogoUrlSelector);
  const userProfile = useSelector(profileUserSelector);
  const isMultiTenant = (useSelector(authUserTenantSubDomainListSelector) || []).length > 1;

  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const toggleDropdown = () => setIsOpenDropdown((prevState) => !prevState);

  return (
    <>
      <Navbar className={classNames(styles.Header, 'shadow-md bg-white')} variant="white" expand="md" as="header">
        <Container fluid>
          <Navbar.Brand href="/">
            <img className={styles.Logo} src={tenantLogoUrl} alt="bridgingx logo" />
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse>
            <Nav className="ms-auto gap-3">
              <div className="d-flex align-items-center gap-2">
                {isMultiTenant && <TenantMenu />}
                <Nav.Link href="https://support.bridgingx.com/kb/en/">
                  <QuestionMarkIcon className="text-primary" />
                </Nav.Link>
                <Notifications />
              </div>
              <NavDropdown
                className={styles.NavDropdown}
                show={isOpenDropdown}
                onToggle={toggleDropdown}
                title={
                  <div className="d-flex align-items-center gap-2">
                    <Avatar src={userProfile.logoUrl} alt={userProfile.fullName || ''} size={40} />
                    <span className="fs-14">{userProfile.fullName}</span>
                    <ChevronIcon down={!isOpenDropdown} up={isOpenDropdown} />
                  </div>
                }
              >
                <NavDropdown.Item
                  to={generatePath(RoutePath.userProfileById, { userId: userProfile.id || '' })}
                  as={Link}
                >
                  Profile
                </NavDropdown.Item>
                <NavDropdown.Item to={RoutePath.userProfileSettingsRoot} as={Link}>
                  Settings
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  as={Link}
                  to={RoutePath.login}
                  onClick={(e) => {
                    e.preventDefault();
                    logout();
                  }}
                >
                  Log out
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
