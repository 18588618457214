import expr from 'property-expr';
import { Control } from 'react-hook-form';

function getIn<C = any>(schema: any, path: string, value?: any, context: C = value) {
  let parent: any, lastPart: string, lastPartDebug: string;

  if (!path) return { parent, parentPath: path, schema };

  expr.forEach(path, (_part, isBracket, isArray) => {
    const part = isBracket ? _part.slice(1, -1) : _part;

    schema = schema.resolve({ context, parent, value });

    const isTuple = schema.type === 'tuple';
    const idx = isArray ? parseInt(part, 10) : 0;

    if (schema.innerType || isTuple) {
      if (isTuple && !isArray)
        throw new Error(
          `Yup.reach cannot implicitly index into a tuple type. the path part "${lastPartDebug}" must contain an index to the tuple element, e.g. "${lastPartDebug}[0]"`,
        );
      if (value && idx >= value.length) {
        throw new Error(
          `Yup.reach cannot resolve an array item at index: ${_part}, in the path: ${path}. Because there is no value at that index.`,
        );
      }
      parent = value;
      value = value && value[idx];
      schema = isTuple ? schema.spec.types[idx] : schema.innerType;
    }

    if (!isArray) {
      if (!schema.fields || !schema.fields[part])
        throw new Error(
          `The schema does not contain the path: ${path}. (failed at: ${lastPartDebug} which is a type: "${schema.type}")`,
        );

      parent = value;
      value = value && value[part];
      schema = schema.fields[part];
    }

    lastPart = part;
    lastPartDebug = isBracket ? `[${_part}]` : `.${_part}`;
  });

  return {
    schema: schema.resolve({ context, parent, value }),
    parent,
    parentPath: lastPart!,
  };
}

function customReach(obj: any, path: string, value?: any, context?: any) {
  return getIn(obj, path, value, context).schema;
}

export function testIsFieldIsRequired(name: string, formSchema: any, control: Control<any, object>) {
  const fieldValidationSchema = formSchema
    ? customReach(formSchema, name, control._formValues, control._formValues).resolve({
        parent: control._formValues,
        context: control._formValues,
        value: control._formValues,
      })
    : false;

  const resolvedSchema = fieldValidationSchema
    ? fieldValidationSchema.resolve({
        parent: control._formValues,
        context: formSchema,
      })
    : false;

  const optional = resolvedSchema ? resolvedSchema.describe().optional : true;
  return !optional;
}
