import { useMemo } from 'react';
import { useQuery, keepPreviousData } from '@tanstack/react-query';

import { useSelector } from 'src/store';
import { authUserTenantDataListSelector } from 'src/store/selectors/authSelector';

import { getPublicTenantSettings } from 'src/api/admin/tenantPublic';

import { UUIDString, TenantPublicSettingsDto } from 'src/types';

type UserTenantOrigin = Record<UUIDString, string>;
type UserTenant = {
  id: string;
  domain: string;
};
type UserTenantSettings = UserTenant & TenantPublicSettingsDto;

const formatTenantList = (userTenants: UserTenantOrigin[]): UserTenant[] =>
  userTenants.flatMap((tenant) =>
    Object.entries(tenant).map((entry) => {
      return { domain: entry[1], id: entry[0] };
    }),
  );

const useUserTenantSettingList = () => {
  const authUserTenantDataList = useSelector(authUserTenantDataListSelector);
  const tenantAuthList = useMemo(() => {
    if (!authUserTenantDataList) return [];
    return formatTenantList(authUserTenantDataList);
  }, [authUserTenantDataList]);

  const {
    data: tenantSettingList,
    error: tenantSettingListError,
    isLoading: tenantSettingListIsLoading,
  } = useQuery({
    queryKey: ['getPublicTenantSettings', tenantAuthList],
    async queryFn({ signal }): Promise<UserTenantSettings[]> {
      const config = { signal };
      const tenantSettingList = tenantAuthList.map(async (tenant) => {
        const tenantSetting = await getPublicTenantSettings(tenant.domain, config);
        return { ...tenant, ...tenantSetting.data };
      });
      const res = await Promise.all(tenantSettingList);
      return res;
    },
    placeholderData: keepPreviousData,
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  return { tenantSettingList, tenantSettingListError, tenantSettingListIsLoading };
};

export default useUserTenantSettingList;
