import classNames from 'classnames';

import styles from './Dot.module.scss';

export type Props = {
  color?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'light'
    | 'dark'
    | 'yellow'
    | 'metallic-silver';
  className?: string;
};

const Dot = ({ color, className }: Props) => {
  return <figure className={classNames(styles.Dot, className, color && `bg-${color}`, 'mb-0')}></figure>;
};

export default Dot;
