import classNames from 'classnames';
import { useState, useEffect, useMemo, lazy, Suspense } from 'react';
import { useParams, Params } from 'react-router-dom';
import { useQuery, keepPreviousData } from '@tanstack/react-query';

import { useSelector } from 'src/store';
import { tenantTimezoneInBracketsSelector } from 'src/store/selectors/tenantSelector';

import { useForm, UseFormWatch, UseFormHandleSubmit } from 'react-hook-form';
import { object, string, date, DateSchema, array } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Field from 'src/components/forms/Field';
import ServerErrorMessages from 'src/components/ServerErrorMessages';

import AuditList from './components/dealRotorEdit/AuditList';
import SpinnerLoader from 'src/components/SpinnerLoader';
import { ParticipantIcon, AttachmentIcon, EventIcon, StatusIcon, ActionIcon } from 'src/components/icons/time-line';

import Converter from 'src/utils/Converter';
import LocalDate from 'src/utils/LocalDate';
import ServerErrorAdapter from 'src/utils/ServerErrorAdapter';
import { dealRotorAuditSubCategoryOptions } from 'src/utils/selectOptions';

import { getDealRotorAuditListAll, GetDealRotorAuditListAllParams } from 'src/api/base/dealRotor';
import uuidv4 from 'src/utils/uuidv4';

import {
  DealRotorAuditDto,
  DealRotorAuditSubCategory,
  YupFormShape,
  DateTimeISOString,
  ServerError,
  SelectOption,
} from 'src/types';

import styles from './DealRotorEdit.module.scss';

const TimelineChart = lazy(
  () => import(/* webpackChunkName: "dx-chart" */ 'src/pages/deal-rotor/DealRotorEdit.timeline.chart'),
);

const dealRotorAuditEventOptions: SelectOption[] = dealRotorAuditSubCategoryOptions.map((el) => {
  switch (el.label) {
    case Converter.stripUpperToCapitalizedFirst(DealRotorAuditSubCategory.Participants):
      return {
        ...el,
        label: (
          <div>
            <ParticipantIcon width={24} height={21} />
            <span className="px-2">{el.label}</span>
          </div>
        ),
      };

    case Converter.stripUpperToCapitalizedFirst(DealRotorAuditSubCategory.Attachments):
      return {
        ...el,
        label: (
          <div>
            <AttachmentIcon width={24} height={24} />
            <span className="px-2">{el.label}</span>
          </div>
        ),
      };

    case Converter.stripUpperToCapitalizedFirst(DealRotorAuditSubCategory.SigningEvent):
      return {
        ...el,
        label: (
          <div>
            <EventIcon width={24} height={24} />
            <span className="px-2">{el.label}</span>
          </div>
        ),
      };

    case Converter.stripUpperToCapitalizedFirst(DealRotorAuditSubCategory.StatusChange):
      return {
        ...el,
        label: (
          <div>
            <StatusIcon width={22} height={22} />
            <span className="px-2">{el.label}</span>
          </div>
        ),
      };

    default:
      return {
        ...el,
        label: (
          <div>
            <ActionIcon width={24} height={24} />
            <span className="px-2">{el.label}</span>
          </div>
        ),
      };
  }
});

type FormData = GetDealRotorAuditListAllParams & { isCompactView: 'datetime' | 'string' };
type FormShape = YupFormShape<FormData>;
const formSchema = object().shape<FormShape>({
  subCategories: array().of(string().trim()).label('Event type').emptyToUndefined(),
  dateFrom: date()
    .label('Date from')
    .nullable()
    .transform((curr, orig) => (orig === '' ? undefined : curr)),
  dateTo: date()
    .label('Date to')
    .nullable()
    .transform((curr, orig) => (orig === '' ? undefined : curr))
    .when('dateFrom', ([value]: Array<string | Date | undefined | null>, scheme: DateSchema<any>) => {
      if (!value) return scheme;
      const dateFrom = new Date(value);
      return scheme.min(dateFrom, ({ label, min }) => {
        const dateFromFormatted = Converter.getFormattedDate(new LocalDate(min).toJSON());
        return `${label} field must be ${dateFromFormatted} or later`;
      });
    }),
  isCompactView: string(),
});

const viewTypeOptions: SelectOption[] = [
  { label: 'Normal view', value: 'datetime' },
  { label: 'Compressed view', value: 'string' },
];

function useFormattedWatch(
  watch: UseFormWatch<FormData>,
  handleSubmit: UseFormHandleSubmit<FormData, undefined>,
): FormData {
  const [data, setData] = useState<FormData>({} as FormData);
  const { subCategories, dateFrom, dateTo, isCompactView } = watch();

  useEffect(() => {
    handleSubmit((formData) => {
      setData({ ...formData });
    })();
  }, [handleSubmit, subCategories, dateFrom, dateTo, isCompactView]);

  return data;
}

function DealRotorEditTimeline() {
  const { dealRotorId = '' } = useParams<Params<'dealRotorId'>>();
  const tenantTimezoneText = useSelector(tenantTimezoneInBracketsSelector);

  const { register, control, formState, watch, handleSubmit } = useForm<FormData>({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    defaultValues: { isCompactView: 'datetime' },
  });
  const { subCategories, dateFrom, dateTo, isCompactView } = useFormattedWatch(watch, handleSubmit);

  const dateFromRaw = watch('dateFrom');

  const {
    isLoading,
    data: auditList,
    error: auditPagesError,
  } = useQuery({
    enabled: Boolean(dealRotorId),
    queryKey: ['getDealRotorAuditListAll', dealRotorId, subCategories, dateFrom, dateTo],
    async queryFn({ signal }) {
      const config = { signal };
      const dateFromStartDate = dateFrom ? new LocalDate(dateFrom).getStartDay().toJSON() : undefined;
      const dateToEndDate = dateTo ? new LocalDate(dateTo).getEndDay().toJSON() : undefined;
      const params = { subCategories, dateFrom: dateFromStartDate, dateTo: dateToEndDate };
      const res = await getDealRotorAuditListAll(dealRotorId, params, config);
      return res.data;
    },
    placeholderData: keepPreviousData,
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: 1 * 60 * 1000, // 1 min
  });

  const auditTupleList = useMemo<[DateTimeISOString, DealRotorAuditDto[]][]>(() => {
    if (!auditList) return [];
    const auditGrouped: Record<string, DealRotorAuditDto[]> = {};

    const auditKeys = Object.keys(auditList.audits);
    auditKeys.forEach((key) => {
      auditGrouped[key] = auditList.audits[key].map((audit) => {
        return { id: uuidv4(), ...audit, entityBeforeJson: null, entityAfterJson: null };
      });
    });

    return Object.entries(auditGrouped);
  }, [auditList]);

  const serverErrorMessages = useMemo(() => {
    return new ServerErrorAdapter(auditPagesError as ServerError).combine();
  }, [auditPagesError]);

  return (
    <div className="row flex-grow-1 g-4">
      <div className="col-lg-8 col-xxl-9">
        <section className="card h-100">
          <header className={classNames(classNames(styles.TimeLineHeader, 'card-header bg-white'))}>
            <h1 className="h5 mb-1">Timeline</h1>
            {auditList?.engagementDateFrom && auditList.engagementDateFrom && (
              <p className="fs-14 text-secondary mb-0">
                Deal Engagement:{' '}
                <span>{`${Converter.getFormattedDate(auditList?.engagementDateFrom)} to ${Converter.getFormattedDate(
                  auditList?.engagementDateTo,
                )}`}</span>
              </p>
            )}
          </header>
          <div className={classNames(styles.ScrollableBody, 'card-body custom-scroll p-4')}>
            <form className="mb-3" name="dealRotorActivityFilters" noValidate>
              <div className="row g-3">
                <Field
                  className="col-sm-12 col-xxl-6"
                  field="dropdown"
                  label="Event type"
                  placeholder="Choose event type"
                  autoComplete="off"
                  register={register('subCategories')}
                  control={control}
                  formSchema={formSchema}
                  errors={formState.errors}
                  options={dealRotorAuditEventOptions}
                  multiple
                  dropdownProps={{
                    isSearchable: false,
                    isClearable: true,
                  }}
                />

                <Field
                  className="col-sm-6 col-xxl-3"
                  field="datepicker"
                  label={`Date from${tenantTimezoneText}`}
                  autoComplete="off"
                  register={register('dateFrom')}
                  control={control}
                  formSchema={formSchema}
                  errors={formState.errors}
                />

                <Field
                  className="col-sm-6 col-xxl-3"
                  field="datepicker"
                  label={`Date to${tenantTimezoneText}`}
                  autoComplete="off"
                  register={register('dateTo')}
                  control={control}
                  formSchema={formSchema}
                  errors={formState.errors}
                  datepickerProps={{ minDate: dateFromRaw ? new Date(dateFromRaw) : null }}
                />
              </div>
            </form>
            {isLoading ? (
              <SpinnerLoader />
            ) : (
              <Suspense fallback={<SpinnerLoader />}>
                {auditList && <TimelineChart auditList={auditList} viewType={isCompactView} />}
              </Suspense>
            )}
            <div className="row p-4 pb-0">
              <Field
                inputGroupClassName="hstack gap-3"
                field="input"
                type="radio"
                autoComplete="off"
                register={register('isCompactView')}
                control={control}
                formSchema={formSchema}
                errors={formState.errors}
                options={viewTypeOptions}
              />
            </div>
          </div>
        </section>
      </div>
      <div className="col-lg-4 col-xxl-3">
        <section className="card h-100">
          <header className="card-header bg-white p-4">
            <h2 className="h5 mb-0">Events Details</h2>
          </header>
          <div className={classNames(styles.ScrollableBody, 'card-body custom-scroll px-0 py-3')}>
            {auditTupleList.length > 0 ? (
              <AuditList auditTupleList={auditTupleList} />
            ) : serverErrorMessages.length ? (
              <ServerErrorMessages className="mx-3" messages={serverErrorMessages} />
            ) : (
              <div className="px-4">{isLoading ? 'Loading...' : 'No event details'}</div>
            )}
          </div>
        </section>
      </div>
    </div>
  );
}

export default DealRotorEditTimeline;
