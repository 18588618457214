import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useMemo, lazy, Suspense } from 'react';

import { RoutePath } from 'src/router';
import Breadcrumbs from 'src/components/Breadcrumbs';
import SpinnerLoader from 'src/components/SpinnerLoader';

import AnalyticsContainer from 'src/pages/analytics/Analytics';

// webpackChunkName to be safe for ad-blockers
const AnalyticsMain = lazy(() => import(/* webpackChunkName: "dx-charts" */ 'src/pages/analytics/Analytics.main'));
const AnalyticsTimeline = lazy(
  () => import(/* webpackChunkName: "dx-gantt" */ 'src/pages/analytics/Analytics.timeline'),
);

function AnalyticsRoutes() {
  return (
    <>
      {/* "/analytics/*" */}
      <Routes>
        <Route path="/*" element={<AnalyticsPage />}>
          <Route
            path="portfolio-analysis"
            element={
              <Suspense fallback={<SpinnerLoader />}>
                <AnalyticsMain />
              </Suspense>
            }
          />
          <Route
            path="portfolio-timeline"
            element={
              <Suspense fallback={<SpinnerLoader />}>
                <AnalyticsTimeline />
              </Suspense>
            }
          />

          <Route path="*" element={<Navigate to="portfolio-analysis" replace />} />
        </Route>

        <Route path="*" element={<Navigate to="./" />} />
      </Routes>
    </>
  );
}

export default AnalyticsRoutes;

const AnalyticsPage = () => {
  const location = useLocation();

  const breadcrumbs = useMemo(
    () => [
      { name: 'Home', to: RoutePath.root },
      { name: 'Analytics & Insights', to: location },
    ],
    [location],
  );

  return (
    <div className="h-100">
      <Breadcrumbs className="mb-4" breadcrumbs={breadcrumbs} />
      <header className="mb-4">
        <h1 className="h5">Analytics & Insights</h1>
        <p className="text-secondary mb-0">View deals and participants</p>
      </header>
      <AnalyticsContainer />
    </div>
  );
};
