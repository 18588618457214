import storage from 'redux-persist/lib/storage';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch as useDispatchOriginal, useSelector } from 'react-redux';
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';

import { resetStore } from './slices/storeSlice';
import authReducer from './slices/authSlice';
import dealRotorChatReducer from './slices/dealRotorChatSlice';
import dealRotorGlobalChatReducer from './slices/globalChatSlice';
import profileReducer from './slices/profileSlice';
import tenantReducer from './slices/tenantSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  dealRotorChat: persistReducer({ key: 'dealRotorChat', storage }, dealRotorChatReducer),
  dealRotorGlobalChat: persistReducer({ key: 'dealRotorGlobalChat', storage }, dealRotorGlobalChatReducer),
  profile: profileReducer,
  tenant: tenantReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;
export type StoreDispatch = typeof store.dispatch;

export const useDispatch = () => useDispatchOriginal<StoreDispatch>();
export { useSelector, resetStore, persistor };

export default store;
