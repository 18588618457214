import { ChatQuestionSourceDocumentDto, ServerFormErrors, UUIDString } from 'src/types';

export enum SenderType {
  User = 'User',
  DealRotor = 'DealRotor',
  System = 'System',
}

export type ChatMessageModel = {
  id: string;
  senderId: string;
  senderType: SenderType;
  textMessage: string;
  isLoading: boolean;
  sourceDocuments: ChatQuestionSourceDocumentDto[] | null;
  errors: ServerFormErrors | null;
};

export type SetChatHistoryPayloadAction = {
  dealRotorId: UUIDString;
  chatHistory: string | null;
};

export type AddMessagePayloadAction = {
  dealRotorId: UUIDString;
  message: ChatMessageModel;
};

export type UpdateMessagePayloadAction = {
  dealRotorId: UUIDString;
  messageId: UUIDString;
  messagePatch: Partial<ChatMessageModel>;
};

//Global chat
export type GlobalChatMessageModel = ChatMessageModel;

export type AddGlobalMessagePayloadAction = {
  message: GlobalChatMessageModel;
};

export type UpdateGlobalMessagePayloadAction = {
  messageId: UUIDString;
  messagePatch: Partial<GlobalChatMessageModel>;
};

export type SetGlobalChatHistoryPayloadAction = Pick<SetChatHistoryPayloadAction, 'chatHistory'>;
