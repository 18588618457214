import { useState, useMemo, useEffect } from 'react';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { Link, generatePath } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { useForm, UseFormWatch, UseFormHandleSubmit } from 'react-hook-form';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Field from 'src/components/forms/Field';
import Table, { useTableChangeParams, createColumnHelper } from 'src/components/Table';
import FormatDate from 'src/components/FormatDate';
import ServerErrorMessages from 'src/components/ServerErrorMessages';

import useUserPermission from 'src/hooks/useUserPermission';

import Converter from 'src/utils/Converter';
import ServerErrorAdapter from 'src/utils/ServerErrorAdapter';
import RequestSearchParamsAdapter from 'src/utils/RequestSearchParamsAdapter';

import { getParticipantDealRotorListSearch } from 'src/api/base/dealRotor';

import { DealRotorParticipantDto, YupFormShape, ServerError, ServerFormErrors, PermissionNames } from 'src/types';

type Props = {
  userId: string;
};

type FormData = {
  search: string | undefined;
};
type FormShape = YupFormShape<FormData>;
const formSchema = object().shape<FormShape>({
  search: string().trim().label('Search').emptyToUndefined(),
});

const columnHelper = createColumnHelper<DealRotorParticipantDto>();

function useFormattedWatch(
  watch: UseFormWatch<FormData>,
  handleSubmit: UseFormHandleSubmit<FormData, undefined>,
  resetPage: () => void,
): FormData {
  const [data, setData] = useState<FormData>({} as FormData);
  const { search } = watch();

  useEffect(() => {
    handleSubmit((formData) => {
      setData({ ...formData });
      resetPage();
    })();
  }, [search, handleSubmit, resetPage]);

  return data;
}

function DealParticipantDetailsList({ userId }: Props) {
  const { hasUserPermission } = useUserPermission();
  const isAllowedOrganisationRead = hasUserPermission(PermissionNames.OrganisationRead);

  const { register, control, formState, watch, handleSubmit } = useForm<FormData>({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
  });
  const { pagination, setPagination, sorting, setSorting, resetPage } = useTableChangeParams({});
  const { search } = useFormattedWatch(watch, handleSubmit, resetPage);

  const {
    isLoading,
    data: paginatedData,
    error: paginatedDataError,
  } = useQuery({
    enabled: Boolean(userId),
    queryKey: ['getParticipantDealRotorListSearch', userId, search, pagination, sorting],
    async queryFn({ signal }) {
      const config = { signal };
      const params = new RequestSearchParamsAdapter({ search, pagination, sorting });
      const res = await getParticipantDealRotorListSearch(userId, params, config);
      return res.data;
    },
    placeholderData: keepPreviousData,
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 3 * 60 * 1000, // 3 min
  });

  const { data, pageCount, total } = useMemo(
    () => ({
      data: paginatedData?.items ?? [],
      pageCount: paginatedData?.total ? Math.ceil(paginatedData.total / pagination.pageSize) : 1,
      total: paginatedData?.total ?? 0,
    }),
    [paginatedData, pagination.pageSize],
  );

  const errorMessages = useMemo<ServerFormErrors>(() => {
    const paginatedDataErrorMessages = new ServerErrorAdapter(paginatedDataError as ServerError).combine();
    return paginatedDataErrorMessages;
  }, [paginatedDataError]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        id: 'name',
        header: 'Deal name',
      }),
      columnHelper.accessor('userRoles', {
        id: 'userRoles',
        header: 'Roles',
        enableSorting: false,
        cell: ({ getValue }) => {
          const userRoles = getValue();
          const userRolesText =
            userRoles && userRoles.length
              ? userRoles.map((userRole) => Converter.stripUpperToCapitalizedFirst(userRole)).join(', ')
              : '';
          return <>{userRolesText}</>;
        },
      }),
      columnHelper.accessor('number', {
        id: 'number',
        header: 'Deal number',
        cell: ({ row, getValue }) => (
          <Link to={generatePath(RoutePath.dealRotorEditDetailedDealRotorDetails, { dealRotorId: row.original.id })}>
            {getValue()}
          </Link>
        ),
      }),
      columnHelper.accessor('partyB', {
        id: 'partyB',
        header: 'Party B',
        enableSorting: false,
        cell: ({ getValue }) => {
          const partyB = getValue();
          if (!partyB) return null;
          return isAllowedOrganisationRead ? (
            <Link to={generatePath(RoutePath.partyBManagementById, { organisationId: partyB.organisationId })}>
              {partyB.name}
            </Link>
          ) : (
            <span>{partyB.name}</span>
          );
        },
      }),
      columnHelper.accessor('title', {
        id: 'title',
        header: 'Title',
        enableSorting: false,
      }),
      columnHelper.accessor('updatedDate', {
        id: 'updatedDate',
        header: 'Last update',
        cell: ({ getValue }) => <FormatDate date={getValue()} formatValue="dd/MM/yyyy - HH:mm:ss" />,
      }),
    ],
    [isAllowedOrganisationRead],
  );

  return (
    <section>
      <h2 className="h5 mb-4">Participant deal rotors</h2>
      <form className="bg-white border rounded-top border-bottom-0 p-3" name="dealParticipantDetailsFilters" noValidate>
        <div className="row g-3">
          <Field
            className="col-sm-6 col-md-4 col-lg-4 col-xl-3"
            field="input"
            type="search"
            placeholder="Search"
            autoComplete="off"
            register={register('search')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
          />
        </div>
        <ServerErrorMessages className="mt-3 mb-0" messages={errorMessages} />
      </form>
      <Table
        className="table-border-bottom-none mb-0"
        wrapperClassName="border rounded-bottom border-top-0 mb-3"
        data={data}
        total={total}
        columns={columns}
        isLoading={isLoading}
        pageCount={pageCount}
        pagination={pagination}
        setPagination={setPagination}
        sorting={sorting}
        setSorting={setSorting}
      />
    </section>
  );
}

export default DealParticipantDetailsList;
