import { useEffect, useCallback, useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useParams, Params } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { object, string, number } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Field from 'src/components/forms/Field';
import LoadingButton from 'src/components/buttons/LoadingButton';
import ServerErrorMessages from 'src/components/ServerErrorMessages';
import { InfoIcon } from 'src/components/icons';

import useUserPermission from 'src/hooks/useUserPermission';
import { useFormErrors, useFormHasChanges } from 'src/hooks/FormHelpers';

import { boveOptions, currencyCodesOptions } from 'src/utils/selectOptions';

import { updateAgreementAttribute } from 'src/api/base/agreementAttribute';

import {
  DealRotorDto,
  DealRotorStatus,
  AgreementAttributeDto,
  AgreementStage,
  AgreementState,
  AgreementStatus,
  UpdateAgreementAttributeModel,
  BOVE,
  CurrencyCodes,
  YupFormShape,
  ServerError,
  PermissionNames,
} from 'src/types';

type Props = {
  dealRotor: DealRotorDto;
  dealRotorAgreementAttribute: AgreementAttributeDto | null;
};

type FormData = UpdateAgreementAttributeModel;
type FormShape = YupFormShape<FormData>;
const formSchema = object().shape<FormShape>({
  bove: string().label('BOVE').trim().required(),
  value: number()
    .label('Total agreement value')
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .when('bove', {
      is: (bove: BOVE | '' | null | undefined) => bove && bove !== BOVE.NotSpecified,
      then: (schema) => schema.moreThan(0).required(),
      otherwise: (schema) => schema.optional(),
    }),
  currencyCode: string()
    .label('Currency code')
    .trim()
    .when('bove', {
      is: (bove: BOVE | '' | null | undefined) => bove && bove !== BOVE.NotSpecified,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional(),
    }),
});

function AgreementAttributes({ dealRotor, dealRotorAgreementAttribute }: Props) {
  const queryClient = useQueryClient();
  const { dealRotorId = '' } = useParams<Params<'dealRotorId'>>();

  const [isAgreementAttributesEditModeOn, setIsAgreementAttributesEditModeOn] = useState(false);

  const { register, control, handleSubmit, formState, setValue, setError, clearErrors, watch, trigger } =
    useForm<FormData>({
      resolver: yupResolver(formSchema),
    });
  const { serverErrorMessages, handleErrors } = useFormErrors<FormData>(setError, clearErrors);

  const onSubmit = handleSubmit(async ({ bove, currencyCode, value }) => {
    try {
      handleErrors();
      const payload = {
        bove,
        value: bove && bove !== BOVE.NotSpecified ? value : null,
        currencyCode: bove && bove !== BOVE.NotSpecified ? currencyCode : null,
      };
      await updateAgreementAttribute(dealRotorId, payload);

      await Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: ['getAgreementAttribute', dealRotorId] }),
        queryClient.invalidateQueries({ queryKey: ['getDealRotorAuditListSearch', dealRotorId] }),
      ]);
      queryClient.removeQueries({ queryKey: ['getDealRotorAuditListAll'] });
      setIsAgreementAttributesEditModeOn(false);
    } catch (e) {
      handleErrors(e as ServerError);
    }
  });

  const dealRotorAgreementAttributePrefill = useMemo(
    () => ({
      bove: dealRotorAgreementAttribute ? dealRotorAgreementAttribute.bove : ('' as BOVE),
      value: dealRotorAgreementAttribute ? dealRotorAgreementAttribute.value : ('' as any as number),
      currencyCode: dealRotorAgreementAttribute ? dealRotorAgreementAttribute.currencyCode : ('' as CurrencyCodes),
    }),
    [dealRotorAgreementAttribute],
  );

  const makeDealRotorAgreementAttributePrefill = useCallback(() => {
    setValue('bove', dealRotorAgreementAttributePrefill.bove);
    setValue('value', dealRotorAgreementAttributePrefill.value);
    setValue('currencyCode', dealRotorAgreementAttributePrefill.currencyCode);
  }, [dealRotorAgreementAttributePrefill, setValue]);

  const agreementAttributesCancelEditMode = useCallback(() => {
    setIsAgreementAttributesEditModeOn(false);
    makeDealRotorAgreementAttributePrefill();
  }, [makeDealRotorAgreementAttributePrefill]);

  const hasChanges = useFormHasChanges(watch, dealRotorAgreementAttributePrefill);
  const boveValue = watch('bove');

  useEffect(() => {
    if (boveValue && boveValue !== BOVE.FixedPrice && (formState.isSubmitted || formState.isSubmitting)) {
      trigger(['value', 'currencyCode']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boveValue]);

  useEffect(() => {
    makeDealRotorAgreementAttributePrefill();
  }, [makeDealRotorAgreementAttributePrefill]);

  const { hasUserPermission } = useUserPermission();
  const isAllowedAgreementAttributesWrite = hasUserPermission(PermissionNames.AgreementAttributesWrite);

  const isPreShapingDraftStatus =
    dealRotor.agreementStage === AgreementStage.Pre &&
    dealRotor.agreementState === AgreementState.Shaping &&
    dealRotor.agreementStatus === AgreementStatus.Inactive;

  const isAttachmentEditGlobalDisabled =
    !isAllowedAgreementAttributesWrite ||
    dealRotor.status !== DealRotorStatus.Active ||
    dealRotor.agreementStatus === AgreementStatus.AwaitingSignature ||
    dealRotor.agreementStatus === AgreementStatus.Rejected ||
    dealRotor.agreementStatus === AgreementStatus.Ended;
  const isAttachmentEditCoreFieldsDisabled =
    !isPreShapingDraftStatus && Boolean(dealRotorAgreementAttribute && dealRotorAgreementAttribute.bove);

  return (
    <section className="card">
      <header className="card-header bg-white">
        <h2 className="h5 flex-grow-1 mb-0">Agreement attributes</h2>
      </header>
      <div className="card-body">
        <form name="agreementAttributesForm" onSubmit={onSubmit} noValidate>
          <div className="row g-3 mb-3">
            <Field
              className="col-md-6 col-xxl-4"
              field="dropdown"
              label="Basis of value exchange (BOVE)"
              placeholder="Choose BOVE"
              autoComplete="off"
              register={register('bove')}
              control={control}
              formSchema={formSchema}
              errors={formState.errors}
              options={boveOptions}
              disabled={
                isAttachmentEditGlobalDisabled ||
                isAgreementAttributesEditModeOn === false ||
                isAttachmentEditCoreFieldsDisabled
              }
              dropdownProps={{
                isSearchable: true,
                isClearable: false,
              }}
              AfterLabel={
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip>
                      Basis of Value Exchange - the way you are expecting to represent an exchange of value with the
                      other party.
                    </Tooltip>
                  }
                >
                  <InfoIcon className="text-primary ms-2 mb-1" />
                </OverlayTrigger>
              }
            />

            {boveValue && boveValue !== BOVE.NotSpecified && (
              <Field
                className="col-md-6 col-xxl-4"
                field="input"
                type="number"
                label="Total agreement value"
                placeholder="Enter total agreement value"
                autoComplete="off"
                min={0}
                register={register('value')}
                control={control}
                formSchema={formSchema}
                errors={formState.errors}
                disabled={isAttachmentEditGlobalDisabled || isAgreementAttributesEditModeOn === false}
              />
            )}

            {boveValue && boveValue !== BOVE.NotSpecified && (
              <Field
                className="col-md-6 col-xxl-4"
                field="dropdown"
                label="Currency code"
                placeholder="Choose currency code"
                autoComplete="off"
                register={register('currencyCode')}
                control={control}
                formSchema={formSchema}
                errors={formState.errors}
                options={currencyCodesOptions}
                disabled={
                  isAttachmentEditGlobalDisabled ||
                  isAgreementAttributesEditModeOn === false ||
                  isAttachmentEditCoreFieldsDisabled
                }
                dropdownProps={{
                  isSearchable: true,
                  isClearable: false,
                }}
              />
            )}
          </div>

          <div className="d-flex flex-wrap justify-content-end gap-3">
            {!isAgreementAttributesEditModeOn && (
              <button
                type="button"
                className="btn btn-outline-primary"
                disabled={isAttachmentEditGlobalDisabled}
                onClick={() => setIsAgreementAttributesEditModeOn(true)}
              >
                Edit
              </button>
            )}

            {isAgreementAttributesEditModeOn && (
              <button type="button" className="btn btn-outline-primary" onClick={agreementAttributesCancelEditMode}>
                Cancel
              </button>
            )}

            {isAgreementAttributesEditModeOn && (
              <LoadingButton
                type="submit"
                className="btn btn-primary text-nowrap"
                disabled={isAttachmentEditGlobalDisabled || !hasChanges}
                isLoading={formState.isSubmitting}
              >
                Save
              </LoadingButton>
            )}

            <ServerErrorMessages className="w-100 mb-0" messages={serverErrorMessages} />
          </div>
        </form>
      </div>
    </section>
  );
}

export default AgreementAttributes;
