import createActivityDetector from 'activity-detector';
import Cookie from 'js-cookie';
import { useState, useCallback, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { RoutePath } from 'src/router';

import store from 'src/store';
import { isLoggedInSelector } from 'src/store/selectors/authSelector';

import { logout } from 'src/hocs/Auth';

import {
  APP_AUTH_COOKIE_NAME,
  APP_LAST_ACTIVITY_DATE_COOKIE_NAME,
  APP_COUNTDOWN_START_DATE_COOKIE_NAME,
  cookieAttributes,
} from 'src/utils/constants';

const MAX_INACTIVE_TIME = 30 * 60 * 1000; // 30 min
const COUNTDOWN_TIME = 30 * 1000; // 30 sec
const activityDetector = createActivityDetector({ timeToIdle: 5000 });

function getLastActivityDateMilliseconds() {
  const lastActivityValue = Cookie.get()[APP_LAST_ACTIVITY_DATE_COOKIE_NAME];
  return lastActivityValue ? parseInt(lastActivityValue, 10) : null;
}

function updateLastActivityDate() {
  Cookie.set(APP_LAST_ACTIVITY_DATE_COOKIE_NAME, Date.now().toString(), cookieAttributes);
}

function getCountdownStartDateMilliseconds() {
  const lastCountdownValue = Cookie.get()[APP_COUNTDOWN_START_DATE_COOKIE_NAME];
  return lastCountdownValue ? parseInt(lastCountdownValue, 10) : null;
}

function ActivityTracker() {
  const countdownIntervalIdRef = useRef<number>();
  const [logoutCountdownInSeconds, setLogoutCountdownInSeconds] = useState(COUNTDOWN_TIME / 1000);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const stopLogoutCountdown = useCallback(() => {
    Cookie.remove(APP_COUNTDOWN_START_DATE_COOKIE_NAME, cookieAttributes);
    clearInterval(countdownIntervalIdRef.current);
  }, []);

  // Exit point
  const makeLogout = useCallback(() => {
    stopLogoutCountdown();
    closeModal();
    logout();
  }, [closeModal, stopLogoutCountdown]);

  // Exit point
  const extendSession = useCallback(() => {
    updateLastActivityDate();
    stopLogoutCountdown();
    closeModal();
  }, [closeModal, stopLogoutCountdown]);

  const triggerLogoutCountdown = useCallback(() => {
    const lastCountdownDateMilliseconds = getCountdownStartDateMilliseconds();
    if (lastCountdownDateMilliseconds === null || Date.now() - lastCountdownDateMilliseconds > COUNTDOWN_TIME) {
      Cookie.set(APP_COUNTDOWN_START_DATE_COOKIE_NAME, Date.now().toString(), cookieAttributes);
    }

    updateCountdownModal();
    clearInterval(countdownIntervalIdRef.current);
    countdownIntervalIdRef.current = window.setInterval(() => {
      updateCountdownModal();
    }, 1000);

    function updateCountdownModal() {
      const lastCountdownDateMilliseconds = getCountdownStartDateMilliseconds();
      if (lastCountdownDateMilliseconds === null) {
        extendSession();
      } else {
        const timeSinceStart = Date.now() - lastCountdownDateMilliseconds;
        const countdownSeconds = Math.round((COUNTDOWN_TIME - timeSinceStart) / 1000);
        if (countdownSeconds < 0) {
          makeLogout();
        } else {
          setLogoutCountdownInSeconds(countdownSeconds);
          setIsModalOpen(true);
        }
      }
    }
  }, [extendSession, makeLogout]);

  const checkActivity = useCallback(
    (isOnInit = false) => {
      const isLoggedIn = isLoggedInSelector(store.getState());
      if (!isLoggedIn) return;

      // When logged in but auth cookie removed by another tab
      if (!Cookie.get()[APP_AUTH_COOKIE_NAME]) {
        makeLogout();
        return;
      }

      const dateNow = Date.now();
      const lastActivityTime = getLastActivityDateMilliseconds() ?? dateNow;
      const diffTime = dateNow - lastActivityTime;

      if (isOnInit && diffTime >= MAX_INACTIVE_TIME + COUNTDOWN_TIME) {
        makeLogout();
      } else if (diffTime >= MAX_INACTIVE_TIME) {
        triggerLogoutCountdown();
      }
    },
    [makeLogout, triggerLogoutCountdown],
  );

  useEffect(() => {
    checkActivity(true);

    activityDetector.on('active', () => {
      const isCountdownActive = getCountdownStartDateMilliseconds() !== null;
      if (isCountdownActive) return;
      updateLastActivityDate();
    });
    activityDetector.init('idle');

    const checkActivityIntervalId = window.setInterval(() => {
      checkActivity();
    }, 10000);

    return () => {
      clearInterval(checkActivityIntervalId);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      clearInterval(countdownIntervalIdRef.current);
      activityDetector.stop();
    };
  }, [checkActivity]);

  return (
    <Modal show={isModalOpen} onHide={closeModal} backdrop="static" keyboard={false} centered>
      <Modal.Header onHide={closeModal}>
        <Modal.Title className="h5">Your session is going to expire</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Your session is going to expire in {logoutCountdownInSeconds} seconds.</p>
        <p>Do you want to extend the session?</p>
      </Modal.Body>
      <Modal.Footer>
        <Link
          className="btn btn-link"
          to={RoutePath.login}
          onClick={(e) => {
            e.preventDefault();
            makeLogout();
          }}
        >
          Log out
        </Link>
        <button type="button" className="btn btn-primary" onClick={extendSession}>
          Extend session
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ActivityTracker;
