import Cookie from 'js-cookie';
import { useState, useCallback, PropsWithChildren, useEffect } from 'react';

import store, { resetStore } from 'src/store';
import { setAccessToken } from 'src/store/slices/authSlice';
import { fetchCurrentUser, fetchMFACurrentUser } from 'src/store/slices/profileSlice';
import { authUserTenantIdSelector, authUserRoleSelector } from 'src/store/selectors/authSelector';

import { history, RoutePath } from 'src/router';
import { queryClient } from 'src/router/RootRouter';

import SpinnerLoader from 'src/components/SpinnerLoader';

import { loginUser, renewToken } from 'src/api/auth/auth';

import { APP_AUTH_COOKIE_NAME, cookieAttributes } from 'src/utils/constants';
import isJWT from 'src/utils/isJWT';

import { LoginRefreshTokenModel, ClientType, Role, LoginStatus } from 'src/types';

const REFRESH_CHECK_TIME = 30 * 1000; // 30 sec
const REFRESH_IN_ADVANCE_TIME = 1 * 60 * 1000; // 1 min - must be greater than REFRESH_CHECK_TIME

type LoginParams = {
  accessToken: string;
  accessTokenExpiresIn: number;
  refreshToken: string;
  refreshTokenExpiresIn: number;
};

type CookieAuthState = {
  accessToken: string;
  accessTokenExpireTime: number;
  refreshToken: string;
  refreshTokenExpireTime: number;
};

/**
 * Gets current user and put it in store
 */
async function retrieveCurrentUser() {
  const tenantId = authUserTenantIdSelector(store.getState());
  if (tenantId) {
    await store.dispatch(fetchCurrentUser());
    await store.dispatch(fetchMFACurrentUser());
  }
}

/**
 * Login user by email and password
 */
export async function loginUserRequest(email: string, password: string, code: string | null) {
  const payload = { email, password, clientType: ClientType.App, code };
  const res = await loginUser(payload);
  const { status } = res.data;
  if (status === LoginStatus.Success) {
    const { accessToken, expiresIn: accessTokenExpiresIn, refreshToken, refreshTokenExpiresIn } = res.data;
    login({ accessToken, accessTokenExpiresIn, refreshToken, refreshTokenExpiresIn });
    checkUserRole();
    await retrieveCurrentUser();
  }
  return res.data;
}

/**
 * Clears query client
 * Removes auth cookie
 * Cleans up store
 * Makes redirect to login app
 */
export function logout() {
  history.push(RoutePath.login);
  queryClient.clear();
  Cookie.remove(APP_AUTH_COOKIE_NAME, cookieAttributes);
  store.dispatch(resetStore());
}

/**
 * Puts new tokens in cookie and store
 * @param accessTokenExpiresIn in seconds
 * @param refreshTokenExpiresIn in seconds
 */
export function login({ accessToken, accessTokenExpiresIn, refreshToken, refreshTokenExpiresIn }: LoginParams) {
  const authState: CookieAuthState = {
    accessToken,
    accessTokenExpireTime: Date.now() + accessTokenExpiresIn * 1000,
    refreshToken,
    refreshTokenExpireTime: Date.now() + refreshTokenExpiresIn * 1000,
  };

  Cookie.set(APP_AUTH_COOKIE_NAME, JSON.stringify(authState), cookieAttributes);
  store.dispatch(setAccessToken(accessToken));
}

/**
 * Retrieves new access and refresh tokens.
 * Put new tokens in cookie and store
 */
async function refreshAuthState(refreshToken: string) {
  const renewTokenPayload: LoginRefreshTokenModel = { refreshToken, clientType: ClientType.App };
  try {
    const res = await renewToken(renewTokenPayload);
    const { accessToken, expiresIn: accessTokenExpiresIn, refreshToken, refreshTokenExpiresIn } = res.data;

    // Update both cookie and store
    login({ accessToken, accessTokenExpiresIn, refreshToken, refreshTokenExpiresIn });
  } catch (e) {
    logout();
  }
}

export function getCookieAuthState(): CookieAuthState | null {
  let appAuthCookie = Cookie.get()[APP_AUTH_COOKIE_NAME] || null;
  if (!appAuthCookie) return null;
  const appAuth: unknown = JSON.parse(appAuthCookie);

  // Validate cookie format
  if (
    typeof appAuth === 'object' &&
    appAuth !== null &&
    'accessToken' in appAuth &&
    'accessTokenExpireTime' in appAuth &&
    'refreshToken' in appAuth &&
    'refreshTokenExpireTime' in appAuth &&
    typeof appAuth.accessToken === 'string' &&
    typeof appAuth.accessTokenExpireTime === 'number' &&
    typeof appAuth.refreshToken === 'string' &&
    typeof appAuth.refreshTokenExpireTime === 'number' &&
    isJWT(appAuth.accessToken)
  ) {
    // Destructuring to satisfy TypeScript
    const { accessToken, accessTokenExpireTime, refreshToken, refreshTokenExpireTime } = appAuth;
    return { accessToken, accessTokenExpireTime, refreshToken, refreshTokenExpireTime };
  }

  return null;
}

function checkUserRole() {
  const userRole = authUserRoleSelector(store.getState());
  if (userRole && userRole !== Role.Client) {
    logout();
    throw new Error('Access not allowed. This user is for the admin application.');
  }
}

function Auth(props: PropsWithChildren<object>) {
  const [isInitialized, setIsInitialized] = useState(false);

  const init = useCallback(async () => {
    // Step 1 - Retrieve cookie and dispatch data to store
    const cookieAuthState = getCookieAuthState();
    if (cookieAuthState) {
      const isAccessTokenExpired = cookieAuthState.accessTokenExpireTime < Date.now();
      if (isAccessTokenExpired) {
        const isRefreshTokenExpired = cookieAuthState.refreshTokenExpireTime < Date.now();
        if (isRefreshTokenExpired) {
          logout();
        } else {
          await refreshAuthState(cookieAuthState.refreshToken);
        }
      } else {
        store.dispatch(setAccessToken(cookieAuthState.accessToken));
      }
    }

    // Step 2 - Retrieve current user
    await retrieveCurrentUser();

    // Step 3 - Finish
    setIsInitialized(true);
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  const checkTokenExpiration = useCallback(async () => {
    const cookieAuthState = getCookieAuthState();

    // Not Authorized
    if (!cookieAuthState) return;

    const isRefreshRequired = cookieAuthState.accessTokenExpireTime < Date.now() + REFRESH_IN_ADVANCE_TIME;
    if (isRefreshRequired) {
      await refreshAuthState(cookieAuthState.refreshToken);
    }
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      checkTokenExpiration();
    }, REFRESH_CHECK_TIME);
    return () => {
      clearInterval(intervalId);
    };
  }, [checkTokenExpiration]);

  return <>{isInitialized ? props.children : <SpinnerLoader />}</>;
}

export default Auth;
