import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { envTenant } from 'src/utils/domain';
import { AccessTokenData, Role } from 'src/types';

export const authSliceSelector = (state: RootState) => state.auth;

export const accessTokenSelector = createSelector(authSliceSelector, (state): string | null => state.accessToken);

export const accessTokenDataSelector = createSelector(accessTokenSelector, (token): AccessTokenData | null => {
  if (!token) return null;
  const tokenDataBase64 = token.split('.')[1];
  try {
    const tokenDataJSON = window.atob(tokenDataBase64);
    return JSON.parse(tokenDataJSON);
  } catch (e) {
    return null;
  }
});

// E.g. [ {95edba9c-0e48-4280-9225-0c0168d419fb: 'mosaic'} ]
export const authUserTenantDataListSelector = createSelector(
  accessTokenDataSelector,
  (tokenData): Record<string, string>[] | null => {
    if (!tokenData || !tokenData.tenant) return null;

    const tokenJsonList = typeof tokenData.tenant === 'string' ? [tokenData.tenant] : tokenData.tenant;

    try {
      return tokenJsonList.map<Record<string, string>>((tokenJson) => JSON.parse(tokenJson));
    } catch (e) {
      return null;
    }
  },
);

export const authUserTenantSubDomainListSelector = createSelector(
  authUserTenantDataListSelector,
  (tenantList): string[] | null => (tenantList ? tenantList.flatMap((tenantData) => Object.values(tenantData)) : null),
);

export const authUserTenantIdSelector = createSelector(authUserTenantDataListSelector, (tenantList): string | null => {
  if (!tenantList) return null;
  const [tenantId = null] =
    tenantList
      .flatMap((tenantData) => Object.entries(tenantData))
      .find(([_, tenantSubDomain]) => tenantSubDomain === envTenant) || [];
  return tenantId;
});

export const authUserRoleSelector = createSelector(accessTokenDataSelector, (tokenData): Role | null =>
  tokenData ? tokenData.role : null,
);

export const authUserSecurityIdSelector = createSelector(accessTokenDataSelector, (tokenData): string | null =>
  tokenData ? tokenData.sub : null,
);

export const authUserEmailSelector = createSelector(accessTokenDataSelector, (tokenData): string | null =>
  tokenData ? tokenData.email : null,
);

export const isLoggedInSelector = createSelector(
  accessTokenSelector,
  authUserRoleSelector,
  (token, role) => !!token && !!role,
);
