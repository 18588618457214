import { Routes, Route, Navigate } from 'react-router-dom';
import { GuardedRoute } from 'src/router';

import usePersistPageParams, { DEAL_ROTOR_LIST_PAGE_KEY } from 'src/hooks/usePersistPageParams';

import DealRotorListPage from './DealRotorList';

import DealRotorCreate from './DealRotorCreate';
import DealRotorCreateTemplateAndRolesPage from './DealRotorCreate.template-and-roles';
import DealRotorCreateDetailsPage from './DealRotorCreate.details';

import DealRotorEdit from './DealRotorEdit';

import DealRotorEditDetailed from './DealRotorEdit.detailed';
import DealRotorDetailsTab from './DealRotorEdit.detailed.tabs.deal-rotor-details';
import AgreementDetailsTab from './DealRotorEdit.detailed.tabs.agreement-details';
import ParticipantsTab from './DealRotorEdit.detailed.tabs.participants';
import AttachmentsTab from './DealRotorEdit.detailed.tabs.attachments';
import AIConsoleTab from './DealRotorEdit.detailed.tabs.ai-console';

import DealRotorEditTimeline from './DealRotorEdit.timeline';

import DealRotorEditNotifications from 'src/pages/deal-rotor/DealRotorEdit.notifications';
import DealRotorEditNotificationsUserTab from 'src/pages/deal-rotor/DealRotorEdit.notifications.user';
import DealRotorEditNotificationsDealTab from 'src/pages/deal-rotor/DealRotorEdit.notifications.deal';

import { PermissionNames } from 'src/types';

function DealRotorRoutes() {
  const { pageUrlSearch: dealRotorListPageUrlSearch } = usePersistPageParams(DEAL_ROTOR_LIST_PAGE_KEY);
  return (
    <>
      {/* "/deal-rotor/*" */}
      <Routes>
        <Route
          index
          element={<Navigate to={{ pathname: 'list', search: dealRotorListPageUrlSearch }} replace={true} />}
        />

        <Route
          path="list"
          element={
            <GuardedRoute
              meta={{
                auth: true,
                allowedPermissions: [PermissionNames.DealRotorRead],
              }}
            >
              <DealRotorListPage />
            </GuardedRoute>
          }
        />

        <Route
          path="create/*"
          element={
            <GuardedRoute
              meta={{
                auth: true,
                allowedPermissions: [PermissionNames.DealRotorCreate],
              }}
            >
              <DealRotorCreate />
            </GuardedRoute>
          }
        >
          <Route index element={<Navigate to="template-and-roles" replace={true} />} />

          <Route path="template-and-roles" element={<DealRotorCreateTemplateAndRolesPage />} />
          <Route path=":dealRotorId/details" element={<DealRotorCreateDetailsPage />} />

          <Route path="*" element={<Navigate to="./" />} />
        </Route>

        <Route
          path="edit/:dealRotorId/*"
          element={
            <GuardedRoute
              meta={{
                auth: true,
                allowedPermissions: [PermissionNames.DealRotorRead],
              }}
            >
              <DealRotorEdit />
            </GuardedRoute>
          }
        >
          <Route index element={<Navigate to="detailed" replace={true} />} />

          <Route path="detailed/*" element={<DealRotorEditDetailed />}>
            <Route index element={<Navigate to="deal-rotor-details" replace={true} />} />

            <Route path="deal-rotor-details" element={<DealRotorDetailsTab />} />
            <Route path="agreement-details" element={<AgreementDetailsTab />} />
            <Route
              path="participants"
              element={
                <GuardedRoute
                  meta={{
                    auth: true,
                    allowedPermissions: [
                      PermissionNames.DealRotorTransferOwner,
                      PermissionNames.DealRotorTransferOperationOwner,
                      PermissionNames.DealRotorUpdateManagers,
                      PermissionNames.DealRotorUpdateOperationManagers,
                      PermissionNames.DealRotorUpdateSignatories,
                    ],
                  }}
                >
                  <ParticipantsTab />
                </GuardedRoute>
              }
            />
            <Route path="attachments" element={<AttachmentsTab />} />
            <Route
              path="ai-console"
              element={
                <GuardedRoute
                  meta={{
                    auth: true,
                    allowedPermissions: [PermissionNames.ChatRead],
                  }}
                >
                  <AIConsoleTab />
                </GuardedRoute>
              }
            />

            <Route path="*" element={<Navigate to="./" />} />
          </Route>

          <Route path="timeline" element={<DealRotorEditTimeline />} />

          <Route path="notification/*" element={<DealRotorEditNotifications />}>
            <Route index element={<Navigate to="user" replace={true} />} />

            <Route path="user" element={<DealRotorEditNotificationsUserTab />} />
            <Route path="deal" element={<DealRotorEditNotificationsDealTab />} />

            <Route path="*" element={<Navigate to="./" />} />
          </Route>

          <Route path="*" element={<Navigate to="./" />} />
        </Route>

        <Route path="*" element={<Navigate to="./" />} />
      </Routes>
    </>
  );
}

export default DealRotorRoutes;
