import { AxiosRequestConfig } from 'axios';

import { baseApi } from 'src/api';

import {
  DealRotorActorDto,
  GetPartyBUserDto,
  TransferDealOwnerModel,
  UpsertDealManagersModel,
  UpsertDealSignatoriesModel,
  UUIDString,
} from 'src/types';

// PermissionNames.DealRotorRead
export const getDealRotorParticipantList = (dealRotorId: UUIDString, config: AxiosRequestConfig = {}) =>
  baseApi.get<DealRotorActorDto[]>(`/deal-rotors/${dealRotorId}/participants`, config);

// PermissionNames.DealRotorRead
export const getDealRotorPartyBParticipantList = (dealRotorId: UUIDString, config: AxiosRequestConfig = {}) =>
  baseApi.get<GetPartyBUserDto[]>(`/deal-rotors/${dealRotorId}/participants/party-b`, config);

// PermissionNames.DealRotorTransferOwner
export const transferDealRotorOwner = (
  dealRotorId: UUIDString,
  data: TransferDealOwnerModel,
  config: AxiosRequestConfig = {},
) => {
  return baseApi.post<void>(`/deal-rotors/${dealRotorId}/participants/transfer-deal-rotor-owner`, data, config);
};

// PermissionNames.DealRotorTransferOperationOwner
export const transferOperationOwner = (
  dealRotorId: UUIDString,
  data: TransferDealOwnerModel,
  config: AxiosRequestConfig = {},
) => {
  return baseApi.post<void>(`/deal-rotors/${dealRotorId}/participants/transfer-operation-owner`, data, config);
};

// Permissions(PermissionNames.DealRotorUpdateManagers
export const upsertDealRotorManagers = (
  dealRotorId: UUIDString,
  data: UpsertDealManagersModel,
  config: AxiosRequestConfig = {},
) => {
  return baseApi.post<void>(`/deal-rotors/${dealRotorId}/participants/upsert-deal-rotor-managers`, data, config);
};

// PermissionNames.DealRotorUpdateOperationManagers
export const upsertOperationManagers = (
  dealRotorId: UUIDString,
  data: UpsertDealManagersModel,
  config: AxiosRequestConfig = {},
) => {
  return baseApi.post<void>(`/deal-rotors/${dealRotorId}/participants/upsert-operation-managers`, data, config);
};

// PermissionNames.DealRotorUpdateSignatories
export const upsertSignatories = (
  dealRotorId: UUIDString,
  data: UpsertDealSignatoriesModel,
  config: AxiosRequestConfig = {},
) => {
  return baseApi.post<void>(`/deal-rotors/${dealRotorId}/participants/upsert-signatories`, data, config);
};
