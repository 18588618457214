import { Routes, Route, Navigate } from 'react-router-dom';

import LoginPage from './Login';
import LogoutPage from './Logout';
import SignUpPage from './SignUp';
import SetupMFAPage from './SetupMFA';
import PasswordForgotPage from './PasswordForgot';
import PasswordResetPage from './PasswordReset';
import { GuardedRoute } from 'src/router';
import TenantSelect from 'src/pages/auth/TenantSelect';

function AuthRoutes() {
  return (
    // "/auth/*"
    <Routes>
      <Route index element={<Navigate to="login" replace={true} />} />

      <Route path="login" element={<LoginPage />} />
      <Route path="logout" element={<LogoutPage />} />
      <Route path="signup" element={<SignUpPage />} />
      <Route path="mfa/setup" element={<SetupMFAPage />} />
      <Route path="forgot-password" element={<PasswordForgotPage />} />
      <Route path="reset-password" element={<PasswordResetPage />} />
      <Route
        path="tenant"
        element={
          <GuardedRoute meta={{ auth: true }}>
            <TenantSelect />
          </GuardedRoute>
        }
      />

      <Route path="*" element={<Navigate to="./" />} />
    </Routes>
  );
}

export default AuthRoutes;
