import classNames from 'classnames';
import { useState, useEffect, useCallback } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { formatDistanceToNow } from 'date-fns';
import { useInView } from 'react-intersection-observer';
import {
  useQuery,
  useQueryClient,
  useInfiniteQuery,
  useMutation,
  UseMutateFunction,
  keepPreviousData,
} from '@tanstack/react-query';

import Dot from 'src/components/Dot';
import Message from 'src/components/Message';
import LoadingButton from 'src/components/buttons/LoadingButton';
import { NotificationIcon, TimeIcon } from 'src/components/icons';

import {
  getUserNotificationUnreadCount,
  getUserNotificationList,
  markNotificationAsRead,
  markAllNotificationAsRead,
  deleteNotification,
  GetUserNotificationListParams,
} from 'src/api/base/notificationInApp';

import { UserNotificationDto, UUIDString } from 'src/types';

import styles from './Notifications.module.scss';

type NotificationProps = {
  notification: UserNotificationDto;
  markNotificationAsReadMutation: UseMutateFunction<void, unknown, UUIDString, unknown>;
  deleteNotificationMutation: UseMutateFunction<void, unknown, UserNotificationDto, unknown>;
  closeModal: () => void;
};

function Notification({
  notification,
  markNotificationAsReadMutation,
  deleteNotificationMutation,
  closeModal,
}: NotificationProps) {
  const formattedDate = formatDistanceToNow(new Date(notification.createdDate), { addSuffix: true });

  return (
    <div
      className={classNames('d-flex border-bottom p-3 gap-3', {
        'bg-cultured': notification.isRead === false,
      })}
    >
      <div>
        <Dot className="d-inline-block" color={notification.isRead ? undefined : 'success'} />
      </div>
      <div className="vstack gap-2">
        <div className="d-flex justify-content-between">
          <Message className="mb-0" message={notification.message} afterLinkClick={closeModal} tag="p" />
          <LoadingButton
            className="tag-delete flex-shrink-0"
            type="button"
            aria-label="delete notification"
            onClick={() => deleteNotificationMutation(notification)}
          />
        </div>
        <div className="d-flex justify-content-between">
          <div className="hstack text-secondary fs-14 gap-1">
            <TimeIcon />
            <time dateTime={notification.createdDate}>{formattedDate}</time>
          </div>
          {notification.isRead === false && (
            <LoadingButton
              className="btn btn-link fs-14 lh-1 fw-normal p-0"
              onClick={() => markNotificationAsReadMutation(notification.id)}
            >
              Mark as read
            </LoadingButton>
          )}
        </div>
      </div>
    </div>
  );
}

function Notifications() {
  const { ref, inView } = useInView();

  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);
  const toggleOffcanvas = () => {
    setIsOffcanvasOpen((state) => !state);
  };
  const closeOffcanvas = useCallback(() => {
    setIsOffcanvasOpen(false);
  }, []);

  const [unreadCount, setUnreadCount] = useState(0);
  const [notificationList, setNotificationList] = useState<UserNotificationDto[]>([]);

  const { data: notificationUnreadCount, refetch: refetchNotificationUnreadCount } = useQuery({
    queryKey: ['getUserNotificationUnreadCount'],
    async queryFn({ signal }) {
      const config = { signal };
      const res = await getUserNotificationUnreadCount(config);
      return res.data;
    },
    placeholderData: keepPreviousData,
    retry: false,
    refetchOnWindowFocus: false,
    refetchInterval: (isOffcanvasOpen ? 30 : 120) * 1000,
  });
  useEffect(() => {
    setUnreadCount(typeof notificationUnreadCount === 'number' ? notificationUnreadCount : 0);
  }, [notificationUnreadCount, setUnreadCount]);

  const queryClient = useQueryClient();
  const {
    data: notificationInfinitePages,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    refetch,
  } = useInfiniteQuery({
    enabled: isOffcanvasOpen,
    queryKey: ['getUserNotificationList'],
    initialPageParam: { take: 20, skip: 0 },
    async queryFn({ signal, pageParam }) {
      const config = { signal };
      const res = await getUserNotificationList(pageParam, config);
      return res;
    },
    getPreviousPageParam(firstPage) {
      const { take, skip } = firstPage.config.params as GetUserNotificationListParams;
      if (firstPage.data.length < take) return undefined;
      return { take, skip: skip - take };
    },
    getNextPageParam(lastPage) {
      const { take, skip } = lastPage.config.params as GetUserNotificationListParams;
      if (lastPage.data.length < take) return undefined;
      return { take, skip: skip + take };
    },
    placeholderData: keepPreviousData,
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    gcTime: 0,
  });
  useEffect(() => {
    if (!notificationInfinitePages) {
      setNotificationList([]);
      return;
    }
    const notifications = notificationInfinitePages.pages.reduce<UserNotificationDto[]>((acc, { data }) => {
      data.forEach((notification) => acc.push(notification));
      return acc;
    }, []);
    setNotificationList(notifications);
  }, [notificationInfinitePages]);

  const { mutate: markNotificationAsReadMutation } = useMutation({
    async mutationFn(notificationId: string) {
      await markNotificationAsRead(notificationId);
    },
    onSuccess(res, notificationId) {
      // Decrement unread counter
      setUnreadCount((c) => c - 1);

      // Update notification
      setNotificationList((oldNotificationList) =>
        oldNotificationList.map((notification) =>
          notification.id === notificationId ? { ...notification, isRead: true } : notification,
        ),
      );
    },
  });

  const { mutate: markAllNotificationAsReadMutation } = useMutation({
    async mutationFn() {
      await markAllNotificationAsRead();
    },
    onSuccess() {
      // Set unread counter to zero
      setUnreadCount(0);

      // Update all notifications
      setNotificationList((oldNotificationList) =>
        oldNotificationList.map((notification) => ({ ...notification, isRead: true })),
      );
    },
  });

  const { mutate: deleteNotificationMutation } = useMutation({
    async mutationFn(notification: UserNotificationDto) {
      await deleteNotification(notification.id);
    },
    onSuccess(res, notification) {
      // If unread deleted
      if (notification.isRead === false) {
        // Decrement unread counter
        setUnreadCount((c) => c - 1);
      }

      // Update notification
      setNotificationList((oldNotificationList) =>
        oldNotificationList.filter((currentNotification) => currentNotification.id !== notification.id),
      );
    },
  });

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);

  // Fetch new on open
  // Fetch new on unread count change if opened
  // Clean on close
  useEffect(() => {
    if (isOffcanvasOpen) {
      refetch();
    } else {
      queryClient.removeQueries({ queryKey: ['getUserNotificationList'] });
    }
  }, [notificationUnreadCount, isOffcanvasOpen, refetch, queryClient]);

  // refetch notificationUnreadCount on open
  useEffect(() => {
    if (isOffcanvasOpen) {
      refetchNotificationUnreadCount();
    }
  }, [isOffcanvasOpen, refetchNotificationUnreadCount]);

  return (
    <>
      <button
        className="btn btn-link position-relative px-1"
        type="button"
        aria-label="notifications"
        onClick={toggleOffcanvas}
      >
        <NotificationIcon width={25} height={23} />
        {unreadCount > 0 && (
          <figure className="position-absolute top-50 start-100 translate-middle badge rounded-pill bg-danger fw-normal">
            {unreadCount > 99 ? '99+' : unreadCount}
            <span className="visually-hidden">unread messages</span>
          </figure>
        )}
      </button>

      <Offcanvas
        className={classNames(styles.Offcanvas)}
        backdropClassName={styles.OffcanvasBackdrop}
        show={isOffcanvasOpen}
        onHide={closeOffcanvas}
        placement="end"
      >
        <header className="hstack flex-wrap justify-content-between border-top border-bottom gap-3 p-3">
          <h2 className="h4 mb-0">Notifications</h2>
          {unreadCount > 0 && (
            <LoadingButton className="btn btn-link px-0" onClick={() => markAllNotificationAsReadMutation()}>
              Mark all as read
            </LoadingButton>
          )}
        </header>
        <Offcanvas.Body className="custom-scroll p-0" as="section">
          {notificationList.length > 0 ? (
            notificationList.map((notification) => (
              <Notification
                notification={notification}
                markNotificationAsReadMutation={markNotificationAsReadMutation}
                deleteNotificationMutation={deleteNotificationMutation}
                closeModal={closeOffcanvas}
                key={notification.id}
              />
            ))
          ) : (
            <div className="p-3">{isLoading ? 'Loading...' : 'No notifications'}</div>
          )}
          {hasNextPage && (
            <div className="p-3 text-center" ref={ref}>
              <LoadingButton className="btn btn-link" disabled={isFetchingNextPage} onClick={() => fetchNextPage()}>
                {isFetchingNextPage ? 'Loading...' : 'Load more'}
              </LoadingButton>
            </div>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default Notifications;
