import classNames from 'classnames';
import { useState, useEffect, useId, useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams, Params, generatePath } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { useForm } from 'react-hook-form';
import { object, string, array } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Field from 'src/components/forms/Field';
import LoadingButton from 'src/components/buttons/LoadingButton';
import ServerErrorMessages from 'src/components/ServerErrorMessages';

import useUserPermission from 'src/hooks/useUserPermission';
import usePersistPageParams, { DEAL_ROTOR_LIST_PAGE_KEY } from 'src/hooks/usePersistPageParams';
import { useFormErrors } from 'src/hooks/FormHelpers';

import ServerErrorAdapter from 'src/utils/ServerErrorAdapter';
import { dealRotorTypeOptions, dealRotorRelationshipTypeOptions } from 'src/utils/selectOptions';

import {
  getDealRotorById,
  activateDealRotor,
  deleteDealRotorById,
  getDealRotorListSearch,
  saveDealRotorAsDraft,
} from 'src/api/base/dealRotor';
import { getOrganisationPartyBListSummary } from 'src/api/base/organisation';

import {
  DealRotorActivateModel,
  DealRotorType,
  YupFormShape,
  ServerError,
  SelectOption,
  PermissionNames,
  OrganisationStatus,
} from 'src/types';

type FormData = DealRotorActivateModel;
type FormShape = YupFormShape<FormData>;
const formSchema = object().shape<FormShape>({
  partyB: array().of(string()).label('Party B').required().min(1),
  name: string().label('Deal name').trim().required(),
  relationshipType: string().label('Relationship type').trim().required(),
  type: string().label('Deal type').trim().required(),
  description: string().label('Description').nullable().trim().max(210),
  tags: array().of(string()).label('Tags'),
  parentDealRotorId: string()
    .label('Parent deal rotor')
    .trim()
    .nullable()
    .emptyToUndefined()
    .when('type', {
      is: DealRotorType.Child,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional(),
    }),
});

function DealRotorCreateDetails() {
  const { pageUrlSearch: dealRotorListPageUrlSearch } = usePersistPageParams(DEAL_ROTOR_LIST_PAGE_KEY);

  const { hasUserPermission } = useUserPermission();
  const isAllowedOrganisationPartyBSummaryRead = hasUserPermission(PermissionNames.OrganisationPartyBSummaryRead);

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { dealRotorId = '' } = useParams<Params<'dealRotorId'>>();

  const [isActivateModalOpen, setIsActivateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const closeActivateModal = () => {
    setIsActivateModalOpen(false);
  };
  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const createDealRotorDetailsFormId = useId();
  const { register, control, handleSubmit, formState, setValue, setError, clearErrors, watch } = useForm<FormData>({
    resolver: yupResolver(formSchema),
  });
  const { serverErrorMessages, setServerErrorMessages, handleErrors } = useFormErrors<FormData>(setError, clearErrors);

  const [partyB, type, parentDealRotorId] = watch(['partyB', 'type', 'parentDealRotorId']);

  const {
    isLoading: isDealRotorLoading,
    data: dealRotor,
    error: dealRotorError,
  } = useQuery({
    enabled: Boolean(dealRotorId),
    queryKey: ['getDealRotorById', dealRotorId],
    async queryFn({ signal }) {
      const config = { signal };
      const res = await getDealRotorById(dealRotorId, config);
      return res.data;
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  const organisationStatus = OrganisationStatus.Active;
  const {
    isLoading: isPartyBSummaryLoading,
    data: partyBSummary,
    error: partyBSummaryError,
  } = useQuery({
    queryKey: ['getOrganisationPartyBListSummary', organisationStatus],
    async queryFn({ signal }) {
      if (!isAllowedOrganisationPartyBSummaryRead) return [];
      const config = { signal };
      const res = await getOrganisationPartyBListSummary(organisationStatus, config);
      return res.data;
    },
    initialData: [],
    retry: false,
    refetchOnWindowFocus: false,
  });
  const partyBOptions = useMemo<SelectOption[]>(
    () =>
      partyBSummary
        .toSorted((a, b) => a.name.localeCompare(b.name))
        .map(({ id, name }) => ({ label: name, value: id })),
    [partyBSummary],
  );

  const {
    isFetching: isDealRotorOptionsLoading,
    data: dealRotorOptions,
    error: dealRotorOptionsError,
  } = useQuery({
    enabled: type === DealRotorType.Child && partyB ? partyB.length > 0 : false,
    queryKey: ['getDealRotorListSearch', partyB],
    async queryFn({ signal }): Promise<SelectOption[]> {
      const config = { signal };
      const res = await getDealRotorListSearch({ partyB, type: DealRotorType.Parent, take: 1000 } as any, config);
      return res.data.items.map(({ id, name, number }) => ({ label: `${name} (${number})` || id, value: id }));
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    const dealRotorErrorMessages = new ServerErrorAdapter(dealRotorError as ServerError).combine();
    const dealRotorOptionsErrorMessages = new ServerErrorAdapter(dealRotorOptionsError as ServerError).combine();
    const partyBOptionsErrorMessages = new ServerErrorAdapter(partyBSummaryError as ServerError).combine();
    const errorMessages = [...dealRotorErrorMessages, ...dealRotorOptionsErrorMessages, ...partyBOptionsErrorMessages];
    setServerErrorMessages(errorMessages);
  }, [dealRotorError, dealRotorOptionsError, partyBSummaryError, setServerErrorMessages]);

  useEffect(() => {
    if (!dealRotor) return;
    setValue('partyB', dealRotor.partyB ? dealRotor.partyB.map(({ organisationId }) => organisationId) : []);
    setValue('name', dealRotor.name || '');
    setValue('relationshipType', dealRotor.relationshipType);
    setValue('type', dealRotor.type);
    setValue('parentDealRotorId', dealRotor.parentDealRotorId);
    setValue('description', dealRotor.description);
    setValue('tags', dealRotor.tags ? dealRotor.tags.map(({ name }) => name) : []);
  }, [dealRotor, setValue]);

  // Reset parent deal rotor value if it doesn't exist in options
  useEffect(() => {
    if (!dealRotor || !dealRotorOptions) return;
    if (!dealRotorOptions.find(({ value }) => value === parentDealRotorId)) {
      setValue('parentDealRotorId', '');
    }
  }, [dealRotor, dealRotorOptions, parentDealRotorId, setValue]);

  const deleteDealRotor = async () => {
    if (!dealRotorId) return;

    try {
      handleErrors();
      await deleteDealRotorById(dealRotorId);
      queryClient.removeQueries({ queryKey: ['getDealRotorListSearch'] });
      navigate({ pathname: RoutePath.dealRotorList, search: dealRotorListPageUrlSearch });
    } catch (e) {
      handleErrors(e as ServerError);
    } finally {
      closeDeleteModal();
    }
  };

  const onSaveDraft = handleSubmit(async (formData) => {
    if (!dealRotorId || !dealRotor) return;

    try {
      handleErrors();
      const payload = {
        ...formData,
        parentDealRotorId: formData.type === DealRotorType.Child ? formData.parentDealRotorId : null,
      };
      await saveDealRotorAsDraft(dealRotorId, payload);
      queryClient.removeQueries({ queryKey: ['getDealRotorById', dealRotorId] });
      queryClient.removeQueries({ queryKey: ['getDealRotorListSearch'] });
      navigate({ pathname: RoutePath.dealRotorList, search: dealRotorListPageUrlSearch });
    } catch (e) {
      const errors = new ServerErrorAdapter(e as ServerError).combine();
      setServerErrorMessages(errors);
    }
  });

  const onSubmit = handleSubmit(async (formData) => {
    if (!dealRotorId || !dealRotor) return;

    if (isActivateModalOpen === false) {
      setIsActivateModalOpen(true);
      return;
    }

    try {
      handleErrors();
      const payload = {
        ...formData,
        parentDealRotorId: formData.type === DealRotorType.Child ? formData.parentDealRotorId : null,
      };
      await activateDealRotor(dealRotorId, payload);
      queryClient.removeQueries({ queryKey: ['getDealRotorListSearch'] });
      navigate(generatePath(RoutePath.dealRotorEditDetailedDealRotorDetails, { dealRotorId }));
    } catch (e) {
      const errors = new ServerErrorAdapter(e as ServerError).combine();
      setServerErrorMessages(errors);
    } finally {
      closeActivateModal();
    }
  });

  return (
    <section>
      <header className="d-flex justify-content-between flex-wrap bg-white border-start border-end gap-3 p-4">
        <h2 className="h5 mb-0">Deal rotor details</h2>

        <div className="d-flex gap-5 placeholder-glow">
          <strong className="fw-normal">
            <span className="text-secondary">Deal number: </span>
            <span className={classNames('text-nowrap align-baseline', { placeholder: isDealRotorLoading })}>
              {isDealRotorLoading ? 'DL-000000' : dealRotor?.number ?? '---'}
            </span>
          </strong>
          <strong className="fw-normal">
            <span className="text-secondary">Deal template: </span>
            <span className={classNames('text-nowrap align-baseline', { placeholder: isDealRotorLoading })}>
              {isDealRotorLoading ? 'Deal Template' : dealRotor?.templateName ?? '---'}
            </span>
          </strong>
        </div>
      </header>
      <form name="createDealRotorDetails" id={createDealRotorDetailsFormId} onSubmit={onSubmit} noValidate>
        <div className="bg-white border border-top-0 rounded-bottom p-4 pt-0 mb-4">
          <div className="row g-3 mb-3">
            <Field
              className="col-sm-6 col-md-6 col-lg-6 col-xl-3"
              field="dropdown"
              label="Party B"
              placeholder="Choose party B"
              autoComplete="off"
              register={register('partyB')}
              control={control}
              formSchema={formSchema}
              errors={formState.errors}
              options={partyBOptions}
              dropdownProps={{
                isLoading: isPartyBSummaryLoading,
                isSearchable: true,
                isClearable: false,
              }}
              multiple
              disabled={isDealRotorLoading}
            />

            <Field
              className="col-sm-6 col-md-6 col-lg-6 col-xl-3"
              field="input"
              type="text"
              label="Deal name"
              placeholder="Enter deal name"
              autoComplete="off"
              register={register('name')}
              control={control}
              formSchema={formSchema}
              errors={formState.errors}
              disabled={isDealRotorLoading}
            />

            <Field
              className="col-sm-6 col-md-6 col-lg-6 col-xl-3"
              field="dropdown"
              label="Relationship type"
              placeholder="Choose relationship type"
              autoComplete="off"
              register={register('relationshipType')}
              control={control}
              formSchema={formSchema}
              errors={formState.errors}
              options={dealRotorRelationshipTypeOptions}
              dropdownProps={{
                isSearchable: true,
                isClearable: false,
              }}
              disabled={isDealRotorLoading}
            />
          </div>

          <div className="row g-3 mb-3">
            <Field
              className="col-sm-6 col-md-6 col-lg-6 col-xl-3"
              inputGroupClassName="d-flex align-items-center h-44"
              formCheckClassName="form-check-inline"
              field="input"
              type="radio"
              label="Deal type"
              placeholder="Choose deal type"
              autoComplete="off"
              register={register('type')}
              control={control}
              formSchema={formSchema}
              errors={formState.errors}
              options={dealRotorTypeOptions}
              disabled={isDealRotorLoading}
            />

            {type === DealRotorType.Child && (
              <Field
                className="col-sm-6 col-md-6 col-lg-6 col-xl-3"
                field="dropdown"
                label="Parent deal rotor"
                placeholder="Choose parent deal rotor"
                autoComplete="off"
                register={register('parentDealRotorId')}
                control={control}
                formSchema={formSchema}
                errors={formState.errors}
                options={dealRotorOptions}
                dropdownProps={{
                  isLoading: isDealRotorOptionsLoading,
                  isSearchable: true,
                  isClearable: false,
                  noOptionsMessage: () => (partyB && partyB.length > 0 ? 'No options' : 'Choose party B first'),
                }}
                disabled={isDealRotorLoading}
              />
            )}
          </div>

          <div className="row g-3">
            <Field
              className="col-sm-6 col-md-6 col-lg-6 col-xl-3"
              field="textarea"
              label="Description"
              placeholder="Enter description"
              autoComplete="off"
              max={210}
              register={register('description')}
              control={control}
              formSchema={formSchema}
              errors={formState.errors}
              options={[]}
              dropdownProps={{
                isSearchable: true,
                isClearable: false,
              }}
              disabled={isDealRotorLoading}
              After={<div className="text-end text-secondary fs-14 pt-1">Maximum 210 characters allowed</div>}
            />

            <Field
              className="col-sm-6 col-md-6 col-lg-6 col-xl-3"
              field="tags"
              label="Tags"
              placeholder="Enter to create a tag"
              autoComplete="off"
              register={register('tags')}
              control={control}
              formSchema={formSchema}
              errors={formState.errors}
              disabled={isDealRotorLoading}
              After={<div className="text-end text-secondary fs-14 pt-1">Separate by comma (,) to create a tag</div>}
            />
          </div>
        </div>
        <footer className="d-flex flex-wrap justify-content-end gap-3">
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={() => setIsDeleteModalOpen(true)}
            disabled={isDealRotorLoading}
          >
            Delete deal
          </button>
          <LoadingButton
            type="button"
            className="btn btn-outline-primary"
            onClick={onSaveDraft}
            disabled={isDealRotorLoading}
          >
            Save as draft
          </LoadingButton>
          <LoadingButton type="submit" className="btn btn-primary text-nowrap" disabled={isDealRotorLoading}>
            Activate
          </LoadingButton>
          <ServerErrorMessages className="w-100" messages={serverErrorMessages} />
        </footer>
      </form>

      <Modal show={isActivateModalOpen} onHide={closeActivateModal} size="lg" centered>
        <Modal.Header onHide={closeActivateModal} closeButton>
          <Modal.Title className="h5">Are you sure you want to activate the deal rotor?</Modal.Title>
        </Modal.Header>
        <Modal.Body>After activating the deal rotor it will be activated</Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-link" onClick={closeActivateModal}>
            Cancel
          </button>
          <LoadingButton
            type="submit"
            className="btn btn-primary"
            form={createDealRotorDetailsFormId}
            isLoading={formState.isSubmitting}
          >
            Activate
          </LoadingButton>
          <ServerErrorMessages className="w-100" messages={serverErrorMessages} />
        </Modal.Footer>
      </Modal>

      <Modal show={isDeleteModalOpen} onHide={closeDeleteModal} centered>
        <Modal.Header onHide={closeDeleteModal} closeButton>
          <Modal.Title className="h5">Are you sure you want to delete the deal rotor?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <button type="button" className="btn btn-link" onClick={closeDeleteModal}>
            Cancel
          </button>
          <LoadingButton type="button" className="btn btn-danger" onClick={deleteDealRotor}>
            Delete
          </LoadingButton>
          <ServerErrorMessages className="w-100" messages={serverErrorMessages} />
        </Modal.Footer>
      </Modal>
    </section>
  );
}

export default DealRotorCreateDetails;
