import classNames from 'classnames';
import { useState, useId, useEffect, useMemo } from 'react';
import { useWatch, Control } from 'react-hook-form';

import { FormTableData } from 'src/pages/configuration/components/RolePermissionConfig.table';

import styles from './RolePermissionConfig.module.scss';

export enum IndeterminateStatus {
  Checked = 'Checked',
  Unchecked = 'Unchecked',
  Indeterminate = 'Indeterminate',
}

export type IndeterminateChangeEvent = React.ChangeEvent<HTMLInputElement> & {
  IndeterminateState: IndeterminateStatus;
};

type Props = Omit<React.HTMLProps<HTMLInputElement>, 'onChange' | 'type' | 'checked' | 'ref'> & {
  state?: IndeterminateStatus;
  permissionsList: Record<string, Record<string, string[]>[]>;
  permissionType: string;
  control: Control<FormTableData, any>;
  roleId: string;
  onChange?: (event: IndeterminateChangeEvent) => void;
  index: number;
};

const nextState = (status: IndeterminateStatus): IndeterminateStatus => {
  switch (status) {
    case IndeterminateStatus.Indeterminate:
      return IndeterminateStatus.Unchecked;
    case IndeterminateStatus.Checked:
      return IndeterminateStatus.Indeterminate;
    default:
      return IndeterminateStatus.Checked;
  }
};

const CheckboxIndeterminate = ({
  onChange,
  state,
  permissionsList,
  permissionType,
  control,
  roleId,
  index,
  ...rest
}: Props) => {
  const checkBoxId = useId();
  const [checkState, setCheckState] = useState<{ [key: string]: IndeterminateStatus }>({
    [checkBoxId]: state || IndeterminateStatus.Unchecked,
  });
  const formValues: string[] = useWatch({ control, name: `permissionsCollection.${index}.permissionIds` as const });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange !== undefined) {
      e.persist();
      onChange({ ...e, IndeterminateState: nextState(checkState[checkBoxId]) });
      setCheckState((prevState) => {
        const nextStatus = nextState(prevState[checkBoxId]);
        return { ...prevState, ...{ [checkBoxId]: nextStatus } };
      });
    }
  };

  const setIndeterminate = (checkbox: HTMLInputElement | null) => {
    if (checkbox !== null) {
      checkbox.indeterminate = checkState[checkBoxId] === IndeterminateStatus.Indeterminate;
    }
  };

  const calcIndeterminate = useMemo(() => {
    if (!permissionsList) return;
    if (!formValues) return;
    const permissionByRole = permissionsList[roleId];
    const permissionByType = permissionByRole.find((i) => i[permissionType]);
    const permissionSet = permissionByType ? permissionByType[permissionType] : [];
    const checked = permissionSet ? permissionSet.filter((i) => formValues.some((j) => i === j)) : [];

    if (checked.length === 0) return IndeterminateStatus.Unchecked;
    if (checked.length === permissionSet.length) return IndeterminateStatus.Checked;

    return IndeterminateStatus.Indeterminate;
  }, [roleId, permissionsList, permissionType, formValues]);

  useEffect(() => {
    if (!calcIndeterminate) return;
    setCheckState({ [checkBoxId]: calcIndeterminate });
  }, [calcIndeterminate, checkBoxId]);

  return (
    <input
      id={checkBoxId}
      className={classNames(styles.Checkbox, styles.CheckboxType, 'form-check-input')}
      type="checkbox"
      checked={checkState[checkBoxId] === IndeterminateStatus.Checked}
      onChange={handleChange}
      ref={setIndeterminate}
      {...rest}
    />
  );
};

export default CheckboxIndeterminate;
