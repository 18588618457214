import classNames from 'classnames';
import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Params, useParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

import LoadingButton from 'src/components/buttons/LoadingButton';
import ServerErrorMessages from 'src/components/ServerErrorMessages';

import ServerErrorAdapter from 'src/utils/ServerErrorAdapter';

import { moveToInactiveAgreement } from 'src/api/base/agreement';

import { ServerError, ServerFormErrors } from 'src/types';

type Props = {
  className?: string;
  agreementId: string;
  isAllowRevertToDraft: boolean;
};

type CancelSigningModalProps = {
  agreementId: string;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

function RevertToDraftModal({ agreementId, isModalOpen, setIsModalOpen }: CancelSigningModalProps) {
  const queryClient = useQueryClient();
  const { dealRotorId = '' } = useParams<Params<'dealRotorId'>>();

  const [serverErrorMessages, setServerErrorMessages] = useState<ServerFormErrors>([]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onRevertToDraft = async () => {
    try {
      await moveToInactiveAgreement(agreementId);

      await Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: ['getDealRotorById', dealRotorId] }),
        queryClient.invalidateQueries({ queryKey: ['getAgreementList', dealRotorId] }),
        queryClient.invalidateQueries({ queryKey: ['getDealRotorAuditListSearch', dealRotorId] }),
      ]);
      queryClient.removeQueries({ queryKey: ['getDealRotorListSearch'] });
      queryClient.removeQueries({ queryKey: ['getDealRotorAuditListAll'] });

      closeModal();
    } catch (e) {
      const serverErrors = new ServerErrorAdapter(e as ServerError);
      setServerErrorMessages(serverErrors.combine());
    }
  };

  return (
    <Modal show={isModalOpen} onHide={closeModal} size="lg" centered>
      <Modal.Header onHide={closeModal} closeButton>
        <Modal.Title className="h5">Are you sure you want to revert back this agreement to draft?</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <button type="button" className="btn btn-link" onClick={closeModal}>
          No
        </button>
        <LoadingButton type="button" className="btn btn-primary" onClick={onRevertToDraft}>
          Yes
        </LoadingButton>
        <ServerErrorMessages className="w-100 mt-2 mb-0" messages={serverErrorMessages} />
      </Modal.Footer>
    </Modal>
  );
}

function AgreementDetailsRevertToDraft({ className, agreementId, isAllowRevertToDraft }: Props) {
  const [isCancelSigningModalOpen, setIsCancelSigningModalOpen] = useState(false);

  return (
    <>
      <button
        type="button"
        className={classNames('btn btn-primary', className)}
        onClick={() => setIsCancelSigningModalOpen(true)}
        disabled={!isAllowRevertToDraft}
      >
        Revert to draft
      </button>

      <RevertToDraftModal
        agreementId={agreementId}
        isModalOpen={isCancelSigningModalOpen}
        setIsModalOpen={setIsCancelSigningModalOpen}
      />
    </>
  );
}

export default AgreementDetailsRevertToDraft;
