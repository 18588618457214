import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Outlet } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { useForm, UseFormHandleSubmit, UseFormWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, array, boolean } from 'yup';

import Field from 'src/components/forms/Field';
import TabNavigation from 'src/components/TabNavigation';
import ServerErrorMessages from 'src/components/ServerErrorMessages';

import useUserPermission from 'src/hooks/useUserPermission';
import { useFormErrors } from 'src/hooks/FormHelpers';

import ServerErrorAdapter from 'src/utils/ServerErrorAdapter';
import { organisationStatusSortingScore } from 'src/utils/selectOptions';

import {
  YupFormShape,
  ServerError,
  CurrencyCodes,
  AgreementStage,
  SelectOption,
  PermissionNames,
  OrganisationStatus,
} from 'src/types';

import {
  getAllPartyB,
  getAllPartyBForCurrentUser,
  getAnalytics,
  getAnalyticsForCurrentUser,
} from 'src/api/base/analytics';
import { getDealRotorTemplateList } from 'src/api/base/dealRotor';

export enum TimeLineRadioType {
  agreementStatus = 'agreementStatus',
  dealTemplate = 'dealTemplate',
}

export const timelineOptions: SelectOption[] = [
  { value: TimeLineRadioType.agreementStatus, label: 'By agreement status' },
  { value: TimeLineRadioType.dealTemplate, label: 'By deal template' },
];

export type FormData = {
  partyBIds: string[];
  isIncludeAllDeals: boolean;
  currency: CurrencyCodes;
  stages: AgreementStage[];
  templateIds: string[];
  timelineFilter: TimeLineRadioType;
};

type FormShape = YupFormShape<FormData>;
export const formSchema = object().shape<FormShape>({
  partyBIds: array().of(string()).label('Parties').max(5),
  isIncludeAllDeals: boolean(),
  currency: string(),
  stages: array().of(string()),
  timelineFilter: string().label('Filter'),
  templateIds: array().of(string()).label('Deal template').nullable(),
});

const defaultValues: FormData = {
  partyBIds: [],
  isIncludeAllDeals: false,
  currency: CurrencyCodes.GBP,
  stages: [AgreementStage.Active],
  timelineFilter: TimeLineRadioType.agreementStatus,
  templateIds: [],
};

function useFormattedWatch(
  watch: UseFormWatch<FormData>,
  handleSubmit: UseFormHandleSubmit<FormData, undefined>,
): FormData {
  const [data, setData] = useState<FormData>({} as FormData);
  const { partyBIds, isIncludeAllDeals, currency, stages, templateIds } = watch();

  useEffect(() => {
    handleSubmit((formData) => {
      setData({ ...formData });
    })();
  }, [partyBIds, isIncludeAllDeals, currency, stages, templateIds, handleSubmit]);

  return data;
}

const AnalyticsContainer = () => {
  const { hasUserPermission } = useUserPermission();
  const isAllowedAnalyticAllSystemDealsRotorsRead = hasUserPermission(PermissionNames.AnalyticAllSystemDealsRotorsRead);

  const { register, control, formState, handleSubmit, setError, clearErrors, watch, setValue } = useForm<FormData>({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    defaultValues,
  });
  const { serverErrorMessages, setServerErrorMessages } = useFormErrors<FormData>(setError, clearErrors);
  const { partyBIds, isIncludeAllDeals, stages, templateIds } = useFormattedWatch(watch, handleSubmit);

  const tabs = useMemo(
    () => [
      { name: 'Portfolio analysis', to: RoutePath.analyticsPortfolioAnalysis },
      { name: 'Portfolio timeline', to: RoutePath.analyticsPortfolioTimeline },
    ],
    [],
  );

  const { data: partyBList, error: partyBListError } = useQuery({
    queryKey: ['getAllPartyB', isAllowedAnalyticAllSystemDealsRotorsRead],
    async queryFn({ signal }) {
      const config = { signal };
      const res = isAllowedAnalyticAllSystemDealsRotorsRead
        ? await getAllPartyB(config)
        : await getAllPartyBForCurrentUser(config);
      return res.data;
    },
    initialData: [],
    retry: false,
    refetchOnWindowFocus: false,
  });

  const partyBListOptions = useMemo<SelectOption[]>(
    () =>
      partyBList
        .toSorted((a, b) =>
          a.status === b.status
            ? a.name.localeCompare(b.name)
            : organisationStatusSortingScore[a.status] - organisationStatusSortingScore[b.status],
        )
        .map((el) => {
          const isEmpty = el.dealsCount < 1;
          let optionLabel = el.name;
          if (!isEmpty) optionLabel += ` (${el.dealsCount} deal${el.dealsCount > 1 ? 's' : ''})`;
          if (el.status === OrganisationStatus.Archived) optionLabel += ' (Archived)';
          const option = {
            label: optionLabel,
            value: el.id,
            isDisabled: isEmpty,
          };
          return option;
        }),
    [partyBList],
  );

  const {
    data: analytics,
    error: analyticsListError,
    isLoading: analyticsIsLoading,
  } = useQuery({
    enabled: stages !== undefined,
    queryKey: [
      'getAnalytics',
      isAllowedAnalyticAllSystemDealsRotorsRead,
      isIncludeAllDeals,
      partyBIds,
      stages,
      templateIds,
    ],
    async queryFn({ signal }) {
      const config = { signal };
      const params = { partyBIds, stages, templateIds };
      const res =
        isAllowedAnalyticAllSystemDealsRotorsRead && isIncludeAllDeals
          ? await getAnalytics(params, config)
          : await getAnalyticsForCurrentUser(params, config);
      return res.data;
    },
    gcTime: 0,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const { data: dealRotorTemplateList, error: dealRotorTemplateListError } = useQuery({
    queryKey: ['getDealRotorTemplateList'],
    async queryFn({ signal }) {
      const config = { signal };
      const res = await getDealRotorTemplateList(config);
      return res.data;
    },
    initialData: [],
    retry: false,
    refetchOnWindowFocus: false,
  });

  const dealRotorTemplateOptions = useMemo<SelectOption[]>(() => {
    return dealRotorTemplateList.map((dealRotorTemplate) => ({
      label: dealRotorTemplate.description,
      value: dealRotorTemplate.id,
    }));
  }, [dealRotorTemplateList]);

  useEffect(() => {
    const getAllPartyBError = new ServerErrorAdapter(partyBListError as ServerError).combine();
    const getAnalyticsError = new ServerErrorAdapter(analyticsListError as ServerError).combine();
    const getDealRotorTemplateError = new ServerErrorAdapter(dealRotorTemplateListError as ServerError).combine();
    const errorMessages = [...getAllPartyBError, ...getAnalyticsError, ...getDealRotorTemplateError];
    setServerErrorMessages(errorMessages);
  }, [analyticsListError, partyBListError, setServerErrorMessages, dealRotorTemplateListError]);

  return (
    <>
      <form name="AnalyticsForm" noValidate>
        <div className="row col-md-12">
          <Field
            className="col col-lg-6 mb-2"
            label="Parties"
            field="dropdown"
            placeholder="All parties"
            autoComplete="off"
            register={register('partyBIds')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
            options={partyBListOptions}
            dropdownProps={{
              isSearchable: true,
              isClearable: true,
            }}
            multiple
          />
        </div>
        {isAllowedAnalyticAllSystemDealsRotorsRead && (
          <div className="row">
            <Field
              className="col-12 mb-3"
              field="input"
              type="checkbox"
              label="Include all user deals"
              register={register('isIncludeAllDeals')}
              control={control}
              formSchema={formSchema}
              errors={formState.errors}
            />
          </div>
        )}
      </form>
      <TabNavigation className="mb-4 border-bottom" tabs={tabs} />
      <Outlet
        context={{ control, register, formState, setValue, analytics, analyticsIsLoading, dealRotorTemplateOptions }}
      />
      <ServerErrorMessages messages={serverErrorMessages} />
    </>
  );
};

export default AnalyticsContainer;
