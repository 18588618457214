import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { object, string, array } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Field from 'src/components/forms/Field';
import LoadingButton from 'src/components/buttons/LoadingButton';
import ServerErrorMessages from 'src/components/ServerErrorMessages';

import { useFormErrors } from 'src/hooks/FormHelpers';

import { UpsertDealManagersModel, YupFormShape, SelectOption, ServerError, UUIDString } from 'src/types';
import { useEffect, useMemo } from 'react';

type ManagerFormProps = {
  formName: string;
  fieldLabel: string;
  dealRotorId: UUIDString;
  userIds: UUIDString[];
  isLoading: boolean;
  isUserOptionsLoading: boolean;
  userOptions: SelectOption[];
  isAllowEditParticipants: boolean;
  upsertManagers: (
    dealRotorId: UUIDString,
    data: UpsertDealManagersModel,
    config?: AxiosRequestConfig,
  ) => Promise<AxiosResponse<void, any>>;
};

type FormData = { userIds: UUIDString[] };
type FormShape = YupFormShape<FormData>;

function ManagerForm({
  formName,
  fieldLabel,
  userIds,
  dealRotorId,
  isLoading,
  isUserOptionsLoading,
  userOptions,
  isAllowEditParticipants,
  upsertManagers,
}: ManagerFormProps) {
  const queryClient = useQueryClient();

  const formSchema = useMemo(
    () =>
      object().shape<FormShape>({
        userIds: array().of(string()).label(fieldLabel),
      }),
    [fieldLabel],
  );

  const { register, control, handleSubmit, formState, setValue, setError, clearErrors, watch } = useForm<FormData>({
    resolver: yupResolver(formSchema),
    defaultValues: { userIds: [] },
  });
  const { serverErrorMessages, handleErrors } = useFormErrors<FormData>(setError, clearErrors);

  const selectedUserIds = watch('userIds');
  const isSameValue = useMemo(
    () => selectedUserIds.length === userIds.length && selectedUserIds.every((id) => userIds.includes(id)),
    [selectedUserIds, userIds],
  );

  useEffect(() => {
    setValue('userIds', userIds);
  }, [userIds, setValue]);

  const onSubmit = handleSubmit(async (formData) => {
    try {
      handleErrors();
      await upsertManagers(dealRotorId, formData);

      await Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: ['getDealRotorParticipantList', dealRotorId] }),
        queryClient.invalidateQueries({ queryKey: ['getDealRotorAuditListSearch', dealRotorId] }),
        queryClient.invalidateQueries({ queryKey: ['getDealRotorAuditParticipantList', dealRotorId] }),
      ]);
      queryClient.removeQueries({ queryKey: ['getDealRotorListSearch'] });
      queryClient.removeQueries({ queryKey: ['getDealRotorAuditListAll'] });
    } catch (e) {
      handleErrors(e as ServerError);
    }
  });

  return (
    <form name={formName} onSubmit={onSubmit} noValidate>
      <Field
        field="dropdown"
        label={fieldLabel}
        placeholder={`${isAllowEditParticipants ? 'Choose' : 'No'} ${fieldLabel.toLocaleLowerCase()}`}
        autoComplete="off"
        register={register('userIds')}
        control={control}
        formSchema={formSchema}
        errors={formState.errors}
        options={userOptions}
        disabled={isLoading || !isAllowEditParticipants}
        dropdownProps={{
          isLoading: isUserOptionsLoading,
          isSearchable: true,
          isClearable: false,
        }}
        multiple
      />

      {isAllowEditParticipants && (
        <div className="d-flex flex-wrap justify-content-end gap-3 mt-3">
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={() => {
              setValue('userIds', userIds);
              handleErrors();
            }}
            disabled={isLoading || isSameValue}
          >
            Cancel
          </button>
          <LoadingButton
            type="submit"
            className="btn btn-primary text-nowrap"
            disabled={isLoading || isSameValue}
            isLoading={formState.isSubmitting}
          >
            Save
          </LoadingButton>
          <ServerErrorMessages className="w-100" messages={serverErrorMessages} />
        </div>
      )}
    </form>
  );
}

export default ManagerForm;
