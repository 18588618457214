import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

import Converter from 'src/utils/Converter';
import { SENTRY_ENABLED, SENTRY_DSN, SENTRY_ENV, AUTH_API_URL, BASE_API_URL } from 'src/utils/constants';

Sentry.init({
  enabled: SENTRY_ENABLED === 'true' || (SENTRY_ENABLED as unknown as boolean) === true,
  dsn: SENTRY_DSN,
  environment: SENTRY_ENV,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    new RegExp(`^${Converter.escapeStringRegexp(AUTH_API_URL)}`),
    new RegExp(`^${Converter.escapeStringRegexp(BASE_API_URL)}`),
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  ignoreErrors: [/^AxiosError: Network Error$/, /^AxiosError: timeout exceeded$/],
});
