import classNames from 'classnames';
import { useMemo, useCallback, useEffect } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useParams, Params, Link, generatePath, useOutletContext } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { object, string, mixed } from 'yup';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Field from 'src/components/forms/Field';
import Table, { createColumnHelper, useTableChangeParams } from 'src/components/Table';
import FileTypeIcon from 'src/components/icons/FileTypeIcon';
import LoadingButton from 'src/components/buttons/LoadingButton';
import ServerErrorMessages from 'src/components/ServerErrorMessages';
import { NoteFileTypeIcon } from 'src/components/icons';

import Download from 'src/utils/Download';
import Converter from 'src/utils/Converter';
import ServerErrorAdapter from 'src/utils/ServerErrorAdapter';
import RequestSearchParamsAdapter from 'src/utils/RequestSearchParamsAdapter';
import { dealRotorAttachmentOptions } from 'src/utils/selectOptions';

import useUserPermission from 'src/hooks/useUserPermission';
import { useFormErrors } from 'src/hooks/FormHelpers';

import { DealRotorEditDetailedTabsOutletContext } from './DealRotorEdit.detailed.tabs';

import { getAttachments, uploadAttachment, downloadAttachment } from 'src/api/base/dealRotor';

import {
  YupFormShape,
  DealRotorAttachmentDto,
  ServerError,
  DealRotorAttachmentType,
  DealRotorStatus,
  PermissionNames,
} from 'src/types';

import styles from 'src/pages/deal-rotor/DealRotorEdit.detailed.tabs.attachments.module.scss';

const allowedFileExtensions = [
  '.pdf',
  '.doc',
  '.docx',
  '.rtf',
  '.txt',
  '.xls',
  '.xlsx',
  '.ppt',
  '.pptx',
  '.png',
  '.jpg',
  '.jpeg',
  '.bmp',
  '.gif',
  '.msg',
  '.pst',
];

type FormSearch = {
  search: string;
};
type FormSearchShape = YupFormShape<FormSearch>;
type FormData = {
  type: DealRotorAttachmentType;
  description: string;
  file: FileList;
};
type FormShape = YupFormShape<FormData>;
const formSearchSchema = object().shape<FormSearchShape>({
  search: string().label('Search'),
});
const formSchema = object().shape<FormShape>({
  type: string().label('Attachment type'),
  description: string().when('type', {
    is: DealRotorAttachmentType.Note,
    then: (schema) => schema.label('Note').required().max(210),
    otherwise: (schema) => schema.label('Description').optional().max(210),
  }),
  file: mixed<FileList>()
    .label('Attachment file')
    .fileExt(allowedFileExtensions)
    .fileSizeMax(10000000)
    .when('type', {
      is: DealRotorAttachmentType.Attachment,
      then: (schema) => schema.fileRequired(),
      otherwise: (schema) => schema.optional(),
    }),
});

const columnHelper = createColumnHelper<DealRotorAttachmentDto>();

const AttachmentsTab = () => {
  const { hasUserPermission } = useUserPermission();
  const isAllowedUserReadContacts = hasUserPermission(PermissionNames.UserReadContacts);
  const isAllowedDealRotorAttachmentWrite = hasUserPermission(PermissionNames.DealRotorAttachmentWrite);

  const queryClient = useQueryClient();
  const { dealRotorId = '' } = useParams<Params<'dealRotorId'>>();
  const { isDealRotorLoading, dealRotor } = useOutletContext<DealRotorEditDetailedTabsOutletContext>();

  const isDealRotorAttachmentsEditDisabled =
    !isAllowedDealRotorAttachmentWrite ||
    (dealRotor ? dealRotor.status === DealRotorStatus.Ceased || dealRotor.isAgreementTerminated === true : true);

  const searchFormMethods = useForm<FormSearch>({ resolver: yupResolver(formSchema) });
  const { register, control, formState, handleSubmit, reset, setError, clearErrors } = useForm<FormData>({
    defaultValues: {
      type: DealRotorAttachmentType.Note,
      description: '',
    },
    resolver: yupResolver(formSchema),
  });
  const typeField = useWatch({ control, name: 'type' });
  const searchField = useWatch({ control: searchFormMethods.control, name: 'search' });
  const search = searchField?.trim() || undefined;

  const { pagination, setPagination } = useTableChangeParams({});
  const { serverErrorMessages, setServerErrorMessages, handleErrors } = useFormErrors<FormData>(setError, clearErrors);

  const {
    isLoading,
    data: attachments,
    error: attachmentError,
    refetch: attachmentsRefetch,
  } = useQuery({
    queryKey: ['getAttachments', search, pagination],
    async queryFn({ signal }) {
      const config = { signal };
      const params = { ...new RequestSearchParamsAdapter({ search, pagination, sorting: [] }) };
      const res = await getAttachments(dealRotorId, params, config);
      return res.data;
    },
    initialData: {
      total: 0,
      items: [],
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  const downloadAttachmentFile = useCallback(
    async (dealRotorFileId: string) => {
      handleErrors();
      try {
        const res = await downloadAttachment(dealRotorFileId);
        Download.fileFromRes(res, '');
      } catch (error) {
        const serverErrors = new ServerErrorAdapter(error as ServerError);
        setServerErrorMessages(serverErrors.combine());
      }
    },
    [handleErrors, setServerErrorMessages],
  );

  const isShowPagination = useMemo(() => attachments.total > 10, [attachments.total]);

  const { data, pageCount, total } = useMemo(
    () => ({
      data: attachments?.items ?? [],
      pageCount: attachments?.total ? Math.ceil(attachments.total / pagination.pageSize) : 1,
      total: attachments?.total ?? 0,
    }),
    [attachments, pagination.pageSize],
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor('dealRotorFileName', {
        id: 'dealRotorFileName',
        header: 'File name',
        enableSorting: false,
        cell: ({ getValue, row }) => {
          const dealRotorFileId = row.original.dealRotorFileId;
          const fileName = row.original?.dealRotorFileName;
          const fileExt = fileName ? fileName.split('.').at(-1) : null;
          return !dealRotorFileId || !fileExt ? (
            <NoteFileTypeIcon />
          ) : (
            <button className="btn btn-link px-0" type="button" onClick={() => downloadAttachmentFile(dealRotorFileId)}>
              <div className="text-nowrap">
                <FileTypeIcon className="me-2" ext={fileExt} />
                <OverlayTrigger overlay={<Tooltip>{getValue()}</Tooltip>}>
                  <span className={classNames(styles.AttachmentFileName, 'd-inline-block text-truncate align-middle')}>
                    {getValue()}
                  </span>
                </OverlayTrigger>
              </div>
            </button>
          );
        },
      }),

      columnHelper.accessor('description', {
        id: 'description',
        header: 'Description',
        enableSorting: false,
        cell: ({ getValue }) => (
          <OverlayTrigger overlay={<Tooltip>{getValue()}</Tooltip>}>
            <span className={classNames(styles.Description, 'd-inline-block text-truncate')}>{getValue()}</span>
          </OverlayTrigger>
        ),
      }),
      columnHelper.accessor('createdByUserFullName', {
        id: 'createdByUserFullName',
        header: 'User name',
        enableSorting: false,
        cell: ({ getValue, row }) =>
          isAllowedUserReadContacts ? (
            <Link
              to={generatePath(RoutePath.userContactById, { userId: row.original.createdByUserId })}
              className="text-nowrap fw-medium"
            >
              {getValue()}
            </Link>
          ) : (
            <span className="text-nowrap fw-medium">{getValue()}</span>
          ),
      }),
      columnHelper.accessor('createdDate', {
        id: 'createDate',
        header: 'Create Date',
        enableSorting: false,
        cell: ({ getValue }) => <span>{Converter.getFormattedDate(getValue())}</span>,
      }),
    ],
    [isAllowedUserReadContacts, downloadAttachmentFile],
  );

  const onSubmit = handleSubmit(async (formValues) => {
    handleErrors();
    const { type, file, description } = formValues;

    const formData = new FormData();
    formData.append('type', type);
    formData.append('description', description);
    if (typeField === DealRotorAttachmentType.Attachment) {
      formData.append('file', file[0]);
    }
    try {
      handleErrors();
      await uploadAttachment(dealRotorId, formData);

      await Promise.allSettled([
        attachmentsRefetch(),
        queryClient.invalidateQueries({ queryKey: ['getDealRotorAuditListSearch', dealRotorId] }),
      ]);
      queryClient.removeQueries({ queryKey: ['getDealRotorAuditListAll'] });

      reset();
    } catch (error) {
      const serverErrors = new ServerErrorAdapter(error as ServerError);
      setServerErrorMessages(serverErrors.combine());
    }
  });

  useEffect(() => {
    const error = new ServerErrorAdapter(attachmentError as ServerError).combine();
    setServerErrorMessages(error);
  }, [attachmentError, setServerErrorMessages]);

  useEffect(() => {
    clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeField]);

  return (
    <div>
      <form className="position-relative mb-4" name="attachmentsForm" onSubmit={onSubmit} noValidate>
        <div className="row gx-3">
          <Field
            className="col-md-4 mb-2"
            inputGroupClassName="d-flex align-items-center h-44"
            formCheckClassName="form-check-inline"
            field="input"
            type="radio"
            label="Attachment type"
            autoComplete="off"
            register={register('type')}
            control={control}
            options={dealRotorAttachmentOptions}
            formSchema={formSchema}
            errors={formState.errors}
            disabled={isDealRotorLoading || isDealRotorAttachmentsEditDisabled}
          />
        </div>
        <div className="row gx-3 mb-3">
          <div></div>
          <Field
            className={classNames('col-md-6 col-lg-6 col-xl-6 col-xxl-4 mb-2', {
              'visually-hidden': typeField === DealRotorAttachmentType.Note,
            })}
            field="input"
            type="file"
            placeholder={`${allowedFileExtensions.join(', ')}, not more than 10 MB`}
            accept={allowedFileExtensions.join(',')}
            autoComplete="off"
            register={register('file')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
            disabled={isDealRotorLoading || isDealRotorAttachmentsEditDisabled}
          />
          <Field
            className="col-md-6 col-lg-6 col-xl-6 col-xxl-4 mb-2"
            field="textarea"
            type="text"
            rows={5}
            label={classNames(
              { Description: typeField === DealRotorAttachmentType.Attachment },
              { Note: typeField === DealRotorAttachmentType.Note },
            )}
            placeholder={classNames(
              { 'Enter description': typeField === DealRotorAttachmentType.Attachment },
              { 'Type note': typeField === DealRotorAttachmentType.Note },
            )}
            autoComplete="off"
            register={register('description')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
            disabled={isDealRotorLoading || isDealRotorAttachmentsEditDisabled}
          />
        </div>
        <div className="hstack">
          <LoadingButton
            className="btn btn-primary ms-auto"
            type="submit"
            disabled={isDealRotorLoading || isDealRotorAttachmentsEditDisabled}
            isLoading={formState.isSubmitting}
          >
            {typeField === DealRotorAttachmentType.Attachment ? 'Upload attachment' : 'Add note'}
          </LoadingButton>
        </div>
      </form>
      <div>
        <Field
          className="col-md-4 mb-4"
          field="input"
          type="search"
          placeholder="Search"
          autoComplete="off"
          register={searchFormMethods.register('search')}
          control={searchFormMethods.control}
          formSchema={formSearchSchema}
          errors={searchFormMethods.formState.errors}
        />
        <Table
          className="mb-0"
          wrapperClassName="border rounded mb-3"
          data={data}
          total={total}
          columns={columns}
          pagination={!isShowPagination ? { pageIndex: 0, pageSize: attachments.total } : pagination}
          setPagination={setPagination}
          isLoading={isLoading}
          pageCount={pageCount}
          isShowPagination={isShowPagination}
        />
      </div>
      <ServerErrorMessages messages={serverErrorMessages} />
    </div>
  );
};

export default AttachmentsTab;
