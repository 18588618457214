import classNames from 'classnames';
import { useState, useEffect, useCallback, useMemo } from 'react';

import { getSubDomainHostUrl } from 'src/utils/domain';
import AuthCard from 'src/pages/auth/components/AuthCard';
import useUserTenantSettingList from 'src/hooks/useUserTenantSettingList';
import ServerErrorAdapter from 'src/utils/ServerErrorAdapter';
import ServerErrorMessages from 'src/components/ServerErrorMessages';
import SpinnerLoader from 'src/components/SpinnerLoader';
import TenantLogo from 'src/pages/auth/components/TenantLogo';

import { ServerFormErrors, ServerError } from 'src/types';

import styles from 'src/pages/auth/TenantSelect.module.scss';

const TenantSelect = () => {
  const [activeTenant, setActiveTenant] = useState<string>('');
  const { tenantSettingList = [], tenantSettingListError, tenantSettingListIsLoading } = useUserTenantSettingList();

  const redirectToTenant = useCallback(() => {
    const redirectTo = getSubDomainHostUrl(activeTenant);
    return (window.location.href = redirectTo);
  }, [activeTenant]);

  const errorMessages = useMemo<ServerFormErrors>(
    () => new ServerErrorAdapter(tenantSettingListError as ServerError).combine(),
    [tenantSettingListError],
  );

  useEffect(() => {
    if (!tenantSettingList) return;
    if (tenantSettingList.length === 1) {
      redirectToTenant();
    }
  }, [activeTenant, redirectToTenant, tenantSettingList]);

  useEffect(() => {
    if (!tenantSettingList) return;
    if (tenantSettingList.length > 0) {
      setActiveTenant(tenantSettingList[0].domain);
    }
  }, [tenantSettingList]);

  return (
    <AuthCard title="Your available businesses">
      {tenantSettingListIsLoading || tenantSettingList.length === 1 ? (
        <SpinnerLoader />
      ) : (
        <ul
          className={classNames(
            styles.TenantList,
            'd-flex flex-wrap border rounded overflow-auto custom-scroll mb-5 p-2 w-100',
          )}
        >
          {tenantSettingList.map((tenant) => (
            <li
              className={classNames(styles.TenantListItem, 'border m-1 w-100', {
                active: activeTenant === tenant.domain,
              })}
              key={tenant.id}
            >
              <button
                className="hstack gap-3 list-group-item p-2 w-100 h-100"
                type="button"
                onClick={() => setActiveTenant(tenant.domain)}
              >
                <div className="tenant-logo hstack justify-content-center align-self-center border rounded-1 p-1 bg-white w-100">
                  <TenantLogo logo={tenant.logoUrl} name={tenant.name} />
                </div>
                <div className="title text-start">{tenant.name}</div>
              </button>
            </li>
          ))}
        </ul>
      )}
      <div className="text-center mb-4">
        <button
          className="btn btn-primary"
          type="button"
          onClick={redirectToTenant}
          disabled={tenantSettingListIsLoading}
        >
          Continue
        </button>
      </div>
      <ServerErrorMessages className="mt-3" messages={errorMessages} />
    </AuthCard>
  );
};

export default TenantSelect;
