import { useState } from 'react';
import { Modal } from 'react-bootstrap';

import { useForm } from 'react-hook-form';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Field from 'src/components/forms/Field';
import LoadingButton from 'src/components/buttons/LoadingButton';
import ServerErrorMessages from 'src/components/ServerErrorMessages';

import { useFormErrors } from 'src/hooks/FormHelpers';

import { RejectAgreementModel, ServerError, YupFormShape } from 'src/types';

type Props = {
  isAllowedDealRotorAgreementSign: boolean;
  setSignatureFilename: React.Dispatch<React.SetStateAction<string>>;
  rejectAgreementRequest: (data: RejectAgreementModel) => Promise<any>;
  refetchAgreementDetailsForSign: () => Promise<any>;
  refetchDownloadLatestAgreementDocument: () => Promise<any>;
};

type FormData = RejectAgreementModel;
type FormShape = YupFormShape<FormData>;
const formSchema = object().shape<FormShape>({
  comment: string().label('Reason').trim().max(210).required(),
});

function RejectDocument({
  isAllowedDealRotorAgreementSign,
  setSignatureFilename,
  rejectAgreementRequest,
  refetchAgreementDetailsForSign,
  refetchDownloadLatestAgreementDocument,
}: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const { register, control, handleSubmit, formState, setError, clearErrors, reset } = useForm<FormData>({
    resolver: yupResolver(formSchema),
  });
  const { serverErrorMessages, handleErrors } = useFormErrors<FormData>(setError, clearErrors);

  const onSubmit = handleSubmit(async (formData) => {
    try {
      handleErrors();

      await rejectAgreementRequest(formData);

      await Promise.allSettled([refetchAgreementDetailsForSign(), refetchDownloadLatestAgreementDocument()]);

      setSignatureFilename('');

      closeModal();
    } catch (e) {
      handleErrors(e as ServerError);
    }
  });

  const onClick = () => {
    reset();
    handleErrors();
    setIsModalOpen(true);
  };

  return (
    <>
      <button className="btn btn-link px-0" type="button" onClick={onClick} disabled={!isAllowedDealRotorAgreementSign}>
        Reject document
      </button>

      <Modal show={isModalOpen} onHide={closeModal} centered>
        <Modal.Header onHide={closeModal} closeButton>
          <Modal.Title className="h5">Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            By proceeding, you will reject the document. Please give the reason below why you are rejecting the
            document.
          </p>
          <form name="rejectDocumentForm" onSubmit={onSubmit} noValidate>
            <Field
              className="mb-3"
              field="textarea"
              autoComplete="off"
              placeholder="Reason for rejecting the document"
              max={210}
              register={register('comment')}
              control={control}
              formSchema={formSchema}
              errors={formState.errors}
            />

            <div className="d-flex flex-wrap justify-content-end gap-3">
              <button type="button" className="btn btn-link" onClick={closeModal}>
                Cancel
              </button>
              <LoadingButton type="submit" className="btn btn-primary" isLoading={formState.isSubmitting}>
                Reject
              </LoadingButton>
              <ServerErrorMessages className="w-100 mb-0" messages={serverErrorMessages} />
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default RejectDocument;
