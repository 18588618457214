import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/router';

import AuthCard from './components/AuthCard';
import SignUpForm from './components/SignUpForm';

import useUrlQuery from 'src/hooks/useUrlQuery';

function SignUp() {
  const navigate = useNavigate();
  const urlQuery = useUrlQuery();
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');

  useEffect(() => {
    const rawToken = urlQuery.get('token');
    const decodedToken = decodeRawToken(rawToken);
    if (decodedToken) {
      setEmail(decodedToken.email);
      setToken(decodedToken.token);
    } else {
      navigate(RoutePath.login, { replace: true });
    }
  }, [navigate, urlQuery]);

  return (
    <AuthCard title="Sign up">
      <p>Please set your password to sign up</p>
      <SignUpForm email={email} token={token} />
    </AuthCard>
  );
}

type DecodedToken = {
  email: string;
  token: string;
};

function decodeRawToken(token: string | null | undefined): DecodedToken | null {
  if (!token) return null;
  try {
    const decodedToken: unknown = JSON.parse(window.atob(token));
    // Validate token format
    if (
      typeof decodedToken === 'object' &&
      decodedToken !== null &&
      'Email' in decodedToken &&
      'resetPasswordToken' in decodedToken &&
      typeof decodedToken.Email === 'string' &&
      typeof decodedToken.resetPasswordToken === 'string'
    ) {
      // Destructuring to satisfy TypeScript
      const { Email: email, resetPasswordToken } = decodedToken;
      const token = window.atob(resetPasswordToken);
      return { email, token };
    }
  } catch (e) {}
  return null;
}

export default SignUp;
