import classNames from 'classnames';
import { useMemo } from 'react';

import Converter from 'src/utils/Converter';

import styles from './Avatar.module.scss';

type Props = {
  src: string | null | undefined;
  alt: string;
  initials?: string | null;
  className?: string;
  size?: number;
  theme?: string | null;
};

const Avatar = ({ src, alt, initials, className, size = 32, theme }: Props) => {
  const displayInitials = useMemo(() => initials || Converter.getInitials(alt), [initials, alt]);

  const style = useMemo(() => ({ width: size, height: size, fontSize: size / 2, lineHeight: 2 }), [size]);
  const themeColor = theme ? { backgroundColor: theme, color: theme === '#798289' ? 'white' : '#161616' } : undefined;

  return (
    <figure className={classNames(styles.Avatar, className, 'rounded-circle mb-0')} style={style}>
      {src ? (
        <img className={styles.Image} src={src} alt={alt} />
      ) : (
        <span className={classNames(styles.Image)} style={themeColor}>
          {displayInitials}
        </span>
      )}
    </figure>
  );
};

export default Avatar;
