import { useState } from 'react';
import { Modal } from 'react-bootstrap';

import LoadingButton from 'src/components/buttons/LoadingButton';
import ServerErrorMessages from 'src/components/ServerErrorMessages';

import { resetUserMFAById } from 'src/api/base/user';

import ServerErrorAdapter from 'src/utils/ServerErrorAdapter';

import { ServerError, ServerFormErrors, UUIDString } from 'src/types';

type ModalProps = {
  userId: UUIDString;
  show: boolean;
  closeModal: () => void;
};

function ResetMFAModal({ userId, show, closeModal }: ModalProps) {
  const [serverErrorMessages, setServerErrorMessages] = useState<ServerFormErrors>([]);
  const onConfirm = async () => {
    try {
      await resetUserMFAById(userId);
      closeModal();
    } catch (e) {
      const serverErrors = new ServerErrorAdapter(e as ServerError);
      setServerErrorMessages(serverErrors.combine());
    }
  };

  return (
    <Modal show={show} onHide={closeModal} centered>
      <Modal.Header onHide={closeModal} closeButton>
        <Modal.Title className="h5">Reset Multi-Factor Authentication (MFA)</Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-0">
        <p>
          By resetting MFA for this user, they will no longer be able to use verification codes from their current MFA
          application.
        </p>
        <p>
          This user will receive an email informing them that MFA has been reset on their account. This email will
          contain instructions for how they re-enable MFA.
        </p>
        <p>
          <strong>Are you sure you want to reset MFA for this user?</strong>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-link" onClick={closeModal}>
          Cancel
        </button>
        <LoadingButton type="button" className="btn btn-danger" onClick={onConfirm}>
          Yes, reset MFA
        </LoadingButton>
        <ServerErrorMessages className="w-100" messages={serverErrorMessages} />
      </Modal.Footer>
    </Modal>
  );
}

export default ResetMFAModal;
