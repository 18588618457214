import { Suspense, useState, useLayoutEffect } from 'react';
import { Router, Routes, Route, Navigate } from 'react-router-dom';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ErrorBoundary } from '@sentry/react';
import { RoutePath, GuardedRoute, history } from 'src/router';
import { PersistGate } from 'redux-persist/integration/react';

import store, { useSelector, persistor } from 'src/store';
import { isLoggedInSelector, authUserTenantSubDomainListSelector } from 'src/store/selectors/authSelector';

import TenantPublicSettings from 'src/hocs/TenantPublicSettings';
import Auth from 'src/hocs/Auth';

import Layout from 'src/components/layout/Layout';
import AuthLayout from 'src/components/layout/AuthLayout';
import AnonymousLayout from 'src/components/layout/AnonymousLayout';
import SpinnerLoader from 'src/components/SpinnerLoader';
import ErrorFallback from 'src/components/layout/ErrorFallback';
import TenantSettings from 'src/components/TenantSettings';
import ActivityTracker from 'src/components/ActivityTracker';
import CheckNewVersion from 'src/components/CheckNewVersion';

import AuthRoutes from 'src/pages/auth';
import DashboardPage from 'src/pages/dashboard/Dashboard';
import DocumentViewerPage from 'src/pages/document/DocumentViewer';
import DealRotorRoutes from 'src/pages/deal-rotor';
import DealParticipantRoutes from 'src/pages/deal-participant';
import PartyBManagementRoutes from 'src/pages/party-b-management';
import ConfigurationRoutes from 'src/pages/configuration';
import UserProfileRoutes from 'src/pages/profile';
import ContactDetailsPage from 'src/pages/contact/ContactDetails';
import NotarisePrivateRoutes from 'src/pages/notarise/NotarisePrivate';
import NotarisePublicRoutes from 'src/pages/notarise/NotarisePublic';
import AnalyticsRoutes from 'src/pages/analytics';
import GlobalChatConsolePage from 'src/pages/global-console/GlobalChatConsole';

import { envTenant } from 'src/utils/domain';

import { PermissionNames, RoleName } from 'src/types';

export const queryClient = new QueryClient();

function RootRoutes() {
  const isLoggedIn = useSelector(isLoggedInSelector);
  const userTenants = useSelector(authUserTenantSubDomainListSelector) || [];
  const isCorrectTenant = userTenants.includes(envTenant);

  return (
    <Suspense fallback={<SpinnerLoader />}>
      <Routes>
        <Route
          index
          element={
            <Navigate
              to={isLoggedIn ? (isCorrectTenant ? RoutePath.dashboard : RoutePath.tenantSelect) : RoutePath.login}
              replace={true}
            />
          }
        />

        {/* Auth */}
        <Route
          path={`${RoutePath.authRoot}/*`}
          element={
            <AuthLayout>
              <AuthRoutes />
            </AuthLayout>
          }
        />

        <Route
          path={`${RoutePath.notarisePublic}/*`}
          element={
            <AnonymousLayout>
              <NotarisePublicRoutes />
            </AnonymousLayout>
          }
        />

        <Route
          path={RoutePath.documentViewer}
          element={
            <GuardedRoute meta={{ auth: true, allowedPermissions: [PermissionNames.DealRotorRead] }}>
              <DocumentViewerPage />
            </GuardedRoute>
          }
        />

        <Route path={RoutePath.documentViewerPublic} element={<DocumentViewerPage />} />

        {/* With Layout */}
        <Route
          path="*"
          element={
            <Layout>
              <Routes>
                <Route
                  path={RoutePath.dashboard}
                  element={
                    <GuardedRoute meta={{ auth: true }}>
                      <DashboardPage />
                    </GuardedRoute>
                  }
                />

                <Route
                  path={`${RoutePath.dealRotorRoot}/*`}
                  element={
                    <GuardedRoute
                      meta={{
                        auth: true,
                        allowedPermissions: [
                          PermissionNames.DealRotorCreate,
                          PermissionNames.DealRotorRead,
                          PermissionNames.DealRotorAuditRead,
                          PermissionNames.DealRotorNotificationsRead,
                          PermissionNames.DealRotorDetailsWrite,
                          PermissionNames.DealRotorTypeUpdate,
                          PermissionNames.DealRotorDeactivate,
                          PermissionNames.AgreementAttributesRead,
                          PermissionNames.AgreementAttributesWrite,
                          PermissionNames.DealRotorAgreementWrite,
                          PermissionNames.DealRotorAgreementSign,
                          PermissionNames.DealRotorAttachmentWrite,
                          PermissionNames.DealRotorTransferOwner,
                          PermissionNames.DealRotorTransferOperationOwner,
                          PermissionNames.DealRotorUpdateManagers,
                          PermissionNames.DealRotorUpdateOperationManagers,
                          PermissionNames.DealRotorUpdateSignatories,
                        ],
                      }}
                    >
                      <DealRotorRoutes />
                    </GuardedRoute>
                  }
                />

                <Route
                  path={`${RoutePath.globalAIConsole}/*`}
                  element={
                    <GuardedRoute meta={{ auth: true, allowedRoles: [RoleName.GlobalDealRotorManager] }}>
                      <GlobalChatConsolePage />
                    </GuardedRoute>
                  }
                ></Route>

                <Route
                  path={`${RoutePath.dealParticipantRoot}/*`}
                  element={
                    <GuardedRoute meta={{ auth: true, allowedPermissions: [PermissionNames.DealRotorRead] }}>
                      <DealParticipantRoutes />
                    </GuardedRoute>
                  }
                />

                <Route
                  path={`${RoutePath.partyBManagementRoot}/*`}
                  element={
                    <GuardedRoute
                      meta={{
                        auth: true,
                        allowedPermissions: [PermissionNames.OrganisationRead, PermissionNames.OrganisationWrite],
                      }}
                    >
                      <PartyBManagementRoutes />
                    </GuardedRoute>
                  }
                />

                <Route
                  path={`${RoutePath.analyticsRoot}/*`}
                  element={
                    <GuardedRoute meta={{ auth: true }}>
                      <AnalyticsRoutes />
                    </GuardedRoute>
                  }
                />

                <Route
                  path={`${RoutePath.configurationRoot}/*`}
                  element={
                    <GuardedRoute
                      meta={{
                        auth: true,
                        allowedPermissions: [
                          PermissionNames.UserRead,
                          PermissionNames.UserWrite,
                          PermissionNames.RoleRead,
                          PermissionNames.RoleWrite,
                          PermissionNames.NotificationWrite,
                          PermissionNames.TemplateRead,
                          PermissionNames.TemplateWrite,
                        ],
                      }}
                    >
                      <ConfigurationRoutes />
                    </GuardedRoute>
                  }
                />

                <Route
                  path={`${RoutePath.userProfileRoot}/*`}
                  element={
                    <GuardedRoute meta={{ auth: true }}>
                      <UserProfileRoutes />
                    </GuardedRoute>
                  }
                />

                <Route
                  path={RoutePath.userContactById}
                  element={
                    <GuardedRoute
                      meta={{
                        auth: true,
                        allowedPermissions: [PermissionNames.UserReadContacts],
                      }}
                    >
                      <ContactDetailsPage />
                    </GuardedRoute>
                  }
                />

                <Route
                  path={`${RoutePath.notarisePrivate}/*`}
                  element={
                    <GuardedRoute meta={{ auth: true, allowedPermissions: [PermissionNames.DealRotorAgreementWrite] }}>
                      <NotarisePrivateRoutes />
                    </GuardedRoute>
                  }
                />

                <Route path="*" element={<Navigate to={RoutePath.root} />} />
              </Routes>
            </Layout>
          }
        />
      </Routes>
    </Suspense>
  );
}

function RootRouter() {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });
  useLayoutEffect(() => history.listen(setState), []);
  return (
    <ErrorBoundary fallback={<ErrorFallback />}>
      <Router location={state.location} navigationType={state.action} navigator={history}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <QueryClientProvider client={queryClient}>
              <TenantPublicSettings>
                <Auth>
                  <TenantSettings />
                  <RootRoutes />
                  <ActivityTracker />
                  <CheckNewVersion />
                </Auth>
              </TenantPublicSettings>
            </QueryClientProvider>
          </PersistGate>
        </Provider>
      </Router>
    </ErrorBoundary>
  );
}

export default RootRouter;
