import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

import { object, string } from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Field from 'src/components/forms/Field';
import ServerErrorAdapter from 'src/utils/ServerErrorAdapter';
import ServerErrorMessages from 'src/components/ServerErrorMessages';

import { useFormErrors } from 'src/hooks/FormHelpers';

import { getUserParticipantById } from 'src/api/base/user';

import { YupFormShape, ServerError } from 'src/types';

type Props = {
  userId: string;
};

type FormData = {
  firstName: string;
  lastName: string;
  email: string;
};
type FormShape = YupFormShape<FormData>;
const formSchema = object().shape<FormShape>({
  firstName: string().label('First name').trim(),
  lastName: string().label('Last name').trim(),
  email: string().label('Email').trim().email(),
});

function DealParticipantDetailsFrom({ userId }: Props) {
  const { register, control, formState, setValue, setError, clearErrors } = useForm<FormData>({
    resolver: yupResolver(formSchema),
  });
  const { serverErrorMessages, setServerErrorMessages } = useFormErrors<FormData>(setError, clearErrors);

  const { data: userParticipant, error: userParticipantError } = useQuery({
    enabled: Boolean(userId),
    queryKey: ['getUserParticipantById', userId],
    async queryFn({ signal }) {
      const config = { signal };
      const res = await getUserParticipantById(userId, config);
      return res.data;
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (!userParticipant) return;
    setValue('firstName', userParticipant.firstName || '');
    setValue('lastName', userParticipant.lastName || '');
    setValue('email', userParticipant.email || '');
  }, [userParticipant, setValue]);

  useEffect(() => {
    const userParticipantServerError = new ServerErrorAdapter(userParticipantError as ServerError).combine();
    setServerErrorMessages(userParticipantServerError);
  }, [setServerErrorMessages, userParticipantError]);

  return (
    <form className="bg-white border rounded p-3 mb-5" name="dealParticipantDetailsForm" noValidate>
      <div className="row g-3">
        <div className="col-sm-12 col-md">
          <div className="row g-3">
            <Field
              className="col-md-6 col-lg-4 col-xl-3 col-xxl-2"
              field="input"
              type="text"
              label="First name"
              placeholder="Enter first name"
              autoComplete="off"
              register={register('firstName')}
              control={control}
              formSchema={formSchema}
              errors={formState.errors}
              disabled
            />
            <Field
              className="col-md-6 col-lg-4 col-xl-3 col-xxl-2"
              field="input"
              type="text"
              label="Last name"
              placeholder="Enter last name"
              autoComplete="off"
              register={register('lastName')}
              control={control}
              formSchema={formSchema}
              errors={formState.errors}
              disabled
            />
            <Field
              className="col-md-6 col-lg-4 col-xl-3 col-xxl-2"
              field="input"
              type="email"
              label="Email"
              placeholder="Enter email"
              autoComplete="off"
              register={register('email')}
              control={control}
              formSchema={formSchema}
              errors={formState.errors}
              disabled
            />
          </div>
        </div>
      </div>
      <ServerErrorMessages className="mt-3" messages={serverErrorMessages} />
    </form>
  );
}

export default DealParticipantDetailsFrom;
