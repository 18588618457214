import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { useForm } from 'react-hook-form';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Field from 'src/components/forms/Field';
import LoadingButton from 'src/components/buttons/LoadingButton';
import ServerErrorMessages from 'src/components/ServerErrorMessages';

import { loginUserRequest } from 'src/hocs/Auth';
import { useFormErrors } from 'src/hooks/FormHelpers';
import useMFA from '../hooks/useMFA';

import { LoginUserModel, YupFormShape, ServerError } from 'src/types';

type FormData = Omit<LoginUserModel, 'clientType'>;
type FormShape = YupFormShape<FormData>;

function LoginForm() {
  const { isCodeStage, setIsCodeStage, handleLoginStatus } = useMFA();

  const formSchema = useMemo(
    () =>
      object().shape<FormShape>({
        email: string().label('Email').nullable().trim().lowercase().required().email(),
        password: string().label('Password').nullable().trim().required(),
        code: isCodeStage ? string().label('MFA code').trim().required().nDigitCode() : string().label('Code').trim(),
      }),
    [isCodeStage],
  );
  const { register, control, handleSubmit, formState, setError, clearErrors } = useForm<FormData>({
    resolver: yupResolver(formSchema),
  });
  const { serverErrorMessages, handleErrors } = useFormErrors<FormData>(setError, clearErrors);

  const onSubmit = handleSubmit(async ({ email, password, code }) => {
    try {
      handleErrors();
      const { status, secret } = await loginUserRequest(email, password, code || null);
      handleLoginStatus({ status, secret, email, password });
    } catch (e) {
      handleErrors(e as ServerError);
    }
  });

  return (
    <form name="loginForm" onSubmit={onSubmit} noValidate>
      <div className="mb-3">
        <Field
          label="Email address"
          field="input"
          type="email"
          placeholder="Enter your email"
          autoComplete="email"
          register={register('email')}
          control={control}
          formSchema={formSchema}
          errors={formState.errors}
          disabled={isCodeStage}
          autoFocus
        />
      </div>
      <div className="mb-3">
        <Field
          label="Password"
          field="input"
          type="password"
          placeholder="Enter your password"
          autoComplete="current-password"
          register={register('password')}
          control={control}
          formSchema={formSchema}
          errors={formState.errors}
          disabled={isCodeStage}
        />
      </div>

      {isCodeStage && (
        <div className="mb-3">
          <Field
            label="MFA code"
            field="input"
            type="text"
            inputMode="numeric"
            placeholder="Enter MFA code"
            autoComplete="off"
            register={register('code')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
          />
        </div>
      )}

      <div className="text-center mb-4">
        {isCodeStage ? (
          <button
            type="button"
            className="btn btn-link fs-14 lh-base p-0 border-0"
            onClick={() => setIsCodeStage(false)}
          >
            Use another account
          </button>
        ) : (
          <Link className="btn btn-link fs-14 lh-base p-0 border-0" to={RoutePath.passwordForgot}>
            Forgot password?
          </Link>
        )}
      </div>

      <div className="text-center mb-3">
        <LoadingButton type="submit" className="btn btn-primary" isLoading={formState.isSubmitting}>
          Sign In
        </LoadingButton>
      </div>

      <ServerErrorMessages messages={serverErrorMessages} />
    </form>
  );
}

export default LoginForm;
