import classNames from 'classnames';
import { useState, useMemo, useEffect, useCallback } from 'react';
import { useQuery, useQueryClient, keepPreviousData } from '@tanstack/react-query';
import { Params, useParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { Dropdown } from 'react-bootstrap';

import { useSelector } from 'src/store';
import { tenantTimezoneInBracketsSelector } from 'src/store/selectors/tenantSelector';

import { useForm } from 'react-hook-form';
import { object, string, date, array } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Field from 'src/components/forms/Field';
import Avatar from 'src/components/Avatar';
import LoadingButton from 'src/components/buttons/LoadingButton';
import ServerErrorMessages from 'src/components/ServerErrorMessages';
import { DotsVerticalIcon } from 'src/components/icons';

import useTenantTimezone from 'src/hooks/useTenantTimezone';
import { useFormErrors } from 'src/hooks/FormHelpers';

import Converter from 'src/utils/Converter';
import LocalDate from 'src/utils/LocalDate';
import ServerErrorAdapter from 'src/utils/ServerErrorAdapter';

import {
  cancelSigningAgreement,
  resendSigningInvitationsAgreement,
  resetAgreementDetails,
  discardAddendum,
  getUsersAbleToSign,
} from 'src/api/base/agreement';
import {
  scheduleAgreementTermination,
  editAgreementTermination,
  cancelAgreementTermination,
} from 'src/api/base/dealRotor';

import { YupFormShape, SelectOption, ServerError, ServerFormErrors, UUIDString, DateTimeISOString } from 'src/types';

import styles from './AgreementDetails.action-menu.module.scss';

type Props = {
  agreementId: UUIDString;
  agreementEndDate: string | null;
  agreementTerminationDate: string | null;
  isCancelSigningAllowed: boolean;
  isResendInviteAllowed: boolean;
  isResetFieldsAllowed: boolean;
  isDiscardAllowed: boolean;
  isTerminateAllowed: boolean;
  isTerminateEditCancelAllowed: boolean;
};

type DefaultModalProps = {
  agreementId: string;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
type CancelSigningModalProps = DefaultModalProps;
type ResendInviteModalProps = DefaultModalProps;
type ResetFieldsModalProps = DefaultModalProps;
type DiscardModalProps = DefaultModalProps;
type TerminateModalProps = Pick<DefaultModalProps, 'isModalOpen' | 'setIsModalOpen'> &
  Pick<Props, 'agreementEndDate' | 'agreementTerminationDate'>;
type CancelTerminationModalProps = Pick<DefaultModalProps, 'isModalOpen' | 'setIsModalOpen'>;

function CancelSigningModal({ agreementId, isModalOpen, setIsModalOpen }: CancelSigningModalProps) {
  const queryClient = useQueryClient();
  const { dealRotorId = '' } = useParams<Params<'dealRotorId'>>();

  const [serverErrorMessages, setServerErrorMessages] = useState<ServerFormErrors>([]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onConfirmCancelSigning = async () => {
    try {
      await cancelSigningAgreement(agreementId);

      await Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: ['getDealRotorById', dealRotorId] }),
        queryClient.invalidateQueries({ queryKey: ['getAgreementList', dealRotorId] }),
        queryClient.invalidateQueries({ queryKey: ['getDealRotorAuditListSearch', dealRotorId] }),
      ]);
      queryClient.removeQueries({ queryKey: ['getDealRotorListSearch'] });
      queryClient.removeQueries({ queryKey: ['getDealRotorAuditListAll'] });

      closeModal();
    } catch (e) {
      const serverErrors = new ServerErrorAdapter(e as ServerError);
      setServerErrorMessages(serverErrors.combine());
    }
  };

  return (
    <Modal show={isModalOpen} onHide={closeModal} centered>
      <Modal.Header onHide={closeModal} closeButton>
        <Modal.Title className="h5">Are you sure you want to cancel signing?</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <button type="button" className="btn btn-link" onClick={closeModal}>
          No
        </button>
        <LoadingButton type="button" className="btn btn-primary" onClick={onConfirmCancelSigning}>
          Yes
        </LoadingButton>
        <ServerErrorMessages className="w-100 mt-2 mb-0" messages={serverErrorMessages} />
      </Modal.Footer>
    </Modal>
  );
}

function ResendInviteModal({ agreementId, isModalOpen, setIsModalOpen }: ResendInviteModalProps) {
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const { isLoading: isUsersAbleToSignLoading, data: usersAbleToSign } = useQuery({
    enabled: isModalOpen,
    queryKey: ['getUsersAbleToSign', agreementId],
    async queryFn({ signal }) {
      const config = { signal };
      const res = await getUsersAbleToSign(agreementId, config);
      return res.data;
    },
    initialData: [],
    placeholderData: keepPreviousData,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const userOptions = useMemo<SelectOption[]>(
    () =>
      usersAbleToSign.map(({ id, firstName, lastName, title, logoUrl }) => {
        const fullName = `${firstName} ${lastName}`;
        return {
          label: (
            <div className="hstack gap-2">
              <Avatar src={logoUrl} alt={fullName} />
              <div>
                <div className="fw-medium">{fullName}</div>
                {title && <div className="text-secondary fs-14">{title}</div>}
              </div>
            </div>
          ),
          shortLabel: fullName,
          value: id,
        };
      }),
    [usersAbleToSign],
  );

  type FormData = { userIds: UUIDString[] };
  type FormShape = YupFormShape<FormData>;
  const formSchema = object().shape<FormShape>({
    userIds: array().of(string()).label('Signatories').min(1).required(),
  });
  const { register, control, handleSubmit, formState, setValue, setError, clearErrors } = useForm<FormData>({
    resolver: yupResolver(formSchema),
    defaultValues: { userIds: [] },
  });
  const { serverErrorMessages, handleErrors } = useFormErrors<FormData>(setError, clearErrors);

  useEffect(() => {
    if (!isModalOpen) return;
    const userIds = usersAbleToSign.map(({ id }) => id);
    setValue('userIds', userIds);
  }, [isModalOpen, usersAbleToSign, setValue]);

  const onSubmit = handleSubmit(async (formData) => {
    try {
      handleErrors();
      await resendSigningInvitationsAgreement(agreementId, formData);
      closeModal();
    } catch (e) {
      handleErrors(e as ServerError);
    }
  });

  return (
    <Modal show={isModalOpen} onHide={closeModal} size="lg" centered>
      <Modal.Header onHide={closeModal} closeButton>
        <Modal.Title className="h5">Resend Invite?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form name="resendInviteForm" onSubmit={onSubmit} noValidate>
          <Field
            className="mb-3"
            field="dropdown"
            label="Please choose signatories to resend invite"
            placeholder="Choose signatories"
            autoComplete="off"
            register={register('userIds')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
            options={userOptions}
            dropdownProps={{
              isLoading: isUsersAbleToSignLoading,
              isSearchable: true,
              isClearable: true,
            }}
            multiple
          />

          <div className="d-flex flex-wrap justify-content-end gap-3">
            <button type="button" className="btn btn-link" onClick={closeModal}>
              Cancel
            </button>
            <LoadingButton type="submit" className="btn btn-primary" isLoading={formState.isSubmitting}>
              Resend invite
            </LoadingButton>
            <ServerErrorMessages className="w-100 mb-0" messages={serverErrorMessages} />
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

function ResetFieldsModal({ agreementId, isModalOpen, setIsModalOpen }: ResetFieldsModalProps) {
  const queryClient = useQueryClient();
  const { dealRotorId = '' } = useParams<Params<'dealRotorId'>>();

  const [serverErrorMessages, setServerErrorMessages] = useState<ServerFormErrors>([]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onConfirmResetFields = async () => {
    try {
      await resetAgreementDetails(agreementId);

      await Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: ['getDealRotorById', dealRotorId] }),
        queryClient.invalidateQueries({ queryKey: ['getAgreementList', dealRotorId] }),
        queryClient.invalidateQueries({ queryKey: ['getDealRotorAuditListSearch', dealRotorId] }),
      ]);
      queryClient.removeQueries({ queryKey: ['getDealRotorListSearch'] });
      queryClient.removeQueries({ queryKey: ['getDealRotorAuditListAll'] });

      closeModal();
    } catch (e) {
      const serverErrors = new ServerErrorAdapter(e as ServerError);
      setServerErrorMessages(serverErrors.combine());
    }
  };

  return (
    <Modal show={isModalOpen} onHide={closeModal} centered>
      <Modal.Header onHide={closeModal} closeButton>
        <Modal.Title className="h5">Are you sure you want to reset fields?</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <button type="button" className="btn btn-link" onClick={closeModal}>
          No
        </button>
        <LoadingButton type="button" className="btn btn-primary" onClick={onConfirmResetFields}>
          Yes
        </LoadingButton>
        <ServerErrorMessages className="w-100 mt-2 mb-0" messages={serverErrorMessages} />
      </Modal.Footer>
    </Modal>
  );
}

function DiscardModal({ agreementId, isModalOpen, setIsModalOpen }: DiscardModalProps) {
  const queryClient = useQueryClient();
  const { dealRotorId = '' } = useParams<Params<'dealRotorId'>>();

  const [serverErrorMessages, setServerErrorMessages] = useState<ServerFormErrors>([]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onConfirmDiscard = async () => {
    try {
      await discardAddendum(agreementId);

      await Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: ['getDealRotorById', dealRotorId] }),
        queryClient.invalidateQueries({ queryKey: ['getAgreementList', dealRotorId] }),
        queryClient.invalidateQueries({ queryKey: ['getDealRotorAuditListSearch', dealRotorId] }),
      ]);
      queryClient.removeQueries({ queryKey: ['getDealRotorListSearch'] });
      queryClient.removeQueries({ queryKey: ['getDealRotorAuditListAll'] });

      closeModal();
    } catch (e) {
      const serverErrors = new ServerErrorAdapter(e as ServerError);
      setServerErrorMessages(serverErrors.combine());
    }
  };

  return (
    <Modal show={isModalOpen} onHide={closeModal} centered>
      <Modal.Header onHide={closeModal} closeButton>
        <Modal.Title className="h5">Are you sure you want to discard this draft?</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <button type="button" className="btn btn-link" onClick={closeModal}>
          No
        </button>
        <LoadingButton type="button" className="btn btn-primary" onClick={onConfirmDiscard}>
          Yes
        </LoadingButton>
        <ServerErrorMessages className="w-100 mt-2 mb-0" messages={serverErrorMessages} />
      </Modal.Footer>
    </Modal>
  );
}

function TerminateModal({
  isModalOpen,
  setIsModalOpen,
  agreementEndDate,
  agreementTerminationDate,
}: TerminateModalProps) {
  const queryClient = useQueryClient();
  const { dealRotorId = '' } = useParams<Params<'dealRotorId'>>();
  const tenantTimezoneText = useSelector(tenantTimezoneInBracketsSelector);
  const { tenantTimezoneDateNow } = useTenantTimezone();

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const getMinDate = useCallback(
    () =>
      Converter.roundToNearestMinutes(tenantTimezoneDateNow, {
        nearestTo: 30,
        roundingMethod: 'ceil',
      }),
    [tenantTimezoneDateNow],
  );

  // Using state to update minDate and minTime on modal open
  const [minDate, setMinDate] = useState(getMinDate());
  const maxDate = useMemo(
    () => (agreementEndDate ? new LocalDate(agreementEndDate).getEndDay().value : null),
    [agreementEndDate],
  );

  type FormData = { endDate: DateTimeISOString; terminationDate: DateTimeISOString };
  type FormShape = YupFormShape<FormData>;
  const formSchema = useMemo(
    () =>
      object().shape<FormShape>({
        endDate: date()
          .label('Original end date')
          .nullable()
          .transform((curr, orig) => (orig === '' ? undefined : curr)),
        terminationDate: date()
          .label('New end date')
          .nullable()
          .transform((curr, orig) => (orig === '' ? undefined : curr))
          .min(minDate, ({ label, min }) => {
            const minFormatted = Converter.getFormattedDate(new LocalDate(min).toJSON(), 'dd/MM/yyyy - HH:mm');
            return `${label} field must be ${minFormatted} or later`;
          })
          .max(agreementEndDate || tenantTimezoneDateNow, ({ label, max }) => {
            const maxFormatted = Converter.getFormattedDate(new LocalDate(max).toJSON(), 'dd/MM/yyyy - HH:mm');
            return `${label} field must be before ${maxFormatted}`;
          })
          .required(),
      }),
    [agreementEndDate, tenantTimezoneDateNow, minDate],
  );
  const { register, control, handleSubmit, formState, setValue, setError, clearErrors, watch } = useForm<FormData>({
    resolver: yupResolver(formSchema),
  });
  const { serverErrorMessages, handleErrors } = useFormErrors<FormData>(setError, clearErrors);
  const terminationDate = watch('terminationDate');
  const minTime = useMemo(() => {
    if (!terminationDate) return minDate;
    const minDateStartDay = new LocalDate(minDate).getStartDay().value;
    const terminationDateStartDay = new LocalDate(terminationDate).getStartDay().value;
    const isSelectedMinDay = terminationDateStartDay.getTime() === minDateStartDay.getTime();
    const minTimeResult = new Date(terminationDate);
    minTimeResult.setHours(isSelectedMinDay ? minDate.getHours() : 0);
    minTimeResult.setMinutes(isSelectedMinDay ? minDate.getMinutes() : 0);
    minTimeResult.setSeconds(0);
    minTimeResult.setMilliseconds(0);
    return minTimeResult;
  }, [minDate, terminationDate]);

  useEffect(() => {
    if (!isModalOpen) return;
    setMinDate(getMinDate());
  }, [isModalOpen, getMinDate]);

  useEffect(() => {
    if (!isModalOpen) return;
    setValue('endDate', agreementEndDate || '');
  }, [isModalOpen, agreementEndDate, setValue]);

  useEffect(() => {
    if (!isModalOpen) return;
    setValue('terminationDate', agreementTerminationDate || new LocalDate(minDate).toJSON());
  }, [isModalOpen, agreementTerminationDate, setValue, minDate]);

  const onSubmit = handleSubmit(async (form) => {
    try {
      handleErrors();

      const terminationDate = new LocalDate(form.terminationDate).toJSON();

      if (agreementTerminationDate === null) {
        await scheduleAgreementTermination(dealRotorId, { terminationDate });
      } else {
        await editAgreementTermination(dealRotorId, { terminationDate });
      }

      await Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: ['getDealRotorById', dealRotorId] }),
        queryClient.invalidateQueries({ queryKey: ['getAgreementList', dealRotorId] }),
        queryClient.invalidateQueries({ queryKey: ['getDealRotorAuditListSearch', dealRotorId] }),
      ]);
      queryClient.removeQueries({ queryKey: ['getDealRotorListSearch'] });
      queryClient.removeQueries({ queryKey: ['getDealRotorAuditListAll'] });

      closeModal();
    } catch (e) {
      handleErrors(e as ServerError);
    }
  });

  return (
    <Modal show={isModalOpen} onHide={closeModal} size="lg" centered>
      <Modal.Header onHide={closeModal} closeButton>
        <Modal.Title className="h5">Terminate</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {agreementTerminationDate === null ? (
          <p className="mb-4">Are you sure you want to terminate the agreement?</p>
        ) : (
          <p className="mb-4">Are you sure you want to change the termination date?</p>
        )}

        <form name="terminateForm" onSubmit={onSubmit} noValidate>
          <div className="row g-3 mb-4">
            <Field
              className="col-sm-6"
              field="datepicker"
              label={`Original end date${tenantTimezoneText}`}
              autoComplete="off"
              register={register('endDate')}
              control={control}
              formSchema={formSchema}
              errors={formState.errors}
              datepickerProps={{ showTimeSelect: true }}
              disabled
            />

            <Field
              className="col-sm-6"
              field="datepicker"
              label={`New end date${tenantTimezoneText}`}
              autoComplete="off"
              register={register('terminationDate')}
              control={control}
              formSchema={formSchema}
              errors={formState.errors}
              datepickerProps={{
                minDate,
                minTime,
                maxDate,
                maxTime: new Date(2000, 1, 1, 23, 59),
                showTimeSelect: true,
              }}
            />
          </div>

          <div className="d-flex flex-wrap justify-content-end gap-3">
            <button type="button" className="btn btn-link" onClick={closeModal}>
              Cancel
            </button>
            <LoadingButton type="submit" className="btn btn-primary" isLoading={formState.isSubmitting}>
              Terminate
            </LoadingButton>
            <ServerErrorMessages className="w-100 mb-0" messages={serverErrorMessages} />
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

function CancelTerminationModal({ isModalOpen, setIsModalOpen }: CancelTerminationModalProps) {
  const queryClient = useQueryClient();
  const { dealRotorId = '' } = useParams<Params<'dealRotorId'>>();

  const [serverErrorMessages, setServerErrorMessages] = useState<ServerFormErrors>([]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onConfirmCancelTermination = async () => {
    try {
      await cancelAgreementTermination(dealRotorId);

      await Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: ['getDealRotorById', dealRotorId] }),
        queryClient.invalidateQueries({ queryKey: ['getAgreementList', dealRotorId] }),
        queryClient.invalidateQueries({ queryKey: ['getDealRotorAuditListSearch', dealRotorId] }),
      ]);
      queryClient.removeQueries({ queryKey: ['getDealRotorListSearch'] });
      queryClient.removeQueries({ queryKey: ['getDealRotorAuditListAll'] });

      closeModal();
    } catch (e) {
      const serverErrors = new ServerErrorAdapter(e as ServerError);
      setServerErrorMessages(serverErrors.combine());
    }
  };

  return (
    <Modal show={isModalOpen} onHide={closeModal} size="lg" centered>
      <Modal.Header onHide={closeModal} closeButton>
        <Modal.Title className="h5">Are you sure you want to cancel termination for agreement?</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <button type="button" className="btn btn-link" onClick={closeModal}>
          No
        </button>
        <LoadingButton type="button" className="btn btn-primary" onClick={onConfirmCancelTermination}>
          Yes
        </LoadingButton>
        <ServerErrorMessages className="w-100 mt-2 mb-0" messages={serverErrorMessages} />
      </Modal.Footer>
    </Modal>
  );
}

function AgreementDetailsActionMenu({
  agreementId,
  agreementEndDate,
  agreementTerminationDate,
  isCancelSigningAllowed,
  isResendInviteAllowed,
  isResetFieldsAllowed,
  isDiscardAllowed,
  isTerminateAllowed,
  isTerminateEditCancelAllowed,
}: Props) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isCancelSigningModalOpen, setIsCancelSigningModalOpen] = useState(false);
  const [isResendInviteModalOpen, setIsResendInviteModalOpen] = useState(false);
  const [isResetFieldsModalOpen, setIsResetFieldsModalOpen] = useState(false);
  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState(false);
  const [isTerminateModalOpen, setIsTerminateModalOpen] = useState(false);
  const [isCancelTerminationModalOpen, setIsCancelTerminationModalOpen] = useState(false);

  return (
    <>
      <Dropdown
        className={classNames(styles.Dropdown, 'd-flex justify-content-end pe-3')}
        show={isDropdownOpen}
        onToggle={() => {
          setIsDropdownOpen((s) => !s);
        }}
      >
        <Dropdown.Toggle
          className="fs-14 lh-1 fw-normal rounded-circle p-1"
          variant="light"
          onClick={(e) => {
            e.stopPropagation();
            setIsDropdownOpen((s) => !s);
          }}
        >
          <DotsVerticalIcon />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {isCancelSigningAllowed && (
            <Dropdown.Item
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                setIsCancelSigningModalOpen(true);
              }}
            >
              Cancel signing
            </Dropdown.Item>
          )}
          {isResendInviteAllowed && (
            <Dropdown.Item
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                setIsResendInviteModalOpen(true);
              }}
            >
              Resend invite
            </Dropdown.Item>
          )}
          {isResetFieldsAllowed && (
            <Dropdown.Item
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                setIsResetFieldsModalOpen(true);
              }}
            >
              Reset fields
            </Dropdown.Item>
          )}
          {isDiscardAllowed && (
            <Dropdown.Item
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                setIsDiscardModalOpen(true);
              }}
            >
              Discard addendum
            </Dropdown.Item>
          )}
          {isTerminateAllowed && (
            <Dropdown.Item
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                setIsTerminateModalOpen(true);
              }}
            >
              Terminate
            </Dropdown.Item>
          )}
          {isTerminateEditCancelAllowed && (
            <Dropdown.Item
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                setIsTerminateModalOpen(true);
              }}
            >
              Edit termination
            </Dropdown.Item>
          )}
          {isTerminateEditCancelAllowed && (
            <Dropdown.Item
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                setIsCancelTerminationModalOpen(true);
              }}
            >
              Cancel termination
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>

      {isCancelSigningAllowed && (
        <CancelSigningModal
          agreementId={agreementId}
          isModalOpen={isCancelSigningModalOpen}
          setIsModalOpen={setIsCancelSigningModalOpen}
        />
      )}
      {isResendInviteAllowed && (
        <ResendInviteModal
          agreementId={agreementId}
          isModalOpen={isResendInviteModalOpen}
          setIsModalOpen={setIsResendInviteModalOpen}
        />
      )}
      {isResetFieldsAllowed && (
        <ResetFieldsModal
          agreementId={agreementId}
          isModalOpen={isResetFieldsModalOpen}
          setIsModalOpen={setIsResetFieldsModalOpen}
        />
      )}
      {isDiscardAllowed && (
        <DiscardModal
          agreementId={agreementId}
          isModalOpen={isDiscardModalOpen}
          setIsModalOpen={setIsDiscardModalOpen}
        />
      )}
      {(isTerminateAllowed || isTerminateEditCancelAllowed) && (
        <TerminateModal
          isModalOpen={isTerminateModalOpen}
          setIsModalOpen={setIsTerminateModalOpen}
          agreementEndDate={agreementEndDate}
          agreementTerminationDate={agreementTerminationDate}
        />
      )}
      {isTerminateEditCancelAllowed && (
        <CancelTerminationModal
          isModalOpen={isCancelTerminationModalOpen}
          setIsModalOpen={setIsCancelTerminationModalOpen}
        />
      )}
    </>
  );
}

export default AgreementDetailsActionMenu;
