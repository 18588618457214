import { AxiosRequestConfig } from 'axios';

import { publicBaseApi } from 'src/api';

import { envTenant } from 'src/utils/domain';

import {
  ContactSignAgreementAggregatedDetailsDto,
  SignAgreementModel,
  RejectAgreementModel,
  SignatureType,
} from 'src/types';

const subdomain = envTenant;

export const getAgreementDetailsForSignPublic = (accessToken: string, config: AxiosRequestConfig = {}) =>
  publicBaseApi.get<ContactSignAgreementAggregatedDetailsDto>(
    `/tenants/${subdomain}/auth/${accessToken}/contact-sign/sign-aggregated-details`,
    config,
  );

export const downloadLatestAgreementDocumentPublic = (accessToken: string, config: AxiosRequestConfig = {}) =>
  publicBaseApi.get<Blob>(`/tenants/${subdomain}/auth/${accessToken}/contact-sign/agreement-document`, {
    ...config,
    responseType: 'blob',
  });

export const downloadAgreementDocumentPublic = (
  accessToken: string,
  agreementDocumentId: string,
  config: AxiosRequestConfig = {},
) =>
  publicBaseApi.get<Blob>(
    `/tenants/${subdomain}/auth/${accessToken}/contact-sign/agreement-documents/${agreementDocumentId}`,
    { ...config, responseType: 'blob' },
  );

export const signAgreementPublic = (accessToken: string, data: SignAgreementModel, config: AxiosRequestConfig = {}) => {
  return publicBaseApi.post<void>(`/tenants/${subdomain}/auth/${accessToken}/contact-sign/sign`, data, config);
};

export const downloadSignPreviewAgreementDocumentPublic = (
  accessToken: string,
  data: SignAgreementModel,
  config: AxiosRequestConfig = {},
) =>
  publicBaseApi.post<Blob>(`/tenants/${subdomain}/auth/${accessToken}/contact-sign/sign/preview`, data, {
    ...config,
    responseType: 'blob',
  });

export const rejectAgreementPublic = (
  accessToken: string,
  data: RejectAgreementModel,
  config: AxiosRequestConfig = {},
) => {
  return publicBaseApi.post<void>(`/tenants/${subdomain}/auth/${accessToken}/contact-sign/sign/reject`, data, config);
};

export const downloadUserSignaturePublic = (
  accessToken: string,
  signatureFilename: string,
  config: AxiosRequestConfig = {},
) =>
  publicBaseApi.get<Blob>(`/tenants/${subdomain}/auth/${accessToken}/contact-sign/signatures/${signatureFilename}`, {
    ...config,
    responseType: 'blob',
  });

export const uploadUserSignaturePublic = (
  accessToken: string,
  type: SignatureType,
  data: FormData,
  config: AxiosRequestConfig = {},
) =>
  publicBaseApi.post<string>(`/tenants/${subdomain}/auth/${accessToken}/contact-sign/signatures`, data, {
    ...config,
    params: { type },
  });
