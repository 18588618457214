import classNames from 'classnames';
import { useState, useCallback, useMemo, useContext, PropsWithChildren, Dispatch, SetStateAction } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, generatePath } from 'react-router-dom';
import { Accordion, AccordionContext, useAccordionButton } from 'react-bootstrap';
import { RoutePath } from 'src/router';

import { useSelector } from 'src/store';
import { tenantTimezoneInBracketsSelector } from 'src/store/selectors/tenantSelector';

import FormatDate from 'src/components/FormatDate';
import AgreementDocument from 'src/components/AgreementDocument';
import { InfoIcon, AwaitingSignatureIcon } from 'src/components/icons';

import AgreementDetailsForm from './AgreementDetails.form';
import AgreementDetailsDocumentForm from './AgreementDetails.document.form';
import AgreementDetailsWatermark from './AgreementDetails.watermark';
import AgreementDetailsParticipants from './AgreementDetails.participants';
import AgreementDetailsSignStatus from './AgreementDetails.sign-status';
import AgreementDetailsRevertToDraft from './AgreementDetails.revert-to-draft';
import AgreementDetailsFinalise from './AgreementDetails.finalise';
import AgreementDetailsSignAndFinalise from './AgreementDetails.sign-and-finalise';
import AgreementDetailsActionMenu from './AgreementDetails.action-menu';
import UploadTemplate from 'src/pages/deal-rotor/components/dealRotorEdit/AgreementDetails.upload-template';

import useUserPermission from 'src/hooks/useUserPermission';

import { removeAgreementDocument } from 'src/api/base/agreement';

import {
  DealRotorDto,
  DealRotorStatus,
  AgreementDto,
  AgreementType,
  AgreementStatus,
  AgreementDocumentType,
  AgreementAttributeDto,
  UUIDString,
  PermissionNames,
} from 'src/types';

type Props = {
  dealRotor: DealRotorDto;
  dealRotorAgreement: AgreementDto;
  dealRotorAgreementAttribute: AgreementAttributeDto | null;
  isLastAgreement: boolean;
  setAccordionList: Dispatch<SetStateAction<string[]>>;
};

type ContextAwareToggleProps = {
  eventKey: string;
  setActive: Dispatch<SetStateAction<string[]>>;
};

const AccordionToggle = ({ children, eventKey, setActive }: PropsWithChildren<ContextAwareToggleProps>) => {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, () => {
    setActive((prev) => (prev.includes(eventKey) ? prev.filter((key) => key !== eventKey) : [...prev, eventKey]));
  });

  return (
    <header
      className={classNames('accordion-button', { collapsed: !activeEventKey?.includes(eventKey) })}
      role="button"
      aria-expanded={activeEventKey?.includes(eventKey)}
      onClick={decoratedOnClick}
    >
      {children}
    </header>
  );
};

function AgreementDetails({
  dealRotor,
  dealRotorAgreement,
  dealRotorAgreementAttribute,
  isLastAgreement,
  setAccordionList,
}: Props) {
  const queryClient = useQueryClient();
  const tenantTimezoneText = useSelector(tenantTimezoneInBracketsSelector);

  const [templateUploadAgreementId, setTemplateUploadAgreementId] = useState('');
  const [isShowTemplateUpload, setIsShowTemplateUpload] = useState(false);
  const toggleShowTemplateUpload = useCallback(() => setIsShowTemplateUpload((s) => !s), []);
  const openTemplateUpload = (dealRotorAgreementId: UUIDString) => {
    setTemplateUploadAgreementId(dealRotorAgreementId);
    setIsShowTemplateUpload(true);
  };

  const onRemoveDocument = useCallback(
    async (agreementDocumentId: UUIDString) => {
      try {
        await removeAgreementDocument(dealRotorAgreement.id, agreementDocumentId);

        await Promise.allSettled([
          queryClient.invalidateQueries({ queryKey: ['getAgreementList', dealRotor.id] }),
          queryClient.invalidateQueries({ queryKey: ['getDealRotorAuditListSearch', dealRotor.id] }),
        ]);

        queryClient.removeQueries({ queryKey: ['getDealRotorListSearch'] });
      } catch (e) {}
    },
    [dealRotor.id, dealRotorAgreement.id, queryClient],
  );

  const { hasUserPermission } = useUserPermission();
  const isAllowedDealRotorAgreementWrite = hasUserPermission(PermissionNames.DealRotorAgreementWrite);
  const isAllowedAgreementAttributesRead = hasUserPermission(PermissionNames.AgreementAttributesRead);
  const isAllowedUserReadContacts = hasUserPermission(PermissionNames.UserReadContacts);

  const isAgreementEditDisabled =
    !isAllowedDealRotorAgreementWrite ||
    !dealRotor ||
    dealRotor.status !== DealRotorStatus.Active ||
    dealRotorAgreement.status !== AgreementStatus.Inactive;

  const isAgreementEnded = dealRotorAgreement.status === AgreementStatus.Ended;
  const isAgreementCanFinalise = useMemo<boolean>(() => {
    const isPassesAttributes =
      isAllowedAgreementAttributesRead && dealRotor.isAgreementAttribute
        ? (dealRotorAgreementAttribute &&
            'bove' in dealRotorAgreementAttribute &&
            'currencyCode' in dealRotorAgreementAttribute &&
            'value' in dealRotorAgreementAttribute) ||
          false
        : true;
    const isPassesAgreementForm = dealRotorAgreement.startDate !== null && dealRotorAgreement.endDate !== null;
    const isPassesAgreementDocument = dealRotorAgreement.agreementDocument !== null;
    return isAllowedDealRotorAgreementWrite && isPassesAttributes && isPassesAgreementForm && isPassesAgreementDocument;
  }, [
    isAllowedAgreementAttributesRead,
    isAllowedDealRotorAgreementWrite,
    dealRotor.isAgreementAttribute,
    dealRotorAgreementAttribute,
    dealRotorAgreement.startDate,
    dealRotorAgreement.endDate,
    dealRotorAgreement.agreementDocument,
  ]);

  const isCancelSigningAllowed =
    isAllowedDealRotorAgreementWrite &&
    dealRotor.status === DealRotorStatus.Active &&
    dealRotorAgreement.status === AgreementStatus.AwaitingSignature;
  const isResendInviteAllowed =
    isAllowedDealRotorAgreementWrite &&
    dealRotor.status === DealRotorStatus.Active &&
    dealRotorAgreement.status === AgreementStatus.AwaitingSignature;
  const isResetFieldsAllowed =
    isAllowedDealRotorAgreementWrite &&
    dealRotor.status === DealRotorStatus.Active &&
    dealRotorAgreement.status === AgreementStatus.Inactive;
  const isDiscardAllowed =
    isAllowedDealRotorAgreementWrite &&
    dealRotor.status === DealRotorStatus.Active &&
    dealRotorAgreement.type === AgreementType.Addendum &&
    dealRotorAgreement.status === AgreementStatus.Inactive;
  const isTerminateAllowedGeneral =
    isAllowedDealRotorAgreementWrite &&
    isLastAgreement &&
    dealRotor.status === DealRotorStatus.Active &&
    dealRotor.agreementEndDate !== null &&
    new Date(dealRotor.agreementEndDate).getTime() > Date.now() &&
    (dealRotor.agreementStatus === AgreementStatus.Finalised || dealRotor.agreementStatus === AgreementStatus.InEffect);
  const isTerminateAllowed = isTerminateAllowedGeneral && dealRotor.agreementTerminationDate === null;
  const isTerminateEditCancelAllowed = isTerminateAllowedGeneral && dealRotor.agreementTerminationDate !== null;

  const isActionMenuVisible =
    isCancelSigningAllowed ||
    isResetFieldsAllowed ||
    isDiscardAllowed ||
    isTerminateAllowed ||
    isTerminateEditCancelAllowed;

  const isShowSignPreview =
    isAllowedDealRotorAgreementWrite &&
    (dealRotorAgreement.status === AgreementStatus.AwaitingSignature ||
      dealRotorAgreement.status === AgreementStatus.Rejected);
  const isShowAgreementSignStatus =
    dealRotorAgreement.status === AgreementStatus.AwaitingSignature ||
    dealRotorAgreement.status === AgreementStatus.Rejected;
  const isShowRevertToDraft =
    dealRotor.status === DealRotorStatus.Active && dealRotorAgreement.status === AgreementStatus.Rejected;
  const isAllowRevertToDraft = isAllowedDealRotorAgreementWrite;
  const isShowSignAndFinalise =
    dealRotor.status === DealRotorStatus.Active && dealRotorAgreement.status === AgreementStatus.Inactive;

  return (
    <section className="card mb-3">
      <AccordionToggle eventKey={dealRotorAgreement.id} setActive={setAccordionList}>
        <div className="hstack gap-3 w-100 flex-wrap">
          <h2 className="h5 mb-0">{dealRotorAgreement.name || dealRotorAgreement.type}</h2>
          {isAgreementEnded && <span className="badge text-bg-cultured fs-14">Ended</span>}
          {isLastAgreement && dealRotor.agreementTerminationDate && (
            <span className="badge text-bg-cultured fs-14 d-flex flex-wrap gap-1 me-1">
              {dealRotor.isAgreementTerminated === true ? 'Terminated on ' : 'Now expected to end on '}
              <FormatDate date={dealRotor.agreementTerminationDate} formatValue="dd/MM/yyyy - HH:mm:ss" />
              <span>{tenantTimezoneText}</span>
            </span>
          )}
        </div>
        {isActionMenuVisible && (
          <AgreementDetailsActionMenu
            agreementId={dealRotorAgreement.id}
            agreementEndDate={dealRotor.agreementEndDate}
            agreementTerminationDate={dealRotor.agreementTerminationDate}
            isCancelSigningAllowed={isCancelSigningAllowed}
            isResendInviteAllowed={isResendInviteAllowed}
            isResetFieldsAllowed={isResetFieldsAllowed}
            isDiscardAllowed={isDiscardAllowed}
            isTerminateAllowed={isTerminateAllowed}
            isTerminateEditCancelAllowed={isTerminateEditCancelAllowed}
          />
        )}
      </AccordionToggle>
      <Accordion.Collapse eventKey={dealRotorAgreement.id}>
        <>
          <div className="card-body">
            <AgreementDetailsForm
              dealRotor={dealRotor}
              dealRotorAgreement={dealRotorAgreement}
              isAgreementEditDisabled={isAgreementEditDisabled}
            />

            <div className="hstack gap-3 mb-1">
              <span className="text-secondary">Agreement document</span>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Document to be finalised or signed and finalised.</Tooltip>}
              >
                <InfoIcon className="text-primary" />
              </OverlayTrigger>
            </div>
            <div className="hstack align-items-start gap-4 mb-3">
              {dealRotorAgreement.agreementDocument ? (
                <AgreementDocument
                  document={dealRotorAgreement.agreementDocument}
                  onRemove={onRemoveDocument}
                  isShowRemove={!isAgreementEditDisabled}
                />
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => openTemplateUpload(dealRotorAgreement.id)}
                    disabled={isAgreementEditDisabled}
                  >
                    Use template
                  </button>
                  <AgreementDetailsDocumentForm
                    agreementId={dealRotorAgreement.id}
                    type={AgreementDocumentType.Agreement}
                    isAgreementEditDisabled={isAgreementEditDisabled}
                  />
                </>
              )}
            </div>

            {(dealRotorAgreement.status === AgreementStatus.Inactive || dealRotorAgreement.sourceDocument !== null) && (
              <>
                <div className="hstack gap-3 mb-1">
                  <span className="text-secondary">Source Document (Optional)</span>
                  <OverlayTrigger placement="bottom" overlay={<Tooltip>Copy of original document.</Tooltip>}>
                    <InfoIcon className="text-primary" />
                  </OverlayTrigger>
                </div>
                {dealRotorAgreement.sourceDocument ? (
                  <AgreementDocument
                    document={dealRotorAgreement.sourceDocument}
                    onRemove={onRemoveDocument}
                    isShowRemove={!isAgreementEditDisabled}
                  />
                ) : (
                  <AgreementDetailsDocumentForm
                    agreementId={dealRotorAgreement.id}
                    type={AgreementDocumentType.Source}
                    isAgreementEditDisabled={isAgreementEditDisabled}
                  />
                )}
              </>
            )}

            <AgreementDetailsWatermark dealRotorAgreement={dealRotorAgreement} />

            <div className="d-flex align-items-end gap-3 mt-4">
              <div className="vstack align-self-end gap-2">
                <div className="hstack gap-3">
                  {isShowSignPreview && (
                    <Link
                      className="btn btn-link hstack align-self-end gap-2 fs-14 lh-1 fw-normal p-0"
                      to={generatePath(RoutePath.notarisePrivate, { agreementId: dealRotorAgreement.id })}
                    >
                      <AwaitingSignatureIcon />
                      <span>Signing/Preview</span>
                    </Link>
                  )}

                  <AgreementDetailsParticipants
                    isAllowedUserReadContacts={isAllowedUserReadContacts}
                    participants={dealRotorAgreement.participants}
                  />
                </div>

                {isShowAgreementSignStatus && (
                  <AgreementDetailsSignStatus
                    isCurrentUserSignaturePending={dealRotorAgreement.isCurrentUserSignaturePending}
                    partyASigningStatus={dealRotorAgreement.partyASigningStatus}
                    partyBSigningStatus={dealRotorAgreement.partyBSigningStatus}
                    signingPartyFirst={dealRotorAgreement.signingPartyFirst}
                  />
                )}
              </div>

              {isShowRevertToDraft && (
                <AgreementDetailsRevertToDraft
                  className="ms-auto"
                  agreementId={dealRotorAgreement.id}
                  isAllowRevertToDraft={isAllowRevertToDraft}
                />
              )}

              {isShowSignAndFinalise && (
                <div className="d-flex flex-wrap justify-content-end gap-3 ms-auto">
                  <AgreementDetailsFinalise
                    dealRotorAgreement={dealRotorAgreement}
                    isAgreementCanFinalise={isAgreementCanFinalise}
                  />
                  <AgreementDetailsSignAndFinalise
                    dealRotorAgreementId={dealRotorAgreement.id}
                    isAgreementCanFinalise={isAgreementCanFinalise}
                  />
                </div>
              )}
            </div>
          </div>
          <UploadTemplate
            dealRotorAgreementId={templateUploadAgreementId}
            isShowTemplateUpload={isShowTemplateUpload}
            toggleShowTemplateUpload={toggleShowTemplateUpload}
          />
        </>
      </Accordion.Collapse>
    </section>
  );
}

export default AgreementDetails;
