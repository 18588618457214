import { useForm } from 'react-hook-form';
import { object, string, array, number } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from '@tanstack/react-query';
import { useMemo, useEffect, useId, useCallback, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import HeaderConfig from 'src/pages/configuration/components/HeaderConfig';
import Field from 'src/components/forms/Field';
import Table, { createColumnHelper } from 'src/components/Table';
import { RoutePath } from 'src/router';
import { EditIcon, RemoveIcon, InfoIcon, MailIcon } from 'src/components/icons';
import { TemplatesIcon as AppIcon } from 'src/components/icons/configuration-page';

import useUserPermission from 'src/hooks/useUserPermission';

import Converter from 'src/utils/Converter';
import { useFormErrors } from 'src/hooks/FormHelpers';
import ServerErrorMessages from 'src/components/ServerErrorMessages';
import ServerErrorAdapter from 'src/utils/ServerErrorAdapter';

import {
  NotificationMethod,
  NotificationType,
  SelectOption,
  YupFormShape,
  NotificationDto,
  ServerError,
  NotificationSource,
  PermissionNames,
} from 'src/types';

import {
  getAllNotifications,
  getAllowedRoles,
  createAgreementEndDateNotification,
  removeAgreementEndDateNotification,
  updateNotification,
} from 'src/api/base/notification';

export type groupedNotifications = {
  [key in NotificationType]: {
    name: string;
    description: string;
    collection: NotificationDto[];
  };
};

type RemainderFormData = { triggerNoOfDaysBefore: number; methods: NotificationMethod[]; roles: string[] };
type EditFormData = { triggerNoOfDaysBefore: number | undefined; methods: NotificationMethod[]; roles: string[] };
type RemainderFormShape = YupFormShape<RemainderFormData>;
type EditFormShape = YupFormShape<EditFormData>;
const remainderFormSchema = object().shape<RemainderFormShape>({
  triggerNoOfDaysBefore: number()
    .label('Number of days')
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .required()
    .min(1)
    .max(365),
  methods: array().of(string()).label('Notification method').required().min(1),
  roles: array().of(string()).label('Roles').required().min(1),
});
const editFormSchema = object().shape<EditFormShape>({
  triggerNoOfDaysBefore: number()
    .label('Number of days')
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .nullable()
    .min(1)
    .max(365),
  methods: array().of(string()).label('Notification method').required().min(1),
  roles: array().of(string()).label('Roles').required().min(1),
});

const notificationMethodOptions = Converter.enumToSelectOptions(NotificationMethod).map((option) =>
  option.value === NotificationMethod.InApp ? { ...option, label: 'In-App' } : option,
);

const agreementColumnHelper = createColumnHelper<NotificationDto>();

export const getMethodCell = (values: NotificationMethod[]) => (
  <div className="d-md-flex">
    {values.map((method) => {
      switch (method) {
        case NotificationMethod.Email:
          return (
            <div className="badge text-bg-cultured me-2" key={method}>
              <MailIcon className="me-1" width={16} height={16} />
              <span className="align-middle">{method}</span>
            </div>
          );
        case NotificationMethod.InApp:
          return (
            <div className="badge text-bg-cultured me-2" key={method}>
              <AppIcon className="me-1" width={16} height={16} />
              <span className="align-middle">In-App</span>
            </div>
          );

        default:
          return method;
      }
    })}
  </div>
);

const NotificationConfig = () => {
  const { hasUserPermission } = useUserPermission();
  const isAllowedNotificationWrite = hasUserPermission(PermissionNames.NotificationWrite);

  const [editNoticeId, setEditNoticeId] = useState<string | null>(null);
  const { register, control, formState, handleSubmit, setError, clearErrors, reset } = useForm<RemainderFormData>({
    resolver: yupResolver(remainderFormSchema),
    defaultValues: {
      triggerNoOfDaysBefore: 1,
      methods: [],
      roles: [],
    },
  });

  const editFormMethods = useForm<EditFormData>({
    resolver: yupResolver(editFormSchema),
    defaultValues: {
      triggerNoOfDaysBefore: undefined,
      methods: [],
      roles: [],
    },
  });

  const { serverErrorMessages, setServerErrorMessages, handleErrors } = useFormErrors<RemainderFormData>(
    setError,
    clearErrors,
  );

  const notifications = useQuery({
    queryKey: ['getAllNotifications'],
    async queryFn({ signal }) {
      const config = { signal };
      const res = await getAllNotifications(config);

      const groupedNotifications = res.data
        .sort((a, b) => (a.source < b.source ? 1 : -1))
        .reduce((acc, notice) => {
          const { type } = notice;
          acc[type] = acc[type] ?? { name: '', description: '', collection: [] };
          acc[type].collection.push(notice);
          acc[type].description = notice.description;
          acc[type].name = notice.name;
          return acc;
        }, {} as groupedNotifications);
      return groupedNotifications;
    },
    initialData: {
      [NotificationType.AgreementActivation]: { name: '', description: '', collection: [] },
      [NotificationType.AgreementEndDateReminder]: { name: '', description: '', collection: [] },
      [NotificationType.AgreementMovedToEventsAndChanges]: { name: '', description: '', collection: [] },
      [NotificationType.AgreementSigningEvents]: { name: '', description: '', collection: [] },
      [NotificationType.DealRotorParticipantsChange]: { name: '', description: '', collection: [] },
      [NotificationType.DealRotorTermination]: { name: '', description: '', collection: [] },
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  const rolesOptions = useQuery({
    queryKey: ['getAllowedRoles'],
    async queryFn({ signal }) {
      const config = { signal };
      const res = await getAllowedRoles(config);
      const options: SelectOption[] = res.data.map((role) => {
        return {
          label: Converter.stripToAbbreviation(role.displayName),
          value: role.id,
        };
      });
      return options;
    },
    initialData: [],
    retry: false,
    refetchOnWindowFocus: false,
  });

  const removeCustomNotification = useCallback(
    async (id: string) => {
      await removeAgreementEndDateNotification(id);
      notifications.refetch();
    },
    [notifications],
  );

  const editCustomNotification = useCallback(
    (notice: NotificationDto) => {
      const { id, type, triggerNoOfDaysBefore } = notice;
      const daysDefault =
        type === NotificationType.AgreementEndDateReminder && triggerNoOfDaysBefore ? triggerNoOfDaysBefore : undefined;
      setEditNoticeId(id);
      editFormMethods.setValue('triggerNoOfDaysBefore', daysDefault);
      editFormMethods.setValue('methods', notice.methods);
      editFormMethods.setValue(
        'roles',
        notice.roles.map((role) => role.id),
      );
    },
    [editFormMethods],
  );

  const onEditClose = useCallback(() => {
    editFormMethods.reset();
    setEditNoticeId(null);
  }, [editFormMethods]);

  const onEditSubmit = editFormMethods.handleSubmit(async (formData) => {
    if (!editNoticeId) return;
    try {
      const days = !formData.triggerNoOfDaysBefore ? null : formData.triggerNoOfDaysBefore;
      await updateNotification(editNoticeId, { ...formData, triggerNoOfDaysBefore: days });
      await notifications.refetch();
      onEditClose();
    } catch (error) {
      const serverErrors = new ServerErrorAdapter(error as ServerError);
      setServerErrorMessages(serverErrors.combine());
    }
  });

  const numberOfDaysColumn = useMemo(
    () => [
      agreementColumnHelper.accessor('triggerNoOfDaysBefore', {
        id: 'triggerNoOfDaysBefore',
        header: 'Number of days',
        enableSorting: false,
        cell: ({ getValue, row }) => {
          const isEditMode = row.original.id === editNoticeId;
          return isEditMode ? (
            <Field
              className="col-12"
              field="input"
              type="number"
              min="1"
              max="365"
              placeholder="Enter number of days"
              autoComplete="off"
              register={editFormMethods.register('triggerNoOfDaysBefore')}
              control={editFormMethods.control}
              formSchema={editFormSchema}
              errors={editFormMethods.formState.errors}
            />
          ) : (
            <div>{getValue()}</div>
          );
        },
      }),
    ],
    [editFormMethods, editNoticeId],
  );

  const agreementColumns = useMemo(
    () =>
      [
        agreementColumnHelper.accessor('methods', {
          id: 'methods',
          header: 'Notification method',
          enableSorting: false,
          cell: ({ getValue, row }) => {
            const methodsValue = getValue();
            const isEditMode = row.original.id === editNoticeId;
            return isEditMode ? (
              <Field
                className="col-12"
                field="dropdown"
                placeholder="Choose"
                autoComplete="off"
                register={editFormMethods.register('methods')}
                control={editFormMethods.control}
                formSchema={editFormSchema}
                errors={editFormMethods.formState.errors}
                options={notificationMethodOptions}
                multiple
                dropdownProps={{
                  menuPosition: 'fixed',
                  isSearchable: false,
                }}
              />
            ) : (
              getMethodCell(methodsValue)
            );
          },
        }),
        agreementColumnHelper.accessor('roles', {
          id: 'roles',
          header: 'Roles',
          enableSorting: false,
          cell: function Cell({ getValue, row }) {
            const isEditMode = row.original.id === editNoticeId;
            const tooltipId = useId().replaceAll(':', '_');
            return isEditMode ? (
              <Field
                className="col-12"
                field="dropdown"
                placeholder="Choose"
                autoComplete="off"
                register={editFormMethods.register('roles')}
                control={editFormMethods.control}
                formSchema={editFormSchema}
                errors={editFormMethods.formState.errors}
                options={rolesOptions.data}
                multiple
                dropdownProps={{
                  menuPosition: 'fixed',
                  isSearchable: false,
                }}
              />
            ) : (
              <div>
                <span className="me-3 align-middle">
                  {getValue()
                    .map((role) => Converter.stripToAbbreviation(role.displayName))
                    .join(', ')}
                </span>
                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      {getValue().map((role, index) => (
                        <div key={`${index}${tooltipId}`}>{`${role.displayName} (${Converter.stripToAbbreviation(
                          role.displayName,
                        )})`}</div>
                      ))}
                    </Tooltip>
                  }
                >
                  <InfoIcon className="text-primary" />
                </OverlayTrigger>
              </div>
            );
          },
        }),
        agreementColumnHelper.display({
          id: 'actions',
          header: '',
          enableSorting: false,
          cell: ({ row }) => {
            const isEditMode = row.original.id === editNoticeId;
            const isSourceSystem = row.original.source === NotificationSource.System;
            return isEditMode ? (
              <div className="hstack gap-3 justify-content-center">
                <button className="btn btn-primary" type="button" onClick={onEditSubmit}>
                  Update
                </button>
                <button className="btn  btn-outline-primary" type="button" onClick={onEditClose}>
                  Cancel
                </button>
              </div>
            ) : (
              <div className="hstack gap-3 justify-content-center">
                <button className="btn btn-link" type="button">
                  <EditIcon width={16} height={16} onClick={() => editCustomNotification(row.original)} />
                </button>
                {!isSourceSystem && (
                  <button className="btn btn-link" type="button">
                    <RemoveIcon width={16} height={16} onClick={() => removeCustomNotification(row.original.id)} />
                  </button>
                )}
              </div>
            );
          },
        }),
      ].filter(({ id }) => (id === 'actions' && !isAllowedNotificationWrite ? false : true)),
    [
      isAllowedNotificationWrite,
      editCustomNotification,
      editFormMethods,
      editNoticeId,
      onEditClose,
      onEditSubmit,
      removeCustomNotification,
      rolesOptions.data,
    ],
  );

  const breadcrumbPaths = [
    { name: 'Home', to: RoutePath.root },
    { name: 'Configuration', to: RoutePath.configurationRoot },
  ];

  const agreementRemainderSubmit = handleSubmit(async (formData) => {
    try {
      handleErrors();
      await createAgreementEndDateNotification(formData);
      await notifications.refetch();
      reset();
    } catch (error) {
      const serverErrors = new ServerErrorAdapter(error as ServerError);
      setServerErrorMessages(serverErrors.combine());
    }
  });

  useEffect(() => {
    const serverErrors = new ServerErrorAdapter(notifications.error as ServerError);
    setServerErrorMessages(serverErrors.combine());
  }, [notifications.error, setServerErrorMessages]);

  return (
    <div className="h-100">
      <HeaderConfig className="mb-4" title="Notification settings" breadcrumbPaths={breadcrumbPaths} />
      <div className="mb-4 bg-white border rounded">
        <div className="pt-3 px-3">
          <h2 className="h5">{notifications.data[NotificationType.AgreementEndDateReminder].name}</h2>
          <p className="text-secondary">{notifications.data[NotificationType.AgreementEndDateReminder].description}</p>
        </div>
        <hr />
        {isAllowedNotificationWrite && (
          <form
            className="mb-4 px-3"
            name="notificationConfigurationForm"
            onSubmit={agreementRemainderSubmit}
            noValidate
          >
            <div className="row gx-3 mb-3">
              <Field
                className="col-sm-6 col-md-4 col-lg-4 col-xl-3"
                label="Number of days"
                field="input"
                type="number"
                min="1"
                max="365"
                placeholder="Enter number of days"
                autoComplete="off"
                register={register('triggerNoOfDaysBefore')}
                control={control}
                formSchema={remainderFormSchema}
                errors={formState.errors}
                disabled={!isAllowedNotificationWrite}
                autoFocus
              />
              <Field
                className="col-sm-6 col-md-4 col-lg-4 col-xl-3"
                label="Notification method"
                field="dropdown"
                placeholder="Choose"
                autoComplete="off"
                register={register('methods')}
                control={control}
                formSchema={remainderFormSchema}
                errors={formState.errors}
                options={notificationMethodOptions}
                multiple
                dropdownProps={{ isSearchable: false }}
                disabled={!isAllowedNotificationWrite}
              />
              <Field
                className="col-sm-6 col-md-4 col-lg-4 col-xl-3"
                label="Select roles"
                field="dropdown"
                placeholder="Choose"
                autoComplete="off"
                register={register('roles')}
                control={control}
                formSchema={remainderFormSchema}
                errors={formState.errors}
                options={rolesOptions.data}
                multiple
                dropdownProps={{ isSearchable: false }}
                disabled={!isAllowedNotificationWrite}
              />
              <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                <div className="form-label">&nbsp;</div>
                <button className="btn btn-outline-primary" type="submit" disabled={!isAllowedNotificationWrite}>
                  Add Notification
                </button>
              </div>
            </div>
          </form>
        )}

        <Table
          className="table-bordered mb-0"
          wrapperClassName="border rounded-bottom mx-3 mb-3"
          data={notifications.data[NotificationType.AgreementEndDateReminder].collection}
          total={notifications.data[NotificationType.AgreementEndDateReminder].collection.length}
          columns={[...numberOfDaysColumn, ...agreementColumns]}
          isShowPagination={false}
          pagination={{
            pageIndex: 0,
            pageSize: notifications.data[NotificationType.AgreementEndDateReminder].collection.length,
          }}
        />
      </div>
      <div className="mb-4 bg-white border rounded">
        <h2 className="h5 pt-3 px-3">Agreement status</h2>
        <hr />
        <div className="px-3">
          <h3 className="h6">{notifications.data[NotificationType.AgreementActivation].name}</h3>
          <p className="text-secondary">{notifications.data[NotificationType.AgreementActivation].description}</p>
          <Table
            className="table-bordered mb-0"
            wrapperClassName="border rounded-bottom mb-3"
            data={notifications.data[NotificationType.AgreementActivation].collection}
            total={notifications.data[NotificationType.AgreementActivation].collection.length}
            columns={agreementColumns}
            isShowPagination={false}
            pagination={{
              pageIndex: 0,
              pageSize: notifications.data[NotificationType.AgreementActivation].collection.length,
            }}
          />
        </div>
        <hr />
        <div className="px-3 mb-3">
          <h3 className="h6">{notifications.data[NotificationType.AgreementMovedToEventsAndChanges].name}</h3>
          <p className="text-secondary">
            {notifications.data[NotificationType.AgreementMovedToEventsAndChanges].description}
          </p>
          <Table
            className="table-bordered mb-0"
            wrapperClassName="border rounded-bottom mb-3"
            data={notifications.data[NotificationType.AgreementMovedToEventsAndChanges].collection}
            total={notifications.data[NotificationType.AgreementMovedToEventsAndChanges].collection.length}
            columns={agreementColumns}
            isShowPagination={false}
            pagination={{
              pageIndex: 0,
              pageSize: notifications.data[NotificationType.AgreementMovedToEventsAndChanges].collection.length,
            }}
          />
        </div>
      </div>
      <div className="mb-4 bg-white border rounded">
        <div className="pt-3 px-3">
          <h2 className="h5">{notifications.data[NotificationType.AgreementSigningEvents].name}</h2>
          <p>{notifications.data[NotificationType.AgreementSigningEvents].description}</p>
        </div>
        <hr />
        <Table
          className="table-bordered mb-0"
          wrapperClassName="border rounded-bottom mx-3 mb-3"
          data={notifications.data[NotificationType.AgreementSigningEvents].collection}
          total={notifications.data[NotificationType.AgreementSigningEvents].collection.length}
          columns={agreementColumns}
          isShowPagination={false}
          pagination={{
            pageIndex: 0,
            pageSize: notifications.data[NotificationType.AgreementSigningEvents].collection.length,
          }}
        />
      </div>
      <div className="mb-4 bg-white border rounded">
        <div className="pt-3 px-3">
          <h2 className="h5">{notifications.data[NotificationType.DealRotorParticipantsChange].name}</h2>
          <p>{notifications.data[NotificationType.DealRotorParticipantsChange].description}</p>
        </div>
        <hr />
        <Table
          className="table-bordered mb-0"
          wrapperClassName="border rounded-bottom mx-3 mb-3"
          data={notifications.data[NotificationType.DealRotorParticipantsChange].collection}
          total={notifications.data[NotificationType.DealRotorParticipantsChange].collection.length}
          columns={agreementColumns}
          isShowPagination={false}
          pagination={{
            pageIndex: 0,
            pageSize: notifications.data[NotificationType.DealRotorParticipantsChange].collection.length,
          }}
        />
      </div>
      <div className="bg-white border rounded">
        <div className="pt-3 px-3">
          <h2 className="h5">{notifications.data[NotificationType.DealRotorTermination].name}</h2>
          <p>{notifications.data[NotificationType.DealRotorTermination].description}</p>
        </div>
        <hr />
        <Table
          className="table-bordered mb-0"
          wrapperClassName="border rounded-bottom mx-3 mb-3"
          data={notifications.data[NotificationType.DealRotorTermination].collection}
          total={notifications.data[NotificationType.DealRotorTermination].collection.length}
          columns={agreementColumns}
          isShowPagination={false}
          pagination={{
            pageIndex: 0,
            pageSize: notifications.data[NotificationType.DealRotorTermination].collection.length,
          }}
        />
      </div>
      <ServerErrorMessages className="mx-3" messages={serverErrorMessages} />
    </div>
  );
};

export default NotificationConfig;
