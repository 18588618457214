import axios, { AxiosError, AxiosRequestHeaders } from 'axios';
import { logout, getCookieAuthState } from 'src/hocs/Auth';
import store from 'src/store';
import { authUserTenantIdSelector } from 'src/store/selectors/authSelector';

const createAxiosInstance = (url: string) => {
  const api = axios.create({
    baseURL: url,
    paramsSerializer: {
      indexes: null,
    },
  });

  api.interceptors.response.use(
    (response) => response,
    async (error: AxiosError) => {
      const { response } = error;
      if (response && (response.status === 401 || response.status === 403)) {
        try {
          logout();
        } catch (e) {}
      }
      return Promise.reject(error);
    },
  );

  api.interceptors.request.use(async (config) => {
    const cookieAuthState = getCookieAuthState();
    const tenantId = authUserTenantIdSelector(store.getState());
    try {
      if (!config.headers) config.headers = {} as AxiosRequestHeaders;
      if (cookieAuthState) config.headers.Authorization = `Bearer ${cookieAuthState.accessToken}`;
      if (tenantId) config.headers.TenantId = tenantId;
    } catch (e) {}
    return config;
  });

  return api;
};

export default createAxiosInstance;
