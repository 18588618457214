import classNames from 'classnames';
import { NavLink, To } from 'react-router-dom';

import ChevronIcon from 'src/components/icons/ChevronIcon';

import styles from './BulletNavigation.module.scss';

export type Bullet = {
  name: string;
  to?: To;
  disabled?: boolean;
};

export type Props = {
  bullets: Bullet[];
  className?: string;
};

function BulletNavigation({ className, bullets }: Props) {
  return (
    <nav className={classNames(styles.BulletNavigation, className)}>
      <ol className="nav gap-3 mb-0">
        {bullets.map(({ name, to, disabled }, index) => (
          <li className="nav-item d-flex align-items-center" key={`${name}${index}`}>
            {index > 0 && <ChevronIcon className="text-secondary me-3" right />}
            {to ? (
              <NavLink
                className={({ isActive }) => classNames('nav-link p-0', { active: isActive, disabled })}
                to={to}
                end
              >
                {name}
              </NavLink>
            ) : (
              <span>{name}</span>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}

export default BulletNavigation;
