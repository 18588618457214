import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetStore } from 'src/store';
import { UUIDString } from 'src/types';
import {
  AddMessagePayloadAction,
  ChatMessageModel,
  SetChatHistoryPayloadAction,
  UpdateMessagePayloadAction,
} from 'src/types/chat';

type DealRotorByIdChatData = {
  chatHistory: string | null;
  chatConversation: ChatMessageModel[];
};

type DealRotorChatState = Record<string, DealRotorByIdChatData>;

const initialState: DealRotorChatState = {};

const tenantSlice = createSlice({
  name: 'dealRotorChat',
  initialState,
  reducers: {
    setChatHistory(state: DealRotorChatState, action: PayloadAction<SetChatHistoryPayloadAction>) {
      const { dealRotorId, chatHistory } = action.payload;
      addOrIgnoreDealRotorChatData(dealRotorId, state);
      state[dealRotorId].chatHistory = chatHistory;
      return state;
    },
    addChatMessage(state: DealRotorChatState, action: PayloadAction<AddMessagePayloadAction>) {
      const { dealRotorId, message } = action.payload;
      addOrIgnoreDealRotorChatData(dealRotorId, state);
      state[dealRotorId].chatConversation.push(message);
      return state;
    },
    updateChatMessage(state: DealRotorChatState, action: PayloadAction<UpdateMessagePayloadAction>) {
      const { dealRotorId, messageId, messagePatch } = action.payload;
      addOrIgnoreDealRotorChatData(dealRotorId, state);
      const messageIndex = state[dealRotorId].chatConversation.findLastIndex((msg) => msg.id === messageId);
      if (messageIndex !== -1) {
        state[dealRotorId].chatConversation[messageIndex] = {
          ...state[dealRotorId].chatConversation[messageIndex],
          ...messagePatch,
        };
      }
      return state;
    },
    removeLoadingChatMessage(state: DealRotorChatState, action: PayloadAction<UUIDString>) {
      const dealRotorId = action.payload;
      if (dealRotorId in state) {
        state[dealRotorId].chatConversation = state[dealRotorId].chatConversation.filter(({ isLoading }) => !isLoading);
      }
      return state;
    },
  },
  extraReducers: (builder) => {
    // Cleanup
    builder.addCase(resetStore, () => initialState);
  },
});

export const { setChatHistory, addChatMessage, updateChatMessage, removeLoadingChatMessage } = tenantSlice.actions;

function addOrIgnoreDealRotorChatData(dealRotorId: UUIDString, state: DealRotorChatState): void {
  if (dealRotorId in state) return;
  state[dealRotorId] = { chatHistory: null, chatConversation: [] };
}

export default tenantSlice.reducer;
