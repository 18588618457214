import { AxiosRequestConfig } from 'axios';
import { useState, useCallback, useMemo } from 'react';
import { useQuery, keepPreviousData } from '@tanstack/react-query';

import useUrlQuery from 'src/hooks/useUrlQuery';

import parsePublicToken from 'src/utils/parsePublicToken';
import ServerErrorAdapter from 'src/utils/ServerErrorAdapter';

import {
  getAgreementDetailsForSignPublic,
  downloadLatestAgreementDocumentPublic,
  downloadSignPreviewAgreementDocumentPublic,
  signAgreementPublic,
  rejectAgreementPublic,
  downloadUserSignaturePublic,
  uploadUserSignaturePublic,
} from 'src/api/base/contactSign';

import { SignAgreementModel, RejectAgreementModel, SignatureType, ServerError, ServerFormErrors } from 'src/types';

import Notarise from './Notarise';

function NotarisePublic() {
  const urlQuery = useUrlQuery();
  const token = urlQuery.get('token');
  const accessToken = useMemo(() => parsePublicToken(token), [token]);

  const [signatureFilename, setSignatureFilename] = useState('');

  const {
    isLoading: isAgreementDetailsForSignLoading,
    data: agreementDetailsForSign,
    refetch: refetchAgreementDetailsForSign,
    error: agreementDetailsForSignError,
  } = useQuery({
    enabled: Boolean(accessToken),
    queryKey: ['getAgreementDetailsForSignPublic', accessToken],
    async queryFn({ signal }) {
      const config = { signal };
      const res = await getAgreementDetailsForSignPublic(accessToken, config);
      return res.data;
    },
    placeholderData: keepPreviousData,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const { userId, userSignatureFilename, dealRotorNumber, agreementSignatoryList, agreementDocumentList } = useMemo(
    () => ({
      userId: agreementDetailsForSign ? agreementDetailsForSign.userId : '',
      userSignatureFilename: agreementDetailsForSign ? agreementDetailsForSign.signatureFilename : null,
      dealRotorNumber: agreementDetailsForSign ? agreementDetailsForSign.dealRotorNumber : null,
      agreementSignatoryList: agreementDetailsForSign ? agreementDetailsForSign.signatories : null,
      agreementDocumentList: agreementDetailsForSign ? agreementDetailsForSign.documents : null,
    }),
    [agreementDetailsForSign],
  );

  const {
    isLoading: isLatestAgreementDocumentLoading,
    isFetching: isLatestAgreementDocumentFetching,
    data: latestAgreementDocument,
    refetch: refetchDownloadLatestAgreementDocument,
    error: latestAgreementDocumentError,
  } = useQuery({
    enabled: Boolean(accessToken),
    queryKey: ['downloadLatestAgreementDocumentPublic', accessToken],
    async queryFn({ signal }) {
      const config = { signal };
      const res = await downloadLatestAgreementDocumentPublic(accessToken, config);
      return res.data;
    },
    placeholderData: keepPreviousData,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const {
    isLoading: isSignPreviewAgreementDocumentLoading,
    isFetching: isSignPreviewAgreementDocumentFetching,
    data: signPreviewAgreementDocument,
    error: signPreviewAgreementDocumentError,
  } = useQuery({
    enabled: Boolean(accessToken),
    queryKey: ['downloadSignPreviewAgreementDocumentPublic', accessToken, signatureFilename],
    async queryFn({ signal }) {
      if (!signatureFilename) return null;
      const config = { signal };
      const payload = { signatureFilename };
      const res = await downloadSignPreviewAgreementDocumentPublic(accessToken, payload, config);
      return res.data;
    },
    initialData: null,
    placeholderData: keepPreviousData,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const errorMessages = useMemo<ServerFormErrors>(() => {
    const agreementDetailsForSignErrorMessages = new ServerErrorAdapter(
      agreementDetailsForSignError as ServerError,
    ).combine();
    const latestAgreementDocumentErrorMessages = new ServerErrorAdapter(
      latestAgreementDocumentError as ServerError,
    ).combine();
    const signPreviewAgreementDocumentErrorMessages = new ServerErrorAdapter(
      signPreviewAgreementDocumentError as ServerError,
    ).combine();

    return [
      ...agreementDetailsForSignErrorMessages,
      ...latestAgreementDocumentErrorMessages,
      ...signPreviewAgreementDocumentErrorMessages,
    ];
  }, [agreementDetailsForSignError, latestAgreementDocumentError, signPreviewAgreementDocumentError]);

  const isLatestDocumentLoading =
    isLatestAgreementDocumentLoading ||
    isLatestAgreementDocumentFetching ||
    isSignPreviewAgreementDocumentLoading ||
    isSignPreviewAgreementDocumentFetching;
  const latestDocument = useMemo(
    () => signPreviewAgreementDocument || latestAgreementDocument,
    [latestAgreementDocument, signPreviewAgreementDocument],
  );

  const downloadUserSignatureRequest = useCallback(
    async (signatureFilename: string, config?: AxiosRequestConfig) => {
      const res = await downloadUserSignaturePublic(accessToken, signatureFilename, config);
      return res.data;
    },
    [accessToken],
  );

  const uploadUserSignatureRequest = useCallback(
    async (type: SignatureType, data: FormData) => {
      const res = await uploadUserSignaturePublic(accessToken, type, data);
      return res.data;
    },
    [accessToken],
  );

  const signAgreementRequest = useCallback(
    (data: SignAgreementModel) => signAgreementPublic(accessToken, data),
    [accessToken],
  );

  const rejectAgreementRequest = useCallback(
    (data: RejectAgreementModel) => rejectAgreementPublic(accessToken, data),
    [accessToken],
  );

  if (!token || !accessToken || agreementDetailsForSignError) {
    return (
      <div className="container text-center">
        <em className="fst-normal fw-medium">Request can not be processed, link is expired or changed!</em>
      </div>
    );
  }

  return (
    <Notarise
      className="is-public"
      // Permissions
      isAllowedDealRotorAgreementSign={true}
      isAllowedUserReadSignature={true}
      // User
      userId={userId}
      userSignatureFilename={userSignatureFilename}
      // Agreement
      isAgreementDetailsForSignLoading={isAgreementDetailsForSignLoading}
      dealRotorNumber={dealRotorNumber}
      agreementSignatoryList={agreementSignatoryList}
      agreementDocumentList={agreementDocumentList}
      // PDF
      isLatestDocumentLoading={isLatestDocumentLoading}
      latestDocument={latestDocument}
      // signatureFilename
      signatureFilename={signatureFilename}
      setSignatureFilename={setSignatureFilename}
      // Requests
      downloadUserSignatureRequest={downloadUserSignatureRequest}
      uploadUserSignatureRequest={uploadUserSignatureRequest}
      signAgreementRequest={signAgreementRequest}
      rejectAgreementRequest={rejectAgreementRequest}
      // Refetch
      refetchAgreementDetailsForSign={refetchAgreementDetailsForSign}
      refetchDownloadLatestAgreementDocument={refetchDownloadLatestAgreementDocument}
      refetchUserSignatureFilename={refetchAgreementDetailsForSign}
      // Errors
      errorMessages={errorMessages}
    />
  );
}

export default NotarisePublic;
