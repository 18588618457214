import classNames from 'classnames';
import { NavLink, To } from 'react-router-dom';

import styles from './TabNavigation.module.scss';

export type Tab = {
  name: string;
  to?: To;
};

export type Props = {
  tabs: Tab[];
  className?: string;
};

function TabNavigation({ className, tabs }: Props) {
  return (
    <nav className={classNames(styles.TabNavigation, className)}>
      <ul className="nav custom-scroll gap-5">
        {tabs.map(({ name, to }, index) => (
          <li className="nav-item" key={`${name}${index}`}>
            {to ? (
              <NavLink className={({ isActive }) => classNames('nav-link', { active: isActive })} to={to} end>
                {name}
              </NavLink>
            ) : (
              <span className="nav-item disabled">{name}</span>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default TabNavigation;
