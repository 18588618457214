import { useState, useMemo, useEffect } from 'react';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { Link, generatePath } from 'react-router-dom';
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import { RoutePath } from 'src/router';

import { useForm, UseFormWatch, UseFormHandleSubmit } from 'react-hook-form';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Dot from 'src/components/Dot';
import Field from 'src/components/forms/Field';
import Table, { createColumnHelper, useTableChangeParams } from 'src/components/Table';
import Avatar from 'src/components/Avatar';
import BaseButton from 'src/components/buttons/BaseButton';
import ServerErrorMessages from 'src/components/ServerErrorMessages';
import { PlusIcon } from 'src/components/icons';

import useIsMount from 'src/hooks/useIsMount';
import useUserPermission from 'src/hooks/useUserPermission';
import useUrlQueryFilters from 'src/hooks/useUrlQueryFilters';

import { organisationStatusColorMap, organisationStatusOptions } from 'src/utils/selectOptions';
import RequestSearchParamsAdapter from 'src/utils/RequestSearchParamsAdapter';
import ServerErrorAdapter from 'src/utils/ServerErrorAdapter';

import { getOrganisationListSearch } from 'src/api/base/organisation';

import {
  OrganisationStatus,
  YupFormShape,
  OrganisationDto,
  ServerFormErrors,
  ServerError,
  PermissionNames,
} from 'src/types';

type FormData = {
  search: string;
  status: OrganisationStatus;
};
type FormShape = YupFormShape<FormData>;

const formSchema = object().shape<FormShape>({
  search: string().label('Search'),
  status: string().label('Organisation status'),
});

const columnHelper = createColumnHelper<OrganisationDto>();

function useFormattedWatch(
  isDataLoaded: boolean,
  watch: UseFormWatch<FormData>,
  handleSubmit: UseFormHandleSubmit<FormData, undefined>,
  resetPage: () => void,
): FormData {
  const isMount = useIsMount();
  const [data, setData] = useState<FormData>({} as FormData);
  const { search, status } = watch();

  useEffect(() => {
    handleSubmit((formData) => {
      if (isMount) return;
      setData({ ...formData });
      if (isDataLoaded) {
        resetPage();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, status]);

  return data;
}

const PartyBManagementList = () => {
  const { hasUserPermission } = useUserPermission();
  const isAllowedUserRead = hasUserPermission(PermissionNames.UserRead);
  const isAllowedOrganisationRead = hasUserPermission(PermissionNames.OrganisationRead);
  const isAllowedOrganisationWrite = hasUserPermission(PermissionNames.OrganisationWrite);

  const [isFiltersInitialized, setIsFiltersInitialized] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false); // Used to enable reset paging

  const { register, control, formState, setValue, watch, handleSubmit } = useForm<FormData>({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
  });

  const { pagination, setPagination, sorting, setSorting, resetPage } = useTableChangeParams({});
  const { search, status } = useFormattedWatch(isDataLoaded, watch, handleSubmit, resetPage);

  const initialFilters = useUrlQueryFilters({ search, status }, { pagination, sorting });

  useEffect(() => {
    if (initialFilters.search) {
      setValue('search', initialFilters.search);
    }
    if (initialFilters.status) {
      setValue('status', initialFilters.status);
    }

    if (initialFilters.pagination) {
      setPagination(initialFilters.pagination);
    }

    if (initialFilters.sorting) {
      setSorting(initialFilters.sorting);
    }

    // 1ms timeout to get rid of 2 requests
    setTimeout(() => {
      setIsFiltersInitialized(true);
    }, 1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFilters]);

  const {
    isFetching,
    data: paginatedData,
    error,
  } = useQuery({
    enabled: isFiltersInitialized,
    queryKey: ['getOrganisationListSearch', search, pagination, sorting, status],
    async queryFn({ signal }) {
      const config = { signal };
      const params = { ...new RequestSearchParamsAdapter({ search, pagination, sorting }), status };
      const res = await getOrganisationListSearch(params, config);
      return res.data;
    },
    placeholderData: keepPreviousData,
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 3 * 60 * 1000, // 3 min
  });

  const { data, pageCount, total } = useMemo(
    () => ({
      data: paginatedData?.items ?? [],
      pageCount: paginatedData?.total ? Math.ceil(paginatedData.total / pagination.pageSize) : 1,
      total: paginatedData?.total ?? 0,
    }),
    [paginatedData, pagination.pageSize],
  );

  const errorMessages = useMemo<ServerFormErrors>(
    () => new ServerErrorAdapter(error as ServerError).combine(),
    [error],
  );

  useEffect(() => {
    if (paginatedData) {
      setIsDataLoaded(true);
    }
  }, [paginatedData]);

  useEffect(() => {
    // Keep in mind that data can be loaded from the cache
    if (isFiltersInitialized && isDataLoaded) {
      resetPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        id: 'name',
        header: 'Name',
        cell: ({ getValue, row }) => (
          <div className="d-flex align-items-center gap-2">
            <Avatar
              src={row.original.logoUrl}
              alt={row.original.name}
              initials={row.original.displayInitials}
              theme={row.original.theme}
            />
            {isAllowedOrganisationRead ? (
              <Link
                className="fw-medium"
                to={generatePath(RoutePath.partyBManagementById, { organisationId: row.original.id })}
              >
                {getValue()}
              </Link>
            ) : (
              <span className="fw-medium">{getValue()}</span>
            )}
          </div>
        ),
      }),
      columnHelper.accessor('addressLine', {
        id: 'address',
        header: 'Address',
        cell: ({ row }) => {
          const { addressLine, town, state, country, postCode } = row.original;
          const fullAddress =
            addressLine || town || state || country || postCode
              ? [addressLine, town, state, country, postCode].filter((v) => v).join(', ')
              : 'N/A';
          return <address className="mb-0">{fullAddress}</address>;
        },
      }),
      columnHelper.accessor('email', {
        id: 'email',
        header: 'Email',
        cell: ({ getValue }) => {
          const email = getValue();
          return email ? (
            <Link className="text-nowrap" to={`mailto:${getValue()}`}>
              {getValue()}
            </Link>
          ) : (
            'N/A'
          );
        },
      }),
      columnHelper.accessor('phone', {
        cell: ({ getValue }) => {
          const phone = getValue();
          return phone ? (
            <Link className="text-nowrap" to={`tel:${phone.replace(/[\s()]/g, '')}`}>
              {phone}
            </Link>
          ) : (
            'N/A'
          );
        },
        id: 'phone',
        header: 'Phone',
      }),
      columnHelper.accessor('users', {
        cell: function Cell({ getValue }) {
          const [firstContact, ...restContacts] = getValue();
          return (
            <div className="hstack gap-2">
              {firstContact && (
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{`${firstContact.firstName} ${firstContact.lastName}`}</Tooltip>}
                >
                  <span>
                    <BaseButton
                      className="btn btn-text border-0 p-0"
                      to={
                        isAllowedUserRead
                          ? generatePath(RoutePath.dealParticipantDetailsById, { userId: firstContact.id })
                          : undefined
                      }
                    >
                      <Avatar
                        src={firstContact.logoUrl}
                        alt={`${firstContact.firstName} ${firstContact.lastName}` || '--'}
                      />
                    </BaseButton>
                  </span>
                </OverlayTrigger>
              )}
              {restContacts.length > 0 && (
                <OverlayTrigger
                  placement="bottom"
                  rootClose={true}
                  trigger={['click']}
                  overlay={
                    <Popover className=" mb-3">
                      <ul className="list-unstyled mb-0 py-2">
                        {restContacts.map(({ id, firstName, lastName, logoUrl }) => {
                          const fullName = `${firstName} ${lastName}`;
                          return (
                            <li key={id}>
                              <div className="hstack gap-2 px-3 py-1">
                                <Avatar src={logoUrl} alt={fullName} />
                                {isAllowedUserRead ? (
                                  <Link
                                    className="text-decoration-none hstack gap-2"
                                    to={generatePath(RoutePath.dealParticipantDetailsById, { userId: id })}
                                  >
                                    {fullName}
                                  </Link>
                                ) : (
                                  <span>{fullName}</span>
                                )}
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </Popover>
                  }
                >
                  <button
                    type="button"
                    className="btn btn-link fs-14 lh-1 fw-normal text-nowrap p-0"
                  >{`+${restContacts.length} more`}</button>
                </OverlayTrigger>
              )}
            </div>
          );
        },
        id: 'users',
        header: 'Contacts',
        enableSorting: false,
      }),
      columnHelper.accessor('status', {
        cell: ({ getValue }) => (
          <div className="d-flex align-items-center gap-2">
            <Dot color={organisationStatusColorMap[getValue()] as any} />
            <span>{getValue()}</span>
          </div>
        ),
        id: 'status',
        header: 'Status',
        enableSorting: false,
      }),
    ],
    [isAllowedUserRead, isAllowedOrganisationRead],
  );

  return (
    <>
      <div className="bg-white border rounded-top border-bottom-0">
        <form name="partyBManagementListFilters" noValidate>
          <div className="row g-3 p-3">
            <Field
              className="col-md-4"
              field="input"
              type="search"
              placeholder="Search"
              autoComplete="off"
              register={register('search')}
              control={control}
              formSchema={formSchema}
              errors={formState.errors}
            />
            <Field
              className="col-md-4"
              field="dropdown"
              placeholder={
                (
                  <div className="hstack gap-2">
                    <Dot color="metallic-silver" />
                    <span>All</span>
                  </div>
                ) as any
              }
              autoComplete="off"
              register={register('status')}
              control={control}
              formSchema={formSchema}
              errors={formState.errors}
              options={organisationStatusOptions}
              dropdownProps={{
                isSearchable: false,
                isClearable: true,
              }}
            />
            {isAllowedOrganisationWrite && (
              <div className="col-md-4 d-flex justify-content-end">
                <Link className="btn btn-primary" to={RoutePath.partyBManagementCreate}>
                  Create party B <PlusIcon />
                </Link>
              </div>
            )}
          </div>
        </form>
      </div>
      <Table
        className="table-border-bottom-none mb-0"
        wrapperClassName="border rounded-bottom border-top-0 mb-3"
        data={data}
        total={total}
        columns={columns}
        setSorting={setSorting}
        sorting={sorting}
        pagination={pagination}
        setPagination={setPagination}
        isLoading={isFetching}
        pageCount={pageCount}
      />
      <ServerErrorMessages messages={errorMessages} />
    </>
  );
};

export default PartyBManagementList;
