import { lazy, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { GuardedRoute } from 'src/router';

import ConfigurationPage from 'src/pages/configuration/Configuration';

import UserConfig, { UserConfigList } from 'src/pages/configuration/components/UserConfig';
import CreateUserForm from 'src/pages/configuration/components/UserConfig.form';

import RolePermissionConfig from 'src/pages/configuration/components/RolePermissionConfig';

import IntegrationConfig from 'src/pages/configuration/components/IntegrationConfig';
import IntegrationSalesForce from 'src/pages/configuration/components/IntegrationConfig.sales-force';
import IntegrationXero from 'src/pages/configuration/components/IntegrationConfig.xero';

import NotificationConfig from 'src/pages/configuration/components/NotificationConfig';
import TemplateConfig, { TemplateManagementList } from 'src/pages/configuration/components/TemplateConfig';

import TemplatesList from 'src/pages/configuration/components/TemplateConfig.templates';
import ParametersList from 'src/pages/configuration/components/TemplateConfig.parameters';

import CognitiveServicesConfig from 'src/pages/configuration/components/CognitiveServicesConfig';

import TimezoneManagement from 'src/pages/configuration/components/TimezoneManagement';

import { PermissionNames } from 'src/types';

const TemplateEdit = lazy(
  () => import(/* webpackChunkName: "rich-edit" */ 'src/pages/configuration/components/TemplateConfig.edit'),
);

const TemplateEditPlaceholder = () => (
  <div className="py-3" aria-hidden="true">
    <div className="placeholder-glow vstack gap-3">
      <span className="placeholder col-12 p-3 bg-primary rounded"></span>
    </div>
  </div>
);

function ConfigurationRoutes() {
  // "/configuration/*"
  return (
    <Routes>
      <Route index element={<ConfigurationPage />} />

      <Route
        path="user/*"
        element={
          <GuardedRoute
            meta={{
              auth: true,
              allowedPermissions: [PermissionNames.UserRead, PermissionNames.UserWrite],
            }}
          >
            <UserConfig />
          </GuardedRoute>
        }
      >
        <Route path="list" element={<UserConfigList />} />
        <Route
          path="create"
          element={
            <GuardedRoute
              meta={{
                auth: true,
                allowedPermissions: [PermissionNames.UserWrite],
              }}
            >
              <CreateUserForm />
            </GuardedRoute>
          }
        />
        <Route path="*" element={<Navigate to="list" replace />} />
      </Route>
      <Route
        path="role"
        element={
          <GuardedRoute
            meta={{
              auth: true,
              allowedPermissions: [PermissionNames.RoleRead, PermissionNames.RoleWrite],
            }}
          >
            <RolePermissionConfig />
          </GuardedRoute>
        }
      />
      <Route
        path="integration/*"
        element={
          <GuardedRoute meta={{ auth: true, allowedPermissions: [PermissionNames.IntegrationsSalesforce] }}>
            <IntegrationConfig />
          </GuardedRoute>
        }
      >
        <Route
          path="salesforce"
          element={
            <GuardedRoute meta={{ auth: true, allowedPermissions: [PermissionNames.IntegrationsSalesforce] }}>
              <IntegrationSalesForce />
            </GuardedRoute>
          }
        />
        <Route
          path="xero"
          element={
            <GuardedRoute meta={{ auth: true, allowedPermissions: [] }}>
              <IntegrationXero />
            </GuardedRoute>
          }
        />
        <Route path="*" element={<Navigate to="salesforce" replace />} />
      </Route>
      <Route
        path="notification"
        element={
          <GuardedRoute
            meta={{
              auth: true,
              allowedPermissions: [PermissionNames.NotificationWrite],
            }}
          >
            <NotificationConfig />
          </GuardedRoute>
        }
      />
      <Route
        path="templates-management/*"
        element={
          <GuardedRoute
            meta={{
              auth: true,
              allowedPermissions: [PermissionNames.TemplateRead, PermissionNames.TemplateWrite],
            }}
          >
            <TemplateConfig />
          </GuardedRoute>
        }
      >
        <Route path="list/*" element={<TemplateManagementList />}>
          <Route path="templates" element={<TemplatesList />} />
          <Route path="parameters" element={<ParametersList />} />
          <Route path="*" element={<Navigate to="templates" replace />} />
        </Route>
        <Route
          path="edit"
          element={
            <GuardedRoute
              meta={{
                auth: true,
                allowedPermissions: [PermissionNames.TemplateWrite],
              }}
            >
              <Suspense fallback={<TemplateEditPlaceholder />}>
                <TemplateEdit />
              </Suspense>
            </GuardedRoute>
          }
        >
          <Route
            path=":templateId"
            element={
              <Suspense fallback={<TemplateEditPlaceholder />}>
                <TemplateEdit />
              </Suspense>
            }
          />
        </Route>
        <Route path="*" element={<Navigate to="list" replace />} />
      </Route>
      <Route
        path="cognitive-services"
        element={
          <GuardedRoute
            meta={{
              auth: true,
              allowedPermissions: [PermissionNames.ChatWrite],
            }}
          >
            <CognitiveServicesConfig />
          </GuardedRoute>
        }
      />
      <Route
        path="timezone"
        element={
          <GuardedRoute
            meta={{
              auth: true,
              allowedPermissions: [PermissionNames.TimezoneRead, PermissionNames.TimezoneWrite],
            }}
          >
            <TimezoneManagement />
          </GuardedRoute>
        }
      />

      <Route path="*" element={<Navigate to="./" />} />
    </Routes>
  );
}

export default ConfigurationRoutes;
