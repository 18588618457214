import { useCallback, useState } from 'react';
import { Modal } from 'react-bootstrap';

import { useSelector } from 'src/store';
import { userMFAEnabledSelector } from 'src/store/selectors/profileSelector';

import ServerErrorMessages from 'src/components/ServerErrorMessages';
import LoadingButton from 'src/components/buttons/LoadingButton';

import { logout } from 'src/hocs/Auth';

import ServerErrorAdapter from 'src/utils/ServerErrorAdapter';

import { updateMFACurrentUser } from 'src/api/base/user';

import { ServerError, ServerFormErrors } from 'src/types';

enum MFAModal {
  Add,
  Change,
  Remove,
}

type ModalProps = {
  show: boolean;
  closeModal: () => void;
};

function AddMFAModal({ show, closeModal }: ModalProps) {
  const [serverErrorMessages, setServerErrorMessages] = useState<ServerFormErrors>([]);
  const onConfirm = async () => {
    try {
      await updateMFACurrentUser(true);
      logout();
    } catch (e) {
      const serverErrors = new ServerErrorAdapter(e as ServerError);
      setServerErrorMessages(serverErrors.combine());
    }
  };

  return (
    <Modal show={show} onHide={closeModal} size="lg" centered>
      <Modal.Header onHide={closeModal} closeButton>
        <Modal.Title className="h5">Enable Multi-Factor Authentication (MFA)</Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-0">
        <p>Enabling MFA enhances the security of your account by requiring an verification code during login.</p>
        <p>Once enabled, you will be logged out, and the next time you log in, you will be prompted to set up MFA.</p>
        <p>
          We recommend installing a trusted Multi-Factor Authentication (MFA) application on your device before
          proceeding. Here are some popular options available for iOS and Android:
          <ul>
            <li className="mb-2">
              <strong>Google Authenticator</strong>: Available on both iOS and Android, Google Authenticator is a widely
              used MFA application that generates time-based one-time passcodes (TOTP).
            </li>
            <li>
              <strong>Microsoft Authenticator</strong>: Another reliable option, Microsoft Authenticator offers both
              TOTP and push notification-based MFA capabilities, compatible with iOS and Android devices.
            </li>
          </ul>
        </p>
        <p>Please install one of these applications on your device to ensure a smooth MFA setup process.</p>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-link" onClick={closeModal}>
          Cancel
        </button>
        <LoadingButton type="button" className="btn btn-primary" onClick={onConfirm}>
          Enable MFA
        </LoadingButton>
        <ServerErrorMessages className="w-100" messages={serverErrorMessages} />
      </Modal.Footer>
    </Modal>
  );
}

function ChangeMFAModal({ show, closeModal }: ModalProps) {
  const [serverErrorMessages, setServerErrorMessages] = useState<ServerFormErrors>([]);
  const onConfirm = async () => {
    try {
      await updateMFACurrentUser(true);
      logout();
    } catch (e) {
      const serverErrors = new ServerErrorAdapter(e as ServerError);
      setServerErrorMessages(serverErrors.combine());
    }
  };

  return (
    <Modal show={show} onHide={closeModal} centered>
      <Modal.Header onHide={closeModal} closeButton>
        <Modal.Title className="h5">Reset Multi-Factor Authentication (MFA)</Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-0">
        <p>Once confirmed, you will be logged out, and the next time you log in, you will be prompted to set up MFA.</p>
        <p>
          <strong>Are you sure you want to reset MFA for your account?</strong>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-link" onClick={closeModal}>
          Cancel
        </button>
        <LoadingButton type="button" className="btn btn-primary" onClick={onConfirm}>
          Yes, reset MFA
        </LoadingButton>
        <ServerErrorMessages className="w-100" messages={serverErrorMessages} />
      </Modal.Footer>
    </Modal>
  );
}

function RemoveMFAModal({ show, closeModal }: ModalProps) {
  const [serverErrorMessages, setServerErrorMessages] = useState<ServerFormErrors>([]);
  const onConfirm = async () => {
    try {
      await updateMFACurrentUser(false);
      logout();
    } catch (e) {
      const serverErrors = new ServerErrorAdapter(e as ServerError);
      setServerErrorMessages(serverErrors.combine());
    }
  };

  return (
    <Modal show={show} onHide={closeModal} centered>
      <Modal.Header onHide={closeModal} closeButton>
        <Modal.Title className="h5">Disable Multi-Factor Authentication (MFA)</Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-0">
        <p>Disabling MFA will remove the additional security layer currently protecting your account.</p>
        <p>Once disabled, you will be logged out, and you will need to log in again.</p>
        <p>
          Please note that disabling MFA may decrease the security of your account and increase the risk of unauthorized
          access.
        </p>
        <p>
          <strong>Are you sure you want to disable MFA for your account?</strong>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-link" onClick={closeModal}>
          Cancel
        </button>
        <LoadingButton type="button" className="btn btn-danger" onClick={onConfirm}>
          Yes, disable MFA
        </LoadingButton>
        <ServerErrorMessages className="w-100" messages={serverErrorMessages} />
      </Modal.Footer>
    </Modal>
  );
}

function UserProfileSettingsMFA() {
  const isMFAEnabled = useSelector(userMFAEnabledSelector);
  const [openedMFAModal, setOpenedMFAModal] = useState<MFAModal | null>();
  const closeModal = useCallback(() => {
    setOpenedMFAModal(null);
  }, []);

  return (
    <>
      <div className="d-flex gap-4">
        {!isMFAEnabled && (
          <button className="btn btn-outline-primary" type="button" onClick={() => setOpenedMFAModal(MFAModal.Add)}>
            Enable MFA
          </button>
        )}
        {isMFAEnabled && (
          <button className="btn btn-outline-primary" type="button" onClick={() => setOpenedMFAModal(MFAModal.Change)}>
            Reset MFA
          </button>
        )}
        {isMFAEnabled && (
          <button className="btn btn-outline-danger" type="button" onClick={() => setOpenedMFAModal(MFAModal.Remove)}>
            Disable MFA
          </button>
        )}
      </div>
      <AddMFAModal show={openedMFAModal === MFAModal.Add} closeModal={closeModal} />
      <ChangeMFAModal show={openedMFAModal === MFAModal.Change} closeModal={closeModal} />
      <RemoveMFAModal show={openedMFAModal === MFAModal.Remove} closeModal={closeModal} />
    </>
  );
}

export default UserProfileSettingsMFA;
