import { ErrorMessage } from '@hookform/error-message';

type Props = {
  name: string;
  errors?: any;
};

function FieldErrorMessage({ name, errors }: Props) {
  return (
    <ErrorMessage
      className="text-danger fs-14 pt-1"
      name={name}
      errors={errors}
      as="div"
      role="status"
      aria-live="polite"
    />
  );
}

export default FieldErrorMessage;
