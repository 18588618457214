import { useMemo, createElement, ReactHTML } from 'react';
import { useNavigate, generatePath } from 'react-router-dom';
import { RoutePath } from 'src/router';

import Download from 'src/utils/Download';

import { downloadDealRotorFile } from 'src/api/base/dealRotor';

import { MessageDto, MessageVariableLinkType } from 'src/types';

const downloadFile = async (dealRotorFileId: string, fileName: string) => {
  try {
    const res = await downloadDealRotorFile(dealRotorFileId);
    Download.fileFromRes(res, fileName);
  } catch (e) {}
};

type Props = {
  className?: string;
  message: string | null;
  afterLinkClick?: (...args: unknown[]) => unknown;
  tag?: keyof ReactHTML;
};

function Message({ className, message, afterLinkClick, tag = 'div' }: Props) {
  const navigate = useNavigate();
  const messageHtml = useMemo<string>(() => {
    if (!message) return '';
    const isLikeJSONTemplate = message.startsWith('{') && message.endsWith('}') && message.includes('"Text"');
    if (!isLikeJSONTemplate) return message;

    let messageResult = '';
    try {
      const { Text, Variables } = JSON.parse(message) as MessageDto;
      messageResult = Text;
      Variables.forEach(({ Key, Text, Link: { Id, Type } }) => {
        let url = '';
        switch (Type) {
          case MessageVariableLinkType.DealRotor:
            url = generatePath(RoutePath.dealRotorEditDetailedDealRotorDetails, { dealRotorId: Id });
            break;
          case MessageVariableLinkType.UserProfile:
            url = generatePath(RoutePath.userContactById, { userId: Id });
            break;
          case MessageVariableLinkType.PartyB:
            url = generatePath(RoutePath.partyBManagementById, { organisationId: Id });
            break;
          case MessageVariableLinkType.DealRotorFile:
            url = `/files/${Text}`;
            break;
        }

        const replaceText = url ? `<a href="${url}" data-type="${Type}" data-id="${Id}">${Text}</a>` : Text;
        messageResult = messageResult.replace(Key, replaceText);
      });
    } catch (e) {}
    return messageResult;
  }, [message]);

  const onClick = (e: any) => {
    const target: HTMLElement = e.target;
    const targetLink = target.closest('a');
    if (targetLink) {
      e.preventDefault();
      const type = targetLink.getAttribute('data-type') as MessageVariableLinkType;
      if (type === MessageVariableLinkType.DealRotorFile) {
        const dealRotorFileId = targetLink.getAttribute('data-id') || '';
        const fileName = targetLink.textContent || '';
        downloadFile(dealRotorFileId, fileName);
      } else {
        const url = new URL(targetLink.href);
        navigate(url.pathname);
      }

      if (afterLinkClick) {
        afterLinkClick();
      }
    }
  };

  return createElement(tag, { className, onClick, dangerouslySetInnerHTML: { __html: messageHtml } });
}

export default Message;
