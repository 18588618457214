import { useMemo } from 'react';
import { useLocation, useMatch, useParams, Params, Outlet, generatePath } from 'react-router-dom';
import { RoutePath } from 'src/router';

import Breadcrumbs, { Breadcrumb } from 'src/components/Breadcrumbs';
import BulletNavigation from 'src/components/BulletNavigation';
import usePersistPageParams, { DEAL_ROTOR_LIST_PAGE_KEY } from 'src/hooks/usePersistPageParams';

function DealRotorCreate() {
  const location = useLocation();
  const dealRotorCreateDetailsMatch = useMatch(RoutePath.dealRotorCreateDetails);
  const { dealRotorId = '' } = useParams<Params<'dealRotorId'>>();
  const { pageUrlSearch: dealRotorListPageUrlSearch } = usePersistPageParams(DEAL_ROTOR_LIST_PAGE_KEY);

  const breadcrumbs = useMemo<Breadcrumb[]>(
    () => [
      { name: 'Home', to: RoutePath.root },
      { name: 'Deal rotors', to: { pathname: RoutePath.dealRotorList, search: dealRotorListPageUrlSearch } },
      { name: 'Create deal rotor', to: location },
    ],
    [location, dealRotorListPageUrlSearch],
  );

  const bullets = useMemo(
    () => [
      {
        name: 'Select template and roles',
        to: RoutePath.dealRotorCreateTemplateAndRoles,
        disabled: Boolean(dealRotorCreateDetailsMatch),
      },
      {
        name: 'Deal rotor details',
        to: dealRotorCreateDetailsMatch ? generatePath(RoutePath.dealRotorCreateDetails, { dealRotorId }) : undefined,
      },
    ],
    [dealRotorCreateDetailsMatch, dealRotorId],
  );

  // "/deal-rotor/create/*"
  return (
    <div className="h-100">
      <Breadcrumbs className="mb-4" breadcrumbs={breadcrumbs} />
      <header className="bg-white border rounded-top p-4">
        <h1 className="h5 mb-4">Create deal rotor</h1>
        <BulletNavigation bullets={bullets} />
      </header>
      <Outlet />
    </div>
  );
}

export default DealRotorCreate;
