import { useParams } from 'react-router-dom';

import DealParticipantDetailsFrom from './DealParticipantDetails.from';
import DealParticipantDetailsList from './DealParticipantDetails.list';

function DealParticipantDetails() {
  const { userId = '' } = useParams<'userId'>();

  return (
    <>
      <h1 className="h5 mb-4">Participant details</h1>
      <DealParticipantDetailsFrom userId={userId} />
      <DealParticipantDetailsList userId={userId} />
    </>
  );
}

export default DealParticipantDetails;
