import { Routes, Route, Navigate } from 'react-router-dom';
import { GuardedRoute } from 'src/router';

import DealParticipantListPage from './DealParticipantList';
import DealParticipantDetailsPage from './DealParticipantDetails';

import { PermissionNames } from 'src/types';

function DealParticipantRoutes() {
  // "/deal-participant/*"
  return (
    <Routes>
      <Route index element={<Navigate to="list" replace={true} />} />

      <Route path="list" element={<DealParticipantListPage />} />
      <Route
        path=":userId"
        element={
          <GuardedRoute meta={{ auth: true, allowedPermissions: [PermissionNames.UserRead] }}>
            <DealParticipantDetailsPage />
          </GuardedRoute>
        }
      />

      <Route path="*" element={<Navigate to="./" />} />
    </Routes>
  );
}

export default DealParticipantRoutes;
