import classNames from 'classnames';
import { useState, useMemo, useEffect } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Collapse } from 'react-bootstrap';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { VisibilityState } from '@tanstack/react-table';
import { Link, generatePath } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { useSelector } from 'src/store';
import { tenantTimezoneInBracketsSelector } from 'src/store/selectors/tenantSelector';

import { useForm, UseFormWatch, UseFormHandleSubmit } from 'react-hook-form';
import { object, string, array, date, DateSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Dot from 'src/components/Dot';
import Field from 'src/components/forms/Field';
import Table, { useTableChangeParams, createColumnHelper } from 'src/components/Table';
import FormatDate from 'src/components/FormatDate';
import ServerErrorMessages from 'src/components/ServerErrorMessages';
import ActiveFilterList, { ActiveFilter } from 'src/components/ActiveFilterList';
import { FilterIcon, PlusIcon } from 'src/components/icons';

import useIsMount from 'src/hooks/useIsMount';
import useUserPermission from 'src/hooks/useUserPermission';
import useUrlQueryFilters from 'src/hooks/useUrlQueryFilters';
import usePersistPageParams, {
  DEAL_ROTOR_LIST_PAGE_KEY,
  DealRotorListPageParams,
} from 'src/hooks/usePersistPageParams';

import Converter from 'src/utils/Converter';
import LocalDate from 'src/utils/LocalDate';
import ServerErrorAdapter from 'src/utils/ServerErrorAdapter';
import RequestSearchParamsAdapter from 'src/utils/RequestSearchParamsAdapter';
import { getAgreementFullStatus } from 'src/utils/statusFormat';
import {
  dealRotorTypeOptions,
  dealRotorStatusOptions,
  agreementStageOptions,
  dealRotorRelationshipTypeOptions,
  roleNameOptions,
  agreementStageColorMap,
  transactionTypeOptions,
  dealRotorStatusColorMap,
} from 'src/utils/selectOptions';

import { getDealRotorListSearch, getDealRotorTagList, GetDealRotorListParams } from 'src/api/base/dealRotor';
import { getOrganisationPartyBListSummary } from 'src/api/base/organisation';

import {
  DealRotorSummaryDto,
  SelectOption,
  YupFormShape,
  ServerError,
  ServerFormErrors,
  AgreementTransactionType,
  PermissionNames,
  DealRotorStatus,
  OrganisationStatus,
} from 'src/types';

import styles from './DealRotorList.module.scss';

type FormData = GetDealRotorListParams & {
  search: string | undefined;
  extraColumns: string[] | undefined;
  roles: string[] | undefined;
};
type FormShape = YupFormShape<FormData>;
const formSchema = object().shape<FormShape>({
  search: string().trim().label('Search').emptyToUndefined(),
  agreementStage: string().trim().label('Agreement stage').emptyToUndefined(),
  extraColumns: array().of(string()).label('Column').emptyToUndefined(),
  agreementTransactionType: string().trim().label('Agreement transaction type').emptyToUndefined(),
  daysRange: string().trim().label('Days range').emptyToUndefined(),

  partyB: array().of(string()).label('Party B').emptyToUndefined(),
  type: string().trim().label('Deal rotor type').emptyToUndefined(),
  status: string().trim().label('Deal rotor status').emptyToUndefined(),

  relationshipType: string().trim().label('Deal relationship type').emptyToUndefined(),
  roles: array().of(string()).label('Roles').emptyToUndefined(),
  tags: array().of(string()).label('Tags').emptyToUndefined(),

  startDateFrom: date()
    .label('Start date from')
    .nullable()
    .transform((curr, orig) => (orig === '' ? undefined : curr)),
  startDateTo: date()
    .label('Start date to')
    .nullable()
    .transform((curr, orig) => (orig === '' ? undefined : curr))
    .when('startDateFrom', ([value]: Array<string | Date | undefined | null>, scheme: DateSchema<any>) => {
      if (!value) return scheme;
      const startDateFrom = new Date(value);
      return scheme.min(startDateFrom, ({ label, min }) => {
        const dateFromFormatted = Converter.getFormattedDate(new LocalDate(min).toJSON());
        return `${label} field must be ${dateFromFormatted} or later`;
      });
    }),
});

const columnHelper = createColumnHelper<DealRotorSummaryDto>();

function useFormattedWatch(
  isDataLoaded: boolean,
  watch: UseFormWatch<FormData>,
  handleSubmit: UseFormHandleSubmit<FormData, undefined>,
  resetPage: () => void,
): FormData {
  const isMount = useIsMount();
  const [data, setData] = useState<FormData>({} as FormData);
  const {
    search,
    agreementStage,
    partyB,
    type,
    status,
    relationshipType,
    roles,
    tags,
    startDateFrom,
    startDateTo,
    agreementTransactionType,
    daysRange,
  } = watch();

  useEffect(() => {
    handleSubmit((formData) => {
      if (isMount) return;
      setData({ ...formData });
      if (isDataLoaded) {
        resetPage();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    search,
    agreementStage,
    partyB,
    type,
    status,
    relationshipType,
    roles,
    tags,
    startDateFrom,
    startDateTo,
    agreementTransactionType,
    daysRange,
  ]);

  return data;
}

function DealRotorList() {
  const tenantTimezoneText = useSelector(tenantTimezoneInBracketsSelector);

  const { hasUserPermission } = useUserPermission();
  const isAllowedDealRotorCreate = hasUserPermission(PermissionNames.DealRotorCreate);
  const isAllowedOrganisationRead = hasUserPermission(PermissionNames.OrganisationRead);
  const isAllowedOrganisationPartyBSummaryRead = hasUserPermission(PermissionNames.OrganisationPartyBSummaryRead);

  const [isFiltersInitialized, setIsFiltersInitialized] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false); // Used to enable reset paging
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const { setPersistPageParams } = usePersistPageParams<DealRotorListPageParams>(DEAL_ROTOR_LIST_PAGE_KEY);

  const { register, control, formState, setValue, getValues, watch, handleSubmit } = useForm<FormData>({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      extraColumns: [],
      partyB: [],
      roles: [],
      tags: [],
    },
  });
  const startDateFromRaw = watch('startDateFrom');
  const extraColumnsRaw = watch('extraColumns');

  const columns = useMemo(
    () => [
      columnHelper.accessor('partyB', {
        id: 'partyB',
        header: 'Party B',
        enableSorting: false,
        meta: { isAlwaysVisible: true },
        cell: ({ getValue }) => {
          const [firstOrganisation, ...restOrganisations] = getValue() || [];
          return (
            <div className="hstack gap-2">
              {firstOrganisation && (
                <>
                  {isAllowedOrganisationRead ? (
                    <Link
                      className="link-dark fw-medium"
                      to={generatePath(RoutePath.partyBManagementById, { organisationId: firstOrganisation.id })}
                    >
                      {firstOrganisation.name}
                    </Link>
                  ) : (
                    <span>{firstOrganisation.name}</span>
                  )}
                </>
              )}

              {restOrganisations.length > 0 && (
                <OverlayTrigger
                  placement="bottom"
                  rootClose={true}
                  trigger={['click']}
                  overlay={
                    <Popover className=" mb-3">
                      <ul className="dropdown-menu show position-relative border-0 bg-transparent">
                        {restOrganisations.map(({ name, id }) => (
                          <li key={id}>
                            {isAllowedOrganisationRead ? (
                              <Link
                                className="dropdown-item"
                                to={generatePath(RoutePath.partyBManagementById, { organisationId: id })}
                              >
                                {name}
                              </Link>
                            ) : (
                              <span className="dropdown-item">{name}</span>
                            )}
                          </li>
                        ))}
                      </ul>
                    </Popover>
                  }
                >
                  <button
                    type="button"
                    className="btn btn-link fs-14 lh-1 fw-normal text-nowrap p-0"
                  >{`+${restOrganisations.length} more`}</button>
                </OverlayTrigger>
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor('name', {
        id: 'name',
        header: 'Deal name',
        meta: { isAlwaysVisible: true },
      }),
      columnHelper.accessor('number', {
        id: 'number',
        header: 'Deal number',
        meta: { isAlwaysVisible: true },
        cell: ({ row, getValue }) => {
          const to =
            row.original.status !== DealRotorStatus.Inactive
              ? generatePath(RoutePath.dealRotorEditDetailedDealRotorDetails, { dealRotorId: row.original.id })
              : generatePath(RoutePath.dealRotorCreateDetails, { dealRotorId: row.original.id });
          return <Link to={to}>{getValue()}</Link>;
        },
      }),
      columnHelper.accessor('relationshipType', {
        id: 'relationshipType',
        header: 'Deal rel. type',
        meta: { isAlwaysVisible: true },
      }),
      columnHelper.accessor('agreementStage', {
        id: 'agreementStage',
        header: 'Agreement status',
        meta: { isAlwaysVisible: true },
        cell: ({ row }) => {
          const { agreementStage, agreementState, agreementStatus } = row.original;
          const fullStatus = getAgreementFullStatus(agreementStage, agreementState, agreementStatus);
          return (
            <div className="hstack gap-2">
              <Dot color={agreementStage ? agreementStageColorMap[agreementStage] : 'metallic-silver'} />
              <span>{fullStatus}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor('userRoles', {
        id: 'userRoles',
        header: 'User roles',
        enableSorting: false,
        meta: { isAlwaysVisible: true },
        cell: ({ getValue }) => {
          const userRoles = getValue();
          return userRoles && userRoles.length
            ? userRoles.map((role) => Converter.stripUpperToCapitalizedFirst(role)).join(', ')
            : 'N/A';
        },
      }),
      columnHelper.accessor('updatedDate', {
        id: 'updatedDate',
        header: 'Last update',
        meta: { isAlwaysVisible: true },
        cell: ({ getValue }) => <FormatDate date={getValue()} formatValue="dd/MM/yyyy - HH:mm:ss" />,
      }),
      columnHelper.accessor('status', {
        id: 'status',
        header: 'Deal rotor status',
        cell: ({ getValue }) => {
          const status = getValue();
          return (
            <div className="hstack gap-2 ms-auto">
              <Dot color={dealRotorStatusColorMap[status]} />
              <span>{status}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor('type', {
        id: 'type',
        header: 'Deal Type',
      }),
      columnHelper.accessor('startDate', {
        id: 'startDate',
        header: 'Deal Rotors activation date',
        cell: ({ getValue }) => {
          const startDate = getValue();
          return startDate ? <FormatDate date={startDate} formatValue="dd/MM/yyyy - HH:mm:ss" /> : 'N/A';
        },
      }),
      columnHelper.accessor('agreementStartDate', {
        id: 'agreementStartDate',
        header: 'Agreement date from',
        cell: ({ getValue }) => {
          const agreementStartDate = getValue();
          return agreementStartDate ? (
            <FormatDate date={agreementStartDate} formatValue="dd/MM/yyyy - HH:mm:ss" />
          ) : (
            'N/A'
          );
        },
      }),
      columnHelper.accessor('agreementEndDate', {
        id: 'agreementEndDate',
        header: 'Agreement date to',
        cell: ({ getValue }) => {
          const agreementEndDate = getValue();
          return agreementEndDate ? <FormatDate date={agreementEndDate} formatValue="dd/MM/yyyy - HH:mm:ss" /> : 'N/A';
        },
      }),
      columnHelper.accessor('agreementAttributeBOVE', {
        id: 'agreementAttributeBOVE',
        header: 'BOVE type',
        enableSorting: false,
        cell: ({ getValue }) => {
          const bove = getValue();
          return bove ? Converter.stripUpperToCapitalizedFirst(bove) : 'N/A';
        },
      }),
      columnHelper.accessor('agreementAttributeValue', {
        id: 'agreementAttributeValue',
        header: 'Agreement Value',
        enableSorting: false,
        cell: ({ row }) => {
          const { agreementAttributeValue, agreementAttributeCurrencyCode } = row.original;
          return agreementAttributeValue
            ? `${Intl.NumberFormat().format(agreementAttributeValue)} ${agreementAttributeCurrencyCode}`
            : 'N/A';
        },
      }),
      columnHelper.accessor('tags', {
        id: 'tags',
        header: 'Tags',
        enableSorting: false,
        cell: ({ getValue }) => {
          const tags = getValue();
          return tags && tags.length ? tags.join(', ') : 'N/A';
        },
      }),
    ],
    [isAllowedOrganisationRead],
  );

  const { pagination, setPagination, sorting, setSorting, columnOrder, setColumnOrder, resetPage } =
    useTableChangeParams({ columns });
  const {
    search,
    agreementStage,
    partyB,
    type,
    status,
    relationshipType,
    roles,
    tags,
    startDateFrom,
    startDateTo,
    agreementTransactionType,
    daysRange,
  } = useFormattedWatch(isDataLoaded, watch, handleSubmit, resetPage);

  const initialFilters = useUrlQueryFilters(
    {
      search,
      agreementStage,
      partyB,
      type,
      status,
      relationshipType,
      roles,
      tags,
      startDateFrom,
      startDateTo,
      agreementTransactionType,
      daysRange,
      extraColumns: extraColumnsRaw,
      columnOrder,
    },
    { pagination, sorting },
  );

  useEffect(() => {
    if (initialFilters.search) {
      setValue('search', initialFilters.search);
    }
    if (initialFilters.agreementStage) {
      setValue('agreementStage', initialFilters.agreementStage);
    }
    if (initialFilters.partyB) {
      setValue('partyB', Array.isArray(initialFilters.partyB) ? initialFilters.partyB : [initialFilters.partyB]);
    }
    if (initialFilters.type) {
      setValue('type', initialFilters.type);
    }
    if (initialFilters.status) {
      setValue('status', initialFilters.status);
    }
    if (initialFilters.relationshipType) {
      setValue('relationshipType', initialFilters.relationshipType);
    }
    if (initialFilters.roles) {
      setValue('roles', Array.isArray(initialFilters.roles) ? initialFilters.roles : [initialFilters.roles]);
    }
    if (initialFilters.tags) {
      setValue('tags', Array.isArray(initialFilters.tags) ? initialFilters.tags : [initialFilters.tags]);
    }
    if (initialFilters.startDateFrom) {
      setValue('startDateFrom', initialFilters.startDateFrom);
    }
    if (initialFilters.startDateTo) {
      setValue('startDateTo', initialFilters.startDateTo);
    }
    if (initialFilters.agreementTransactionType && initialFilters.daysRange) {
      setValue('agreementTransactionType', initialFilters.agreementTransactionType);
      setValue('daysRange', initialFilters.daysRange);
    }
    if (initialFilters.extraColumns) {
      setValue(
        'extraColumns',
        Array.isArray(initialFilters.extraColumns) ? initialFilters.extraColumns : [initialFilters.extraColumns],
      );
    }

    if (initialFilters.columnOrder) {
      setColumnOrder(
        Array.isArray(initialFilters.columnOrder) ? initialFilters.columnOrder : [initialFilters.columnOrder],
      );
    }
    if (initialFilters.pagination) {
      setPagination(initialFilters.pagination);
    }

    if (initialFilters.sorting) {
      setSorting(initialFilters.sorting);
    }

    // 1ms timeout to get rid of 2 requests
    setTimeout(() => {
      setIsFiltersInitialized(true);
    }, 1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFilters]);

  useEffect(() => {
    if (!isFiltersInitialized) return;
    setPersistPageParams({
      pageSize: pagination.pageSize,
      extraColumns: extraColumnsRaw,
      columnOrder,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.pageSize, extraColumnsRaw, columnOrder]);

  const { columnOptions, columnVisibilityInitial } = useMemo(() => {
    const columnOptions: SelectOption[] = [];
    const columnVisibilityInitial: VisibilityState = {};

    columns.forEach(({ id = '', header = '', meta = {} }) => {
      const { isAlwaysVisible = false } = meta as any;

      if (!isAlwaysVisible) {
        columnOptions.push({ label: header as string, value: id });
      }

      columnVisibilityInitial[id] = isAlwaysVisible;
    });

    return { columnOptions, columnVisibilityInitial };
  }, [columns]);

  const columnVisibility = useMemo<VisibilityState>(
    () =>
      extraColumnsRaw && extraColumnsRaw.length
        ? {
            ...columnVisibilityInitial,
            ...extraColumnsRaw.reduce<VisibilityState>((acc, key) => ({ ...acc, [key]: true }), {}),
          }
        : columnVisibilityInitial,
    [columnVisibilityInitial, extraColumnsRaw],
  );

  const {
    isFetching,
    data: paginatedData,
    error: paginatedDataError,
  } = useQuery({
    enabled: isFiltersInitialized,
    queryKey: [
      'getDealRotorListSearch',
      search,
      pagination,
      sorting,
      agreementStage,
      partyB,
      type,
      status,
      relationshipType,
      roles,
      tags,
      startDateFrom,
      startDateTo,
      agreementTransactionType,
      daysRange,
    ],
    async queryFn({ signal }) {
      const config = { signal };
      const startDateFromStartDate = startDateFrom ? new LocalDate(startDateFrom).getStartDay().toJSON() : undefined;
      const startDateToEndDate = startDateTo ? new LocalDate(startDateTo).getEndDay().toJSON() : undefined;
      const params = {
        ...new RequestSearchParamsAdapter({ search, pagination, sorting }),
        agreementStage,
        partyB,
        type,
        status,
        relationshipType,
        roles,
        tags,
        startDateFrom: startDateFromStartDate,
        startDateTo: startDateToEndDate,
        agreementTransactionType,
        daysRange,
      };
      const res = await getDealRotorListSearch(params, config);
      return res.data;
    },
    placeholderData: keepPreviousData,
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 3 * 60 * 1000, // 3 min
  });

  const { data, pageCount, total } = useMemo(
    () => ({
      data: paginatedData?.items ?? [],
      pageCount: paginatedData?.total ? Math.ceil(paginatedData.total / pagination.pageSize) : 1,
      total: paginatedData?.total ?? 0,
    }),
    [paginatedData, pagination.pageSize],
  );

  const organisationStatus = OrganisationStatus.Active;
  const {
    isLoading: isPartyBSummaryLoading,
    data: partyBSummary,
    error: partyBSummaryError,
  } = useQuery({
    queryKey: ['getOrganisationPartyBListSummary', organisationStatus],
    async queryFn({ signal }) {
      if (!isAllowedOrganisationPartyBSummaryRead) return [];
      const config = { signal };
      const res = await getOrganisationPartyBListSummary(organisationStatus, config);
      return res.data;
    },
    initialData: [],
    retry: false,
    refetchOnWindowFocus: false,
  });
  const partyBOptions = useMemo<SelectOption[]>(
    () =>
      partyBSummary
        .toSorted((a, b) => a.name.localeCompare(b.name))
        .map(({ id, name }) => ({ label: name, value: id })),
    [partyBSummary],
  );

  const {
    isLoading: isTagsOptionsLoading,
    data: tagsOptions,
    error: tagsOptionsError,
  } = useQuery({
    queryKey: ['getDealRotorTagList'],
    async queryFn({ signal }): Promise<SelectOption[]> {
      const config = { signal };
      const res = await getDealRotorTagList(config);
      return res.data.map(({ id, name }) => ({ label: name, value: id }));
    },
    initialData: [],
    retry: false,
    refetchOnWindowFocus: false,
  });

  const errorMessages = useMemo<ServerFormErrors>(() => {
    const paginatedDataErrorMessages = new ServerErrorAdapter(paginatedDataError as ServerError).combine();
    const partyBOptionsErrorMessages = new ServerErrorAdapter(partyBSummaryError as ServerError).combine();
    const tagsOptionsMessages = new ServerErrorAdapter(tagsOptionsError as ServerError).combine();
    return [...paginatedDataErrorMessages, ...partyBOptionsErrorMessages, ...tagsOptionsMessages];
  }, [paginatedDataError, partyBSummaryError, tagsOptionsError]);

  const activeFilters = useMemo<ActiveFilter[]>(
    () => [
      { name: 'search', title: 'Search', value: search },
      {
        name: 'agreementStage',
        title: 'Agreement stage',
        value: agreementStage,
        options: agreementStageOptions,
      },
      { name: 'partyB', title: 'Party B', value: partyB, options: partyBOptions },
      { name: 'type', title: 'Deal rotor type', value: type, options: dealRotorTypeOptions },
      { name: 'status', title: 'Deal rotor status', value: status, options: dealRotorStatusOptions },
      {
        name: 'relationshipType',
        title: 'Deal relationship type',
        value: relationshipType,
        options: dealRotorRelationshipTypeOptions,
      },
      { name: 'roles', title: 'Roles', value: roles, options: roleNameOptions },
      { name: 'tags', title: 'Tags', value: tags, options: tagsOptions },
      { name: 'startDateFrom', title: 'Start date from', value: startDateFrom },
      { name: 'startDateTo', title: 'Start date to', value: startDateTo },
      {
        name: 'agreementTransactionType',
        title: 'Agreement transaction type',
        value:
          agreementTransactionType === AgreementTransactionType.AwaitingSignature
            ? `Agreements that ${Converter.stripUpperToCapitalizedFirst(agreementTransactionType).toLowerCase()}`
            : agreementTransactionType &&
              `Agreements that ${Converter.stripUpperToCapitalizedFirst(
                agreementTransactionType,
              ).toLowerCase()} in ${daysRange} days`,
        options: transactionTypeOptions,
      },
    ],
    [
      search,
      agreementStage,
      partyB,
      type,
      status,
      relationshipType,
      roles,
      tags,
      startDateFrom,
      startDateTo,
      partyBOptions,
      tagsOptions,
      agreementTransactionType,
      daysRange,
    ],
  );

  useEffect(() => {
    if (paginatedData) {
      setIsDataLoaded(true);
    }
  }, [paginatedData]);

  useEffect(() => {
    // Keep in mind that data can be loaded from the cache
    if (isFiltersInitialized && isDataLoaded) {
      resetPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting]);

  useEffect(() => {
    register('agreementTransactionType', undefined);
    register('daysRange', undefined);
  }, [register]);

  return (
    <div className="h-100">
      <header className="d-flex flex-wrap align-items-center justify-content-between gap-3 mb-4">
        <h1 className="h5">Deal rotors</h1>
        {isAllowedDealRotorCreate && (
          <Link className="btn btn-primary text-nowrap hstack gap-2" to={RoutePath.dealRotorCreate}>
            <span>Create deal rotor</span>
            <PlusIcon />
          </Link>
        )}
      </header>
      <ServerErrorMessages messages={errorMessages} />
      <div className="bg-white border rounded-top border-bottom-0">
        <form name="dealRotorFilters" noValidate>
          <div className="p-3">
            <div className="row g-3">
              <Field
                className="col-sm-6 col-md-4 col-lg-4 col-xl-3"
                field="input"
                type="search"
                placeholder="Search"
                autoComplete="off"
                register={register('search')}
                control={control}
                formSchema={formSchema}
                errors={formState.errors}
              />
              <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                <button
                  type="button"
                  className={classNames(styles.FilterBtn, 'btn btn-outline-primary btn-sq', isFilterOpen && 'active')}
                  onClick={() => setIsFilterOpen((state) => !state)}
                >
                  <FilterIcon />
                </button>
              </div>
              <Field
                className="col-sm-6 col-md-4 col-lg-4 col-xl-3"
                field="dropdown"
                placeholder={
                  (
                    <div className="hstack gap-2">
                      <Dot color="metallic-silver" />
                      <span>All</span>
                    </div>
                  ) as any
                }
                autoComplete="off"
                register={register('agreementStage')}
                control={control}
                formSchema={formSchema}
                errors={formState.errors}
                options={agreementStageOptions}
                dropdownProps={{
                  isSearchable: false,
                  isClearable: true,
                }}
              />
              <Field
                className="col-sm-6 col-md-4 col-lg-4 col-xl-3"
                inputClassName={styles.ColumnDropdown}
                field="dropdown"
                placeholder="Column"
                autoComplete="off"
                register={register('extraColumns')}
                control={control}
                formSchema={formSchema}
                errors={formState.errors}
                options={columnOptions}
                multiple
                dropdownProps={{
                  isSearchable: false,
                  isClearable: true,
                }}
              />
            </div>
          </div>
          <Collapse in={isFilterOpen}>
            <div>
              <div className="p-3 border-top">
                <div className="row g-3 mb-3">
                  <Field
                    className="col-sm-6 col-md-4 col-lg-4 col-xl-3"
                    field="dropdown"
                    label="Party B"
                    placeholder="Choose party B"
                    autoComplete="off"
                    register={register('partyB')}
                    control={control}
                    formSchema={formSchema}
                    errors={formState.errors}
                    options={partyBOptions}
                    dropdownProps={{
                      isLoading: isPartyBSummaryLoading,
                      isSearchable: true,
                      isClearable: true,
                    }}
                    multiple
                  />
                  <Field
                    className="col-sm-6 col-md-4 col-lg-4 col-xl-3"
                    field="dropdown"
                    label="Deal rotor type"
                    placeholder="Choose deal rotor type"
                    autoComplete="off"
                    register={register('type')}
                    control={control}
                    formSchema={formSchema}
                    errors={formState.errors}
                    options={dealRotorTypeOptions}
                    dropdownProps={{
                      isLoading: isPartyBSummaryLoading,
                      isSearchable: true,
                      isClearable: true,
                    }}
                  />
                  <Field
                    className="col-sm-6 col-md-4 col-lg-4 col-xl-3"
                    field="dropdown"
                    label="Deal rotor status"
                    placeholder="Choose deal rotor status"
                    autoComplete="off"
                    register={register('status')}
                    control={control}
                    formSchema={formSchema}
                    errors={formState.errors}
                    options={dealRotorStatusOptions}
                    dropdownProps={{
                      isLoading: isPartyBSummaryLoading,
                      isSearchable: true,
                      isClearable: true,
                    }}
                  />
                </div>
                <div className="row g-3 mb-3">
                  <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                    <div className="vstack gap-3">
                      <Field
                        field="dropdown"
                        label="Deal relationship type"
                        placeholder="Choose deal relationship type"
                        autoComplete="off"
                        register={register('relationshipType')}
                        control={control}
                        formSchema={formSchema}
                        errors={formState.errors}
                        options={dealRotorRelationshipTypeOptions}
                        dropdownProps={{
                          isSearchable: true,
                          isClearable: true,
                        }}
                      />

                      <Field
                        field="datepicker"
                        label={`Start date from${tenantTimezoneText}`}
                        autoComplete="off"
                        register={register('startDateFrom')}
                        control={control}
                        formSchema={formSchema}
                        errors={formState.errors}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                    <div className="vstack gap-3">
                      <Field
                        field="dropdown"
                        label="Roles"
                        placeholder="Choose roles"
                        autoComplete="off"
                        register={register('roles')}
                        control={control}
                        formSchema={formSchema}
                        errors={formState.errors}
                        options={roleNameOptions}
                        dropdownProps={{
                          isSearchable: true,
                          isClearable: true,
                        }}
                        multiple
                      />

                      <Field
                        field="datepicker"
                        label={`Start date to${tenantTimezoneText}`}
                        autoComplete="off"
                        register={register('startDateTo')}
                        control={control}
                        formSchema={formSchema}
                        errors={formState.errors}
                        datepickerProps={{ minDate: startDateFromRaw ? new Date(startDateFromRaw) : null }}
                      />
                    </div>
                  </div>

                  <Field
                    className="col-sm-6 col-md-4 col-lg-4 col-xl-3"
                    field="dropdown"
                    label="Tags"
                    placeholder="Choose tags"
                    autoComplete="off"
                    register={register('tags')}
                    control={control}
                    formSchema={formSchema}
                    errors={formState.errors}
                    options={tagsOptions}
                    dropdownProps={{
                      isLoading: isTagsOptionsLoading,
                      isSearchable: true,
                      isClearable: true,
                    }}
                    multiple
                  />
                </div>
                <div className="row g-3"></div>
              </div>
            </div>
          </Collapse>
        </form>
        <ActiveFilterList filters={activeFilters} total={total} setValue={setValue} getValues={getValues} />
      </div>
      <Table
        className="table-border-bottom-none mb-0"
        wrapperClassName="border rounded-bottom border-top-0 mb-3"
        data={data}
        total={total}
        columns={columns}
        columnVisibility={columnVisibility}
        isLoading={isFetching}
        pageCount={pageCount}
        pagination={pagination}
        setPagination={setPagination}
        sorting={sorting}
        setSorting={setSorting}
        columnOrder={columnOrder}
        setColumnOrder={setColumnOrder}
      />
    </div>
  );
}

export default DealRotorList;
