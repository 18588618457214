import qs from 'qs';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useIsMount from './useIsMount';
import { PaginationState, SortingState } from '@tanstack/react-table';

type TableParams = {
  pagination?: PaginationState;
  sorting?: SortingState;
};

function useUrlQueryFilters<F extends Record<string, any>, T extends TableParams>(filters: F, tableParams?: T) {
  const location = useLocation();
  const navigate = useNavigate();
  const isMount = useIsMount();
  const [initialFilters] = useState<F & Partial<T>>(() => {
    const { pageIndex, pageSize, orderField, orderBy, ...result } = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }) as any;

    if (pageIndex !== undefined || pageSize !== undefined) {
      Object.assign(result, {
        pagination: {
          pageIndex: isNaN(pageIndex) ? 0 : Number(pageIndex),
          pageSize: isNaN(pageSize) ? 10 : Number(pageSize),
        },
      });
    }

    if (orderField && orderBy) {
      Object.assign(result, { sorting: [{ id: orderField, desc: orderBy === '1' }] });
    }
    return result;
  });

  const pageIndex = tableParams?.pagination?.pageIndex;
  const pageSize = tableParams?.pagination?.pageSize;
  const orderField = tableParams?.sorting?.[0]?.id;
  const orderBy =
    tableParams?.sorting?.[0]?.desc === true ? 1 : tableParams?.sorting?.[0]?.desc === false ? 0 : undefined;

  useEffect(() => {
    if (isMount) return;
    navigate(
      { search: qs.stringify({ ...filters, pageIndex, pageSize, orderField, orderBy }, { indices: false }) },
      { replace: true },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...Object.values(filters), pageIndex, pageSize, orderField, orderBy]);

  return initialFilters;
}

export default useUrlQueryFilters;
