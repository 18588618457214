import classNames from 'classnames';
import { Spinner } from 'react-bootstrap';

import { useSelector } from 'src/store';
import { userIdSelector } from 'src/store/selectors/profileSelector';

import AnswerSource from './AnswerSource';

import { ChatMessageModel, GlobalChatMessageModel } from 'src/types/chat';

import styles from './AIConsoleChat.message.module.scss';

type Props = {
  msg: ChatMessageModel | GlobalChatMessageModel;
};

function AIConsoleChatMessage({ msg }: Props) {
  const userId = useSelector(userIdSelector);

  const isOwnMessage = msg.senderId === userId;

  return (
    <div
      className={classNames({
        'text-end': isOwnMessage,
      })}
    >
      <span
        className={classNames(
          'd-inline-block border rounded-top p-3',
          styles.MessageContent,
          isOwnMessage ? 'rounded-start' : 'rounded-end',
          {
            'is-own-message': isOwnMessage,
            'has-error': !!msg.errors,
          },
        )}
      >
        {msg.textMessage}
        <AnswerSource sourceDocuments={msg.sourceDocuments} />
        {msg.errors && msg.errors.length && (
          <ul className="list-unstyled text-danger fs-12 mb-0" role="alert" aria-live="assertive">
            {msg.errors.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        )}

        {msg.isLoading && (
          <div className="pt-1">
            <Spinner variant="primary" size="sm" />
          </div>
        )}
      </span>
    </div>
  );
}

export default AIConsoleChatMessage;
