import classNames from 'classnames';
import { PropsWithChildren } from 'react';

import styles from './AuthLayout.module.scss';

function AuthLayout({ children }: PropsWithChildren) {
  return (
    <div className={classNames(styles.Root, 'd-flex justify-content-center align-items-center py-4')}>{children}</div>
  );
}

export default AuthLayout;
