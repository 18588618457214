import classNames from 'classnames';
import { useState, useMemo, useId, MouseEventHandler, useEffect } from 'react';
import { useWatch, Controller, UseFormRegisterReturn } from 'react-hook-form';
import TagsInput from 'react-tagsinput';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import Select, {
  components,
  Props as ReactSelectProps,
  MultiValueProps,
  MultiValueRemoveProps,
  MultiValueGenericProps,
  ActionMeta,
} from 'react-select';
import AsyncCreatableSelect, { AsyncCreatableProps } from 'react-select/async-creatable';
import { closestCenter, DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import { arrayMove, horizontalListSortingStrategy, SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import FieldErrorMessage from './FieldErrorMessage';
import { ChevronDownIcon } from '../icons';

import Converter from 'src/utils/Converter';
import LocalDate from 'src/utils/LocalDate';
import nth from 'src/utils/nth';
import { testIsFieldIsRequired } from 'src/utils/yupHelpers';

import { SelectOption } from 'src/types';

import styles from './Field.module.scss';
import imagePlaceholder from 'src/assets/images/add-circle-btn.svg';

export type Props = {
  className?: string;
  inputClassName?: string;
  inputGroupClassName?: string;
  labelClassName?: string;
  formCheckClassName?: string;
  field:
    | 'input'
    | 'textarea'
    | 'image'
    | 'document'
    | 'dropdown'
    | 'dropdown-multi-sort'
    | 'dropdown-async-creatable'
    | 'datepicker'
    | 'tags'
    | 'switch';
  label?: JSX.Element | string;
  type?: 'text' | 'search' | 'number' | 'email' | 'password' | 'file' | 'tel' | 'url' | 'checkbox' | 'radio';
  options?: SelectOption[];
  IconBefore?: () => JSX.Element;
  IconAfter?: () => JSX.Element;
  url?: string | null;
  register: UseFormRegisterReturn;
  control: any;
  formSchema: any;
  errors?: any;
  disabled?: boolean;
  dropdownProps?: Partial<Omit<ReactSelectProps<any, any, any>, 'onChange' | 'onBlur' | 'options'>>;
  dropdownAsyncCreatableProps?: Partial<Omit<AsyncCreatableProps<any, any, any>, 'onChange' | 'onBlur'>>;
  datepickerProps?: Partial<Omit<ReactDatePickerProps, 'onChange' | 'onBlur'>>;
  Before?: JSX.Element;
  After?: JSX.Element;
  BeforeLabel?: JSX.Element;
  AfterLabel?: JSX.Element;
  onClearOptions?: (removedOption: SelectOption[] | []) => void;
};

export type LabelProps = {
  id: string;
  label?: JSX.Element | string;
  staticLabelClassName?: string;
  labelClassName?: string;
  isRequired: boolean;
  BeforeLabel: JSX.Element | undefined;
  AfterLabel: JSX.Element | undefined;
};
function Label({
  id,
  label,
  staticLabelClassName: mainLabelClassName = 'form-label',
  labelClassName,
  isRequired,
  BeforeLabel,
  AfterLabel,
}: LabelProps) {
  return label ? (
    <label className={classNames(mainLabelClassName, labelClassName)} htmlFor={id}>
      {BeforeLabel}
      {label}
      {isRequired && <sup className="text-danger">*</sup>}
      {AfterLabel}
    </label>
  ) : null;
}

type FieldInputDefaultProps = Pick<
  Props,
  'inputClassName' | 'inputGroupClassName' | 'labelClassName' | 'type' | 'label' | 'register'
> & {
  id: string;
  isRequired: boolean;
  isEmpty: boolean;
  hasError: boolean;
  props: Omit<React.InputHTMLAttributes<any>, 'type'>;
  BeforeLabel: JSX.Element | undefined;
  AfterLabel: JSX.Element | undefined;
};
function FieldInputDefault({
  inputClassName,
  inputGroupClassName,
  labelClassName,
  type,
  label,
  BeforeLabel,
  AfterLabel,
  register,
  id,
  isRequired,
  isEmpty,
  hasError,
  props,
}: FieldInputDefaultProps) {
  return (
    <>
      <Label
        labelClassName={labelClassName}
        id={id}
        label={label}
        isRequired={isRequired}
        BeforeLabel={BeforeLabel}
        AfterLabel={AfterLabel}
      />
      <div className={classNames('input-group', inputGroupClassName)}>
        <input
          className={classNames('form-control', inputClassName, { 'border-danger': hasError })}
          aria-invalid={hasError}
          id={id}
          type={type}
          data-is-empty={isEmpty}
          {...props}
          {...register}
        />
      </div>
    </>
  );
}

type FieldInputFileProps = Pick<Props, 'inputClassName' | 'labelClassName' | 'label' | 'register' | 'control'> & {
  id: string;
  isRequired: boolean;
  hasError: boolean;
  props: Omit<React.InputHTMLAttributes<any>, 'type'>;
  BeforeLabel: JSX.Element | undefined;
  AfterLabel: JSX.Element | undefined;
};
function FieldInputFile({
  inputClassName,
  labelClassName,
  label,
  BeforeLabel,
  AfterLabel,
  register,
  control,
  id,
  isRequired,
  hasError,
  props,
}: FieldInputFileProps) {
  const [fileName, setFileName] = useState('');
  const [isDragOverInput, setIsDragOverInput] = useState(false);
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    register.onChange(event);

    // Cleanup
    setFileName('');

    if (!event.target.files || !event.target.files.length) return;

    const file = event.target.files[0];
    setFileName(file.name);
  };
  const onClick = ({ nativeEvent }: any) => {
    // Prohibit default click behavior on <input type"file" /> directly for Firefox || Chrome
    if (nativeEvent.explicitOriginalTarget ? nativeEvent.explicitOriginalTarget.id === id : nativeEvent.detail) {
      nativeEvent.preventDefault();
    }
  };

  const value = useWatch({ control, name: register.name });

  useEffect(() => {
    if (!value) {
      setFileName('');
      (document as any).getElementById(id).value = '';
    }
  }, [value, id]);

  return (
    <>
      <div
        className={classNames(styles.FieldInputFile, inputClassName, 'd-flex flex-column align-items-center p-4', {
          'border-danger': hasError,
          'is-drag-over-input': isDragOverInput,
        })}
      >
        <input
          id={id}
          aria-invalid={hasError}
          type="file"
          {...props}
          {...register}
          onChange={onChange}
          onClick={onClick}
          onDragEnter={() => setIsDragOverInput(true)}
          onDragLeave={() => setIsDragOverInput(false)}
          onDrop={() => setIsDragOverInput(false)}
        />
        <Label
          staticLabelClassName="btn btn-outline-primary"
          labelClassName={labelClassName}
          id={id}
          label={label || 'Select file'}
          isRequired={isRequired}
          BeforeLabel={BeforeLabel}
          AfterLabel={AfterLabel}
        />
        <span className={classNames('fw-medium mt-3')}>{fileName || 'Or drop file here'}</span>
        {props.placeholder && <span className="mt-3">{props.placeholder}</span>}
      </div>
    </>
  );
}

type FieldImageProps = Pick<Props, 'inputClassName' | 'labelClassName' | 'label' | 'register' | 'control' | 'url'> & {
  id: string;
  isRequired: boolean;
  hasError: boolean;
  props: React.InputHTMLAttributes<any>;
  BeforeLabel: JSX.Element | undefined;
  AfterLabel: JSX.Element | undefined;
};
function FieldImage({
  inputClassName,
  labelClassName,
  label,
  BeforeLabel,
  AfterLabel,
  register,
  control,
  url,
  id,
  isRequired,
  hasError,
  props,
}: FieldImageProps) {
  const [base64Image, setBase64Image] = useState<string>('');
  const [fileName, setFileName] = useState('');
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    register.onChange(event);
    // Cleanup
    setFileName('');
    setBase64Image('');

    if (!event.target.files || !event.target.files.length) return;

    const file = event.target.files[0];
    const currentFileName = file.name.slice(file.name.lastIndexOf('.'));
    setFileName(currentFileName);

    if (file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setBase64Image(e.target?.result?.toString() ?? '');
      };
      reader.readAsDataURL(file);
    }
  };

  const value = useWatch({ control, name: register.name });

  useEffect(() => {
    if (!value) {
      setFileName('');
      (document as any).getElementById(id).value = '';
    }
  }, [value, id]);

  return (
    <>
      <Label
        labelClassName={labelClassName}
        id={id}
        label={label}
        isRequired={isRequired}
        BeforeLabel={BeforeLabel}
        AfterLabel={AfterLabel}
      />
      <div className={classNames(styles.FieldImage, inputClassName, { disabled: props.disabled }, 'd-flex')}>
        <div className={classNames('image-holder flex-shrink-0', { 'border-danger': hasError })}>
          <input type="file" id={id} {...props} {...register} onChange={onChange} />
          {!props.disabled || base64Image || url ? (
            <img src={base64Image || url || imagePlaceholder} alt={fileName} />
          ) : (
            <span className="empty-image"></span>
          )}
        </div>
        {props.placeholder && !props.disabled && (
          <span className="placeholder-text fs-14 ms-3">{props.placeholder}</span>
        )}
      </div>
    </>
  );
}

type FieldDocumentProps = Pick<
  Props,
  'inputClassName' | 'labelClassName' | 'label' | 'register' | 'url' | 'IconBefore' | 'IconAfter'
> & {
  id: string;
  isRequired: boolean;
  hasError: boolean;
  props: React.InputHTMLAttributes<any>;
};
function FieldDocument({
  inputClassName,
  labelClassName,
  label,
  register,
  url,
  IconBefore,
  IconAfter,
  id,
  isRequired,
  hasError,
  props,
}: FieldDocumentProps) {
  const [fileName, setFileName] = useState('');
  const [fileSize, setFileSize] = useState<number | null>(null);

  const fileHeader = useMemo(() => {
    if (fileName) return fileName;
    if (url) return url;
    return 'Document yet to be attached';
  }, [fileName, url]);

  const fileExtra = useMemo(() => {
    if (fileSize !== null) return `(${Converter.formatBytes(fileSize)})`;
    if (url) return <>&nbsp;</>;
    return props.placeholder;
  }, [fileSize, props.placeholder, url]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    register.onChange(event);
    // Cleanup
    setFileName('');
    setFileSize(null);

    if (!event.target.files || !event.target.files.length) return;

    const file = event.target.files[0];
    setFileName(file.name);
    setFileSize(file.size);
  };
  return (
    <div className={(styles.FieldDocument, 'position-relative hstack gap-3')}>
      <input
        className={classNames(inputClassName, 'visually-hidden')}
        type="file"
        id={id}
        {...props}
        {...register}
        onChange={onChange}
      />
      <label
        className={classNames(labelClassName, 'btn btn-outline-primary hstack gap-2', {
          'border-danger': hasError,
          'text-danger': hasError,
        })}
        htmlFor={id}
      >
        {IconBefore && <IconBefore />}
        <span>{label}</span>
        {IconAfter && <IconAfter />}
      </label>
      <div className="text-secondary fs-14">
        <div className="fw-medium">
          {fileHeader}
          {isRequired && <sup className="text-danger">*</sup>}
        </div>
        <div>{fileExtra}</div>
      </div>
    </div>
  );
}

type FieldInputCheckboxProps = Pick<Props, 'inputClassName' | 'labelClassName' | 'label' | 'register'> & {
  formCheckClassName: string | undefined;
  id: string;
  isRequired: boolean;
  hasError: boolean;
  props: Omit<React.InputHTMLAttributes<any>, 'type'>;
  BeforeLabel: JSX.Element | undefined;
  AfterLabel: JSX.Element | undefined;
};
function FieldInputCheckbox({
  inputClassName,
  labelClassName,
  formCheckClassName,
  label,
  BeforeLabel,
  AfterLabel,
  register,
  id,
  isRequired,
  hasError,
  props,
}: FieldInputCheckboxProps) {
  return (
    <div className={classNames('form-check mb-0', formCheckClassName)}>
      <input
        className={classNames('form-check-input', inputClassName, { 'border-danger': hasError })}
        aria-invalid={hasError}
        type="checkbox"
        id={id}
        {...props}
        {...register}
      />
      <Label
        staticLabelClassName={classNames('form-check-label', { 'text-danger': hasError })}
        labelClassName={labelClassName}
        id={id}
        label={label}
        isRequired={isRequired}
        BeforeLabel={BeforeLabel}
        AfterLabel={AfterLabel}
      />
    </div>
  );
}

type FieldSwitchProps = Pick<Props, 'inputClassName' | 'labelClassName' | 'label' | 'register'> & {
  formCheckClassName: string | undefined;
  id: string;
  isRequired: boolean;
  hasError: boolean;
  props: Omit<React.InputHTMLAttributes<any>, 'type'>;
  BeforeLabel: JSX.Element | undefined;
  AfterLabel: JSX.Element | undefined;
};
function FieldSwitch({
  inputClassName,
  labelClassName,
  formCheckClassName,
  label,
  BeforeLabel,
  AfterLabel,
  register,
  id,
  isRequired,
  hasError,
  props,
}: FieldSwitchProps) {
  return (
    <div className={classNames('form-check form-switch mb-0', formCheckClassName)}>
      <input
        className={classNames('form-check-input', inputClassName, { 'border-danger': hasError })}
        aria-invalid={hasError}
        type="checkbox"
        role="switch"
        id={id}
        {...props}
        {...register}
      />
      <Label
        staticLabelClassName={classNames('form-check-label', { 'text-danger': hasError })}
        labelClassName={labelClassName}
        id={id}
        label={label}
        isRequired={isRequired}
        BeforeLabel={BeforeLabel}
        AfterLabel={AfterLabel}
      />
    </div>
  );
}

type FieldInputRadioProps = Pick<
  Props,
  'inputClassName' | 'inputGroupClassName' | 'labelClassName' | 'label' | 'options' | 'register'
> & {
  formCheckClassName: string | undefined;
  id: string;
  isRequired: boolean;
  hasError: boolean;
  props: Omit<React.InputHTMLAttributes<any>, 'type'>;
  BeforeLabel: JSX.Element | undefined;
  AfterLabel: JSX.Element | undefined;
};
function FieldInputRadio({
  inputClassName,
  inputGroupClassName,
  labelClassName,
  formCheckClassName,
  label,
  BeforeLabel,
  AfterLabel,
  options = [],
  register,
  id,
  isRequired,
  hasError,
  props,
}: FieldInputRadioProps) {
  return (
    <>
      <Label
        labelClassName={labelClassName}
        id={id}
        label={label}
        isRequired={isRequired}
        BeforeLabel={BeforeLabel}
        AfterLabel={AfterLabel}
      />
      <div className={classNames('input-group', inputGroupClassName)}>
        {options.map(({ value, label, isDisabled }) => {
          const radioId = `${register.name}_${value}`;
          return (
            <div className={classNames('form-check', formCheckClassName)} key={radioId}>
              <input
                className={classNames('form-check-input', inputClassName, { 'border-danger': hasError })}
                type="radio"
                id={radioId}
                {...props}
                {...register}
                value={value}
                disabled={isDisabled}
              />
              <label className="form-check-label" htmlFor={radioId}>
                {label}
              </label>
            </div>
          );
        })}
      </div>
    </>
  );
}

type FieldTextareaProps = Pick<Props, 'inputClassName' | 'labelClassName' | 'label' | 'register'> & {
  id: string;
  isRequired: boolean;
  isEmpty: boolean;
  hasError: boolean;
  props: Omit<React.InputHTMLAttributes<any>, 'type'>;
  BeforeLabel: JSX.Element | undefined;
  AfterLabel: JSX.Element | undefined;
};
function FieldTextarea({
  inputClassName,
  labelClassName,
  label,
  BeforeLabel,
  AfterLabel,
  register,
  id,
  isRequired,
  isEmpty,
  hasError,
  props,
}: FieldTextareaProps) {
  return (
    <>
      <Label
        labelClassName={labelClassName}
        id={id}
        label={label}
        isRequired={isRequired}
        BeforeLabel={BeforeLabel}
        AfterLabel={AfterLabel}
      />
      <textarea
        className={classNames('form-control', inputClassName, { 'border-danger': hasError })}
        aria-invalid={hasError}
        id={id}
        data-is-empty={isEmpty}
        {...props}
        {...register}
      />
    </>
  );
}

type FieldDatePickerProps = Pick<
  Props,
  'inputClassName' | 'labelClassName' | 'label' | 'register' | 'control' | 'datepickerProps'
> & {
  id: string;
  isRequired: boolean;
  hasError: boolean;
  props: React.InputHTMLAttributes<any>;
  BeforeLabel: JSX.Element | undefined;
  AfterLabel: JSX.Element | undefined;
};
function FieldDatePicker({
  inputClassName,
  labelClassName,
  label,
  BeforeLabel,
  AfterLabel,
  register,
  control,
  datepickerProps,
  id,
  isRequired,
  hasError,
  props,
}: FieldDatePickerProps) {
  return (
    <>
      <Label
        labelClassName={labelClassName}
        id={id}
        label={label}
        isRequired={isRequired}
        BeforeLabel={BeforeLabel}
        AfterLabel={AfterLabel}
      />
      <Controller
        control={control}
        name={register.name}
        defaultValue={props.defaultValue}
        render={({ field }) => {
          const fieldValue: string = field.value ?? '';
          let selectedValue: Date | null = null;
          if (fieldValue) {
            const localDate = new LocalDate(fieldValue);
            if (localDate.isValid) {
              selectedValue = localDate.value;
            }
          }
          const onChangeHandler = (dateLocal: Date | null) => {
            let payload: string = '';
            if (dateLocal) {
              const localDate = new LocalDate(dateLocal);
              if (!localDate.isValid) return;
              const fullYear = localDate.value.getFullYear();
              if (fullYear > 9999) return;
              payload = localDate.toJSON();
            }
            field.onChange(payload);
          };

          return (
            <DatePicker
              id={id}
              className={classNames('form-control', inputClassName, { 'border-danger': hasError })}
              selected={selectedValue}
              onChange={onChangeHandler}
              name={register.name}
              placeholderText={props.placeholder || 'dd/mm/yyyy'}
              autoComplete={props.autoComplete}
              disabled={props.disabled}
              readOnly={props.readOnly}
              dateFormat={datepickerProps && datepickerProps.showTimeSelect ? 'dd/MM/yyyy - HH:mm' : 'dd/MM/yyyy'}
              timeFormat="HH:mm"
              dropdownMode="select"
              showMonthDropdown
              showYearDropdown
              {...datepickerProps}
            />
          );
        }}
      />
    </>
  );
}

type FieldTagsProps = Pick<
  Props,
  'inputClassName' | 'inputGroupClassName' | 'labelClassName' | 'label' | 'register' | 'control' | 'datepickerProps'
> & {
  id: string;
  isRequired: boolean;
  hasError: boolean;
  props: React.InputHTMLAttributes<any>;
  BeforeLabel: JSX.Element | undefined;
  AfterLabel: JSX.Element | undefined;
};
function FieldTags({
  inputClassName,
  inputGroupClassName,
  labelClassName,
  label,
  BeforeLabel,
  AfterLabel,
  register,
  control,
  id,
  isRequired,
  hasError,
  props,
}: FieldTagsProps) {
  return (
    <>
      <Label
        labelClassName={labelClassName}
        id={id}
        label={label}
        isRequired={isRequired}
        BeforeLabel={BeforeLabel}
        AfterLabel={AfterLabel}
      />
      <Controller
        control={control}
        name={register.name}
        defaultValue={props.defaultValue}
        render={({ field }) => {
          const addKeys = [9, 13, 32, 188]; // [Tab], [Enter], [Space], [,<]
          const maxTags = typeof props.max === 'string' ? Number(props.max) : props.max;
          return (
            <TagsInput
              className={classNames('react-tagsinput', inputGroupClassName, {
                disabled: props.disabled,
                'border-danger': hasError,
              })}
              value={field.value || []}
              onChange={(tags) => field.onChange(tags)}
              addKeys={addKeys}
              disabled={props.disabled}
              maxTags={maxTags}
              inputProps={{
                className: classNames('react-tagsinput-input', inputClassName),
                placeholder: props.placeholder,
                autoComplete: props.autoComplete,
                name: register.name,
                id,
              }}
              onlyUnique
            />
          );
        }}
      />
    </>
  );
}

type FieldDropdownAsyncCreatableProps = Pick<
  Props,
  'inputClassName' | 'labelClassName' | 'label' | 'register' | 'control' | 'dropdownAsyncCreatableProps'
> & {
  id: string;
  hasError: boolean;
  isRequired: boolean;
  props: React.InputHTMLAttributes<any>;
  BeforeLabel: JSX.Element | undefined;
  AfterLabel: JSX.Element | undefined;
  onClearOptions: ((removedValues: SelectOption[] | []) => void) | undefined;
};
function FieldDropdownAsyncCreatable({
  inputClassName,
  labelClassName,
  label,
  BeforeLabel,
  AfterLabel,
  register,
  control,
  id,
  hasError,
  isRequired,
  dropdownAsyncCreatableProps,
  props,
  onClearOptions,
}: FieldDropdownAsyncCreatableProps) {
  return (
    <>
      <Label
        labelClassName={labelClassName}
        id={id}
        label={label}
        isRequired={isRequired}
        BeforeLabel={BeforeLabel}
        AfterLabel={AfterLabel}
      />
      <Controller
        control={control}
        name={register.name}
        render={({ field }) => {
          const fieldValue: string | string[] = field.value;
          const getDefaultOption = (value: string) => {
            if (!value.length) return;
            return { label: value, value: value };
          };
          const selectedValue = Array.isArray(fieldValue)
            ? fieldValue.map((el) => {
                return getDefaultOption(el);
              })
            : getDefaultOption(field.value) || null;
          const onChangeHandler = (newValue: unknown, action: ActionMeta<any>) => {
            if (action.action === 'clear' && onClearOptions) {
              onClearOptions(action.removedValues as SelectOption[]);
            }

            const selected = newValue as SelectOption | SelectOption[] | null;
            const payload = Array.isArray(selected)
              ? selected.map((option: SelectOption) => option.value)
              : selected?.value ?? '';
            field.onChange(payload);
          };
          return (
            <AsyncCreatableSelect
              className={classNames('react-select', inputClassName, { 'border-danger': hasError })}
              classNamePrefix="react-select"
              value={selectedValue}
              placeholder={props.placeholder}
              onBlur={field.onBlur}
              onChange={onChangeHandler}
              isMulti={props.multiple}
              isClearable={false}
              isSearchable={true}
              closeMenuOnSelect={true}
              isDisabled={props.disabled}
              inputId={id}
              components={{
                DropdownIndicator: () => (
                  <div className={classNames(styles.ChevronDown, 'text-primary')}>
                    <ChevronDownIcon />
                  </div>
                ),
                SingleValue: (props) => (
                  <components.SingleValue {...props}>
                    {props.data.shortLabel || props.data.label}
                  </components.SingleValue>
                ),
                MultiValue: (props) => (
                  <components.MultiValue {...props}>{props.data.shortLabel || props.data.label}</components.MultiValue>
                ),
              }}
              {...dropdownAsyncCreatableProps}
            />
          );
        }}
      />
    </>
  );
}

type FieldDropdownProps = Pick<
  Props,
  'inputClassName' | 'labelClassName' | 'label' | 'options' | 'register' | 'control' | 'dropdownProps'
> & {
  id: string;
  hasError: boolean;
  isRequired: boolean;
  props: React.InputHTMLAttributes<any>;
  BeforeLabel: JSX.Element | undefined;
  AfterLabel: JSX.Element | undefined;
};
function FieldDropdown({
  inputClassName,
  labelClassName,
  label,
  BeforeLabel,
  AfterLabel,
  options = [],
  register,
  control,
  id,
  hasError,
  isRequired,
  dropdownProps,
  props,
}: FieldDropdownProps) {
  return (
    <>
      <Label
        labelClassName={labelClassName}
        id={id}
        label={label}
        isRequired={isRequired}
        BeforeLabel={BeforeLabel}
        AfterLabel={AfterLabel}
      />
      <Controller
        control={control}
        name={register.name}
        defaultValue={props.defaultValue}
        render={({ field }) => {
          const fieldValue: string | string[] = field.value;
          const selectedValue = Array.isArray(fieldValue)
            ? (fieldValue
                .map((value) => options.find((option) => option.value === value))
                .filter((v) => v) as SelectOption[])
            : options.find((option) => option.value === fieldValue) || null;
          const onChangeHandler = (newValue: unknown) => {
            const selected = newValue as SelectOption | SelectOption[] | null;
            const payload = Array.isArray(selected)
              ? selected.map((option: SelectOption) => option.value)
              : selected?.value ?? '';
            field.onChange(payload);
          };
          return (
            <Select
              className={classNames('react-select', inputClassName, { 'border-danger': hasError })}
              classNamePrefix="react-select"
              value={selectedValue}
              options={options}
              placeholder={props.placeholder}
              onBlur={field.onBlur}
              onChange={onChangeHandler}
              isMulti={props.multiple}
              isClearable={false}
              isSearchable={true}
              closeMenuOnSelect={true}
              isDisabled={props.disabled}
              inputId={id}
              components={{
                DropdownIndicator: () => (
                  <div className={classNames(styles.ChevronDown, 'text-primary')}>
                    <ChevronDownIcon />
                  </div>
                ),
                SingleValue: (props) => (
                  <components.SingleValue {...props}>
                    {props.data.shortLabel || props.data.label}
                  </components.SingleValue>
                ),
                MultiValue: (props) => (
                  <components.MultiValue {...props}>{props.data.shortLabel || props.data.label}</components.MultiValue>
                ),
              }}
              {...dropdownProps}
            />
          );
        }}
      />
    </>
  );
}

function FieldDropdownMultiSort({
  inputClassName,
  labelClassName,
  label,
  BeforeLabel,
  AfterLabel,
  options = [],
  register,
  control,
  id,
  hasError,
  isRequired,
  dropdownProps,
  props,
}: FieldDropdownProps) {
  return (
    <>
      <Label
        labelClassName={labelClassName}
        id={id}
        label={label}
        isRequired={isRequired}
        BeforeLabel={BeforeLabel}
        AfterLabel={AfterLabel}
      />
      <Controller
        control={control}
        name={register.name}
        defaultValue={props.defaultValue}
        render={({ field }) => {
          const fieldValue: string[] = field.value || [];
          const selectedValue = fieldValue
            .map((value) => options.find((option) => option.value === value))
            .filter((v) => v) as SelectOption[];
          const onChangeHandler = (newValue: unknown) => {
            const selected = newValue as SelectOption[] | null;
            const payload = selected ? selected.map((option: SelectOption) => option.value) : [];
            field.onChange(payload);
          };
          const onDragEnd = (event: DragEndEvent) => {
            const { active, over } = event;
            if (!active || !over) return;
            const oldIndex = fieldValue.indexOf(active.id as string);
            const newIndex = fieldValue.indexOf(over.id as string);
            const newValue = arrayMove(fieldValue, oldIndex, newIndex);
            field.onChange(newValue);
          };

          return (
            <DndContext modifiers={[restrictToParentElement]} onDragEnd={onDragEnd} collisionDetection={closestCenter}>
              <SortableContext items={fieldValue} strategy={horizontalListSortingStrategy}>
                <Select
                  className={classNames('react-select', inputClassName, { 'border-danger': hasError })}
                  classNamePrefix="react-select"
                  value={selectedValue}
                  options={options}
                  placeholder={props.placeholder}
                  onBlur={field.onBlur}
                  onChange={onChangeHandler}
                  isMulti={true}
                  isClearable={false}
                  isSearchable={true}
                  closeMenuOnSelect={true}
                  isDisabled={props.disabled}
                  inputId={id}
                  components={{
                    DropdownIndicator: () => (
                      <div className={classNames(styles.ChevronDown, 'text-primary')}>
                        <ChevronDownIcon />
                      </div>
                    ),
                    MultiValue: (props: MultiValueProps<SelectOption>) => {
                      const onMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      };
                      const innerProps = { ...props.innerProps, onMouseDown };
                      const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
                        id: props.data.value,
                      });
                      const style = {
                        transform: CSS.Transform.toString(transform),
                        transition,
                      };
                      return (
                        <div style={style} ref={setNodeRef} {...attributes} {...listeners}>
                          <components.MultiValue {...props} innerProps={innerProps} />
                        </div>
                      );
                    },
                    MultiValueLabel: (props: MultiValueGenericProps<any, true>) => {
                      const { data, selectProps } = props;
                      const options = selectProps.value as SelectOption[] | null;
                      let index = options ? options.findIndex((option) => option.value === data.value) : 0;
                      if (index >= 0) index++;
                      return (
                        <components.MultiValueLabel {...props}>
                          {data.shortLabel || data.label}
                          {index > 0 ? ` (${index}${nth(index)})` : ''}
                        </components.MultiValueLabel>
                      );
                    },
                    MultiValueRemove: (props: MultiValueRemoveProps<SelectOption>) => {
                      return (
                        <components.MultiValueRemove
                          {...props}
                          innerProps={{
                            onPointerDown: (e) => e.stopPropagation(),
                            ...props.innerProps,
                          }}
                        />
                      );
                    },
                  }}
                  {...dropdownProps}
                />
              </SortableContext>
            </DndContext>
          );
        }}
      />
    </>
  );
}

function Field({
  className,
  inputGroupClassName,
  inputClassName,
  labelClassName,
  formCheckClassName,
  field,
  label,
  type = 'text',
  options,
  IconBefore,
  IconAfter,
  url,
  register,
  control,
  formSchema,
  errors,
  dropdownProps,
  dropdownAsyncCreatableProps,
  datepickerProps,
  Before,
  After,
  BeforeLabel,
  AfterLabel,
  onClearOptions,
  ...props
}: Props & React.InputHTMLAttributes<any> & React.TextareaHTMLAttributes<any>) {
  const id = useId();
  const value = useWatch({ name: register.name, control });
  const hasError = checkErrors(register.name, errors);
  const isEmpty = useMemo(() => !(Array.isArray(value) ? value.length > 0 : Boolean(value)), [value]);
  const isRequired = useMemo(
    () => testIsFieldIsRequired(register.name, formSchema, control),
    [register, formSchema, control],
  );

  const fieldSwitch = (): JSX.Element => {
    switch (field) {
      case 'input':
        switch (type) {
          case 'checkbox':
            return (
              <FieldInputCheckbox
                {...{
                  inputClassName,
                  labelClassName,
                  formCheckClassName,
                  label,
                  BeforeLabel,
                  AfterLabel,
                  register,
                  id,
                  isRequired,
                  hasError,
                  props,
                }}
              />
            );
          case 'radio':
            return (
              <FieldInputRadio
                {...{
                  inputClassName,
                  inputGroupClassName,
                  labelClassName,
                  formCheckClassName,
                  label,
                  BeforeLabel,
                  AfterLabel,
                  options,
                  register,
                  id,
                  isRequired,
                  hasError,
                  props,
                }}
              />
            );
          case 'file':
            return (
              <FieldInputFile
                {...{
                  inputClassName,
                  labelClassName,
                  label,
                  BeforeLabel,
                  AfterLabel,
                  register,
                  control,
                  id,
                  isRequired,
                  hasError,
                  props,
                }}
              />
            );
          case 'search':
            return (
              <FieldInputDefault
                {...{
                  inputClassName,
                  inputGroupClassName: classNames(inputGroupClassName, 'search-icon-right'),
                  labelClassName,
                  type,
                  label,
                  BeforeLabel,
                  AfterLabel,
                  register,
                  id,
                  isRequired,
                  isEmpty,
                  hasError,
                  props,
                }}
              />
            );
          default:
            return (
              <FieldInputDefault
                {...{
                  inputClassName,
                  inputGroupClassName,
                  labelClassName,
                  type,
                  label,
                  BeforeLabel,
                  AfterLabel,
                  register,
                  id,
                  isRequired,
                  isEmpty,
                  hasError,
                  props,
                }}
              />
            );
        }
      case 'switch':
        return (
          <FieldSwitch
            {...{
              inputClassName,
              labelClassName,
              formCheckClassName,
              label,
              BeforeLabel,
              AfterLabel,
              register,
              id,
              isRequired,
              hasError,
              props,
            }}
          />
        );
      case 'textarea':
        return (
          <FieldTextarea
            {...{
              inputClassName,
              labelClassName,
              label,
              BeforeLabel,
              AfterLabel,
              register,
              id,
              isRequired,
              isEmpty,
              hasError,
              props,
            }}
          />
        );
      case 'image':
        return (
          <FieldImage
            {...{
              inputClassName,
              labelClassName,
              label,
              BeforeLabel,
              AfterLabel,
              register,
              control,
              url,
              id,
              isRequired,
              hasError,
              props,
            }}
          />
        );
      case 'document':
        return (
          <FieldDocument
            {...{
              inputClassName,
              labelClassName,
              label,
              register,
              url,
              IconBefore,
              IconAfter,
              id,
              isRequired,
              hasError,
              props,
            }}
          />
        );
      case 'dropdown':
        return (
          <FieldDropdown
            {...{
              inputClassName,
              labelClassName,
              label,
              BeforeLabel,
              AfterLabel,
              options,
              register,
              control,
              id,
              hasError,
              isRequired,
              dropdownProps,
              props,
            }}
          />
        );
      case 'dropdown-multi-sort':
        return (
          <FieldDropdownMultiSort
            {...{
              inputClassName,
              labelClassName,
              label,
              BeforeLabel,
              AfterLabel,
              options,
              register,
              control,
              id,
              hasError,
              isRequired,
              dropdownProps,
              props,
            }}
          />
        );
      case 'dropdown-async-creatable':
        return (
          <FieldDropdownAsyncCreatable
            {...{
              inputClassName,
              labelClassName,
              label,
              BeforeLabel,
              AfterLabel,
              options,
              register,
              control,
              id,
              hasError,
              isRequired,
              dropdownAsyncCreatableProps,
              props,
              onClearOptions,
            }}
          />
        );
      case 'datepicker':
        return (
          <FieldDatePicker
            {...{
              inputClassName,
              labelClassName,
              label,
              BeforeLabel,
              AfterLabel,
              register,
              control,
              datepickerProps,
              id,
              isRequired,
              hasError,
              props,
            }}
          />
        );
      case 'tags':
        return (
          <FieldTags
            {...{
              inputClassName,
              inputGroupClassName,
              labelClassName,
              label,
              BeforeLabel,
              AfterLabel,
              register,
              control,
              id,
              isRequired,
              hasError,
              props,
            }}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <div className={classNames(className)}>
      {Before}
      {props.disabled ? <fieldset disabled>{fieldSwitch()}</fieldset> : fieldSwitch()}
      {After}
      <FieldErrorMessage name={register.name} errors={errors} />
    </div>
  );
}

function checkErrors(name: string, errors: any): boolean {
  if (!name.includes('.')) return !!errors[name];
  return name.split('.').reduce((acc, el) => acc?.[el], errors);
}

export default Field;
