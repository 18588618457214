import { useEffect, useMemo } from 'react';
import { useQuery, keepPreviousData } from '@tanstack/react-query';

import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, array } from 'yup';

import Field from 'src/components/forms/Field';
import ServerErrorMessages from 'src/components/ServerErrorMessages';

import SummaryBar from 'src/pages/dashboard/components/Summary';
import RemaindersBar from 'src/pages/dashboard/components/Remainders';
import UpcomingTransitionsBar from 'src/pages/dashboard/components/UpcomingTransitions';

import useUserPermission from 'src/hooks/useUserPermission';
import { useFormErrors } from 'src/hooks/FormHelpers';

import { getAllPartyB, getAllPartyBForCurrentUser, getDashboardAnalytics } from 'src/api/base/analytics';

import ServerErrorAdapter from 'src/utils/ServerErrorAdapter';
import {
  dealRotorRelationshipTypeOptions,
  organisationStatusSortingScore,
  transactionRanges,
} from 'src/utils/selectOptions';

import {
  DealRotorRelationshipType,
  UUIDString,
  YupFormShape,
  ServerError,
  SelectOption,
  PermissionNames,
  OrganisationStatus,
} from 'src/types';

type FormData = {
  relationshipType: DealRotorRelationshipType;
  partyBIds: UUIDString[];
};
export type DateRangeFormData = {
  range: string;
};

type FormShape = YupFormShape<FormData>;
type DateRangeFormShape = YupFormShape<DateRangeFormData>;

const formSchema = object().shape<FormShape>({
  relationshipType: string(),
  partyBIds: array().of(string()),
});
const dateRangeFormSchema = object().shape<DateRangeFormShape>({
  range: string().default(transactionRanges[1]),
});

const Dashboard = () => {
  const { hasUserPermission } = useUserPermission();
  const isAllowedAnalyticAllSystemDealsRotorsRead = hasUserPermission(PermissionNames.AnalyticAllSystemDealsRotorsRead);

  const { control, register, handleSubmit, formState, setError, clearErrors } = useForm<FormData>({
    resolver: yupResolver(formSchema),
  });
  const rangeMethods = useForm<DateRangeFormData>({
    resolver: yupResolver(dateRangeFormSchema),
    defaultValues: dateRangeFormSchema.cast({}),
  });

  const relationshipTypeField = useWatch({ control, name: 'relationshipType' });
  const partyBField = useWatch({ control, name: 'partyBIds' });
  const rangeField = useWatch({ control: rangeMethods.control, name: 'range' });

  const { serverErrorMessages, setServerErrorMessages, handleErrors } = useFormErrors<FormData>(setError, clearErrors);

  const { data: partyBList, error: partyBListError } = useQuery({
    queryKey: ['getAllPartyB', isAllowedAnalyticAllSystemDealsRotorsRead],
    async queryFn({ signal }) {
      const config = { signal };
      const res = isAllowedAnalyticAllSystemDealsRotorsRead
        ? await getAllPartyB(config)
        : await getAllPartyBForCurrentUser(config);
      return res.data;
    },
    initialData: [],
    retry: false,
    refetchOnWindowFocus: false,
  });

  const partyBListOptions = useMemo<SelectOption[]>(
    () =>
      partyBList
        .toSorted((a, b) =>
          a.status === b.status
            ? a.name.localeCompare(b.name)
            : organisationStatusSortingScore[a.status] - organisationStatusSortingScore[b.status],
        )
        .map((el) => {
          const isEmpty = el.dealsCount < 1;
          let optionLabel = el.name;
          if (!isEmpty) optionLabel += ` (${el.dealsCount} deal${el.dealsCount > 1 ? 's' : ''})`;
          if (el.status === OrganisationStatus.Archived) optionLabel += ' (Archived)';
          const option = {
            label: optionLabel,
            value: el.id,
            isDisabled: isEmpty,
          };
          return option;
        }),
    [partyBList],
  );

  const {
    data: dashboardAnalytics,
    error: dashboardAnalyticsListError,
    refetch: dashboardAnalyticsRefetch,
  } = useQuery({
    queryKey: ['getDashboardAnalytics', rangeField],
    async queryFn({ signal }) {
      const config = { signal };
      const params = {
        partyBIds: partyBField,
        relationshipType: relationshipTypeField,
        daysRange: Number(rangeField),
      };
      const res = await getDashboardAnalytics(params, config);
      return res.data;
    },
    placeholderData: keepPreviousData,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const onSubmit = handleSubmit(async (formData) => {
    handleErrors();
    dashboardAnalyticsRefetch();
  });

  useEffect(() => {
    const getAllPartyBError = new ServerErrorAdapter(partyBListError as ServerError).combine();
    const getDashboardAnalyticsError = new ServerErrorAdapter(dashboardAnalyticsListError as ServerError).combine();
    const errorMessages = [...getAllPartyBError, ...getDashboardAnalyticsError];
    setServerErrorMessages(errorMessages);
  }, [dashboardAnalyticsListError, partyBListError, setServerErrorMessages]);

  return (
    <div className="h-100">
      <header className="mb-4">
        <h1 className="h5">Dashboard</h1>
        <p className="text-secondary">View your active deals, action items, reminders and upcoming agreements</p>
      </header>
      <div className="col-md-12 col-lg-8 mb-4">
        <form className="row g-3" onSubmit={onSubmit}>
          <Field
            className="col-md-12 col-lg-5"
            label="Select deal relationship type"
            field="dropdown"
            placeholder="All types"
            autoComplete="off"
            register={register('relationshipType')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
            options={dealRotorRelationshipTypeOptions}
            dropdownProps={{
              isSearchable: false,
              isClearable: true,
            }}
          />
          <Field
            className="col-md-12 col-lg-5"
            label="Select other parties"
            field="dropdown"
            placeholder="All parties"
            autoComplete="off"
            register={register('partyBIds')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
            options={partyBListOptions}
            dropdownProps={{
              isSearchable: true,
              isClearable: true,
            }}
            multiple
          />
          <div className="col-auto align-self-end">
            <button className="btn btn-primary" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
      <div className="mb-4">
        <SummaryBar
          data={dashboardAnalytics?.deals}
          filters={{ relationshipType: relationshipTypeField, partyBs: partyBField }}
        />
      </div>
      <div className="mb-4">
        <UpcomingTransitionsBar data={dashboardAnalytics?.agreements} control={rangeMethods.control} />
      </div>
      <div>
        <RemaindersBar actionsData={dashboardAnalytics?.actions} remainderData={dashboardAnalytics?.reminders} />
      </div>
      <ServerErrorMessages messages={serverErrorMessages} />
    </div>
  );
};

export default Dashboard;
