import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import ServerErrorMessages from 'src/components/ServerErrorMessages';
import ContactDetailsFrom from './ContactDetails.from';
import ContactDetailsList from './ContactDetails.list';

import ServerErrorAdapter from 'src/utils/ServerErrorAdapter';

import { getUserContactById } from 'src/api/base/user';

import { ServerError } from 'src/types';

function ContactDetails() {
  const { userId = '' } = useParams<'userId'>();

  const {
    isLoading,
    data: userContact,
    error: userContactError,
  } = useQuery({
    enabled: Boolean(userId),
    queryKey: ['getContactById', userId],
    async queryFn({ signal }) {
      const config = { signal };
      const res = await getUserContactById(userId, config);
      return res.data;
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  const serverErrorMessages = useMemo(
    () => new ServerErrorAdapter(userContactError as ServerError).combine(),
    [userContactError],
  );

  return (
    <>
      <h1 className="h5 mb-4">Contact details</h1>
      <ContactDetailsFrom userContact={userContact} />
      <ContactDetailsList isLoading={isLoading} organisations={userContact?.organisations ?? []} />
      <ServerErrorMessages className="mt-3" messages={serverErrorMessages} />
    </>
  );
}

export default ContactDetails;
