import Converter from './Converter';
import { FieldErrors, ServerFormErrors, ServerError, ServerValidationError } from 'src/types';

class ServerErrorAdapter {
  fieldErrors: FieldErrors = {};
  formErrors: ServerFormErrors = [];

  constructor(error?: ServerError | null) {
    if (!error) return;
    const { response, message } = error;
    if (response) {
      const { data } = response;
      if (!data) {
        this.formErrors.push(message || 'No Response Data.');
      } else if (data instanceof Blob) {
        this.formErrors.push(this.blobToStringSync(data) || message);
      } else if (typeof data === 'string') {
        this.formErrors.push(data);
      } else if (typeof data.status === 'string') {
        this.formErrors.push(data.status);
      } else if (!data.errors) {
        this.formErrors.push(data.title);
      } else {
        this.fieldErrors = this.errorsAdapter(data.errors);
      }
    } else {
      this.formErrors.push(message || 'Unknown Error.');
    }
  }

  private errorsAdapter(errors: ServerValidationError): FieldErrors {
    return Object.entries(errors).reduce(
      (acc, [key, value]) => ({ ...acc, [Converter.pascalToCamel(key)]: value }),
      {} as FieldErrors,
    );
  }

  // Hack to extract text synchronously
  private blobToStringSync(blob: Blob) {
    const url = URL.createObjectURL(blob);
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, false);
    xhr.send();
    URL.revokeObjectURL(url);
    return xhr.responseText;
  }

  public combine(): ServerFormErrors {
    return [...this.formErrors, ...Object.values(this.fieldErrors).flat()];
  }
}

export default ServerErrorAdapter;
