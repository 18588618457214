import { AgreementStage, AgreementState, AgreementStatus } from 'src/types';

const agreementStateMap: Record<AgreementState, string> = {
  [AgreementState.Shaping]: 'Shaping',
  [AgreementState.Formalisation]: 'Formalisation',
  [AgreementState.DeliveryWithoutEvents]: 'Delivery',
  [AgreementState.DeliveryWithEvents]: 'Events and changes',
  [AgreementState.PostCompletion]: 'Completion',
};
const agreementStatusMap: Record<AgreementStatus, string> = {
  [AgreementStatus.Inactive]: 'Draft',
  [AgreementStatus.Finalised]: 'Finalised',
  [AgreementStatus.AwaitingSignature]: 'Awaiting signature',
  [AgreementStatus.Signed]: 'Signed',
  [AgreementStatus.Rejected]: 'Declined',
  [AgreementStatus.InEffect]: 'In effect',
  [AgreementStatus.Ended]: 'Ended',
  [AgreementStatus.Cancelled]: 'Cancelled',
};

export function getAgreementStageText(agreementStage: AgreementStage | null) {
  return agreementStage ? `${agreementStage} agreement` : 'N/A';
}
export function getAgreementStateText(agreementState: AgreementState | null) {
  return agreementState ? agreementStateMap[agreementState] : 'N/A';
}
export function getAgreementStatusText(agreementStatus: AgreementStatus | null) {
  return agreementStatus ? agreementStatusMap[agreementStatus] : 'N/A';
}

export function getAgreementFullStatus(
  agreementStage: AgreementStage | null,
  agreementState: AgreementState | null,
  agreementStatus: AgreementStatus | null,
): string {
  return `${getAgreementStageText(agreementStage)} - ${getAgreementStateText(
    agreementState,
  )} - ${getAgreementStatusText(agreementStatus)}`;
}
