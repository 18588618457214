import { Spinner } from 'react-bootstrap';

function SpinnerLoader() {
  return (
    <div className="container text-center my-5">
      <Spinner variant="primary" />
    </div>
  );
}

export default SpinnerLoader;
