import { AxiosRequestConfig } from 'axios';

import { baseApi } from 'src/api';
import RequestSearchParamsAdapter from 'src/utils/RequestSearchParamsAdapter';
import {
  UUIDString,
  GetUserDto,
  GetCurrentUserDto,
  GetUserContactDto,
  UpdateUserModel,
  UpdateCurrentUserModel,
  UserSummaryDto,
  UserPartyBSummaryDtoDataPagedDto,
  CreateUserModel,
  UserChangePasswordModel,
  UserAccessStatus,
  GetUserDtoDataPagedDto,
  GetUserParticipantDto,
  GetUserParticipantDtoDataPagedDto,
  UpsertRolesModel,
  SignatureType,
  OrganisationType,
  GetUserSignedAgreementDtoDataPagedDto,
  OrganisationUserModel,
  ExistedOrganisationUserModel,
  UpdateOrganisationUserModel,
} from 'src/types';

// PermissionNames.UserRead
export const getUserById = (userId: UUIDString, config: AxiosRequestConfig = {}) =>
  baseApi.get<GetUserDto>(`/users/${userId}`, config);

// PermissionNames.UserWrite
export const updateUser = (userId: UUIDString, data: UpdateUserModel, config: AxiosRequestConfig = {}) =>
  baseApi.patch(`/users/${userId}`, data, config);

// PermissionNames.UserRead
export const getUserParticipantById = (userId: UUIDString, config: AxiosRequestConfig = {}) =>
  baseApi.get<GetUserParticipantDto>(`/users/${userId}/participants`, config);

// PermissionNames.UserReadContacts
export const getUserContactById = (userId: UUIDString, config: AxiosRequestConfig = {}) =>
  baseApi.get<GetUserContactDto>(`/users/${userId}/contacts`, config);

// IgnorePermissions
export const getCurrentUser = (config: AxiosRequestConfig = {}) =>
  baseApi.get<GetCurrentUserDto>('/users/current', config);

// IgnorePermissions
export const updateCurrentUser = (data: UpdateCurrentUserModel, config: AxiosRequestConfig = {}) =>
  baseApi.patch('/users/current', data, config);

// PermissionNames.UserReadSignature
export const downloadUserSignature = (signatureFilename: string, config: AxiosRequestConfig = {}) =>
  baseApi.get<Blob>(`/users/signatures/${signatureFilename}`, {
    ...config,
    responseType: 'blob',
  });

// PermissionNames.UserReadSummary)
export const getUserListSummary = (config: AxiosRequestConfig = {}) =>
  baseApi.get<UserSummaryDto[]>('/users/summary', config);

type GetExistPartyBUsersParams = {
  excludeOrganisationId?: UUIDString;
  excludeUserIds?: UUIDString[];
  search?: string;
};
// PermissionNames.UserReadSummary
export const getExistPartyBUsers = (params: GetExistPartyBUsersParams, config: AxiosRequestConfig = {}) =>
  baseApi.get<UserPartyBSummaryDtoDataPagedDto>('/users/party-b-summary', { ...config, params });

// PermissionNames.UserRead
export const getUserSignatoryList = (organisationId: UUIDString, config: AxiosRequestConfig = {}) =>
  baseApi.get<UserSummaryDto[]>('/users/signatories', { ...config, params: { organisationId } });

type GetUserListParams = RequestSearchParamsAdapter & {
  accessStatus: UserAccessStatus | undefined;
};
// PermissionNames.UserRead
export const getUserListSearch = (params: GetUserListParams, config: AxiosRequestConfig = {}) =>
  baseApi.get<GetUserDtoDataPagedDto>('/users/search', { ...config, params });

export type GetParticipantListParams = {
  type: OrganisationType | undefined;
  includeAll: boolean;
};
// PermissionNames.AnalyticAllSystemDealsRotorsRead
export const getParticipantListSearch = (
  params: RequestSearchParamsAdapter & GetParticipantListParams,
  config: AxiosRequestConfig = {},
) => baseApi.get<GetUserParticipantDtoDataPagedDto>('/users/participants', { ...config, params });

export type GetCurrentUserParticipantListParams = {
  type: OrganisationType | undefined;
};
// PermissionNames.UserReadSummary
export const getCurrentUserParticipantListSearch = (
  params: RequestSearchParamsAdapter & GetCurrentUserParticipantListParams,
  config: AxiosRequestConfig = {},
) => baseApi.get<GetUserParticipantDtoDataPagedDto>('/users/current/participants', { ...config, params });

type GetCurrentUserSignedAgreementsParams = RequestSearchParamsAdapter;
// IgnorePermissions
export const getCurrentUserSignedAgreements = (
  params: GetCurrentUserSignedAgreementsParams,
  config: AxiosRequestConfig = {},
) => baseApi.get<GetUserSignedAgreementDtoDataPagedDto>('/users/current/agreements', { ...config, params });

// PermissionNames.UserWrite
export const createUser = (data: CreateUserModel, config: AxiosRequestConfig = {}) =>
  baseApi.post<UUIDString>('/users', data, config);

// IgnorePermissions
export const getMFACurrentUser = (config: AxiosRequestConfig = {}) =>
  baseApi.get<boolean>('/users/current/mfa', config);

// IgnorePermissions
export const updateMFACurrentUser = (enabled: boolean) => baseApi.patch<void>(`/users/current/set-mfa/${enabled}`);

// PermissionNames.UserResetMFA
export const resetUserMFAById = (userId: UUIDString) => baseApi.patch<UUIDString>(`/users/${userId}/reset-mfa`);

// IgnorePermissions
export const changeUserPassword = (data: UserChangePasswordModel, config: AxiosRequestConfig = {}) =>
  baseApi.patch<UUIDString>('/users/change-password', data, config);

// PermissionNames.OrganisationWrite
export const createOrganisationUser = (
  organisationId: UUIDString,
  data: OrganisationUserModel,
  config: AxiosRequestConfig = {},
) => baseApi.post<UUIDString>(`/users/organisations/${organisationId}`, data, config);

// PermissionNames.OrganisationWrite
export const addExistUserToOrganisation = (
  userId: UUIDString,
  organisationId: string,
  data: ExistedOrganisationUserModel,
  config: AxiosRequestConfig = {},
) => baseApi.post<UUIDString>(`/users/${userId}/organisations/${organisationId}`, data, config);

// PermissionNames.OrganisationWrite
export const updateOrganisationUser = (
  userId: UUIDString,
  organisationId: UUIDString,
  data: UpdateOrganisationUserModel,
  config: AxiosRequestConfig = {},
) => baseApi.patch<void>(`/users/${userId}/organisations/${organisationId}`, data, config);

// PermissionNames.OrganisationWrite
export const deleteOrganisationUser = (
  userId: UUIDString,
  organisationId: UUIDString,
  config: AxiosRequestConfig = {},
) => baseApi.delete<void>(`/users/${userId}/organisations/${organisationId}`, config);

// PermissionNames.UserWrite
export const uploadUserLogo = (userId: UUIDString, data: FormData, config: AxiosRequestConfig = {}) =>
  baseApi.post<void>(`/users/${userId}/logo`, data, config);

// IgnorePermissions
export const uploadCurrentUserLogo = (data: FormData, config: AxiosRequestConfig = {}) =>
  baseApi.post<void>('/users/current/logo', data, config);

// IgnorePermissions
export const uploadUserSignature = (
  userId: UUIDString,
  type: SignatureType,
  data: FormData,
  config: AxiosRequestConfig = {},
) => baseApi.post<string>(`/users/${userId}/signatures`, data, { ...config, params: { type } });

// PermissionNames.UserWrite
export const updateUserRoles = (userId: UUIDString, data: UpsertRolesModel, config: AxiosRequestConfig = {}) =>
  baseApi.patch<void>(`/users/${userId}/roles`, data, config);

// PermissionNames.UserWrite
export const updateUserStatusDeactivate = (userId: UUIDString) => baseApi.patch<void>(`/users/${userId}/deactivate`);

// PermissionNames.UserWrite
export const updateUserStatusActivate = (userId: UUIDString) => baseApi.patch<void>(`/users/${userId}/activate`);
