import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { resetStore } from 'src/store';
import { getCurrentUser, getMFACurrentUser } from 'src/api/base/user';
import { GetCurrentUserDto } from 'src/types';

export const fetchCurrentUser = createAsyncThunk('users/fetchCurrentUser', async () => {
  const res = await getCurrentUser();
  return res.data;
});

export const fetchMFACurrentUser = createAsyncThunk('/users/current/mfa', async () => {
  const res = await getMFACurrentUser();
  return res.data;
});

type ProfileState = {
  user: GetCurrentUserDto | null;
  mfaEnabled: boolean | null;
};

const initialState: ProfileState = {
  user: null,
  mfaEnabled: null,
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCurrentUser.fulfilled, (state: ProfileState, action) => {
      state.user = action.payload;
    });
    builder.addCase(fetchMFACurrentUser.fulfilled, (state: ProfileState, action) => {
      state.mfaEnabled = action.payload;
    });

    // Cleanup
    builder.addCase(resetStore, () => initialState);
  },
});

export default profileSlice.reducer;
