import classNames from 'classnames';
import { useCallback, useMemo, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { generatePath, Link } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { useForm } from 'react-hook-form';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Field from 'src/components/forms/Field';
import Table, { createColumnHelper, useTableChangeParams } from 'src/components/Table';
import ServerErrorMessages from 'src/components/ServerErrorMessages';
import { DownloadFileIcon, ViewIcon } from 'src/components/icons';
import { PdfFileTypeIcon } from 'src/components/icons/file-type';

import { useFormErrors } from 'src/hooks/FormHelpers';

import Download from 'src/utils/Download';
import Converter from 'src/utils/Converter';
import ServerErrorAdapter from 'src/utils/ServerErrorAdapter';
import RequestSearchParamsAdapter from 'src/utils/RequestSearchParamsAdapter';

import { getCurrentUserSignedAgreements } from 'src/api/base/user';
import { downloadAgreementDocument } from 'src/api/base/agreement';

import { ServerError, YupFormShape, GetUserSignedAgreementDto } from 'src/types';

import styles from './SignedDocuments.module.scss';

type FormData = {
  search: string;
};
type FormShape = YupFormShape<FormData>;
const formSchema = object().shape<FormShape>({
  search: string().label('Search').trim(),
});

const columnHelper = createColumnHelper<GetUserSignedAgreementDto>();

const SignedDocuments = () => {
  const { register, control, formState, watch, setError, clearErrors } = useForm<FormData>({
    resolver: yupResolver(formSchema),
    mode: 'all',
    defaultValues: {
      search: '',
    },
  });

  const searchValue = watch('search');
  const search = searchValue.trim() || undefined;

  const { pagination, setPagination, sorting, setSorting } = useTableChangeParams({});
  const { serverErrorMessages, setServerErrorMessages, handleErrors } = useFormErrors<FormData>(setError, clearErrors);

  const {
    data: signedDocuments,
    error: signedDocumentsError,
    isLoading,
  } = useQuery({
    queryKey: ['getCurrentUserSignedAgreements', search, pagination, sorting],
    async queryFn({ signal }) {
      const config = { signal };
      const params = new RequestSearchParamsAdapter({ search, pagination, sorting });
      const res = await getCurrentUserSignedAgreements(params, config);
      return res.data;
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  const downloadPDF = useCallback(
    async (documentId: string) => {
      try {
        handleErrors();
        const res = await downloadAgreementDocument(documentId);
        Download.fileFromRes(res, '');
      } catch (error) {
        const serverErrors = new ServerErrorAdapter(error as ServerError);
        setServerErrorMessages(serverErrors.combine());
      }
    },
    [handleErrors, setServerErrorMessages],
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor('documentName', {
        id: 'documentName',
        header: 'Document name',
        cell: ({ getValue }) => (
          <div className="d-flex-row">
            <PdfFileTypeIcon className="me-2" />
            <span>{getValue()}</span>
          </div>
        ),
      }),
      columnHelper.accessor('dealName', {
        id: 'dealName',
        header: 'Deal name',
      }),
      columnHelper.accessor('organisations', {
        id: 'organisations',
        header: 'Party B',
        enableSorting: false,
        cell: ({ getValue }) => getValue().map((org) => org.name),
      }),
      columnHelper.accessor('number', {
        id: 'dealNumber',
        header: 'Deal rotor number',
        cell: ({ getValue, row }) => (
          <Link
            to={generatePath(RoutePath.dealRotorEditDetailedDealRotorDetails, {
              dealRotorId: row.original.dealRotorId,
            })}
          >
            {getValue()}
          </Link>
        ),
      }),
      columnHelper.accessor('signedOn', {
        id: 'signedOn',
        header: 'Signed on',
        cell: ({ getValue }) => {
          const date = new Date(getValue()).toISOString();
          return <span>{Converter.getFormattedDate(date)}</span>;
        },
      }),
      columnHelper.display({
        id: 'action',
        header: '',
        cell: ({ row }) => {
          const agreementDocumentId = row.original.agreementDocumentId;
          return (
            <div className="hstack gap-3 justify-content-center">
              <a
                className="btn btn-link px-2"
                href={generatePath(RoutePath.documentViewer, { agreementDocumentId })}
                target="_blank"
                rel="noopener noreferrer"
              >
                <ViewIcon width={24} height={24} />
              </a>

              <button className="btn btn-link px-2" type="button">
                <DownloadFileIcon width={28} height={28} onClick={() => downloadPDF(agreementDocumentId)} />
              </button>
            </div>
          );
        },
      }),
    ],
    [downloadPDF],
  );

  const { data, pageCount, total } = useMemo(
    () => ({
      data: signedDocuments?.items ?? [],
      pageCount: signedDocuments?.total ? Math.ceil(signedDocuments.total / pagination.pageSize) : 1,
      total: signedDocuments?.total ?? 0,
    }),
    [signedDocuments, pagination.pageSize],
  );

  useEffect(() => {
    const error = new ServerErrorAdapter(signedDocumentsError as ServerError).combine();
    setServerErrorMessages(error);
  }, [signedDocumentsError, setServerErrorMessages]);

  return (
    <>
      <div className="p-4 bg-white border rounded">
        <h2 className="h5 mb-4">Signed documents</h2>
        <form name="signedDocumentsForm" noValidate>
          <Field
            className="col-md-4"
            field="input"
            type="search"
            placeholder="Search"
            autoComplete="off"
            register={register('search')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
          />
        </form>
      </div>
      <Table
        className={classNames(styles.Table, 'mb-0')}
        wrapperClassName="border rounded-bottom border-top-0 mb-3"
        data={data}
        total={total}
        columns={columns}
        setSorting={setSorting}
        sorting={sorting}
        pagination={pagination}
        setPagination={setPagination}
        isLoading={isLoading}
        pageCount={pageCount}
      />
      <ServerErrorMessages className="mt-3" messages={serverErrorMessages} />
    </>
  );
};

export default SignedDocuments;
