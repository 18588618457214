import { useState } from 'react';
import { Modal } from 'react-bootstrap';

import LoadingButton from 'src/components/buttons/LoadingButton';
import ServerErrorMessages from 'src/components/ServerErrorMessages';

import ServerErrorAdapter from 'src/utils/ServerErrorAdapter';

import { SignAgreementModel, ServerError, ServerFormErrors } from 'src/types';

type Props = {
  signatureFilename: string;
  setSignatureFilename: React.Dispatch<React.SetStateAction<string>>;
  setIsSignDocumentFinished: React.Dispatch<React.SetStateAction<boolean>>;
  signAgreementRequest: (data: SignAgreementModel) => Promise<any>;
  refetchAgreementDetailsForSign: () => Promise<any>;
  refetchDownloadLatestAgreementDocument: () => Promise<any>;
};

function SignDocument({
  signatureFilename,
  setSignatureFilename,
  setIsSignDocumentFinished,
  signAgreementRequest,
  refetchAgreementDetailsForSign,
  refetchDownloadLatestAgreementDocument,
}: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [serverErrorMessages, setServerErrorMessages] = useState<ServerFormErrors>([]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onConfirmSign = async () => {
    setServerErrorMessages([]);
    try {
      await signAgreementRequest({ signatureFilename });

      await Promise.allSettled([refetchAgreementDetailsForSign(), refetchDownloadLatestAgreementDocument()]);

      setSignatureFilename('');
      setIsSignDocumentFinished(true);
      closeModal();
    } catch (e) {
      const serverErrors = new ServerErrorAdapter(e as ServerError);
      setServerErrorMessages(serverErrors.combine());
    }
  };

  return (
    <>
      <div className="position-absolute bottom-0 w-100 px-4 z-2">
        <div className="hstack flex-wrap justify-content-between gap-3 alert alert-primary">
          <div className="fs-14 fw-medium w-50">
            <span>By proceeding, you agree to the BridgingX </span>
            <br />
            <a href="/files/End_User_Licence_Agreement.pdf" target="_blank" className="me-2">
              End user licence agreement
            </a>
            <span>and </span>
            <a href="/files/Privacy_Notice.pdf" target="_blank">
              Privacy notice
            </a>
          </div>
          <button
            className="btn btn-link text-uppercase text-nowrap text-decoration-none px-0"
            type="button"
            onClick={() => setIsModalOpen(true)}
          >
            Sign this document
          </button>
        </div>
      </div>

      <Modal show={isModalOpen} onHide={closeModal} centered>
        <Modal.Header onHide={closeModal} closeButton>
          <Modal.Title className="h5">Are you sure you want to sign this document?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <button type="button" className="btn btn-link" onClick={closeModal}>
            No
          </button>
          <LoadingButton type="button" className="btn btn-primary" onClick={onConfirmSign}>
            Yes
          </LoadingButton>
          <ServerErrorMessages className="w-100 mt-2 mb-0" messages={serverErrorMessages} />
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SignDocument;
