import classNames from 'classnames';
import { useState, useCallback } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  flexRender,
  createColumnHelper,
  ColumnDef,
  ColumnOrderState,
  VisibilityState,
  PaginationState,
  SortingState,
  Header,
  Table,
} from '@tanstack/react-table';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import { arrayMove, horizontalListSortingStrategy, SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import ChevronIcon from 'src/components/icons/ChevronIcon';

import styles from './Table.module.scss';

type Props<TData = any> = {
  data: TData[];
  total: number;
  columns: ColumnDef<TData, any>[];
  pageCount?: number;
  isLoading?: boolean;
  columnVisibility?: VisibilityState;
  className?: string;
  wrapperClassName?: string;
  isDraggable?: boolean;
  isShowPagination?: boolean;
} & Omit<Partial<TableChangeParams>, 'resetPage'>;

type DraggableColumnHeaderProps<TData = any> = {
  header: Header<TData, unknown>;
  isSortableDisabled: boolean;
};

type PaginationProps = {
  table: Table<any>;
  total: number;
};

export type TableChangeParams = {
  pagination: PaginationState;
  setPagination: React.Dispatch<React.SetStateAction<PaginationState>>;
  sorting: SortingState;
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
  columnOrder: ColumnOrderState;
  setColumnOrder: React.Dispatch<React.SetStateAction<ColumnOrderState>>;
  resetPage: () => void;
};

type UseTableChangeParamsProps = {
  paginationState: PaginationState;
  sortingState: SortingState;
  columns: ColumnDef<any, any>[];
};

const defaultPagination = { pageIndex: 0, pageSize: 10 };

export function useTableChangeParams({
  paginationState = defaultPagination,
  sortingState = [],
  columns = [],
}: Partial<UseTableChangeParamsProps>): TableChangeParams {
  const [pagination, setPagination] = useState<PaginationState>(paginationState);
  const [sorting, setSorting] = useState<SortingState>(sortingState);
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(columns.map((c: any) => c.id || c.accessorKey));
  const resetPage = useCallback(() => setPagination((current) => ({ ...current, pageIndex: 0 })), []);

  return {
    pagination,
    setPagination,
    sorting,
    setSorting,
    columnOrder,
    setColumnOrder,
    resetPage,
  };
}

const pageSizes = [10, 25, 50, 100];
export function Pagination({ table, total }: PaginationProps) {
  const tableState = table.getState();
  const itemFrom = tableState.pagination.pageIndex * tableState.pagination.pageSize + 1;
  const itemTo = Math.min(
    tableState.pagination.pageIndex * tableState.pagination.pageSize + tableState.pagination.pageSize,
    total,
  );
  return (
    <div className={classNames(styles.Pagination, 'd-flex justify-content-between align-items-center')}>
      <div className="d-flex align-items-center gap-3">
        <select
          className="form-select"
          name="pageSize"
          value={tableState.pagination.pageSize}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value));
          }}
        >
          {pageSizes.map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Items per page {pageSize}
            </option>
          ))}
        </select>
        {total === 0 && <span className="text-nowrap">0 items</span>}
        {total === 1 && <span className="text-nowrap">1 item</span>}
        {total > 1 && (
          <span className="text-nowrap">
            {itemFrom}-{itemTo} of {total} items
          </span>
        )}
      </div>
      <div className="d-flex align-items-center">
        <input
          className="form-control btn-sq text-center"
          name="pageCount"
          type="text"
          inputMode="numeric"
          value={tableState.pagination.pageIndex + 1}
          onChange={(e) => {
            const pageCount = table.getPageCount();
            let page = e.target.value ? Number(e.target.value) - 1 : 0;
            if (page >= pageCount) {
              page = pageCount - 1;
            }
            table.setPageIndex(page);
          }}
          min={1}
          max={table.getPageCount()}
        />
        <span className="text-nowrap mx-3">
          of {table.getPageCount()} {table.getPageCount() === 1 ? 'page' : 'pages'}
        </span>
        <button
          type="button"
          className="btn btn-sq me-1"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <ChevronIcon left width={14} hanging={8} />
        </button>
        <button
          type="button"
          className="btn btn-sq"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          <ChevronIcon right width={14} hanging={8} />
        </button>
      </div>
    </div>
  );
}

function ColumnHeader({ header, isSortableDisabled }: DraggableColumnHeaderProps) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: header.id,
    disabled: isSortableDisabled,
  });

  const style = {
    transform: CSS.Transform.toString(transform ? { ...transform, scaleX: 1 } : null),
    transition,
    zIndex: isDragging ? 1 : undefined,
  };

  const isSorted = header.column.getIsSorted();

  return (
    <th
      ref={setNodeRef}
      className={classNames(`th-${header.id}`, 'header-cell', {
        'is-dragging': isDragging,
        'cursor-move': !isSortableDisabled,
        'cursor-pointer': isSortableDisabled && header.column.getCanSort(),
      })}
      colSpan={header.colSpan}
      style={style}
      onClick={header.column.getToggleSortingHandler()}
      {...(isSortableDisabled ? {} : attributes)}
      {...(isSortableDisabled ? {} : listeners)}
    >
      {header.isPlaceholder ? null : (
        <div className="d-flex justify-content-between align-items-center">
          <span className="text-nowrap">{flexRender(header.column.columnDef.header, header.getContext())}</span>
          {header.column.getCanSort() && (
            <span
              className={classNames(
                'd-flex flex-column justify-content-center align-items-center gap-1',
                styles.Sorting,
                header.column.getIsSorted(),
              )}
            >
              {(isSorted === false || isSorted === 'asc') && <ChevronIcon up width={10} height={5} />}
              {(isSorted === false || isSorted === 'desc') && <ChevronIcon down width={10} height={5} />}
            </span>
          )}
        </div>
      )}
    </th>
  );
}

function TableComponent({
  data,
  total,
  columns,
  isLoading,
  columnVisibility,
  pageCount,
  pagination,
  setPagination,
  sorting,
  setSorting,
  columnOrder = [],
  setColumnOrder,
  wrapperClassName,
  className = 'table-bordered',
  isShowPagination = true,
}: Props) {
  const options = {
    data,
    columns,
    pageCount,
    state: {
      columnVisibility,
      columnOrder,
      pagination,
      sorting,
    },
    onColumnOrderChange: setColumnOrder,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: !!setPagination,
    manualSorting: !!setSorting,
    enableMultiSort: false,
  };
  // Table doesn't rely on undefined value - it rely on if key exists on object
  if (!pagination) delete options.state.pagination;
  if (!setPagination) delete options.onPaginationChange;
  if (!sorting) delete options.state.sorting;
  if (!setSorting) delete options.onSortingChange;
  if (!setColumnOrder) delete options.onColumnOrderChange;

  const table = useReactTable(options);

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 8 } }),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor),
    useSensor(PointerSensor, { activationConstraint: { distance: 8 } }),
  );

  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!active || !over) return;

    const { columnOrder } = table.getState();
    const oldIndex = columnOrder.indexOf(active.id as string);
    const newIndex = columnOrder.indexOf(over.id as string);
    const newValue = arrayMove(columnOrder, oldIndex, newIndex);
    table.setColumnOrder(newValue);
  };

  return (
    <DndContext
      sensors={sensors}
      modifiers={[restrictToParentElement]}
      onDragEnd={onDragEnd}
      collisionDetection={closestCenter}
    >
      <div className={classNames('table-responsive custom-scroll', wrapperClassName)}>
        <table className={classNames('table table-white', styles.Table, className)}>
          <thead className="table-gray">
            <SortableContext
              items={columnOrder}
              strategy={horizontalListSortingStrategy}
              disabled={!columnOrder.length}
            >
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <ColumnHeader key={header.id} header={header} isSortableDisabled={!columnOrder.length} />
                  ))}
                </tr>
              ))}
            </SortableContext>
          </thead>
          <tbody>
            {table.getRowModel().rows.length
              ? table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td className={`td-${cell.column.id}`} key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))
              : !isLoading && (
                  <tr>
                    <td colSpan={table.getVisibleLeafColumns().length}>
                      {data.length ? 'No matching records' : 'No records'}
                    </td>
                  </tr>
                )}
            {isLoading && (
              <tr>
                <td colSpan={table.getVisibleLeafColumns().length}>Loading...</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {isShowPagination && <Pagination table={table} total={total} />}
    </DndContext>
  );
}
export default TableComponent;

export { createColumnHelper };
