import { useState, useMemo, useEffect, memo, lazy, Suspense } from 'react';

import SpinnerLoader from 'src/components/SpinnerLoader';

const PreviewDocumentMobile = lazy(() => import(/* webpackChunkName: "pdf-viewer" */ './PreviewDocument.mobile'));

type Props = {
  document: Blob;
  hasSignatureFilename: boolean;
  isSignDocumentFinished?: boolean;
};

function PreviewDocument({ document, hasSignatureFilename, isSignDocumentFinished }: Props) {
  const blobURL = useMemo(() => window.URL.createObjectURL(document), [document]);
  // Clean-up
  useEffect(() => () => window.URL.revokeObjectURL(blobURL), [blobURL]);

  const isMobile =
    window.innerWidth < 1200 ||
    (navigator.userAgent.includes('Macintosh') && navigator.maxTouchPoints && navigator.maxTouchPoints > 3);

  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    const page = hasSignatureFilename ? Number.MAX_SAFE_INTEGER : 1;

    setPageNumber(page);
  }, [document, hasSignatureFilename]);

  return isMobile ? (
    <Suspense fallback={<SpinnerLoader />}>
      <PreviewDocumentMobile
        blobURL={blobURL}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        isSignDocumentFinished={isSignDocumentFinished}
      />
    </Suspense>
  ) : (
    <embed src={`${blobURL}#statusbar=0&page=${pageNumber}`} width="100%" height="100%" type="application/pdf" />
  );
}

export default memo(PreviewDocument);
