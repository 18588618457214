import { useMemo, useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Accordion, Spinner } from 'react-bootstrap';

import Dot from 'src/components/Dot';
import AddendumCreate from './components/dealRotorEdit/AddendumCreate';
import AgreementDetails from './components/dealRotorEdit/AgreementDetails';
import AgreementAttributes from './components/dealRotorEdit/AgreementAttributes';

import { DealRotorEditDetailedTabsOutletContext } from './DealRotorEdit.detailed.tabs';

import usePrevious from 'src/hooks/usePrevious';
import useUserPermission from 'src/hooks/useUserPermission';

import { getAgreementFullStatus } from 'src/utils/statusFormat';
import { agreementStageColorMap } from 'src/utils/selectOptions';

import { AgreementType, DealRotorStatus, AgreementStatus, PermissionNames } from 'src/types';

const createAddendumAllowedAgreementStatuses = [
  AgreementStatus.Finalised,
  AgreementStatus.Signed,
  AgreementStatus.InEffect,
  AgreementStatus.Cancelled,
  AgreementStatus.Ended,
];

function AgreementDetailsTab() {
  const {
    isDealRotorLoading,
    dealRotor,
    isDealRotorAgreementListLoading,
    dealRotorAgreementList,
    isDealRotorAgreementAttributeLoading,
    dealRotorAgreementAttribute,
  } = useOutletContext<DealRotorEditDetailedTabsOutletContext>();
  const [accordionList, setAccordionList] = useState<string[]>([]);
  const prevDealRotorAgreementList = usePrevious(dealRotorAgreementList);

  const expandAll = () => {
    const itemList = dealRotorAgreementList.map((dealRotorAgreement) => dealRotorAgreement.id);
    setAccordionList(itemList);
  };

  const dealRotorRootAgreement = useMemo(
    () => dealRotorAgreementList.find(({ type }) => type === AgreementType.Agreement),
    [dealRotorAgreementList],
  );

  const { hasUserPermission } = useUserPermission();
  const isAllowedAgreementAttributesRead = hasUserPermission(PermissionNames.AgreementAttributesRead);

  const isAllowCreateAddendum = useMemo(() => {
    if (!dealRotor || !dealRotor.agreementStatus) return false;
    return (
      dealRotor.status === DealRotorStatus.Active &&
      dealRotor.agreementEndDate !== null &&
      dealRotor.agreementTerminationDate === null &&
      createAddendumAllowedAgreementStatuses.includes(dealRotor.agreementStatus)
    );
  }, [dealRotor]);

  const fullStatus = useMemo(
    () =>
      dealRotor &&
      getAgreementFullStatus(dealRotor.agreementStage, dealRotor.agreementState, dealRotor.agreementStatus),
    [dealRotor],
  );

  useEffect(() => {
    if (!prevDealRotorAgreementList) {
      // New data - open last agreement
      if (dealRotorAgreementList.length) {
        setAccordionList([dealRotorAgreementList[0].id]);
      }
    } else if (prevDealRotorAgreementList.length < dealRotorAgreementList.length) {
      // New agreement added - add last agreement to open list
      setAccordionList((state) => [...state, dealRotorAgreementList[0].id]);
    } else if (prevDealRotorAgreementList.length > dealRotorAgreementList.length) {
      // Agreement deleted
      setAccordionList((state) => {
        // Remove deleted agreements from open list
        const newState = state.filter((id) => dealRotorAgreementList.find((a) => a.id === id));
        if (!newState.length && dealRotorAgreementList.length) {
          // When open list is empty - open tha last agreement
          newState.push(dealRotorAgreementList[0].id);
        }
        return newState;
      });
    }
  }, [prevDealRotorAgreementList, dealRotorAgreementList]);

  return isDealRotorLoading || isDealRotorAgreementListLoading || isDealRotorAgreementAttributeLoading || !dealRotor ? (
    <div className="text-center">
      <Spinner className="align-self-center" variant="primary" />
    </div>
  ) : (
    <div className="vstack gap-4">
      <div className="hstack gap-2 flex-wrap justify-content-between">
        <div>
          {isAllowCreateAddendum && dealRotorRootAgreement && (
            <AddendumCreate agreementId={dealRotorRootAgreement.id} agreementEndDate={dealRotor.agreementEndDate} />
          )}
        </div>
        <div className="hstack flex-column">
          {dealRotor && (
            <div className="hstack gap-2">
              <Dot
                color={dealRotor.agreementStage ? agreementStageColorMap[dealRotor.agreementStage] : 'metallic-silver'}
              />
              <span>{fullStatus}</span>
            </div>
          )}
          <div className="hstack gap-3 justify-content-end">
            <button className="btn btn-link p-0" type="button" onClick={() => setAccordionList([])}>
              Collapse all
            </button>
            <button className="btn btn-link p-0" type="button" onClick={expandAll}>
              Expand all
            </button>
          </div>
        </div>
      </div>
      {isAllowedAgreementAttributesRead && dealRotor.isAgreementAttribute && (
        <AgreementAttributes dealRotor={dealRotor} dealRotorAgreementAttribute={dealRotorAgreementAttribute} />
      )}
      <Accordion activeKey={accordionList} alwaysOpen>
        {dealRotorAgreementList.map((dealRotorAgreement, index, arr) => (
          <AgreementDetails
            dealRotor={dealRotor}
            dealRotorAgreement={dealRotorAgreement}
            dealRotorAgreementAttribute={dealRotorAgreementAttribute}
            isLastAgreement={index === arr.length - 1}
            key={dealRotorAgreement.id}
            setAccordionList={setAccordionList}
          />
        ))}
      </Accordion>
    </div>
  );
}

export default AgreementDetailsTab;
