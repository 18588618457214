import { AxiosRequestConfig } from 'axios';

import { baseApi } from 'src/api';

import {
  AgreementDto,
  SignAgreementAggregatedDetailsDto,
  UpdateAgreementDetailsModel,
  CreateAddendumModel,
  AgreementDocumentType,
  SendToSigningAgreementModel,
  SignAgreementModel,
  ResendSigningInvitationModel,
  RejectAgreementModel,
  UserSignatoryDto,
  UUIDString,
} from 'src/types';

// PermissionNames.DealRotorRead
export const getAgreementList = (dealRotorId: UUIDString, config: AxiosRequestConfig = {}) =>
  baseApi.get<AgreementDto[]>('/agreements', { ...config, params: { dealRotorId } });

// PermissionNames.DealRotorAgreementWrite
export const getAgreementDetailsForSign = (agreementId: UUIDString, config: AxiosRequestConfig = {}) =>
  baseApi.get<SignAgreementAggregatedDetailsDto>(`/agreements/${agreementId}/sign-aggregated-details`, config);

// PermissionNames.DealRotorRead
export const getUsersAbleToSign = (agreementId: UUIDString, config: AxiosRequestConfig = {}) =>
  baseApi.get<UserSignatoryDto[]>(`/agreements/${agreementId}/current-able-to-sign-signatories`, config);

// PermissionNames.DealRotorRead
export const downloadAgreementDocument = (agreementDocumentId: string, config: AxiosRequestConfig = {}) =>
  baseApi.get<Blob>(`/agreements/agreement-documents/${agreementDocumentId}`, { ...config, responseType: 'blob' });

// PermissionNames.DealRotorRead
export const downloadLatestAgreementDocument = (agreementId: string, config: AxiosRequestConfig = {}) =>
  baseApi.get<Blob>(`/agreements/${agreementId}/agreement-documents/agreement`, {
    ...config,
    responseType: 'blob',
  });

// PermissionNames.DealRotorAgreementWrite
export const createAddendum = (agreementId: UUIDString, data: CreateAddendumModel, config: AxiosRequestConfig = {}) => {
  return baseApi.post<void>(`/agreements/${agreementId}/addendum`, data, config);
};

// PermissionNames.DealRotorAgreementWrite
export const discardAddendum = (agreementId: UUIDString, config: AxiosRequestConfig = {}) => {
  return baseApi.post<void>(`/agreements/${agreementId}/addendum/discard`, null, config);
};

// PermissionNames.DealRotorAgreementWrite
export const resetAgreementDetails = (agreementId: UUIDString, config: AxiosRequestConfig = {}) => {
  return baseApi.patch<void>(`/agreements/${agreementId}/reset`, null, config);
};

// PermissionNames.DealRotorAgreementWrite
export const updateAgreement = (
  agreementId: UUIDString,
  data: UpdateAgreementDetailsModel,
  config: AxiosRequestConfig = {},
) => {
  return baseApi.patch<UUIDString>(`/agreements/${agreementId}/details`, data, config);
};

// PermissionNames.DealRotorAgreementWrite
export const uploadAgreementDocument = (
  agreementId: UUIDString,
  type: AgreementDocumentType,
  data: FormData,
  config: AxiosRequestConfig = {},
) => {
  return baseApi.post<void>(`/agreements/${agreementId}/documents`, data, { ...config, params: { type } });
};

// PermissionNames.DealRotorAgreementWrite
export const removeAgreementDocument = (
  agreementId: UUIDString,
  agreementDocumentId: UUIDString,
  config: AxiosRequestConfig = {},
) => {
  return baseApi.delete<void>(`/agreements/${agreementId}/documents/${agreementDocumentId}`, config);
};

// PermissionNames.DealRotorAgreementWrite
export const moveToInactiveAgreement = (agreementId: UUIDString, config: AxiosRequestConfig = {}) => {
  return baseApi.post<void>(`/agreements/${agreementId}/move-to-inactive`, null, config);
};

// PermissionNames.DealRotorAgreementWrite
export const finaliseAgreement = (agreementId: UUIDString, config: AxiosRequestConfig = {}) => {
  return baseApi.post<void>(`/agreements/${agreementId}/finalise`, null, config);
};

// PermissionNames.DealRotorAgreementWrite
export const sendToSigningAgreement = (
  agreementId: UUIDString,
  data: SendToSigningAgreementModel,
  config: AxiosRequestConfig = {},
) => {
  return baseApi.post<void>(`/agreements/${agreementId}/send-to-signing`, data, config);
};

// PermissionNames.DealRotorAgreementWrite
export const resendSigningInvitationsAgreement = (
  agreementId: UUIDString,
  data: ResendSigningInvitationModel,
  config: AxiosRequestConfig = {},
) => {
  return baseApi.post<void>(`/agreements/${agreementId}/resend-signing-invitations`, data, config);
};

// PermissionNames.DealRotorAgreementWrite
export const cancelSigningAgreement = (agreementId: UUIDString, config: AxiosRequestConfig = {}) => {
  return baseApi.post<void>(`/agreements/${agreementId}/cancel-signing`, null, config);
};

// PermissionNames.DealRotorAgreementSign
export const signAgreement = (agreementId: UUIDString, data: SignAgreementModel, config: AxiosRequestConfig = {}) => {
  return baseApi.post<void>(`/agreements/${agreementId}/sign`, data, config);
};

// PermissionNames.DealRotorAgreementSign
export const downloadSignPreviewAgreementDocument = (
  agreementId: string,
  data: SignAgreementModel,
  config: AxiosRequestConfig = {},
) =>
  baseApi.post<Blob>(`/agreements/${agreementId}/sign/preview`, data, {
    ...config,
    responseType: 'blob',
  });

// PermissionNames.DealRotorAgreementSign
export const rejectAgreement = (
  agreementId: UUIDString,
  data: RejectAgreementModel,
  config: AxiosRequestConfig = {},
) => {
  return baseApi.post<void>(`/agreements/${agreementId}/sign/reject`, data, config);
};
