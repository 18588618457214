import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';

import Field from 'src/components/forms/Field';
import LoadingButton from 'src/components/buttons/LoadingButton';
import ServerErrorMessages from 'src/components/ServerErrorMessages';

import { useFormErrors } from 'src/hooks/FormHelpers';

import { getOrganisationContactsFromSalesforce } from 'src/api/base/integration';

import { GetDataModel, GetSFOrganisationResponse, YupFormShape, ServerError } from 'src/types';

type Props = {
  setOrganisationContacts: React.Dispatch<React.SetStateAction<GetSFOrganisationResponse[] | undefined>>;
};

type FormData = GetDataModel;
type FormShape = YupFormShape<FormData>;

const formSchema = object().shape<FormShape>({
  clientId: string().label('Client id').trim().required(),
  clientSecret: string().label('Client secret').trim().required(),
  userName: string().label('User name').trim().required(),
  password: string().label('Password').trim().required(),
});

function IntegrationSalesForceAuthForm({ setOrganisationContacts }: Props) {
  const { register, control, handleSubmit, formState, setError, clearErrors } = useForm<FormData>({
    resolver: yupResolver(formSchema),
  });
  const { serverErrorMessages, handleErrors } = useFormErrors<FormData>(setError, clearErrors);

  const onSubmit = handleSubmit(async (formData) => {
    setOrganisationContacts(undefined);
    handleErrors();
    try {
      const res = await getOrganisationContactsFromSalesforce(formData);
      setOrganisationContacts(res.data.items);
    } catch (e) {
      handleErrors(e as ServerError);
    }
  });

  return (
    <form className="card p-4" name="salesforceIntegrationForm" onSubmit={onSubmit} noValidate>
      <div className="row g-3 mb-3">
        <Field
          className="col-sm-6 col-md-4 col-xl-3"
          label="Client id"
          field="input"
          type="text"
          placeholder="Enter client Id"
          autoComplete="off"
          register={register('clientId')}
          control={control}
          formSchema={formSchema}
          errors={formState.errors}
          autoFocus
        />
        <Field
          className="col-sm-6 col-md-4 col-xl-3"
          label="Client secret"
          field="input"
          type="text"
          placeholder="Enter client secret"
          autoComplete="off"
          register={register('clientSecret')}
          control={control}
          formSchema={formSchema}
          errors={formState.errors}
        />
        <Field
          className="col-sm-6 col-md-4 col-xl-3"
          label="User name"
          field="input"
          type="text"
          placeholder="Enter user name"
          autoComplete="off"
          register={register('userName')}
          control={control}
          formSchema={formSchema}
          errors={formState.errors}
        />
        <Field
          className="col-sm-6 col-md-4 col-xl-3"
          label="Password"
          field="input"
          type="text"
          placeholder="Enter password"
          autoComplete="off"
          register={register('password')}
          control={control}
          formSchema={formSchema}
          errors={formState.errors}
        />
      </div>
      <div className="d-flex justify-content-end">
        <LoadingButton className="btn btn-primary" type="submit" isLoading={formState.isSubmitting}>
          Preview companies
        </LoadingButton>
      </div>
      <ServerErrorMessages className="mt-3 mb-0" messages={serverErrorMessages} />
    </form>
  );
}

export default IntegrationSalesForceAuthForm;
