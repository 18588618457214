import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Modal } from 'react-bootstrap';
import { useParams, Params } from 'react-router-dom';

import LoadingButton from 'src/components/buttons/LoadingButton';
import ServerErrorMessages from 'src/components/ServerErrorMessages';

import ServerErrorAdapter from 'src/utils/ServerErrorAdapter';

import { finaliseAgreement } from 'src/api/base/agreement';

import { AgreementDto, ServerError, ServerFormErrors } from 'src/types';

type Props = {
  dealRotorAgreement: AgreementDto;
  isAgreementCanFinalise: boolean;
};

function AgreementDetailsFinalise({ dealRotorAgreement, isAgreementCanFinalise }: Props) {
  const queryClient = useQueryClient();
  const { dealRotorId = '' } = useParams<Params<'dealRotorId'>>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [serverErrorMessages, setServerErrorMessages] = useState<ServerFormErrors>([]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onConfirmFinalise = async () => {
    try {
      await finaliseAgreement(dealRotorAgreement.id);

      await Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: ['getDealRotorById', dealRotorId] }),
        queryClient.invalidateQueries({ queryKey: ['getAgreementList', dealRotorId] }),
        queryClient.invalidateQueries({ queryKey: ['getDealRotorAuditListSearch', dealRotorId] }),
      ]);
      queryClient.removeQueries({ queryKey: ['getDealRotorListSearch'] });
      queryClient.removeQueries({ queryKey: ['getDealRotorAuditListAll'] });

      closeModal();
    } catch (e) {
      const serverErrors = new ServerErrorAdapter(e as ServerError);
      setServerErrorMessages(serverErrors.combine());
    }
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-outline-primary"
        onClick={() => setIsModalOpen(true)}
        disabled={!isAgreementCanFinalise}
      >
        Finalise
      </button>

      <Modal show={isModalOpen} onHide={closeModal} size="lg" centered>
        <Modal.Header onHide={closeModal} closeButton>
          <Modal.Title className="h5">
            Are you sure you want to finalise the {dealRotorAgreement.type.toLocaleLowerCase()}?
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <button type="button" className="btn btn-link" onClick={closeModal}>
            No
          </button>
          <LoadingButton type="button" className="btn btn-primary" onClick={onConfirmFinalise}>
            Yes
          </LoadingButton>
          <ServerErrorMessages className="w-100 mt-2 mb-0" messages={serverErrorMessages} />
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AgreementDetailsFinalise;
