import classNames from 'classnames';
import { PropsWithChildren } from 'react';

import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';

import styles from './Layout.module.scss';

function Layout({ children }: PropsWithChildren) {
  return (
    <div className={classNames(styles.Root, 'd-flex flex-column')}>
      <Header />
      <div className={classNames('d-flex flex-grow-1 flex-row')}>
        <Sidebar />
        <div className={classNames(styles.Content, 'd-flex flex-column flex-grow-1 custom-scroll')}>
          <main className={classNames(styles.Main, 'd-flex flex-column flex-grow-1')}>{children}</main>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Layout;
