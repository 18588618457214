import axios from 'axios';
import { useState, useCallback, useEffect, useRef } from 'react';
import { Button, Toast, ToastContainer } from 'react-bootstrap';

const VERSION_CHECK_TIME = 1 * 60 * 1000; // 1 min

function CheckNewVersion() {
  const initialVersionRef = useRef('');
  const [isShowPopup, setIsShowPopup] = useState(false);

  const checkVersion = useCallback(async () => {
    const res = await axios.get<string>('version.txt', { baseURL: '/' });
    const version = res.data;
    if (initialVersionRef.current) {
      if (initialVersionRef.current !== version) {
        setIsShowPopup(true);
      }
    } else {
      // Set initial version
      initialVersionRef.current = version;
    }
  }, []);

  useEffect(() => {
    checkVersion();
    const intervalId = setInterval(() => {
      checkVersion();
    }, VERSION_CHECK_TIME);
    return () => {
      clearInterval(intervalId);
    };
  }, [checkVersion]);

  const refreshPage = () => window.location.reload();

  return (
    <ToastContainer className="m-4" position="bottom-start">
      <Toast bg="light" show={isShowPopup} style={{ width: 'auto' }}>
        <Toast.Body className="hstack gap-2">
          <span className="fw-medium fs-6">A new version of the application is available.</span>
          <Button className="text-uppercase p-0" variant="link" onClick={refreshPage}>
            Refresh
          </Button>
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
}

export default CheckNewVersion;
