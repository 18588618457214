function parsePublicToken(token: string | null) {
  if (!token) return '';
  try {
    const decodedToken: unknown = JSON.parse(window.atob(token));
    // Validate token format
    if (
      typeof decodedToken === 'object' &&
      decodedToken !== null &&
      'AccessToken' in decodedToken &&
      typeof decodedToken.AccessToken === 'string'
    ) {
      // Destructuring to satisfy TypeScript
      const { AccessToken } = decodedToken;
      return AccessToken;
    }
  } catch (e) {}
  return '';
}

export default parsePublicToken;
