import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetStore } from 'src/store';
import { TenantPublicSettingsDto, TenantSettingDto } from 'src/types';

type TenantState = {
  tenantPublicSettings: TenantPublicSettingsDto;
  tenantSetting: TenantSettingDto | null;
};

const initialState: TenantState = {
  tenantPublicSettings: {
    logoUrl: null,
    name: '',
  },
  tenantSetting: null,
};

const tenantSlice = createSlice({
  name: 'tenant',
  initialState,
  reducers: {
    setTenantPublicSettings(state: TenantState, action: PayloadAction<TenantPublicSettingsDto>) {
      state.tenantPublicSettings = action.payload;
    },
    setTenantSettings(state: TenantState, action: PayloadAction<TenantSettingDto | null>) {
      state.tenantSetting = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Cleanup
    builder.addCase(resetStore, () => initialState);
  },
});

export const { setTenantPublicSettings, setTenantSettings } = tenantSlice.actions;

export default tenantSlice.reducer;
