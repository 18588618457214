import { AxiosRequestConfig } from 'axios';
import { useEffect, useMemo, memo } from 'react';
import { useQuery } from '@tanstack/react-query';

import styles from './SignaturePreview.module.scss';

type Props = {
  isAllowedUserReadSignature: boolean;
  signatureFilename: string;
  downloadUserSignatureRequest: (signatureFilename: string, config?: AxiosRequestConfig) => Promise<Blob>;
};

function SignaturePreview({ isAllowedUserReadSignature, signatureFilename, downloadUserSignatureRequest }: Props) {
  const { data: signatureBlob } = useQuery({
    enabled: isAllowedUserReadSignature,
    queryKey: ['downloadUserSignature', signatureFilename],
    async queryFn({ signal }) {
      const config = { signal };
      const blob = await downloadUserSignatureRequest(signatureFilename, config);
      return blob;
    },
    retry: false,
    refetchOnWindowFocus: false,
  });
  const blobURL = useMemo(() => (signatureBlob ? window.URL.createObjectURL(signatureBlob) : ''), [signatureBlob]);
  // Clean-up
  useEffect(() => () => window.URL.revokeObjectURL(blobURL), [blobURL]);

  return blobURL ? <img className={styles.Img} src={blobURL} alt="participant signature" /> : 'Signed';
}

export default memo(SignaturePreview);
