import { AxiosRequestConfig } from 'axios';

import { baseApi } from 'src/api';
import { GetTenantTimezoneResponse } from 'src/types';

// IgnorePermissions
export const getTimezoneAll = (config: AxiosRequestConfig = {}) => {
  return baseApi.get<Record<string, string>>('/timezone', config);
};

// PermissionNames.TimezoneRead;
export const getTimezone = (config: AxiosRequestConfig = {}) => {
  return baseApi.get<GetTenantTimezoneResponse>('/timezone/current', config);
};

// PermissionNames.TimezoneWrite
export const updateTimezone = (timezoneId: string) => {
  return baseApi.patch<void>('/timezone', null, { params: { timezoneId } });
};
