import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetStore } from 'src/store';
import {
  GlobalChatMessageModel,
  SetGlobalChatHistoryPayloadAction,
  AddGlobalMessagePayloadAction,
  UpdateGlobalMessagePayloadAction,
} from 'src/types/chat';

type GlobalChatState = {
  chatHistory: string | null;
  chatConversation: GlobalChatMessageModel[];
};

const initialState: GlobalChatState = { chatHistory: null, chatConversation: [] };

const globalChatSlice = createSlice({
  name: 'GlobalChat',
  initialState,
  reducers: {
    setGlobalChatHistory(state: GlobalChatState, action: PayloadAction<SetGlobalChatHistoryPayloadAction>) {
      const { chatHistory } = action.payload;
      state.chatHistory = chatHistory;
      return state;
    },
    addGlobalChatMessage(state: GlobalChatState, action: PayloadAction<AddGlobalMessagePayloadAction>) {
      const { message } = action.payload;
      state.chatConversation.push(message);
      return state;
    },
    updateGlobalChatMessage(state: GlobalChatState, action: PayloadAction<UpdateGlobalMessagePayloadAction>) {
      const { messageId, messagePatch } = action.payload;
      const messageIndex = state.chatConversation.findLastIndex((msg) => msg.id === messageId);
      if (messageIndex !== -1) {
        state.chatConversation[messageIndex] = {
          ...state.chatConversation[messageIndex],
          ...messagePatch,
        };
      }
      return state;
    },
    removeLoadingGlobalChatMessage(state: GlobalChatState) {
      state.chatConversation = state.chatConversation.filter(({ isLoading }) => !isLoading);
      return state;
    },
  },
  extraReducers: (builder) => {
    // Cleanup
    builder.addCase(resetStore, () => initialState);
  },
});

export const { setGlobalChatHistory, addGlobalChatMessage, updateGlobalChatMessage, removeLoadingGlobalChatMessage } =
  globalChatSlice.actions;

export default globalChatSlice.reducer;
