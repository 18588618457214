import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { Outlet, generatePath, Link } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { useForm, UseFormWatch, UseFormHandleSubmit } from 'react-hook-form';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { OverlayTrigger, Popover, Modal } from 'react-bootstrap';

import Dot from 'src/components/Dot';
import Field from 'src/components/forms/Field';
import Table, { createColumnHelper, useTableChangeParams } from 'src/components/Table';
import LoadingButton from 'src/components/buttons/LoadingButton';
import Avatar from 'src/components/Avatar';
import ServerErrorMessages from 'src/components/ServerErrorMessages';
import { PlusIcon } from 'src/components/icons';

import useIsMount from 'src/hooks/useIsMount';
import useUserPermission from 'src/hooks/useUserPermission';
import useUrlQueryFilters from 'src/hooks/useUrlQueryFilters';

import Converter from 'src/utils/Converter';
import ServerErrorAdapter from 'src/utils/ServerErrorAdapter';
import RequestSearchParamsAdapter from 'src/utils/RequestSearchParamsAdapter';

import HeaderConfig from 'src/pages/configuration/components/HeaderConfig';

import { getUserListSearch, updateUserStatusDeactivate, updateUserStatusActivate } from 'src/api/base/user';

import {
  YupFormShape,
  UserAccessStatus,
  GetUserDto,
  ServerFormErrors,
  ServerError,
  SelectOption,
  PermissionNames,
} from 'src/types';

import styles from './UserConfig.module.scss';

type FormData = {
  search: string;
  accessStatus: UserAccessStatus;
};
type FormShape = YupFormShape<FormData>;

const formSchema = object().shape<FormShape>({
  search: string().label('Search').emptyToUndefined(),
  accessStatus: string().label('User status').emptyToUndefined(),
});

const userStatusColorMap: Record<UserAccessStatus, 'success' | 'yellow' | 'warning' | 'info'> = {
  [UserAccessStatus.Active]: 'success',
  [UserAccessStatus.Invited]: 'yellow',
  [UserAccessStatus.Deactivated]: 'warning',
  [UserAccessStatus.NotInvited]: 'info',
};

const userAccessStatusOptions: SelectOption[] = Object.values(UserAccessStatus).map((value) => ({
  value,
  label: (
    <div className="hstack gap-2">
      <Dot color={userStatusColorMap[value]} />
      <span>{Converter.stripUpperToCapitalizedFirst(value)}</span>
    </div>
  ),
}));

type ChangeUserStatusModalProps = {
  user: GetUserDto | null;
  setUser: React.Dispatch<React.SetStateAction<GetUserDto | null>>;
  refreshTable: () => Promise<any>;
};

function ChangeUserStatusModal({ user, setUser, refreshTable }: ChangeUserStatusModalProps) {
  const [serverErrorMessages, setServerErrorMessages] = useState<ServerFormErrors>([]);

  useEffect(() => {
    setServerErrorMessages([]);
  }, [user]);

  if (!user) return;

  const title = `Are you sure you want to ${
    user.accessStatus === UserAccessStatus.Active ? 'deactivate' : 'activate'
  } ${user.fullName}?`;
  const confirmText = user.accessStatus === UserAccessStatus.Active ? 'Deactivate' : 'Activate';
  const closeModal = () => setUser(null);

  const updateUserStatusRequest = async () => {
    try {
      if (user.accessStatus === UserAccessStatus.Active) {
        await updateUserStatusDeactivate(user.id);
      } else {
        await updateUserStatusActivate(user.id);
      }
      await refreshTable();
      closeModal();
    } catch (e) {
      const serverErrors = new ServerErrorAdapter(e as ServerError);
      setServerErrorMessages(serverErrors.combine());
    }
  };

  return (
    <Modal show={true} onHide={closeModal} size="lg" centered>
      <Modal.Header onHide={closeModal} closeButton>
        <Modal.Title className="h5">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <button type="button" className="btn btn-link" onClick={closeModal}>
          Cancel
        </button>
        <LoadingButton type="button" className="btn btn-primary" onClick={updateUserStatusRequest}>
          {confirmText}
        </LoadingButton>
        <ServerErrorMessages className="w-100" messages={serverErrorMessages} />
      </Modal.Footer>
    </Modal>
  );
}

const columnHelper = createColumnHelper<GetUserDto>();

function useFormattedWatch(
  isDataLoaded: boolean,
  watch: UseFormWatch<FormData>,
  handleSubmit: UseFormHandleSubmit<FormData, undefined>,
  resetPage: () => void,
): FormData {
  const isMount = useIsMount();
  const [data, setData] = useState<FormData>({} as FormData);
  const { search, accessStatus } = watch();

  useEffect(() => {
    handleSubmit((formData) => {
      if (isMount) return;
      setData({ ...formData });
      if (isDataLoaded) {
        resetPage();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, accessStatus]);

  return data;
}

export const UserConfigList = () => {
  const [userToChangeStatus, setUserToChangeStatus] = useState<GetUserDto | null>(null);
  const { hasUserPermission } = useUserPermission();

  const [isFiltersInitialized, setIsFiltersInitialized] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false); // Used to enable reset paging

  const { register, control, formState, setValue, watch, handleSubmit } = useForm<FormData>({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
  });

  const { pagination, setPagination, sorting, setSorting, resetPage } = useTableChangeParams({});
  const { search, accessStatus } = useFormattedWatch(isDataLoaded, watch, handleSubmit, resetPage);

  const initialFilters = useUrlQueryFilters({ search, accessStatus }, { pagination, sorting });

  useEffect(() => {
    if (initialFilters.search) {
      setValue('search', initialFilters.search);
    }
    if (initialFilters.accessStatus) {
      setValue('accessStatus', initialFilters.accessStatus);
    }

    if (initialFilters.pagination) {
      setPagination(initialFilters.pagination);
    }

    if (initialFilters.sorting) {
      setSorting(initialFilters.sorting);
    }

    // 1ms timeout to get rid of 2 requests
    setTimeout(() => {
      setIsFiltersInitialized(true);
    }, 1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFilters]);

  const {
    isLoading,
    data: paginatedData,
    error,
    refetch,
  } = useQuery({
    enabled: isFiltersInitialized,
    queryKey: ['getUserListSearch', search, pagination, sorting, accessStatus],
    async queryFn({ signal }) {
      const config = { signal };
      const params = { ...new RequestSearchParamsAdapter({ search, pagination, sorting }), accessStatus };
      const res = await getUserListSearch(params, config);
      return res.data;
    },
    placeholderData: keepPreviousData,
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 3 * 60 * 1000, // 3 min
  });

  const { data, pageCount, total } = useMemo(
    () => ({
      data: paginatedData?.items ?? [],
      pageCount: paginatedData?.total ? Math.ceil(paginatedData.total / pagination.pageSize) : 1,
      total: paginatedData?.total ?? 0,
    }),
    [paginatedData, pagination.pageSize],
  );

  const errorMessages = useMemo<ServerFormErrors>(
    () => new ServerErrorAdapter(error as ServerError).combine(),
    [error],
  );

  const isAllowCreateUser = hasUserPermission(PermissionNames.UserWrite);

  useEffect(() => {
    if (paginatedData) {
      setIsDataLoaded(true);
    }
  }, [paginatedData]);

  useEffect(() => {
    // Keep in mind that data can be loaded from the cache
    if (isFiltersInitialized && isDataLoaded) {
      resetPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting]);

  const columns = [
    columnHelper.accessor('fullName', {
      cell: ({ getValue, row }) => (
        <div className="d-flex align-items-center gap-2">
          <Avatar src={row.original.logoUrl} alt={getValue() || ''} />
          <Link className="fw-medium" to={generatePath(RoutePath.userProfileById, { userId: row.original.id })}>
            {getValue()}
          </Link>
        </div>
      ),
      id: 'firstName',
      header: 'Name',
    }),
    columnHelper.accessor('email', {
      id: 'email',
      header: 'Email',
    }),
    columnHelper.accessor('accessStatus', {
      cell: ({ getValue }) => (
        <div className="d-flex align-items-center gap-2">
          <Dot color={userStatusColorMap[getValue()] as any} />
          <span>{Converter.stripUpperToCapitalizedFirst(getValue())}</span>
        </div>
      ),
      id: 'accessStatus',
      header: 'Status',
      enableSorting: false,
    }),
    columnHelper.accessor('roles', {
      cell: ({ getValue }) => {
        const [firstRole, ...restRoles] = getValue() || [];
        return (
          <div className="hstack gap-2">
            {firstRole && <div>{firstRole.displayName}</div>}
            {restRoles.length > 0 && (
              <OverlayTrigger
                placement="bottom"
                rootClose={true}
                trigger={['click']}
                overlay={
                  <Popover className="mb-3" body>
                    <ul className="list-unstyled mb-0">
                      {restRoles.map((role) => (
                        <li className={classNames(styles.ToolTip, 'text-secondary fs-14')} key={role.id}>
                          {role.displayName}
                        </li>
                      ))}
                    </ul>
                  </Popover>
                }
              >
                <button
                  type="button"
                  className="btn btn-link fs-14 lh-1 fw-normal text-nowrap p-0"
                >{`+${restRoles.length} more`}</button>
              </OverlayTrigger>
            )}
          </div>
        );
      },
      id: 'role',
      header: 'Role',
      enableSorting: false,
    }),
    columnHelper.display({
      id: 'action',
      header: 'Action',
      enableSorting: false,
      cell: ({ row }) => {
        const { accessStatus } = row.original;

        if (accessStatus === UserAccessStatus.Active || accessStatus === UserAccessStatus.Deactivated) {
          return (
            <button
              type="button"
              className="btn btn-link text-nowrap ps-0"
              onClick={() => setUserToChangeStatus(row.original)}
              disabled={userToChangeStatus !== null}
            >
              {accessStatus === UserAccessStatus.Active ? 'Make inactive' : 'Make active'}
            </button>
          );
        }
      },
    }),
  ];

  return (
    <>
      <div className="bg-white border rounded-top border-bottom-0">
        <form name="userListFilters" noValidate>
          <div className="row gx-3 p-3">
            <Field
              className="col-md-4 mb-2"
              field="input"
              type="search"
              placeholder="Search"
              autoComplete="off"
              register={register('search')}
              control={control}
              formSchema={formSchema}
              errors={formState.errors}
            />
            <Field
              className="col-md-4 mb-2"
              field="dropdown"
              placeholder={
                (
                  <div className="hstack gap-2">
                    <Dot color="metallic-silver" />
                    <span>All</span>
                  </div>
                ) as any
              }
              autoComplete="off"
              register={register('accessStatus')}
              control={control}
              formSchema={formSchema}
              errors={formState.errors}
              options={userAccessStatusOptions}
              dropdownProps={{
                isSearchable: false,
                isClearable: true,
              }}
            />
            {isAllowCreateUser && (
              <div className="col-md-4 mb-2 d-flex justify-content-end">
                <Link className="btn btn-primary" to={RoutePath.configurationUserCreate}>
                  Create user <PlusIcon />
                </Link>
              </div>
            )}
          </div>
        </form>
        <ServerErrorMessages messages={errorMessages} />
      </div>
      <Table
        className={classNames(styles.Table, 'table-border-bottom-none mb-0')}
        wrapperClassName="border rounded-bottom border-top-0 mb-3"
        data={data}
        total={total}
        columns={columns}
        setSorting={setSorting}
        sorting={sorting}
        pagination={pagination}
        setPagination={setPagination}
        isLoading={isLoading}
        pageCount={pageCount}
      />
      <ChangeUserStatusModal user={userToChangeStatus} setUser={setUserToChangeStatus} refreshTable={refetch} />
    </>
  );
};

const UserConfig = () => {
  const breadcrumbPaths = [
    { name: 'Home', to: RoutePath.root },
    { name: 'Configuration', to: RoutePath.configurationRoot },
  ];

  return (
    <div className="h-100">
      <HeaderConfig className="mb-4" title="User management" breadcrumbPaths={breadcrumbPaths} />
      <Outlet />
    </div>
  );
};

export default UserConfig;
