import { useEffect, useMemo } from 'react';
import { useQuery, useQueryClient, keepPreviousData } from '@tanstack/react-query';
import { RoutePath } from 'src/router';

import { useForm } from 'react-hook-form';
import { bool, object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Field from 'src/components/forms/Field';
import LoadingButton from 'src/components/buttons/LoadingButton';
import ServerErrorMessages from 'src/components/ServerErrorMessages';

import { useFormErrors } from 'src/hooks/FormHelpers';
import HeaderConfig from 'src/pages/configuration/components/HeaderConfig';
import ServerErrorAdapter from 'src/utils/ServerErrorAdapter';

import { getChatGlobalSettings, updateChatSettings } from 'src/api/base/chat';
import useAlertStatus from 'src/hooks/useAlertStatus';

import { YupFormShape, ChatSettingDto, ServerError } from 'src/types';

type FormData = ChatSettingDto;
type FormShape = YupFormShape<FormData>;

const formSchema = object().shape<FormShape>({
  isChatEnabledByDefault: bool().label('Turn on/off').default(false),
  isChatEnabled: bool().label('isChatEnabled').default(false),
});

const CognitiveServicesConfig = () => {
  const queryClient = useQueryClient();
  const { setAlertState, AlertStatus: CognitiveServicesAlert } = useAlertStatus({ delay: 3500 });
  const { register, control, formState, setValue, handleSubmit, watch, setError, clearErrors } = useForm<FormData>({
    defaultValues: formSchema.cast({}),
    resolver: yupResolver(formSchema),
  });
  const { serverErrorMessages, handleErrors } = useFormErrors<FormData>(setError, clearErrors);

  const breadcrumbPaths = [
    { name: 'Home', to: RoutePath.root },
    { name: 'Configuration', to: RoutePath.configurationRoot },
  ];

  const chatEnabled = watch('isChatEnabled');

  const {
    isLoading: isChatSettingsLoading,
    data: chatSettings,
    error: chatSettingsError,
  } = useQuery({
    queryKey: ['getChatGlobalSettings'],
    async queryFn() {
      const res = await getChatGlobalSettings();
      return res.data;
    },
    placeholderData: keepPreviousData,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const errorMessages = useMemo(() => {
    const chatSettingsErrorMessages = new ServerErrorAdapter(chatSettingsError as ServerError).combine();
    return [...serverErrorMessages, ...chatSettingsErrorMessages];
  }, [serverErrorMessages, chatSettingsError]);

  const onSubmit = handleSubmit(async ({ isChatEnabled, isChatEnabledByDefault }) => {
    const payload = { isChatEnabled, isChatEnabledByDefault: isChatEnabled ? isChatEnabledByDefault : false };
    handleErrors();
    try {
      await updateChatSettings(payload);
      await queryClient.invalidateQueries({ queryKey: ['getChatGlobalSettings'] });
      setAlertState(true);
    } catch (e) {
      handleErrors(e as ServerError);
    }
  });

  useEffect(() => {
    if (!chatSettings) return;
    setValue('isChatEnabled', chatSettings.isChatEnabled);
    setValue('isChatEnabledByDefault', chatSettings.isChatEnabledByDefault);
  }, [chatSettings, setValue]);

  return (
    <div className="h-100">
      <HeaderConfig className="mb-4" title="Cognitive services" breadcrumbPaths={breadcrumbPaths} />
      <div className="bg-white border rounded p-3 mb-4">
        <form className="row g-3" name="cognitiveServicesForm" onSubmit={onSubmit} noValidate>
          <Field
            className="col-12"
            field="input"
            label="Turn on/off"
            type="checkbox"
            register={register('isChatEnabled')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
          />

          {chatEnabled && (
            <Field
              className="col-12"
              field="input"
              type="checkbox"
              label="Chat enabled by default"
              register={register('isChatEnabledByDefault')}
              control={control}
              formSchema={formSchema}
              errors={formState.errors}
            />
          )}

          <div className="vstack gap-3">
            <LoadingButton
              className="btn btn-primary ms-auto"
              type="submit"
              disabled={isChatSettingsLoading}
              isLoading={formState.isSubmitting}
            >
              Save
            </LoadingButton>

            <ServerErrorMessages className="w-100 mb-0" messages={errorMessages} />
          </div>
        </form>
      </div>
      <CognitiveServicesAlert>Chat settings saved</CognitiveServicesAlert>
    </div>
  );
};

export default CognitiveServicesConfig;
