import DealRotorEditDetailedTabs from './DealRotorEdit.detailed.tabs';
import DealRotorEditDetailedActivity from './DealRotorEdit.detailed.activity';

import useUserPermission from 'src/hooks/useUserPermission';

import { PermissionNames } from 'src/types';

function DealRotorEditDetailed() {
  const { hasUserPermission } = useUserPermission();
  const isAllowedDealRotorAuditRead = hasUserPermission(PermissionNames.DealRotorAuditRead);

  // "/deal-rotor/edit/:dealRotorId/detailed/*"
  return (
    <div className="row flex-grow-1 g-4">
      <div className={isAllowedDealRotorAuditRead ? 'col-lg-8 col-xxl-9' : 'col-12'}>
        <DealRotorEditDetailedTabs />
      </div>
      {isAllowedDealRotorAuditRead && (
        <div className="col-lg-4 col-xxl-3">
          <DealRotorEditDetailedActivity />
        </div>
      )}
    </div>
  );
}

export default DealRotorEditDetailed;
