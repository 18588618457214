import { useMemo } from 'react';
import { ChevronDownIcon } from './';

type Props = {
  left?: boolean;
  right?: boolean;
  up?: boolean;
  down?: boolean;
};

function ChevronIcon({ left, right, up, down, style, ...props }: Props & React.SVGProps<SVGSVGElement>) {
  const internalStyle = useMemo(() => {
    const degree = left ? 90 : up ? 180 : down ? 0 : 270;
    return degree ? { transform: `rotate(${degree}deg)` } : {};
  }, [left, up, down]);
  return <ChevronDownIcon style={{ ...style, ...internalStyle }} {...props} />;
}

export default ChevronIcon;
