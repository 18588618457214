import { useEffect } from 'react';
import { useQuery, keepPreviousData } from '@tanstack/react-query';

import { useSelector, useDispatch } from 'src/store';
import { authUserTenantIdSelector } from 'src/store/selectors/authSelector';
import { setTenantSettings } from 'src/store/slices/tenantSlice';

import { getTenantSettings } from 'src/api/base/tenantSetting';

import { TenantSettingDto } from 'src/types';

function TenantSettings() {
  const dispatch = useDispatch();
  const authUserTenantId = useSelector(authUserTenantIdSelector);

  const { data: tenantSetting } = useQuery({
    queryKey: ['getTenantSettings', authUserTenantId],
    async queryFn({ signal }): Promise<TenantSettingDto | null> {
      if (!authUserTenantId) return null;
      const config = { signal };
      const res = await getTenantSettings(config);
      return res.data;
    },
    initialData: null,
    placeholderData: keepPreviousData,
    retry: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    dispatch(setTenantSettings(tenantSetting));
  }, [dispatch, tenantSetting]);

  return <></>;
}

export default TenantSettings;
