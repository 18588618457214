import { AxiosRequestConfig } from 'axios';

import { baseApi } from 'src/api';

import { Base64String, UUIDString, PreviewAgreementTemplateModel, RenderAgreementTemplateModel } from 'src/types';

export const createTemplatePreview = (
  agreementId: UUIDString,
  templateId: UUIDString,
  data: PreviewAgreementTemplateModel,
  config: AxiosRequestConfig = {},
) => {
  return baseApi.post<Base64String>(`/agreements/${agreementId}/templates/${templateId}/preview`, data, config);
};

export const createTemplatePDF = (
  agreementId: UUIDString,
  templateId: UUIDString,
  data: RenderAgreementTemplateModel,
  config: AxiosRequestConfig = {},
) => {
  return baseApi.post<Blob>(`/agreements/${agreementId}/templates/${templateId}/render-and-add-to-agreement`, data, {
    ...config,
    responseType: 'blob',
  });
};
