import { useCallback } from 'react';
import { useSelector } from 'src/store';
import { userPermissionsSelector } from 'src/store/selectors/profileSelector';

import { PermissionNames } from 'src/types';

function useUserPermission() {
  const userPermissions = useSelector(userPermissionsSelector);

  const hasUserPermission = useCallback(
    (allowedPermission: PermissionNames): boolean =>
      !!userPermissions.find((userPermission) => userPermission === allowedPermission),
    [userPermissions],
  );

  const hasUserAllowedPermissions = useCallback(
    (allowedPermissions?: PermissionNames[]): boolean =>
      allowedPermissions
        ? !!allowedPermissions.find((allowedPermission) => hasUserPermission(allowedPermission))
        : true,
    [hasUserPermission],
  );

  return { hasUserPermission, hasUserAllowedPermissions };
}

export default useUserPermission;
