import { AxiosRequestConfig } from 'axios';

import { baseApi } from 'src/api';
import {
  NotificationDto,
  RoleDto,
  UUIDString,
  CreateAgreementEndDateNotificationModel,
  UpdateNotificationModel,
} from 'src/types';

// PermissionNames.NotificationRead
export const getAllNotifications = (config: AxiosRequestConfig = {}) =>
  baseApi.get<NotificationDto[]>('/notifications', config);

// PermissionNames.NotificationRead
export const getAllowedRoles = (config: AxiosRequestConfig = {}) =>
  baseApi.get<RoleDto[]>('/notifications/allowed-roles', config);

// PermissionNames.NotificationWrite
export const createAgreementEndDateNotification = (
  data: CreateAgreementEndDateNotificationModel,
  config: AxiosRequestConfig = {},
) => baseApi.post<UUIDString>('/notifications/agreement-end-date', data, config);

// Remove notificationId only for Custom source type
// PermissionNames.NotificationWrite
export const removeAgreementEndDateNotification = (notificationId: UUIDString, config: AxiosRequestConfig = {}) =>
  baseApi.delete<void>(`/notifications/${notificationId}/agreement-end-date`, config);

// PermissionNames.NotificationWrite
export const updateNotification = (
  notificationId: UUIDString,
  data: UpdateNotificationModel,
  config: AxiosRequestConfig = {},
) => baseApi.patch<void>(`/notifications/${notificationId}`, data, config);
