import { AxiosRequestConfig } from 'axios';

import { baseApi } from 'src/api';
import { CreateRoleDto, PermissionDto, GetRoleDto, PermissionRoleDto, UUIDString } from 'src/types';

// PermissionNames.RoleRead
export const getAllPermissions = (config: AxiosRequestConfig = {}) => {
  return baseApi.get<PermissionDto[]>('/permissions', config);
};

// PermissionNames.RoleRead
export const getAllRoles = (config: AxiosRequestConfig = {}) => {
  return baseApi.get<GetRoleDto[]>('/permissions/roles', config);
};

// PermissionNames.RoleRead
export const getAllPermissionRoles = (config: AxiosRequestConfig = {}) => {
  return baseApi.get<PermissionRoleDto>('/permissions/permission-roles', config);
};

// PermissionNames.RoleWrite
export const putRole = (data: CreateRoleDto[], config: AxiosRequestConfig = {}) => {
  return baseApi.put<void>('/permissions', data, config);
};

// PermissionNames.RoleWrite
export const removeRole = (roleId: UUIDString, config: AxiosRequestConfig = {}) => {
  return baseApi.delete<void>(`/permissions/roles/${roleId}`, config);
};
