import { PropsWithChildren, ReactNode, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import Breadcrumbs from 'src/components/Breadcrumbs';

type Breadcrumb = {
  name: string;
  to: string;
};
type Props = {
  title: string;
  description?: string | ReactNode;
  breadcrumbPaths: Breadcrumb[];
  className?: string;
};

const HeaderConfig = ({ title, description, breadcrumbPaths, className, children }: PropsWithChildren<Props>) => {
  const location = useLocation();

  const breadcrumbs = useMemo(
    () => [...breadcrumbPaths, { name: title, to: location }],
    [location, title, breadcrumbPaths],
  );

  return (
    <>
      <Breadcrumbs className="mb-4" breadcrumbs={breadcrumbs} />
      <header className={className}>
        <div>
          <h1 className="h5 mb-0">{title}</h1>
          {description && <div className="text-secondary mb-0">{description}</div>}
        </div>
        {children}
      </header>
    </>
  );
};

export default HeaderConfig;
