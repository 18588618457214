import classNames from 'classnames';
import { useState, useEffect, useId } from 'react';
import { Modal } from 'react-bootstrap';
import { Dropdown } from 'react-bootstrap';

import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import Field from 'src/components/forms/Field';
import LoadingButton from 'src/components/buttons/LoadingButton';
import { PlusIcon } from 'src/components/icons';

import { RoleType, YupFormShape } from 'src/types';

export type FormTableData = {
  permissionsCollection: RoleTable[];
};

export type RoleTable = {
  id: string;
  name: string;
  type: RoleType | null;
  permissionIds: string[];
};

type RemoveRoleModal = {
  id: string;
  name: string;
  isShow: boolean;
};

type RoleTableHead = {
  id: string;
  name: string;
  isShow: boolean;
};

type RenameCustomRoleType = {
  roleId: string;
  name: string;
};

type AddRoleButtonProps = {
  addRole: (roleName: string) => void;
};

type TableHeadProps = {
  options: Omit<RoleTable, 'permissionIds'>[];
  removeRole: (roleId: string) => Promise<void>;
  editRole: (role: RenameCustomRoleType) => void;
};

type EditRoleFormData = {
  roleName: string;
};

type EditRoleFormShape = YupFormShape<EditRoleFormData>;

const editRoleFormSchema = object().shape<EditRoleFormShape>({
  roleName: string().label('Role name').trim().required(),
});

type RoleModalFormProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (roleFormData: EditRoleFormData) => void;
  roleName?: string;
};

export const TableHead = ({ options, removeRole, editRole }: TableHeadProps) => {
  const [removeRoleModal, setRemoveRoleModal] = useState<RemoveRoleModal>({ id: '', name: '', isShow: false });
  const [editRoleModal, setEditRoleModal] = useState<RemoveRoleModal>({ id: '', name: '', isShow: false });

  const onOpenEditRoleModal = ({ id, name, isShow }: RoleTableHead) => setEditRoleModal({ id, name, isShow });
  const onCloseEditRoleModal = () => setEditRoleModal({ id: '', name: '', isShow: false });
  const onConfirmEditRoleModal = (formRoleData: EditRoleFormData) => {
    const role = {
      roleId: editRoleModal.id,
      name: formRoleData.roleName,
    };
    editRole(role);
  };

  const onOpenRemoveRoleModal = ({ id, name, isShow }: RoleTableHead) => setRemoveRoleModal({ id, name, isShow });
  const onCloseRemoveRoleModal = () => setRemoveRoleModal({ id: '', name: '', isShow: false });
  const onConfirmRemoveRoleModal = async (id: string) => {
    await removeRole(id);
    onCloseRemoveRoleModal();
  };

  return (
    <tr>
      <th></th>
      {options.map((option) => {
        return option.type === RoleType.System ? (
          <th className={classNames('fw-medium align-middle text-secondary')} key={option.id}>
            <div>{option.name}</div>
          </th>
        ) : (
          <th className="fw-medium align-middle" key={option.id}>
            <Dropdown drop="down">
              <Dropdown.Toggle className="px-0" variant="link">
                {option.name}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => onOpenEditRoleModal({ id: option.id, name: option.name, isShow: true })}>
                  Edit role
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => onOpenRemoveRoleModal({ id: option.id, name: option.name, isShow: true })}
                >
                  Remove role
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </th>
        );
      })}
      <Modal show={removeRoleModal.isShow} onHide={onCloseRemoveRoleModal} centered>
        <Modal.Header onHide={onCloseRemoveRoleModal} closeButton>
          <Modal.Title className="h5">Are you sure to remove {removeRoleModal.name} role ?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <button type="button" className="btn btn-link" onClick={onCloseRemoveRoleModal}>
            No
          </button>
          <LoadingButton
            type="button"
            className="btn btn-danger"
            onClick={() => onConfirmRemoveRoleModal(removeRoleModal.id)}
          >
            Yes, remove
          </LoadingButton>
        </Modal.Footer>
      </Modal>
      <EditRoleModal
        isOpen={editRoleModal.isShow}
        onClose={onCloseEditRoleModal}
        onConfirm={onConfirmEditRoleModal}
        roleName={editRoleModal.name}
      />
    </tr>
  );
};

export const EditRoleModal = ({ isOpen, onClose, onConfirm, roleName = '' }: RoleModalFormProps) => {
  const roleModalId = useId();
  const { register, control, handleSubmit, formState, setValue, reset } = useForm<EditRoleFormData>({
    resolver: yupResolver(editRoleFormSchema),
  });

  const onSubmit = handleSubmit(async (formData) => {
    onConfirm(formData);
    reset({ roleName: '' });
    onClose();
  });

  const handleClose = () => {
    reset({ roleName: '' });
    onClose();
  };

  useEffect(() => {
    if (!roleName) return;
    setValue('roleName', roleName);
  }, [roleName, setValue]);

  return (
    <Modal show={isOpen} onHide={handleClose} centered>
      <Modal.Header onHide={handleClose} closeButton>
        <Modal.Title className="h5">Create role</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form name="editRoleForm" id={roleModalId} onSubmit={onSubmit} noValidate>
          <Field
            className="col"
            label="Role name"
            field="input"
            type="text"
            placeholder="Enter role name"
            autoComplete="off"
            register={register('roleName')}
            control={control}
            formSchema={editRoleFormSchema}
            errors={formState.errors}
            autoFocus
          />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-link" onClick={handleClose}>
          Cancel
        </button>
        <LoadingButton type="submit" className="btn btn-primary" form={roleModalId}>
          Continue
        </LoadingButton>
      </Modal.Footer>
    </Modal>
  );
};

export const AddRoleButton = ({ addRole }: AddRoleButtonProps) => {
  const [isShow, setIsShow] = useState<boolean>(false);
  const onOpen = () => setIsShow(true);
  const onClose = () => setIsShow(false);
  const onConfirm = (formRoleData: EditRoleFormData) => addRole(formRoleData.roleName);

  return (
    <>
      <button className="btn btn-link p-0" type="button" onClick={onOpen}>
        <PlusIcon /> Add new role
      </button>
      <EditRoleModal isOpen={isShow} onClose={onClose} onConfirm={onConfirm} />
    </>
  );
};
