import classNames from 'classnames';
import { useMemo } from 'react';
import { Link, generatePath } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { object, string } from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import Dot from 'src/components/Dot';
import Field from 'src/components/forms/Field';
import Table, { createColumnHelper, useTableChangeParams } from 'src/components/Table';
import FormatDate from 'src/components/FormatDate';
import ServerErrorAdapter from 'src/utils/ServerErrorAdapter';
import ServerErrorMessages from 'src/components/ServerErrorMessages';
import RequestSearchParamsAdapter from 'src/utils/RequestSearchParamsAdapter';
import useAlertStatus from 'src/hooks/useAlertStatus';

import { getAgreementFullStatus } from 'src/utils/statusFormat';
import { agreementStageColorMap, agreementStageOptions } from 'src/utils/selectOptions';

import { getDealRotorListSearchByUserId } from 'src/api/base/dealRotor';

import { ServerError, YupFormShape, ServerFormErrors, AgreementStage, DealRotorSummaryDto } from 'src/types';

type DealsListProps = {
  hasGlobalDealRotorManagerRole: boolean;
  isAllowedOrganisationRead: boolean;
  userId: string | undefined;
};
type FormData = {
  search: string;
  agreementStage: AgreementStage | '';
};
type FormShape = YupFormShape<FormData>;
const formSchema = object().shape<FormShape>({
  search: string().label('Search').trim(),
  agreementStage: string().trim().label('Agreement stage'),
});

const columnHelper = createColumnHelper<DealRotorSummaryDto>();

const DealsList = ({ hasGlobalDealRotorManagerRole, isAllowedOrganisationRead, userId }: DealsListProps) => {
  const { AlertStatus: DealListAlert } = useAlertStatus({
    initState: true,
    dismissible: false,
    innerProps: { alertWrapper: { className: 'mb-0' } },
  });
  const { register, control, formState, watch } = useForm<FormData>({
    resolver: yupResolver(formSchema),
    mode: 'all',
    defaultValues: {
      search: '',
      agreementStage: '',
    },
  });

  const searchValue = watch('search');
  const search = searchValue.trim() || undefined;
  const agreementStageValue = watch('agreementStage');
  const agreementStage = agreementStageValue || undefined;

  const { pagination, setPagination, sorting, setSorting } = useTableChangeParams({});

  const {
    data: userDeals,
    error: userDealsErrorError,
    isLoading,
  } = useQuery({
    queryKey: ['getDealRotorListSearchByUserId', userId, pagination, sorting, agreementStage, search],
    async queryFn({ signal }) {
      if (!hasGlobalDealRotorManagerRole || !userId) return null;
      const config = { signal };
      const params = { ...new RequestSearchParamsAdapter({ search, pagination, sorting }), agreementStage };
      const res = await getDealRotorListSearchByUserId(userId, params, config);
      return res.data;
    },
    placeholderData: keepPreviousData,
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 3 * 60 * 1000, // 3 min
  });

  const { data, pageCount, total } = useMemo(
    () => ({
      data: userDeals?.items ?? [],
      pageCount: userDeals?.total ? Math.ceil(userDeals.total / pagination.pageSize) : 1,
      total: userDeals?.total ?? 0,
    }),
    [userDeals, pagination.pageSize],
  );

  const errorMessages = useMemo<ServerFormErrors>(
    () => new ServerErrorAdapter(userDealsErrorError as ServerError).combine(),
    [userDealsErrorError],
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor('partyB', {
        id: 'partyB',
        header: 'Party B',
        enableSorting: false,
        cell: ({ getValue }) => {
          const [firstOrganisation, ...restOrganisations] = getValue() || [];
          return (
            <div className="hstack gap-2">
              {firstOrganisation && (
                <>
                  {isAllowedOrganisationRead ? (
                    <Link
                      className="link-dark fw-medium"
                      to={generatePath(RoutePath.partyBManagementById, { organisationId: firstOrganisation.id })}
                    >
                      {firstOrganisation.name}
                    </Link>
                  ) : (
                    <span className="fw-medium">{firstOrganisation.name}</span>
                  )}
                </>
              )}

              {restOrganisations.length > 0 && (
                <OverlayTrigger
                  placement="bottom"
                  rootClose={true}
                  trigger={['click']}
                  overlay={
                    <Popover className=" mb-3">
                      <ul className="dropdown-menu show position-relative border-0 bg-transparent">
                        {restOrganisations.map(({ name, id }) => (
                          <li key={id}>
                            {isAllowedOrganisationRead ? (
                              <Link
                                className="dropdown-item"
                                to={generatePath(RoutePath.partyBManagementById, { organisationId: id })}
                              >
                                {name}
                              </Link>
                            ) : (
                              <span className="dropdown-item">{name}</span>
                            )}
                          </li>
                        ))}
                      </ul>
                    </Popover>
                  }
                >
                  <button
                    type="button"
                    className="btn btn-link fs-14 lh-1 fw-normal text-nowrap p-0"
                  >{`+${restOrganisations.length} more`}</button>
                </OverlayTrigger>
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor('name', {
        id: 'name',
        header: 'Deal name',
      }),
      columnHelper.accessor('number', {
        id: 'number',
        header: 'Deal number',
        cell: ({ row, getValue }) => {
          const to =
            row.original.partyB && row.original.partyB.length
              ? generatePath(RoutePath.dealRotorEditDetailedDealRotorDetails, { dealRotorId: row.original.id })
              : generatePath(RoutePath.dealRotorCreateDetails, { dealRotorId: row.original.id });
          return <Link to={to}>{getValue()}</Link>;
        },
      }),
      columnHelper.accessor('relationshipType', {
        id: 'relationshipType',
        header: 'Deal rel. type',
      }),
      columnHelper.accessor('agreementStage', {
        id: 'agreementStage',
        header: 'Agreement status',
        cell: ({ row }) => {
          const { agreementStage, agreementState, agreementStatus } = row.original;
          const fullStatus = getAgreementFullStatus(agreementStage, agreementState, agreementStatus);
          return (
            <div className="hstack gap-2">
              <Dot color={agreementStage ? agreementStageColorMap[agreementStage] : 'metallic-silver'} />
              <span>{fullStatus}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor('updatedDate', {
        id: 'updatedDate',
        header: 'Last update',
        cell: ({ getValue }) => <FormatDate date={getValue()} formatValue="dd/MM/yyyy - HH:mm:ss" />,
      }),
    ],
    [isAllowedOrganisationRead],
  );

  return (
    <>
      <div
        className={classNames('p-4 bg-white border rounded  ', {
          'border-bottom-0': hasGlobalDealRotorManagerRole,
          'rounded-bottom-0': hasGlobalDealRotorManagerRole,
        })}
      >
        <h2 className="h5 mb-4">Deal rotor list</h2>
        {hasGlobalDealRotorManagerRole ? (
          <form name="profileDealListFilters" noValidate>
            <div className="row g-3 mb-3">
              <Field
                className="col-sm-6 col-md-4 col-lg-4 col-xl-3"
                field="input"
                type="search"
                placeholder="Search"
                autoComplete="off"
                register={register('search')}
                control={control}
                formSchema={formSchema}
                errors={formState.errors}
              />
              <Field
                className="col-sm-6 col-md-4 col-lg-4 col-xl-3"
                field="dropdown"
                placeholder={
                  (
                    <div className="hstack gap-2">
                      <Dot color="metallic-silver" />
                      <span>All</span>
                    </div>
                  ) as any
                }
                autoComplete="off"
                register={register('agreementStage')}
                control={control}
                formSchema={formSchema}
                errors={formState.errors}
                options={agreementStageOptions}
                dropdownProps={{
                  isSearchable: false,
                  isClearable: true,
                }}
              />
            </div>
          </form>
        ) : (
          <DealListAlert>Not available for your role</DealListAlert>
        )}
      </div>
      {hasGlobalDealRotorManagerRole && (
        <Table
          className="mb-0"
          wrapperClassName="border rounded-bottom border-top-0 mb-3"
          data={data}
          total={total}
          columns={columns}
          setSorting={setSorting}
          sorting={sorting}
          pagination={pagination}
          setPagination={setPagination}
          isLoading={isLoading}
          pageCount={pageCount}
        />
      )}

      <ServerErrorMessages className="mt-3" messages={errorMessages} />
    </>
  );
};

export default DealsList;
