import { PaginationState, SortingState } from '@tanstack/react-table';

import { SearchOrder } from 'src/types';

type ConstructorParams = {
  search?: string;
  pagination: PaginationState;
  sorting: SortingState | [void];
};

class RequestSearchParamsAdapter {
  take?: number;
  skip?: number;
  search?: string;
  orderField?: string;
  orderBy?: SearchOrder;

  constructor({ search, pagination: { pageSize, pageIndex }, sorting }: ConstructorParams) {
    this.take = pageSize;
    this.skip = pageSize * pageIndex;
    this.search = search;
    if (sorting[0]) {
      this.orderField = sorting[0].id;
      this.orderBy = sorting[0].desc ? SearchOrder.desc : SearchOrder.asc;
    }
  }
}

export default RequestSearchParamsAdapter;
