import classNames from 'classnames';
import { AxiosRequestConfig } from 'axios';
import { useState, useMemo } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import SpinnerLoader from 'src/components/SpinnerLoader';
import TabNavigation from 'src/components/TabNavigation';
import PreviewDocument from 'src/components/PreviewDocument';
import ServerErrorMessages from 'src/components/ServerErrorMessages';

import SignDocument from './components/SignDocument';
import SignDocumentFinish from './components/SignDocumentFinish';
import AgreementSignatoryList from './components/AgreementSignatoryList';
import AgreementDocumentList from './components/AgreementDocumentList';

import useUrlQuery from 'src/hooks/useUrlQuery';

import {
  GetAgreementSignatoryDto,
  AgreementSummaryDto,
  RejectAgreementModel,
  SignAgreementModel,
  SignatureType,
  OrganisationType,
  ServerFormErrors,
} from 'src/types';

import styles from './Notarise.module.scss';

type Props = {
  className?: string;

  isAllowedDealRotorAgreementSign: boolean;
  isAllowedUserReadSignature: boolean;

  userId: string;
  userSignatureFilename: string | null;

  isAgreementDetailsForSignLoading: boolean;
  dealRotorNumber: string | null;
  agreementSignatoryList: GetAgreementSignatoryDto[] | null;
  agreementDocumentList: AgreementSummaryDto[] | null;

  isLatestDocumentLoading: boolean;
  latestDocument: Blob | undefined;

  signatureFilename: string;
  setSignatureFilename: React.Dispatch<React.SetStateAction<string>>;

  downloadUserSignatureRequest: (signatureFilename: string, config?: AxiosRequestConfig) => Promise<Blob>;
  uploadUserSignatureRequest: (type: SignatureType, data: FormData) => Promise<string>;
  signAgreementRequest: (data: SignAgreementModel) => Promise<any>;
  rejectAgreementRequest: (data: RejectAgreementModel) => Promise<any>;

  refetchAgreementDetailsForSign: () => Promise<any>;
  refetchDownloadLatestAgreementDocument: () => Promise<any>;
  refetchUserSignatureFilename: () => Promise<any>;

  errorMessages: ServerFormErrors;
};

function Notarise({
  className,

  isAllowedDealRotorAgreementSign,
  isAllowedUserReadSignature,

  userId,
  userSignatureFilename,

  isAgreementDetailsForSignLoading,
  dealRotorNumber,
  agreementSignatoryList,
  agreementDocumentList,

  isLatestDocumentLoading,
  latestDocument,

  signatureFilename,
  setSignatureFilename,

  downloadUserSignatureRequest,
  uploadUserSignatureRequest,
  signAgreementRequest,
  rejectAgreementRequest,

  refetchAgreementDetailsForSign,
  refetchDownloadLatestAgreementDocument,
  refetchUserSignatureFilename,

  errorMessages,
}: Props) {
  const urlQuery = useUrlQuery();
  const token = urlQuery.get('token');

  const tabs = useMemo(
    () => [
      { name: 'Signatories', to: token ? `signatories?token=${token}` : 'signatories' },
      { name: 'Deal documents', to: token ? `documents?token=${token}` : 'documents' },
    ],
    [token],
  );

  const [isSignDocumentFinished, setIsSignDocumentFinished] = useState(false);
  const hasSignatureFilename = Boolean(signatureFilename);

  const { partiesText, currentUserPartyType, agreementSignatoryTuple } = useMemo(() => {
    const partyAOrganisationNames = new Set<string>();
    const partyBOrganisationNames = new Set<string>();

    const signingPartyFirst: OrganisationType =
      agreementSignatoryList && agreementSignatoryList.length > 0
        ? agreementSignatoryList[0].partyType
        : OrganisationType.PartyA;

    const agreementSignatoryGrouped: Record<string, GetAgreementSignatoryDto[]> = {};
    let currentUserPartyType = null as OrganisationType | null;
    (agreementSignatoryList || []).forEach((signatory) => {
      if (signatory.partyType === OrganisationType.PartyA && signatory.partyName) {
        partyAOrganisationNames.add(signatory.partyName);
      } else if (signatory.partyType === OrganisationType.PartyB && signatory.partyName) {
        partyBOrganisationNames.add(signatory.partyName);
      }

      const partyName = signatory.partyName || 'N/A';
      if (!Array.isArray(agreementSignatoryGrouped[partyName])) {
        agreementSignatoryGrouped[partyName] = [];
      }
      agreementSignatoryGrouped[partyName].push(signatory);

      if (signatory.userId === userId) {
        currentUserPartyType = signatory.partyType;
      }
    });

    let partiesText = '';
    if (partyAOrganisationNames.size && partyBOrganisationNames.size) {
      const partyAs = Array.from(partyAOrganisationNames).join(', ');
      const partyBs = Array.from(partyBOrganisationNames).join(', ');
      partiesText =
        signingPartyFirst === OrganisationType.PartyA ? `${partyAs} & ${partyBs}` : `${partyBs} & ${partyAs}`;
    } else if (partyAOrganisationNames.size) {
      partiesText = Array.from(partyAOrganisationNames).join(', ');
    } else if (partyBOrganisationNames.size) {
      partiesText = Array.from(partyBOrganisationNames).join(', ');
    }

    return {
      partiesText,
      currentUserPartyType,
      agreementSignatoryTuple: Object.entries(agreementSignatoryGrouped),
    };
  }, [userId, agreementSignatoryList]);

  const headerText = `This document is to be signed by ${partiesText}`;

  return (
    <div className={classNames(className, 'container vstack px-0 h-100')}>
      <header className="h5 text-truncate flex-shrink-0 mb-4">{headerText}</header>
      <ServerErrorMessages messages={errorMessages} />
      <div className="row flex-grow-1 g-3">
        <div className="col-lg-8 col-sm-12 order-lg-1 order-md-2">
          {isLatestDocumentLoading ? (
            <SpinnerLoader />
          ) : (
            <>
              {latestDocument && (
                <div className="position-relative h-100">
                  <PreviewDocument
                    document={latestDocument}
                    hasSignatureFilename={hasSignatureFilename}
                    isSignDocumentFinished={isSignDocumentFinished}
                  />
                  {hasSignatureFilename && (
                    <SignDocument
                      signatureFilename={signatureFilename}
                      setSignatureFilename={setSignatureFilename}
                      setIsSignDocumentFinished={setIsSignDocumentFinished}
                      signAgreementRequest={signAgreementRequest}
                      refetchAgreementDetailsForSign={refetchAgreementDetailsForSign}
                      refetchDownloadLatestAgreementDocument={refetchDownloadLatestAgreementDocument}
                    />
                  )}
                  {isSignDocumentFinished && (
                    <SignDocumentFinish
                      partiesText={partiesText}
                      dealRotorNumber={dealRotorNumber}
                      currentUserPartyType={currentUserPartyType}
                      setIsSignDocumentFinished={setIsSignDocumentFinished}
                    />
                  )}
                </div>
              )}
            </>
          )}
        </div>
        <div className="col-lg-4 col-sm-12 order-lg-2 order-md-1">
          <div className="bg-white border rounded h-100">
            <TabNavigation className="border-bottom px-3 pt-1" tabs={tabs} />
            <div className={classNames(styles.TabScrollableBody, 'custom-scroll')}>
              <Routes>
                <Route index element={<Navigate to={tabs[0].to} replace={true} />} />

                <Route
                  path="signatories"
                  element={
                    isAgreementDetailsForSignLoading ? (
                      <SpinnerLoader />
                    ) : (
                      <AgreementSignatoryList
                        isAllowedDealRotorAgreementSign={isAllowedDealRotorAgreementSign}
                        isAllowedUserReadSignature={isAllowedUserReadSignature}
                        userId={userId}
                        userSignatureFilename={userSignatureFilename}
                        downloadUserSignatureRequest={downloadUserSignatureRequest}
                        uploadUserSignatureRequest={uploadUserSignatureRequest}
                        rejectAgreementRequest={rejectAgreementRequest}
                        refetchAgreementDetailsForSign={refetchAgreementDetailsForSign}
                        refetchDownloadLatestAgreementDocument={refetchDownloadLatestAgreementDocument}
                        refetchUserSignatureFilename={refetchUserSignatureFilename}
                        agreementSignatoryTuple={agreementSignatoryTuple}
                        hasSignatureFilename={hasSignatureFilename}
                        setSignatureFilename={setSignatureFilename}
                      />
                    )
                  }
                />
                <Route
                  path="documents"
                  element={
                    isAgreementDetailsForSignLoading ? (
                      <SpinnerLoader />
                    ) : (
                      <AgreementDocumentList agreementDocumentList={agreementDocumentList || []} />
                    )
                  }
                />

                <Route path="*" element={<Navigate to="./" />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Notarise;
