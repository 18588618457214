import { AxiosResponse, AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios';

declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean;
  }
}

class Download {
  /**
   * Downloads file from Blob
   */
  static fileFromBlob = (blob: Blob, filename: string): void => {
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
      window.navigator.msSaveBlob(blob, filename);
    } else {
      const blobURL = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobURL;
      link.setAttribute('download', filename);
      link.setAttribute('hidden', 'hidden');

      if (typeof link.download === 'undefined') {
        link.setAttribute('target', '_blank');
      }

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      window.URL.revokeObjectURL(blobURL);
    }
  };

  /**
   * Downloads file from ArrayBuffer
   */
  static fileFromArrayBuffer(data: ArrayBuffer, filename: string, mime = 'application/octet-stream'): void {
    const blob = new Blob([data], { type: mime });
    return this.fileFromBlob(blob, filename);
  }

  /**
   * Returns extracted file name or empty string.
   */
  private static getFileNameFromHeaders(headers: RawAxiosResponseHeaders | AxiosResponseHeaders): string {
    const contentDispositionHeaderValue: string = headers['content-disposition'] || '';
    const matches: Array<string | void> = contentDispositionHeaderValue.match(/filename="?([^;"]+)/) || [];
    return matches[1] || '';
  }

  /**
   * Downloads a file with the name specified in the response "content-disposition" header.
   */
  static fileFromRes(res: AxiosResponse<Blob | ArrayBuffer>, fallbackFilename: string): void {
    const { headers, data } = res;
    const fileName = this.getFileNameFromHeaders(headers) || fallbackFilename || '';
    if (data instanceof Blob) {
      return this.fileFromBlob(data, fileName);
    } else if (data instanceof ArrayBuffer) {
      return this.fileFromArrayBuffer(data, fileName);
    }
  }
}

export default Download;
