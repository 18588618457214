import { useCallback, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, Link, generatePath } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import Field from 'src/components/forms/Field';
import Table, { createColumnHelper, useTableChangeParams } from 'src/components/Table';
import Avatar from 'src/components/Avatar';
import FormatDate from 'src/components/FormatDate';
import ServerErrorMessages from 'src/components/ServerErrorMessages';
import { PlusIcon, RemoveIcon, EditIcon } from 'src/components/icons';

import useUserPermission from 'src/hooks/useUserPermission';

import { getTemplateListSearch, removeTemplate } from 'src/api/base/template';

import ServerErrorAdapter from 'src/utils/ServerErrorAdapter';
import RequestSearchParamsAdapter from 'src/utils/RequestSearchParamsAdapter';

import { TemplateWithUserDto, YupFormShape, ServerFormErrors, ServerError, PermissionNames } from 'src/types';

type FormData = {
  search: string;
};

type FormShape = YupFormShape<FormData>;

const formSchema = object().shape<FormShape>({
  search: string().label('Search'),
});

const columnHelper = createColumnHelper<TemplateWithUserDto & { actions: string }>();

const TemplatesList = () => {
  const { hasUserPermission } = useUserPermission();
  const isAllowedUserRead = hasUserPermission(PermissionNames.UserRead);
  const isAllowedUserReadContacts = hasUserPermission(PermissionNames.UserReadContacts);
  const isAllowedTemplateWrite = hasUserPermission(PermissionNames.TemplateWrite);

  const navigate = useNavigate();
  const { register, control, formState, watch } = useForm<FormData>({
    resolver: yupResolver(formSchema),
    mode: 'all',
    defaultValues: {
      search: '',
    },
  });

  const search = watch('search').trim();
  const { pagination, setPagination, sorting, setSorting } = useTableChangeParams({});

  const {
    isLoading,
    data: templateList,
    error,
    refetch,
  } = useQuery({
    queryKey: ['getTemplateListSearch', search, pagination, sorting],
    async queryFn({ signal }) {
      const config = { signal };
      const params = { ...new RequestSearchParamsAdapter({ search, pagination, sorting }) };
      const res = await getTemplateListSearch(params, config);
      return res.data;
    },
  });

  const deleteTemplate = useCallback(
    async (id: string) => {
      await removeTemplate(id);
      refetch();
    },
    [refetch],
  );

  const columns = useMemo(
    () =>
      [
        columnHelper.accessor('name', {
          id: 'name',
          header: 'Name',
        }),
        columnHelper.accessor('createdByUserFullname', {
          cell: ({ getValue, row }) => (
            <div className="d-flex align-items-center gap-2">
              <Avatar src={row.original.createdByUserLogo} alt={row.original.createdByUserFullname || ''} />
              {isAllowedUserRead ? (
                <Link
                  className="fw-medium"
                  to={generatePath(RoutePath.userProfileById, { userId: row.original.createdByUserId })}
                >
                  {getValue()}
                </Link>
              ) : isAllowedUserReadContacts ? (
                <Link
                  className="fw-medium"
                  to={generatePath(RoutePath.userContactById, { userId: row.original.createdByUserId })}
                >
                  {getValue()}
                </Link>
              ) : (
                <span className="fw-medium">{getValue()}</span>
              )}
            </div>
          ),
          id: 'createdByUserId',
          header: 'Created by',
        }),
        columnHelper.accessor('createdDate', {
          cell: ({ getValue }) => <FormatDate date={getValue()} formatValue="dd/MM/yyyy - HH:mm:ss" />,
          id: 'createdDate',
          header: 'Date created',
        }),
        columnHelper.accessor('actions', {
          cell: ({ row }) => (
            <div className="hstack gap-3 justify-content-center">
              <button className="btn btn-link" type="button">
                <EditIcon
                  width={16}
                  height={16}
                  onClick={() =>
                    navigate(
                      generatePath(RoutePath.configurationTemplatesManagementEditId, { templateId: row.original.id }),
                    )
                  }
                />
              </button>
              <button className="btn btn-link" type="button">
                <RemoveIcon width={16} height={16} onClick={() => deleteTemplate(row.original.id)} />
              </button>
            </div>
          ),
          id: 'actions',
          header: '',
          enableSorting: false,
        }),
      ].filter(({ id }) => (id === 'actions' && !isAllowedTemplateWrite ? false : true)),
    [deleteTemplate, isAllowedTemplateWrite, isAllowedUserRead, isAllowedUserReadContacts, navigate],
  );

  const { data, pageCount, total } = useMemo(
    () => ({
      data: templateList?.items ?? [],
      pageCount: templateList?.total ? Math.ceil(templateList.total / pagination.pageSize) : 1,
      total: templateList?.total ?? 0,
    }),
    [templateList, pagination.pageSize],
  );

  const errorMessages = useMemo<ServerFormErrors>(
    () => new ServerErrorAdapter(error as ServerError).combine(),
    [error],
  );

  return (
    <>
      <div className="bg-white border rounded-top border-bottom-0">
        <form name="templateSearchForm" noValidate>
          <div className="row gx-3 p-3">
            <Field
              className="col-md-6 mb-2"
              field="input"
              type="search"
              placeholder="Search"
              autoComplete="off"
              register={register('search')}
              control={control}
              formSchema={formSchema}
              errors={formState.errors}
            />
            {isAllowedTemplateWrite && (
              <div className="col-md-6 mb-2 d-flex justify-content-end">
                <button
                  className="btn btn-primary"
                  type="submit"
                  onClick={() => navigate(RoutePath.configurationTemplatesManagementEdit)}
                >
                  Create template <PlusIcon />
                </button>
              </div>
            )}
          </div>
        </form>
      </div>
      <Table
        className="mb-0"
        wrapperClassName="border rounded-bottom border-top-0 mb-3"
        data={data}
        total={total}
        columns={columns}
        isLoading={isLoading}
        setSorting={setSorting}
        sorting={sorting}
        pagination={pagination}
        setPagination={setPagination}
        pageCount={pageCount}
      />
      <ServerErrorMessages messages={errorMessages} />
    </>
  );
};

export default TemplatesList;
