import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams, useOutletContext, Params, generatePath, Link } from 'react-router-dom';
import { RoutePath } from 'src/router';

import ServerErrorMessages from 'src/components/ServerErrorMessages';
import OwnerForm from './components/participantForms/OwnerForm';
import ManagerForm from './components/participantForms/ManagerForm';
import SignatoryForm from './components/participantForms/SignatoryForm';

import { DealRotorEditDetailedTabsOutletContext } from './DealRotorEdit.detailed.tabs';

import useUserPermission from 'src/hooks/useUserPermission';
import usePartyAOptionsByRoles from 'src/hooks/usePartyAOptionsByRoles';

import ServerErrorAdapter from 'src/utils/ServerErrorAdapter';

import {
  getDealRotorParticipantList,
  getDealRotorPartyBParticipantList,
  transferDealRotorOwner,
  transferOperationOwner,
  upsertDealRotorManagers,
  upsertOperationManagers,
} from 'src/api/base/dealRotorParticipants';

import {
  GetPartyBUserDto,
  RoleName,
  SelectOption,
  ServerError,
  UUIDString,
  OrganisationType,
  DealRotorActorDto,
  DealRotorStatus,
  AgreementStatus,
  PermissionNames,
} from 'src/types';

type PartyBWithUserOptions = Pick<GetPartyBUserDto, 'organisationId' | 'organisationName'> & {
  options: SelectOption[];
};

function ParticipantsTab() {
  const { hasUserPermission } = useUserPermission();
  const isAllowedDealRotorTransferOwner = hasUserPermission(PermissionNames.DealRotorTransferOwner);
  const isAllowedDealRotorTransferOperationOwner = hasUserPermission(PermissionNames.DealRotorTransferOperationOwner);
  const isAllowedDealRotorUpdateManagers = hasUserPermission(PermissionNames.DealRotorUpdateManagers);
  const isAllowedDealRotorUpdateOperationManagers = hasUserPermission(PermissionNames.DealRotorUpdateOperationManagers);
  const isAllowedDealRotorUpdateSignatories = hasUserPermission(PermissionNames.DealRotorUpdateSignatories);
  const isAllowedOrganisationRead = hasUserPermission(PermissionNames.OrganisationRead);

  const { dealRotorId = '' } = useParams<Params<'dealRotorId'>>();
  const { dealRotor } = useOutletContext<DealRotorEditDetailedTabsOutletContext>();

  // Party A options by role
  const {
    isPartyAUsersLoading,
    partyAUsersError,
    dealRotorOwnerOptions,
    operationsOwnerOptions,
    dealRotorManagerOptions,
    operationsManagerOptions,
    signatoryPartyAOptions,
  } = usePartyAOptionsByRoles();

  // Party B users
  const {
    isLoading: isPartyBUsersLoading,
    data: partyBUsers,
    error: partyBUsersError,
  } = useQuery({
    enabled: Boolean(dealRotorId),
    queryKey: ['getDealRotorPartyBParticipantList', dealRotorId],
    async queryFn({ signal }) {
      const config = { signal };
      const res = await getDealRotorPartyBParticipantList(dealRotorId, config);
      return res.data;
    },
    initialData: [],
    retry: false,
    refetchOnWindowFocus: false,
  });

  // Selected values
  const {
    isLoading: isDealRotorParticipantListLoading,
    data: dealRotorParticipantList,
    error: dealRotorParticipantListError,
  } = useQuery({
    enabled: Boolean(dealRotorId),
    queryKey: ['getDealRotorParticipantList', dealRotorId],
    async queryFn({ signal }) {
      const config = { signal };
      const res = await getDealRotorParticipantList(dealRotorId, config);
      return res.data;
    },
    initialData: [],
    retry: false,
    refetchOnWindowFocus: false,
  });

  const serverErrorMessages = useMemo(() => {
    const partyAUsersErrorMessages = new ServerErrorAdapter(partyAUsersError as ServerError).combine();
    const partyBUsersErrorMessages = new ServerErrorAdapter(partyBUsersError as ServerError).combine();
    const dealRotorParticipantListErrorMessages = new ServerErrorAdapter(
      dealRotorParticipantListError as ServerError,
    ).combine();
    return [...dealRotorParticipantListErrorMessages, ...partyBUsersErrorMessages, ...partyAUsersErrorMessages];
  }, [partyAUsersError, partyBUsersError, dealRotorParticipantListError]);

  // Party B options grouped by organisation
  const partyBWithSignatoriesOptions = useMemo(() => {
    const partBSignatoriesGrouped: Record<UUIDString, PartyBWithUserOptions> = {};
    partyBUsers
      .slice()
      .sort((a, b) => a.organisationName.localeCompare(b.organisationName))
      .forEach(({ organisationId, organisationName, userId, name }) => {
        if (!partBSignatoriesGrouped[organisationId]) {
          partBSignatoriesGrouped[organisationId] = { organisationId, organisationName, options: [] };
        }
        partBSignatoriesGrouped[organisationId].options.push({ label: name, value: userId });
      });
    return Object.values(partBSignatoriesGrouped);
  }, [partyBUsers]);

  // Selected values by role
  const {
    dealRotorOwnerId,
    operationsOwnerId,
    dealRotorManagerIds,
    operationsManagerIds,
    signatoryPartyAIds,
    signatoryPartyBs,
  } = useMemo(() => {
    let dealRotorOwnerId: UUIDString = '';
    let operationsOwnerId: UUIDString = '';
    const dealRotorManagerIds: UUIDString[] = [];
    const operationsManagerIds: UUIDString[] = [];
    const signatoryPartyAIds: UUIDString[] = [];
    const signatoryPartyBs: Pick<DealRotorActorDto, 'userId' | 'organisationId'>[] = [];

    dealRotorParticipantList.forEach(({ userId, organisationId, party, roleName }) => {
      switch (roleName) {
        case RoleName.DealRotorOwner:
          if (party === OrganisationType.PartyA) {
            dealRotorOwnerId = userId;
          }
          break;
        case RoleName.OperationsOwner:
          if (party === OrganisationType.PartyA) {
            operationsOwnerId = userId;
          }
          break;
        case RoleName.DealRotorManager:
          if (party === OrganisationType.PartyA) {
            dealRotorManagerIds.push(userId);
          }
          break;
        case RoleName.OperationsManager:
          if (party === OrganisationType.PartyA) {
            operationsManagerIds.push(userId);
          }
          break;
        case RoleName.Signatory:
          if (party === OrganisationType.PartyA) {
            signatoryPartyAIds.push(userId);
          } else if (party === OrganisationType.PartyB) {
            signatoryPartyBs.push({ userId, organisationId });
          }
          break;
      }
    });

    return {
      dealRotorOwnerId,
      operationsOwnerId,
      dealRotorManagerIds,
      operationsManagerIds,
      signatoryPartyAIds,
      signatoryPartyBs,
    };
  }, [dealRotorParticipantList]);

  const isAllowEditParticipantsForDealRotorInStatusActive =
    (dealRotor &&
      dealRotor.status === DealRotorStatus.Active &&
      dealRotor.agreementStatus !== AgreementStatus.AwaitingSignature &&
      dealRotor.agreementStatus !== AgreementStatus.Rejected &&
      dealRotor.isAgreementTerminated !== true) ||
    false;

  const isAllowEditParticipants =
    isAllowEditParticipantsForDealRotorInStatusActive || dealRotor?.status === DealRotorStatus.Ceased;

  return (
    <>
      <ServerErrorMessages className="w-100 mb-4" messages={serverErrorMessages} />
      {(isAllowedDealRotorTransferOwner ||
        isAllowedDealRotorTransferOperationOwner ||
        isAllowedDealRotorUpdateManagers ||
        isAllowedDealRotorUpdateOperationManagers ||
        isAllowedDealRotorUpdateSignatories) && (
        <section className="card mb-4">
          <header className="card-header bg-white">
            <h2 className="h5 mb-0">Party A</h2>
          </header>
          <div className="card-body row g-3">
            <div className="col-xl-6">
              <OwnerForm
                formName="dealRotorOwnerForm"
                fieldLabel="Deal owner"
                userId={dealRotorOwnerId}
                dealRotorId={dealRotorId}
                isLoading={isDealRotorParticipantListLoading}
                isUserOptionsLoading={isPartyAUsersLoading}
                userOptions={dealRotorOwnerOptions}
                isAllowEditParticipants={isAllowEditParticipants && isAllowedDealRotorTransferOwner}
                transferOwner={transferDealRotorOwner}
              />
            </div>
            <div className="col-xl-6">
              <OwnerForm
                formName="operationsOwnerForm"
                fieldLabel="Ops owner"
                userId={operationsOwnerId}
                dealRotorId={dealRotorId}
                isLoading={isDealRotorParticipantListLoading}
                isUserOptionsLoading={isPartyAUsersLoading}
                userOptions={operationsOwnerOptions}
                isAllowEditParticipants={isAllowEditParticipants && isAllowedDealRotorTransferOperationOwner}
                transferOwner={transferOperationOwner}
              />
            </div>

            <div className="col-xl-6">
              <ManagerForm
                formName="dealRotorManagerForm"
                fieldLabel="Deal managers"
                userIds={dealRotorManagerIds}
                dealRotorId={dealRotorId}
                isLoading={isDealRotorParticipantListLoading}
                isUserOptionsLoading={isPartyAUsersLoading}
                userOptions={dealRotorManagerOptions}
                isAllowEditParticipants={isAllowEditParticipants && isAllowedDealRotorUpdateManagers}
                upsertManagers={upsertDealRotorManagers}
              />
            </div>
            <div className="col-xl-6">
              <ManagerForm
                formName="operationsManagerForm"
                fieldLabel="Ops managers"
                userIds={operationsManagerIds}
                dealRotorId={dealRotorId}
                isLoading={isDealRotorParticipantListLoading}
                isUserOptionsLoading={isPartyAUsersLoading}
                userOptions={operationsManagerOptions}
                isAllowEditParticipants={isAllowEditParticipants && isAllowedDealRotorUpdateOperationManagers}
                upsertManagers={upsertOperationManagers}
              />
            </div>

            <div className="col-xl-6">
              <SignatoryForm
                formName="partyASignatoryForm"
                fieldLabel="Signatories"
                userIds={signatoryPartyAIds}
                organisationId={null}
                party={OrganisationType.PartyA}
                dealRotorId={dealRotorId}
                isLoading={isDealRotorParticipantListLoading}
                isUserOptionsLoading={isPartyAUsersLoading}
                userOptions={signatoryPartyAOptions}
                isAllowEditParticipants={isAllowEditParticipants && isAllowedDealRotorUpdateSignatories}
              />
            </div>
          </div>
        </section>
      )}

      {isAllowedDealRotorUpdateSignatories && (
        <section className="card">
          <header className="card-header bg-white">
            <h2 className="h5 mb-0">Party B</h2>
          </header>
          <div className="card-body row g-3">
            {partyBWithSignatoriesOptions.map(({ organisationId, organisationName, options }) => {
              const userIds = signatoryPartyBs.reduce<UUIDString[]>((acc, signatory) => {
                const isUserInOptionsAndInOrganisation = !!options.find(
                  ({ value }) => signatory.userId === value && signatory.organisationId === organisationId,
                );
                if (isUserInOptionsAndInOrganisation) {
                  acc.push(signatory.userId);
                }
                return acc;
              }, []);
              return (
                <div className="col-xl-6" key={organisationId}>
                  <SignatoryForm
                    formName={`partyB${organisationName.replaceAll(' ', '')}SignatoryForm`}
                    fieldLabel="Signatories"
                    userIds={userIds}
                    organisationId={organisationId}
                    party={OrganisationType.PartyB}
                    dealRotorId={dealRotorId}
                    isLoading={isDealRotorParticipantListLoading}
                    isUserOptionsLoading={isPartyBUsersLoading}
                    userOptions={options}
                    isAllowEditParticipants={isAllowEditParticipants}
                    BeforeLabel={
                      <>
                        {isAllowedOrganisationRead ? (
                          <Link className="mb-2" to={generatePath(RoutePath.partyBManagementById, { organisationId })}>
                            {organisationName}
                          </Link>
                        ) : (
                          <span className="mb-2">{organisationName}</span>
                        )}
                        <span> &mdash; </span>
                      </>
                    }
                  />
                </div>
              );
            })}
          </div>
        </section>
      )}
    </>
  );
}

export default ParticipantsTab;
