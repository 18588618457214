import classNames from 'classnames';
import { useState, useMemo } from 'react';
import { useParams, Params, useOutletContext } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Spinner } from 'react-bootstrap';

import LoadingButton from 'src/components/buttons/LoadingButton';
import ServerErrorMessages from 'src/components/ServerErrorMessages';

import AnswerSource from './components/dealRotorEdit/AnswerSource';
import AIConsoleChat from './components/dealRotorEdit/AIConsoleChat';
import { DealRotorEditDetailedTabsOutletContext } from './DealRotorEdit.detailed.tabs';

import ServerErrorAdapter from 'src/utils/ServerErrorAdapter';

import { getChatDefaultQuestions, initChatForDealRotor } from 'src/api/base/chat';
import { updateDealRotorChatEnabled } from 'src/api/base/dealRotor';

import { ServerError, ServerFormErrors } from 'src/types';

import styles from './DealRotorEdit.detailed.tabs.ai-console.module.scss';

function AIConsoleTab() {
  const queryClient = useQueryClient();
  const { dealRotorId = '' } = useParams<Params<'dealRotorId'>>();
  const { dealRotor, dealRotorAgreementList, chatSettings } =
    useOutletContext<DealRotorEditDetailedTabsOutletContext>();

  const [activateErrorMessages, setActivateServerErrorMessages] = useState<ServerFormErrors>([]);

  const isChatEnabledByDefault = chatSettings?.isChatEnabledByDefault ?? false;
  const isChatEnabledForDealRotor = dealRotor?.isChatEnabled ?? false;
  const isChatEnabled = isChatEnabledByDefault || isChatEnabledForDealRotor;

  const hasDealRotorUploadedDocuments = useMemo(() => {
    if (!dealRotorAgreementList) return false;
    return Boolean(dealRotorAgreementList.find(({ agreementDocument }) => agreementDocument !== null));
  }, [dealRotorAgreementList]);

  const {
    isFetching: isChatInitLoading,
    data: isChatInitDone,
    error: chatInitError,
  } = useQuery({
    enabled: Boolean(dealRotorId) && isChatEnabled,
    queryKey: ['initChatForDealRotor', dealRotorId],
    async queryFn({ signal }) {
      const config = { signal };
      await initChatForDealRotor(dealRotorId, config);
      return true;
    },
    initialData: false,
    retry: false,
    refetchOnWindowFocus: false,
    gcTime: 0,
  });

  const {
    isFetching: isChatDefaultQuestionsLoading,
    data: chatDefaultQuestions,
    error: chatDefaultQuestionsError,
  } = useQuery({
    enabled: Boolean(dealRotorId) && hasDealRotorUploadedDocuments && isChatEnabled && isChatInitDone,
    queryKey: ['getChatDefaultQuestions', dealRotorId],
    async queryFn({ signal }) {
      const config = { signal };
      const res = await getChatDefaultQuestions(dealRotorId, config);
      return res.data;
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  const serverErrorMessages = useMemo(() => {
    const chatInitErrorMessages = new ServerErrorAdapter(chatInitError as ServerError).combine();
    const chatDefaultQuestionsErrorMessages = new ServerErrorAdapter(
      chatDefaultQuestionsError as ServerError,
    ).combine();
    return [...chatInitErrorMessages, ...chatDefaultQuestionsErrorMessages, ...activateErrorMessages];
  }, [chatInitError, chatDefaultQuestionsError, activateErrorMessages]);

  const activateChat = async () => {
    setActivateServerErrorMessages([]);
    try {
      const payload = { isChatEnabled: true };
      await updateDealRotorChatEnabled(dealRotorId, payload);

      await Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: ['getDealRotorById', dealRotorId] }),
        queryClient.invalidateQueries({ queryKey: ['getDealRotorAuditListSearch', dealRotorId] }),
      ]);
      queryClient.removeQueries({ queryKey: ['getDealRotorAuditListAll'] });
    } catch (e) {
      const errors = new ServerErrorAdapter(e as ServerError).combine();
      setActivateServerErrorMessages(errors);
    }
  };

  const hasGlobalSpinner = chatSettings ? isChatInitLoading : true;

  return (
    <div className="d-flex flex-column h-100">
      <ServerErrorMessages className="w-100 mb-4" messages={serverErrorMessages} />
      {hasGlobalSpinner ? (
        <div className="text-center">
          <Spinner className="align-self-center" variant="primary" />
        </div>
      ) : (
        <>
          {isChatEnabled ? (
            <>
              {isChatInitDone ? (
                <div className="flex-grow-1 row g-3">
                  <section className="col-md-12 col-lg-12 col-xl-6">
                    <div className="card h-100">
                      <div className={classNames(styles.ScrollableBody, 'card-body custom-scroll')}>
                        {isChatDefaultQuestionsLoading ? (
                          <div className="text-center">
                            <Spinner className="align-self-center" variant="primary" />
                          </div>
                        ) : (
                          <>
                            <h2 className="h5 mb-4">Quick insides</h2>
                            <>
                              {chatDefaultQuestions && chatDefaultQuestions.length ? (
                                <ul className="list-unstyled vstack gap-4 mb-0">
                                  {chatDefaultQuestions.map((qa, index) => (
                                    <li key={index}>
                                      <div className="h6">{qa.question}</div>
                                      <p className="mb-0">{qa.answer.result.answer}</p>
                                      <AnswerSource sourceDocuments={qa.answer.result.source_documents} />
                                    </li>
                                  ))}
                                </ul>
                              ) : hasDealRotorUploadedDocuments ? (
                                'No quick insides'
                              ) : (
                                'There is no quick insides, please upload agreement documents.'
                              )}
                            </>
                          </>
                        )}
                      </div>
                    </div>
                  </section>

                  <section className="col-md-12 col-lg-12 col-xl-6">
                    <div className="card h-100">
                      <div className="card-body">
                        <AIConsoleChat dealRotorId={dealRotorId} />
                      </div>
                    </div>
                  </section>
                </div>
              ) : (
                <></>
              )}
            </>
          ) : (
            <LoadingButton
              className="btn btn-primary me-auto"
              onClick={activateChat}
              isLoading={isChatInitLoading}
              disabled={isChatInitLoading}
            >
              Activate
            </LoadingButton>
          )}
        </>
      )}
    </div>
  );
}

export default AIConsoleTab;
