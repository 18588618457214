import classNames from 'classnames';

import styles from './ErrorFallback.module.scss';

function ErrorFallback() {
  return (
    <div className={classNames(styles.ErrorFallback, 'd-flex justify-content-center align-items-center p-4')}>
      <div className="card text-center p-4">
        <img className="img-fluid mb-4" src="/images/logo/app-logo.png" alt="bridgingx logo" width="320" height="25" />
        <h1 className="fs-22 pt-2 mb-4">Something went wrong</h1>
        <a className="fw-medium" href="/">
          Back to home page
        </a>
      </div>
    </div>
  );
}

export default ErrorFallback;
