import { useToggle, useTimeout } from 'usehooks-ts';
import Alert from 'react-bootstrap/Alert';
import { PropsWithChildren } from 'react';
import { AlertHeadingProps } from 'react-bootstrap';

type AlertVariant = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';

export type useAlertStatusProps = {
  initState?: boolean;
  variant?: AlertVariant;
  dismissible?: boolean;
  heading?: string;
  delay?: number;
  innerProps?: {
    alertWrapper?: React.HTMLAttributes<HTMLDivElement>;
    alertHeading?: AlertHeadingProps;
  };
};

const useAlertStatus = ({ initState, variant, dismissible, heading, delay, innerProps }: useAlertStatusProps) => {
  const [alertState, alertToggle, setAlertState] = useToggle(initState ?? false);

  const AlertComponent = ({ children }: PropsWithChildren) => {
    useTimeout(alertToggle, delay && alertState ? delay : null);
    return (
      <Alert
        {...innerProps?.alertWrapper}
        show={alertState}
        onClose={() => setAlertState(false)}
        variant={variant ?? 'info'}
        dismissible={dismissible ?? true}
      >
        {heading && <Alert.Heading {...innerProps?.alertHeading}>{heading}</Alert.Heading>}
        {children}
      </Alert>
    );
  };

  return { alertToggle, setAlertState, AlertStatus: AlertComponent };
};

export default useAlertStatus;
