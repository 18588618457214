import { useCallback } from 'react';
import { useSelector } from 'src/store';
import { userRolesSelector } from 'src/store/selectors/profileSelector';

import { RoleName } from 'src/types';

function useUserRole() {
  const userRoles = useSelector(userRolesSelector);

  const hasUserRole = useCallback(
    (allowedRole: RoleName): boolean => !!userRoles.find((userRole) => userRole === allowedRole),
    [userRoles],
  );

  const hasUserAllowedRoles = useCallback(
    (allowedRoles?: RoleName[]): boolean =>
      allowedRoles ? !!allowedRoles.find((allowedRole) => hasUserRole(allowedRole)) : true,
    [hasUserRole],
  );

  return { hasUserRole, hasUserAllowedRoles };
}

export default useUserRole;
