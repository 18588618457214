import classNames from 'classnames';
import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Breadcrumbs from 'src/components/Breadcrumbs';
import { AIConsoleGlobalChat } from 'src/pages/deal-rotor/components/dealRotorEdit/AIConsoleChat';
import { RoutePath } from 'src/router';

import styles from './GlobalChatConsole.module.scss';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { getChatGlobalSettings } from 'src/api/base/chat';
import ServerErrorAdapter from 'src/utils/ServerErrorAdapter';
import { ServerError } from 'src/types';
import ServerErrorMessages from 'src/components/ServerErrorMessages';
import useAlertStatus from 'src/hooks/useAlertStatus';
import SpinnerLoader from 'src/components/SpinnerLoader';

const GlobalChatConsolePage = () => {
  const { AlertStatus: GlobalChatAlert } = useAlertStatus({
    initState: true,
    heading: 'Chat feature is disabled',
    dismissible: false,
    innerProps: {
      alertHeading: { as: 'h2', className: 'h4' },
    },
  });
  const location = useLocation();

  const {
    isLoading: isChatSettingsLoading,
    data: chatSettings,
    error: chatSettingsError,
  } = useQuery({
    queryKey: ['getChatGlobalSettings'],
    async queryFn() {
      const res = await getChatGlobalSettings();
      return res.data;
    },
    placeholderData: keepPreviousData,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const breadcrumbs = useMemo(
    () => [
      { name: 'Home', to: RoutePath.root },
      { name: 'Global AI console', to: location },
    ],
    [location],
  );

  const errorMessages = useMemo(
    () => new ServerErrorAdapter(chatSettingsError as ServerError).combine(),
    [chatSettingsError],
  );

  function ChatDisabledContainer() {
    return (
      <GlobalChatAlert>
        Update cognitive service <Link to={RoutePath.configurationRoot}>configuration</Link> or contact your
        administrator
      </GlobalChatAlert>
    );
  }

  return (
    <div className="h-100">
      <Breadcrumbs className="mb-4" breadcrumbs={breadcrumbs} />
      <header className="mb-4">
        <h1 className="h5">Global AI console</h1>
      </header>

      <section>
        {isChatSettingsLoading ? (
          <SpinnerLoader />
        ) : chatSettings?.isChatEnabled ? (
          <div className={classNames('card', styles.chatWrapper)}>
            <div className="card-body">
              <AIConsoleGlobalChat />
            </div>
          </div>
        ) : (
          <ChatDisabledContainer />
        )}
      </section>
      <ServerErrorMessages className="w-100 mb-0" messages={errorMessages} />
    </div>
  );
};

export default GlobalChatConsolePage;
