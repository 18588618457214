import { generatePath } from 'react-router-dom';
import { RoutePath } from 'src/router';

import AgreementDocument from 'src/components/AgreementDocument';

import useUrlQuery from 'src/hooks/useUrlQuery';

import { AgreementSummaryDto } from 'src/types';

type Props = {
  agreementDocumentList: AgreementSummaryDto[];
};

function AgreementDocumentList({ agreementDocumentList }: Props) {
  const urlQuery = useUrlQuery();
  const token = urlQuery.get('token');

  return agreementDocumentList.length > 0 ? (
    <div className="vstack p-3 gap-3">
      {agreementDocumentList.map((document) => {
        const href = token
          ? `${generatePath(RoutePath.documentViewerPublic, { agreementDocumentId: document.agreementDocument.agreementDocumentId })}?token=${token}`
          : undefined;
        return (
          <AgreementDocument
            key={document.agreementDocument.agreementDocumentId}
            document={document.agreementDocument}
            isShowRemove={false}
            href={href}
          />
        );
      })}
    </div>
  ) : (
    <div className="p-3">Supporting documents are not available.</div>
  );
}

export default AgreementDocumentList;
