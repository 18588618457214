import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetStore } from 'src/store';

type AuthState = {
  accessToken: string | null;
};

const initialState: AuthState = {
  accessToken: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAccessToken(state: AuthState, action: PayloadAction<string | null>) {
      state.accessToken = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Cleanup
    builder.addCase(resetStore, () => initialState);
  },
});

export const { setAccessToken } = authSlice.actions;

export default authSlice.reducer;
