import { AxiosRequestConfig } from 'axios';

import { baseApi } from 'src/api';

import { TemplateParamDto, UUIDString, TemplateParamModel, UpdateTemplateParamModel } from 'src/types';

export const getAllTemplateParameters = (config: AxiosRequestConfig = {}) => {
  return baseApi.get<TemplateParamDto[]>('/templates/parameters', config);
};

export const createTemplateParameter = (data: TemplateParamModel, config: AxiosRequestConfig = {}) => {
  return baseApi.post<UUIDString>('/templates/parameters', data, config);
};

export const updateTemplateParameter = (data: UpdateTemplateParamModel[], config: AxiosRequestConfig = {}) => {
  return baseApi.patch<void>('/templates/parameters', data, config);
};

export const getParametersByTemplateId = (templateId: UUIDString, config: AxiosRequestConfig = {}) => {
  return baseApi.get<TemplateParamDto[]>(`/templates/${templateId}/parameters`, config);
};

export const removeParameterById = (templateParamId: UUIDString, config: AxiosRequestConfig = {}) => {
  return baseApi.delete<void>(`/templates/parameters/${templateParamId}`, config);
};
