import { Routes, Route, Navigate, Outlet, useLocation } from 'react-router-dom';
import { useMemo } from 'react';

import PartyBManagementListPage from './PartyBManagementList';
import PartyBManagementProfile from 'src/pages/party-b-management/components/PartyBManagement.profile';
import { RoutePath } from 'src/router';
import Breadcrumbs from 'src/components/Breadcrumbs';

function PartyBManagementRoutes() {
  return (
    <>
      {/* "/party-b-management/*" */}
      <Routes>
        <Route path="/*" element={<PartyBManagement />}>
          <Route path="list" element={<PartyBManagementListPage />} />
          <Route path="create" element={<PartyBManagementProfile />} />
          <Route path=":organisationId" element={<PartyBManagementProfile />} />
          <Route path="*" element={<Navigate to="list" replace />} />
        </Route>

        <Route path="*" element={<Navigate to="./" />} />
      </Routes>
    </>
  );
}

export default PartyBManagementRoutes;

function PartyBManagement() {
  const location = useLocation();

  const breadcrumbs = useMemo(
    () => [
      { name: 'Home', to: RoutePath.root },
      { name: 'Party B management', to: location },
    ],
    [location],
  );
  return (
    <div className="h-100">
      <Breadcrumbs className="mb-4" breadcrumbs={breadcrumbs} />
      <header className="mb-4">
        <h1 className="h5">Party B management</h1>
        <p className="text-secondary mb-0">Manage all Party B company and contacts</p>
      </header>
      <Outlet />
    </div>
  );
}
