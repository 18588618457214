import classNames from 'classnames';
import { useState, useEffect, useMemo } from 'react';
import { useParams, Params } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { useInView } from 'react-intersection-observer';
import { useQuery, useQueryClient, useInfiniteQuery, keepPreviousData } from '@tanstack/react-query';

import { useSelector } from 'src/store';
import { tenantTimezoneInBracketsSelector } from 'src/store/selectors/tenantSelector';

import { useForm, UseFormWatch, UseFormHandleSubmit } from 'react-hook-form';
import { object, string, boolean, array, date, DateSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Field from 'src/components/forms/Field';
import LoadingButton from 'src/components/buttons/LoadingButton';
import { FilterIcon } from 'src/components/icons';

import AuditList from './components/dealRotorEdit/AuditList';

import Converter from 'src/utils/Converter';
import LocalDate from 'src/utils/LocalDate';
import { dealRotorAuditCategoryOptions } from 'src/utils/selectOptions';

import {
  getDealRotorAuditListSearch,
  getDealRotorAuditParticipantList,
  GetDealRotorAuditListSearchParams,
} from 'src/api/base/dealRotor';

import {
  DealRotorAuditDto,
  DealRotorAuditSubCategory,
  SelectOption,
  YupFormShape,
  TakeSkip,
  DateTimeISOString,
  ServerError,
} from 'src/types';

import styles from './DealRotorEdit.module.scss';
import drStyles from './DealRotorList.module.scss';
import ServerErrorMessages from 'src/components/ServerErrorMessages';
import ServerErrorAdapter from 'src/utils/ServerErrorAdapter';

type FormData = Omit<GetDealRotorAuditListSearchParams, 'subCategory'> & {
  isOnlyStatus: boolean;
  search: string | undefined;
};
type FormShape = YupFormShape<FormData>;
const formSchema = object().shape<FormShape>({
  isOnlyStatus: boolean().label('Status only'),
  search: string().trim().label('Search').emptyToUndefined(),
  category: string().trim().label('Category').emptyToUndefined(),
  dateFrom: date()
    .label('Date from')
    .nullable()
    .transform((curr, orig) => (orig === '' ? undefined : curr)),
  dateTo: date()
    .label('Date to')
    .nullable()
    .transform((curr, orig) => (orig === '' ? undefined : curr))
    .when('dateFrom', ([value]: Array<string | Date | undefined | null>, scheme: DateSchema<any>) => {
      if (!value) return scheme;
      const dateFrom = new Date(value);
      return scheme.min(dateFrom, ({ label, min }) => {
        const dateFromFormatted = Converter.getFormattedDate(new LocalDate(min).toJSON());
        return `${label} field must be ${dateFromFormatted} or later`;
      });
    }),
  userIds: array().of(string()).label('Users').emptyToUndefined(),
});

function useFormattedWatch(
  watch: UseFormWatch<FormData>,
  handleSubmit: UseFormHandleSubmit<FormData, undefined>,
): FormData {
  const [data, setData] = useState<FormData>({} as FormData);
  const { isOnlyStatus, search, category, dateFrom, dateTo, userIds } = watch();

  useEffect(() => {
    handleSubmit((formData) => {
      setData({ ...formData });
    })();
  }, [handleSubmit, isOnlyStatus, search, category, dateFrom, dateTo, userIds]);

  return data;
}

function DealRotorEditDetailedActivity() {
  const { ref, inView } = useInView();
  const { dealRotorId = '' } = useParams<Params<'dealRotorId'>>();
  const tenantTimezoneText = useSelector(tenantTimezoneInBracketsSelector);

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const { register, control, formState, watch, handleSubmit } = useForm<FormData>({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    defaultValues: { isOnlyStatus: false, userIds: [] },
  });
  const { isOnlyStatus, search, category, dateFrom, dateTo, userIds } = useFormattedWatch(watch, handleSubmit);
  const subCategory = isOnlyStatus ? DealRotorAuditSubCategory.StatusChange : undefined;
  const dateFromRaw = watch('dateFrom');

  const queryClient = useQueryClient();
  const {
    data: auditInfinitePages,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    error: auditInfinitePagesError,
  } = useInfiniteQuery({
    enabled: Boolean(dealRotorId),
    queryKey: ['getDealRotorAuditListSearch', dealRotorId, search, category, subCategory, dateFrom, dateTo, userIds],
    initialPageParam: { take: 20, skip: 0 },
    async queryFn({ signal, pageParam: { take, skip } }) {
      const config = { signal };
      const dateFromStartDate = dateFrom ? new LocalDate(dateFrom).getStartDay().toJSON() : undefined;
      const dateToEndDate = dateTo ? new LocalDate(dateTo).getEndDay().toJSON() : undefined;
      const params = {
        search,
        take,
        skip,
        category,
        subCategory,
        dateFrom: dateFromStartDate,
        dateTo: dateToEndDate,
        userIds,
      };
      const res = await getDealRotorAuditListSearch(dealRotorId, params, config);
      return res;
    },
    getPreviousPageParam(firstPage) {
      const { take, skip } = firstPage.config.params as TakeSkip;
      if (firstPage.data.items.length < take) return undefined;
      return { take, skip: skip - take };
    },
    getNextPageParam(lastPage) {
      const { take, skip } = lastPage.config.params as TakeSkip;
      if (lastPage.data.items.length < take) return undefined;
      return { take, skip: skip + take };
    },
    placeholderData: keepPreviousData,
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: 1 * 60 * 1000, // 1 min
  });

  const auditTupleList = useMemo<[DateTimeISOString, DealRotorAuditDto[]][]>(() => {
    if (!auditInfinitePages) return [];
    const auditGrouped: Record<string, DealRotorAuditDto[]> = {};

    auditInfinitePages.pages.forEach(({ data }) => {
      data.items.forEach((audit) => {
        const dateGroup = new LocalDate(audit.createdDate).getStartDay().toJSON();
        if (!Array.isArray(auditGrouped[dateGroup])) {
          auditGrouped[dateGroup] = [];
        }
        auditGrouped[dateGroup].push(audit);
      });
    });

    return Object.entries(auditGrouped);
  }, [auditInfinitePages]);

  const {
    isLoading: isAuditParticipantOptionsLoading,
    data: auditParticipantOptions,
    error: auditParticipantOptionsError,
  } = useQuery({
    enabled: Boolean(dealRotorId),
    queryKey: ['getDealRotorAuditParticipantList', dealRotorId],
    async queryFn({ signal }): Promise<SelectOption[]> {
      const config = { signal };
      const res = await getDealRotorAuditParticipantList(dealRotorId, config);
      return res.data.map(({ id, firstName, lastName }) => ({ label: `${firstName} ${lastName}`, value: id }));
    },
    initialData: [],
    retry: false,
    refetchOnWindowFocus: false,
  });

  const serverErrorMessages = useMemo(() => {
    const auditInfinitePagesErrorMessages = new ServerErrorAdapter(auditInfinitePagesError as ServerError).combine();
    const auditParticipantOptionsErrorMessages = new ServerErrorAdapter(
      auditParticipantOptionsError as ServerError,
    ).combine();
    return [...auditInfinitePagesErrorMessages, ...auditParticipantOptionsErrorMessages];
  }, [auditInfinitePagesError, auditParticipantOptionsError]);

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);

  // Clean up paging
  useEffect(() => () => queryClient.removeQueries({ queryKey: ['getDealRotorAuditListSearch'] }), [queryClient]);

  return (
    <section className={classNames(styles.Activity, 'card h-100', { 'has-open-filter': isFilterOpen })}>
      <header className="card-header bg-white p-4">
        <form name="dealRotorActivityFilters" noValidate>
          <div className="activity-header d-flex justify-content-end flex-wrap gap-3">
            <h2 className="h5 mb-0 me-auto">Activity</h2>
            <Field
              formCheckClassName="form-check-reverse"
              field="switch"
              label="Show status only"
              autoComplete="off"
              register={register('isOnlyStatus')}
              control={control}
              formSchema={formSchema}
              errors={formState.errors}
            />
          </div>
          <div className="hstack gap-3">
            <Field
              className="flex-grow-1"
              field="input"
              type="search"
              placeholder="Search"
              autoComplete="off"
              register={register('search')}
              control={control}
              formSchema={formSchema}
              errors={formState.errors}
            />
            <button
              type="button"
              className={classNames(drStyles.FilterBtn, 'btn btn-outline-primary btn-sq', isFilterOpen && 'active')}
              onClick={() => setIsFilterOpen((state) => !state)}
            >
              <FilterIcon />
            </button>
          </div>

          <Collapse in={isFilterOpen}>
            <div>
              <div className="pt-3">
                <Field
                  className="mb-3"
                  field="dropdown"
                  label="Category"
                  placeholder="Choose category"
                  autoComplete="off"
                  register={register('category')}
                  control={control}
                  formSchema={formSchema}
                  errors={formState.errors}
                  options={dealRotorAuditCategoryOptions}
                  dropdownProps={{
                    isSearchable: true,
                    isClearable: true,
                  }}
                />

                <Field
                  className="mb-3"
                  field="datepicker"
                  label={`Date from${tenantTimezoneText}`}
                  autoComplete="off"
                  register={register('dateFrom')}
                  control={control}
                  formSchema={formSchema}
                  errors={formState.errors}
                />

                <Field
                  className="mb-3"
                  field="datepicker"
                  label={`Date to${tenantTimezoneText}`}
                  autoComplete="off"
                  register={register('dateTo')}
                  control={control}
                  formSchema={formSchema}
                  errors={formState.errors}
                  datepickerProps={{ minDate: dateFromRaw ? new Date(dateFromRaw) : null }}
                />

                <Field
                  field="dropdown"
                  label="Participants"
                  placeholder="Choose participants"
                  autoComplete="off"
                  register={register('userIds')}
                  control={control}
                  formSchema={formSchema}
                  errors={formState.errors}
                  options={auditParticipantOptions}
                  dropdownProps={{
                    isLoading: isAuditParticipantOptionsLoading,
                    isSearchable: true,
                    isClearable: true,
                  }}
                  multiple
                />
              </div>
            </div>
          </Collapse>
        </form>
      </header>
      <div className={classNames(styles.TabScrollableBody, 'card-body custom-scroll px-0 py-3')}>
        {auditTupleList.length > 0 ? (
          <AuditList auditTupleList={auditTupleList} />
        ) : serverErrorMessages.length ? (
          <ServerErrorMessages className="mx-3" messages={serverErrorMessages} />
        ) : (
          <div className="px-4">{isLoading ? 'Loading...' : 'No activity'}</div>
        )}
        {hasNextPage && (
          <div className="p-3 text-center" ref={ref}>
            <LoadingButton className="btn btn-link" disabled={isFetchingNextPage} onClick={() => fetchNextPage()}>
              {isFetchingNextPage ? 'Loading...' : 'Load more'}
            </LoadingButton>
          </div>
        )}
      </div>
    </section>
  );
}

export default DealRotorEditDetailedActivity;
