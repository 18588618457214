import classNames from 'classnames';
import { StatusCrossIcon, StatusTickIcon } from 'src/components/icons';

import { PartySigningStatus, OrganisationType } from 'src/types';

type Props = {
  isCurrentUserSignaturePending: boolean | null;
  partyASigningStatus: PartySigningStatus | null;
  partyBSigningStatus: PartySigningStatus | null;
  signingPartyFirst: OrganisationType | null;
};

type PartyStatusProps = {
  className?: string;
  partyName: string;
  partySigningStatus: PartySigningStatus;
};

function PartyStatus({ className, partyName, partySigningStatus }: PartyStatusProps) {
  return (
    <div className={classNames(className, 'hstack gap-1')}>
      <span className="text-secondary">{partyName}</span>
      {partySigningStatus === PartySigningStatus.Signed && (
        <StatusTickIcon className="text-success" width={19} height={19} title="Signed" />
      )}
      {partySigningStatus === PartySigningStatus.Rejected && (
        <StatusCrossIcon className="text-danger" width={19} height={19} title="Rejected" />
      )}
    </div>
  );
}

function AgreementDetailsSignStatus({
  isCurrentUserSignaturePending,
  partyASigningStatus,
  partyBSigningStatus,
  signingPartyFirst,
}: Props) {
  return (
    <>
      {isCurrentUserSignaturePending === true ? (
        <strong className="fw-normal fst-italic fs-14">
          <b className="text-danger">*</b> Your signature required
        </strong>
      ) : (
        <>
          {(partyASigningStatus !== null || partyBSigningStatus !== null) && (
            <div className="hstack fs-14 gap-3">
              {partyASigningStatus && (
                <PartyStatus
                  className={signingPartyFirst === OrganisationType.PartyA ? 'order-0' : 'order-1'}
                  partyName="Party A"
                  partySigningStatus={partyASigningStatus}
                />
              )}
              {partyBSigningStatus && (
                <PartyStatus
                  className={signingPartyFirst === OrganisationType.PartyB ? 'order-0' : 'order-1'}
                  partyName="Party B"
                  partySigningStatus={partyBSigningStatus}
                />
              )}
            </div>
          )}
        </>
      )}
    </>
  );
}

export default AgreementDetailsSignStatus;
