import AuthCard from './components/AuthCard';
import LoginForm from './components/LoginForm';

function Login() {
  return (
    <AuthCard title="Sign In">
      <LoginForm />
    </AuthCard>
  );
}

export default Login;
