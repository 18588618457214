import { AxiosRequestConfig } from 'axios';

import { baseApi } from 'src/api';
import { GetPartyDto, GetAnalyticsParams, DealsResponse, GetDashboardAnalyticsParams, DashboardDto } from 'src/types';

// PermissionNames.AnalyticAllSystemDealsRotorsRead
export const getAllPartyB = (config: AxiosRequestConfig = {}) =>
  baseApi.get<GetPartyDto[]>('/analytic/parties', config);

// IgnorePermissions
export const getAllPartyBForCurrentUser = (config: AxiosRequestConfig = {}) =>
  baseApi.get<GetPartyDto[]>('/analytic/current/parties', config);

// PermissionNames.AnalyticAllSystemDealsRotorsRead
export const getAnalytics = (params: GetAnalyticsParams, config: AxiosRequestConfig = {}) =>
  baseApi.get<DealsResponse>('/analytic', { ...config, params });

// IgnorePermissions
export const getAnalyticsForCurrentUser = (params: GetAnalyticsParams, config: AxiosRequestConfig = {}) =>
  baseApi.get<DealsResponse>('/analytic/current', { ...config, params });

// IgnorePermissions
export const getDashboardAnalytics = (params: GetDashboardAnalyticsParams, config: AxiosRequestConfig = {}) =>
  baseApi.get<DashboardDto>('/analytic/dashboard', { ...config, params });
