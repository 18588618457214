import Dot from 'src/components/Dot';

import Converter from './Converter';
import { getAgreementStageText } from './statusFormat';

import {
  BOVE,
  CurrencyCodes,
  DealRotorType,
  RoleName,
  DealRotorAuditCategory,
  DealRotorAuditSubCategory,
  DealRotorRelationshipType,
  DealRotorAttachmentType,
  AgreementTransactionType,
  SigningType,
  OrganisationType,
  SigningParty,
  DealRotorStatus,
  AgreementStage,
  OrganisationStatus,
  SelectOption,
} from 'src/types';

export const boveOptions: SelectOption[] = Converter.enumToSelectOptions(BOVE);
export const currencyCodesOptions: SelectOption[] = Converter.enumToSelectOptions(CurrencyCodes);
export const dealRotorTypeOptions: SelectOption[] = Converter.enumToSelectOptions(DealRotorType);
export const roleNameOptions: SelectOption[] = Converter.enumToSelectOptions(RoleName);
export const dealRotorAuditCategoryOptions: SelectOption[] = Converter.enumToSelectOptions(DealRotorAuditCategory);
export const dealRotorAuditSubCategoryOptions: SelectOption[] =
  Converter.enumToSelectOptions(DealRotorAuditSubCategory);
export const dealRotorRelationshipTypeOptions: SelectOption[] =
  Converter.enumToSelectOptions(DealRotorRelationshipType);
export const dealRotorAttachmentOptions: SelectOption[] = Converter.enumToSelectOptions(DealRotorAttachmentType);
export const transactionTypeOptions: SelectOption[] = Converter.enumToSelectOptions(AgreementTransactionType);

export const signingTypeOptions: SelectOption[] = [
  { value: SigningType.Signature, label: 'With signature' },
  { value: SigningType.PrintedName, label: 'Printed name only' },
];
export const organisationTypeOptions: SelectOption[] = [
  { value: OrganisationType.PartyB, label: 'Party B' },
  { value: OrganisationType.PartyA, label: 'Party A' },
];
export const signingPartyOptions: SelectOption[] = [
  { value: SigningParty.All, label: 'All parties' },
  { value: SigningParty.Single, label: 'Single party' },
];
export const signingOrderBooleanOptions: SelectOption[] = [
  { value: 'false', label: 'Sign in any order' },
  { value: 'true', label: 'Sign in a particular order' },
];

export const dealRotorStatusColorMap: Record<DealRotorStatus, 'info' | 'danger' | 'metallic-silver'> = {
  [DealRotorStatus.Active]: 'info',
  [DealRotorStatus.Ceased]: 'danger',
  [DealRotorStatus.Inactive]: 'metallic-silver',
};

export const dealRotorStatusOptions: SelectOption[] = Object.values(DealRotorStatus).map((value) => ({
  value,
  label: (
    <div className="hstack gap-2">
      <Dot color={dealRotorStatusColorMap[value]} />
      <span>{Converter.stripUpperToCapitalizedFirst(value)}</span>
    </div>
  ),
}));

export const agreementStageColorMap: Record<AgreementStage, 'success' | 'yellow' | 'warning'> = {
  [AgreementStage.Active]: 'success',
  [AgreementStage.Pre]: 'yellow',
  [AgreementStage.Post]: 'warning',
};
export const agreementStageOptions: SelectOption[] = Object.values(AgreementStage).map((value) => ({
  value,
  label: (
    <div className="hstack gap-2">
      <Dot color={agreementStageColorMap[value]} />
      <span>{getAgreementStageText(value)}</span>
    </div>
  ),
}));

export const organisationStatusSortingScore: Record<OrganisationStatus, number> = {
  [OrganisationStatus.Active]: 0,
  [OrganisationStatus.Archived]: 1,
};
export const organisationStatusColorMap: Record<OrganisationStatus, 'success' | 'warning'> = {
  [OrganisationStatus.Active]: 'success',
  [OrganisationStatus.Archived]: 'warning',
};
export const organisationStatusOptions: SelectOption[] = Object.values(OrganisationStatus).map((value) => ({
  value,
  label: (
    <div className="hstack gap-2">
      <Dot color={organisationStatusColorMap[value]} />
      <span>{Converter.stripUpperToCapitalizedFirst(value)}</span>
    </div>
  ),
}));

export const transactionRanges = ['7', '30', '60', '90'] as const;
export const transactionRangeOptions: SelectOption[] = transactionRanges.map((range) => ({
  label: `${String(range)} days`,
  value: String(range),
}));
