import classNames from 'classnames';
import { useMemo } from 'react';
import { Link, To } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { useSelector } from 'src/store';
import { tenantSettingSelector } from 'src/store/selectors/tenantSelector';

import HeaderConfig from 'src/pages/configuration/components/HeaderConfig';

import {
  NotificationIcon,
  IntegrationIcon,
  RolePermissionIcon,
  TemplatesIcon,
  UserManagementIcon,
  CognitiveServicesIcon,
  TimezoneIcon,
} from 'src/components/icons/configuration-page';

import useUserPermission from 'src/hooks/useUserPermission';

import { PermissionNames } from 'src/types';

import styles from './Configuration.module.scss';

type ControlItem = {
  to: To;
  title: string;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  allowedPermissions?: PermissionNames[];
};

function Configuration() {
  const tenantSetting = useSelector(tenantSettingSelector);
  const { hasUserAllowedPermissions } = useUserPermission();

  const breadcrumbPathList = useMemo(() => [{ name: 'Home', to: RoutePath.root }], []);
  const configControlList = useMemo<ControlItem[]>(
    () =>
      [
        {
          to: 'user',
          title: 'User management',
          Icon: UserManagementIcon,
          allowedPermissions: [PermissionNames.UserRead, PermissionNames.UserWrite],
        },
        {
          to: 'role',
          title: 'Roles & permissions',
          Icon: RolePermissionIcon,
          allowedPermissions: [PermissionNames.RoleRead, PermissionNames.RoleWrite],
        },
        {
          to: 'integration',
          title: 'Integrations',
          Icon: IntegrationIcon,
          allowedPermissions: [PermissionNames.IntegrationsSalesforce],
        },
        {
          to: 'notification',
          title: 'Config notifications',
          Icon: NotificationIcon,
          allowedPermissions: [PermissionNames.NotificationRead, PermissionNames.NotificationWrite],
        },
        {
          to: 'templates-management',
          title: 'Templates management',
          Icon: TemplatesIcon,
          allowedPermissions: [PermissionNames.TemplateRead, PermissionNames.TemplateWrite],
        },
        {
          to: 'cognitive-services',
          title: 'Cognitive services',
          Icon: CognitiveServicesIcon,
          allowedPermissions: [PermissionNames.ChatWrite],
          hasChatAccessible: true,
        },
        {
          to: 'timezone',
          title: 'Timezone management',
          Icon: TimezoneIcon,
          allowedPermissions: [PermissionNames.TimezoneRead, PermissionNames.TimezoneWrite],
        },
      ].filter(({ allowedPermissions, hasChatAccessible }) => {
        const isUserPermissionAllowed = hasUserAllowedPermissions(allowedPermissions);
        const isChatPermissionAllowed = hasChatAccessible === true ? tenantSetting?.isChatAccessible === true : true;
        return isUserPermissionAllowed && isChatPermissionAllowed;
      }),
    [hasUserAllowedPermissions, tenantSetting],
  );

  return (
    <div className="h-100">
      <HeaderConfig className="mb-4" title="Configuration" breadcrumbPaths={breadcrumbPathList} />

      <nav className={classNames('d-flex flex-wrap gap-3', styles.ControlList)}>
        {configControlList.map(({ to, title, Icon }) => (
          <Link className={classNames(styles.Card, 'card')} to={to} key={title}>
            <div className="card-body d-flex flex-column justify-content-center align-items-center gap-2 text-center fw-medium">
              <Icon width={28} height={28} />
              <div>{title}</div>
            </div>
          </Link>
        ))}
      </nav>
    </div>
  );
}

export default Configuration;
