import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import LoadingButton, { AdditionalProps } from './LoadingButton';

export type BaseButtonProps = {
  to?: string;
  href?: string;
} & AdditionalProps &
  PropsWithChildren<React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>>;

function BaseButton({ children, type = 'button', onClick, to, href, ...props }: BaseButtonProps) {
  const tag = to ? Link : href ? 'a' : LoadingButton;
  const extraProps = to ? { to, onClick } : href ? { href, onClick } : { type, onClick };
  return React.createElement(
    tag as any,
    {
      ...props,
      ...extraProps,
    } as any,
    <>{children}</>,
  );
}

export default BaseButton;
