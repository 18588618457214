import classNames from 'classnames';
import { useMemo } from 'react';
import { useParams, generatePath, Outlet, Params } from 'react-router-dom';
import { RoutePath } from 'src/router';

import TabNavigation from 'src/components/TabNavigation';

import styles from './DealRotorEdit.module.scss';

const DealRotorEditNotifications = () => {
  const { dealRotorId = '' } = useParams<Params<'dealRotorId'>>();

  const tabs = useMemo(
    () => [
      { name: 'My notifications', to: generatePath(RoutePath.dealRotorNotificationUser, { dealRotorId }) },
      { name: 'Deal rotor notifications', to: generatePath(RoutePath.dealRotorNotificationDeal, { dealRotorId }) },
    ],
    [dealRotorId],
  );

  return (
    <section className="card h-100">
      <header className="card-header bg-white p-4 pb-0">
        <div className="hstack align-items-start gap-3 mb-4">
          <h1 className="h5 mb-0">Deal notifications</h1>
        </div>
        <TabNavigation className="pt-2" tabs={tabs} />
      </header>
      <div className={classNames(styles.TabScrollableBody, 'card-body custom-scroll p-4')}>
        <Outlet />
      </div>
    </section>
  );
};

export default DealRotorEditNotifications;
