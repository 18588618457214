import { AxiosRequestConfig } from 'axios';
import { useState } from 'react';

import LoadingButton from 'src/components/buttons/LoadingButton';
import ServerErrorMessages from 'src/components/ServerErrorMessages';
import { StatusCrossIcon, StatusTickIcon } from 'src/components/icons';

import AddSignatureForm from './AddSignatureForm';
import SignaturePreview from './SignaturePreview';
import RejectDocument from './RejectDocument';

import ServerErrorAdapter from 'src/utils/ServerErrorAdapter';
import { createSignImage } from '../utils/signature';

import {
  GetAgreementSignatoryDto,
  RejectAgreementModel,
  SignatureStatus,
  SignatureType,
  SigningType,
  ServerError,
  ServerFormErrors,
} from 'src/types';

type Props = {
  isAllowedDealRotorAgreementSign: boolean;
  isAllowedUserReadSignature: boolean;
  userId: string;
  userSignatureFilename: string | null;
  downloadUserSignatureRequest: (signatureFilename: string, config?: AxiosRequestConfig) => Promise<Blob>;
  uploadUserSignatureRequest: (type: SignatureType, data: FormData) => Promise<string>;
  rejectAgreementRequest: (data: RejectAgreementModel) => Promise<any>;
  refetchAgreementDetailsForSign: () => Promise<any>;
  refetchDownloadLatestAgreementDocument: () => Promise<any>;
  refetchUserSignatureFilename: () => Promise<any>;
  agreementSignatoryTuple: [string, GetAgreementSignatoryDto[]][];
  hasSignatureFilename: boolean;
  setSignatureFilename: React.Dispatch<React.SetStateAction<string>>;
};

type SignatoryProps = {
  isAllowedDealRotorAgreementSign: boolean;
  isAllowedUserReadSignature: boolean;
  userId: string;
  userSignatureFilename: string | null;
  downloadUserSignatureRequest: (signatureFilename: string, config?: AxiosRequestConfig) => Promise<Blob>;
  uploadUserSignatureRequest: (type: SignatureType, data: FormData) => Promise<string>;
  rejectAgreementRequest: (data: RejectAgreementModel) => Promise<any>;
  refetchAgreementDetailsForSign: () => Promise<any>;
  refetchDownloadLatestAgreementDocument: () => Promise<any>;
  refetchUserSignatureFilename: () => Promise<any>;
  signatory: GetAgreementSignatoryDto;
  hasSignatureFilename: boolean;
  setSignatureFilename: React.Dispatch<React.SetStateAction<string>>;
};

const signatureStatusMap: Record<SignatureStatus, string> = {
  [SignatureStatus.AbleToSign]: 'Awaiting signature',
  [SignatureStatus.NotAbleToSign]: 'Awaiting signature',
  [SignatureStatus.Signed]: 'Signed',
  [SignatureStatus.Rejected]: 'Rejected',
};

function Signatory({
  isAllowedDealRotorAgreementSign,
  isAllowedUserReadSignature,
  userId,
  userSignatureFilename,
  downloadUserSignatureRequest,
  uploadUserSignatureRequest,
  rejectAgreementRequest,
  refetchAgreementDetailsForSign,
  refetchDownloadLatestAgreementDocument,
  refetchUserSignatureFilename,
  signatory,
  hasSignatureFilename,
  setSignatureFilename,
}: SignatoryProps) {
  const signatoryFullName = signatory.fullName || '';

  const [isShowAddSignature, setIsShowAddSignature] = useState(false);
  const [serverErrorMessages, setServerErrorMessages] = useState<ServerFormErrors>([]);

  const proceedWithPrintedName = async () => {
    setServerErrorMessages([]);
    try {
      const type = SignatureType.FreeText;
      const signingFile = createSignImage(signatoryFullName, signatoryFullName.replaceAll(' ', '_'), true);
      const signingFileFormData = new FormData();
      signingFileFormData.append('file', signingFile);

      const signatureFilename = await uploadUserSignatureRequest(type, signingFileFormData);

      setSignatureFilename(signatureFilename);
    } catch (e) {
      const serverErrors = new ServerErrorAdapter(e as ServerError);
      setServerErrorMessages(serverErrors.combine());
    }
  };

  return (
    <div className="signatory">
      {signatory.status === SignatureStatus.AbleToSign && signatory.userId === userId ? (
        <>
          <div className="hstack gap-3">
            {signatory.signingType === SigningType.Signature && (
              <button
                className="btn btn-link px-0"
                type="button"
                onClick={() => setIsShowAddSignature(true)}
                disabled={!isAllowedDealRotorAgreementSign}
              >
                {hasSignatureFilename ? 'Modify signature' : 'Add signature'}
              </button>
            )}
            {signatory.signingType === SigningType.PrintedName && (
              <LoadingButton
                className="btn btn-link px-0"
                type="button"
                onClick={proceedWithPrintedName}
                disabled={!isAllowedDealRotorAgreementSign || hasSignatureFilename}
              >
                Proceed to signing page
              </LoadingButton>
            )}

            <RejectDocument
              isAllowedDealRotorAgreementSign={isAllowedDealRotorAgreementSign}
              setSignatureFilename={setSignatureFilename}
              rejectAgreementRequest={rejectAgreementRequest}
              refetchAgreementDetailsForSign={refetchAgreementDetailsForSign}
              refetchDownloadLatestAgreementDocument={refetchDownloadLatestAgreementDocument}
            />
          </div>
          <ServerErrorMessages messages={serverErrorMessages} />
        </>
      ) : (
        <>
          {signatory.signatureFilename ? (
            <SignaturePreview
              isAllowedUserReadSignature={isAllowedUserReadSignature}
              signatureFilename={signatory.signatureFilename}
              downloadUserSignatureRequest={downloadUserSignatureRequest}
            />
          ) : (
            <div>{signatureStatusMap[signatory.status]}</div>
          )}
        </>
      )}

      <div className="hstack">
        {signatory.status === SignatureStatus.Signed && (
          <StatusTickIcon className="text-success me-1" width={32} height={32} title="Signed" />
        )}
        {signatory.status === SignatureStatus.Rejected && (
          <StatusCrossIcon className="text-danger me-1" width={32} height={32} title="Rejected" />
        )}

        <span className="fw-medium">{signatory.fullName}</span>
        {signatory.title && <span className="text-secondary">, {signatory.title}</span>}
      </div>

      {signatory.status === SignatureStatus.Rejected && !!signatory.comment && (
        <div className="pt-2">
          <div className="h5">Reason for rejecting the document</div>
          <p className="mb-0">{signatory.comment}</p>
        </div>
      )}

      {isShowAddSignature && (
        <AddSignatureForm
          signatoryFullName={signatoryFullName}
          userSignatureFilename={userSignatureFilename}
          downloadUserSignatureRequest={downloadUserSignatureRequest}
          uploadUserSignatureRequest={uploadUserSignatureRequest}
          refetchUserSignatureFilename={refetchUserSignatureFilename}
          setIsShowAddSignature={setIsShowAddSignature}
          setSignatureFilename={setSignatureFilename}
        />
      )}
    </div>
  );
}

function AgreementSignatoryList({
  isAllowedDealRotorAgreementSign,
  isAllowedUserReadSignature,
  userId,
  userSignatureFilename,
  downloadUserSignatureRequest,
  uploadUserSignatureRequest,
  rejectAgreementRequest,
  refetchAgreementDetailsForSign,
  refetchDownloadLatestAgreementDocument,
  refetchUserSignatureFilename,
  agreementSignatoryTuple,
  hasSignatureFilename,
  setSignatureFilename,
}: Props) {
  return agreementSignatoryTuple.length > 0 ? (
    <div className="vstack gap-3 p-3">
      {agreementSignatoryTuple.map(([partyName, signatories]) => (
        <div className="card" key={partyName}>
          <div className="card-header">{partyName}</div>
          <div className="card-body vstack gap-3">
            {signatories.map((signatory) => (
              <Signatory
                isAllowedDealRotorAgreementSign={isAllowedDealRotorAgreementSign}
                isAllowedUserReadSignature={isAllowedUserReadSignature}
                userId={userId}
                userSignatureFilename={userSignatureFilename}
                downloadUserSignatureRequest={downloadUserSignatureRequest}
                uploadUserSignatureRequest={uploadUserSignatureRequest}
                rejectAgreementRequest={rejectAgreementRequest}
                refetchAgreementDetailsForSign={refetchAgreementDetailsForSign}
                refetchDownloadLatestAgreementDocument={refetchDownloadLatestAgreementDocument}
                refetchUserSignatureFilename={refetchUserSignatureFilename}
                signatory={signatory}
                hasSignatureFilename={hasSignatureFilename}
                setSignatureFilename={setSignatureFilename}
                key={signatory.userId}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  ) : (
    <div className="p-3">Signatories are not available.</div>
  );
}

export default AgreementSignatoryList;
