import { useEffect, useMemo } from 'react';
import { useQuery, useQueryClient, keepPreviousData } from '@tanstack/react-query';
import { RoutePath } from 'src/router';

import { useForm } from 'react-hook-form';
import { string, object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Field from 'src/components/forms/Field';
import FormatDate from 'src/components/FormatDate';
import LoadingButton from 'src/components/buttons/LoadingButton';
import ServerErrorMessages from 'src/components/ServerErrorMessages';

import HeaderConfig from 'src/pages/configuration/components/HeaderConfig';

import useAlertStatus from 'src/hooks/useAlertStatus';
import useUserPermission from 'src/hooks/useUserPermission';
import { useFormErrors, useFormHasChanges } from 'src/hooks/FormHelpers';

import ServerErrorAdapter from 'src/utils/ServerErrorAdapter';

import { getTimezone, getTimezoneAll, updateTimezone } from 'src/api/base/timezone';

import { YupFormShape, ServerError, SelectOption, PermissionNames } from 'src/types';

type FormData = {
  timezoneId: string;
};
type FormShape = YupFormShape<FormData>;

const formSchema = object().shape<FormShape>({
  timezoneId: string().label('Timezone').required(),
});

function TimezoneManagement() {
  const queryClient = useQueryClient();

  const { hasUserPermission } = useUserPermission();
  const isAllowedTimezoneWrite = hasUserPermission(PermissionNames.TimezoneWrite);

  const { setAlertState, AlertStatus: TimezoneManagementAlert } = useAlertStatus({ delay: 3500 });
  const { register, control, formState, setValue, handleSubmit, setError, clearErrors, watch } = useForm<FormData>({
    resolver: yupResolver(formSchema),
  });
  const { serverErrorMessages, handleErrors } = useFormErrors<FormData>(setError, clearErrors);

  const breadcrumbPaths = [
    { name: 'Home', to: RoutePath.root },
    { name: 'Configuration', to: RoutePath.configurationRoot },
  ];

  const {
    isLoading: isTimezoneLoading,
    data: timezone,
    error: timezoneError,
    refetch: refetchGetTimezone,
  } = useQuery({
    queryKey: ['getTimezone'],
    async queryFn() {
      const res = await getTimezone();
      return res.data;
    },
    placeholderData: keepPreviousData,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const {
    isLoading: isTimezoneDicLoading,
    data: timezoneDic,
    error: timezoneDicError,
  } = useQuery({
    queryKey: ['getTimezoneAll'],
    async queryFn() {
      const res = await getTimezoneAll();
      return res.data;
    },
    placeholderData: keepPreviousData,
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 60 * 60 * 1000, // 1 hour - static data
  });

  const timezoneOptions = useMemo(() => {
    if (!timezoneDic) return [];
    return Object.entries(timezoneDic).map<SelectOption>(([value, label]) => ({ value, label }));
  }, [timezoneDic]);

  const errorMessages = useMemo(() => {
    const timezoneErrorMessages = new ServerErrorAdapter(timezoneError as ServerError).combine();
    const timezoneListErrorMessages = new ServerErrorAdapter(timezoneDicError as ServerError).combine();
    return [...serverErrorMessages, ...timezoneErrorMessages, ...timezoneListErrorMessages];
  }, [serverErrorMessages, timezoneError, timezoneDicError]);

  const onSubmit = handleSubmit(async ({ timezoneId }) => {
    handleErrors();
    try {
      await updateTimezone(timezoneId);
      queryClient.removeQueries();
      await refetchGetTimezone();
      setAlertState(true);
    } catch (e) {
      handleErrors(e as ServerError);
    }
  });

  const timezonePrefill = useMemo(() => ({ timezoneId: timezone?.timezoneId ?? '' }), [timezone]);

  const hasChanges = useFormHasChanges(watch, timezonePrefill);

  useEffect(() => {
    setValue('timezoneId', timezonePrefill.timezoneId);
  }, [timezonePrefill, setValue]);

  return (
    <div className="h-100">
      <HeaderConfig className="mb-4" title="Timezone management" breadcrumbPaths={breadcrumbPaths} />
      <div className="bg-white border rounded p-3 mb-4">
        <form className="row g-3" name="timezoneManagementForm" onSubmit={onSubmit} noValidate>
          <Field
            className="col-sm-6 col-md-4 col-xl-3"
            field="dropdown"
            label="Timezone"
            placeholder="Choose timezone"
            autoComplete="off"
            register={register('timezoneId')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
            options={timezoneOptions}
            dropdownProps={{
              isSearchable: true,
              isClearable: false,
            }}
            disabled={isTimezoneLoading || isTimezoneDicLoading || !isAllowedTimezoneWrite}
            After={
              timezone && timezone.timezoneUpdatedDate ? (
                <div className="text-end text-secondary fs-14 pt-1">
                  Last updated: <FormatDate date={timezone.timezoneUpdatedDate} formatValue="dd/MM/yyyy - HH:mm:ss" />{' '}
                  (UTC)
                </div>
              ) : undefined
            }
          />

          <div className="vstack gap-3">
            <LoadingButton
              className="btn btn-primary ms-auto"
              type="submit"
              disabled={isTimezoneLoading || isTimezoneDicLoading || !isAllowedTimezoneWrite || !hasChanges}
              isLoading={formState.isSubmitting}
            >
              Save
            </LoadingButton>

            <ServerErrorMessages className="w-100 mb-0" messages={errorMessages} />
          </div>
        </form>
      </div>
      <TimezoneManagementAlert>Timezone settings saved</TimezoneManagementAlert>
    </div>
  );
}

export default TimezoneManagement;
