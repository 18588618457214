import classNames from 'classnames';
import { useMemo, useState, useCallback, useEffect } from 'react';
import { addDays } from 'date-fns';
import { useQuery } from '@tanstack/react-query';
import { useParams, Params } from 'react-router-dom';

import { useSelector } from 'src/store';
import { tenantTimezoneInBracketsSelector } from 'src/store/selectors/tenantSelector';

import { useForm, useWatch } from 'react-hook-form';
import { object, string, array, number, date } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Field from 'src/components/forms/Field';
import Table, { createColumnHelper } from 'src/components/Table';
import ServerErrorMessages from 'src/components/ServerErrorMessages';
import { EditIcon, RemoveIcon } from 'src/components/icons';

import useTenantTimezone from 'src/hooks/useTenantTimezone';
import { useFormErrors } from 'src/hooks/FormHelpers';

import Converter from 'src/utils/Converter';
import LocalDate from 'src/utils/LocalDate';
import ServerErrorAdapter from 'src/utils/ServerErrorAdapter';

import { getMethodCell } from 'src/pages/configuration/components/NotificationConfig';

import {
  getAllDealNotification,
  removeNotification,
  updateDealNotification,
  createDealNotification,
} from 'src/api/base/customNotification';

import {
  CustomNotificationType,
  DateTimeISOString,
  NotificationMethod,
  YupFormShape,
  CustomNotificationDto,
  ServerError,
} from 'src/types';

type FormData = {
  description: string;
  triggerDate: DateTimeISOString | null;
  triggerNoOfDaysBefore: number | null;
  methods: NotificationMethod[];
  type: CustomNotificationType;
};
type FormShape = YupFormShape<FormData>;
const formSchema = object().shape<FormShape>({
  description: string().label('Description').trim().required(),
  triggerDate: date()
    .label('Date')
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .when('type', {
      is: CustomNotificationType.OnDate,
      then: (schema) => schema.required(),
      otherwise: (schema) =>
        schema
          .optional()
          .nullable()
          .transform(() => null),
    }),
  triggerNoOfDaysBefore: number()
    .label('Number of days')
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .when('type', {
      is: CustomNotificationType.AgreementEndDateReminder,
      then: (schema) => schema.min(1).max(365).required(),
    }),
  methods: array().of(string()).label('Notification method').required().min(1),
  type: string().label('Notification type').emptyToUndefined().required(),
});

const notificationMethodOptions = Converter.enumToSelectOptions(NotificationMethod).map((option) =>
  option.value === NotificationMethod.InApp ? { ...option, label: 'In-App' } : option,
);

const typeNotificationOptions = Converter.enumToSelectOptions(CustomNotificationType);

const columnHelper = createColumnHelper<CustomNotificationDto>();

const DealRotorEditNotificationsUserTab = () => {
  const { dealRotorId = '' } = useParams<Params<'dealRotorId'>>();
  const { tenantTimezoneDateNow } = useTenantTimezone();
  const tenantTimezoneText = useSelector(tenantTimezoneInBracketsSelector);
  const [editNoticeId, setEditNoticeId] = useState<string | null>(null);
  const { register, control, formState, handleSubmit, reset, setError, clearErrors } = useForm<FormData>({
    resolver: yupResolver(formSchema),
  });
  const editFormMethods = useForm<FormData>({
    resolver: yupResolver(formSchema),
  });

  const typeField = useWatch({ control, name: 'type' });

  const { serverErrorMessages, setServerErrorMessages, handleErrors } = useFormErrors<FormData>(setError, clearErrors);

  const customNotifications = useQuery({
    queryKey: ['getAllDealNotification', dealRotorId],
    async queryFn({ signal }) {
      const config = { signal };
      const res = await getAllDealNotification(dealRotorId, config);
      return res.data;
    },
    initialData: [],
    retry: false,
    refetchOnWindowFocus: false,
  });

  const editCustomNotification = useCallback(
    (notice: CustomNotificationDto) => {
      const { id } = notice;
      setEditNoticeId(id);
      editFormMethods.setValue('triggerNoOfDaysBefore', notice.triggerNoOfDaysBefore);
      editFormMethods.setValue('triggerDate', notice.triggerDate);
      editFormMethods.setValue('methods', notice.methods);
      editFormMethods.setValue('description', notice.description);
      editFormMethods.setValue('type', CustomNotificationType[notice.type]);
    },
    [editFormMethods],
  );

  const onEditClose = useCallback(() => {
    editFormMethods.reset();
    setEditNoticeId(null);
  }, [editFormMethods]);

  const removeCustomNotification = useCallback(
    async (id: string) => {
      await removeNotification(id);
      customNotifications.refetch();
    },
    [customNotifications],
  );

  const onEditSubmit = editFormMethods.handleSubmit(
    async ({ description, triggerDate, triggerNoOfDaysBefore, methods, type }) => {
      if (!editNoticeId) return;
      try {
        const payload = {
          description,
          triggerDate:
            type === CustomNotificationType.OnDate && triggerDate
              ? new LocalDate(triggerDate).getStartDay().toJSON()
              : null,
          triggerNoOfDaysBefore:
            type === CustomNotificationType.AgreementEndDateReminder ? triggerNoOfDaysBefore : null,
          methods,
        };
        await updateDealNotification(editNoticeId, payload);
        await customNotifications.refetch();
        onEditClose();
      } catch (error) {
        const serverErrors = new ServerErrorAdapter(error as ServerError);
        setServerErrorMessages(serverErrors.combine());
      }
    },
  );

  const notificationByTypeColumn = useMemo(
    () => [
      columnHelper.display({
        id: 'notificationType',
        header: 'Notification date/days',
        enableSorting: false,
        cell: ({ row }) => {
          const isEditMode = row.original.id === editNoticeId;
          const date = row.original.triggerDate;
          const numberOfDays = row.original.triggerNoOfDaysBefore || 1;
          const formattedDate = date ? Converter.getFormattedDate(date) : '';
          switch (row.original.type) {
            case CustomNotificationType.OnDate:
              return isEditMode ? (
                <Field
                  className="col-12"
                  field="datepicker"
                  placeholder={`Select date${tenantTimezoneText}`}
                  autoComplete="off"
                  register={editFormMethods.register('triggerDate')}
                  control={editFormMethods.control}
                  formSchema={formSchema}
                  errors={editFormMethods.formState.errors}
                  options={typeNotificationOptions}
                  datepickerProps={{
                    minDate: addDays(tenantTimezoneDateNow, 1),
                  }}
                />
              ) : (
                <div>{formattedDate}</div>
              );
            case CustomNotificationType.AgreementEndDateReminder:
              return isEditMode ? (
                <Field
                  className="col-12"
                  field="input"
                  type="number"
                  min="1"
                  max="365"
                  placeholder="Enter number of days"
                  autoComplete="off"
                  register={editFormMethods.register('triggerNoOfDaysBefore')}
                  control={editFormMethods.control}
                  formSchema={formSchema}
                  errors={editFormMethods.formState.errors}
                />
              ) : (
                <div>{numberOfDays}</div>
              );

            default:
              break;
          }
        },
      }),
    ],
    [editFormMethods, editNoticeId, tenantTimezoneDateNow, tenantTimezoneText],
  );

  const notificationColumns = useMemo(
    () => [
      columnHelper.accessor('description', {
        id: 'description',
        header: 'Description',
        enableSorting: false,
        cell: ({ getValue, row }) => {
          const isEditMode = row.original.id === editNoticeId;
          return isEditMode ? (
            <Field
              className="col-12"
              field="input"
              type="text"
              placeholder="Enter description"
              autoComplete="off"
              register={editFormMethods.register('description')}
              control={editFormMethods.control}
              formSchema={formSchema}
              errors={editFormMethods.formState.errors}
              options={notificationMethodOptions}
            />
          ) : (
            <div>{getValue()}</div>
          );
        },
      }),
      columnHelper.accessor('methods', {
        id: 'methods',
        header: 'Notification method',
        enableSorting: false,
        cell: ({ getValue, row }) => {
          const methodsValue = getValue();
          const isEditMode = row.original.id === editNoticeId;
          return isEditMode ? (
            <Field
              className="col-12"
              field="dropdown"
              placeholder="Choose"
              autoComplete="off"
              register={editFormMethods.register('methods')}
              control={editFormMethods.control}
              formSchema={formSchema}
              errors={editFormMethods.formState.errors}
              options={notificationMethodOptions}
              multiple
              dropdownProps={{
                menuPosition: 'fixed',
                isSearchable: false,
              }}
            />
          ) : (
            getMethodCell(methodsValue)
          );
        },
      }),
      columnHelper.display({
        id: 'actions',
        header: '',
        enableSorting: false,
        cell: ({ row }) => {
          const isEditMode = row.original.id === editNoticeId;
          return isEditMode ? (
            <div className="d-flex justify-content-center gap-3">
              <button className="btn btn-primary" type="button" onClick={onEditSubmit}>
                Update
              </button>
              <button className="btn  btn-outline-primary" type="button" onClick={onEditClose}>
                Cancel
              </button>
            </div>
          ) : (
            <div className="hstack gap-3 justify-content-center">
              <button className="btn btn-link" type="button">
                <EditIcon width={16} height={16} onClick={() => editCustomNotification(row.original)} />
              </button>
              <button className="btn btn-link" type="button">
                <RemoveIcon width={16} height={16} onClick={() => removeCustomNotification(row.original.id)} />
              </button>
            </div>
          );
        },
      }),
    ],
    [editCustomNotification, editFormMethods, editNoticeId, onEditClose, onEditSubmit, removeCustomNotification],
  );

  const isShowPagination = useMemo(() => customNotifications.data.length > 10, [customNotifications.data.length]);

  const handleAddNotification = handleSubmit(
    async ({ description, triggerDate, triggerNoOfDaysBefore, methods, type }) => {
      try {
        handleErrors();
        const payload = {
          description,
          triggerDate:
            type === CustomNotificationType.OnDate && triggerDate
              ? new LocalDate(triggerDate).getStartDay().toJSON()
              : null,
          triggerNoOfDaysBefore:
            type === CustomNotificationType.AgreementEndDateReminder ? triggerNoOfDaysBefore : null,
          methods,
          type,
        };
        await createDealNotification(dealRotorId, payload);
        await customNotifications.refetch();
        reset();
      } catch (error) {
        const serverErrors = new ServerErrorAdapter(error as ServerError);
        setServerErrorMessages(serverErrors.combine());
      }
    },
  );

  useEffect(() => {
    const serverErrors = new ServerErrorAdapter(customNotifications.error as ServerError);
    setServerErrorMessages(serverErrors.combine());
  }, [customNotifications.error, setServerErrorMessages]);

  return (
    <>
      <div className="bg-white border rounded border-bottom-0 pb-1 rounded-bottom-0">
        <div className="pt-3 px-3">
          <h2 className="h5">Custom alert/reminder</h2>
          <p className="text-secondary">Create your own customer alert/reminder for this deal rotor</p>
        </div>
        <form
          className="position-relative border-top mb-4 pt-3 px-3"
          name="addNotificationForm"
          onSubmit={handleAddNotification}
          noValidate
        >
          <div className="row g-3 mb-4">
            <Field
              className="col-sm-6 col-lg-3"
              label="Notification type"
              field="dropdown"
              placeholder="Choose notification type"
              autoComplete="off"
              register={register('type')}
              control={control}
              formSchema={formSchema}
              errors={formState.errors}
              options={typeNotificationOptions}
              autoFocus
              dropdownProps={{ isSearchable: false }}
            />

            <Field
              className={classNames('col-sm-6 col-lg-3', {
                'visually-hidden': typeField === CustomNotificationType.AgreementEndDateReminder || !typeField,
              })}
              label={`Notification date${tenantTimezoneText}`}
              field="datepicker"
              placeholder="Select date"
              autoComplete="off"
              register={register('triggerDate')}
              control={control}
              formSchema={formSchema}
              errors={formState.errors}
              options={typeNotificationOptions}
              datepickerProps={{
                minDate: addDays(tenantTimezoneDateNow, 1),
              }}
            />

            <Field
              className={classNames('col-sm-6 col-lg-3', {
                'visually-hidden': typeField === CustomNotificationType.OnDate,
              })}
              label="Notification days before"
              field="input"
              type="number"
              min="1"
              max="365"
              placeholder="Enter number of days"
              autoComplete="off"
              register={register('triggerNoOfDaysBefore')}
              control={control}
              formSchema={formSchema}
              errors={formState.errors}
              options={typeNotificationOptions}
            />

            <Field
              className="col-sm-6 col-lg-3"
              label="Notification method"
              field="dropdown"
              placeholder="Choose notification method"
              autoComplete="off"
              register={register('methods')}
              control={control}
              formSchema={formSchema}
              errors={formState.errors}
              options={notificationMethodOptions}
              multiple
              dropdownProps={{ isSearchable: false }}
            />
            <Field
              className="col-sm-6 col-lg-3"
              label="Notification description"
              field="textarea"
              type="text"
              placeholder="Enter notification description"
              autoComplete="off"
              register={register('description')}
              control={control}
              formSchema={formSchema}
              errors={formState.errors}
              options={notificationMethodOptions}
            />
          </div>

          <button className="btn btn-outline-primary ms-auto hstack" type="submit">
            Add Notification
          </button>
        </form>
      </div>
      <div className="border border-top-0 rounded-bottom px-3">
        <Table
          className="mb-0"
          wrapperClassName="border rounded-bottom mb-3"
          data={customNotifications.data}
          total={customNotifications.data.length}
          columns={[...notificationByTypeColumn, ...notificationColumns]}
          isShowPagination={isShowPagination}
          pagination={!isShowPagination ? { pageIndex: 0, pageSize: customNotifications.data.length } : undefined}
        />
      </div>
      <ServerErrorMessages className="mt-3 mb-0" messages={serverErrorMessages} />
    </>
  );
};

export default DealRotorEditNotificationsUserTab;
