import { CookieAttributes } from 'js-cookie';

import { envDomain } from './domain';
import getEnvVariable from './getEnvVariable';

export const AUTH_API_URL = getEnvVariable('AUTH_API_URL');
export const ADMIN_API_URL = getEnvVariable('ADMIN_API_URL');
export const BASE_API_URL = getEnvVariable('BASE_API_URL');
export const SENTRY_ENABLED = getEnvVariable('SENTRY_ENABLED');
export const SENTRY_DSN = getEnvVariable('SENTRY_DSN');
export const SENTRY_ENV = getEnvVariable('SENTRY_ENV');

export const APP_AUTH_COOKIE_NAME = 'bx_app_auth';
export const APP_LAST_ACTIVITY_DATE_COOKIE_NAME = 'bx_app_lad';
export const APP_COUNTDOWN_START_DATE_COOKIE_NAME = 'bx_app_csd';
export const DEFAULT_LOGO_URL = '/images/logo/app-logo.png';
export const LS_TENANT_SETTINGS_KEY = 'tenantSettings';

export const cookieRootDomain = `.${envDomain}`;
export const cookieAttributes: CookieAttributes = {
  domain: cookieRootDomain,
  secure: window.location.protocol.toLocaleLowerCase().startsWith('https'),
  sameSite: 'Strict',
  expires: 0.05, // 1h 12min
};

export const strongPasswordReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,}$/;
