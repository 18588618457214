import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

export const RoutePath = {
  root: '/',
  // Auth
  authRoot: '/auth',
  login: '/auth/login',
  logout: '/auth/logout',
  signup: '/auth/signup',
  setupMFA: '/auth/mfa/setup',
  passwordForgot: '/auth/forgot-password',
  passwordReset: '/auth/reset-password',
  tenantSelect: '/auth/tenant',
  // Dashboard
  dashboard: '/dashboard',
  // Deal rotor
  dealRotorRoot: '/deal-rotor',
  dealRotorList: '/deal-rotor/list',
  dealRotorCreate: '/deal-rotor/create',
  dealRotorCreateTemplateAndRoles: '/deal-rotor/create/template-and-roles',
  dealRotorCreateDetails: '/deal-rotor/create/:dealRotorId/details',
  dealRotorEditRoot: '/deal-rotor/edit/:dealRotorId',
  dealRotorEditDetailedRoot: '/deal-rotor/edit/:dealRotorId/detailed',
  dealRotorEditDetailedDealRotorDetails: '/deal-rotor/edit/:dealRotorId/detailed/deal-rotor-details',
  dealRotorEditDetailedAgreementDetails: '/deal-rotor/edit/:dealRotorId/detailed/agreement-details',
  dealRotorEditDetailedParticipants: '/deal-rotor/edit/:dealRotorId/detailed/participants',
  dealRotorEditDetailedAttachments: '/deal-rotor/edit/:dealRotorId/detailed/attachments',
  dealRotorEditDetailedAIConsole: '/deal-rotor/edit/:dealRotorId/detailed/ai-console',
  dealRotorEditDetailedTimeline: '/deal-rotor/edit/:dealRotorId/timeline',
  dealRotorNotificationRoot: '/deal-rotor/edit/:dealRotorId/notification',
  dealRotorNotificationUser: '/deal-rotor/edit/:dealRotorId/notification/user',
  dealRotorNotificationDeal: '/deal-rotor/edit/:dealRotorId/notification/deal',
  dealRotorListByUserId: '/deal-rotors/users/:userId/search',
  // Global AI console
  globalAIConsole: '/global-console',
  // Deal participant
  dealParticipantRoot: '/deal-participant',
  dealParticipantList: '/deal-participant/list',
  dealParticipantDetailsById: '/deal-participant/:userId',

  // Party B management
  partyBManagementRoot: '/party-b-management',
  partyBManagementById: '/party-b-management/:organisationId',
  partyBManagementCreate: '/party-b-management/create',
  partyBManagementList: '/party-b-management/list',
  // Analytics
  analyticsRoot: '/analytics',
  analyticsPortfolioAnalysis: '/analytics/portfolio-analysis',
  analyticsPortfolioTimeline: '/analytics/portfolio-timeline',
  // Configuration
  configurationRoot: '/configuration',
  configurationUser: '/configuration/user',
  configurationUserList: '/configuration/user/list',
  configurationUserCreate: '/configuration/user/create',
  configurationRole: '/configuration/role',
  configurationIntegration: '/configuration/integration',
  configurationIntegrationSalesforce: '/configuration/integration/salesforce',
  configurationIntegrationXero: '/configuration/integration/xero',
  configurationNotification: '/configuration/notification',
  configurationTemplatesManagementRoot: '/configuration/templates-management',
  configurationTemplatesManagementList: '/configuration/templates-management/list',
  configurationTemplatesManagementTemplates: '/configuration/templates-management/list/templates',
  configurationTemplatesManagementParameters: '/configuration/templates-management/list/parameters',
  configurationTemplatesManagementEdit: '/configuration/templates-management/edit',
  configurationTemplatesManagementEditId: '/configuration/templates-management/edit/:templateId',
  configurationCognitiveServices: '/configuration/cognitive-services',
  configurationTimezone: '/configuration/timezone',

  // User profile
  userProfileRoot: '/profile',
  userProfileById: '/profile/:userId',
  userProfileSettingsRoot: '/profile/settings',
  userProfileSettingsPassword: '/profile/settings/password',
  userProfileSettingsMFA: '/profile/settings/mfa',
  // Contact
  userContactById: '/contact/:userId',
  // Sign
  notarisePublic: '/notarise/public',
  notarisePrivate: '/notarise/:agreementId',
  // Document
  documentViewer: '/document/:agreementDocumentId',
  documentViewerPublic: '/document/public/:agreementDocumentId',
};

export { GuardedRoute } from './GuardedRoute';
