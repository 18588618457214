import classNames from 'classnames';
import { useState, useMemo, useEffect } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Modal } from 'react-bootstrap';
import { OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import { useParams, Params } from 'react-router-dom';
import { closestCenter, DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import { arrayMove, verticalListSortingStrategy, SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { useForm, UseFormRegister, Control, FormState } from 'react-hook-form';
import { object, boolean, string, array } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Field from 'src/components/forms/Field';
import LoadingButton from 'src/components/buttons/LoadingButton';
import ServerErrorMessages from 'src/components/ServerErrorMessages';
import { InfoIcon, DraggableIcon } from 'src/components/icons';

import { useFormErrors } from 'src/hooks/FormHelpers';

import ServerErrorAdapter from 'src/utils/ServerErrorAdapter';
import {
  signingTypeOptions,
  organisationTypeOptions,
  signingPartyOptions,
  signingOrderBooleanOptions,
} from 'src/utils/selectOptions';
import nth from 'src/utils/nth';

import { getDealRotorPartyList, getExpectedSignatoryList } from 'src/api/base/dealRotor';
import { sendToSigningAgreement } from 'src/api/base/agreement';
import { getUserSignatoryList } from 'src/api/base/user';

import {
  SendToSigningAgreementModel,
  SigningType,
  OrganisationType,
  SigningParty,
  SignatoryModel,
  DealRotorPartyDto,
  YupFormShape,
  ServerError,
  SelectOption,
  UUIDString,
} from 'src/types';

import styles from './AgreementDetails.sign-and-finalise.module.scss';

type DealRotorPartyDtoWithOptions = DealRotorPartyDto & {
  options: SelectOption[];
};
type DealRotorPartyDtoWithOptionsAndDefaultValues = DealRotorPartyDtoWithOptions & {
  defaultValue: string[];
};

type Props = {
  dealRotorAgreementId: UUIDString;
  isAgreementCanFinalise: boolean;
};

type FormData = Pick<
  SendToSigningAgreementModel,
  'signingType' | 'signingPartyFirst' | 'signingParty' | 'isOrderRequired'
> & {
  singleParty: OrganisationType;
  signatoriesPartyA: Record<string, string[]>;
  signatoriesPartyB: Record<string, string[]>;
};
type FormShape = YupFormShape<FormData>;

function getSignatoriesPartyInOrder(
  signatoriesParty: Record<string, string[]>,
  partyOrganisationList: DealRotorPartyDtoWithOptions[],
  isOrderRequired: boolean,
) {
  return isOrderRequired
    ? partyOrganisationList.reduce(
        (acc, { organisationId }) => ({ ...acc, [organisationId]: signatoriesParty[organisationId] }),
        {},
      )
    : signatoriesParty;
}

type SignatoryOrganisationFieldProps = {
  party: DealRotorPartyDtoWithOptionsAndDefaultValues;
  dealRotorParties: DealRotorPartyDtoWithOptionsAndDefaultValues[];
  isOrderRequired: boolean;
  name: keyof Pick<FormData, 'signatoriesPartyA' | 'signatoriesPartyB'>;
  register: UseFormRegister<FormData>;
  control: Control<FormData, any>;
  formState: FormState<FormData>;
  formSchema: any;
};
function SignatoryOrganisationField({
  party,
  dealRotorParties,
  isOrderRequired,
  name,
  register,
  control,
  formState,
  formSchema,
}: SignatoryOrganisationFieldProps) {
  const { attributes, listeners, setNodeRef, transform, transition, active } = useSortable({
    id: party.organisationId,
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: active && active.id === party.organisationId ? 1 : undefined,
  };

  const label = useMemo<string>(() => {
    if (isOrderRequired && dealRotorParties.length > 1) {
      let partyIndex = dealRotorParties.indexOf(party);
      if (partyIndex >= 0) partyIndex++;
      return `${party.name} (${partyIndex}${nth(partyIndex)})`;
    }
    return party.name || '';
  }, [isOrderRequired, dealRotorParties, party]);

  return (
    <div className="hstack align-items-start bg-white rounded p-3 gap-3" style={style} ref={setNodeRef} {...attributes}>
      <Field
        className="flex-grow-1"
        field={isOrderRequired ? 'dropdown-multi-sort' : 'dropdown'}
        label={label}
        placeholder="Choose signatures"
        autoComplete="off"
        register={register(`${name}.${party.organisationId}`)}
        control={control}
        formSchema={formSchema}
        errors={formState.errors}
        options={party.options}
        defaultValue={party.defaultValue}
        multiple
        After={
          isOrderRequired ? (
            <div className="text-secondary fs-14 pt-1">
              Signing order based on sequence of selected signatories above
            </div>
          ) : undefined
        }
      />
      {isOrderRequired && dealRotorParties.length > 1 && (
        <button
          className={classNames(styles.DraggableBtn, 'btn btn-light cursor-move px-2')}
          type="button"
          {...listeners}
        >
          <DraggableIcon />
        </button>
      )}
    </div>
  );
}

type SignatoriesFieldsProps = {
  className?: string;
  title: string;
  dealRotorParties: DealRotorPartyDtoWithOptionsAndDefaultValues[];
  setDealRotorParties: React.Dispatch<React.SetStateAction<DealRotorPartyDtoWithOptionsAndDefaultValues[]>>;
  isOrderRequired: boolean;
  name: keyof Pick<FormData, 'signatoriesPartyA' | 'signatoriesPartyB'>;
  register: UseFormRegister<FormData>;
  control: Control<FormData, any>;
  formState: FormState<FormData>;
  formSchema: any;
};
function SignatoriesFields({
  className,
  title,
  dealRotorParties,
  setDealRotorParties,
  isOrderRequired,
  name,
  register,
  control,
  formState,
  formSchema,
}: SignatoriesFieldsProps) {
  const sortingIdentifiers = useMemo(
    () => dealRotorParties.map(({ organisationId }) => organisationId),
    [dealRotorParties],
  );

  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!active || !over) return;
    setDealRotorParties((parties) => {
      const oldIndex = parties.findIndex(({ organisationId }) => organisationId === active.id);
      const newIndex = parties.findIndex(({ organisationId }) => organisationId === over.id);
      return arrayMove(parties, oldIndex, newIndex);
    });
  };

  return (
    <div className={classNames('card', className)}>
      <strong className="card-header">{title}</strong>
      <div className="card-body vstack p-0">
        <DndContext modifiers={[restrictToParentElement]} onDragEnd={onDragEnd} collisionDetection={closestCenter}>
          <SortableContext items={sortingIdentifiers} strategy={verticalListSortingStrategy}>
            {dealRotorParties.map((party) => (
              <SignatoryOrganisationField
                key={party.organisationId}
                party={party}
                dealRotorParties={dealRotorParties}
                isOrderRequired={isOrderRequired}
                name={name}
                register={register}
                control={control}
                formState={formState}
                formSchema={formSchema}
              />
            ))}
          </SortableContext>
        </DndContext>
      </div>
    </div>
  );
}

function AgreementDetailsSignAndFinalise({ dealRotorAgreementId, isAgreementCanFinalise }: Props) {
  const queryClient = useQueryClient();
  const { dealRotorId = '' } = useParams<Params<'dealRotorId'>>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => {
    reset();
    setIsModalOpen(false);
  };

  const {
    isLoading: isDealRotorPartyListLoading,
    data: dealRotorPartyList,
    error: dealRotorPartyListError,
  } = useQuery({
    enabled: Boolean(dealRotorId) && isModalOpen,
    queryKey: ['getDealRotorPartyList', dealRotorId],
    async queryFn({ signal }) {
      const config = { signal };
      const res = await getDealRotorPartyList(dealRotorId, config);
      const dataWithOptions = await Promise.all(
        res.data.map<Promise<DealRotorPartyDtoWithOptions>>(async (party) => {
          const res = await getUserSignatoryList(party.organisationId);
          const options = res.data.map<SelectOption>((user) => ({
            label: `${user.firstName} ${user.lastName}`,
            value: user.id,
          }));
          return { ...party, options };
        }),
      );
      return dataWithOptions;
    },
    initialData: [],
    retry: false,
    refetchOnWindowFocus: false,
  });

  const {
    isLoading: isExpectedSignatoryListLoading,
    data: expectedSignatoryList,
    error: expectedSignatoryListError,
  } = useQuery({
    enabled: Boolean(dealRotorId) && isModalOpen,
    queryKey: ['getExpectedSignatoryList', dealRotorId],
    async queryFn({ signal }) {
      const config = { signal };
      const res = await getExpectedSignatoryList(dealRotorId, config);
      return res.data;
    },
    initialData: [],
    retry: false,
    refetchOnWindowFocus: false,
  });

  const isLoading = isDealRotorPartyListLoading || isExpectedSignatoryListLoading;

  const [partyAOrganisationList, setPartyAOrganisationList] = useState<DealRotorPartyDtoWithOptionsAndDefaultValues[]>(
    [],
  );
  const [partyBOrganisationList, setPartyBOrganisationList] = useState<DealRotorPartyDtoWithOptionsAndDefaultValues[]>(
    [],
  );

  useEffect(() => {
    if (dealRotorPartyList.length === 0) return;
    const partyA: DealRotorPartyDtoWithOptionsAndDefaultValues[] = [];
    const partyB: DealRotorPartyDtoWithOptionsAndDefaultValues[] = [];

    const partyAExpectedSignatoryIdList: string[] = [];
    const partyBExpectedSignatoryIdList: string[] = [];

    expectedSignatoryList.forEach((expectedSignatory) => {
      if (expectedSignatory.partyType === OrganisationType.PartyA) {
        partyAExpectedSignatoryIdList.push(expectedSignatory.userId);
      }
      if (expectedSignatory.partyType === OrganisationType.PartyB) {
        partyBExpectedSignatoryIdList.push(expectedSignatory.userId);
      }
    });

    dealRotorPartyList.forEach((organisation) => {
      if (organisation.type === OrganisationType.PartyA) {
        partyA.push({
          ...organisation,
          defaultValue: getDefaultValue(organisation.options, partyAExpectedSignatoryIdList),
        });
      }
      if (organisation.type === OrganisationType.PartyB) {
        partyB.push({
          ...organisation,
          defaultValue: getDefaultValue(organisation.options, partyBExpectedSignatoryIdList),
        });
      }
    });
    setPartyAOrganisationList(partyA);
    setPartyBOrganisationList(partyB);

    function getDefaultValue(options: SelectOption[], partyExpectedSignatoryIdList: string[]): string[] {
      if (options.length === 0 || partyExpectedSignatoryIdList.length === 0) return [];

      return options.reduce<string[]>((acc, { value: organisationUserId }) => {
        return partyExpectedSignatoryIdList.find((expectedUserId) => expectedUserId === organisationUserId)
          ? acc.concat(organisationUserId)
          : acc;
      }, []);
    }
  }, [dealRotorPartyList, expectedSignatoryList]);

  const formSchema = useMemo(
    () =>
      object().shape<FormShape>({
        signingType: string().label('Type of signing'),
        signingPartyFirst: string()
          .label('Who should sign first')
          .when('signingParty', {
            is: SigningParty.All,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.optional(),
          }),
        signingParty: string().label('Who needs to sign'),
        isOrderRequired: boolean().label('Signing order'),
        singleParty: string()
          .label('Party to sign')
          .when('signingParty', {
            is: SigningParty.Single,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.optional(),
          }),
        signatoriesPartyA: object().when(['signingParty', 'singleParty'], {
          is: (signingParty: SigningParty | undefined, singleParty: OrganisationType | undefined) =>
            signingParty === SigningParty.All ||
            (signingParty === SigningParty.Single && singleParty === OrganisationType.PartyA),
          then: (schema) =>
            schema.shape(
              partyAOrganisationList.reduce(
                (acc, party) => ({
                  ...acc,
                  [party.organisationId]: array().of(string()).label(`${party.name} signatory`).min(1).required(),
                }),
                {},
              ),
            ),
          otherwise: (schema) => schema.optional(),
        }),

        signatoriesPartyB: object().when(['signingParty', 'singleParty'], {
          is: (signingParty: SigningParty | undefined, singleParty: OrganisationType | undefined) =>
            signingParty === SigningParty.All ||
            (signingParty === SigningParty.Single && singleParty === OrganisationType.PartyB),
          then: (schema) =>
            schema.shape(
              partyBOrganisationList.reduce(
                (acc, party) => ({
                  ...acc,
                  [party.organisationId]: array().of(string()).label(`${party.name} signatory`).min(1).required(),
                }),
                {},
              ),
            ),
          otherwise: (schema) => schema.optional(),
        }),
      }),
    [partyAOrganisationList, partyBOrganisationList],
  );

  const { register, control, handleSubmit, formState, setError, clearErrors, reset, watch } = useForm<FormData>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      signingType: SigningType.Signature,
      signingPartyFirst: OrganisationType.PartyB,
      signingParty: SigningParty.All,
      isOrderRequired: 'false' as any,
    },
  });
  const { serverErrorMessages, handleErrors } = useFormErrors<FormData>(setError, clearErrors);
  const errorMessages = useMemo(() => {
    const dealRotorPartyListErrorMessages = new ServerErrorAdapter(dealRotorPartyListError as ServerError).combine();
    const expectedSignatoryListErrorMessages = new ServerErrorAdapter(
      expectedSignatoryListError as ServerError,
    ).combine();
    return [...serverErrorMessages, ...dealRotorPartyListErrorMessages, ...expectedSignatoryListErrorMessages];
  }, [serverErrorMessages, dealRotorPartyListError, expectedSignatoryListError]);

  const signingParty = watch('signingParty');
  const singleParty = watch('singleParty');
  const signingPartyFirst = watch('signingPartyFirst');
  const isOrderRequiredString = watch('isOrderRequired') as unknown as 'true' | 'false';
  const isOrderRequired = isOrderRequiredString === 'true';

  const singlePartyOptions = useMemo<SelectOption[]>(() => {
    const partyANames = partyAOrganisationList.map(({ name }) => name).join(', ');
    const partyBNames = partyBOrganisationList.map(({ name }) => name).join(', ');
    return [
      {
        label: (
          <>
            <div>Party A</div>
            <div className="fw-medium">{partyANames}</div>
          </>
        ),
        shortLabel: (
          <>
            Party A (<b>{partyANames}</b>)
          </>
        ),
        value: OrganisationType.PartyA,
      },
      {
        label: (
          <>
            <div>Party B</div>
            <div className="fw-medium">{partyBNames}</div>
          </>
        ),
        shortLabel: (
          <>
            Party B (<b>{partyBNames}</b>)
          </>
        ),
        value: OrganisationType.PartyB,
      },
    ];
  }, [partyAOrganisationList, partyBOrganisationList]);

  const onSubmit = handleSubmit(
    async ({
      signingType,
      signingPartyFirst,
      signingParty,
      isOrderRequired,
      singleParty,
      signatoriesPartyA,
      signatoriesPartyB,
    }) => {
      let selectedSignatories: Record<string, string[]> = {};
      if (signingParty === SigningParty.Single) {
        if (singleParty === OrganisationType.PartyA) {
          selectedSignatories = signatoriesPartyA;
        } else if (singleParty === OrganisationType.PartyB) {
          selectedSignatories = signatoriesPartyB;
        }
      } else if (signingParty === SigningParty.All) {
        const signatoriesPartyAOrdered = getSignatoriesPartyInOrder(
          signatoriesPartyA,
          partyAOrganisationList,
          isOrderRequired,
        );
        const signatoriesPartyBOrdered = getSignatoriesPartyInOrder(
          signatoriesPartyB,
          partyBOrganisationList,
          isOrderRequired,
        );
        if (signingPartyFirst === OrganisationType.PartyA) {
          selectedSignatories = { ...signatoriesPartyAOrdered, ...signatoriesPartyBOrdered };
        } else if (signingPartyFirst === OrganisationType.PartyB) {
          selectedSignatories = { ...signatoriesPartyBOrdered, ...signatoriesPartyAOrdered };
        }
      }

      let signatories = Object.entries(selectedSignatories).reduce<SignatoryModel[]>(
        (acc, [organisationId, userIdList]) => [
          ...acc,
          ...userIdList.map((userId, index) => ({ userId, organisationId, order: acc.length + index + 1 })),
        ],
        [],
      );

      const payload: SendToSigningAgreementModel = {
        signingParty,
        signingPartyFirst: signingParty === SigningParty.Single ? null : signingPartyFirst,
        isOrderRequired,
        signingType,
        signatories,
      };

      try {
        handleErrors();
        await sendToSigningAgreement(dealRotorAgreementId, payload);

        await Promise.allSettled([
          queryClient.invalidateQueries({ queryKey: ['getDealRotorById', dealRotorId] }),
          queryClient.invalidateQueries({ queryKey: ['getAgreementList', dealRotorId] }),
          queryClient.invalidateQueries({ queryKey: ['getDealRotorAuditListSearch', dealRotorId] }),
        ]);
        queryClient.removeQueries({ queryKey: ['getDealRotorListSearch'] });
        queryClient.removeQueries({ queryKey: ['getDealRotorAuditListAll'] });

        closeModal();
      } catch (e) {
        handleErrors(e as ServerError);
      }
    },
  );

  return (
    <>
      <button
        type="button"
        className="btn btn-primary text-nowrap"
        onClick={() => setIsModalOpen(true)}
        disabled={!isAgreementCanFinalise}
      >
        Sign and finalise
      </button>

      <Modal show={isModalOpen} onHide={closeModal} size="lg" centered>
        <Modal.Header onHide={closeModal} closeButton>
          <Modal.Title className="h5">Sign and finalise</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form name="signAndFinaliseForm" onSubmit={onSubmit} noValidate>
            <div className="row g-3 mb-3">
              <Field
                className="col-sm-6"
                inputGroupClassName="hstack flex-nowrap"
                formCheckClassName="form-check-inline"
                field="input"
                type="radio"
                label="What type of signing is required?"
                placeholder="Choose type of signing"
                autoComplete="off"
                register={register('signingType')}
                control={control}
                formSchema={formSchema}
                errors={formState.errors}
                options={signingTypeOptions}
                disabled={isLoading}
                AfterLabel={
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip>
                        <div className="text-start p-3">
                          <strong className="text-uppercase">With Signature</strong>
                          <p className="mt-1 mb-3">
                            Listed signatory need to accept the document and add signature electronically.
                          </p>
                          <strong className="text-uppercase">Printed name only</strong>
                          <p className="mt-1 mb-0">
                            Listed signatory should accept the document with the printed name as signature.
                          </p>
                        </div>
                      </Tooltip>
                    }
                  >
                    <InfoIcon className="text-primary ms-2" />
                  </OverlayTrigger>
                }
              />

              {signingParty === SigningParty.All && (
                <Field
                  className="col-sm-6"
                  inputGroupClassName="hstack flex-nowrap"
                  formCheckClassName="form-check-inline"
                  field="input"
                  type="radio"
                  label="Who should sign first?"
                  placeholder="Choose who should sign first"
                  autoComplete="off"
                  register={register('signingPartyFirst')}
                  control={control}
                  formSchema={formSchema}
                  errors={formState.errors}
                  options={organisationTypeOptions}
                  disabled={isLoading}
                  AfterLabel={
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip>
                          <div className="text-start p-3">
                            <strong className="text-uppercase">Party B</strong>
                            <p className="mt-1 mb-3">Party B/Other Parties have to sign first.</p>
                            <strong className="text-uppercase">Party A</strong>
                            <p className="mt-1 mb-0">Party A has to sign first.</p>
                          </div>
                        </Tooltip>
                      }
                    >
                      <InfoIcon className="text-primary ms-2" />
                    </OverlayTrigger>
                  }
                />
              )}
            </div>

            <div className="row g-3 mb-3">
              <Field
                className="col-sm-6"
                inputGroupClassName="hstack flex-nowrap"
                formCheckClassName="form-check-inline"
                field="input"
                type="radio"
                label="Who needs to sign?"
                placeholder="Choose who needs to sign"
                autoComplete="off"
                register={register('signingParty')}
                control={control}
                formSchema={formSchema}
                errors={formState.errors}
                options={signingPartyOptions}
                disabled={isLoading}
                AfterLabel={
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip>
                        <div className="text-start p-3">
                          <strong className="text-uppercase">All Parties</strong>
                          <p className="mt-1 mb-3">Both Party A and PartyB/Other Parties have to sign.</p>
                          <strong className="text-uppercase">Single Party</strong>
                          <p className="mt-1 mb-0">Only selected Party has to sign.</p>
                        </div>
                      </Tooltip>
                    }
                  >
                    <InfoIcon className="text-primary ms-2" />
                  </OverlayTrigger>
                }
              />

              <Field
                className="col-sm-6"
                inputGroupClassName="hstack flex-nowrap"
                formCheckClassName="form-check-inline"
                field="input"
                type="radio"
                label="Signatories signing order?"
                placeholder="Choose signing order"
                autoComplete="off"
                register={register('isOrderRequired')}
                control={control}
                formSchema={formSchema}
                errors={formState.errors}
                options={signingOrderBooleanOptions}
                disabled={isLoading}
                AfterLabel={
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip>
                        <div className="text-start p-3">
                          <strong className="text-uppercase">Sign in any order</strong>
                          <p className="mt-1 mb-3">
                            All signatories within a party will be able to sign simultaneously.
                          </p>
                          <strong className="text-uppercase">Sign in a particular order</strong>
                          <p className="mt-1 mb-0">Signatories will sign in an order specified.</p>
                        </div>
                      </Tooltip>
                    }
                  >
                    <InfoIcon className="text-primary ms-2" />
                  </OverlayTrigger>
                }
              />
            </div>

            {isLoading ? (
              <div className="text-center">
                <Spinner className="align-self-center" variant="primary" />
              </div>
            ) : (
              <>
                {signingParty === SigningParty.Single && (
                  <Field
                    className="mb-3"
                    field="dropdown"
                    label="Party to sign"
                    placeholder="Choose party to sign"
                    autoComplete="off"
                    register={register('singleParty')}
                    control={control}
                    formSchema={formSchema}
                    errors={formState.errors}
                    options={singlePartyOptions}
                    dropdownProps={{
                      isSearchable: false,
                      isClearable: false,
                    }}
                  />
                )}

                {(signingParty === SigningParty.All ||
                  (signingParty === SigningParty.Single && singleParty === OrganisationType.PartyA) ||
                  (signingParty === SigningParty.Single && singleParty === OrganisationType.PartyB)) && (
                  <div className="vstack gap-3 mb-5">
                    {(signingParty === SigningParty.All ||
                      (signingParty === SigningParty.Single && singleParty === OrganisationType.PartyB)) && (
                      <SignatoriesFields
                        className={signingPartyFirst === OrganisationType.PartyB ? 'order-0' : 'order-1'}
                        name="signatoriesPartyB"
                        title="Party B signatories"
                        dealRotorParties={partyBOrganisationList}
                        setDealRotorParties={setPartyBOrganisationList}
                        isOrderRequired={isOrderRequired}
                        register={register}
                        control={control}
                        formState={formState}
                        formSchema={formSchema}
                      />
                    )}

                    {(signingParty === SigningParty.All ||
                      (signingParty === SigningParty.Single && singleParty === OrganisationType.PartyA)) && (
                      <SignatoriesFields
                        className={signingPartyFirst === OrganisationType.PartyA ? 'order-0' : 'order-1'}
                        name="signatoriesPartyA"
                        title="Party A signatories"
                        dealRotorParties={partyAOrganisationList}
                        setDealRotorParties={setPartyAOrganisationList}
                        isOrderRequired={isOrderRequired}
                        register={register}
                        control={control}
                        formState={formState}
                        formSchema={formSchema}
                      />
                    )}
                  </div>
                )}
              </>
            )}

            <div className="d-flex flex-wrap justify-content-end gap-3">
              {/* <button type="button" className="btn btn-link me-auto">
                Preview
              </button> */}
              <button type="button" className="btn btn-link" onClick={closeModal}>
                Cancel
              </button>
              <LoadingButton
                type="submit"
                className="btn btn-primary"
                disabled={isLoading}
                isLoading={formState.isSubmitting}
              >
                Continue
              </LoadingButton>
              <ServerErrorMessages className="w-100 mb-0" messages={errorMessages} />
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AgreementDetailsSignAndFinalise;
