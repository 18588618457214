import classNames from 'classnames';
import { AxiosRequestConfig } from 'axios';
import { useState, useEffect, useCallback, useRef } from 'react';

import { useForm } from 'react-hook-form';
import { object, string, mixed } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Field from 'src/components/forms/Field';
import LoadingButton from 'src/components/buttons/LoadingButton';
import ServerErrorMessages from 'src/components/ServerErrorMessages';

import { useFormErrors } from 'src/hooks/FormHelpers';

import { createSignImage } from '../utils/signature';

import { SignatureType, SelectOption, YupFormShape, ServerError } from 'src/types';

import styles from './AddSignatureForm.module.scss';

type Props = {
  signatoryFullName: string;
  userSignatureFilename: string | null;
  downloadUserSignatureRequest: (signatureFilename: string, config?: AxiosRequestConfig) => Promise<Blob>;
  uploadUserSignatureRequest: (type: SignatureType, data: globalThis.FormData) => Promise<string>;
  refetchUserSignatureFilename: () => Promise<any>;
  setIsShowAddSignature: React.Dispatch<React.SetStateAction<boolean>>;
  setSignatureFilename: React.Dispatch<React.SetStateAction<string>>;
};

type FormData = {
  type: SignatureType;
  file: FileList;
  text: string;
};
type FormShape = YupFormShape<FormData>;
const formSchema = object().shape<FormShape>({
  type: string().label('Signing type').trim().required(),
  file: mixed<FileList>()
    .label('Signature')
    .when('type', {
      is: SignatureType.Signature,
      then: (schema) =>
        schema
          .fileRequired()
          .fileExt(['.png'])
          .fileSizeMax(1 * 1024 * 1024),
    }),
  text: string()
    .label('Signing type')
    .trim()
    .when('type', {
      is: SignatureType.FreeText,
      then: (schema) => schema.required(),
    }),
});

const signingTypeOptions: SelectOption[] = [
  { value: SignatureType.FreeText, label: 'Text format' },
  { value: SignatureType.Signature, label: 'Upload signature' },
];

function AddSignatureForm({
  signatoryFullName,
  userSignatureFilename,
  downloadUserSignatureRequest,
  uploadUserSignatureRequest,
  refetchUserSignatureFilename,
  setIsShowAddSignature,
  setSignatureFilename,
}: Props) {
  const { register, control, handleSubmit, formState, setValue, setError, clearErrors, watch } = useForm<FormData>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      type: SignatureType.FreeText,
      text: signatoryFullName,
    },
  });
  const { serverErrorMessages, handleErrors } = useFormErrors<FormData>(setError, clearErrors);
  const { type, text, file } = watch();

  const fileListRef = useRef<FileList | null>(null);
  const fillFormData = useCallback(async () => {
    if (!userSignatureFilename) return;
    try {
      const blob = await downloadUserSignatureRequest(userSignatureFilename);
      const file = new File([blob], userSignatureFilename, { type: blob.type });
      const dt = new DataTransfer();
      dt.items.add(file);
      const fileList = dt.files;
      setValue('file', fileList);
      fileListRef.current = fileList;
    } catch (e) {}
  }, [userSignatureFilename, downloadUserSignatureRequest, setValue]);

  useEffect(() => {
    fillFormData();
  }, [fillFormData]);

  const [base64SignaturePreview, setBase64SignaturePreview] = useState<string>('');
  useEffect(() => {
    if (!file || !file[0] || !file[0].type.startsWith('image/')) {
      setBase64SignaturePreview('');
      if (fileListRef.current) {
        setValue('file', fileListRef.current);
      }
    } else {
      const reader = new FileReader();
      reader.onload = (e) => setBase64SignaturePreview(e.target?.result?.toString() ?? '');
      reader.readAsDataURL(file[0]);
    }
  }, [file, setValue]);

  const onSubmit = handleSubmit(async ({ type, text, file }) => {
    const signingFile =
      type === SignatureType.FreeText ? createSignImage(text, signatoryFullName.replaceAll(' ', '_'), false) : file[0];
    handleErrors();
    try {
      let signatureFilename = '';
      if (userSignatureFilename && fileListRef.current && signingFile === fileListRef.current[0]) {
        signatureFilename = userSignatureFilename;
      } else {
        const signingFileFormData = new FormData();
        signingFileFormData.append('file', signingFile);

        signatureFilename = await uploadUserSignatureRequest(type, signingFileFormData);

        await refetchUserSignatureFilename().catch();
      }

      setSignatureFilename(signatureFilename);
      setIsShowAddSignature(false);
    } catch (e) {
      handleErrors(e as ServerError);
    }
  });

  return (
    <form className="vstack gap-4 mt-3" name="addSignatureForm" onSubmit={onSubmit} noValidate>
      <Field
        inputGroupClassName="hstack gap-3"
        field="input"
        type="radio"
        label="Choose the type of signature to be added"
        autoComplete="off"
        register={register('type')}
        control={control}
        formSchema={formSchema}
        errors={formState.errors}
        options={signingTypeOptions}
      />

      {type === SignatureType.Signature && (
        <div>
          <div className="text-secondary mb-1">Upload your signature</div>
          <Field
            field="document"
            label="Upload"
            placeholder="(File not more than 1MB)"
            accept=".png"
            autoComplete="off"
            register={register('file')}
            url={userSignatureFilename && 'signature.png'}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
          />
        </div>
      )}

      {type === SignatureType.FreeText && (
        <Field
          field="input"
          type="text"
          label="Enter your signature"
          placeholder="Your signature"
          autoComplete="off"
          register={register('text')}
          control={control}
          formSchema={formSchema}
          errors={formState.errors}
        />
      )}

      {Boolean(type) && (
        <div className="mt-3">
          <div className="text-secondary mb-1">Signature Preview</div>
          <div className={classNames(styles.Signature, 'd-flex justify-content-center border rounded')}>
            {type === SignatureType.Signature && <img src={base64SignaturePreview} alt="" />}
            {type === SignatureType.FreeText && (
              <span className={classNames(styles.TextSignaturePreview, 'fs-20 fw-semibold text-truncate')}>
                {text.trim()}
              </span>
            )}
          </div>
        </div>
      )}

      <div className="d-flex flex-wrap justify-content-end gap-3">
        <button type="button" className="btn btn-link" onClick={() => setIsShowAddSignature(false)}>
          Cancel
        </button>
        <LoadingButton type="submit" className="btn btn-primary text-nowrap" isLoading={formState.isSubmitting}>
          Proceed to Signing Page
        </LoadingButton>
        <ServerErrorMessages className="w-100 mb-0" messages={serverErrorMessages} />
      </div>
    </form>
  );
}

export default AddSignatureForm;
