import { useEffect, useMemo, useState, useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';

import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import Table, { createColumnHelper } from 'src/components/Table';
import Field from 'src/components/forms/Field';
import LoadingButton from 'src/components/buttons/LoadingButton';
import ServerErrorMessages from 'src/components/ServerErrorMessages';
import { RemoveIcon, EditIcon } from 'src/components/icons';

import useUserPermission from 'src/hooks/useUserPermission';
import { useFormErrors } from 'src/hooks/FormHelpers';

import ServerErrorAdapter from 'src/utils/ServerErrorAdapter';
import useAlertStatus from 'src/hooks/useAlertStatus';

import {
  getAllTemplateParameters,
  createTemplateParameter,
  removeParameterById,
  updateTemplateParameter,
} from 'src/api/base/templateParameter';

import { TemplateParamDto, ServerError, YupFormShape, PermissionNames } from 'src/types';

type FormData = {
  parameterName: string;
};
type EditFormData = {
  updateName: string;
};

type FormShape = YupFormShape<FormData>;
type EditFormShape = YupFormShape<EditFormData>;
const formSchema = object().shape<FormShape>({
  parameterName: string()
    .label('Parameter name')
    .trim()
    .min(3)
    .required()
    .matches(/^[a-zA-Z0-9_.-\s]*$/, { message: 'Only alphabets and numbers are allowed for this field' }),
});

const editFormSchema = object().shape<EditFormShape>({
  updateName: string()
    .label('Parameter name')
    .trim()
    .min(3)
    .required()
    .matches(/^[a-zA-Z0-9_.-\s]*$/, { message: 'Only alphabets and numbers are allowed for this field' }),
});

const columnHelper = createColumnHelper<TemplateParamDto>();

const ParametersList = () => {
  const { hasUserPermission } = useUserPermission();
  const isAllowedTemplateWrite = hasUserPermission(PermissionNames.TemplateWrite);
  const { setAlertState: setUpdateAlert, AlertStatus: ParameterUpdateAlert } = useAlertStatus({ delay: 3500 });
  const { setAlertState: setAddAlert, AlertStatus: ParameterAddAlert } = useAlertStatus({
    delay: 3500,
    variant: 'success',
  });
  const { setAlertState: setRemoveAlert, AlertStatus: ParameterRemoveAlert } = useAlertStatus({
    delay: 3500,
    variant: 'warning',
  });

  const [editParameter, setEditParameter] = useState<string | null>(null);
  const { register, control, formState, handleSubmit, reset, setError, clearErrors } = useForm<FormData>({
    resolver: yupResolver(formSchema),
  });

  const {
    register: registerEdit,
    control: controlEdit,
    formState: formStateEdit,
    handleSubmit: handleSubmitEdit,
    setValue: setValueEdit,
    setError: setErrorEdit,
    clearErrors: clearErrorsEdit,
  } = useForm<EditFormData>({
    resolver: yupResolver(editFormSchema),
  });

  const { isLoading, data, error, refetch } = useQuery({
    queryKey: ['getAllTemplateParameters'],
    async queryFn({ signal }) {
      const config = { signal };
      const res = await getAllTemplateParameters(config);
      return res.data;
    },
  });

  const { serverErrorMessages, setServerErrorMessages, handleErrors } = useFormErrors<FormData>(setError, clearErrors);

  const removeParameter = useCallback(
    async (parameterId: string) => {
      await removeParameterById(parameterId);
      await refetch();
      setRemoveAlert(true);
    },
    [refetch, setRemoveAlert],
  );

  const onUpdateParameter = handleSubmitEdit(async (editFormValues) => {
    const { updateName: name } = editFormValues;
    if (parameterNamesList?.includes(name)) {
      return setErrorEdit('updateName', { type: 'custom', message: `${name} is already exist` });
    }
    // replace space charters for key
    const key = name.replaceAll(/\s+/g, '_').toLowerCase();
    const originParameter = data?.find((param) => param.id === editParameter);
    if (!originParameter) return;
    const parameter = {
      ...originParameter,
      name,
      key,
    };
    try {
      handleErrors();

      setEditParameter(null);
      await updateTemplateParameter([parameter]);
      await refetch();
      setUpdateAlert(true);
    } catch (error) {
      handleErrors(error as ServerError);
    }
  });

  type EditCellProps = { editParamId: string | null; originParamId: string; value: string };
  const EditCell = useCallback(
    ({ editParamId, originParamId, value }: EditCellProps) => {
      return editParamId === originParamId ? (
        <>
          <Field
            className="me-auto"
            field="input"
            type="text"
            placeholder="Enter parameter name"
            autoComplete="off"
            register={registerEdit('updateName')}
            control={controlEdit}
            formSchema={editFormSchema}
            errors={formStateEdit.errors}
            autoFocus
          />
          <div className="d-flex gap-3 align-self-start">
            <button
              className="btn btn-primary text-nowrap"
              type="button"
              onClick={() => {
                onUpdateParameter();
              }}
            >
              Update
            </button>
            <button
              type="button"
              className="btn btn-outline-primary"
              aria-label="Close"
              onClick={() => {
                setEditParameter(null);
                clearErrorsEdit('updateName');
              }}
            >
              Cancel
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="me-auto">{value}</div>
          {isAllowedTemplateWrite && (
            <button
              className="btn btn-link text-nowrap fs-14"
              type="button"
              onClick={() => {
                setEditParameter(originParamId);
                setValueEdit('updateName', value);
                handleErrors();
              }}
            >
              <EditIcon height={16} width={16} />
            </button>
          )}
          {isAllowedTemplateWrite && (
            <button
              type="button"
              className="btn btn-link text-nowrap fs-14"
              onClick={() => removeParameter(originParamId)}
            >
              <RemoveIcon height={16} width={16} />
            </button>
          )}
        </>
      );
    },
    [
      registerEdit,
      controlEdit,
      formStateEdit.errors,
      isAllowedTemplateWrite,
      onUpdateParameter,
      clearErrorsEdit,
      setValueEdit,
      handleErrors,
      removeParameter,
    ],
  );

  const customColumns = useMemo(() => {
    return [
      columnHelper.accessor('name', {
        cell: ({ getValue, row }) => (
          <div className="hstack gap-3">
            <EditCell editParamId={editParameter} originParamId={row.original.id} value={getValue()} />
          </div>
        ),
        id: 'name',
        header: 'Custom parameter',
        enableSorting: false,
      }),
    ];
  }, [EditCell, editParameter]);
  const generalColumns = [
    columnHelper.accessor('name', {
      id: 'name',
      header: 'General parameter',
      enableSorting: false,
    }),
  ];

  const tableData = useMemo(() => {
    const generalParameters = data ? data.filter((param) => param.isBridgingXParameter) : [];
    const customParameters = data ? data.filter((param) => !param.isBridgingXParameter).reverse() : [];
    return {
      custom: customParameters,
      general: generalParameters,
    };
  }, [data]);

  const parameterNamesList = useMemo(() => {
    return data?.map((param) => param.name);
  }, [data]);

  const onSubmit = handleSubmit(async (formValues) => {
    setEditParameter(null);
    clearErrorsEdit('updateName');
    const { parameterName: name } = formValues;
    if (parameterNamesList?.includes(name)) {
      return setError('parameterName', { type: 'custom', message: `${name} is already exist` });
    }
    // replace space charters for key
    const key = name.replaceAll(/\s+/g, '_').toLowerCase();
    const parameter = {
      name,
      key,
      value: null,
    };

    try {
      handleErrors();

      reset({ parameterName: '' });
      await createTemplateParameter(parameter);
      await refetch();
      setAddAlert(true);
    } catch (error) {
      handleErrors(error as ServerError);
    }
  });

  useEffect(() => {
    const parametersErrorMessages = new ServerErrorAdapter(error as ServerError).combine();
    setServerErrorMessages(parametersErrorMessages);
  }, [error, setServerErrorMessages]);

  return (
    <>
      <div className="row g-3 flex-lg-row flex-sm-column">
        <div className="col">
          <div className="border bg-white rounded">
            <form className="px-3 mb-3" name="createParameterForm" onSubmit={onSubmit} noValidate>
              {isAllowedTemplateWrite && (
                <div className="row g-3 pt-3">
                  <Field
                    className="col-lg-12 col-xl-9"
                    field="input"
                    type="text"
                    placeholder="Enter parameter name"
                    autoComplete="off"
                    register={register('parameterName')}
                    control={control}
                    formSchema={formSchema}
                    errors={formState.errors}
                  />
                  <div className="col-lg-12 col-xl-3 d-flex justify-content-end">
                    <LoadingButton
                      className="btn btn-primary align-self-start"
                      type="submit"
                      isLoading={formState.isSubmitting}
                      disabled={formState.isSubmitting}
                    >
                      Add
                    </LoadingButton>
                  </div>
                </div>
              )}
            </form>
            <Table
              className="mb-0"
              wrapperClassName="border rounded-bottom border-0"
              data={tableData.custom}
              total={tableData.custom.length}
              columns={customColumns}
              isLoading={isLoading}
              isShowPagination={false}
              pagination={{
                pageIndex: 0,
                pageSize: tableData.custom.length,
              }}
            />
          </div>
        </div>
        <div className="col">
          <Table
            className="mb-0"
            wrapperClassName="border rounded"
            data={tableData.general}
            total={tableData.general.length}
            columns={generalColumns}
            isLoading={isLoading}
            isShowPagination={false}
            pagination={{
              pageIndex: 0,
              pageSize: tableData.general.length,
            }}
          />
        </div>
      </div>
      <ParameterRemoveAlert>Parameter has been deleted</ParameterRemoveAlert>
      <ParameterAddAlert>New parameter has been added</ParameterAddAlert>
      <ParameterUpdateAlert>Parameter settings saved</ParameterUpdateAlert>

      <ServerErrorMessages messages={serverErrorMessages} />
    </>
  );
};

export default ParametersList;
