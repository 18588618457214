import { useMemo, PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { useSelector } from 'src/store';
import { isLoggedInSelector, authUserRoleSelector } from 'src/store/selectors/authSelector';

import useUserPermission from 'src/hooks/useUserPermission';

import { Role, PermissionNames, RoleName } from 'src/types';
import useUserRole from 'src/hooks/useUserRole';

type Props = {
  meta: {
    auth?: boolean;
    allowedPermissions?: PermissionNames[];
    allowedRoles?: RoleName[];
  };
};

export function GuardedRoute({ meta, children }: PropsWithChildren<Props>) {
  let redirectRoute = RoutePath.root;

  const isLoggedIn = useSelector(isLoggedInSelector);
  const authUserRole = useSelector(authUserRoleSelector);

  const { hasUserAllowedRoles } = useUserRole();
  const { hasUserAllowedPermissions } = useUserPermission();

  const passAuthGuard = useMemo(() => {
    if (!meta.auth) return true;
    return isLoggedIn;
  }, [meta, isLoggedIn]);

  const passRolesGuard = useMemo(() => {
    return authUserRole === Role.Client;
  }, [authUserRole]);

  const passAllowedPermissionsGuard = useMemo(
    () => hasUserAllowedPermissions(meta.allowedPermissions),
    [meta.allowedPermissions, hasUserAllowedPermissions],
  );

  const passAllowedRoleNamesGuard = useMemo(
    () => hasUserAllowedRoles(meta.allowedRoles),
    [hasUserAllowedRoles, meta.allowedRoles],
  );

  const isRouteAllowed = useMemo(
    () => passAuthGuard && passRolesGuard && passAllowedPermissionsGuard && passAllowedRoleNamesGuard,
    [passAuthGuard, passRolesGuard, passAllowedPermissionsGuard, passAllowedRoleNamesGuard],
  );

  if (passAuthGuard) {
    if (!passRolesGuard) {
      redirectRoute = RoutePath.logout;
    }
  } else {
    const r = window.location.pathname + window.location.search;
    if (r.startsWith(RoutePath.authRoot)) {
      redirectRoute = RoutePath.login;
    } else {
      redirectRoute = `${RoutePath.login}?r=${btoa(r)}`;
    }
  }

  return <>{isRouteAllowed ? children : <Navigate to={redirectRoute} />}</>;
}
