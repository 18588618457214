import classNames from 'classnames';
import { NavLink, To } from 'react-router-dom';

import styles from './PillNavigation.module.scss';

export type Pill = {
  name: string;
  to?: To;
};

export type Props = {
  pills: Pill[];
  className?: string;
};

function PillNavigation({ className, pills }: Props) {
  return (
    <nav className={classNames(styles.PillNavigation, className)}>
      <ul className="nav gap-1">
        {pills.map(({ name, to }, index) => (
          <li className="nav-item" key={`${name}${index}`}>
            {to ? (
              <NavLink className={({ isActive }) => classNames('nav-link', { active: isActive })} to={to}>
                {name}
              </NavLink>
            ) : (
              <span className="nav-item disabled">{name}</span>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default PillNavigation;
