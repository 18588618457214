import classNames from 'classnames';
import { NavDropdown } from 'react-bootstrap';

import { MenuIcon } from 'src/components/icons';
import { envTenant, getSubDomainHostUrl } from 'src/utils/domain';
import useUserTenantSettingList from 'src/hooks/useUserTenantSettingList';
import TenantLogo from 'src/pages/auth/components/TenantLogo';

import styles from './TenantMenu.module.scss';
import tenantSelectStyles from 'src/pages/auth/TenantSelect.module.scss';

const TenantMenu = () => {
  const { tenantSettingList = [] } = useUserTenantSettingList();

  return (
    <NavDropdown
      className={classNames(styles.NavDropdown)}
      title={<MenuIcon className="text-primary" width={23} height={23} />}
    >
      <ul className={classNames('overflow-auto custom-scroll vstack gap-3 p-2 m-0')}>
        {tenantSettingList.map((tenant) => (
          <li
            className={classNames(tenantSelectStyles.TenantListItem, 'border p-2', {
              active: envTenant === tenant.domain,
            })}
            key={tenant.id}
          >
            <a className="hstack gap-3 list-group-item" href={getSubDomainHostUrl(tenant.domain)}>
              <div className="tenant-logo hstack justify-content-center align-self-center border rounded-1 p-1 bg-white w-100">
                <TenantLogo logo={tenant.logoUrl} name={tenant.name} />
              </div>
              <div className="title text-start">{tenant.name}</div>
            </a>
          </li>
        ))}
      </ul>
    </NavDropdown>
  );
};

export default TenantMenu;
