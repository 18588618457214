import classNames from 'classnames';
import { useMemo, useEffect, Fragment, useState, useCallback, memo, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Collapse } from 'react-bootstrap';
import { RoutePath } from 'src/router';

import { useForm, useFieldArray, Control, FieldArrayWithId, UseFormRegister } from 'react-hook-form';
import { object, string, array } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useWindowSize } from 'usehooks-ts';
import { useDraggable } from 'react-use-draggable-scroll';

import ChevronIcon from 'src/components/icons/ChevronIcon';
import LoadingButton from 'src/components/buttons/LoadingButton';
import ServerErrorMessages from 'src/components/ServerErrorMessages';
import useAlertStatus from 'src/hooks/useAlertStatus';

import HeaderConfig from 'src/pages/configuration/components/HeaderConfig';
import CheckboxIndeterminate from 'src/pages/configuration/components/CheckboxIndeterminate';
import {
  TableHead,
  AddRoleButton,
  RoleTable,
  FormTableData,
} from 'src/pages/configuration/components/RolePermissionConfig.table';

import { useFormErrors } from 'src/hooks/FormHelpers';

import Converter from 'src/utils/Converter';
import ServerErrorAdapter from 'src/utils/ServerErrorAdapter';

import { getAllPermissions, getAllRoles, putRole, removeRole } from 'src/api/base/permissions';

import { PermissionDto, CreateRoleDto, ServerError, RoleType, YupFormShape, PermissionNames } from 'src/types';

import styles from './RolePermissionConfig.module.scss';
import useUserPermission from 'src/hooks/useUserPermission';

type PermissionsByType = {
  [key: string]: PermissionDto[];
};

type PermissionsSetByRole = {
  [key: string]: Array<{ [key: string]: string[] }>;
};

type RenamedRoleType = {
  roleId: string;
  name: string;
};

type FormShape = YupFormShape<FormTableData>;

const formSchema = object().shape<FormShape>({
  permissionsCollection: array().of(
    object().shape({
      id: string(),
      name: string(),
      type: string().nullable(),
      permissionIds: array().of(string()),
    }),
  ),
});

type SummaryRowProps = {
  permissionType: string;
  permissionsSetByRole: PermissionsSetByRole;
  control: Control<FormTableData, any>;
  fields: FieldArrayWithId<FormTableData, 'permissionsCollection', 'id'>[];
  toggleCollapse: () => void;
  isOpenCollapse: boolean;
};

const SummaryRow = memo(
  ({ permissionType, control, permissionsSetByRole, fields, toggleCollapse, isOpenCollapse }: SummaryRowProps) => {
    return (
      <tr>
        <th>
          <button
            className="btn btn-link text-start text-dark fw-semibold text-decoration-none hstack"
            type="button"
            onClick={toggleCollapse}
          >
            <ChevronIcon className="me-2 text-black" down up={isOpenCollapse} />
            {permissionType}
          </button>
        </th>
        {fields.map((role, index) => (
          <td className={classNames(styles.TdSummary, { custom: role.type !== RoleType.System })} key={role.id}>
            <CheckboxIndeterminate
              name={permissionType + '-' + role.id}
              permissionsList={permissionsSetByRole}
              permissionType={permissionType}
              index={index}
              roleId={role.id}
              control={control}
              disabled
            />
          </td>
        ))}
      </tr>
    );
  },
);

type PermissionRowProps = {
  permission: PermissionDto;
  permissionType: string;
  fields: FieldArrayWithId<FormTableData, 'permissionsCollection', 'id'>[];
  register: UseFormRegister<FormTableData>;
  isOpenCollapse: boolean;
};

const PermissionRow = memo(({ permission, fields, register, isOpenCollapse }: PermissionRowProps) => {
  return (
    <Collapse in={isOpenCollapse}>
      <tr>
        <th className={classNames(styles.ThPermission, 'text-start')}>
          <span className="d-flex align-items-center fw-normal">{permission.description}</span>
        </th>
        {fields.map((roleColumn, index) => {
          return (
            <td key={roleColumn.id}>
              <input
                className={classNames(styles.Checkbox, 'form-check-input')}
                type="checkbox"
                disabled={roleColumn.type === RoleType.System}
                {...register(`permissionsCollection.${index}.permissionIds` as const)}
                value={permission.id}
              />
            </td>
          );
        })}
      </tr>
    </Collapse>
  );
});

type TableRowProps = {
  permissionsByType: PermissionsByType;
  permissionsSetByRole: PermissionsSetByRole;
  control: Control<FormTableData, any>;
  fields: FieldArrayWithId<FormTableData, 'permissionsCollection', 'id'>[];
  register: UseFormRegister<FormTableData>;
  permissionType: string;
  rowOrder: number;
};

const TableRow = memo(
  ({ permissionsByType, permissionsSetByRole, control, fields, register, permissionType, rowOrder }: TableRowProps) => {
    const [isOpenCollapse, setIsOpenCollapse] = useState(rowOrder === 0 ? true : false);
    const toggleCollapse = () => setIsOpenCollapse((prevState) => !prevState);

    const permissionsSection = permissionsByType[permissionType];
    return (
      <>
        <SummaryRow
          permissionType={permissionType}
          permissionsSetByRole={permissionsSetByRole}
          control={control}
          fields={fields}
          toggleCollapse={toggleCollapse}
          isOpenCollapse={isOpenCollapse}
        />
        {permissionsSection.map((permission) => (
          <PermissionRow
            permissionType={permissionType}
            permission={permission}
            key={permission.id}
            fields={fields}
            register={register}
            isOpenCollapse={isOpenCollapse}
          />
        ))}
      </>
    );
  },
);

type TableBodyProps = {
  uniquePermissionTypes: string[];
  permissionsByType: PermissionsByType;
  permissionsSetByRole: PermissionsSetByRole;
  control: Control<FormTableData, any>;
  fields: FieldArrayWithId<FormTableData, 'permissionsCollection', 'id'>[];
  register: UseFormRegister<FormTableData>;
};

const TableBody = memo(
  ({ uniquePermissionTypes, permissionsByType, permissionsSetByRole, control, fields, register }: TableBodyProps) => {
    return (
      <>
        {uniquePermissionTypes.map((permissionType, index) => (
          <TableRow
            key={permissionType}
            permissionsByType={permissionsByType}
            permissionType={permissionType}
            permissionsSetByRole={permissionsSetByRole}
            control={control}
            fields={fields}
            register={register}
            rowOrder={index}
          />
        ))}
      </>
    );
  },
);

const RolePermissionConfig = () => {
  const { hasUserPermission } = useUserPermission();
  const { setAlertState, AlertStatus: RolesPermissionsAlert } = useAlertStatus({ delay: 3500 });
  const windowSize = useWindowSize();
  const tableRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const { events } = useDraggable(tableRef);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    clearErrors,
    getValues,
    formState: { isLoading },
  } = useForm<FormTableData>({
    resolver: yupResolver(formSchema),
    defaultValues: { permissionsCollection: [] },
  });

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'permissionsCollection',
    shouldUnregister: true,
  });

  const { serverErrorMessages, setServerErrorMessages, handleErrors } = useFormErrors<FormTableData>(
    setError,
    clearErrors,
  );

  const permissions = useQuery({
    queryKey: ['PermissionDto'],
    async queryFn({ signal }) {
      const config = { signal };
      const res = await getAllPermissions(config);
      return res.data;
    },
    initialData: [],
    retry: false,
    refetchOnWindowFocus: false,
  });

  const roles = useQuery({
    queryKey: ['GetRoleDto'],
    async queryFn({ signal }) {
      const config = { signal };
      const res = await getAllRoles(config);
      return res.data.sort((a, b) => (a.type < b.type ? 1 : -1));
    },
    initialData: [],
    retry: false,
    refetchOnWindowFocus: false,
  });

  const refetchTableData = useCallback(() => {
    roles.refetch();
    permissions.refetch();
  }, [permissions, roles]);

  const addCustomRole = useCallback(
    (roleName: string) => {
      const formValues = getValues('permissionsCollection');
      const currentRoleNames = formValues.map((role) => role.name);
      if (currentRoleNames.includes(roleName)) {
        const error = new Error(`New role name ${roleName} is already present`);
        return handleErrors(error as ServerError);
      }
      const customId = `customId-${Date.now()}`;
      const role: RoleTable = {
        id: customId,
        name: roleName,
        type: null,
        permissionIds: [],
      };
      append(role);
    },
    [append, getValues, handleErrors],
  );

  const removeCustomRole = useCallback(
    async (roleId: string) => {
      const roleIndex = fields.findIndex((role) => role.id === roleId);
      const currentRole = getValues(`permissionsCollection.${roleIndex}` as const);
      if (roleIndex === -1) return;
      if (currentRole.type === RoleType.Custom) {
        try {
          await removeRole(currentRole.id);
          remove(roleIndex);
        } catch (error) {
          return handleErrors(error as ServerError);
        }
      } else {
        remove(roleIndex);
      }
    },
    [fields, getValues, handleErrors, remove],
  );

  const renameCustomRole = useCallback(
    (roleEdited: RenamedRoleType) => {
      const roleIndex = fields.findIndex((role) => role.id === roleEdited.roleId);
      if (roleIndex === -1) return;
      const currentRole = getValues(`permissionsCollection.${roleIndex}` as const);
      update(roleIndex, { ...currentRole, name: roleEdited.name });
    },
    [fields, getValues, update],
  );

  const submitRoles = async (payload: CreateRoleDto[]) => {
    await putRole(payload);
    refetchTableData();
  };

  const onSubmit = handleSubmit(async (formData) => {
    handleErrors();

    const customRolesList: CreateRoleDto[] = formData.permissionsCollection
      .filter((role) => role.type === RoleType.Custom || role.type === null)
      .map((role) =>
        !role.id.includes('customId')
          ? role
          : {
              ...role,
              id: null,
              type: RoleType.Custom,
            },
      );

    // check if custom role present
    const hasCustomRoles = customRolesList.length;
    // check if custom role has more than 0 permission
    const hasRoleEmptyPermission = hasCustomRoles && customRolesList.some((role) => !role.permissionIds.length);

    try {
      if (!hasCustomRoles) {
        const error = new Error('One or more custom roles not found');
        return handleErrors(error as ServerError);
      } else if (hasRoleEmptyPermission) {
        const error = new Error('New role must include any permission');
        return handleErrors(error as ServerError);
      } else {
        await submitRoles(customRolesList);
        setAlertState(true);
      }
    } catch (error) {
      const serverErrors = new ServerErrorAdapter(error as ServerError);
      setServerErrorMessages(serverErrors.combine());
    }
  });

  const roleHeaderOptions = useMemo(() => {
    return fields.map((role) => {
      const { id, type } = role;
      return { name: Converter.stripUpperToCapitalizedFirst(role.name), id, type };
    });
  }, [fields]);

  const permissionsByType = useMemo(() => {
    const permissionsList = permissions.data ?? [];
    const permissionsListByType = permissionsList.reduce((acc, permission) => {
      const { type } = permission;
      acc[type] = acc[type] ?? [];
      acc[type].push(permission);

      return acc;
    }, {} as PermissionsByType);

    return permissionsListByType;
  }, [permissions.data]);

  const uniquePermissionTypes = useMemo(() => Object.keys(permissionsByType), [permissionsByType]);

  const permissionsSetByRole = useMemo(() => {
    const result = fields.reduce((acc, role) => {
      acc[role.id] = [];
      uniquePermissionTypes.forEach((permissionType) => {
        const permissionsSection = permissionsByType[permissionType];
        const permissionListByType = permissionsSection.map((el) => el.id);
        return acc[role.id].push({ [permissionType]: [...permissionListByType] });
      });

      return acc;
    }, {} as PermissionsSetByRole);
    return result;
  }, [permissionsByType, fields, uniquePermissionTypes]);

  useEffect(() => {
    // init useFormState
    setValue('permissionsCollection', roles.data);
  }, [roles.data, setValue]);

  useEffect(() => {
    const rolesErrorMessages = new ServerErrorAdapter(roles.error as ServerError).combine();
    const permissionsErrorMessages = new ServerErrorAdapter(permissions.error as ServerError).combine();
    const errorMessages = [...rolesErrorMessages, ...permissionsErrorMessages];
    setServerErrorMessages(errorMessages);
  }, [roles.error, permissions.error, setServerErrorMessages]);

  const breadcrumbPaths = [
    { name: 'Home', to: RoutePath.root },
    { name: 'Configuration', to: RoutePath.configurationRoot },
  ];

  const isAllowEditRole = hasUserPermission(PermissionNames.UserWrite);

  return (
    <div className="h-100">
      <HeaderConfig
        className="d-flex justify-content-between align-items-center mb-4"
        title="Roles and permissions"
        breadcrumbPaths={breadcrumbPaths}
      >
        {isAllowEditRole && <AddRoleButton addRole={addCustomRole} />}
      </HeaderConfig>

      <div
        className={classNames(styles.TableContainer, styles.Scroll, 'custom-scroll border rounded mb-3')}
        /* Calculate table height = widowsSize - header - mainPadding *2 - nav - navMargin - header - actionButton - actionButtonMargin - tableMargin - submitButton - footer  */
        style={{ height: windowSize.height - 56 - 40 * 2 - 24 - 24 - 24 - (44 + 16) - 16 - 44 - 16 - 48 }}
        ref={tableRef}
        {...events}
      >
        {!permissions.isLoading && !roles.isLoading ? (
          <table className={classNames(styles.Table, 'mb-0')}>
            <thead className={classNames(styles.TableHead)}>
              <TableHead options={roleHeaderOptions} removeRole={removeCustomRole} editRole={renameCustomRole} />
            </thead>
            <tbody className={classNames(styles.TableBody)}>
              <TableBody
                uniquePermissionTypes={uniquePermissionTypes}
                permissionsByType={permissionsByType}
                control={control}
                fields={fields}
                permissionsSetByRole={permissionsSetByRole}
                register={register}
              />
            </tbody>
          </table>
        ) : (
          <table className="table table-white border border-top-0 mb-0">
            <tbody>
              <tr>
                <th>
                  <span className="placeholder w-100 bg-white">Loading</span>
                </th>
              </tr>
            </tbody>
          </table>
        )}
      </div>

      <RolesPermissionsAlert>Roles and permissions settings saved</RolesPermissionsAlert>
      <ServerErrorMessages messages={serverErrorMessages} />

      {isAllowEditRole && (
        <div className="d-flex justify-content-end">
          <LoadingButton className="btn btn-primary" type="button" onClick={onSubmit} isLoading={isLoading}>
            Save Changes
          </LoadingButton>
        </div>
      )}
    </div>
  );
};

export default RolePermissionConfig;
