import classNames from 'classnames';
import { Fragment, useId } from 'react';
import { Control, useController, useWatch } from 'react-hook-form';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, createSearchParams, generatePath } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { AwaitingSignatureIcon, DueActiveIcon, DueExtendIcon, DueEndIcon } from 'src/components/icons/dashboard';

import { DateRangeFormData } from 'src/pages/dashboard/Dashboard';

import { transactionRangeOptions } from 'src/utils/selectOptions';

import { DashboardAgreementsDto, AgreementTransactionType } from 'src/types';

import styles from 'src/pages/dashboard/components/UpcomingTransitions.module.scss';

const getInputId = function Id() {
  return useId();
};

type TransactionsBarProps = {
  data: DashboardAgreementsDto | undefined;
  control: Control<DateRangeFormData, any>;
};

const UpcomingTransitionsBar = ({ data, control }: TransactionsBarProps) => {
  const { field } = useController({
    name: 'range',
    control,
  });
  const rangeField = useWatch({ control, name: 'range' });

  return (
    <>
      <div className="d-flex align-items-end gap-2 mb-4">
        <h2 className="h5 mb-0">Upcoming agreement transitions</h2>
        <div className="btn-group ms-auto" role="group" aria-label="range radio toggle button group vstack">
          {transactionRangeOptions.map((range) => {
            const inputId = getInputId();
            return (
              <Fragment key={inputId}>
                <input
                  type="radio"
                  className="btn-check"
                  id={inputId}
                  autoComplete="off"
                  {...field}
                  value={range.value}
                  checked={field.value === range.value}
                />
                <label
                  className={classNames(styles.RangeButton, 'btn btn-outline-primary text-nowrap')}
                  htmlFor={inputId}
                >
                  {range.label}
                </label>
              </Fragment>
            );
          })}
        </div>
      </div>
      <div className="row g-3 mb-3">
        <div className="col-sm-6 col-lg-3">
          <OverlayTrigger placement="bottom" overlay={<Tooltip>Agreements which are awaiting signature</Tooltip>}>
            <div className="card vstack h-100">
              <div className="card-body d-flex flex-row justify-content-between mb-2 align-items-center">
                <div>
                  <h2 className="card-title h5">
                    <Link
                      to={generatePath(
                        `${RoutePath.dealRotorList}?${createSearchParams({
                          agreementTransactionType: AgreementTransactionType.AwaitingSignature,
                          daysRange: rangeField,
                        })}`,
                      )}
                    >
                      Awaiting signature
                    </Link>
                  </h2>
                  <span className="card-text fs-2 fw-semibold me-2">{data?.awaitingSignature}</span>
                </div>
                <AwaitingSignatureIcon width={64} height={64} />
              </div>
            </div>
          </OverlayTrigger>
        </div>

        <div className="col-sm-6 col-lg-3">
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip>Agreements which are executed and awaiting to become in effect</Tooltip>}
          >
            <div className="card vstack h-100">
              <div className="card-body d-flex flex-row justify-content-between mb-2 align-items-center">
                <div>
                  <h2 className="card-title h5">
                    <Link
                      to={generatePath(
                        `${RoutePath.dealRotorList}?${createSearchParams({
                          agreementTransactionType: AgreementTransactionType.DueToBecomeActive,
                          daysRange: rangeField,
                        })}`,
                      )}
                    >
                      Due to become active
                    </Link>
                  </h2>
                  <span className="card-text fs-2 fw-semibold me-2">{data?.dueToBecomeActive}</span>
                </div>
                <DueActiveIcon width={64} height={64} />
              </div>
            </div>
          </OverlayTrigger>
        </div>

        <div className="col-sm-6 col-lg-3">
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip>Agreements with executed addendum awaiting to become in effect</Tooltip>}
          >
            <div className="card vstack h-100">
              <div className="card-body d-flex flex-row justify-content-between mb-2 align-items-center">
                <div>
                  <h2 className="card-title h5">
                    <Link
                      to={generatePath(
                        `${RoutePath.dealRotorList}?${createSearchParams({
                          agreementTransactionType: AgreementTransactionType.DueToExtend,
                          daysRange: rangeField,
                        })}`,
                      )}
                    >
                      Due to extend
                    </Link>
                  </h2>
                  <span className="card-text fs-2 fw-semibold me-2">{data?.dueToExtend}</span>
                </div>
                <DueExtendIcon width={64} height={64} />
              </div>
            </div>
          </OverlayTrigger>
        </div>
        <div className="col-sm-6 col-lg-3">
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip>Agreements which are in effect and about to enter completion</Tooltip>}
          >
            <div className="card vstack h-100">
              <div className="card-body d-flex flex-row justify-content-between mb-2 align-items-center">
                <div>
                  <h2 className="card-title h5">
                    <Link
                      to={generatePath(
                        `${RoutePath.dealRotorList}?${createSearchParams({
                          agreementTransactionType: AgreementTransactionType.DueToEnd,
                          daysRange: rangeField,
                        })}`,
                      )}
                    >
                      Due to end
                    </Link>
                  </h2>
                  <span className="card-text fs-2 fw-semibold me-2">{data?.dueToEnd}</span>
                </div>
                <DueEndIcon width={64} height={64} />
              </div>
            </div>
          </OverlayTrigger>
        </div>
      </div>
    </>
  );
};

export default UpcomingTransitionsBar;
