import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/router';

import useUrlQuery from 'src/hooks/useUrlQuery';

import { LoginStatus } from 'src/types';

type NavigateByLoginStatusParams = {
  status: LoginStatus;
  email: string;
  password: string;
  secret: string | null;
};

function useMFA() {
  const navigate = useNavigate();
  const urlQuery = useUrlQuery();
  const [isCodeStage, setIsCodeStage] = useState(false);

  const r = useMemo(() => urlQuery.get('r'), [urlQuery]);
  const redirectPath = useMemo<string | null>(() => {
    if (!r) return null;
    try {
      const path = atob(r);
      return path.startsWith('/') ? path : null;
    } catch (e) {
      return null;
    }
  }, [r]);

  const handleLoginStatus = useCallback(
    ({ status, email, password, secret }: NavigateByLoginStatusParams) => {
      switch (status) {
        case LoginStatus.Success:
          navigate(redirectPath || RoutePath.root, { replace: true });
          break;
        case LoginStatus.RequireSetupMFA:
          navigate(r ? `${RoutePath.setupMFA}?r=${r}` : RoutePath.setupMFA, {
            replace: true,
            state: { email, password, secret },
          });
          break;
        case LoginStatus.RequireMFA:
          setIsCodeStage(true);
          break;
        case LoginStatus.Failed:
        default:
      }
    },
    [navigate, r, redirectPath],
  );

  return { isCodeStage, setIsCodeStage, handleLoginStatus };
}

export default useMFA;
