import classNames from 'classnames';
import { ServerFormErrors } from 'src/types';

type Props = {
  messages?: ServerFormErrors;
  className?: string;
};

function ServerErrorMessages({ messages, className }: Props) {
  if (!messages || !messages.length) return null;
  return (
    <div className={classNames('alert alert-danger', className)} role="alert" aria-live="assertive">
      <ul className="list-unstyled mb-0">
        {messages.map((message, index) => (
          <li key={index}>{message}</li>
        ))}
      </ul>
    </div>
  );
}

export default ServerErrorMessages;
