import classNames from 'classnames';

import { StatusTickIcon } from 'src/components/icons';

import { OrganisationType } from 'src/types';

import styles from './SignDocumentFinish.module.scss';

type Props = {
  partiesText: string;
  dealRotorNumber: string | null;
  currentUserPartyType: OrganisationType | null;
  setIsSignDocumentFinished: React.Dispatch<React.SetStateAction<boolean>>;
};

function SignDocumentFinish({ partiesText, dealRotorNumber, currentUserPartyType, setIsSignDocumentFinished }: Props) {
  return (
    <div className="bg-light border position-absolute top-0 h-100 w-100 d-flex z-2">
      <div className={classNames(styles.Content, 'vstack gap-3 text-center m-auto')}>
        <div className="text-success">
          <StatusTickIcon width={72} height={72} />
        </div>
        <strong>You have successfully signed the agreement between {partiesText}.</strong>
        <div className="text-secondary">
          A copy of the final agreement will be emailed to you once all parties have signed. You can also{' '}
          <button
            className="btn btn-link lh-base align-baseline border-0 p-0"
            type="button"
            onClick={() => setIsSignDocumentFinished(false)}
          >
            click here
          </button>{' '}
          to view the document.
        </div>
        {dealRotorNumber && <div className="text-secondary">BridgingX deal reference number {dealRotorNumber}.</div>}
        {currentUserPartyType === OrganisationType.PartyB && (
          <div className="vstack gap-3 py-5">
            <em className="h4 fst-normal fw-medium mb-0">What is BridgingX?</em>
            <div className="text-secondary">
              BridgingX is used by organisations to help manage
              <br /> the life cycle of deals and agreements.
            </div>
            <a
              className="btn btn-primary align-self-center"
              href="https://bridgingx.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              More information
            </a>
          </div>
        )}
        <div className="fw-medium">No further action required.</div>
      </div>
    </div>
  );
}

export default SignDocumentFinish;
