function dataURLtoFile(dataUrl: string, filename: string) {
  const arr = dataUrl.split(',');
  const [, mime = ''] = arr[0].match(/:(.*?);/) || [];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export const createSignImage = (signature: string, prefix: string, isPrintedName: boolean) => {
  const text = ` ${signature} `;
  const fontSize = 48;
  const font = isPrintedName ? `500 ${fontSize}px IBM Plex Sans` : `600 ${fontSize}px Kaufmann BT`;

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

  ctx.font = font;
  const width = ctx.measureText(text).width;
  const height = 1.5 * fontSize;

  canvas.width = width;
  canvas.height = height;

  ctx.font = font;
  ctx.fillStyle = 'black';
  ctx.fillText(text, 0, fontSize);
  const dataUrl = canvas.toDataURL('image/png');

  const fileName = `${prefix}_${new Date().toISOString()}_sign.png`;
  const file = dataURLtoFile(dataUrl, fileName);

  return file;
};
