import { useState, useMemo } from 'react';
import { addDays } from 'date-fns';
import { useQueryClient } from '@tanstack/react-query';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useParams, Params } from 'react-router-dom';

import { useSelector } from 'src/store';
import { tenantTimezoneInBracketsSelector } from 'src/store/selectors/tenantSelector';

import { useForm } from 'react-hook-form';
import { object, string, date } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Field from 'src/components/forms/Field';
import LoadingButton from 'src/components/buttons/LoadingButton';
import ServerErrorMessages from 'src/components/ServerErrorMessages';
import { InfoIcon, PlusIcon } from 'src/components/icons';

import useUserPermission from 'src/hooks/useUserPermission';
import useTenantTimezone from 'src/hooks/useTenantTimezone';
import { useFormErrors } from 'src/hooks/FormHelpers';

import Converter from 'src/utils/Converter';
import LocalDate from 'src/utils/LocalDate';

import { createAddendum } from 'src/api/base/agreement';

import {
  CreateAddendumModel,
  AgreementEndDateModification,
  ServerError,
  YupFormShape,
  UUIDString,
  DateTimeISOString,
  PermissionNames,
  SelectOption,
} from 'src/types';

type Props = {
  agreementId: UUIDString;
  agreementEndDate: DateTimeISOString | null | undefined;
};

type FormData = CreateAddendumModel;
type FormShape = YupFormShape<FormData>;

function AddendumCreate({ agreementId, agreementEndDate }: Props) {
  const tenantTimezoneText = useSelector(tenantTimezoneInBracketsSelector);
  const { tenantTimezoneDateNow } = useTenantTimezone();

  const { hasUserPermission } = useUserPermission();
  const isAllowedDealRotorAgreementWrite = hasUserPermission(PermissionNames.DealRotorAgreementWrite);

  const queryClient = useQueryClient();
  const { dealRotorId = '' } = useParams<Params<'dealRotorId'>>();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const minDate = useMemo(() => {
    if (!agreementEndDate) return new Date(1970);
    const endDateStartDay = new LocalDate(agreementEndDate).getStartDay().value;
    const todayStartDay = new LocalDate(tenantTimezoneDateNow).getStartDay().value;
    return todayStartDay.getTime() >= endDateStartDay.getTime() ? todayStartDay : addDays(endDateStartDay, 1);
  }, [agreementEndDate, tenantTimezoneDateNow]);

  const isEndDateInPast = useMemo<boolean>(() => {
    if (!agreementEndDate) return false;
    const endDateEndDay = new LocalDate(agreementEndDate).getEndDay().value;
    return endDateEndDay.getTime() < Date.now();
  }, [agreementEndDate]);

  const endDateModificationOptions = useMemo<SelectOption[]>(
    () => [
      {
        label: 'No',
        value: AgreementEndDateModification.WithoutModification,
        isDisabled: isEndDateInPast,
      },
      { label: 'Yes', value: AgreementEndDateModification.WithModification },
    ],
    [isEndDateInPast],
  );

  const formSchema = useMemo(
    () =>
      object().shape<FormShape>({
        endDateModification: string().label('Option').required(),
        agreementEndDate: date()
          .label('Agreement end date')
          .nullable()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .when('endDateModification', {
            is: AgreementEndDateModification.WithModification,
            then: (schema) =>
              schema
                .min(minDate, ({ label, min }) => {
                  const startDateFormatted = Converter.getFormattedDate(new LocalDate(min).toJSON());
                  return `${label} field must be ${startDateFormatted} or later`;
                })
                .required(),
            otherwise: (schema) => schema.optional(),
          }),
      }),
    [minDate],
  );
  const { register, control, handleSubmit, formState, setError, clearErrors, reset, watch } = useForm<FormData>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      endDateModification: AgreementEndDateModification.WithModification,
    },
  });
  const { serverErrorMessages, handleErrors } = useFormErrors<FormData>(setError, clearErrors);
  const endDateModification = watch('endDateModification');

  const onSubmit = handleSubmit(async ({ endDateModification, agreementEndDate }) => {
    try {
      handleErrors();

      const payload: CreateAddendumModel = {
        endDateModification,
        agreementEndDate: undefined,
      };
      if (agreementEndDate && endDateModification === AgreementEndDateModification.WithModification) {
        payload.agreementEndDate = new LocalDate(agreementEndDate).getEndDay().toJSON();
      }
      await createAddendum(agreementId, payload);

      await Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: ['getDealRotorById', dealRotorId] }),
        queryClient.invalidateQueries({ queryKey: ['getAgreementList', dealRotorId] }),
        queryClient.invalidateQueries({ queryKey: ['getDealRotorAuditListSearch', dealRotorId] }),
      ]);
      queryClient.removeQueries({ queryKey: ['getDealRotorListSearch'] });
      queryClient.removeQueries({ queryKey: ['getDealRotorAuditListAll'] });

      closeModal();
    } catch (e) {
      handleErrors(e as ServerError);
    }
  });

  const onClick = () => {
    reset();
    handleErrors();
    setIsModalOpen(true);
  };

  return (
    <>
      {isAllowedDealRotorAgreementWrite && (
        <button className="btn btn-primary text-nowrap hstack align-self-start gap-2" type="button" onClick={onClick}>
          <span>Addendum</span>
          <PlusIcon />
        </button>
      )}
      <Modal show={isModalOpen} onHide={closeModal} size="lg" centered>
        <Modal.Header onHide={closeModal} closeButton>
          <Modal.Title className="h5">Addendum</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Does this addendum modify the expiry date of the current legal agreement?</p>
          <form name="createAddendumForm" onSubmit={onSubmit} noValidate>
            <div className="d-flex gap-3 mb-3">
              <Field
                inputGroupClassName="hstack flex-nowrap h-44"
                formCheckClassName="form-check-inline"
                field="input"
                type="radio"
                autoComplete="off"
                register={register('endDateModification')}
                control={control}
                formSchema={formSchema}
                errors={formState.errors}
                options={endDateModificationOptions}
              />

              {endDateModification === AgreementEndDateModification.WithModification && (
                <>
                  <Field
                    field="datepicker"
                    autoComplete="off"
                    register={register('agreementEndDate')}
                    control={control}
                    formSchema={formSchema}
                    errors={formState.errors}
                    datepickerProps={{ minDate }}
                  />
                  <div className="hstack h-44">
                    <span>{tenantTimezoneText}</span>
                  </div>
                </>
              )}

              {isEndDateInPast && (
                <div className="d-flex align-items-center align-self-start h-44">
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip>
                        New addendum expiry date must be modified because current legal agreement had expired
                      </Tooltip>
                    }
                  >
                    <InfoIcon className="text-primary" />
                  </OverlayTrigger>
                </div>
              )}
            </div>

            <div className="d-flex flex-wrap justify-content-end gap-3">
              <button type="button" className="btn btn-link" onClick={closeModal}>
                Cancel
              </button>
              <LoadingButton type="submit" className="btn btn-primary" isLoading={formState.isSubmitting}>
                Continue
              </LoadingButton>
              <ServerErrorMessages className="w-100 mb-0" messages={serverErrorMessages} />
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddendumCreate;
