import classNames from 'classnames';
import { useMemo, useEffect, useState, useCallback } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useLocation, useParams, Params, Navigate } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { object, string, array, mixed, boolean } from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useSelector, useDispatch } from 'src/store';
import { userIdSelector, userSelector } from 'src/store/selectors/profileSelector';
import { fetchCurrentUser } from 'src/store/slices/profileSlice';

import Field from 'src/components/forms/Field';
import Breadcrumbs from 'src/components/Breadcrumbs';
import LoadingButton from 'src/components/buttons/LoadingButton';
import SignedDocuments from 'src/pages/profile/components/SignedDocuments';
import ServerErrorAdapter from 'src/utils/ServerErrorAdapter';
import ServerErrorMessages from 'src/components/ServerErrorMessages';
import ResetMFAModal from './components/resetMFAModal';

import DealsList from 'src/pages/profile/components/DealsList';
import useAlertStatus from 'src/hooks/useAlertStatus';

import useUserRole from 'src/hooks/useUserRole';
import useUserPermission from 'src/hooks/useUserPermission';
import { useFormErrors } from 'src/hooks/FormHelpers';

import Converter from 'src/utils/Converter';

import {
  getUserById,
  uploadUserLogo,
  uploadCurrentUserLogo,
  updateUserRoles,
  updateUser,
  updateCurrentUser,
} from 'src/api/base/user';
import { getAllRoles } from 'src/api/base/permissions';

import {
  UpdateUserModel,
  YupFormShape,
  ServerError,
  SelectOption,
  UserAccessStatus,
  PermissionNames,
  GetUserDto,
  GetCurrentUserDto,
  UUIDString,
  RoleDto,
  RoleName,
} from 'src/types';

import styles from 'src/pages/profile/UserProfile.module.scss';
import checkFormHasChanges from 'src/utils/checkFormHasChanges';

type FormData = UpdateUserModel & {
  email: string;
  roles: string[];
  logoFile: FileList;
};

type FormShape = YupFormShape<FormData>;

const formSchema = object().shape<FormShape>({
  firstName: string().label('First name').trim().required(),
  lastName: string().label('Last name').trim().required(),
  email: string().label('Email').trim().email(),
  roles: array().of(string()).label('Roles'),
  logoFile: mixed<FileList>().label('User logo').fileExt(['.png']).fileSizeMax(30000000),
  title: string().label('Job title').trim().required(),
  phone: string().label('Phone number').trim(),
  mobile: string().label('Phone mobile').trim(),
  shouldBeInvited: boolean().label('Invite user').default(false),
});

const UserProfileForm = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const currentUserId = useSelector(userIdSelector);
  const currentUser = useSelector(userSelector);
  const { userId = '' } = useParams<Params<'userId'>>();
  const { setAlertState: setProfileUpdateAlertState, AlertStatus: ProfileUpdateAlert } = useAlertStatus({
    delay: 8000,
  });
  const { setAlertState: setResetMFAAlertState, AlertStatus: ResetMFAAlert } = useAlertStatus({ delay: 8000 });

  const [isShowResetMFAModalOpen, setIsShowResetMFAModalOpen] = useState(false);
  const closeResetMFAModal = useCallback(() => {
    setIsShowResetMFAModalOpen(false);
    setResetMFAAlertState(true);
  }, [setResetMFAAlertState]);

  const { hasUserRole } = useUserRole();
  const hasGlobalDealRotorManagerRole = hasUserRole(RoleName.GlobalDealRotorManager);

  const { hasUserPermission } = useUserPermission();
  const isAllowedDealRotorRead = hasUserPermission(PermissionNames.DealRotorRead);
  const isAllowedOrganisationRead = hasUserPermission(PermissionNames.OrganisationRead);
  const isAllowedUserResetMFA = hasUserPermission(PermissionNames.UserResetMFA);
  const isAllowedUserWrite = hasUserPermission(PermissionNames.UserWrite);
  const isAllowedRoleRead = hasUserPermission(PermissionNames.RoleRead);

  const { register, control, formState, setValue, setError, clearErrors, reset, handleSubmit, watch } =
    useForm<FormData>({
      resolver: yupResolver(formSchema),
    });

  const { serverErrorMessages, setServerErrorMessages, handleErrors } = useFormErrors<FormData>(setError, clearErrors);

  const {
    data: user,
    error: userError,
    refetch: userRefetch,
  } = useQuery({
    enabled: Boolean(userId),
    queryKey: ['getUserById', userId],
    async queryFn({ signal }): Promise<GetUserDto | GetCurrentUserDto | null> {
      if (userId === currentUserId) return currentUser;
      const config = { signal };
      const res = await getUserById(userId, config);
      return res.data;
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  const {
    data: roleList,
    error: roleListError,
    isLoading: roleListIsLoading,
  } = useQuery({
    queryKey: ['getAllRoles', isAllowedRoleRead, userId, currentUserId],
    async queryFn({ signal }) {
      if (!isAllowedRoleRead || userId === currentUserId) return [];
      const config = { signal };
      const res = await getAllRoles(config);
      return res.data;
    },
    initialData: [],
    retry: false,
    refetchOnWindowFocus: false,
  });
  const roleOptions = useMemo<SelectOption[]>(() => {
    if (userId === currentUserId && currentUser && currentUser.roles) {
      return currentUser.roles.map((name) => ({ label: Converter.stripUpperToCapitalizedFirst(name), value: name }));
    }
    return roleList.map(({ id, name }) => ({ label: Converter.stripUpperToCapitalizedFirst(name), value: id }));
  }, [currentUser, currentUserId, roleList, userId]);

  const updateUserLogo = async (userId: string | undefined, file: FileList) => {
    if (file.length === 0) return;
    if (!userId) return;
    try {
      const formData = new FormData();
      formData.append('file', file[0]);
      if (userId === currentUserId) {
        await uploadCurrentUserLogo(formData);
      } else {
        await uploadUserLogo(userId, formData);
      }
    } catch (e) {
      const serverErrors = new ServerErrorAdapter(e as ServerError);
      setServerErrorMessages(serverErrors.combine());
    }
  };

  const userPrefill = useMemo(() => {
    if (!user) return null;
    return {
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      email: user.email || '',
      title: user.title || '',
      phone: user.phone || '',
      mobile: user.mobile || '',
      shouldBeInvited: false,
    };
  }, [user]);

  const rolesPrefill = useMemo(() => {
    if (!user) return null;
    let roles: UUIDString[] = [];
    if (user.roles) {
      if (userId === currentUserId) {
        roles = user.roles as string[];
      } else {
        roles = (user.roles as RoleDto[]).map((role) => role.id);
      }
    }
    return { roles };
  }, [currentUserId, userId, user]);

  const { firstName, lastName, email, title, phone, mobile, shouldBeInvited, roles, logoFile } = watch();

  const hasUserChanges = useMemo(
    () => checkFormHasChanges({ firstName, lastName, email, title, phone, mobile, shouldBeInvited }, userPrefill),
    [firstName, lastName, email, title, phone, mobile, shouldBeInvited, userPrefill],
  );
  const hasRolesChanges = useMemo(() => checkFormHasChanges({ roles }, rolesPrefill), [roles, rolesPrefill]);
  const hasLogoChanges = useMemo(() => (logoFile ? logoFile.length > 0 : false), [logoFile]);
  const hasChanges = hasUserChanges || hasRolesChanges || hasLogoChanges;

  const onSubmit = handleSubmit(async (formData) => {
    if (!userId) return;
    const { logoFile, roles, ...userData } = formData;
    const userRoles = { roleIds: roles };
    try {
      if (userId === currentUserId) {
        if (hasUserChanges) {
          await updateCurrentUser(userData);
        }
        if (hasLogoChanges) {
          await updateUserLogo(userId, logoFile);
          reset({ logoFile: undefined });
        }

        await dispatch(fetchCurrentUser());
      } else {
        if (hasUserChanges) {
          await updateUser(userId, userData);
        }
        if (hasRolesChanges) {
          await updateUserRoles(userId, userRoles);
        }
        if (hasLogoChanges) {
          await updateUserLogo(userId, logoFile);
          reset({ logoFile: undefined });
        }
        await userRefetch();
      }
      setProfileUpdateAlertState(true);
      queryClient.removeQueries({ queryKey: ['getUserListSearch'] });
    } catch (error) {
      handleErrors(error as ServerError);
    }
  });

  useEffect(() => {
    if (!userPrefill || !rolesPrefill) return;

    setValue('firstName', userPrefill.firstName);
    setValue('lastName', userPrefill.lastName);
    setValue('email', userPrefill.email);
    setValue('title', userPrefill.title);
    setValue('phone', userPrefill.phone);
    setValue('mobile', userPrefill.mobile);
    setValue('shouldBeInvited', userPrefill.shouldBeInvited);

    setValue('roles', rolesPrefill.roles);
  }, [userPrefill, rolesPrefill, setValue]);

  useEffect(() => {
    const userServerError = new ServerErrorAdapter(userError as ServerError).combine();
    const roleListServerError = new ServerErrorAdapter(roleListError as ServerError).combine();
    const serverErrors = [...userServerError, ...roleListServerError];
    setServerErrorMessages(serverErrors);
  }, [roleListError, setServerErrorMessages, userError]);

  const canEditUserProfile = isAllowedUserWrite || userId === currentUserId;
  const isEditProfileDisabled = !canEditUserProfile;

  return (
    <>
      <form
        className="position-relative p-4 bg-white border rounded mb-3"
        name="userProfileForm"
        onSubmit={onSubmit}
        noValidate
      >
        <div className="row">
          <Field
            className="pb-1 col-3 mb-4"
            inputClassName={classNames(styles.ProfileLogo)}
            field="image"
            label="User logo"
            placeholder={'Upload PNG\nnot more than 3 MB'}
            accept=".png"
            autoComplete="off"
            register={register('logoFile')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
            url={user?.logoUrl}
            disabled={isEditProfileDisabled}
          />
        </div>
        <div className="row">
          <Field
            className="col-md-6 col-lg-3 mb-2"
            field="input"
            label="Email"
            type="email"
            placeholder="Enter email"
            autoComplete="off"
            register={register('email')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
            disabled
          />
          <Field
            className="col-md-6 col-lg-3 mb-2"
            label="First name"
            field="input"
            type="text"
            placeholder="Enter first name"
            autoComplete="off"
            register={register('firstName')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
            disabled={isEditProfileDisabled}
            autoFocus
          />
          <Field
            className="col-md-6 col-lg-3 mb-2"
            label="Last name"
            field="input"
            type="text"
            placeholder="Enter last name"
            autoComplete="off"
            register={register('lastName')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
            disabled={isEditProfileDisabled}
          />
        </div>
        <div className="row">
          <Field
            className="col-md-6 col-lg-3 mb-2"
            field="input"
            label="Job title"
            type="text"
            placeholder="Enter job title"
            autoComplete="off"
            register={register('title')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
            disabled={isEditProfileDisabled}
          />
          <Field
            className="col-md-6 col-lg-3 mb-2"
            label="Phone number"
            field="input"
            type="tel"
            placeholder="Enter phone number"
            autoComplete="off"
            register={register('phone')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
            disabled={isEditProfileDisabled}
          />
          <Field
            className="col-md-6 col-lg-3 mb-2"
            label="Phone mobile"
            field="input"
            type="tel"
            placeholder="Enter phone mobile"
            autoComplete="off"
            register={register('mobile')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
            disabled={isEditProfileDisabled}
          />
        </div>

        {(isAllowedRoleRead || userId === currentUserId) && (
          <div className="row">
            <Field
              className="col-md-12 col-lg-6 mb-3"
              field="dropdown"
              label="Roles"
              placeholder="Choose roles"
              autoComplete="off"
              register={register('roles')}
              control={control}
              formSchema={formSchema}
              errors={formState.errors}
              options={roleOptions}
              multiple
              dropdownProps={{
                isLoading: roleListIsLoading,
                isSearchable: true,
                isClearable: false,
              }}
              disabled={isEditProfileDisabled || userId === currentUserId}
            />
          </div>
        )}

        <div className="row">
          <Field
            className={classNames('col-12 mb-3', {
              'visually-hidden':
                (user && [UserAccessStatus.Active, UserAccessStatus.Deactivated].includes(user.accessStatus)) ||
                userId === currentUserId,
            })}
            field="input"
            type="checkbox"
            label="Invite user"
            register={register('shouldBeInvited')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
            disabled={isEditProfileDisabled}
          />
        </div>
        <div className="d-flex justify-content-end gap-2">
          {currentUserId !== userId && isAllowedUserResetMFA && user?.mfaEnabled === true && (
            <button
              className="btn btn-outline-danger me-auto"
              type="button"
              onClick={() => setIsShowResetMFAModalOpen(true)}
            >
              Reset MFA
            </button>
          )}

          <LoadingButton
            className="btn btn-primary"
            type="submit"
            disabled={isEditProfileDisabled || !hasChanges}
            isLoading={formState.isSubmitting}
          >
            Update
          </LoadingButton>
        </div>
      </form>
      <ProfileUpdateAlert>Profile settings saved</ProfileUpdateAlert>
      <ResetMFAAlert>User MFA had been reset</ResetMFAAlert>
      <ServerErrorMessages className="mt-3" messages={serverErrorMessages} />
      {currentUserId === userId && <SignedDocuments />}
      {currentUserId !== userId && isAllowedDealRotorRead && (
        <DealsList
          hasGlobalDealRotorManagerRole={hasGlobalDealRotorManagerRole}
          isAllowedOrganisationRead={isAllowedOrganisationRead}
          userId={userId}
        />
      )}
      {currentUserId !== userId && isAllowedUserResetMFA && user?.mfaEnabled === true && (
        <ResetMFAModal userId={userId} show={isShowResetMFAModalOpen} closeModal={closeResetMFAModal} />
      )}
    </>
  );
};

const UserProfile = () => {
  const { userId } = useParams<Params<'userId'>>();
  const location = useLocation();
  const currentUserId = useSelector(userIdSelector);
  const { hasUserPermission } = useUserPermission();

  const breadcrumbs = useMemo(
    () => [
      { name: 'Home', to: RoutePath.root },
      { name: 'Profile', to: location },
    ],
    [location],
  );

  const isAllowedUserRead = hasUserPermission(PermissionNames.UserRead) || userId === currentUserId;

  if (isAllowedUserRead) {
    return (
      <div className="h-100">
        <Breadcrumbs className="mb-4" breadcrumbs={breadcrumbs} />
        <header className="mb-5">
          <h1 className="h5">Profile details</h1>
        </header>
        <UserProfileForm />
      </div>
    );
  } else {
    return <Navigate to={RoutePath.root} />;
  }
};

export default UserProfile;
