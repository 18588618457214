import { PropsWithChildren, useCallback, useEffect } from 'react';

import store from 'src/store';
import { setTenantPublicSettings } from 'src/store/slices/tenantSlice';

import { getPublicTenantSettings } from 'src/api/admin/tenantPublic';

import { LS_TENANT_SETTINGS_KEY } from 'src/utils/constants';

import { TenantPublicSettingsDto } from 'src/types';

function applyTenantPublicSettings(tenantSettings: TenantPublicSettingsDto) {
  store.dispatch(setTenantPublicSettings(tenantSettings));
}
function saveTenantPublicSettings(tenantSettings: TenantPublicSettingsDto) {
  localStorage.setItem(LS_TENANT_SETTINGS_KEY, JSON.stringify(tenantSettings));
}
function restoreTenantPublicSettings() {
  try {
    const tenantSettingsRawJSON = localStorage.getItem(LS_TENANT_SETTINGS_KEY);
    if (!tenantSettingsRawJSON) return;
    const tenantSettingsRaw: unknown = JSON.parse(tenantSettingsRawJSON);
    if (
      typeof tenantSettingsRaw === 'object' &&
      tenantSettingsRaw !== null &&
      'logoUrl' in tenantSettingsRaw &&
      'name' in tenantSettingsRaw &&
      (typeof tenantSettingsRaw.logoUrl === 'string' || tenantSettingsRaw.logoUrl === null) &&
      typeof tenantSettingsRaw.name === 'string'
    ) {
      // Destructuring to satisfy TypeScript
      const { logoUrl, name } = tenantSettingsRaw;
      const tenantSettings: TenantPublicSettingsDto = { logoUrl, name };

      applyTenantPublicSettings(tenantSettings);
    }
  } catch (e) {}
}

function TenantPublicSettings(props: PropsWithChildren) {
  const initTenantPublicSettings = useCallback(async () => {
    restoreTenantPublicSettings();
    try {
      const res = await getPublicTenantSettings();
      const tenantSettings = res.data;
      applyTenantPublicSettings(tenantSettings);
      saveTenantPublicSettings(tenantSettings);
    } catch (e) {}
  }, []);

  useEffect(() => {
    initTenantPublicSettings();
  }, [initTenantPublicSettings]);

  return props.children;
}

export default TenantPublicSettings;
