import { useEffect, useCallback, Dispatch, SetStateAction, useState, lazy, Suspense } from 'react';
import { Modal } from 'react-bootstrap';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams, Params } from 'react-router-dom';
import { useWindowSize } from 'usehooks-ts';

import SpinnerLoader from 'src/components/SpinnerLoader';
import ServerErrorMessages from 'src/components/ServerErrorMessages';
import ServerErrorAdapter from 'src/utils/ServerErrorAdapter';

import { createTemplatePreview, createTemplatePDF } from 'src/api/base/agreementTemplate';

import { TemplateRenderedParamModel, ServerError, ServerFormErrors } from 'src/types';

const RichEditPreview = lazy(
  () => import(/* webpackChunkName: "rich-edit" */ 'src/components/richEdit/RichEdit.preview'),
);

type PreviewTemplateProps = {
  isOpenNested: boolean;
  setIsOpenNested: Dispatch<SetStateAction<boolean>>;
  setIsOpenParent: Dispatch<SetStateAction<boolean>>;
  parameters: TemplateRenderedParamModel[];
  templateId: string;
  dealRotorAgreementId: string;
  documentName: string;
};

const PreviewTemplate = ({
  isOpenNested,
  setIsOpenNested,
  setIsOpenParent,
  parameters,
  dealRotorAgreementId,
  templateId,
  documentName,
}: PreviewTemplateProps) => {
  const queryClient = useQueryClient();
  const { dealRotorId = '' } = useParams<Params<'dealRotorId'>>();
  const [serverErrorMessages, setServerErrorMessages] = useState<ServerFormErrors>([]);
  const windowsSize = useWindowSize();

  const {
    data: previewString,
    error: previewError,
    isLoading: previewIsLoading,
  } = useQuery({
    queryKey: ['createTemplatePreview', isOpenNested, parameters, dealRotorAgreementId, templateId],
    async queryFn({ signal }) {
      const config = { signal };
      const res = await createTemplatePreview(dealRotorAgreementId, templateId, { parameters }, config);
      return res.data;
    },
    retry: false,
    refetchOnWindowFocus: false,
    enabled: isOpenNested,
  });

  useEffect(() => {
    const serverError = new ServerErrorAdapter(previewError as ServerError).combine();
    setServerErrorMessages(serverError);
  }, [previewError]);

  const toggleNested = useCallback(() => {
    setServerErrorMessages([]);
    setIsOpenNested(!isOpenNested);
  }, [isOpenNested, setIsOpenNested]);

  const toggleAll = useCallback(() => {
    setIsOpenNested(false);
    setIsOpenParent(false);
  }, [setIsOpenNested, setIsOpenParent]);

  const onCancel = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: ['createTemplatePreview', isOpenNested, parameters, dealRotorAgreementId, templateId],
    });
    toggleNested();
  }, [dealRotorAgreementId, isOpenNested, parameters, queryClient, templateId, toggleNested]);

  const onRender = useCallback(async () => {
    try {
      await createTemplatePDF(dealRotorAgreementId, templateId, {
        name: documentName,
        templateRenderedParams: parameters,
      });

      await Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: ['getAgreementList', dealRotorId] }),
        queryClient.invalidateQueries({ queryKey: ['getDealRotorAuditListSearch', dealRotorId] }),
      ]);
      queryClient.removeQueries({ queryKey: ['getDealRotorListSearch'] });
      queryClient.removeQueries({ queryKey: ['getDealRotorAuditListAll'] });

      toggleAll();
    } catch (error) {
      const serverError = new ServerErrorAdapter(error as ServerError).combine();
      setServerErrorMessages(serverError);
    }
  }, [dealRotorAgreementId, dealRotorId, documentName, parameters, queryClient, templateId, toggleAll]);

  return (
    <Modal show={isOpenNested} onHide={toggleNested} fullscreen centered>
      <Modal.Header onHide={toggleNested} closeButton>
        <Modal.Title className="h5">PDF preview result</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex align-items-center h-100">
          {!previewString || previewIsLoading ? (
            <SpinnerLoader />
          ) : (
            <Suspense fallback={<SpinnerLoader />}>
              <RichEditPreview
                documentContent={previewString}
                height={String(windowsSize.height - 53 - 12 * 2 - 30 - 16 * 2)} //exclude header & footer modal height
              />
            </Suspense>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-primary" type="button" onClick={onCancel}>
          Cancel
        </button>
        <button className="btn btn-outline-primary" type="button" onClick={onRender} disabled={!!previewError}>
          Render & add
        </button>
      </Modal.Footer>
      <ServerErrorMessages className="mx-3" messages={serverErrorMessages} />
    </Modal>
  );
};

export default PreviewTemplate;
