import { memo } from 'react';
import { generatePath } from 'react-router-dom';
import { RoutePath } from 'src/router';

import FormatDate from 'src/components/FormatDate';
import LoadingButton from 'src/components/buttons/LoadingButton';
import { DeleteActionIcon } from 'src/components/icons/configuration-page';
import FileTypeIcon from 'src/components/icons/FileTypeIcon';

import { AgreementDocumentDto, UUIDString } from 'src/types';

type Props = {
  document: AgreementDocumentDto;
  onRemove?: (agreementDocumentId: UUIDString) => Promise<void> | void;
  isShowRemove: boolean;
  href?: string;
};

function AgreementDocument({ document, onRemove, isShowRemove, href }: Props) {
  const fileName = document.name || document.agreementDocumentId;

  const onClick = () => {
    if (!onRemove) return;
    return onRemove(document.agreementDocumentId);
  };

  return (
    <div className="hstack gap-2">
      <FileTypeIcon ext={document.name?.split('.').at(-1)} />
      <div className="text-secondary fs-14">
        <a
          className="d-block fw-medium cursor-pointer"
          href={href || generatePath(RoutePath.documentViewer, { agreementDocumentId: document.agreementDocumentId })}
          target="_blank"
          rel="noopener noreferrer"
        >
          {fileName}
        </a>
        <FormatDate date={document.createdDate} formatValue="dd/MM/yyyy - HH:mm:ss" />
      </div>
      {isShowRemove && (
        <LoadingButton className="btn btn-link text-decoration-none hstack gap-2 px-0" type="button" onClick={onClick}>
          <DeleteActionIcon height={16} width={16} />
          Remove
        </LoadingButton>
      )}
    </div>
  );
}

export default memo(AgreementDocument);
