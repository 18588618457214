import { AxiosRequestConfig } from 'axios';

import { baseApi } from 'src/api';

import { AgreementAttributeDto, UpdateAgreementAttributeModel, UUIDString } from 'src/types';

// PermissionNames.AgreementAttributesRead
export const getAgreementAttribute = (dealRotorId: UUIDString, config: AxiosRequestConfig = {}) =>
  baseApi.get<AgreementAttributeDto>(`/deal-rotors/${dealRotorId}/agreement-attributes`, config);

// PermissionNames.AgreementAttributesWrite
export const updateAgreementAttribute = (
  dealRotorId: UUIDString,
  data: UpdateAgreementAttributeModel,
  config: AxiosRequestConfig = {},
) => baseApi.patch<AgreementAttributeDto>(`/deal-rotors/${dealRotorId}/agreement-attributes`, data, config);
