import { AgreementDto } from 'src/types';

import styles from './AgreementDetails.watermark.module.scss';

type Props = {
  dealRotorAgreement: AgreementDto;
};

function AgreementDetailsWatermark({ dealRotorAgreement: { isSigned, isAddedToBlockchain } }: Props) {
  return isSigned || isAddedToBlockchain ? (
    <div className="d-flex gap-1 mt-3">
      {isSigned && <img src={require('src/assets/images/agreement/bridgingx-signed.png')} height={34} alt="signed" />}
      {isAddedToBlockchain && (
        <figure className={styles.Blockchain}>
          <img src={require('src/assets/images/agreement/blockchain.png')} height={24} alt="blockchain" />
        </figure>
      )}
      {false && (
        <img src={require('src/assets/images/agreement/cancelled.png')} height={34} alt="cancelled watermark" />
      )}
    </div>
  ) : null;
}

export default AgreementDetailsWatermark;
