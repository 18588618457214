import classNames from 'classnames';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  PaginationState,
  getPaginationRowModel,
} from '@tanstack/react-table';
import { useState, useMemo, useEffect } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Link, generatePath } from 'react-router-dom';

import { Pagination } from 'src/components/Table';
import { ActionsIcon, RemaindersIcon, AwaitingSignatureIcon } from 'src/components/icons';
import { EmptyActionIcon, EmptyRemainderIcon } from 'src/components/icons/dashboard';
import { RoutePath } from 'src/router';
import Message from 'src/components/Message';

import { UserActionDto, ReminderDto } from 'src/types';

import tableStyles from 'src/components/Table.module.scss';

type RemaindersBarProps = {
  actionsData: UserActionDto[] | undefined;
  remainderData: ReminderDto[] | undefined;
};

const columnHelperActions = createColumnHelper<UserActionDto>();
const columnHelperRemainders = createColumnHelper<ReminderDto>();

const columnsActions = [
  columnHelperActions.accessor('dealRotorName', {
    id: 'dealRotorName',
    header: 'Deal name',
    cell: ({ row }) => (
      <Link
        to={generatePath(RoutePath.dealRotorEditDetailedAgreementDetails, { dealRotorId: row.original.dealRotorId })}
      >
        {row.original.dealRotorName}
      </Link>
    ),
  }),
  columnHelperActions.accessor('partyBs', {
    id: 'partyBs',
    header: 'Party B',
    cell: ({ getValue }) => {
      const organisationsList = getValue() || [];
      const [firstOrganisationName, ...restOrganisationNames] = organisationsList;
      return (
        <div className="hstack gap-2">
          {firstOrganisationName && <span className="link-dark fw-medium">{firstOrganisationName}</span>}
          {restOrganisationNames.length > 0 && (
            <OverlayTrigger
              placement="bottom"
              rootClose={true}
              trigger={['click']}
              overlay={
                <Popover className="mb-3" body>
                  <ul className="list-unstyled mb-0">
                    {restOrganisationNames.map((name) => (
                      <li key={name}>{name}</li>
                    ))}
                  </ul>
                </Popover>
              }
            >
              <button
                type="button"
                className="btn btn-link fs-14 lh-1 fw-normal text-nowrap p-0"
              >{`+${restOrganisationNames.length} more`}</button>
            </OverlayTrigger>
          )}
        </div>
      );
    },
  }),
  columnHelperActions.display({
    id: 'actions',
    header: 'Required action',
    cell: ({ row }) => {
      return (
        <Link to={generatePath(RoutePath.notarisePrivate, { agreementId: row.original.agreementId })}>
          <div className="text-nowrap">
            <AwaitingSignatureIcon className="me-2 text-primary" />
            <span>Signature required</span>
          </div>
        </Link>
      );
    },
  }),
];

const columnsRemainders = [
  columnHelperRemainders.accessor('dealRotorName', {
    id: 'dealName',
    header: 'Deal name',
    cell: ({ row }) => (
      <Link
        to={generatePath(RoutePath.dealRotorEditDetailedAgreementDetails, { dealRotorId: row.original.dealRotorId })}
      >
        {row.original.dealRotorName}
      </Link>
    ),
  }),
  columnHelperRemainders.accessor('message', {
    id: 'description',
    header: 'Description',
    cell: ({ row }) => <Message message={row.original.message} />,
  }),
];

const RemaindersBar = ({ actionsData = [], remainderData = [] }: RemaindersBarProps) => {
  const [paginationRemainder, setPaginationRemainder] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const [paginationAction, setPaginationAction] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const [columnVisibility, setColumnVisibility] = useState({});

  const paginationRemainderState = useMemo(
    () => ({
      pageIndex: paginationRemainder.pageIndex,
      pageSize: paginationRemainder.pageSize,
    }),
    [paginationRemainder.pageIndex, paginationRemainder.pageSize],
  );

  const paginationActionsState = useMemo(
    () => ({
      pageIndex: paginationAction.pageIndex,
      pageSize: paginationAction.pageSize,
    }),
    [paginationAction.pageIndex, paginationAction.pageSize],
  );

  const tableActions = useReactTable({
    data: actionsData,
    state: {
      pagination: {
        pageIndex: paginationActionsState.pageIndex,
        pageSize: paginationAction.pageSize,
      },
      columnVisibility,
    },
    onPaginationChange: setPaginationAction,
    columns: columnsActions,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    manualPagination: false,
  });

  const tableRemainders = useReactTable({
    data: remainderData || [],
    state: {
      pagination: {
        pageIndex: paginationRemainderState.pageIndex,
        pageSize: paginationRemainderState.pageSize,
      },
    },
    onPaginationChange: setPaginationRemainder,
    columns: columnsRemainders,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: false,
  });

  useEffect(() => {
    if (!actionsData.length) {
      tableActions.getAllColumns().forEach((column) => column.toggleVisibility(false));
    } else {
      tableActions.getAllColumns().forEach((column) => column.toggleVisibility(true));
    }
  }, [actionsData.length, tableActions]);

  useEffect(() => {
    if (!remainderData.length) {
      tableRemainders.getAllColumns().forEach((column) => column.toggleVisibility(false));
    } else {
      tableRemainders.getAllColumns().forEach((column) => column.toggleVisibility(true));
    }
  }, [remainderData.length, tableRemainders]);

  return (
    <div className="row g-3">
      <div className="col-lg-6">
        <h3 className="h5 mb-3">
          <ActionsIcon className="me-2 mb-1" width={23} height={23} />
          Actions
        </h3>

        {actionsData.length < 1 ? (
          <div className="card vstack text-center p-4">
            <EmptyActionIcon className="text-primary text-opacity-25 align-self-center py-2" height={128} width={128} />
            <span className="mb-2">No actions</span>
          </div>
        ) : (
          <>
            <div className="table-responsive border rounded custom-scroll mb-3">
              <table className={classNames(tableStyles.Table, 'table table-white table-border-bottom-none mb-0')}>
                <thead className="table-gray">
                  {tableActions.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th key={header.id} scope="col" className="header-cell align-middle">
                          {header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext())}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {tableActions.getRowModel().rows.map((row) => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination table={tableActions} total={actionsData.length} />
          </>
        )}
      </div>
      <div className="col-lg-6">
        <h3 className="h5 mb-3">
          <RemaindersIcon className="me-2" width={24} height={24} />
          Reminders
        </h3>

        {remainderData.length < 1 ? (
          <div className="card vstack text-center p-4">
            <EmptyRemainderIcon className="text-primary text-opacity-25 align-self-center" height={128} width={128} />
            <span className="mb-2">No reminders</span>
          </div>
        ) : (
          <>
            <div className="table-responsive border rounded custom-scroll mb-3">
              <table className={classNames(tableStyles.Table, 'table table-white table-border-bottom-none mb-0')}>
                <thead className="table-gray">
                  {tableRemainders.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th key={header.id} scope="col" className="header-cell align-middle">
                          {header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext())}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {tableRemainders.getRowModel().rows.map((row) => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination table={tableRemainders} total={remainderData.length} />
          </>
        )}
      </div>
    </div>
  );
};

export default RemaindersBar;
