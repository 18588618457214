import classNames from 'classnames';
import { useState, useEffect, PropsWithChildren } from 'react';
import { Spinner } from 'react-bootstrap';

export type AdditionalProps = {
  minTime?: number;
  isLoading?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<any> | any;
};

export type Props = PropsWithChildren<
  React.DetailedHTMLProps<Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'>, HTMLButtonElement> &
    AdditionalProps
>;

function LoadingButton({
  className,
  minTime = 500,
  type = 'button',
  disabled,
  isLoading,
  onClick,
  children,
  ...otherProps
}: Props) {
  const [isAsyncLoading, setIsAsyncLoading] = useState(false);
  const [timeoutId, setTimeoutId] = useState<number>();

  const buttonClickHandler = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!onClick) return;
    setIsAsyncLoading(true);

    const startTime = new Date().getTime();

    try {
      const result = await onClick(event);

      const endTime = new Date().getTime();
      const elapsedTime = endTime - startTime;
      const leftTime = minTime - elapsedTime;

      if (leftTime > 0) {
        await new Promise((resolve) => {
          clearTimeout(timeoutId);
          const id = window.setTimeout(resolve, leftTime);
          setTimeoutId(id);
        });
      }
      return result;
    } catch (e) {
      throw e;
    } finally {
      setIsAsyncLoading(false);
    }
  };

  // Unmount Cleanup
  useEffect(() => () => clearTimeout(timeoutId), [timeoutId]);

  const isButtonLoading = isAsyncLoading || isLoading || false;

  return (
    <button
      className={classNames(className)}
      type={type}
      onClick={buttonClickHandler}
      disabled={isButtonLoading || disabled}
      {...otherProps}
    >
      {isButtonLoading && <Spinner className="me-2" size="sm" />}
      {children}
    </button>
  );
}

export default LoadingButton;
