import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useLocation, useParams, generatePath, Outlet, Params } from 'react-router-dom';
import { RoutePath } from 'src/router';

import Breadcrumbs, { Breadcrumb } from 'src/components/Breadcrumbs';
import PillNavigation from 'src/components/PillNavigation';

import usePersistPageParams, { DEAL_ROTOR_LIST_PAGE_KEY } from 'src/hooks/usePersistPageParams';

import { getDealRotorById } from 'src/api/base/dealRotor';

import { DealRotorDto } from 'src/types';

export type DealRotorEditOutletContext = {
  isDealRotorLoading: boolean;
  dealRotor: DealRotorDto | undefined;
  dealRotorError: any;
};

function DealRotorEdit() {
  const location = useLocation();
  const { dealRotorId = '' } = useParams<Params<'dealRotorId'>>();
  const { pageUrlSearch: dealRotorListPageUrlSearch } = usePersistPageParams(DEAL_ROTOR_LIST_PAGE_KEY);

  const {
    isLoading: isDealRotorLoading,
    data: dealRotor,
    error: dealRotorError,
  } = useQuery({
    enabled: Boolean(dealRotorId),
    queryKey: ['getDealRotorById', dealRotorId],
    async queryFn({ signal }) {
      const config = { signal };
      const res = await getDealRotorById(dealRotorId, config);
      return res.data;
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  const breadcrumbs = useMemo<Breadcrumb[]>(
    () => [
      { name: 'Home', to: RoutePath.root },
      { name: 'Deal rotors', to: { pathname: RoutePath.dealRotorList, search: dealRotorListPageUrlSearch } },
      { name: dealRotor?.number ?? 'DL-123456', to: location, isLoading: isDealRotorLoading },
    ],
    [dealRotor, isDealRotorLoading, dealRotorListPageUrlSearch, location],
  );

  const pills = useMemo(
    () => [
      { name: 'Detailed', to: generatePath(RoutePath.dealRotorEditDetailedRoot, { dealRotorId }) },
      { name: 'Timeline', to: generatePath(RoutePath.dealRotorEditDetailedTimeline, { dealRotorId }) },
      { name: 'Notifications', to: generatePath(RoutePath.dealRotorNotificationRoot, { dealRotorId }) },
    ],
    [dealRotorId],
  );

  const context: DealRotorEditOutletContext = useMemo(
    () => ({ isDealRotorLoading, dealRotor, dealRotorError }),
    [dealRotor, dealRotorError, isDealRotorLoading],
  );

  // "/deal-rotor/edit/:dealRotorId/*"
  return (
    <div className="vstack">
      <div className="d-flex flex-wrap align-items-baseline gap-3 mb-4">
        <Breadcrumbs className="me-auto" breadcrumbs={breadcrumbs} />
        <PillNavigation pills={pills} />
      </div>
      <Outlet context={context} />
    </div>
  );
}

export default DealRotorEdit;
