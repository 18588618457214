import { useMemo } from 'react';

import { useSelector } from 'src/store';
import { tenantTimezoneOffsetSecondsSelector } from 'src/store/selectors/tenantSelector';

function useTenantTimezone() {
  const tenantTimezoneOffsetSeconds = useSelector(tenantTimezoneOffsetSecondsSelector);
  const browserTimezoneOffsetSeconds = new Date().getTimezoneOffset() * -60;
  const timezoneOffsetSecondsDiff = tenantTimezoneOffsetSeconds - browserTimezoneOffsetSeconds;

  const tenantTimezoneDateNow = useMemo(
    () => new Date(new Date().getTime() + timezoneOffsetSecondsDiff * 1000),
    [timezoneOffsetSecondsDiff],
  );

  return { tenantTimezoneDateNow };
}

export default useTenantTimezone;
