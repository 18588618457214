class LocalDate {
  private date: Date;

  constructor(date: Date | string | number) {
    this.date = date instanceof Date ? date : new Date(date);
  }

  get value(): Date {
    return this.date;
  }

  get isValid() {
    return !isNaN(this.date.getTime());
  }

  getStartDay(): LocalDate {
    return new LocalDate(new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate()));
  }

  getEndDay(): LocalDate {
    return new LocalDate(new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate(), 23, 59, 59, 999));
  }

  toJSON(): string {
    const year = this.date.getFullYear();
    const month = String(this.date.getMonth() + 1).padStart(2, '0');
    const day = String(this.date.getDate()).padStart(2, '0');
    const hours = String(this.date.getHours()).padStart(2, '0');
    const minutes = String(this.date.getMinutes()).padStart(2, '0');
    const seconds = String(this.date.getSeconds()).padStart(2, '0');
    const milliseconds = String(this.date.getMilliseconds()).padStart(3, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
  }
}

export default LocalDate;
