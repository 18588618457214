import { AxiosRequestConfig } from 'axios';

import { baseApi } from 'src/api';
import RequestSearchParamsAdapter from 'src/utils/RequestSearchParamsAdapter';
import {
  UUIDString,
  OrganisationStatus,
  OrganisationDtoDataPagedDto,
  OrganisationSummaryDto,
  GetOrganisationDto,
  OrganisationModel,
  CreateOrganisationWithContactsModel,
} from 'src/types';

export const getOrganisationById = (organisationId: UUIDString, config: AxiosRequestConfig = {}) =>
  baseApi.get<GetOrganisationDto>(`/organisations/${organisationId}`, config);

export const updateOrganisation = (
  organisationId: UUIDString,
  data: OrganisationModel,
  config: AxiosRequestConfig = {},
) => baseApi.patch<void>(`/organisations/${organisationId}`, data, config);

export const deleteOrganisation = (organisationId: UUIDString) =>
  baseApi.delete<void>(`/organisations/${organisationId}`);

export const getOrganisationPartyBListSummary = (
  status: OrganisationStatus | undefined,
  config: AxiosRequestConfig = {},
) => baseApi.get<OrganisationSummaryDto[]>('/organisations/party-b-summary', { ...config, params: { status } });

type GetOrganisationListParams = RequestSearchParamsAdapter & {
  status: OrganisationStatus | undefined;
};
export const getOrganisationListSearch = (params: GetOrganisationListParams, config: AxiosRequestConfig = {}) =>
  baseApi.get<OrganisationDtoDataPagedDto>('/organisations/search', { ...config, params });

export const createOrganisation = (data: OrganisationModel, config: AxiosRequestConfig = {}) =>
  baseApi.post<UUIDString>('/organisations', data, config);

export const createOrganisationWithContacts = (
  data: CreateOrganisationWithContactsModel[],
  config: AxiosRequestConfig = {},
) => baseApi.post<void>('/organisations/contacts', data, config);

export const uploadOrganisationLogo = (organisationId: UUIDString, data: FormData, config: AxiosRequestConfig = {}) =>
  baseApi.post<void>(`/organisations/${organisationId}/logo`, data, config);

export const updateOrganisationStatus = (organisationId: UUIDString, status: OrganisationStatus) =>
  baseApi.patch<void>(`/organisations/${organisationId}/status`, null, { params: { status } });
