import classNames from 'classnames';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useParams, useOutletContext, generatePath, Outlet, Params } from 'react-router-dom';
import { RoutePath } from 'src/router';

import Dot from 'src/components/Dot';
import TabNavigation from 'src/components/TabNavigation';
import ServerErrorMessages from 'src/components/ServerErrorMessages';

import { DealRotorEditOutletContext } from './DealRotorEdit';

import useUserPermission from 'src/hooks/useUserPermission';
import { tenantSettingSelector } from 'src/store/selectors/tenantSelector';

import ServerErrorAdapter from 'src/utils/ServerErrorAdapter';
import { dealRotorStatusColorMap } from 'src/utils/selectOptions';

import { getAgreementList } from 'src/api/base/agreement';
import { getAgreementAttribute } from 'src/api/base/agreementAttribute';
import { getOrganisationPartyBListSummary } from 'src/api/base/organisation';
import { getChatGlobalSettings } from 'src/api/base/chat';

import {
  DealRotorDto,
  AgreementDto,
  AgreementAttributeDto,
  ChatSettingDto,
  PermissionNames,
  SelectOption,
  ServerError,
  OrganisationStatus,
} from 'src/types';

import styles from './DealRotorEdit.module.scss';
import { useSelector } from 'react-redux';

export type DealRotorEditDetailedTabsOutletContext = {
  isDealRotorLoading: boolean;
  dealRotor: DealRotorDto | undefined;
  isDealRotorAgreementListLoading: boolean;
  dealRotorAgreementList: AgreementDto[];
  isDealRotorAgreementAttributeLoading: boolean;
  dealRotorAgreementAttribute: AgreementAttributeDto | null;
  isPartyBOptionsLoading: boolean;
  partyBOptions: SelectOption[];
  chatSettings: ChatSettingDto | undefined;
};

function DealRotorEditDetailedTabs() {
  const { hasUserPermission, hasUserAllowedPermissions } = useUserPermission();
  const isAllowedOrganisationPartyBSummaryRead = hasUserPermission(PermissionNames.OrganisationPartyBSummaryRead);
  const isAllowedAgreementAttributesRead = hasUserPermission(PermissionNames.AgreementAttributesRead);
  const isAllowedChatRead = hasUserPermission(PermissionNames.ChatRead);
  const tenantSetting = useSelector(tenantSettingSelector);

  const { dealRotorId = '' } = useParams<Params<'dealRotorId'>>();
  const { isDealRotorLoading, dealRotor, dealRotorError } = useOutletContext<DealRotorEditOutletContext>();

  const {
    isLoading: isDealRotorAgreementListLoading,
    data: dealRotorAgreementList,
    error: dealRotorAgreementListError,
  } = useQuery({
    enabled: Boolean(dealRotorId),
    queryKey: ['getAgreementList', dealRotorId],
    async queryFn({ signal }) {
      const config = { signal };
      const res = await getAgreementList(dealRotorId, config);
      return res.data;
    },
    initialData: [],
    retry: false,
    refetchOnWindowFocus: false,
  });

  const { isLoading: isDealRotorAgreementAttributeLoading, data: dealRotorAgreementAttribute } = useQuery({
    enabled: isAllowedAgreementAttributesRead && Boolean(dealRotorId) && dealRotor?.isAgreementAttribute === true,
    queryKey: ['getAgreementAttribute', dealRotorId, dealRotor],
    async queryFn({ signal }) {
      const config = { signal };
      const res = await getAgreementAttribute(dealRotorId, config);
      return res.data;
    },
    initialData: null,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const organisationStatus = OrganisationStatus.Active;
  const {
    isLoading: isPartyBSummaryLoading,
    data: partyBSummary,
    error: partyBSummaryError,
  } = useQuery({
    queryKey: ['getOrganisationPartyBListSummary', organisationStatus],
    async queryFn({ signal }) {
      if (!isAllowedOrganisationPartyBSummaryRead) return [];
      const config = { signal };
      const res = await getOrganisationPartyBListSummary(organisationStatus, config);
      return res.data;
    },
    initialData: [],
    retry: false,
    refetchOnWindowFocus: false,
  });
  const partyBOptions = useMemo<SelectOption[]>(
    () =>
      partyBSummary
        .toSorted((a, b) => a.name.localeCompare(b.name))
        .map(({ id, name }) => ({ label: name, value: id })),
    [partyBSummary],
  );

  const { data: chatSettings } = useQuery({
    enabled: tenantSetting?.isChatAccessible === true && isAllowedChatRead,
    queryKey: ['getChatGlobalSettings'],
    async queryFn({ signal }) {
      const config = { signal };
      const res = await getChatGlobalSettings(config);
      return res.data;
    },
    retry: false,
    refetchOnWindowFocus: false,
    gcTime: 0,
  });

  const errorMessages = useMemo(() => {
    const dealRotorErrorMessages = new ServerErrorAdapter(dealRotorError as ServerError).combine();
    const dealRotorAgreementListMessages = new ServerErrorAdapter(dealRotorAgreementListError as ServerError).combine();
    const partyBOptionsErrorMessages = new ServerErrorAdapter(partyBSummaryError as ServerError).combine();
    return [...dealRotorErrorMessages, ...dealRotorAgreementListMessages, ...partyBOptionsErrorMessages];
  }, [dealRotorError, dealRotorAgreementListError, partyBSummaryError]);

  const tabs = useMemo(
    () =>
      [
        {
          name: 'Deal rotor details',
          to: generatePath(RoutePath.dealRotorEditDetailedDealRotorDetails, { dealRotorId }),
        },
        {
          name: 'Agreement details',
          to: generatePath(RoutePath.dealRotorEditDetailedAgreementDetails, { dealRotorId }),
        },
        {
          name: 'Participants',
          to: generatePath(RoutePath.dealRotorEditDetailedParticipants, { dealRotorId }),
          allowedPermissions: [
            PermissionNames.DealRotorTransferOwner,
            PermissionNames.DealRotorTransferOperationOwner,
            PermissionNames.DealRotorUpdateManagers,
            PermissionNames.DealRotorUpdateOperationManagers,
            PermissionNames.DealRotorUpdateSignatories,
          ],
        },
        { name: 'Attachments', to: generatePath(RoutePath.dealRotorEditDetailedAttachments, { dealRotorId }) },
        {
          name: 'AI console',
          to: generatePath(RoutePath.dealRotorEditDetailedAIConsole, { dealRotorId }),
          allowedPermissions: [PermissionNames.ChatRead],
          hasChatEnabled: true,
        },
      ].filter(({ allowedPermissions, hasChatEnabled }) => {
        const isUserPermissionAllowed = hasUserAllowedPermissions(allowedPermissions);
        const isChatPermissionAllowed = hasChatEnabled === true ? chatSettings?.isChatEnabled === true : true;
        return isUserPermissionAllowed && isChatPermissionAllowed;
      }),
    [dealRotorId, hasUserAllowedPermissions, chatSettings],
  );

  const context: DealRotorEditDetailedTabsOutletContext = useMemo(
    () => ({
      isDealRotorLoading,
      dealRotor,
      isDealRotorAgreementListLoading,
      dealRotorAgreementList,
      isDealRotorAgreementAttributeLoading,
      dealRotorAgreementAttribute,
      isPartyBOptionsLoading: isPartyBSummaryLoading,
      partyBOptions,
      chatSettings,
    }),
    [
      dealRotor,
      dealRotorAgreementAttribute,
      dealRotorAgreementList,
      isDealRotorAgreementAttributeLoading,
      isDealRotorAgreementListLoading,
      isDealRotorLoading,
      isPartyBSummaryLoading,
      partyBOptions,
      chatSettings,
    ],
  );

  return (
    <section className="card h-100">
      <header className="card-header bg-white p-4 pb-0">
        <div className="hstack align-items-start gap-3 mb-4">
          <h1 className="h5 mb-0">Deal rotor</h1>
          {dealRotor && (
            <div className="hstack gap-2 ms-auto">
              <Dot color={dealRotorStatusColorMap[dealRotor.status]} />
              <span className={`text-${dealRotorStatusColorMap[dealRotor.status]}`}>{dealRotor.status}</span>
            </div>
          )}
        </div>
        <ServerErrorMessages className="w-100" messages={errorMessages} />
        <TabNavigation className="pt-2" tabs={tabs} />
      </header>
      <div className={classNames(styles.TabScrollableBody, 'card-body custom-scroll p-4')}>
        <Outlet context={context} />
      </div>
    </section>
  );
}

export default DealRotorEditDetailedTabs;
