const PROCESS_ENV_PREFIX = 'REACT_APP_';

function getEnvVariable(name: string): string {
  let envVariable = '';
  if (process.env.NODE_ENV === 'production') {
    envVariable = (window.__ENV && window.__ENV[name]) ?? '';
  } else {
    envVariable = process.env[`${PROCESS_ENV_PREFIX}${name}`] ?? '';
  }
  return envVariable;
}

export default getEnvVariable;
